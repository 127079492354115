import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import AssignedUsers from './AssignedUsers';
import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';
import Items from '../../../ItemManagement/Items';

interface UserGroupDetailsProps {
	userGroupObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchUserGroup?(...args: unknown[]): unknown;
}

const UserGroupDetails = (props: UserGroupDetailsProps) => {
	const { userGroupObject, onFetchUserGroup } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: userGroupData, loading: userGroupLoading, error: userGroupError } = userGroupObject;
	const userGroupReady = isObject(userGroupData) && !userGroupLoading && !userGroupError;

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!userGroupLoading) {
			onFetchUserGroup(id);
		}
	}, [id, onFetchUserGroup]);

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		{ value: 'items', label: t('ui.category.items') },
		{ value: 'users', label: t('ui.category.users') },
	];

	if (!tab) {
		return <Navigate to={'/user-management/user-groups'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'summary', hasButton: false },
		{ key: 'items', hasButton: false },
		{ key: 'users', hasButton: false },
	];

	return (
		<Page className={classes.root} title={t('views.userGroupDetail.page.title')}>
			{headerButtons.map(
				(button) =>
					button.key === tab && (
						<Header
							key={button.key}
							loading={!userGroupReady}
							name={isObject(userGroupData) ? userGroupData.name : '-'}
							{...button}
						/>
					),
			)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary loading={!userGroupReady} userGroup={userGroupData} />}
				{tab === 'items' && (
					<Items
						itemUse='userGroup'
						userGroupData={userGroupData}
						userGroupId={parseInt(id, 10)}
						userGroupReady={userGroupReady}
					/>
				)}
				{tab === 'users' && <AssignedUsers loading={!userGroupReady} userGroup={userGroupData} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		userGroupObject: state.details.userGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroup: (id) => dispatch(actions.fetchUserGroup(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupDetails);
