import path from 'path';
import { useAtomValue } from 'jotai';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';
import { PageLayout } from '~layouts';
import i18n from '~lib/i18n';
import {
	AccessLogsPage,
	AccessRuleDetailsPage,
	AccessRulesPage,
	AccessScheduleDetailsPage,
	AccessSchedulesPage,
	SkcDeviceGroupDetailsPage,
	SkcDeviceGroupsPage,
	SkcUserGroupDetailsPage,
	SkcUserGroupsPage,
} from '~pages';

const isHigherThanAcceptance =
	import.meta.env.VITE_APP_ENV === 'accept' || import.meta.env.VITE_APP_ENV === 'production';

const skcAccessRoutes = (): RouteObject[] => [
	{
		path: pagePathSegments.Access,
		element: (
			<PageLayout
				title={i18n.t('nav.category.accessManagement')}
				pages={[
					{ label: i18n.t('rules'), path: pagePathSegments.Rules },
					{ label: i18n.t('deviceGroups'), path: pagePathSegments.DeviceGroups },
					{ label: i18n.t('userGroups'), path: pagePathSegments.UserGroups },
					{ label: i18n.t('schedules'), path: pagePathSegments.Schedules },
					{ label: i18n.t('logs'), path: pagePathSegments.Logs },
				]}
			/>
		),
		children: [
			{
				index: true,
				element: <Navigate to={pagePathSegments.Rules} />,
			},
			{
				path: pagePathSegments.Rules,
				element: <AccessRulesPage />,
			},
			{
				path: pagePathSegments.Logs,
				element: <AccessLogsPage />,
			},
			{
				path: pagePathSegments.DeviceGroups,
				element: <SkcDeviceGroupsPage />,
			},
			{
				path: pagePathSegments.UserGroups,
				element: <SkcUserGroupsPage />,
			},
			{
				path: pagePathSegments.Schedules,
				element: <AccessSchedulesPage />,
			},
		],
	},
	{
		path: pagePathSegments.Access,
		children: [
			{
				path: path.join(pagePathSegments.Rules, ':id'),
				element: <PageLayout title={i18n.t('views.addTemplate.templateDetails.title')} />,
				children: [
					{
						index: true,
						element: <AccessRuleDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.UserGroups, ':id'),
				element: <PageLayout title={i18n.t('views.userGroupDetail.page.title')} />,
				children: [
					{
						index: true,
						element: <SkcUserGroupDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.DeviceGroups, ':id'),
				element: <PageLayout title={i18n.t('deviceGroupDetails')} />,
				children: [
					{
						index: true,
						element: <SkcDeviceGroupDetailsPage />,
					},
				],
			},
			{
				path: path.join(pagePathSegments.Schedules, ':id'),
				element: <PageLayout title={i18n.t('scheduleDetails')} />,
				children: [
					{
						index: true,
						element: <AccessScheduleDetailsPage />,
					},
				],
			},
		],
	},
];

export default skcAccessRoutes;
