import { useEffect, useState } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	Card,
	CardContent,
	CircularProgress,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Label, StyledButton } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { useInterval } from '../../../../../shared/hooks';
import {
	isObject,
	isFullArray,
	isBoolean,
	isFullString,
	isNumber,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import theme from '../../../../../theme';
import { MyTooltip } from '../../../DevicesList/style';

interface VehicleStatusProps {
	vehicleData?: object | boolean;
	id?: string;
	deviceType?: string;
	onUpdateVehicleStatus?(...args: unknown[]): unknown;
	instanceAccess?: any;
	isInstance?: boolean;
}

const VehicleStatus = (props: VehicleStatusProps) => {
	const { vehicleData, id, onUpdateVehicleStatus, deviceType, instanceAccess, isInstance } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const isBMW = deviceType === 'bmwCar';
	const { data: instanceAccessData } = instanceAccess;

	const [vehicleStateLoading, setVehicleStateLoading] = useState(false);
	const [lastUpdatedVehicleState, setLastUpdatedVehicleState] = useState(vehicleData.timestamp);
	const [forceRefresh, setForceRefresh] = useState(false);
	const [counter, setCounter] = useState(5);
	const [delay, setDelay] = useState(null);
	const [showUnavailableWarning, setShowUnavailableWarning] = useState(false);

	const statusOfWindows =
		isBMW &&
		isObject(vehicleData) &&
		vehicleData?.windows &&
		vehicleData.windows.reduce((acc, status) => acc || status.open, false);
	const openWindows =
		isBMW &&
		isObject(vehicleData) &&
		vehicleData?.windows &&
		vehicleData.windows.filter((window) => (window.open ? window.location : null));

	const statusOfDoors =
		!isBMW &&
		isObject(vehicleData) &&
		vehicleData?.doors &&
		vehicleData.doors.reduce((acc, status) => acc || status.open, false);
	const openDoors =
		!isBMW &&
		isObject(vehicleData) &&
		vehicleData?.doors &&
		vehicleData.doors.filter((door) => (door.open ? door.location : null));

	const windowsOpen = {
		Driver: t('ui.label.driver'),
		DriverRear: t('ui.label.driverRear'),
		Passenger: t('ui.label.passenger'),
		PassengerRear: t('ui.label.passengerRear'),
		SunRoof: t('ui.label.sunRoof'),
	};

	const ignitionStatus = {
		Disabled: t('ui.disabled'),
		Enabled: t('ui.enabled'),
	};

	const centralLocking = {
		locked: t('ui.label.locked'),
		unlocked: t('ui.label.unlocked'),
		secured: t('ui.label.lockedSecurely'),
	};

	const handleWindows = () => (
		<Box display='flex'>
			{statusOfWindows ?
				<MyTooltip
					arrow
					title={
						isFullArray(openWindows) &&
						openWindows.map((item, index) => (
							<Typography key={index}>{windowsOpen[item.location]}</Typography>
						))
					}
				>
					<span>{t('ui.open')}</span>
				</MyTooltip>
			:	t('ui.openStatus.closed')}
		</Box>
	);

	const handleDoors = () => (
		<Box display='flex'>
			{statusOfDoors ?
				<MyTooltip
					arrow
					title={
						isFullArray(openDoors) &&
						openDoors.map((item, index) => (
							<Typography key={index}>{windowsOpen[item.location]}</Typography>
						))
					}
				>
					<span>{t('ui.open')}</span>
				</MyTooltip>
			:	t('ui.openStatus.closed')}
		</Box>
	);

	const handleGPSLocation = () => {
		if ((isBMW && vehicleData?.latitude) || isObject(vehicleData?.location)) {
			const latitude = isBMW ? vehicleData.latitude : vehicleData.location.latitude;
			const longitude = isBMW ? vehicleData.longitude : vehicleData.location.longitude;
			return (
				<a
					className={classes.mapLink}
					href={`https://maps.google.com/?daddr=${latitude},${longitude}`}
					rel='noreferrer noopener'
					target='_blank'
				>
					{`${latitude}, ${longitude}`}
				</a>
			);
		}
	};

	const handleBatteryLevel = () => {
		if (isBMW && isNumber(vehicleData.hvBatteryLevel)) {
			return `${vehicleData.hvBatteryLevel}%`;
		} else if (deviceType === 'vehicleTrackerCan' && isNumber(vehicleData?.vehicleBatteryLevel)) {
			return `${vehicleData.vehicleBatteryLevel}%`;
		} else if (isNumber(vehicleData?.batteryLevel)) {
			return `${vehicleData.batteryLevel}%`;
		} else {
			return '-';
		}
	};

	const handleLastUpdateRefresh = () => {
		setVehicleStateLoading(true);
		setForceRefresh(true);
		setDelay(100);
	};

	useEffect(() => {
		if (!counter) {
			setVehicleStateLoading(false);
			setForceRefresh(false);
			setDelay(null);
			setCounter(5);
			setLastUpdatedVehicleState(vehicleData.timestamp);
			if (lastUpdatedVehicleState === vehicleData.timestamp) {
				setShowUnavailableWarning(true);
			} else {
				setShowUnavailableWarning(false);
			}
		}
	}, [counter]);

	useInterval(
		() => {
			if (forceRefresh) {
				onUpdateVehicleStatus(id, 'getVehicleState');
				if (lastUpdatedVehicleState !== vehicleData.timestamp) {
					setCounter(0);
				} else {
					setCounter(counter - 1);
					setDelay(5000);
				}
			}
		},
		counter ? delay : null,
	);

	const handleFuelRange = () => {
		if (isBMW && vehicleData?.fuelLevel) {
			return `${vehicleData.fuelLevel} liter`;
		} else if (vehicleData?.vehicleFuelVolume) {
			return `${vehicleData.vehicleFuelVolume} liter`;
		} else if (vehicleData?.vehicleFuelLevel) {
			return `${vehicleData?.vehicleFuelLevel}%`;
		} else {
			return '-';
		}
	};

	const access = isInstance ? instanceAccessData?.includes('internal') : !isInstance;

	const vehicleDataContent = [
		{
			label: 'views.ticketsDetails.lastUpdated',
			content: (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<Label>
							{isBMW ?
								commaTimeStrings(lastUpdatedVehicleState)
							:	commaTimeStrings(vehicleData?.lastUpdated)}
						</Label>
						{isBMW ?
							vehicleStateLoading ?
								<Box sx={{ padding: theme.spacing(0.5, 4) }}>
									<CircularProgress color='info' disableShrink size={16} />
								</Box>
							:	<StyledButton
									className={classes.button}
									onClick={handleLastUpdateRefresh}
									size={'small'}
									startIcon={<RefreshIcon fontSize='small' />}
								>
									{t('ui.refresh')}
								</StyledButton>

						:	null}
					</Box>
					{showUnavailableWarning && (
						<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: '#CE2A3D' }}>
							<ErrorOutlineIcon fontSize='small' />
							<Typography sx={{ color: '#CE2A3D' }} variant={'caption'}>
								{t('views.devices.vehicleStatus.unavailableStatus')}
							</Typography>
						</Box>
					)}
				</Box>
			),
		},
		{
			label: 'ui.label.lockStatus',
			content:
				isBMW && vehicleData?.centralLocking ?
					centralLocking[vehicleData.centralLocking.toLowerCase()]
				:	centralLocking[vehicleData.lockState],
		},
		{ label: 'ui.label.ignitionStatus', content: ignitionStatus[vehicleData.ignition] },
		...(!isBMW ?
			[
				{
					label: 'ui.label.immobiliserStatus',
					content:
						isBoolean(vehicleData.immobiliserStateEnabled) &&
						(vehicleData.immobiliserStateEnabled ? t('ui.enabled') : t('ui.disabled')),
				},
				{
					label: 'ui.label.keyDetected',
					content:
						isFullString(vehicleData.keyDetected) && t(`ui.${String(vehicleData.keyDetected)}`),
				},
				// { label: 'ui.label.tankCardDetected', content: isFullString(vehicleData.tankCardDetected) && t(`ui.${String(vehicleData.tankCardDetected)}`) },
			]
		:	[]),
		{ label: 'ui.label.doorStatus', content: handleDoors() },
		...(isBMW ? [{ label: 'ui.label.windowStatus', content: handleWindows() }] : []),
		{
			label: 'ui.label.chargingStatus',
			content:
				isBMW ?
					vehicleData?.charging && t(`ui.label.${vehicleData.charging.toLowerCase()}`)
				:	vehicleData?.batteryCharging && t('ui.label.device.state.charging'),
		},
		{
			label:
				isBMW ? 'ui.label.batteryBMW'
				: deviceType === 'vehicleTrackerCan' ? 'ui.label.vehicleBattery'
				: 'ui.label.battery',
			content: handleBatteryLevel(),
		},
		{ label: isBMW ? 'ui.label.fuelLevelBMW' : 'ui.label.fuelLevel', content: handleFuelRange() },
		...(isBMW ?
			[{ label: 'ui.device.label.reachCombustion', content: vehicleData.reachCombustion }]
		:	[]),
		{
			label: 'ui.device.label.reachElectric',
			content:
				isBMW ?
					vehicleData.reachElectric
				:	vehicleData?.electricalRange && `${vehicleData.electricalRange}`,
		},
		{ label: 'ui.device.label.reachTotal', content: isBMW ? vehicleData.reach : vehicleData.range },
		...(access ? [{ label: 'ui.label.mileage', content: vehicleData.mileage }] : []),
		...(access ?
			[
				{
					label: 'views.addLocation.locationDetails.locationSection.input.gps.title',
					content: handleGPSLocation(),
				},
			]
		:	[]),
	];

	return (
		<Card className={classes.root}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('ui.label.deviceStatus')}</Typography>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{vehicleData ?
							<>
								{vehicleDataContent.map((vehicle) => (
									<TableRow key={vehicle.label}>
										<TableCell>{t(vehicle.label)}</TableCell>
										<TableCell>{vehicle.content ? vehicle.content : '-'}</TableCell>
									</TableRow>
								))}
							</>
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		instanceAccess: state.list.instanceAccess,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateVehicleStatus: (id, command) => dispatch(actions.updateVehicleStatus(id, command)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleStatus);
