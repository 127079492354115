import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Label } from '../../../../../../components';

interface PaymentPolicyInfoProps {
	className?: string;
	policy: object;
}

const PaymentPolicyInfo = (props: PaymentPolicyInfoProps) => {
	const { policy, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.paymentPolicies')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.personalBilling')}</TableCell>
							<TableCell>
								<Label type={policy.allowPersonalBilling ? 'success' : 'error'}>
									{policy.allowPersonalBilling ? t('ui.enabled') : t('ui.disabled')}
								</Label>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default PaymentPolicyInfo;
