import { useEffect } from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../../components';
import { exportType } from '../../../../../constantsOld';
import { useExport } from '../../../../../shared/downloadHooks';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions/index';

interface TripDetailsProps {
	trip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemInstancesTrip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	tripCoordinates?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	instanceTripCoordinates?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	bookingTripCoordinates?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	bookingTrip?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportBookingTripDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportInstanceTripDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	exportDeviceTripDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onTrip?(...args: unknown[]): unknown;
	onTripCoordinates?(...args: unknown[]): unknown;
	onItemInstancesTrip?(...args: unknown[]): unknown;
	onInstanceTripCoordinates?(...args: unknown[]): unknown;
	onBookingTripCoordinates?(...args: unknown[]): unknown;
	onBookingTrip?(...args: unknown[]): unknown;
	onExportBookingTripDetails?(...args: unknown[]): unknown;
	onExportInstanceTripDetails?(...args: unknown[]): unknown;
	onExportDeviceTripDetails?(...args: unknown[]): unknown;
}

const TripDetails = (props: TripDetailsProps) => {
	const {
		trip,
		onTrip,
		onTripCoordinates,
		tripCoordinates,
		itemInstancesTrip,
		onItemInstancesTrip,
		onInstanceTripCoordinates,
		instanceTripCoordinates,
		onBookingTripCoordinates,
		bookingTrip,
		bookingTripCoordinates,
		onBookingTrip,
		onExportDeviceTripDetails,
		onExportInstanceTripDetails,
		onExportBookingTripDetails,
		exportBookingTripDetails,
		exportInstanceTripDetails,
		exportDeviceTripDetails,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const { locality, id, tripId, tab } = useParams();

	const isBookingTrip = locality === 'booking';
	const isInstanceTrip = locality === 'item-management';
	const isDeviceTrip = locality === 'devices';

	const handleTripData = () => {
		if (isInstanceTrip) {
			return itemInstancesTrip;
		}
		if (isBookingTrip) {
			return bookingTrip;
		}
		if (isDeviceTrip) {
			return trip;
		}
	};

	const handleExportTripDetails = () => {
		if (isInstanceTrip) {
			return exportInstanceTripDetails;
		}
		if (isBookingTrip) {
			return exportBookingTripDetails;
		}
		if (isDeviceTrip) {
			return exportDeviceTripDetails;
		}
	};

	const { data: tripData, loading: tripLoading, error: tripError } = handleTripData();

	const tripReady = isObject(tripData) && !tripLoading && !tripError;

	const { loading: tripExportDetailsLoading } = handleExportTripDetails();

	const exportTrip = useExport({
		label: `${t('views.devices.trip.table.title.tripDetails')}-${tripData?.sequenceNumber}`,
		exportData: handleExportTripDetails(),
	});

	const handleCoordinates = () => {
		if (isInstanceTrip) {
			return instanceTripCoordinates;
		}
		if (isBookingTrip) {
			return bookingTripCoordinates;
		}
		if (isDeviceTrip) {
			return tripCoordinates;
		}
	};

	const { data: tripCoordinatesData, loading: coordinatesLoading } = handleCoordinates();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (
			!tripLoading &&
			tripId &&
			(!isObject(tripData) || (isObject(tripData) && `${tripData.id}` !== tripId))
		) {
			if (isDeviceTrip) {
				onTrip(id, tripId);
				onTripCoordinates(id, tripId);
			} else if (isInstanceTrip) {
				onItemInstancesTrip(id, tripId);
				onInstanceTripCoordinates(id, tripId);
			} else if (isBookingTrip) {
				onBookingTrip(id, tripId);
				onBookingTripCoordinates(id, tripId);
			}
		}
	}, [tripId]);

	const handleExport = (type) => {
		if (isDeviceTrip) {
			onExportDeviceTripDetails(id, tripId, type);
		} else if (isInstanceTrip) {
			onExportInstanceTripDetails(id, tripId, type);
		} else if (isBookingTrip) {
			onExportBookingTripDetails(id, tripId, type);
		}
		exportTrip.setDownload(true);
		exportTrip.setExportType(type);
	};

	const tabs = [
		{ value: 'summary', label: t('ui.summary') },
		{ value: 'map', label: t('ui.label.tab.map') },
	];

	if (!tab) {
		return <Navigate to={'/dashboard'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const summaryProps = {
		data: tripData,

		loading: tripLoading,
		coordinates: tripCoordinatesData,
		coordinatesLoading,
		tab,
	};

	const downloadData = {
		disabled: tripExportDetailsLoading,
		text: t('views.trip.button.export.trip'),
		action: () => [
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.xlsx'),
				action: () => handleExport(exportType.XLSX),
			},
			{
				icon: <InsertDriveFileOutlinedIcon />,
				text: t('ui.button.export.csv'),
				action: () => handleExport(exportType.CSV),
			},
		],
	};

	const headerProps = {
		devicesId: id,
		tripId: tripData?.sequenceNumber,
		loading: !tripReady,
		downloadData,
	};

	return (
		<Page className={classes.root} title={t('views.devices.trip.table.title.tripDetails')}>
			<Header {...headerProps} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tripTab) => (
					<Tab key={tripTab.value} label={tripTab.label} value={tripTab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary {...summaryProps} />}
				{tab === 'map' && <Summary {...summaryProps} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		trip: state.details.trip,

		tripCoordinates: state.list.tripCoordinates,
		itemInstancesTrip: state.details.itemInstancesTrip,
		instanceTripCoordinates: state.list.instanceTripCoordinates,
		bookingTripCoordinates: state.list.bookingTripCoordinates,
		bookingTrip: state.details.bookingTrip,
		exportBookingTripDetails: state.details.exportBookingTripDetails,
		exportInstanceTripDetails: state.details.exportInstanceTripDetails,
		exportDeviceTripDetails: state.details.exportDeviceTripDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTrip: (devicesId, tripId) => dispatch(actions.trip(devicesId, tripId)),
		onTripCoordinates: (devicesId, tripId) => dispatch(actions.tripCoordinates(devicesId, tripId)),
		onItemInstancesTrip: (instancesId, tripId) =>
			dispatch(actions.itemInstancesTrip(instancesId, tripId)),
		onInstanceTripCoordinates: (instancesId, tripId) =>
			dispatch(actions.instanceTripCoordinates(instancesId, tripId)),
		onBookingTripCoordinates: (bookingId, tripId) =>
			dispatch(actions.bookingTripCoordinates(bookingId, tripId)),
		onBookingTrip: (bookingId, tripId) => dispatch(actions.bookingTrip(bookingId, tripId)),
		onExportBookingTripDetails: (bookingId, tripId, exportType) =>
			dispatch(actions.exportBookingTripDetails(bookingId, tripId, exportType)),
		onExportInstanceTripDetails: (instanceId, tripId, exportType) =>
			dispatch(actions.exportInstanceTripDetails(instanceId, tripId, exportType)),
		onExportDeviceTripDetails: (deviceId, tripId, exportType) =>
			dispatch(actions.exportDeviceTripDetails(deviceId, tripId, exportType, exportType)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripDetails);
