import { useContext, useState, useEffect, createContext } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import FilterBar from './FilterBar';
import FleetDrawer from './FleetDrawer/FleetDrawer';
import ItemContainer from './ItemContainer';
import MapComponent from './MapComponent';
import { useStyles } from './style';
import { Page } from '../../components';
import { usePrevious, useBasicFilter, useComplexFilter } from '../../shared/hooks';
import {
	isObject,
	isNull,
	isFullArray,
	isUndefined,
	isInteger,
	isBoolean,
} from '../../shared/utility';
import * as actions from '../../store/actions/index';

const mainContext = createContext();

export function useMainContext() {
	return useContext(mainContext);
}

const render = () => {
	return (
		<CircularProgress
			color='info'
			disableShrink
			size={60}
			sx={{ display: 'inline-flex', alignSelf: 'center', margin: 'auto' }}
		/>
	);
};

interface FleetManagementProps {
	location?: {
		data?: {
			navigateToMapView?: boolean;
			itemInstanceId?: number;
		};
		state?: object;
	};
	onFetchFleetsInstances?(...args: unknown[]): unknown;
	onFetchFleetsInstancesList?(...args: unknown[]): unknown;
	fetchFleetsInstances?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	fetchFleetsInstancesList?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const FleetManagement = ({
	location = {
		data: {
			navigateToMapView: false,
			itemInstanceId: -1,
		},
	},
	onFetchFleetsInstances,
	onFetchFleetsInstancesList,
	fetchFleetsInstances,
	fetchFleetsInstancesList,
	currentUser,
	...props
}: FleetManagementProps) => {
	const classes = useStyles();

	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const { data: fleetsData, loading: fleetsLoading } = fetchFleetsInstances;
	const { data: fleetsDataList, loading: fleetsLoadingList } = fetchFleetsInstancesList;
	const { data: currentUserData } = currentUser;
	const prevLoading = usePrevious(fleetsLoading);

	const [zoom, setZoom] = useState(5);
	const [clicks, setClicks] = useState([]);
	const [center, setCenter] = useState({ lat: 52.00717, lng: 4.3673422 });
	const [hideInstanceless, setHideInstanceless] = useState(
		sessionStorage?.hideInstanceles && isBoolean(JSON.parse(sessionStorage.hideInstanceles)) ?
			!JSON.parse(sessionStorage.hideInstanceles)
		:	false,
	);
	const [searchValues, setSearchValues] = useState({ text: '' });
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [fleetList, setFleetList] = useState([]);
	const [showFilteredList, setShowFilteredList] = useState(false);
	const [listHight, setListHight] = useState(null);
	const [selectedFleet, setSelectedFleet] = useState(null);

	const mapViewCategoryFilter = useBasicFilter('mapViewCategoryFilter');
	const mapViewDeviceTypeFilter = useBasicFilter('mapViewDeviceTypeFilter');
	const mapViewOrganisationFilter = useComplexFilter(
		'mapViewOrganisationNameFilter',
		'mapViewOrganisationIdFilter',
	);

	const prevCategories = usePrevious(mapViewCategoryFilter.value);
	const prevDeviceType = usePrevious(mapViewDeviceTypeFilter.value);
	const prevOrganisationFilter = usePrevious(mapViewOrganisationFilter.valueId);
	const prevHideInstanceless = usePrevious(hideInstanceless);

	const [openFleetDrawer, setOpenFleetFrawer] = useState(false);

	const handleClosingFleetDrawer = () => setOpenFleetFrawer(false);

	const handleOpenFleetDrawer = () => setOpenFleetFrawer(true);

	const navigateFromItemInstance = location.data?.navigateToMapView;
	const [renderedItemInstanceNavigation, setRenderedItemInstanceNavigation] = useState(false);

	const filters = {
		hideInstanceless: hideInstanceless,
		sortBy: 'name',
		...(mapViewCategoryFilter.value !== 'all' && { type: mapViewCategoryFilter.value }),
		...((mapViewOrganisationFilter.valueId !== 'all' || !isSuperAdmin()) && {
			organisationId:
				isSuperAdmin() ?
					mapViewOrganisationFilter.valueId
				:	currentUserData?.organisationReference?.id,
		}),
		...(mapViewDeviceTypeFilter.value !== 'all' && { deviceType: mapViewDeviceTypeFilter.value }),
	};

	useEffect(() => {
		if (!fleetsLoading) {
			onFetchFleetsInstances(filters);
		}
		if (!fleetsLoadingList) {
			onFetchFleetsInstancesList(filters);
		}
	}, []);

	useEffect(() => {
		if (
			prevCategories !== mapViewCategoryFilter.value ||
			prevDeviceType !== mapViewDeviceTypeFilter.value ||
			prevOrganisationFilter !== mapViewOrganisationFilter.valueId ||
			prevHideInstanceless !== hideInstanceless
		) {
			resetActiveStates();

			onFetchFleetsInstances(filters);
			onFetchFleetsInstancesList(filters);
		}
	}, [
		mapViewOrganisationFilter.valueId,
		mapViewDeviceTypeFilter.value,
		mapViewCategoryFilter.value,
		hideInstanceless,
	]);

	useEffect(() => {
		if (isNull(selectedFleet) && !isInteger(selectedIndex)) {
			handleClosingFleetDrawer();
		}
	}, [selectedFleet]);

	useEffect(() => {
		if (
			!isFullArray(fleetsDataList) &&
			(mapViewCategoryFilter.value !== 'all' ||
				mapViewDeviceTypeFilter.value !== 'all' ||
				mapViewOrganisationFilter.valueId !== 'all')
		) {
			resetOptions({ lat: 52.00717, lng: 4.3673422 }, 2);
		}
	}, [
		fleetsDataList,
		mapViewCategoryFilter.value,
		mapViewDeviceTypeFilter.value,
		mapViewOrganisationFilter.valueId,
	]);

	useEffect(() => {
		if (
			navigateFromItemInstance &&
			isFullArray(fleetsDataList) &&
			!renderedItemInstanceNavigation
		) {
			handleClearFilters();
			const selectedItemFleet = fleetsDataList.find(
				(fleet) => fleet.instanceId === location.data.itemInstanceId,
			);

			if (isObject(selectedItemFleet)) {
				const selectedFilteredList = fleetsDataList.filter(
					(item) =>
						item.latitude === selectedItemFleet.latitude &&
						item.longitude === selectedItemFleet.longitude,
				);
				const selectedIndexFromFilteredList = selectedFilteredList.findIndex(
					(fleet) => fleet.instanceId === selectedItemFleet.instanceId,
				);

				resetOptions({ lat: selectedItemFleet.latitude, lng: selectedItemFleet.longitude });
				setActiveStates({
					fleet: selectedItemFleet,
					index: selectedIndexFromFilteredList,
					showFiltered: true,
				});
			}
		}
	}, [navigateFromItemInstance, fleetsDataList]);

	const resetOptions = (location, allowedLevel = 21) => {
		if (zoom < allowedLevel) {
			setZoom(17);
		}

		setCenter(location);
	};

	const setActiveStates = ({ fleet = null, index = null, showFiltered = undefined }) => {
		showFiltered = isUndefined(showFiltered) ? isObject(fleet) && fleet.total > 1 : showFiltered;
		const fleetsList =
			showFiltered ?
				fleetsDataList.filter(
					(item) => item.latitude === fleet.latitude && item.longitude === fleet.longitude,
				)
			:	[];
		index =
			!isInteger(index) && isObject(fleet) && fleet.total === 1 ?
				fleetsDataList.reduce(
					(prev, current, index) =>
						current.latitude === fleet.latitude && current.longitude === fleet.longitude ?
							index
						:	prev,
					null,
				)
			:	index;

		setSelectedIndex(index);
		setShowFilteredList(showFiltered);

		if (isInteger(index)) {
			handleOpenFleetDrawer();
		}
		if (!isInteger(index) && showFiltered) {
			handleClosingFleetDrawer();
		}
		setSelectedFleet(fleet);
		setFleetList(fleetsList);
		setRenderedItemInstanceNavigation(true);
	};

	const resetActiveStates = () => {
		if (!isNull(selectedIndex)) {
			setSelectedIndex(null);
		}
		if (showFilteredList) {
			setShowFilteredList(false);
		}
		if (!isNull(selectedFleet)) {
			setSelectedFleet(null);
		}

		if (isFullArray(fleetList)) {
			setFleetList([]);
		}
	};

	const handleClearFilters = () => {
		mapViewCategoryFilter.setValue('all');
		mapViewDeviceTypeFilter.setValue('all');
		mapViewOrganisationFilter.setValueName('');
		mapViewOrganisationFilter.setValueId('all');
		setHideInstanceless(false);
		sessionStorage.removeItem('hideInstanceles');
	};

	const clearFilters = {
		clear:
			mapViewCategoryFilter.value !== 'all' ||
			mapViewDeviceTypeFilter.value !== 'all' ||
			mapViewOrganisationFilter.valueId !== 'all' ||
			hideInstanceless,
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const value = {
		categories: mapViewCategoryFilter.value,
		setCategoiries: mapViewCategoryFilter.setValue,
		selectedOrganisation: mapViewOrganisationFilter.valueName,
		setSelectedOrganisation: mapViewOrganisationFilter.setValueName,
		organisationFilter: mapViewOrganisationFilter.valueId,
		setOrganisationFilter: mapViewOrganisationFilter.setValueId,
		searchValues,
		setSearchValues,
		hideInstanceless,
		setHideInstanceless,
		deviceType: mapViewDeviceTypeFilter.value,
		setDeviceType: mapViewDeviceTypeFilter.setValue,
		zoom,
		setZoom,
		center,
		setCenter,
		clicks,
		setClicks,

		listHight,
		setListHight,
		setSelectedIndex,
		selectedIndex,
		selectedFleet,
		fleetList,
		showFilteredList,
		onClose: handleClosingFleetDrawer,
		onOpenDrawer: handleOpenFleetDrawer,
		resetOptions,
		setActiveStates,
		resetActiveStates,
		filters,
		setShowFilteredList,
		setSelectedFleet,
		fleetsDataList,
		openFleetDrawer,
	};

	const itemContainerProps = {
		loading: fleetsLoadingList,
		data: fleetsDataList,
	};

	return (
		<Page className={classes.filterContainerRoot} title={t('nav.category.fleetManagement')}>
			<mainContext.Provider value={value}>
				<Box display='flex' height='100%' width='100%' flexDirection='column'>
					<FilterBar clearFilters={clearFilters} />
					{/* The height='100px' shouldn't be necessary. But this keeps the component inside
                    the bounds. Doens't matter what the height value is, is sets the display blox properties */}
					<Box flex='1 1 100px' display='flex' width='100%' height='100px' maxHeight='100%'>
						<ItemContainer {...itemContainerProps} />
						<Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} render={render}>
							<MapComponent
								data={fleetsData}
								loading={fleetsLoading}
								maxZoom={20}
								prevLoading={prevLoading}
							/>
						</Wrapper>
					</Box>
				</Box>
				<FleetDrawer />
			</mainContext.Provider>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchFleetsInstances: state.list.fetchFleetsInstances,
		fetchFleetsInstancesList: state.list.fetchFleetsInstancesList,

		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchFleetsInstances: (filters) => dispatch(actions.fetchFleetsInstances(filters)),
		onFetchFleetsInstancesList: (filters) => dispatch(actions.fetchFleetsInstancesList(filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetManagement);
