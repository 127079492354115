import { useEffect, useState } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Paper, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useStyles, MyTooltip } from './style';
import { SearchBar, Table } from '../../../components';
import { useSearchComponent } from '../../../shared/hooks';
import {
	isObject,
	isUndefined,
	isEmptyArray,
	isFullArray,
	isEmptyString,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface DockingLocationsProps {
	onFetchDockingLocations?(...args: unknown[]): unknown;
	fetchDockingLocations?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const DockingLocations = (props: DockingLocationsProps) => {
	const { onFetchDockingLocations, fetchDockingLocations } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const { tab } = useParams();

	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [sortByProperty, setSortByProperty] = useState('');

	const { data: fetchDockingLocationsData, loading: fetchDockingLocationsLoading } =
		fetchDockingLocations;

	const [loading, setLoading] = useState(true);
	const isPageEmpty =
		isObject(fetchDockingLocationsData) &&
		(isEmptyArray(fetchDockingLocationsData.results) ||
			isUndefined(fetchDockingLocationsData.results));
	const isPageFull =
		isObject(fetchDockingLocationsData) && isFullArray(fetchDockingLocationsData.results);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [shouldSort, setShouldSort] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const page = { number: pageNumber, size: pageSize };
	const search = useSearchComponent(setPageNumber, setShouldSearch);

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortByProperty) && { sortBy: sortByProperty }),
		orderDescending,
	};

	useEffect(() => {
		if (!fetchDockingLocationsLoading && (tab || shouldSearch || shouldSort)) {
			onFetchDockingLocations(page, filters);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		}
	}, [tab, shouldSearch, shouldSort, pageNumber, pageSize, order]);

	useEffect(() => {
		if (isObject(fetchDockingLocationsData) && !fetchDockingLocationsLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [fetchDockingLocationsLoading]);

	const handlePageChange = (number) => {
		setPageNumber(number);
	};

	const handleRequestSort = (property) => {
		const isDesc = sortByProperty === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortByProperty(property);
		setShouldSort(true);
	};

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
	};

	const handleBorderRouters = (borderRouter) => {
		if (borderRouter.length > 1) {
			return (
				<Box display='flex'>
					<MyTooltip
						arrow
						placement='top'
						title={borderRouter.map((router, index) => (
							<Box key={index} pb={1} pt={1}>
								<Typography className={classes.tableText}>{router}</Typography>
							</Box>
						))}
					>
						<Typography
							className={classes.detailTitle}
						>{`${borderRouter.length} ${t('ui.label.borderRouters')}`}</Typography>
					</MyTooltip>
				</Box>
			);
		} else if (borderRouter.length === 1) {
			return borderRouter[0];
		}
	};

	const handleLinkedLocations = (linkedLocations) => {
		if (linkedLocations.length > 1) {
			return (
				<Box display='flex'>
					<MyTooltip
						arrow
						placement='top'
						title={linkedLocations.map((location, index) => (
							<Box key={index} pb={1} pt={1}>
								<Typography className={classes.tableText}>{location.name}</Typography>
							</Box>
						))}
					>
						<Typography
							className={classes.detailTitle}
						>{`${linkedLocations.length} ${t('ui.label.linkedLocations')}`}</Typography>
					</MyTooltip>
				</Box>
			);
		} else if (linkedLocations.length === 1) {
			return linkedLocations[0].name;
		}
	};

	const tableHeaders =
		!loading && isPageEmpty ?
			[]
		:	[
				{ name: 'name', content: t('ui.label.name'), hasSorting: true },
				{ name: 'borderRouters', content: t('ui.label.borderRouters'), hasSorting: false },
				{ name: 'linkedLocations', content: t('ui.label.linkedLocations'), hasSorting: false },
			];

	const loadingBody =
		loading ?
			Array(5)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const tableBody =
		!loading && isPageFull ?
			fetchDockingLocationsData.results.map((dockingLocation) => {
				return [
					{ content: dockingLocation.name },
					{ content: handleBorderRouters(dockingLocation.deviceIds) },
					{ content: handleLinkedLocations(dockingLocation.locations) },
				];
			})
		:	null;

	const emptyBody =
		!loading && isPageEmpty ?
			[
				[
					{
						content: (
							<Paper className={classes.tipCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{' '}
									{t('views.maintenance.dockingLocations.emptyTable')}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	return (
		<>
			<SearchBar
				placeholder={t('ui.placeholders.search.generic')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			<Table
				body={loadingBody || emptyBody || tableBody}
				data={isPageFull ? fetchDockingLocationsData.results : []}
				handlePageChange={handlePageChange}
				handleSorting={handleRequestSort}
				header={tableHeaders}
				isNotPaginate={loading || isPageEmpty || !isPageFull}
				loading={fetchDockingLocationsLoading}
				order={order}
				orderBy={sortByProperty}
				page={pageNumber}
				rowsPerPage={pageSize}
				setRowsPerPage={handlePageSizeChange}
				title={t('views.devices.dockingLocations.table.title')}
				total={fetchDockingLocationsData ? fetchDockingLocationsData.total : 0}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchDockingLocations: state.paged.fetchDockingLocations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDockingLocations: (page, filters, concat) =>
			dispatch(actions.fetchDockingLocations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DockingLocations);
