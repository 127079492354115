import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { JSONSchemaType } from 'ajv';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { FormContainer, TextField, WeeklyScheduleForm } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './accessSchedulesSchema.json';
import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import { SkcScheduleNew } from '../../interfaces/skcSchedule';
import SkcSchedulesService from '../../services/skcSchedulesService';

const service = new SkcSchedulesService();

interface AccessSchedulesFormProps {
	id?: string;
	onSubmit?: (value: SkcScheduleNew) => void;
}

const AccessSchedulesForm = forwardRef<FormWrapperRefProps, AccessSchedulesFormProps>(
	({ id, onSubmit }, ref) => {
		const editMode = id != null;

		const { t } = useTranslation('general');
		const { isSuperAdmin } = useAuthorize();
		const userInfo = useAtomValue(userInfoAtom);
		const selectedOrganisation = useAtomValue(selectedOrganisationAtom);

		const { data, isLoading } = useSWRImmutable(
			id ? [service.basePath, id] : null,
			([_, args]) => service.getScheduleById(args),
			{
				revalidateOnMount: true,
			},
		);

		const { setDisabled } = useFormContainerState();
		// const { getValues, control, formState, reset } = useForm<SkcSchedule>({
		// 	defaultValues:
		// 		editMode ?
		// 			async () => service.getScheduleById(id)
		// 		:	{ organisation: isSuperAdmin() ? undefined : userInfo.organisation },
		// 	mode: 'onChange',
		// 	resolver: ajvResolver(schema as JSONSchemaType<any>),
		// });
		const { getValues, control, formState, reset } = useForm<SkcScheduleNew>({
			defaultValues: useMemo<Partial<SkcScheduleNew>>(
				() =>
					data ?? {
						label: '',
						description: '',
						organisation: isSuperAdmin() ? undefined : userInfo.organisation,
					},
				[data],
			),
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useEffect(() => {
			if (data) {
				// Used to reset the useform, otherwise the page won't properly reload
				reset({
					...data,
					organisation: selectedOrganisation,
				});
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<FormContainer loading={isLoading}>
				{isSuperAdmin() && !editMode && (
					<Controller
						name='organisation'
						control={control}
						render={({ field }) => (
							<OrganisationAutocomplete
								required
								value={field.value}
								onChange={(_, newValue, reason) => reason !== 'clear' && field.onChange(newValue)}
							/>
						)}
					/>
				)}
				<Controller
					name='label'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							label={t('ui.label.name')}
							required
							slotProps={{
								htmlInput: {
									minLength: schema.properties.label.minLength,
									maxLength: schema.properties.label.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='description'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							label={t('ui.label.description')}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.description.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='schedule'
					control={control}
					render={({ field }) => (
						<WeeklyScheduleForm
							value={field.value}
							onChange={(newValue) => field.onChange(newValue)}
						/>
					)}
				/>
			</FormContainer>
		);
	},
);
AccessSchedulesForm.displayName = 'AccessSchedulesForm';

export default AccessSchedulesForm;
