import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const PREFIX = 'Header';

const classes = {
	root: `${PREFIX}-root`,
};

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {},
}));

interface HeaderProps {
	className?: string;
}

const Header = (props: HeaderProps) => {
	const { className } = props;
	const { t } = useTranslation('general');

	return (
		<Root className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				{t('ui.organisationSetup')}
			</Typography>
			<Typography component='h1' variant='h2'>
				{t('views.register.organisation.steps.contactPerson')}
			</Typography>
		</Root>
	);
};

export default Header;
