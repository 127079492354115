import { useState, useEffect, useCallback } from 'react';

import { Wrapper } from '@googlemaps/react-wrapper';
import { Card, CardContent, Box, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { DragAndDropImageCropper } from '~components';
import { useDebounce } from '~hooks';

import { useStyles } from './style';
import {
	Tip,
	InteractiveMap,
	SearchAutocomplete,
	FormField,
	SelectedOrganisationCard,
	InfoLabel,
	AutocompleteInput,
} from '../../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../../shared/hooks';
import { isObject, isEmptyObject, isFullString, isNull } from '../../../../../../shared/utility';
import { validateInput } from '../../../../../../shared/validation';
import * as actions from '../../../../../../store/actions';

const descriptionLimits = {
	maxLength: 1000,
};
const render = (status) => {
	return <h1>{status}</h1>;
};

interface HubCardProps {
	className?: object;
	images?: unknown[];
	save?(...args: unknown[]): unknown;
	name?: string;
	itemDescription?: string;
	selectOptions?: unknown[];
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisations?(...args: unknown[]): unknown;
	placesAutocomplete?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	place?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPlacesAutocomplete?(...args: unknown[]): unknown;
	onFetchPlace?(...args: unknown[]): unknown;
	selectedOrganisation?: object;
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	searchValue?: string;
	setSearchValue?(...args: unknown[]): unknown;
}

const HubCard = (props: HubCardProps) => {
	const {
		images,
		save,
		onFetchPlacesAutocomplete,
		onFetchPlace,
		organisationsList,
		onFetchOrganisations,
		placesAutocomplete,
		place,
		selectedOrganisation: savedOrganisation,

		currentUser,

		searchValue,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const role = auth.user?.profile.role.toLowerCase();
	const classes = useStyles();
	const name = useWizardFormField('', { required: true, maxLength: 25 });
	useDebouncedWizardSave('name', name.value, name.isValid, save, 400);
	const [, setFileName] = useState(null);
	const { data: currentUserData } = currentUser;

	const [description, setDescription] = useState({
		value: '',
		valid: validateInput('', descriptionLimits),
		error: null,
		focus: false,
		touched: false,
	});

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);

	const debouncedSearchValue = useDebounce(searchValue, 500);
	const valeTest =
		isFullString(debouncedSearchValue) && debouncedSearchValue === debouncedSearchValue;
	const [coordinates, setCoordinates] = useState({
		lat: 52.00717,
		lng: 4.3673422,
	});

	const { data: placeData } = place;

	const { data: placesAutocompleteData, loading: placesAutocompleteLoading } = placesAutocomplete;

	const selectedOrganisation = useWizardFormField(savedOrganisation, { required: true });

	useEffect(() => {
		if (role === 'superadmin') {
			save({
				organisation: {
					value: selectedOrganisation.value,
					valid: selectedOrganisation.isValid,
				},
			});
		} else if (isObject(currentUserData) && role === 'admin') {
			save({
				organisation: {
					valid: true,
					value: currentUserData.organisationReference,
				},
			});
		}
	}, [selectedOrganisation.value, selectedOrganisation.isValid, currentUserData]);

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	const handleDescription = (event) => {
		event.preventDefault();
		const value = event.target.value;

		setDescription({
			...description,
			...{
				value: value,
				touched: true,
				valid: validateInput(value, descriptionLimits),
			},
		});
	};

	const finishStep = useCallback((data) => {
		save(data);
	}, []);

	/* * * * * * * *
	 * FETCH DATA  *
	 * * * * * * * */
	useEffect(() => {
		if (description.valid) {
			const data = {
				description: description.value,
				valid: name.valid && description.valid,
			};
			finishStep(data);
		}
	}, [name, description, images]);

	useEffect(() => {
		if (isObject(placeData)) {
			setCoordinates({
				lat: placeData.geometry.location.latitude,
				lng: placeData.geometry.location.longitude,
			});
			save({ placeData });
		}
	}, [placeData]);

	const handleUploadImage = (images) => {
		save({ hubImages: images });
	};

	const updateCoordinates = (coords) => {
		if (
			isObject(placeData) &&
			isObject(placeData.geometry) &&
			isObject(placeData.geometry.location) &&
			(placeData.geometry.location.latitude !== coords.lat ||
				placeData.geometry.location.longitude !== coords.lng)
		) {
			setCoordinates(coords);
			const updatedData = {
				...placeData,
				geometry: {
					...placeData.geometry,
					location: { latitude: coords.lat, longitude: coords.lng },
				},
			};

			save({ placeData: updatedData });
		}
	};

	const handleChangeInput = (event) => {
		save({ searchValue: event.target.value });
	};

	const handlehandleSelectedInput = (event, values) => {
		if (isObject(values)) {
			onFetchPlace(values.id);
		}
		if (isNull(values)) {
			save({ placeData: null });
		}
	};

	useEffect(() => {
		if (isFullString(debouncedSearchValue || valeTest)) {
			const token = isObject(placesAutocompleteData) ? placesAutocompleteData.sessionToken : null;
			onFetchPlacesAutocomplete(debouncedSearchValue, undefined, token);
		}
	}, [debouncedSearchValue, valeTest]);

	useEffect(() => {
		if (!open) {
			save({ searchValue: '' });
		}
	}, [open]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	useEffect(() => {
		if (isObject(placesAutocompleteData)) {
			setOptions(
				placesAutocompleteData.predictions.map((item) => ({ name: item.description, id: item.id })),
			);
		}
	}, [placesAutocompleteData]);

	return (
		<Card className={classes.root}>
			<CardContent>
				<InfoLabel
					info={t('views.addLocation.locationDetails.locationDescription.description')}
					isRequired
					name={t('views.addLocation.locationDetails.title')}
					paddingBottom={2}
					variant={'h3'}
				/>
				<Box pt={3}>
					<FormField label={t('ui.label.name')} maxLength={25} name='itemName' variable={name} />
					{role === 'superadmin' ?
						!isEmptyObject(selectedOrganisation.value) ?
							<Box pt={3}>
								<SelectedOrganisationCard
									handleClose={resetSelectedOrganisation}
									name={selectedOrganisation.value.name}
								/>
							</Box>
						:	<Box pt={3}>
								<SearchAutocomplete
									dataList={organisationsList}
									listType={'organisations'}
									onFetchData={onFetchOrganisations}
									placeholder={t('views.addPolicy.aboutPolicy.placeholder.searchOrganisation')}
									setSelected={selectedOrganisation.onChange}
								/>
							</Box>

					:	null}
					<form>
						<Box display='flex' justifyContent='space-between' pt={3}>
							<InfoLabel
								info={t('views.addLocation.locationDetails.description.subtitle')}
								isRequired
								name={t('ui.label.description')}
								variant={'h5'}
							/>
							<Typography variant='body1'>
								{description.value.length}/{descriptionLimits.maxLength} {t('ui.characters')}
							</Typography>
						</Box>
						<TextField
							className={classes.description}
							error={description.touched && !description.valid}
							fullWidth
							helperText={
								description.touched && !description.valid ? t('ui.error.message.validValue') : null
							}
							inputProps={{ ...descriptionLimits }}
							margin='normal'
							multiline
							onChange={handleDescription}
							placeholder={t(
								'views.addLocation.locationDetails.locationSection.placeholder.description',
							)}
							rows='4'
							value={description.value}
							variant='outlined'
						/>
					</form>
					<Box>
						<Tip
							arrowDirection={'top'}
							message={t('views.addLocation.locationDetails.tipDescription')}
						/>
					</Box>
					<Box pt={3}>
						<DragAndDropImageCropper
							src={images != null ? images[0]?.uri : null}
							onChange={(value) =>
								handleUploadImage(
									value ?
										[
											{
												uri: value.uri,
												name: value.file.name,
												file: value.file,
											},
										]
									:	[],
								)
							}
							slotProps={{
								dragAndDropField: {
									title: t('views.addLocation.locationDetails.image'),
									maxImageSizeInBytes: 1024000,
								},
								imageCropper: {
									aspect: 1.5,
								},
							}}
						/>
					</Box>
					<Box pb={3} pt={3}>
						<Tip arrowDirection={'top'} message={t('ui.imageTip.3by2')} />
					</Box>
				</Box>
				<Box>
					<Box>
						<Typography variant='h5'>
							{t('views.addLocation.locationDetails.locationSection.input.gps.title')}
						</Typography>
						<Typography variant='body1'>
							{t('views.addLocation.locationDetails.locationSection.input.gps.subtitle')}
						</Typography>
					</Box>
					<Box mt={3}>
						<Box mb={3}>
							<AutocompleteInput
								loading={placesAutocompleteLoading}
								loadingText={t('ui.loading')}
								noOptionsText={t('ui.noOprions')}
								onChange={handleChangeInput}
								onSelected={handlehandleSelectedInput}
								open={open}
								options={options}
								placeholder={t(
									'views.addLocation.locationDetails.locationSection.placeholder.locationAutocomplete',
								)}
								setOpen={setOpen}
							/>
						</Box>
						<Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} render={render}>
							<InteractiveMap
								callback={updateCoordinates}
								errorMessage={'errorMessage'}
								isImage
								myLocation={coordinates}
							/>
						</Wrapper>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,
		place: state.details.place,
		placesAutocomplete: state.details.placesAutocomplete,
		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchPlacesAutocomplete: (searchTerm, placeType, sessionToken, globalSearch) =>
			dispatch(actions.fetchPlacesAutocomplete(searchTerm, placeType, sessionToken, globalSearch)),
		onFetchPlace: (placeId) => dispatch(actions.fetchPlace(placeId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HubCard);
