import { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Link, Box, TextField, Typography, MenuItem } from '@mui/material';
import { getName } from 'country-list';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import OnHoldSwitch from './OnHoldSwitch';
import { useStyles, MyTooltip } from './style';
import {
	ActionDialog,
	SidepanelContainer,
	SidePanel,
	StyledButton,
	HeaderWithStatus,
} from '../../../../components';
import { languages } from '../../../../constantsOld';
import { localizeDateTime, commaTimeStrings } from '../../../../shared/datetime';
import { useError, useCloseTimeout } from '../../../../shared/hooks';
import { isFullString, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const handleDate = (value) => {
	return localizeDateTime(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

interface SidePanelCardProps {
	licenseDetails: object;
	onApproveUserLicense?(...args: unknown[]): unknown;
	onRejectUserLicense?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	onUserLicensesHold?(...args: unknown[]): unknown;
	languagePreferences?: string;
	onFetchActivities?(...args: unknown[]): unknown;
	userLicensesHold?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	approveUserLicense?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	rejectUserLicense?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const SidePanelCard = (props: SidePanelCardProps) => {
	const maxLength = 500;

	const {
		licenseDetails,
		approveUserLicense,
		rejectUserLicense,
		onApproveUserLicense,
		onRejectUserLicense,
		onResetState,
		onResetStateCondition,
		onUserLicensesHold,
		userLicensesHold,
		languagePreferences,
		onFetchActivities,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const classes = useStyles();

	const [onHold, setOnHold] = useState(licenseDetails?.isOnHold);

	const [rejectionReason, setRejectionReason] = useState('');
	const [comment, setComment] = useState('');

	const [commentLength, setCommentLength] = useState(0);
	const [commentError, setCommentError] = useState(false);

	const [selectedLanguage, setSelectedLanguage] = useState(languagePreferences);

	const {
		success: approveUserLicenseData,
		loading: approveUserLicenseLoading,
		error: approveUserLicenseError,
	} = approveUserLicense;
	const approveUserLicenseDone =
		approveUserLicenseData && !approveUserLicenseLoading && !approveUserLicenseError;
	const {
		success: rejectUserLicenseData,
		loading: rejectUserLicenseLoading,
		error: rejectUserLicenseError,
	} = rejectUserLicense;
	const rejectUserLicenseDone =
		rejectUserLicenseData && !rejectUserLicenseLoading && !rejectUserLicenseError;

	const { data: userLicensesHoldData, error: userLicensesHoldError } = userLicensesHold;

	useEffect(() => {
		if (approveUserLicenseDone || rejectUserLicenseDone) {
			if (approveUserLicenseDone) {
				onResetStateCondition('approveUserLicense', false);
				onResetState('licenseDetails');
			}
			if (rejectUserLicenseDone) {
				onResetStateCondition('rejectUserLicense', false);
				onResetState('licenseDetails');
			}
			navigate('/licenses/pending');
		}
	}, [approveUserLicense, rejectUserLicense]);

	useEffect(() => {
		if (userLicensesHoldData) {
			enqueueSnackbar(t(`licens.hold.${userLicensesHoldData.isOnHold}`), { variant: 'success' });
			onResetState('userLicensesHold');
			onFetchActivities();
		} else if (userLicensesHoldError) {
			onResetState('userLicensesHold');
			enqueueSnackbar('error', { variant: 'error' });
		}
	}, [userLicensesHoldData, userLicensesHoldError]);

	const approveUserLicenseMessage = useError({
		value: approveUserLicense,
		message: t('views.pendingLicenseDetails.approveSuccess'),
	});

	const rejectUserLicenseMessage = useError({
		value: rejectUserLicense,
		message: t('views.pendingLicenseDetails.rejectSuccess'),
	});

	const approveDialogAction = useCloseTimeout(
		isObject(approveUserLicense) ?
			{
				loading: approveUserLicenseLoading,
				error: approveUserLicenseError,
				actionDone: approveUserLicenseDone,
			}
		:	null,
	);

	const rejectDialogAction = useCloseTimeout(
		isObject(rejectUserLicense) ?
			{
				loading: rejectUserLicenseLoading,
				error: rejectUserLicenseError,
				actionDone: rejectUserLicenseDone,
			}
		:	null,
	);

	const handleComment = (event) => {
		if (event.target.value.length) {
			setCommentError(false);
		}
		setCommentLength(event.target.value.trim().length);
		setComment(event.target.value);
	};

	const handleCloseDialog = () => {
		setComment('');
		setSelectedLanguage(languagePreferences);
		setCommentLength(0);
		setCommentError(false);
		setRejectionReason('');
		if (approveDialogAction.action) {
			approveDialogAction.setAction(false);
		}
		if (rejectDialogAction.action) {
			rejectDialogAction.setAction(false);
		}
	};

	const handleApprove = () => {
		onApproveUserLicense(licenseDetails.userReference.id);
		approveUserLicenseMessage.setStartAction(true);
	};

	const handleReject = () => {
		if (commentLength) {
			onRejectUserLicense(licenseDetails.userReference.id, comment);
			rejectUserLicenseMessage.setStartAction(true);
		} else {
			setCommentError(true);
		}
	};

	const handleOnHold = (event) => {
		setOnHold(event.target.checked);
		const body = {
			value: event.target.checked,
		};
		onUserLicensesHold(licenseDetails.userReference.id, body);
	};

	const handleLanguageChange = (event) => {
		const language = languages.find((lan) => lan.language === event.target.value);
		const languageComment =
			rejectionReason !== 'other' && isFullString(comment) ?
				t(`views.licenses.rejectionDescription.${rejectionReason}`, { lng: language.code })
			:	'';
		setSelectedLanguage(language.code);
		setComment(languageComment);
		setCommentLength(languageComment?.length);
	};

	const selectLanguageProps = {
		InputLabelProps: { shrink: true },
		className: classes.languageSelector,
		fullWidth: true,
		onChange: handleLanguageChange,
		required: true,
		select: true,
		size: 'medium',
		variant: 'outlined',
		label: t('views.licenses.rejection.select.label.language'),
		SelectProps: {
			value: selectedLanguage,
			displayEmpty: true,
			renderValue: (value) => {
				const renLan = languages?.find((lan) => lan.code === value);
				return t(`ui.${renLan.language}`);
			},
		},
	};

	const options = [
		'missingLicense',
		'dateOfBirth',
		'unclearImages',
		'mismatchingNumber',
		'incorrectUser',
		'incorrectName',
		'other',
	];

	const handlePreFilled = (event) => {
		const value = event.target.value;
		const preFilledComment =
			value !== 'other' ?
				t(`views.licenses.rejectionDescription.${value}`, { lng: selectedLanguage })
			:	'';
		setRejectionReason(value);
		setCommentLength(preFilledComment?.length);
		setComment(preFilledComment);
	};

	const rejectionReasonSelectProps = {
		onChange: handlePreFilled,
		value: rejectionReason,
		InputLabelProps: { shrink: true },
		label: t('views.licenses.rejectionReason.selectOption'),
		select: true,
		size: 'medium',
		fullWidth: true,
		variant: 'outlined',
	};

	return (
		<SidepanelContainer className={classes.sideCardContainer} sticky={true}>
			<SidePanel className={classes.sideCard}>
				<Box display='flex' flexDirection='column' rowGap={2}>
					<Box>
						<Typography variant='h4'>
							{`${t('views.pendingLicenseDetails.request')} ${licenseDetails.id}`}
						</Typography>
						<Typography variant='body2'>
							{`${t('views.pendingLicenseDetails.lastUpdated')}: ${commaTimeStrings(licenseDetails.lastModifiedDate)}`}
						</Typography>
					</Box>
					{licenseDetails.status !== 'rejected' ?
						<Box>
							<OnHoldSwitch actions={handleOnHold} checked={onHold} label='On Hold' />
						</Box>
					:	null}
					<Box>
						<Typography variant='h6'>{t('views.licenses.table.dateOfBirth')}</Typography>
						<Typography variant='body2'>{handleDate(licenseDetails.dateOfBirth)}</Typography>
					</Box>
					<Box>
						<Typography variant='h6'>{t('views.licenses.table.licenseCountry')}</Typography>
						<Typography variant='body2'>{getName(licenseDetails.countryCode)}</Typography>
					</Box>
					<Box>
						<Typography variant='h6'>{t('views.licenses.table.licenseNumber')}</Typography>
						<Typography variant='body2'>{licenseDetails.licenseNumber}</Typography>
					</Box>
					{licenseDetails.isDuplicate && (
						<Box className={classes.duplicateCard} pt={4}>
							<Box ml={1}>
								<ErrorOutlineOutlinedIcon
									className={classes.duplicateIcon}
									color='error'
									fontSize='small'
								/>
							</Box>
							<Box display='flex' flexDirection='column'>
								<Typography className={classes.rdwLabel}>
									{t('views.pendingLicenseDetails.duplicateLicense')}{' '}
								</Typography>
								{licenseDetails?.duplicateUsers ?
									licenseDetails.duplicateUsers.map((duplicateUser) => (
										<Link
											className={classes.link}
											color='inherit'
											component={RouterLink}
											key={duplicateUser.id}
											to={`/user-management/users/${duplicateUser.id}/summary`}
											state={{ from: location.pathname }}
											variant='body1'
										>
											{duplicateUser.name}{' '}
										</Link>
									))
								:	null}
							</Box>
						</Box>
					)}
					<Box>
						<Typography variant='h6'>{t('views.licenses.table.expiryDate')}</Typography>
						<Typography variant='body2'>{handleDate(licenseDetails.expiryDate)}</Typography>
					</Box>
					{licenseDetails.validationStatus === 'validated' &&
						licenseDetails.countryCode.localeCompare('NL', 'en', { sensitivity: 'base' }) === 0 && (
							<Box className={classes.rdwCard}>
								<Box ml={1}>
									<CheckCircleOutlineIcon className={classes.rdwIcon} fontSize='small' />
								</Box>
								<Typography className={classes.rdwLabel}>
									{`${t('ui.checkedAndVerified')}, ${commaTimeStrings(licenseDetails.validationDate)}`}
								</Typography>
							</Box>
						)}
					{licenseDetails.verificationStatus === 'rejected' ?
						<Box className={classes.rejectedLabel}>
							<Box>
								<MyTooltip
									arrow
									title={<Typography>{licenseDetails?.rejectionMessage}</Typography>}
								>
									<CancelIcon className={classes.rdwIconRejected} color='error' fontSize='small' />
								</MyTooltip>
							</Box>
							<Typography className={classes.rdwLabelRejected}>
								{t('views.pendingLicenseDetails.rejected')}
							</Typography>
						</Box>
					:	null}
					{licenseDetails.status === 'pending' && (
						<>
							<StyledButton
								fullWidth
								onClick={() => approveDialogAction.setAction(true)}
								variant='contained-primary'
							>
								{t('ui.verify')}
							</StyledButton>
							<StyledButton
								fullWidth
								onClick={() => rejectDialogAction.setAction(true)}
								variant='contained-tertiary'
							>
								{t('ui.reject')}
							</StyledButton>
						</>
					)}
					<ActionDialog
						actionButtonProps={{
							action: handleApprove,
							text: t('ui.confirm'),
						}}
						handleClose={handleCloseDialog}
						loading={approveUserLicenseLoading && !approveUserLicenseDone}
						open={approveDialogAction.action}
						title={t('ui.label.confirmApprove')}
					>
						<Typography variant='body2'>{t('views.pendingLicenseDetails.approvalText')}</Typography>
					</ActionDialog>
					<ActionDialog
						actionButtonProps={{
							action: handleReject,
							text: t('ui.confirm'),
							fullWidth: true,
						}}
						handleClose={handleCloseDialog}
						loading={rejectUserLicenseLoading && !rejectUserLicenseDone}
						open={rejectDialogAction.action}
						title={t('ui.label.reasonForRejection')}
					>
						<Box className={classes.dialogCard}>
							<TextField {...rejectionReasonSelectProps}>
								{options.map((item) => (
									<MenuItem key={item} value={item}>
										{t(`views.licenses.rejectionReason.${item}`)}
									</MenuItem>
								))}
							</TextField>
							<TextField {...selectLanguageProps}>
								{languages?.map((ln) => (
									<MenuItem key={ln.code} value={ln.language}>
										{t(`ui.${ln.language}`)}{' '}
									</MenuItem>
								))}
							</TextField>
							<HeaderWithStatus
								header={`${t('ui.label.comment')}*`}
								headerSize='h5'
								justifyContent='space-between'
								subheader={t('views.pendingLicenseDetails.reject.subHeader')}
								subheaderSize='body2'
								text={`${commentLength}/${maxLength}`}
								variantText='body2'
							/>
							<TextField
								error={commentError}
								fullWidth
								inputProps={{ maxLength: maxLength }}
								multiline
								onChange={(e) => handleComment(e)}
								placeholder={t('ui.placeholder.description')}
								required
								rows={5}
								value={comment}
								variant='outlined'
							/>
						</Box>
					</ActionDialog>
				</Box>
			</SidePanel>
		</SidepanelContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		approveUserLicense: state.condition.approveUserLicense,
		rejectUserLicense: state.condition.rejectUserLicense,
		userLicensesHold: state.details.userLicensesHold,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onApproveUserLicense: (userId) => dispatch(actions.approveUserLicense(userId)),
		onRejectUserLicense: (userId, reasonForRejection) =>
			dispatch(actions.rejectUserLicense(userId, reasonForRejection)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUserLicensesHold: (userId, body) => dispatch(actions.userLicensesHold(userId, body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelCard);
