import { Modal, Card, CardContent, CardActions, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { FormField, StyledButton } from '../../../../../../../components';
import { useWizardFormField, useError } from '../../../../../../../shared/hooks';
import { isObject, isUndefined } from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';

interface EditContactInfoProps {
	className?: string;
	organisation?: any;
	onClose?(...args: unknown[]): unknown;
	open?: boolean;
	usersList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	//functions
	onFetchUsers(...args: unknown[]): unknown;
	onUpdateOrganisation?(...args: unknown[]): unknown;
	updateOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const EditContactInfo = ({
	open = false,
	onClose = () => {},
	organisation,
	className,

	onUpdateOrganisation,
	updateOrganisation,
}: EditContactInfoProps) => {
	const { t } = useTranslation('general');

	const classes = useStyles();
	const contactPerson = organisation.contactPerson;

	const firstName = useWizardFormField(isObject(contactPerson) ? contactPerson.firstName : '', {
		required: true,
		minLength: 3,
	});
	const lastName = useWizardFormField(isObject(contactPerson) ? contactPerson.lastName : '', {
		required: true,
		minLength: 3,
	});
	const email = useWizardFormField(isObject(contactPerson) ? contactPerson.emailAddress : '', {
		required: true,
		isEmail: true,
	});
	const phoneNumber = useWizardFormField(isObject(contactPerson) ? contactPerson.phoneNumber : '', {
		required: true,
	});

	const isChange =
		isObject(contactPerson) ?
			firstName.value !== contactPerson.firstName ||
			lastName.value !== contactPerson.lastName ||
			email.value !== contactPerson.emailAddress ||
			phoneNumber.value !== contactPerson.phoneNumber
		:	true;
	const formValid =
		isChange && firstName.isValid && lastName.isValid && email.isValid && phoneNumber.isValid;

	const errorHandle = useError(
		isObject(updateOrganisation) ?
			{
				value: updateOrganisation,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${organisation.name}`,
				variant: 'success',
			}
		:	null,
	);

	const organisationData = [
		{
			name: 'firstName',
			label: t('ui.label.firstName'),
			variable: firstName,
			placeholder: t('ui.label.firstName'),
			maxLength: 25,
			required: true,
		},
		{
			name: 'lastName',
			label: t('ui.label.lastName'),
			variable: lastName,
			placeholder: t('ui.label.lastName'),
			maxLength: 25,
			required: true,
		},
		{
			name: 'phoneNumber',
			label: t('ui.label.phoneNumber'),
			variable: phoneNumber,
			placeholder: t('ui.label.phoneNumber'),
			required: true,
		},
		{
			name: 'email',
			label: t('ui.label.email'),
			variable: email,
			placeholder: t('ui.label.email'),
			maxLength: 64,
			required: true,
		},
	];

	const handleSave = () => {
		onUpdateOrganisation(organisation.id, {
			name: organisation.name,
			legalName: !isUndefined(organisation.legalName) ? organisation.legalName : organisation.name,
			description: organisation.description,
			phoneNumber: organisation.phoneNumber,
			email: organisation.emailAddress,
			...(organisation.address && { address: organisation.address }),
			contactPerson: {
				email: email.value,
				firstName: firstName.value,
				lastName: lastName.value,
				phoneNumber: phoneNumber.value,
			},
			...(organisation.invoiceContact && {
				invoiceContact: {
					email: organisation.invoiceContact.emailAddress,
					name: organisation.invoiceContact.name,
				},
			}),
			...(organisation.administration && {
				administration: {
					...(!isUndefined(organisation.administration.vatNumber) && {
						vatNumber: organisation.administration.vatNumber,
					}),
					...(!isUndefined(organisation.administration.chamberOfCommerceNumber) && {
						chamberOfCommerceNumber: organisation.administration.chamberOfCommerceNumber,
					}),
					...(!isUndefined(organisation.administration.ibanNumber) && {
						ibanNumber: organisation.administration.ibanNumber,
					}),
					...(!isUndefined(organisation.administration.enterpriseNumber) && {
						enterpriseNumber: organisation.administration.enterpriseNumber,
					}),
				},
			}),
			subscriptionId: organisation.subscription.id,
		});
		onClose();
		errorHandle.setStartAction(true);
	};

	const handleClose = () => {
		resetData();
		onClose();
	};

	const resetData = () => {
		firstName.resetToInitialValue();
		lastName.resetToInitialValue();
		email.resetToInitialValue();
		phoneNumber.resetToInitialValue();
	};

	if (!open) {
		return null;
	}

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<form>
					<CardContent className={classes.cardContent}>
						<Typography align='center' className={classes.header} variant='h3'>
							{t('views.organisationDetail.summary.edit.titles.contact')}
						</Typography>
						{organisationData.map((org) => (
							<div className={classes.formGroup} key={org.name}>
								<FormField
									hideCharacterCounter={!org.variable.hasFocus}
									label={org.label}
									maxLength={org.maxLength}
									name={org.name}
									placeholder={org.placeholder}
									required={org.required}
									variable={org.variable}
								/>
							</div>
						))}
					</CardContent>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleClose} variant='contained-tertiary'>
							{t('ui.button.contained.close')}
						</StyledButton>
						<StyledButton
							className={classes.saveButton}
							disabled={!formValid}
							onClick={handleSave}
							variant='contained-secondary'
						>
							{t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		usersList: state.paged.users,
		updateOrganisation: state.details.updateOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUsers: (page, filters, concat) => dispatch(actions.fetchUsers(page, filters, concat)),
		onUpdateOrganisation: (orgId, body) => dispatch(actions.updateOrganisation(orgId, body)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContactInfo);
