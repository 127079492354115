import { useEffect } from 'react';

import path from 'path';

import { Button, ButtonGroup, Link as MuiLink, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { InfoCard, InfoCardRowProps, WeekToggleButtonGroup } from '~components';
import { pagePathSegments } from '~constants';
import { DayOfWeekEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { WeeklySchedule } from '~interfaces';
import { time24WithoutSecondsFormatter } from '~utils/dateUtils';

import SkcSchedulesService from '../../services/skcSchedulesService';

interface ScheduleGeneralInfoCardProps {
	id: string;
}

const service = new SkcSchedulesService();

const ScheduleGeneralInfoCard = ({ id }: ScheduleGeneralInfoCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const theme = useTheme();
	const location = useLocation();

	const { data, isLoading, error } = useSWR([service.basePath, id], ([_, args]) =>
		service.getScheduleById(args),
	);

	const rows: InfoCardRowProps[] | undefined = data && [
		{
			headerName: 'ID',
			value: data.id,
		},
		{
			headerName: t('ui.label.description'),
			value: data.description,
		},
		{
			headerName: t('ui.label.open'),
			value: data.schedule,
			valueFormatter: (value: WeeklySchedule) =>
				value.isOpenAllDay ?
					t('ui.allDay')
				:	`${time24WithoutSecondsFormatter.format(value.period.start)} - ${time24WithoutSecondsFormatter.format(value.period.end)}`,
		},
		{
			headerName: t('schedule'),
			value: data.schedule.daysOfWeek,
			renderCell: (value) => <WeekToggleButtonGroup disabled size='small' value={value} />,
		},
	];

	return (
		<InfoCard
			title={`${t('schedule')} ${t('info').toLowerCase()}`}
			rows={rows ?? []}
			loading={isLoading}
			error={error != null}
		/>
	);
};

export default ScheduleGeneralInfoCard;
