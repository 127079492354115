import { Box, Typography } from '@mui/material';

import { InfoLabel } from '../../../components';

interface FormFieldLabelProps {
	className?: string;
	label: string;
	info?: string;
	description?: string;
	children?: object;
	required?: boolean;
	variant?: string;
}

const FormFieldLabel = (props: FormFieldLabelProps) => {
	const { className, label, info, description, variant, children, required } = props;

	return (
		<>
			<Box className={className}>
				{(
					variant === 'info' ||
					variant === 'h1' ||
					variant === 'h2' ||
					variant === 'h3' ||
					variant === 'h4' ||
					variant === 'h5'
				) ?
					<InfoLabel
						info={info}
						isRequired={required}
						name={label}
						variant={variant === 'info' ? 'h5' : variant}
					/>
				:	<Typography variant='h5'>
						{label}
						{required ?
							<sup>*</sup>
						:	null}
					</Typography>
				}
				{description ?
					<Typography>{description}</Typography>
				:	null}
			</Box>
			{children}
		</>
	);
};

export default FormFieldLabel;
