import { useEffect, useState } from 'react';

import {
	Card,
	CardContent,
	Box,
	MenuItem,
	Typography,
	TextField,
	InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { CustomNumberField, FormField, Tip, StyledButton } from '../../../../../components';
import { useWizardFormField, useDebouncedDecimalSave } from '../../../../../shared/hooks';
import { isNull, isNumber, isObject, isUndefined } from '../../../../../shared/utility';
import palette from '../../../../../theme/palette';
import Header from '../Header';

const PREFIX = 'PricingUnits';

const classes = {
	root: `${PREFIX}-root`,
	unitLabel: `${PREFIX}-unitLabel`,
	header: `${PREFIX}-header`,
	perButtons: `${PREFIX}-perButtons`,
	selectedOption: `${PREFIX}-selectedOption`,
	unitPricing: `${PREFIX}-unitPricing`,
	unitPricingTextFields: `${PREFIX}-unitPricingTextFields`,
	tip: `${PREFIX}-tip`,
};

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.unitLabel}`]: {
		margin: theme.spacing(4.5, 0),
	},

	[`& .${classes.header}`]: {
		padding: theme.spacing(1.5, 0, 2, 0),
	},

	[`& .${classes.perButtons}`]: {
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(1),
		borderRadius: '18px',
		border: 'solid 1px #eeeeee',
		'& 	.MuiButton-label': {
			textTransform: 'none',
		},
	},

	[`& .${classes.selectedOption}`]: {
		backgroundColor: palette.info.light,
		color: palette.primary.main,
	},

	[`& .${classes.unitPricing}`]: {
		marginTop: theme.spacing(4.5),
	},

	[`& .${classes.unitPricingTextFields}`]: {
		margin: theme.spacing(3, 0),
	},

	[`& .${classes.tip}`]: {
		margin: theme.spacing(3, 0),
	},
}));

const currencies = [
	{ symbol: '€', label: 'euro', name: 'Euro' },
	// { symbol: '$', label: 'dollar', name: 'Dollar'},
];

interface PricingUnitsProps {
	className?: string;
	save?(...args: unknown[]): unknown;
	editing?: boolean;
	perMinuteValue?: object;
	perHourValue?: object;
	perDayValue?: object;
	perWeekValue?: object;
	fixedHourValue?: number;
	fixedDayValue?: number;
	fixedWeekValue?: number;
	currency?: unknown[];
	currencyValue?: string;
	selectedCurrencies?: object;
	selectedVATPercentage?: number | string;
	selectedPricingType?: string;
}

const PricingUnits = (props: PricingUnitsProps) => {
	const {
		className,
		save,
		editing,

		perMinuteValue,
		perHourValue,
		perDayValue,
		perWeekValue,
		fixedHourValue,
		fixedDayValue,
		fixedWeekValue,
		selectedVATPercentage: currentVATPercentage,
		selectedPricingType,
	} = props;
	const { t } = useTranslation('general');

	const [perMinute, setPerMinute] = useState(false);
	const [perHour, setPerHour] = useState(false);
	const [perDay, setPerDay] = useState(false);
	const [perWeek, setPerWeek] = useState(false);
	const [selectedVATPercentage, setSelectedVATPercentage] = useState(
		isNumber(currentVATPercentage) ? currentVATPercentage : '',
	);

	const handlePricingPerMinute = () => {
		handlePricingUnit(
			isNull(perMinuteValue) || !perMinuteValue.value,
			'minute',
			setPerMinute,
			t('ui.addPricing.perMinute'),
		);
	};
	const handlePricingPerHour = () => {
		handlePricingUnit(
			isNull(perHourValue) || !perHourValue.value,
			'hour',
			setPerHour,
			t('ui.addPricing.perHour'),
		);
	};
	const handlePricingPerDay = () => {
		handlePricingUnit(
			isNull(perDayValue) || !perDayValue.value,
			'day',
			setPerDay,
			t('ui.addPricing.perDay'),
		);
	};
	const handlePricingPerWeek = () => {
		handlePricingUnit(isNull(perWeekValue) || !perWeekValue.value, 'week', setPerWeek, 'Per week');
	};

	const handlePricingUnit = (value, name, update, label) => {
		update(value);
		save({ [name]: { value, name, label } });
	};

	const fixedPricePerHour = useWizardFormField(fixedHourValue, {
		isDecimal: true,
		required: true,
		minAmount: 0.01,
	});
	useDebouncedDecimalSave('fixedPricePerHour', fixedPricePerHour.value, save, 300);

	const fixedPricePerDay = useWizardFormField(fixedDayValue, {
		isDecimal: true,
		required: true,
		minAmount: 0.01,
	});
	useDebouncedDecimalSave('fixedPricePerDay', fixedPricePerDay.value, save, 300);

	const fixedPricePerWeek = useWizardFormField(fixedWeekValue, {
		isDecimal: true,
		required: true,
		minAmount: 0.01,
	});
	useDebouncedDecimalSave('fixedPricePerWeek', fixedPricePerWeek.value, save, 300);

	const fixedPricePerHourValue = parseFloat(fixedPricePerHour.value);
	const fixedPricePerDayValue = parseFloat(fixedPricePerDay.value);
	const fixedPricePerWeekValue = parseFloat(fixedPricePerWeek.value);

	const validFixedPricePerDay =
		fixedPricePerDay.isValid &&
		fixedPricePerDayValue > fixedPricePerHourValue &&
		fixedPricePerDayValue <= 24 * fixedPricePerHourValue;
	const validFixedPricePerWeek =
		fixedPricePerWeek.isValid &&
		fixedPricePerWeekValue > fixedPricePerDayValue &&
		fixedPricePerWeekValue > fixedPricePerHourValue &&
		fixedPricePerWeekValue <= 7 * fixedPricePerDayValue;

	const extraValidHelperTextDay =
		editing ? validFixedPricePerDay : (
			fixedPricePerWeek.isTouched && fixedPricePerHour.isTouched && validFixedPricePerDay
		);
	const extraValidHelperTextWeek =
		editing ? validFixedPricePerWeek : (
			fixedPricePerDay.isTouched && fixedPricePerHour.isTouched && validFixedPricePerWeek
		);

	const fixedPriceFields = [
		{ name: 'fixedPricePerHour', label: t('ui.label.hourFixedPrice'), value: fixedPricePerHour },
		{
			name: 'fixedPricePerDay',
			label: t('ui.label.dayFixedPrice'),
			value: fixedPricePerDay,
			isExtraValid: extraValidHelperTextDay,
			extraValidHelperText: t('views.addPricing.dayFixedPrice.error'),
		},
		{
			name: 'fixedPricePerWeek',
			label: t('ui.label.weekFixedPrice'),
			value: fixedPricePerWeek,
			isExtraValid: extraValidHelperTextWeek,
			extraValidHelperText: t('views.addPricing.weekFixedPrice.error'),
		},
	];
	const buttonGroup = [
		{
			id: 'perMinute',
			label: t('view.policymanagement.addpricing.button.contained.perminute'),
			action: handlePricingPerMinute,
			color: perMinute ? 'primary' : 'inherit',
			...(isObject(perMinuteValue) && { value: perMinuteValue.value }),
		},
		{
			id: 'perHour',
			label: t('view.policymanagement.addpricing.button.contained.perhour'),
			action: handlePricingPerHour,
			color: perHour ? 'primary' : 'inherit',
			...(isObject(perHourValue) && { value: perHourValue.value }),
		},
		{
			id: 'perDay',
			label: t('view.policymanagement.addpricing.button.contained.perday'),
			action: handlePricingPerDay,
			color: perDay ? 'primary' : 'inherit',
			...(isObject(perDayValue) && { value: perDayValue.value }),
		},
		{
			id: 'perWeek',
			label: t('view.policymanagement.addpricing.button.contained.perweek'),
			action: handlePricingPerWeek,
			color: perWeek ? 'primary' : 'inherit',
			...(isObject(perWeekValue) && { value: perWeekValue.value }),
		},
	];

	const VATPrices = [19, 21];

	useEffect(() => {
		save({ vatPercentage: selectedVATPercentage });
	}, [selectedVATPercentage]);

	useEffect(() => {
		save({
			fixedPricingValidation: validFixedPricePerDay && validFixedPricePerWeek,
		});
	}, [validFixedPricePerDay, validFixedPricePerWeek]);

	const handleCurrency = (event) => {
		const updatedCurrency = event.target.value;
		save({ currencies: updatedCurrency });
	};

	const handleVAT = (VATPrice) => {
		setSelectedVATPercentage(VATPrice.target.value);
	};

	return (
		<Root>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<Typography className={classes.header} variant='h4'>
						{t('views.addPricing.pricingUnits.header')}
					</Typography>
					<Typography
						className={classes.unitLabel}
						variant='h4'
					>{`${t('views.addPricing.VATRate')} (%)`}</Typography>
					<TextField
						InputLabelProps={{ shrink: true }}
						SelectProps={{
							value: selectedVATPercentage,
							defaultValue: isNumber(selectedVATPercentage) ? selectedVATPercentage : '',
						}}
						fullWidth
						label={`${t('ui.vat')}*`}
						onChange={handleVAT}
						select
						variant='outlined'
					>
						{VATPrices.map((VATPrice) => (
							<MenuItem key={VATPrice} value={VATPrice}>
								{`${VATPrice}`}
							</MenuItem>
						))}
					</TextField>
					<Typography className={classes.unitLabel} variant='h4'>
						{t('views.addPricing.pricingUnits.currency')}
					</Typography>
					<TextField
						InputLabelProps={{ shrink: true }}
						SelectProps={{ value: currencies[0] }}
						fullWidth
						label={t('views.addPricing.pricingUnits.currency')}
						onChange={handleCurrency}
						select
						variant='outlined'
					>
						{currencies.map((option) => (
							<MenuItem key={option.label} value={option}>
								{`${option.symbol} ${option.name}`}
							</MenuItem>
						))}
					</TextField>
					<Box>
						<Typography className={classes.unitPricing} variant='h4'>
							{t('views.addPricing.pricingUnits.unitLabel')}
						</Typography>
						{selectedPricingType === 'fixed' && (
							<Typography variant='body2'>{t('ui.label.unitPricingSubheading')}</Typography>
						)}
						<Tip
							arrowDirection={'top'}
							className={classes.tip}
							message={t('views.addPricing.pricingUnits.tip')}
						/>
						{selectedPricingType === 'fixed' ?
							<>
								{fixedPriceFields.map((priceField) => (
									<FormField
										InputProps={{
											inputComponent: CustomNumberField,
											startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
										}}
										className={classes.unitPricingTextFields}
										{...(!isUndefined(priceField.isExtraValid) && {
											isExtraValid: priceField.isExtraValid,
											extraValidHelperText: priceField.extraValidHelperText,
										})}
										key={priceField.name}
										label={priceField.label}
										name={priceField.name}
										required
										variable={priceField.value}
									/>
								))}
							</>
						:	buttonGroup.map((button) => (
								<StyledButton
									autoFocus={button.focus}
									className={clsx(classes.perButtons, {
										[classes.selectedOption]: button.value === true,
									})}
									color={button.color}
									key={button.id}
									onClick={button.action}
									variant='contained-tertiary'
								>
									{button.label}
								</StyledButton>
							))
						}
					</Box>
				</CardContent>
			</Card>
		</Root>
	);
};

export default PricingUnits;
