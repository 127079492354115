import { Fragment, useRef, useState, memo, forwardRef } from 'react';

import {
	ListItemIcon,
	ListItemText,
	Tooltip,
	Menu,
	MenuItem,
	Button,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';

const MyButton = forwardRef((props, ref) => {
	return <Button ref={ref} {...props} />;
});

interface MoreButtonProps {
	className?: string;
	menuItems: unknown[];
	startIcon?: any;
	text?: string;
	disabled?: boolean;
}

const MoreButton = (props: MoreButtonProps) => {
	const { className, menuItems, startIcon, text, disabled } = props;

	const classes = useStyles();

	const moreRef = useRef(null);

	const origin = { vertical: 'top', horizontal: 'left' };

	const [openMenu, setOpenMenu] = useState(false);

	const handleMenuOpen = () => setOpenMenu(true);

	const handleMenuClose = () => setOpenMenu(false);

	return (
		<Fragment>
			<Tooltip title='More options'>
				<MyButton
					className={className}
					disabled={disabled}
					onClick={handleMenuOpen}
					ref={moreRef}
					size='large'
					startIcon={
						disabled ? <CircularProgress color='info' disableShrink size={24} /> : startIcon
					}
					variant='outlined'
				>
					{text}
				</MyButton>
			</Tooltip>
			<Menu
				anchorEl={moreRef.current}
				anchorOrigin={origin}
				onClose={handleMenuClose}
				open={openMenu}
				transformOrigin={origin}
			>
				{menuItems.map((menuItem, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							menuItem.action();
							handleMenuClose();
						}}
					>
						<ListItemIcon className={clsx({ [classes.red]: menuItem.isRed })}>
							{menuItem.icon}
						</ListItemIcon>
						<ListItemText
							primary={menuItem.text}
							primaryTypographyProps={{ className: clsx({ [classes.red]: menuItem.isRed }) }}
						/>
					</MenuItem>
				))}
			</Menu>
		</Fragment>
	);
};

export default memo(MoreButton);
