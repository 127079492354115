import { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '~hooks';

import {
	isArray,
	handleLockType,
	isFullArray,
	isFullString,
	isEmptyString,
	isEmptyArray,
} from '../../../shared/utility';
import { useStyles } from '../style';

interface SearchProps {
	fetchData?(...args: unknown[]): unknown;
	data?: object;
	filters?: object;
	onChange?(...args: unknown[]): unknown;
	resetState?(...args: unknown[]): unknown;
}

const Search = (props: SearchProps) => {
	const { fetchData, data, filters, onChange, resetState } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (isFullString(debouncedSearchValue) && debouncedSearchValue === searchValue) {
			fetchData({
				...filters,
				requestedResources: 5,
				...(debouncedSearchValue && { searchTerm: debouncedSearchValue }),
			});
			setLoading(true);
		}
	}, [debouncedSearchValue]);

	useEffect(() => {
		if (isFullArray(options) || (isEmptyArray(options) && isEmptyArray(data.data))) {
			setOpen(true);
			setLoading(false);
		}
	}, [options]);

	useEffect(() => {
		if (isEmptyString(searchValue)) {
			setLoading(false);
			setOpen(false);
			setOptions([]);
			setSearchValue('');
			resetState();
		}
	}, [isEmptyString(searchValue)]);

	useEffect(() => {
		if (isArray(data?.data)) {
			setOptions(
				data.data.map((item) => ({
					...(item.itemId ?
						{
							...item,
							key: item.instanceId,
							optionsName: `${item.itemName} - ${item.instanceName}`,
						}
					:	{
							...item,
							key: item.deviceId,
							optionsName: `${handleLockType(item.deviceType)} - ${item.deviceId}`,
						}),
				})),
			);
		}
	}, [data]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
			setLoading(false);
		}
	}, [open]);

	const handleClose = () => {
		setOpen(false);
		setLoading(false);
	};

	const handleValue = (event) => {
		setSearchValue(event.target.value);
	};

	const handleMatch = (val, match) => isFullArray(val?.toLowerCase().match(match.toLowerCase()));

	const handleGetOptionLabel = (option) => {
		if (option?.deviceId && handleMatch(option.deviceId, searchValue)) {
			return option.deviceId;
		} else if (option?.instanceName && handleMatch(option.instanceName, searchValue)) {
			return option.instanceName;
		} else if (option?.itemName && handleMatch(option.itemName, searchValue)) {
			return option.itemName;
		} else if (!handleMatch(option.itemName, searchValue)) {
			return option.itemName;
		}
	};

	const optionEqualToValue = (option, value) => option.key === value.key;

	const handleRenderOption = (props, option) => (
		<li {...props} key={option.key}>
			{option.optionsName}
		</li>
	);

	const handleChange = (event, values) => {
		onChange(values);
		setOpen(false);
	};

	return (
		<Autocomplete
			className={classes.myTest}
			getOptionLabel={handleGetOptionLabel}
			isOptionEqualToValue={optionEqualToValue}
			loading={loading}
			onChange={handleChange}
			onClose={handleClose}
			open={open}
			options={options}
			popupIcon={<SearchIcon />}
			renderInput={(params) => (
				<TextField
					onChange={handleValue}
					{...params}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ?
									<CircularProgress color='inherit' size={20} />
								:	null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
					placeholder={t('ui.placeholders.search.generic')}
				/>
			)}
			renderOption={handleRenderOption}
			sx={{ width: 300, mr: 2 }}
		/>
	);
};

export default Search;
