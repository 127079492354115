import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface StyledButtonProps {
	children?: string | unknown[] | React.ReactNode;
	variant?:
		| 'contained-primary'
		| 'contained-secondary'
		| 'contained-tertiary'
		| 'contained-delete'
		| 'inline-default'
		| 'inline-delete';
}

const StyledButton = (props: StyledButtonProps) => {
	const { children, variant, ...rest } = props;
	const { t } = useTranslation('general');

	//override variant and color props when variant variable is present
	const buttonProps = {
		...rest,
		...(variant === 'contained-primary' && {
			variant: 'contained',
			color: 'primary',
		}),
		...(variant === 'contained-secondary' && {
			variant: 'contained',
			color: 'secondary',
		}),
		...(variant === 'contained-tertiary' && {
			variant: 'outlined',
			color: 'primary',
		}),
		...(variant === 'contained-delete' && {
			variant: 'outlined',
			color: 'secondary',
		}),
		...(variant === 'inline-default' && {
			variant: 'text',
			color: 'primary',
		}),
		...(variant === 'inline-delete' && {
			variant: 'text',
			color: 'secondary',
		}),
	};

	return <Button {...buttonProps}>{children}</Button>;
};

export default StyledButton;
