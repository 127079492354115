import { Tabs, Tab, Divider, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import Header from './Header';
import LicensesList from './LicensesList';
import { useStyles } from './style';
import { Page } from '../../components';

const Licenses = (props) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();

	const { tab } = useParams();

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'pending', label: t('views.licenses.tabs.pending') },
		{ value: 'rejected', label: t('views.licenses.tabs.rejected') },
	];

	if (!tab) {
		return <Navigate to={'/dashboard'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('nav.category.licenses')}>
			<Header />
			{isSuperAdmin() ?
				<Tabs
					className={classes.tabs}
					indicatorColor='primary'
					onChange={handleTabsChange}
					scrollButtons='auto'
					value={tab}
					variant='scrollable'
				>
					{tabs.map((tab) => (
						<Tab key={tab.value} label={tab.label} value={tab.value} />
					))}
				</Tabs>
			:	<Box className={classes.tabs}></Box>}
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'pending' && <LicensesList licenseType='pending' />}
				{tab === 'rejected' && <LicensesList licenseType='rejected' />}
			</div>
		</Page>
	);
};

export default Licenses;
