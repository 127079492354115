import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AddItemDrawer from './AddItemDrawer';
import Header from './Header';
import InstancesTable from './InstancesTable';
import SummaryTable from './SummaryTable';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { sharingTypes } from '../../ManagementConstants';
import { useStyles } from '../../style';

interface SharingDetailsProps {
	onInternalSharingInstances?(...args: unknown[]): unknown;
	onPublicSharingInstances?(...args: unknown[]): unknown;
	onExternalSharingInstances?(...args: unknown[]): unknown;
	onPublicItem?(...args: unknown[]): unknown;
	onExternalItem?(...args: unknown[]): unknown;
	onInternalItem?(...args: unknown[]): unknown;
	onUpdateListState?(...args: unknown[]): unknown;
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	internalSharingInstances?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	publicSharingInstances?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	externalSharingInstances?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	updatePublicShare?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	stopSharingExternalItems?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	unassignUserGroupItem?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	assignedItemsToUserGroup?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	assignedItemsToPartner?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	internalItem?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	publicItem?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	externalItem?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const SharingDetails = (props: SharingDetailsProps) => {
	const {
		match,
		onInternalSharingInstances,
		onPublicSharingInstances,
		onExternalSharingInstances,
		internalSharingInstances,
		publicSharingInstances,
		externalSharingInstances,
		updatePublicShare,
		onUpdateListState,
		stopSharingExternalItems,
		unassignUserGroupItem,
		assignedItemsToUserGroup,
		assignedItemsToPartner,
		onInternalItem,
		onPublicItem,
		onExternalItem,
		internalItem,
		publicItem,
		externalItem,
	} = props;
	const { t } = useTranslation('general');

	const { itemId, providerId, sharingType, id } = useParams();

	const location = useLocation();

	const { data: itemData } =
		sharingType === sharingTypes.INTERNAL ? internalItem
		: sharingType === sharingTypes.PUBLIC ? publicItem
		: externalItem;

	const itemDetails = location?.state?.itemDetails ? location.state.itemDetails : itemData;

	const [totalInstances, setTotalInstances] = useState(
		location?.state?.itemDetails?.totalInstances,
	);

	const [openItmeDrawer, setOpenItmeDrawer] = useState(false);

	const { data: instancesData, loading: instancesLoading } =
		sharingType === sharingTypes.INTERNAL ? internalSharingInstances
		: sharingType === sharingTypes.PUBLIC ? publicSharingInstances
		: externalSharingInstances;

	const classes = useStyles();

	const { data: updatePublicShareData } = updatePublicShare;

	const { data: stopSharingExternalItemsData } = stopSharingExternalItems;

	const { data: unassignUserGroupItemData } = unassignUserGroupItem;

	const { data: assignedItemsToUserGroupData } = assignedItemsToUserGroup;

	const { data: assignedItemsToPartnerData } = assignedItemsToPartner;

	useEffect(() => {
		if (
			sharingType === sharingTypes.INTERNAL &&
			!isObject(updatePublicShareData) &&
			!isObject(unassignUserGroupItemData) &&
			!isObject(assignedItemsToUserGroupData)
		) {
			onInternalSharingInstances(providerId, id, itemId);
			if (!isObject(itemDetails)) {
				onInternalItem(providerId, itemId);
			}
		} else if (sharingType === sharingTypes.PUBLIC && !isObject(updatePublicShareData)) {
			onPublicSharingInstances(providerId, itemId);
			if (!isObject(itemDetails)) {
				onPublicItem(providerId, itemId);
			}
		} else if (
			sharingType === sharingTypes.EXTERNAL &&
			!isObject(stopSharingExternalItemsData) &&
			!isObject(assignedItemsToPartnerData)
		) {
			onExternalSharingInstances(providerId, id, itemId);
			if (!isObject(itemDetails)) {
				onExternalItem(providerId, id, itemId);
			}
		}

		return () => {
			onUpdateListState('internalSharingInstances', { data: null, error: null, loading: false });
			onUpdateListState('publicSharingInstances', { data: null, error: null, loading: false });
			onUpdateListState('externalSharingInstances', { data: null, error: null, loading: false });
		};
	}, [
		providerId,
		assignedItemsToPartnerData,
		updatePublicShareData,
		stopSharingExternalItemsData,
		unassignUserGroupItemData,
		assignedItemsToUserGroupData,
	]);

	useEffect(() => {
		setTotalInstances(() => instancesData?.length);
	}, [instancesLoading]);

	const headerProps = {
		loading: !isObject(itemDetails),
		setOpenItmeDrawer,
		sharingType,
		itemDetails,
	};

	const summaryProps = {
		totalInstances,
		providerId: parseInt(providerId, 10),
		itemDetails,
		sharingType,
	};

	const instancesProps = {
		type: sharingType,
		instancesData,
		instancesLoading,

		...itemDetails,
	};

	const drawerProps = {
		providerId: parseInt(providerId, 10),
		openItmeDrawer,
		setOpenItmeDrawer,
		...itemDetails,
	};

	return (
		<Page className={classes.root} title={t('views.assets.page.title')}>
			<Header {...headerProps} />
			<div className={classes.content}>
				<SummaryTable {...summaryProps} />
				<InstancesTable {...instancesProps} />
				<AddItemDrawer {...drawerProps} />
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
		internalSharingInstances: state.list.internalSharingInstances,
		publicSharingInstances: state.list.publicSharingInstances,
		externalSharingInstances: state.list.externalSharingInstances,
		updatePublicShare: state.details.updatePublicShare,
		stopSharingExternalItems: state.details.stopSharingExternalItems,
		unassignUserGroupItem: state.details.unassignOrCancelItem,
		internalItem: state.details.internalItem,
		publicItem: state.details.publicItem,
		externalItem: state.details.externalItem,
		assignedItemsToUserGroup: state.details.assignedItemsToUserGroup,
		assignedItemsToPartner: state.details.assignedItemsToPartner,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onInternalSharingInstances: (providerId, userGroupId, itemId) =>
			dispatch(actions.internalSharingInstances(providerId, userGroupId, itemId)),
		onPublicSharingInstances: (providerId, itemId) =>
			dispatch(actions.publicSharingInstances(providerId, itemId)),
		onExternalSharingInstances: (providerId, consumerId, itemId) =>
			dispatch(actions.externalSharingInstances(providerId, consumerId, itemId)),
		onUpdateListState: (identifier, data) => dispatch(actions.updateListState(identifier, data)),
		onInternalItem: (providerId, itemId) => dispatch(actions.internalItem(providerId, itemId)),
		onPublicItem: (providerId, itemId) => dispatch(actions.publicItem(providerId, itemId)),
		onExternalItem: (providerId, consumerId, itemId) =>
			dispatch(actions.externalItem(providerId, consumerId, itemId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SharingDetails);
