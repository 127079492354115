import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { EmptyState, Table, AlertDialog, GenericMoreButton, SearchBar } from '../../../components';
import { useSearchComponent, usePagination, useComplexFilter } from '../../../shared/hooks';
import {
	isObject,
	isEmptyArray,
	isEmptyString,
	isFullString,
	isInteger,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface ItemPoliciesProps {
	onFetchPolicies?(...args: unknown[]): unknown;
	policies?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onDeletePolicy?(...args: unknown[]): unknown;
	deletePolicy?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisations?(...args: unknown[]): unknown;
}

const ItemPolicies = (props: ItemPoliciesProps) => {
	const {
		onFetchPolicies,
		policies,
		onDeletePolicy,
		deletePolicy,
		organisationsList,
		onFetchOrganisations,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [order, setOrder] = useState('asc');

	const [orderDescending, setOrderDescending] = useState(true);

	const [sortingBy, setSortingBy] = useState('');

	const [shouldFilter, setShouldFilter] = useState(false);

	// Dialog
	const [openDialog, setOpenDialog] = useState(false);
	const [policyId, setPolicyId] = useState(null);
	const [deletedPolicyIndex, setDeletedPolicyIndex] = useState(null);
	const [policyName, setPolicyName] = useState(null);

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deletePolicy;
	const deleteDone = deleteSuccess && !deleteLoading && !deleteError;
	const [deletingPolicy, setDeletingPolicy] = useState(false);

	const { data: policiesData, loading: policiesLoading, error: policiesError } = policies;
	const policiesReady = isObject(policiesData) && !policiesLoading && !policiesError;

	const { enqueueSnackbar } = useSnackbar();

	const pagination = usePagination();

	const policiesOrganisationFilter = useComplexFilter(
		'policiesOrganisationNameFilter',
		'policiesOrganisationIdFilter',
	);

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	const policiesFilters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingBy) && { sortBy: sortingBy }),
		...(policiesOrganisationFilter.valueId !== 'all' && {
			organisationId: policiesOrganisationFilter.valueId,
		}),
		orderDescending,
	};

	useEffect(() => {
		if (
			shouldFilter ||
			(shouldDoInitialFetch && !policiesLoading) ||
			pagination.fetch ||
			shouldSearch ||
			isFullString(sortingBy) ||
			!orderDescending ||
			(deleteDone && deletedPolicyIndex)
		) {
			onFetchPolicies(pagination.page, policiesFilters);
			setShouldDoInitialFetch(false);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (deleteDone && deletedPolicyIndex) {
			setDeletedPolicyIndex(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		deleteDone,
		deletedPolicyIndex,
		pagination,
		orderDescending,
		sortingBy,
		order,
		shouldSearch,
		shouldFilter,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShowingInitialResults]);

	useEffect(() => {
		if (deletingPolicy) {
			if (deleteDone) {
				setDeletingPolicy(false);
				enqueueSnackbar(`${t('ui.successfullyDeleted')} ${policyName}`, { variant: 'success' });
			} else if (deleteError) {
				setDeletingPolicy(false);
				enqueueSnackbar(isObject(deleteError) ? deleteError.message : deleteError, {
					variant: 'error',
				});
			}
		}
	}, [deletingPolicy, deleteDone, deleteError]);

	const handleSorting = (property) => {
		const isDesc = sortingBy === property && order === 'desc';
		setOrderDescending(!orderDescending);
		setOrder(isDesc ? 'asc' : 'desc');
		setSortingBy(property);
	};

	// Edit
	const handleEditPolicy = (data) => {
		navigate(`/policy/${data.id}/edit`, {
			state: { detail: false },
		});
	};

	// Delete policie
	const deleteSinglePolicie = (data) => {
		setPolicyId(data.id);
		setPolicyName(data.name);
		setOpenDialog(true);
	};

	const createMenuItems = (data) => [
		{
			icon: <EditIcon color='action' />,
			text: t('ui.button.inline.edit'),
			action: () => handleEditPolicy(data),
		},
		{
			icon: <DeleteIcon color='error' />,
			text: t('ui.button.inline.delete'),
			action: () => deleteSinglePolicie(data),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('view.policymanagement.itempolicy.button.contained.addpolicy'),
			onClick: () => navigate('/policy-management/item-policies/add'),
			variant: 'contained-primary',
		},
	];

	const handleCancelDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		if (policyId) {
			onDeletePolicy(policyId);
			setDeletingPolicy(true);
			setDeletedPolicyIndex(policyId);
		}
		setOpenDialog(false);
	};

	const handleOrganisationFilter = (item) => {
		if (item.id === policiesOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			policiesOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			policiesOrganisationFilter.setValueName('');
		}
		policiesOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleOrganisationFilter,
						searchHandle: policiesOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: policiesOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
	];

	const handleClearFilters = () => {
		policiesOrganisationFilter.setValueName('');
		policiesOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			policiesOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const tableHeader = [
		{ name: 'id', content: t('ui.label.id'), hasSorting: true },
		{ name: 'name', content: t('ui.label.name'), hasSorting: true },
		...(isSuperAdmin() ?
			[{ name: 'organisation', content: t('ui.label.organisation'), hasSorting: false }]
		:	[]),
		{ name: '', content: '' },
	];

	const tableBody =
		policiesReady ?
			policiesData.results.map((policy) => [
				{
					content: (
						<Link
							component={RouterLink}
							to={`/policy-management/item-policies/${policy.id}/summary`}
							state={{ from: location.pathname }}
						>
							{policy.id}
						</Link>
					),
				},
				{
					content: (
						<Link
							className={classes.itemGroup}
							component={RouterLink}
							to={`/policy-management/item-policies/${policy.id}/summary`}
							state={{ from: location.pathname }}
						>
							{policy.name}
						</Link>
					),
				},
				...(isSuperAdmin() ? [{ content: policy.organisationReference.name }] : []),
				{
					content: (
						<Box display='flex' flexDirection='row-reverse'>
							<Box pl={5.5}>
								<GenericMoreButton menuItems={createMenuItems(policy)} />
							</Box>
						</Box>
					),
				},
			])
		:	Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const itemsListEmpty =
		isObject(policiesData) && !policiesLoading && isEmptyArray(policiesData.results) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/policy-management/item-policies/add'}
					callToActionText={t('view.policymanagement.itempolicy.button.contained.addpolicy')}
					image={'booking'}
					subTitle={t('views.policies.itemPolicies.empty.zeroInSystem')}
					title={t('views.policies.itemPolicies.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/policy-management/item-policies/add'}
					callToActionText={t('view.policymanagement.itempolicy.button.contained.addpolicy')}
					image={'booking'}
					subTitle={t('views.policies.itemPolicies.empty.zeroMatching')}
					title={t('views.policies.itemPolicies.empty.title')}
				/>
		:	null;

	return (
		<div className={classes.root}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter={true}
				mainFilters={mainFilters}
				placeholder={t('views.assets.items.placeholder.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{!itemsListEmpty ?
				<Table
					body={tableBody}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleSorting}
					header={tableHeader}
					loading={policiesLoading}
					order={order}
					orderBy={sortingBy}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.policies.itemPolicies.table.title')}
					total={policiesData ? policiesData.total : 0}
				/>
			:	itemsListEmpty}
			<AlertDialog
				dialogDescription={`${t('ui.deleted.dialogDescription')} ${policyName} ${t('ui.permanently')}`}
				dialogTitle={t('ui.delete')}
				handleClose={handleCancelDialog}
				handleConfirm={handleConfirmDialog}
				loading={deleteLoading && !deleteDone}
				open={openDialog}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		policies: state.paged.policies,
		deletePolicy: state.condition.deletePolicy,

		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicies: (page, filters) => dispatch(actions.fetchPolicies(page, filters)),
		onDeletePolicy: (id) => dispatch(actions.deletePolicy(id)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPolicies);
