import { useState } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactDatePicker, FormFieldLabel } from '../../../../../../components';
import {
	isEarlierThan,
	sameDates,
	getDateRoundedToDuration,
	modifyDate,
	isDateToday,
	unsetTime,
} from '../../../../../../shared/datetime';
import { isNull } from '../../../../../../shared/utility';
import { useStyles } from '../../style';

interface DateTimeCardProps {
	selectedStartDate?: object;
	selectedEndDate?: object;
	save?(...args: unknown[]): unknown;
}

const DateTimeCard = (props: DateTimeCardProps) => {
	const { selectedStartDate, selectedEndDate, save } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [minDuration] = useState(15);

	const updateStartDate = (date) => {
		save({ updatedStartDate: date });
	};

	const updateEndDate = (date) => {
		save({ updatedEndDate: date });
	};

	return (
		<Box>
			<FormFieldLabel
				className={classes.inputLabelSpacing}
				info={t('views.contract.add.date&time.info')}
				label={t('ui.label.date&time')}
				variant='h4'
			/>
			<Box display='flex' justifyContent='space-between'>
				<ReactDatePicker
					containerClassName={classes.dateTimePicker}
					label={t('views.planboard.addBooking.placeholder.startDate')}
					maxTime={modifyDate(new Date(), { hours: 23, minutes: 45 })}
					minDate={new Date()}
					minTime={
						isNull(selectedStartDate) || isDateToday(selectedStartDate) ?
							getDateRoundedToDuration(new Date(), 'PT15M')
						:	modifyDate(new Date(), { hours: 0, minutes: 0 })
					}
					onChange={(date) =>
						updateStartDate(
							isDateToday(date) && isEarlierThan(date) ?
								getDateRoundedToDuration(new Date(), 'PT15M')
							:	date,
						)
					}
					selected={selectedStartDate}
					showTimeSelect
					wrapperClassName={classes.fullWidth}
				/>
				<ReactDatePicker
					containerClassName={classes.dateTimePicker}
					label={t('views.planboard.addBooking.placeholder.endDate')}
					maxTime={modifyDate(new Date(), { hours: 23, minutes: 45 })}
					minDate={new Date()}
					minTime={
						isEarlierThan(new Date(selectedStartDate)) ?
							(
								!isEarlierThan(
									modifyDate(new Date(selectedStartDate), { minutes: `+${minDuration}` }),
								)
							) ?
								modifyDate(new Date(selectedStartDate), { minutes: `+${minDuration}` })
							:	getDateRoundedToDuration(new Date(), 'PT15M')
						: isNull(selectedStartDate) ?
							modifyDate(getDateRoundedToDuration(new Date(), 'PT15M'), {
								minutes: `+${minDuration}`,
							})
						: (
							isNull(selectedEndDate) ||
							sameDates(unsetTime(selectedStartDate), unsetTime(selectedEndDate))
						) ?
							modifyDate(selectedStartDate, { minutes: `+${minDuration}` })
						:	modifyDate(new Date(), { hours: 0, minutes: 0 })
					}
					onChange={(date) => updateEndDate(date)}
					selected={selectedEndDate}
					showTimeSelect
					wrapperClassName={classes.fullWidth}
				/>
			</Box>
		</Box>
	);
};

export default DateTimeCard;
