import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Info as InfoIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { AsyncTextField, FormContainer, TextField, TransferList } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { Device, SkcDevicesService } from '~features/devices';
import { Organisation, OrganisationAutocomplete } from '~features/organisations';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './skcDeviceGroupSchema.json';
import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import { SkcDeviceGroupNew } from '../../interfaces/skcDeviceGroup';
import SkcDeviceGroupsService from '../../services/skcDeviceGroupsService';

const deviceGroupsService = new SkcDeviceGroupsService();
const devicesService = new SkcDevicesService();

interface SkcDeviceGroupFormProps {
	id?: string;
	onSubmit?: (value: SkcDeviceGroupNew) => void;
}

const SkcDeviceGroupForm = forwardRef<FormWrapperRefProps, SkcDeviceGroupFormProps>(
	({ id, onSubmit }, ref) => {
		const { t } = useTranslation('general');
		const userInfo = useAtomValue(userInfoAtom);
		const selectedOrganisation = useAtomValue(selectedOrganisationAtom);
		const { isSuperAdmin } = useAuthorize();

		const editMode: boolean = id != null;

		const [totalLeftCount, setTotalLeftCount] = useState<number>(0);
		const [rightPage, setRightPage] = useState(1);
		const [rowsPerPage, setRowsPerPage] = useState(5);

		const [availableLeftList, setAvailableLeftList] = useState([]);

		const { data: deviceGroupData, isLoading: isDeviceGroupLoading } = useSWRImmutable(
			id ? [deviceGroupsService.basePath, id] : null,
			([_, args]) => deviceGroupsService.getDeviceGroupById(args),
			{
				revalidateOnMount: true,
			},
		);

		const { setDisabled } = useFormContainerState();
		const { getValues, control, formState, reset, watch, setValue } = useForm<SkcDeviceGroupNew>({
			defaultValues: useMemo<Partial<SkcDeviceGroupNew>>(
				() =>
					deviceGroupData ?? {
						organisation: isSuperAdmin() ? undefined : userInfo.organisation,
						label: '',
						description: '',
						devices: [],
					},
				[deviceGroupData],
			),
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		const watchOrganisation = watch('organisation');

		const [searchQuery, setSearchQuery] = useState<string>('');
		const [devicesPagination, setDevicePagination] = useState({
			page: 1,
			pageSize: rowsPerPage,
		});
		const fetchParameters = useMemo(
			() => ({
				...devicesPagination,
				organisationId: watchOrganisation?.id,
				searchQuery: searchQuery,
			}),
			[watchOrganisation, devicesPagination, searchQuery],
		);

		const {
			data: devicesData,
			isLoading: isDevicesLoading,
			isValidating: isDevicesValidating,
		} = useSWR(
			[devicesService.basePath, fetchParameters],
			([_, args]) => devicesService.getDevices(args),
			{
				onSuccess: (res) => setTotalLeftCount(res.total),
			},
		);

		useEffect(() => {
			if (deviceGroupData) {
				// Used to reset the useform, otherwise the page won't properly reload
				reset({
					...deviceGroupData,
					organisation: selectedOrganisation,
				});
			}
		}, [deviceGroupData]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		useEffect(() => {
			if (!devicesData) {
				setAvailableLeftList([]);
				return;
			}

			determineAvailableLeftItems();
		}, [devicesData, deviceGroupData]);

		const mapToTransferListItem = (element: Device) => ({
			key: element.id,
			primary: element.skopeiNumber,
			secondary: `${element.id} - ${element.hardwareId}`,
			value: element,
		});

		const handleRowsPerPageChange = (val) => {
			setRowsPerPage(val);
			setDevicePagination((prev) => ({ ...prev, pageSize: val }));
		};

		const handleTransferListChange = (val, onChange: () => void) => {
			const unique: Device[] = [
				...new Map(val.rightItems.map((el) => [el?.key, el.value])).values(),
			];

			onChange(unique);

			determineAvailableLeftItems();
		};

		const determineAvailableLeftItems = () => {
			if (!devicesData) {
				return;
			}

			const chosenArray = getValues().devices?.map((el) => el.id) ?? [];
			const mapped = devicesData?.results.map((el) => ({
				...mapToTransferListItem(el),
				disabled: chosenArray.includes(el.id),
			}));
			setAvailableLeftList(mapped);
		};

		const getRightList = (value) => {
			const items = value?.map((el) => mapToTransferListItem(el)) ?? [];
			return items;
		};

		/**
		 * Do this through a useEffect? A bit more stable if we change
		 * the organisation programmatically
		 * @param newOrg
		 * @param onChange
		 */
		const handleOrgansationChange = (newOrg: Organisation, onChange: (...event: any[]) => void) => {
			const previousOrg: Organisation | undefined = getValues('organisation');
			if (previousOrg?.id !== newOrg.id && !editMode) {
				setValue('devices', []);
			}

			onChange(newOrg);
		};

		return (
			<FormContainer loading={isDeviceGroupLoading}>
				{isSuperAdmin() && !editMode && (
					<Controller
						name='organisation'
						control={control}
						render={({ field }) => (
							<OrganisationAutocomplete
								value={field.value}
								onChange={(_, newValue, reason) =>
									reason !== 'clear' && handleOrgansationChange(newValue, field.onChange)
								}
								required
							/>
						)}
					/>
				)}
				<Controller
					name='label'
					control={control}
					render={({ field }) => (
						<TextField
							label={t('ui.label.name')}
							value={field.value}
							onChange={field.onChange}
							required
							slotProps={{
								htmlInput: {
									minLength: schema.properties.label.minLength,
									maxLength: schema.properties.label.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='description'
					control={control}
					render={({ field }) => (
						<TextField
							value={field.value}
							onChange={field.onChange}
							label={t('ui.label.description')}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.description.maxLength,
								},
							}}
						/>
					)}
				/>
				{watchOrganisation == null && isSuperAdmin() ?
					<Stack
						direction='row'
						spacing={1}
						sx={{ pt: '2rem', justifyContent: 'center', alignItems: 'center' }}
					>
						<InfoIcon />
						<Typography>Select an organisation before adding devices</Typography>
					</Stack>
				:	<>
						{false && (
							<Stack
								direction='row'
								spacing={1}
								sx={{
									alignItems: 'center',
								}}
							>
								<AsyncTextField
									manual
									placeholder={t('ui.search')}
									defaultValue={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</Stack>
						)}
						<Controller
							name='devices'
							control={control}
							render={({ field }) => (
								<TransferList
									leftTitle={t('availableResource', { resource: t('devices') })}
									leftItems={availableLeftList}
									leftItemsCount={totalLeftCount}
									leftLoading={isDevicesLoading || isDevicesValidating}
									leftPage={devicesPagination.page}
									onLeftPageChange={(val) =>
										setDevicePagination((prev) => ({ ...prev, page: val }))
									}
									rowsPerPage={rowsPerPage}
									rightTitle={t('selectedResource', { resource: t(`devices`) })}
									rightItems={getRightList(field.value)}
									rightLoading={isDeviceGroupLoading}
									rightPage={rightPage}
									onRightPageChange={(val) => setRightPage(val)}
									onChange={(val) => handleTransferListChange(val, field.onChange)}
									onRowsPerPageChange={handleRowsPerPageChange}
								/>
							)}
						/>
					</>
				}
			</FormContainer>
		);
	},
);
SkcDeviceGroupForm.displayName = 'SkcDeviceGroupForm';

export default SkcDeviceGroupForm;
