import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';

import { useStyles } from './style';

interface CustomExpansionPanelProps {
	title?: string;
	children?: React.ReactNode;
}

const CustomExpansionPanel = (props: CustomExpansionPanelProps) => {
	const { title, children } = props;
	const classes = useStyles();

	return (
		<Accordion className={classes.expansionPanel} defaultExpanded elevation={0} square>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant='h5'>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};

export default CustomExpansionPanel;
