import { Box, Typography } from '@mui/material';

interface ItemHeaderProps {
	padding?: number;
	variant?: string;
	subheader?: string;
	header?: string;
	variantText?: string;
}

const ItemHeader = (props: ItemHeaderProps) => {
	const { padding, variant, header, subheader, variantText } = props;

	return (
		<Box display='flex' flexDirection='column' pb={padding}>
			<Box pb={0.5}>
				<Typography variant={variant}>{header}</Typography>
			</Box>
			<Typography variant={variantText}>{subheader}</Typography>
		</Box>
	);
};

export default ItemHeader;
