import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import EditAddress from './EditAddress';
import { useStyles } from './style';
import { EmptyState, StyledButton } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';

interface AddressProps {
	className?: string;
	organisation: object;
}

const Address = (props: AddressProps) => {
	const { organisation, className } = props;
	const { t } = useTranslation('general');

	const [openEdit, setOpenEdit] = useState(false);
	const classes = useStyles();

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const address = isObject(organisation.address) ? organisation.address : null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.label.address')} />
			<Divider />
			<CardContent className={classes.content}>
				{address ?
					<Table>
						<TableBody>
							<TableRow className={classes.tableRow}>
								<TableCell>{t('ui.label.address')}</TableCell>
								<TableCell>
									{address ?
										`${address.street} ${address.number}${address.addition ? ` ${address.addition}` : ''}`
									:	'-'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.postalCode')}</TableCell>
								<TableCell>{address ? address.postalCode : '-'}</TableCell>
							</TableRow>
							<TableRow className={classes.tableRow}>
								<TableCell>{t('ui.label.city')}</TableCell>
								<TableCell>{address ? address.city : '-'}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.country')}</TableCell>
								<TableCell>{address ? address.country : '-'}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	<Box padding={2}>
						<EmptyState
							image={'booking'}
							subTitle={t('views.organisationDetail.summary.contact.missing.description')}
							title={t('views.organisationDetail.summary.contact.missing.title')}
						/>
					</Box>
				}
			</CardContent>
			{organisation.isVerified ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.edit')}
						</StyledButton>
					</CardActions>
					<EditAddress onClose={handleEditClose} open={openEdit} organisation={organisation} />
				</>
			:	null}
		</Card>
	);
};

export default Address;
