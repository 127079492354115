import { useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import StopIcon from '@mui/icons-material/Stop';
import { Typography, Grid, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import ExtendCard from './ExtendCard';
import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton, ActionDialog } from '../../../../components';
import { useDetailPageBackButton } from '../../../../shared/hooks';
import { isObject, isUndefined } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

interface HeaderProps {
	className?: string;
	contractData?: object;
	loading?: boolean;
	onResetStateCondition?(...args: unknown[]): unknown;
	onTerminateContract?(...args: unknown[]): unknown;
	onExtendContract?(...args: unknown[]): unknown;
	onRejectContract?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onDeleteContract?(...args: unknown[]): unknown;
	deleteContract?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	extendContract?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	rejectContract?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	terminateContract?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Header = (props: HeaderProps) => {
	const {
		className,
		contractData,
		loading,
		onDeleteContract,
		deleteContract,
		onResetStateCondition,
		currentUser,
		onTerminateContract,
		terminateContract,
		onUpdateDetailsState,
		onResetState,
		onExtendContract,
		extendContract,
		onRejectContract,
		rejectContract,
	} = props;

	const { t } = useTranslation('general');
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const {
		success: deleteContractSuccess,
		loading: deleteContractLoading,
		error: deleteContractError,
	} = deleteContract;

	const {
		data: terminateContractData,
		loading: terminateContractLoading,
		error: terminateContractError,
	} = terminateContract;

	const {
		success: extendContractSuccess,
		loading: extendContractLoading,
		error: extendContractError,
	} = extendContract;

	const {
		data: rejectContractData,
		loading: rejectContractLoading,
		error: rejectContractError,
	} = rejectContract;

	const { data: currentUserData } = currentUser;

	const isProvider =
		currentUserData?.organisationReference?.id ===
			contractData?.itemInstance?.hub?.organisation?.id || isSuperAdmin();

	const activeOvertime = ['active', 'expired'].includes(contractData?.status);

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const classes = useStyles();

	const [selectedEndDate, setSelectedEndDate] = useState(null);
	const [switchTimeSelect, setSwitchTimeSelect] = useState(false);

	const [openDeleteContract, setOpenDeleteContract] = useState(false);
	const [openTerminateContract, setOpenTerminateContract] = useState(false);
	const [openExtendContract, setOpenExtendContract] = useState(false);
	const [openRejectContract, setOpenRejectContract] = useState(false);

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => {
		const url = '/contracts/contracts';
		if (location.state && location.state.from) {
			//eslint-disable-line
			navigate(location.state.from);
		} else if (!(!!location?.state && !!location?.state.showBackButton)) {
			navigate(url);
		} else {
			navigate(-1);
		}
	};

	const extendCardProps = {
		description: 'views.contract.details.description.extend',

		classes,
		end: contractData?.end,
		selectedEndDate,
		setSelectedEndDate,
		terminateCard: false,
		extend: openExtendContract,
	};

	const terminateCardProps = {
		description: 'views.contract.details.description.terminate',

		classes,
		end: contractData?.status === 'expired' ? new Date() : new Date(contractData?.end),
		selectedEndDate,
		setSelectedEndDate,
		switchTimeSelect,
		setSwitchTimeSelect,
		terminateCard: true,
		start: new Date(contractData?.start),
	};

	const isNewTime =
		contractData?.end &&
		selectedEndDate?.toISOString() === new Date(contractData.end).toISOString();

	useEffect(() => {
		if (deleteContractSuccess) {
			enqueueSnackbar(`${t('views.contract.details.deleteSuccess.message')} ${contractData.name}`, {
				variant: 'success',
			});
			onResetStateCondition('deleteContract', false);
			handleCloseDialog();
			navigate(location.state.from);
		} else if (isObject(deleteContractError)) {
			onResetStateCondition('deleteContract', false);
			enqueueSnackbar(deleteContractError.message, { variant: 'error' });
		} else if (isObject(terminateContractData)) {
			enqueueSnackbar(
				`${t('views.contract.details.terminateSuccess.message')} ${contractData.name}`,
				{ variant: 'success' },
			);
			onUpdateDetailsState('contract', terminateContractData);
			onResetState('terminateContract');
			handleCloseDialog();
		} else if (isObject(terminateContractError)) {
			enqueueSnackbar(terminateContractError.message, { variant: 'error' });
			onResetState('terminateContract');
		} else if (extendContractSuccess) {
			enqueueSnackbar(`${t('views.contract.details.extendSuccess.message')} ${contractData.name}`, {
				variant: 'success',
			});
			onResetStateCondition('extendContract', false);
			handleCloseDialog();
			navigate(location.state.from);
		} else if (extendContractError) {
			onResetStateCondition('extendContract', false);
			enqueueSnackbar(extendContractError.message, { variant: 'error' });
		} else if (isObject(rejectContractData)) {
			enqueueSnackbar(`${t('views.contract.details.rejectSuccess.message')} ${contractData.name}`, {
				variant: 'success',
			});
			onResetState('rejectContract');
			navigate(location.state.from);
		} else if (rejectContractError) {
			onResetState('rejectContract');
			enqueueSnackbar(rejectContractError.message, { variant: 'error' });
		}
	}, [deleteContract, terminateContract, extendContract, rejectContract]);

	useEffect(() => {
		if (contractData?.end && !openTerminateContract) {
			setSelectedEndDate(new Date(contractData.end));
		} else if (openTerminateContract) {
			setSelectedEndDate(new Date());
		}
	}, [contractData, openExtendContract, openTerminateContract]);

	const handleOpenDeleteDialog = () => setOpenDeleteContract(true);
	const handleOpenTerminateDialog = () => setOpenTerminateContract(true);
	const handleOpenExtendContract = () => setOpenExtendContract(true);
	const handleRejectContract = () => setOpenRejectContract(true);

	const handleCloseDialog = () => {
		if (openDeleteContract) {
			setOpenDeleteContract(false);
		} else if (openTerminateContract) {
			setOpenTerminateContract(false);
			setSwitchTimeSelect(false);
			setSelectedEndDate(null);
		} else if (openExtendContract) {
			setOpenExtendContract(false);
			setSelectedEndDate(null);
		} else if (openRejectContract) {
			setOpenRejectContract(false);
		}
	};

	const handleConfirmContract = () => {
		if (openDeleteContract) {
			onDeleteContract(contractData.id);
		} else if (openTerminateContract) {
			onTerminateContract(
				contractData.id,
				switchTimeSelect ? { intendedTerminated: selectedEndDate } : null,
			);
		} else if (openExtendContract) {
			onExtendContract(contractData.id, { value: selectedEndDate });
		} else if (openRejectContract) {
			onRejectContract(contractData.id);
		}
	};

	const buttons = [
		{
			name: 'views.contract.details.extend',
			variant: 'inline-default',
			icon: <ReceiptOutlinedIcon />,
			action: handleOpenExtendContract,
			isVisible: contractData?.end && activeOvertime && isProvider,
		},
		{
			name: 'views.contract.details.terminate',
			variant: 'inline-delete',
			icon: <StopIcon />,
			action: handleOpenTerminateDialog,
			isVisible: activeOvertime && isProvider,
		},
		{
			name: 'views.contract.details.delete',
			variant: 'inline-delete',
			icon: <DeleteIcon />,
			action: handleOpenDeleteDialog,
			isVisible: isProvider,
		},
		{
			name: 'views.contract.details.reject',
			variant: 'inline-delete',
			icon: <CloseIcon />,
			action: handleRejectContract,
			isVisible: !isProvider && contractData?.status === 'pendingAdmin',
		},
	];

	const dialogs = [
		{
			open: openExtendContract,
			loading: extendContractLoading,
			description: <ExtendCard {...extendCardProps} />,
			title: 'views.contract.details.extend',
			isValid: isNewTime,
		},
		{
			open: openDeleteContract,
			loading: deleteContractLoading,
			description: 'views.contract.details.description.delete',
			title: 'views.contract.details.delete',
		},
		{
			open: openTerminateContract,
			loading: terminateContractLoading,
			description: <ExtendCard {...terminateCardProps} />,
			title: 'views.contract.details.terminate',
		},
		{
			open: openRejectContract,
			loading: rejectContractLoading,
			description: 'views.contract.details.description.reject',
			title: 'views.contract.details.reject',
		},
	];

	return (
		<div className={className}>
			<Grid
				alignItems='flex-end'
				className={classes.headerSpacing}
				container
				justifyContent='space-between'
				spacing={3}
			>
				<Grid item>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{returnButton.isBackButton ? t('ui.back') : location?.state?.label}
					</StyledButton>
					<Typography component='h1' variant='h3'>
						{loading ?
							<AdjustedSkeleton animation='wave' width={200} />
						:	contractData.name}
					</Typography>
				</Grid>
				<Grid item>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	buttons.map((button) =>
							button.isVisible ?
								<StyledButton
									key={button.name}
									onClick={button.action}
									startIcon={button.icon}
									variant={button.variant}
								>
									{t(button.name)}
								</StyledButton>
							:	null,
						)
					}
				</Grid>
			</Grid>
			{dialogs.map((dialog) =>
				dialog.open ?
					<ActionDialog
						actionButtonProps={{
							action: handleConfirmContract,
							text: dialog.loading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
						}}
						handleClose={handleCloseDialog}
						key={dialog.title}
						loading={!isUndefined(dialog.isValid) ? dialog.isValid : false}
						open={dialog.open}
						title={t(dialog.title)}
					>
						{openExtendContract || openTerminateContract ?
							dialog.description
						:	t(dialog.description)}
					</ActionDialog>
				:	null,
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteContract: state.condition.deleteContract,
		terminateContract: state.details.terminateContract,
		extendContract: state.condition.extendContract,
		currentUser: state.details.currentUser,
		rejectContract: state.details.rejectContract,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteContract: (contractId) => dispatch(actions.deleteContract(contractId)),
		onTerminateContract: (contractId, body) =>
			dispatch(actions.terminateContract(contractId, body)),
		onExtendContract: (contractId, body) => dispatch(actions.extendContract(contractId, body)),
		onRejectContract: (contractId) => dispatch(actions.rejectContract(contractId)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
