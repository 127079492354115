import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Categories from './Categories';
import Characteristics from './Characteristics';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../components';
import { isObject } from '../../shared/utility';
import * as actions from '../../store/actions';

interface SettingsProps {
	onFetchCharacteristics?(...args: unknown[]): unknown;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Settings = (props: SettingsProps) => {
	const { onFetchCharacteristics, characteristics } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const classes = useStyles();

	const { tab } = useParams();
	const { data: characteristicsData, loading: loadingCharacteristicsLoading } = characteristics;
	useEffect(() => {
		if (!loadingCharacteristicsLoading && !isObject(characteristicsData)) {
			onFetchCharacteristics();
		}
	}, [loadingCharacteristicsLoading, characteristicsData]);

	const handleTabsChange = (e, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'categories', label: t('ui.category.categories') },
		{ value: 'characteristicsCar', label: t('ui.cars'), propName: 'cars' },
		{ value: 'characteristicsBikes', label: t('ui.bikes'), propName: 'bikes' },
		{ value: 'characteristicsOffices', label: t('ui.offices'), propName: 'offices' },
		{ value: 'characteristicsTrailers', label: t('ui.label.trailers'), propName: 'trailers' },
		{ value: 'characteristicsBoats', label: t('ui.label.boats'), propName: 'boats' },
		{
			value: 'characteristicsParkingLots',
			label: t('ui.label.parkingLots'),
			propName: 'parkingLots',
		},
	];

	if (!tab) {
		return <Navigate to={'/settings/categories'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.settings.page.title')}>
			<Header tab={tab} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((item) => (
					<Tab key={item.value} label={item.label} value={item.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'categories' && <Categories />}
				{tabs.map((item) =>
					isObject(characteristicsData) && item.value === tab && tab !== 'categories' ?
						<Characteristics
							characteristicsData={characteristicsData}
							data={characteristicsData[item.propName]}
							key={item.value}
						/>
					:	null,
				)}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCharacteristics: () => dispatch(actions.fetchCharacteristics()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
