import { useEffect } from 'react';

import path from 'path';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { pagePathSegments } from '~constants';

import BookingList from './BookingList';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../components';
import * as actions from '../../store/actions';

interface BookingsProps {
	onUpdatePagedState?(...args: unknown[]): unknown;
}

const Bookings = (props: BookingsProps) => {
	const { onUpdatePagedState } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const classes = useStyles();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		// This is been done to redirect properly from the emails.
		const bookingId = searchParams.get('bookingId');
		if (bookingId) {
			navigate(
				`/${path.join(pagePathSegments.Booking, pagePathSegments.BookingDetail, bookingId, pagePathSegments.Summary)}`,
			);
		}
	}, [searchParams]);

	useEffect(() => {
		return () => {
			onUpdatePagedState('bookings', null, false);
		};
	}, []);

	return (
		<Page className={classes.root} title={t('views.bookings.page.title')}>
			<Header />
			<Divider className={classes.divider} />
			<div className={classes.content}>
				<BookingList />
			</div>
		</Page>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
	};
};

export default connect(null, mapDispatchToProps)(Bookings);
