import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import BookingInfo from './BookingInfo';
import BookingInformation from './BookingInformation';
import InvoicesBilling from './InvoicesBilling';
import UsageInfo from './UsageInfo';
import UserComment from './UserComment';
import UserInfo from './UserInfo';
import UserNote from './UserNote';
import { isObject } from '../../../../shared/utility';
import SummaryLoading from '../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

const PREFIX = 'Summary';

const classes = {
	root: `${PREFIX}-root`,
};

const StyledSummaryLoading = styled(SummaryLoading)(() => ({
	[`& .${classes.root}`]: {
		flexGrow: 1,
	},
}));

interface SummaryProps {
	className?: string;
	bookingData?: object;
	access?: boolean;
	loading?: boolean;
	userRoleData?: string;
	userImageData?: unknown[];
}

const Summary = (props: SummaryProps) => {
	const { className, bookingData, loading, access } = props;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return isObject(bookingData) && !loading ?
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<BookingInfo bookingData={bookingData} />
				</Grid>
				<Grid {...gridProps}>
					<UsageInfo bookingData={bookingData} />
				</Grid>
				<Grid {...gridProps}>
					<UserInfo bookingData={bookingData} userData={bookingData.userReference} />
				</Grid>
				<Grid {...gridProps}>
					<InvoicesBilling bookingData={bookingData} />
				</Grid>
				<Grid {...gridProps}>
					<BookingInformation bookingData={bookingData} />
				</Grid>
				<Grid {...gridProps}>
					<UserComment bookingData={bookingData} />
				</Grid>
				{access ?
					<Grid {...gridProps}>
						<UserNote bookingData={bookingData} />
					</Grid>
				:	null}
			</Grid>
		:	<StyledSummaryLoading />;
};

export default Summary;
