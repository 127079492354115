import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import EditContactInfo from './EditContactInfo';
import { useStyles } from './style';
import { EmptyState, OpenEmail, OpenPhoneNumber, StyledButton } from '../../../../../../components';
import { isFullString, isObject } from '../../../../../../shared/utility';

interface ContactInfoProps {
	className?: string;
	organisation: object;
}

const ContactInfo = (props: ContactInfoProps) => {
	const { organisation, className } = props;
	const { t } = useTranslation('general');

	const [openEdit, setOpenEdit] = useState(false);
	const classes = useStyles();

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const contactPerson = isObject(organisation.contactPerson) ? organisation.contactPerson : null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.organisationDetail.summary.cardHeaders.contact')} />
			<Divider />
			<CardContent className={classes.content}>
				{isObject(contactPerson) ?
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>{t('ui.label.name')}</TableCell>
								<TableCell>
									{isFullString(contactPerson.firstName) && isFullString(contactPerson.lastName) ?
										`${contactPerson.firstName} ${contactPerson.lastName}`
									:	'-'}
								</TableCell>
							</TableRow>
							<TableRow className={classes.tableRow}>
								<TableCell>{t('ui.label.phoneNumber')}</TableCell>
								<TableCell>
									{isFullString(contactPerson.phoneNumber) ?
										<OpenPhoneNumber number={contactPerson.phoneNumber} />
									:	'-'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>{t('ui.label.email')}</TableCell>
								<TableCell>
									{isFullString(contactPerson.emailAddress) ?
										<OpenEmail email={contactPerson.emailAddress} />
									:	'-'}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				:	<Box padding={2}>
						<EmptyState
							image={'booking'}
							subTitle={t('views.organisationDetail.summary.contact.missing.description')}
							title={t('views.organisationDetail.summary.contact.missing.title')}
						/>
					</Box>
				}
			</CardContent>
			{organisation.isVerified ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.edit')}
						</StyledButton>
					</CardActions>
					<EditContactInfo onClose={handleEditClose} open={openEdit} organisation={organisation} />
				</>
			:	null}
		</Card>
	);
};

export default ContactInfo;
