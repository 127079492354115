import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../components';
import { isObject } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';

interface ContractsDetailsProps {
	fetchContract?(...args: unknown[]): unknown;
	contract?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ContractsDetails = (props: ContractsDetailsProps) => {
	const { fetchContract, contract } = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: contractData, loading: contractLoading, error: contractError } = contract;
	const contractReady = isObject(contractData) && !contractLoading && !contractError;

	const summaryProps = {
		loading: !contractLoading && isObject(contractData) && `${contractData.id}` === id,
		contractData,
	};

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (id && !contractLoading) {
			fetchContract(id);
		}
	}, [id]);

	const tabs = [{ value: 'summary', label: t('ui.summary') }];

	if (!tab) {
		return <Navigate to={'/contracts/contracts'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	return (
		<Page className={classes.root} title={t('views.contract.details.page.title')}>
			<Header contractData={contractData} loading={!contractReady} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>{tab === 'summary' && <Summary {...summaryProps} />}</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		contract: state.details.contract,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchContract: (contractId) => dispatch(actions.contract(contractId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractsDetails);
