import { useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Paper, Typography } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';

interface TipProps {
	className?: string;
	message: string;
	arrowDirection?: 'top' | 'left';
}

const Tip = (props: TipProps) => {
	const { className, message, arrowDirection } = props;

	const classes = useStyles();

	const [tipOpen, setTipOpen] = useState(true);

	const handleToggleTip = () => {
		setTipOpen(!tipOpen);
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Typography className={classes.tipToggle} onClick={handleToggleTip}>
				<span className={classes.iconContainer}>
					<InfoIcon fontSize={'small'} />
				</span>
				{tipOpen ? 'Close tip' : 'Open tip'}
			</Typography>
			{tipOpen ?
				<Paper
					className={clsx({
						[classes.tipCard]: true,
						[classes.arrowLeft]: arrowDirection === 'left',
						[classes.arrowTop]: arrowDirection === 'top',
					})}
				>
					<Typography variant='body2'>{message}</Typography>
				</Paper>
			:	null}
		</div>
	);
};

export default Tip;
