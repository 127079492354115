import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface CancelUnabailabilitysDialogProps {
	onCancelUnavailabilities?(...args: unknown[]): unknown;
	cancelUnavailabilities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	openCancelUnavailability?: boolean;
	setOpenCancelUnavailability?(...args: unknown[]): unknown;
	unavailabilityId?: any;
	onResetState?(...args: unknown[]): unknown;
}

const CancelUnabailabilitysDialog = (props: CancelUnabailabilitysDialogProps) => {
	const {
		openCancelUnavailability,
		setOpenCancelUnavailability,
		cancelUnavailabilities,

		onCancelUnavailabilities,
		unavailabilityId,
		onResetState,
	} = props;
	const { t } = useTranslation('general');

	const {
		data: cancelUnavailabilityData,
		loading: cancelUnavailabilityLoading,
		error: cancelUnavailabilityError,
	} = cancelUnavailabilities;

	const { enqueueSnackbar } = useSnackbar();

	const handleConfirmCancelUnabailability = () => {
		onCancelUnavailabilities(unavailabilityId);
	};

	const handleCancelUnabailabilityClose = () => setOpenCancelUnavailability(false);

	useEffect(() => {
		if (isObject(cancelUnavailabilityData)) {
			setOpenCancelUnavailability(false);
			onResetState('cancelUnavailabilities');
			enqueueSnackbar(t('views.actions.cancelUnabailabilitys.errorMessage'), {
				variant: 'success',
			});
		} else if (cancelUnavailabilityError) {
			enqueueSnackbar('error', { variant: 'error' });
		}
	}, [cancelUnavailabilityData, cancelUnavailabilityError]);

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirmCancelUnabailability,
				text:
					cancelUnavailabilityLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('ui.confirm'),
			}}
			handleClose={handleCancelUnabailabilityClose}
			loading={false}
			open={openCancelUnavailability}
			title={t('views.actions.cancelUnabailabilitys.title')}
		>
			{t('views.actions.cancelUnabailabilitys.description')}
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		cancelUnavailabilities: state.details.cancelUnavailabilities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCancelUnavailabilities: (id) => dispatch(actions.cancelUnavailabilities(id)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelUnabailabilitysDialog);
