import { useState, useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { EmptyState, Table, GenericMoreButton, AlertDialog, SearchBar } from '../../../components';
import {
	useError,
	useSearchComponent,
	usePagination,
	useComplexFilter,
} from '../../../shared/hooks';
import { isObject, isEmptyArray, isEmptyString, isInteger } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface PricingProps {
	onFetchPricingModels?(...args: unknown[]): unknown;
	pricingModels?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deletedPricingModel?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onDeletePricingModel?(...args: unknown[]): unknown;
	onFetchOrganisations?(...args: unknown[]): unknown;
}

const Pricing = (props: PricingProps) => {
	const {
		onFetchPricingModels,
		pricingModels,

		onDeletePricingModel,
		deletedPricingModel,
		onFetchOrganisations,
		organisationsList,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [shouldSort, setShouldSort] = useState(false);

	const [shouldFilter, setShouldFilter] = useState(false);

	// Dialog
	const [openDialog, setOpenDialog] = useState(false);

	const [pricingModelToDelete, setPricingModelToDelete] = useState(null);
	const [deletingPricingModel, setDeletingPricingModel] = useState(false);

	const {
		success: deleteSuccess,
		loading: deleteLoading,
		error: deleteError,
	} = deletedPricingModel;
	const deletePricingModelDone = deleteSuccess && !deleteLoading && !deleteError;

	const {
		data: pricingModelsData,
		loading: pricingModelsLoading,
		error: pricingModelsError,
	} = pricingModels;
	const pricingReady = isObject(pricingModelsData) && !pricingModelsLoading && !pricingModelsError;

	const pricingOrganisationFilter = useComplexFilter(
		'pricingOrganisationNameFilter',
		'pricingOrganisationIdFilter',
	);

	const pagination = usePagination('pageNumberPricing', 'pageSizePricing');

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch);

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(pricingOrganisationFilter.valueId !== 'all' && {
			organisationId: pricingOrganisationFilter.valueId,
		}),
		sortBy: sortingCategory,
		orderDescending: orderDescending,
	};

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		if (
			!pricingModelsLoading &&
			(pagination.fetch || shouldSort || shouldFilter || shouldSearch || shouldDoInitialFetch)
		) {
			onFetchPricingModels(pagination.page, filters);
			setShouldDoInitialFetch(false);
		}
		if (shouldSort) {
			setShouldSort(false);
		} else if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [shouldDoInitialFetch, pagination, shouldSort, shouldSearch, shouldFilter]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	const deletedPricingMessage = useError({
		value: deletedPricingModel,
		message: `${t('views.pricingModelDetail.messages.success.delete')} ${isObject(pricingModelToDelete) && pricingModelToDelete.name}`,
	});

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	useEffect(() => {
		if (deletingPricingModel && deletePricingModelDone) {
			setDeletingPricingModel(false);
			pagination.setPageNumber(1);
			setShouldDoInitialFetch(true);
		}
	}, [deletePricingModelDone, deletingPricingModel]);

	useEffect(() => {
		if (deletingPricingModel && !!deleteError) {
			setDeletingPricingModel(false);
			setOpenDialog(false);
		}
	}, [deleteError, deletingPricingModel]);

	/* * * * * * * * *
	 * TABLE ACTIONS *
	 * * * * * * * * */

	const handleClickDelete = (pricingModel) => {
		setPricingModelToDelete(pricingModel);
		setOpenDialog(true);
	};

	const handleEdit = (pricingModel) => {
		navigate(`/pricing/${pricingModel.id}/edit`, {
			state: { detail: false },
		});
	};

	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const createMenuItems = (pricingModel) => [
		{
			icon: <EditIcon />,
			text: t('ui.button.inline.edit'),
			action: () => handleEdit(pricingModel),
		},
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => handleClickDelete(pricingModel),
			isRed: true,
		},
	];

	const extraButtons = [
		{
			text: t('view.policymanagement.pricing.button.contained.addpricing'),
			onClick: () => navigate('/policy-management/pricing/add'),
			variant: 'contained-primary',
		},
	];

	const handleCancelDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		setDeletingPricingModel(true);
		onDeletePricingModel(pricingModelToDelete.id);
		deletedPricingMessage.setStartAction(true);
	};

	useEffect(() => {
		if (openDialog && deletePricingModelDone && deletingPricingModel) {
			setOpenDialog(false);
		}
	}, [openDialog, deletePricingModelDone, deletingPricingModel]);

	const handleOrganisationFilter = (item) => {
		if (item.id === pricingOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			pricingOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			pricingOrganisationFilter.setValueName('');
		}
		pricingOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleOrganisationFilter,
						searchHandle: pricingOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: pricingOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
	];

	const handleClearFilters = () => {
		pricingOrganisationFilter.setValueName('');
		pricingOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			pricingOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */
	const tableHeader = [
		{ name: 'id', content: t('ui.label.id') },
		{ name: 'name', content: t('ui.label.name'), hasSorting: true },
		...(isSuperAdmin() ? [{ name: 'organisation', content: t('ui.label.organisation') }] : []),
		{ name: 'category', content: t('ui.label.category') },
		{ name: 'type', content: t('ui.characteristics.carBodyType') },
		{ name: 'vat', content: `${t('ui.vat')}(%)` },
		{ name: 'actions' },
	];

	const tableBody =
		pricingReady ?
			pricingModelsData.results.map((item) => [
				{
					content: (
						<Link
							component={RouterLink}
							to={`/pricing/${item.id}/summary`}
							state={{ from: location.pathname }}
						>
							{item.id}
						</Link>
					),
				},
				{
					content: (
						<Link
							className={classes.itemGroup}
							component={RouterLink}
							to={`/pricing/${item.id}/summary`}
							state={{ from: location.pathname }}
						>
							{item.name}
						</Link>
					),
				},
				...(isSuperAdmin() ? [{ content: item.organisationReference.name }] : []),
				{ content: item.categoryReference.name },
				{ content: t(`ui.label.pricing.${item.calculationType}`) },
				{ content: item.vatPercentage },
				{
					content: (
						<Box display='flex' flexDirection='row-reverse'>
							<Box>
								<GenericMoreButton menuItems={createMenuItems(item)} />
							</Box>
						</Box>
					),
				},
			])
		:	Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const itemsListEmpty =
		(
			isObject(pricingModelsData) &&
			!pricingModelsLoading &&
			isEmptyArray(pricingModelsData.results)
		) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/policy-management/pricing/add'}
					callToActionText={t('view.policymanagement.pricing.button.contained.addpricing')}
					image={'booking'}
					subTitle={t('views.assets.pricing.empty.description.zeroInSystem')}
					title={t('views.assets.pricing.empty.title')}
				/>
			:	<EmptyState
					callToAction={'/policy-management/pricing/add'}
					callToActionText={t('view.policymanagement.pricing.button.contained.addpricing')}
					image={'booking'}
					subTitle={t('views.assets.pricing.empty.description.zeroMatching')}
					title={t('views.assets.pricing.empty.title')}
				/>
		:	null;

	return (
		<div className={classes.root}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter={true}
				mainFilters={mainFilters}
				placeholder={t('views.assets.items.placeholder.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{!itemsListEmpty ?
				<Table
					body={tableBody}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={pricingModelsLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.assets.pricing.table.title')}
					total={pricingModelsData ? pricingModelsData.total : 0}
				/>
			:	itemsListEmpty}
			<AlertDialog
				dialogDescription={t('views.pricingModelDetail.summary.deleteDialog.description')}
				dialogTitle={t('views.pricingModelDetail.summary.deleteDialog.title')}
				handleClose={handleCancelDialog}
				handleConfirm={handleConfirmDialog}
				loading={deleteLoading && !deletePricingModelDone}
				open={openDialog}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		pricingModels: state.paged.pricingModels,
		deletedPricingModel: state.condition.deletedPricingModel,
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPricingModels: (page, filters) => dispatch(actions.fetchPricingModels(page, filters)),
		onDeletePricingModel: (id) => dispatch(actions.deletePricingModel(id)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
