import { Card, CardContent, CardHeader, Typography, Box, Divider } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { stringifyTime, modifyDate } from '../../../../../../shared/datetime';
import {
	isObject,
	isInteger,
	isFullArray,
	isUndefined,
	createSummaryPeriod,
} from '../../../../../../shared/utility';

interface PriceSettingsProps {
	className?: string;
	pricingModel?: object;
	loading?(...args: unknown[]): unknown;
}

const PriceSettings = (props: PriceSettingsProps) => {
	const { className, pricingModel, loading } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const minutePeriods =
		isObject(pricingModel) ?
			pricingModel.pricingPeriods.filter((period) => period.periodType === 'minute')
		:	[];
	const hourPeriods =
		isObject(pricingModel) ?
			pricingModel.pricingPeriods.filter((period) => period.periodType === 'hour')
		:	[];
	const dayPeriods =
		isObject(pricingModel) ?
			pricingModel.pricingPeriods.filter((period) => period.periodType === 'day')
		:	[];
	const weekPeriods =
		isObject(pricingModel) ?
			pricingModel.pricingPeriods.filter((period) => period.periodType === 'week')
		:	[];
	const isFree =
		isObject(pricingModel) &&
		pricingModel.pricingPeriods.reduce((acc, model) => acc && model.price === 0, true);
	const data = [
		createSummaryPeriod(minutePeriods),
		createSummaryPeriod(hourPeriods),
		createSummaryPeriod(dayPeriods),
		createSummaryPeriod(weekPeriods),
	];

	return !loading ?
			<Card className={clsx(classes.root, className)}>
				<CardHeader title={t('views.pricingModelDetail.summary.cardHeaders.priceSettings')} />
				<Divider />
				<CardContent>
					{!isFree &&
						data.map((model, index) =>
							isFullArray(model) ?
								<Card className={classes.card} key={index}>
									<CardContent>
										{isFullArray(model) ?
											model
												.sort((first, second) => first.days[0].id - second.days[0].id)
												.map((item, iIndex) => (
													<div key={item.id}>
														{iIndex === 0 ?
															<Typography>{`${t('views.addPricing.summary.pricePer')} ${item.periodType}`}</Typography>
														:	null}
														{iIndex === 0 ?
															<Divider className={classes.divider} />
														:	null}
														<div className={classes.daySection}>
															<Box flexBasis='25%'>
																<Typography>
																	{item.days
																		.map((day) =>
																			isInteger(day.id) ?
																				`${day.value === 'weekdays' || day.value === 'weekend' || day.value === 'allWeek' ? day.name : day.name.slice(0, 3)}`
																			:	null,
																		)
																		.filter((name) => name !== null)
																		.join(', ')}
																</Typography>
															</Box>
															{isUndefined(item.start) && isUndefined(item.end) ?
																<Box flexBasis='25%' justifyContent='center'>
																	<Typography>{t('views.addPricing.summary.allWeek')}</Typography>
																</Box>
															:	<Box flexBasis='25%' justifyContent='center'>
																	<Typography>
																		{`${stringifyTime(item.start)} - ${stringifyTime(modifyDate(item.end, { ...(item.end.getHours() === 0 && item.end.getMinutes() === 0 && { hours: 23, minutes: 59, date: item.start.getDate() }) }))}`}
																	</Typography>
																</Box>
															}
															<Box flexBasis='25%' justifyContent='center'>
																<Typography>
																	{item.price === 0 ?
																		t('ui.free')
																	:	`€${item.price.toFixed(2)}/${item.periodType}`}
																</Typography>
															</Box>
														</div>
													</div>
												))
										:	null}
									</CardContent>
								</Card>
							:	null,
						)}
					{isFree ?
						<Card className={classes.card}>
							<CardContent>
								<Typography>{t('ui.free')}</Typography>
								<Divider className={classes.divider} />
								<Box display='flex'>
									<Box flexBasis='50%'>
										<Typography>{t('views.addPricing.summary.allWeek')}</Typography>
									</Box>
									<Box>
										<Typography>00:00 - 23:59</Typography>
									</Box>
								</Box>
							</CardContent>
						</Card>
					:	null}
				</CardContent>
			</Card>
		:	null;
};

export default PriceSettings;
