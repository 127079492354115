import { useMemo } from 'react';

import { AxisValueFormatterContext } from '@mui/x-charts/internals';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ChartsLegendWithValues, LineChart } from '~components';
import { PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import {
	generateDateArray,
	longMonthAndYearFormatter,
	shortMonthAndYearFormatter,
} from '~utils/dateUtils';

import FinanceStatisticsService from '../../services/financeStatisticsService';

const service = new FinanceStatisticsService();

interface RevenuesLineChartProps {
	organisationId?: string;
	period: DateRange<Dayjs>;
	categoryId?: string;
	itemId?: string;
	hubId?: string;
	partnerId?: string;
	periodicity?: PeriodicityEnum;
}

const RevenuesLineChart = ({
	period = {
		start: dayjs().subtract(3, 'month'),
		end: dayjs(),
	},
	...props
}: RevenuesLineChartProps) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			...props,
			period: period,
			periodicity:
				props.periodicity === PeriodicityEnum.Year ? PeriodicityEnum.Month : PeriodicityEnum.Week,
		}),
		[props, period],
	);

	const { data, isLoading } = useSWR([service.basePath, fetchParameters], ([_, args]) => {
		if (isLoading) {
			service.abortCurrentRequest('parameter change');
		}

		return service.getRevenues(args);
	});

	/**
	 * Scope the results for the current selected periodicity.
	 */
	const selectedPeriodResults = useMemo(
		() =>
			data?.results.filter((el) =>
				dayjs(el.timestamp).isBetween(
					period.end.startOf(props.periodicity === PeriodicityEnum.Month ? 'month' : 'year'),
					period.end,
					null,
					'[]',
				),
			),
		[data],
	);

	/**
	 * Generate an array of dates, so we don't have any missing gaps
	 * within the charts.
	 */
	const dates = useMemo(
		() =>
			generateDateArray(
				period,
				props.periodicity === PeriodicityEnum.Month ? PeriodicityEnum.Week : PeriodicityEnum.Month,
			),
		[period, props.periodicity],
	);

	const results = useMemo(
		() =>
			dates.map(
				(el) =>
					data?.results.find((result) =>
						dayjs(result.timestamp).isSame(
							el,
							props.periodicity === PeriodicityEnum.Month ? 'day' : 'month',
						),
					) ?? {
						timestamp: el,
						revenue: 0,
					},
			),
		[dates, data],
	);

	const yAxisValueFormatter = (value: number) => `€${value.toLocaleString()}`;

	const xAxisValueFormatter = (value: Date, context: AxisValueFormatterContext) => {
		if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Month) {
			return value.toLocaleDateString();
		} else if (context.location !== 'tick') {
			return longMonthAndYearFormatter.format(value);
		}

		return shortMonthAndYearFormatter.format(value);
	};

	return (
		<LineChart
			loading={isLoading}
			xAxis={[
				{
					min: period.start.toDate(),
					max: period.end
						.startOf(props.periodicity === PeriodicityEnum.Year ? 'month' : 'day')
						.toDate(),
					scaleType: 'time',
					data: dates,
					valueFormatter: xAxisValueFormatter,
					tickNumber: 3,
				},
			]}
			yAxis={[
				{
					min: 0,
					valueFormatter: yAxisValueFormatter,
					domainLimit: (min, max) => ({
						min: min,
						max: max <= 0 ? 2 : max,
					}),
				},
			]}
			series={[
				{
					curve: 'linear',
					data: results.map((el) => el.revenue) ?? [],
					label: t('nav.financialReports.revenue'),
					showMark: false,
					valueFormatter: (value: number | null) =>
						value != null ? yAxisValueFormatter(value) : '',
				},
			]}
			slots={{
				legend: ChartsLegendWithValues,
			}}
			slotProps={{
				legend: {
					values: [selectedPeriodResults?.reduce((total, el) => (total += el.revenue), 0)],
				},
			}}
			margin={{ left: 70 }}
		/>
	);
};

export default RevenuesLineChart;
