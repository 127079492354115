import { useState, useEffect } from 'react';

import { Card, CardContent, CardHeader, FormControlLabel, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';

interface SettingsProps {
	organisationalBillingAllowed?: boolean;
	personalBillingAllowed?: boolean;
	userGroupReady?: boolean;
	save?(...args: unknown[]): unknown;
	userGroupObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Settings = (props: SettingsProps) => {
	const {
		organisationalBillingAllowed,
		personalBillingAllowed,
		userGroupReady,
		userGroupObject,
		save,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const { data: userGroupData } = userGroupObject;

	const [allowOrganisationalBilling, setAllowOrganisationalBilling] = useState(
		organisationalBillingAllowed,
	);

	useEffect(() => {
		save({
			allowOrganisationalBilling,
		});
	}, [allowOrganisationalBilling]);

	const [allowPersonalBilling, setAllowPersonalBilling] = useState(personalBillingAllowed);

	useEffect(() => {
		save({
			allowPersonalBilling,
		});
	}, [allowPersonalBilling]);

	//set initial data once usergroup has been fetched
	useEffect(() => {
		if (userGroupReady) {
			setAllowOrganisationalBilling(userGroupData.allowOrganisationalBilling);
			setAllowPersonalBilling(userGroupData.allowPersonalBilling);
		}
	}, [userGroupReady]);

	/* * * * * * *
	 * FUNCTIONS *
	 * * * * * * */
	const handleToggleOrganisationalBilling = () => {
		setAllowOrganisationalBilling(!allowOrganisationalBilling);
	};

	// const handleTogglePersonalBilling = () => {
	//   setAllowPersonalBilling(!allowPersonalBilling);
	// };

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.cardHeader}
				subheader={t('views.editUserGroupSettings.settings.subtitle')}
				title={t('views.editUserGroupSettings.settings.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<div className={classes.formGroup}>
					<FormControlLabel
						className={classes.switchLabel}
						control={
							<Switch
								checked={allowOrganisationalBilling}
								color='primary'
								onChange={handleToggleOrganisationalBilling}
							/>
						}
						label={<Typography variant='h5'>{t('ui.label.organisationalBilling')}</Typography>}
					/>
					<Typography variant='body2'>
						{t('views.userGroupDetail.summary.info.organisationalBilling')}
					</Typography>
				</div>
				{/* <div className={classes.formGroup}>
          <FormControlLabel
            className={classes.switchLabel}
            control={<Switch color='primary' checked={allowPersonalBilling} onChange={handleTogglePersonalBilling} />}
            label={<Typography variant='h5'>{t('ui.label.personalBilling')}</Typography>}
          />
          <Typography variant='body2'>{t('views.userGroupDetail.summary.info.personalBilling')}</Typography>
        </div> */}
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		userGroupObject: state.details.userGroup,
	};
};

export default connect(mapStateToProps, null)(Settings);
