import { useState, useEffect } from 'react';

import { Modal, Card, CardContent, CardActions, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { FormField, StyledButton } from '../../../../../../../components';
import { useWizardFormField, useError } from '../../../../../../../shared/hooks';
import {
	isEmptyString,
	isObject,
	isFullString,
	isUndefined,
} from '../../../../../../../shared/utility';
import * as actions from '../../../../../../../store/actions';

interface EditGeneralInfoProps {
	className?: string;
	organisation?: any;
	onClose?(...args: unknown[]): unknown;
	open?: boolean;
	checkedNameField?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	//functions
	onUpdateOrganisation(...args: unknown[]): unknown;
	onValidateOrganisationNameField(...args: unknown[]): unknown;
	updateOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const EditGeneralInfo = ({
	open = false,
	onClose = () => {},
	organisation,
	className,

	checkedNameField,
	onUpdateOrganisation,
	onValidateOrganisationNameField,
	updateOrganisation,
}: EditGeneralInfoProps) => {
	const { t } = useTranslation('general');

	const classes = useStyles();

	const organisationName = useWizardFormField(organisation.name, {
		required: true,
		minLength: 3,
		maxLength: 128,
	});
	const [isUniqueOrganisationName, setIsUniqueOrganisationName] = useState(true);

	const legalName = useWizardFormField(
		!isUndefined(organisation.legalName) ? organisation.legalName : '',
		{ minLength: 3, maxLength: 128 },
	);

	const phoneNumber = useWizardFormField(organisation.phoneNumber, { required: true });

	const organisationEmail = useWizardFormField(organisation.emailAddress, {
		required: true,
		minLength: 3,
		isEmail: true,
		maxLength: 128,
	});

	const description = useWizardFormField(organisation.description ? organisation.description : '', {
		required: true,
		maxLength: 500,
	});

	const debouncedNameValue = useDebounce(organisationName.value, 300);

	const {
		success: checkedNameSuccess,
		loading: checkedNameLoading,
		error: checkedNameError,
	} = checkedNameField;

	const formValid =
		organisationName.isValid &&
		isUniqueOrganisationName &&
		description.isValid &&
		phoneNumber.isValid &&
		organisationEmail.isValid;

	const isChange =
		organisation.name === organisationName.value &&
		organisation.phoneNumber === phoneNumber.value &&
		organisation.emailAddress === organisationEmail.value &&
		organisation.description === description.value &&
		((isEmptyString(legalName.value) && isUndefined(organisation.legalName)) ||
			organisation.legalName === legalName.value);

	const errorHandle = useError(
		isObject(updateOrganisation) ?
			{
				value: updateOrganisation,
				message: `${t('ui.successfully')} ${t('ui.updated')} ${organisation.name}`,
			}
		:	null,
	);

	useEffect(() => {
		if (!checkedNameLoading && !isEmptyString(debouncedNameValue) && organisationName.isChanged) {
			onValidateOrganisationNameField(debouncedNameValue, organisation.id);
		}
	}, [debouncedNameValue]);

	useEffect(() => {
		if (!checkedNameLoading && !isObject(checkedNameError)) {
			setIsUniqueOrganisationName(true);
		} else if (!checkedNameLoading) {
			if (checkedNameError.key === 'DuplicateOrganisation') {
				setIsUniqueOrganisationName(false);
			}
		}
	}, [checkedNameSuccess, checkedNameLoading, checkedNameError]);

	const handleSave = () => {
		onUpdateOrganisation(organisation.id, {
			name: organisationName.value,
			legalName: isFullString(legalName.value) ? legalName.value : organisationName.value,
			description: description.value,
			phoneNumber: phoneNumber.value,
			email: organisationEmail.value,
			subscriptionId: organisation.subscription.id,
			address: {
				street: organisation.address.street,
				number: organisation.address.number,
				postalCode: organisation.address.postalCode,
				city: organisation.address.city,
				countryCode: organisation.address.countryCode,
			},
			contactPerson: {
				email: organisation.contactPerson.emailAddress,
				firstName: organisation.contactPerson.firstName,
				lastName: organisation.contactPerson.lastName,
				phoneNumber: organisation.contactPerson.phoneNumber,
			},
			invoiceContact: {
				email: organisation.invoiceContact.emailAddress,
				name: organisation.invoiceContact.name,
			},
			...(organisation.administration ?
				{
					administration: {
						...(!isUndefined(organisation.administration.vatNumber) && {
							vatNumber: organisation.administration.vatNumber,
						}),
						...(!isUndefined(organisation.administration.chamberOfCommerceNumber) && {
							chamberOfCommerceNumber: organisation.administration.chamberOfCommerceNumber,
						}),
						...(!isUndefined(organisation.administration.ibanNumber) && {
							ibanNumber: organisation.administration.ibanNumber,
						}),
						...(!isUndefined(organisation.administration.enterpriseNumber) && {
							enterpriseNumber: organisation.administration.enterpriseNumber,
						}),
					},
				}
			:	null),
		});
		onClose();
		errorHandle.setStartAction(true);
	};

	const resetData = () => {
		organisationName.resetToInitialValue();
		legalName.resetToInitialValue();
		description.resetToInitialValue();
		phoneNumber.resetToInitialValue();
		organisationEmail.resetToInitialValue();
	};

	const handleClose = () => {
		resetData();
		onClose();
	};

	if (!open) {
		return null;
	}

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>
				<form>
					<CardContent className={classes.cardContent}>
						<Typography align='center' gutterBottom variant='h3'>
							{t('views.organisationDetail.summary.edit.titles.general')}
						</Typography>
						<div className={classes.container}>
							<div className={classes.formGroup}>
								<FormField
									extraValidHelperText={t(
										'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
									)}
									hideCharacterCounter={!organisationName.hasFocus}
									isExtraValid={isUniqueOrganisationName}
									label={t('ui.label.organisationName')}
									maxLength={25}
									name='organisationName'
									required
									variable={organisationName}
								/>
							</div>
							<div className={classes.formGroup}>
								<FormField
									extraValidHelperText={t(
										'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
									)}
									hideCharacterCounter={!legalName.hasFocus}
									isExtraValid={true}
									label={t('ui.label.legalOrganisationName')}
									maxLength={128}
									name='legalName'
									variable={legalName}
								/>
							</div>
							<div className={classes.formGroup}>
								<FormField
									extraValidHelperText={t(
										'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
									)}
									hideCharacterCounter={!organisationEmail.hasFocus}
									isExtraValid={true}
									label={t('ui.label.email')}
									maxLength={64}
									name='email'
									required
									variable={organisationEmail}
								/>
							</div>
							<div className={classes.formGroup}>
								<FormField
									extraValidHelperText={t(
										'views.register.organisation.organisationInfo.error.nameAlreadyInUse',
									)}
									isExtraValid={true}
									label={t('ui.label.phoneNumber')}
									name='phoneNumber'
									required
									variable={phoneNumber}
								/>
							</div>
							<div className={classes.formGroup}>
								<Box display='flex' justifyContent='space-between' pb={1}>
									<Typography variant='h5'>
										{t('ui.label.description')}
										<sup>*</sup>
									</Typography>
									<Typography className={classes.counterText}>
										{description.value.length}/500 {t('ui.characters')}
									</Typography>
								</Box>
								<FormField
									hideCharacterCounter
									maxLength={500}
									multiline
									name='description'
									rows={5}
									variable={description}
								/>
							</div>
						</div>
					</CardContent>
					<CardActions className={classes.actions}>
						<StyledButton onClick={handleClose} variant='contained-tertiary'>
							{t('ui.button.contained.close')}
						</StyledButton>
						<StyledButton
							disabled={isChange || !formValid}
							onClick={handleSave}
							variant='contained-secondary'
						>
							{t('ui.button.contained.save')}
						</StyledButton>
					</CardActions>
				</form>
			</Card>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		checkedNameField: state.condition.checkedOrganisationNameField,
		updateOrganisation: state.details.updateOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateOrganisation: (organisationId, bodyData) =>
			dispatch(actions.updateOrganisation(organisationId, bodyData)),
		onValidateOrganisationNameField: (value, organisationId) =>
			dispatch(actions.validateOrganisationNameField(value, organisationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralInfo);
