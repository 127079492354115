import { useReducer, useEffect } from 'react';

import { Box, Typography, List, ListItem, Checkbox, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { availableSharingsReducer, dispatchTypes } from './reducer';
import { LoadingBar } from '../../../../../components';
import { isEmptyArray, isFullArray, handleHubReference } from '../../../../../shared/utility';
import { useFetchAvailableSharing } from '../../../Management/hooks';

interface ItmesListProps {
	setDisabled?(...args: unknown[]): unknown;
	setSelectedItems?(...args: unknown[]): unknown;
	openItmeDrawer?: boolean;
}

const ItmesList = (props: ItmesListProps) => {
	const { setDisabled, openItmeDrawer, setSelectedItems } = props;
	const { t } = useTranslation('general');

	const [availableItems, dispatchAvailableItems] = useReducer(availableSharingsReducer, []);

	const availableSharings = useFetchAvailableSharing(props);

	const selectedItems = isFullArray(availableItems) && availableItems.filter((item) => item.select);

	useEffect(() => {
		if (availableSharings.isUpdated) {
			dispatchAvailableItems({});
			dispatchAvailableItems({ type: dispatchTypes.ADD, items: availableSharings.data });
			availableSharings.setIsUpdated(() => false);
		}
	}, [availableSharings.isUpdated]);

	const selectItem = (item) => {
		dispatchAvailableItems({ type: dispatchTypes.SELECTONE, selectedItem: item });
	};

	const selectAllItems = (selectAll) => {
		dispatchAvailableItems({ type: dispatchTypes.SELECTALL, selectedItem: selectAll });
	};

	useEffect(() => {
		if (isFullArray(availableItems)) {
			setDisabled(!availableItems.some((item) => item.select));
			setSelectedItems(selectedItems);
		}
	}, [availableItems]);

	const handleSelectedItems = () => {
		if (isFullArray(availableItems) && !selectedItems?.length) {
			return `${0} ${t('ui.label.selectedItems')}`;
		} else if (selectedItems?.length) {
			return `${selectedItems.length} ${t('ui.label.selectedItems')}`;
		}
	};

	return (
		<Box sx={{ marginTop: 5 }}>
			{availableSharings.loading && openItmeDrawer ?
				<List>
					<ListItem sx={{ paddingLeft: 0 }}>
						<LoadingBar />
					</ListItem>
					<ListItem sx={{ paddingLeft: 0 }}>
						<LoadingBar />
					</ListItem>
					<ListItem sx={{ paddingLeft: 0 }}>
						<LoadingBar />
					</ListItem>
				</List>
			:	null}
			{isFullArray(availableItems) && (
				<List>
					<ListItem sx={{ paddingLeft: 0 }}>
						<Box sx={{ flexGrow: 1 }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<Box>
									<Typography>{handleSelectedItems()}</Typography>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography>{t('ui.selectAll')}</Typography>
									<Checkbox
										checked={
											isFullArray(availableItems) && availableItems.every((item) => item.select)
										}
										indeterminate={
											isFullArray(availableItems) &&
											!availableItems.every((item) => item.select) &&
											availableItems.some((item) => item.select)
										}
										onClick={(event) => selectAllItems(event.target.checked)}
									/>
								</Box>
							</Box>
							<Divider sx={{ flexGrow: 1 }} />
						</Box>
					</ListItem>
					{isFullArray(availableItems) &&
						availableItems.map((item) => (
							<ListItem key={item.name} sx={{ paddingLeft: 0 }}>
								<Box sx={{ flexGrow: 1 }}>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Box>
											<Typography>{item.name}</Typography>
											<Typography>{handleHubReference(item)}</Typography>
										</Box>
										<Checkbox
											checked={item.select}
											onClick={(event) => selectItem({ ...item, select: event.target.checked })}
										/>
									</Box>
									<Divider sx={{ flexGrow: 1 }} />
								</Box>
							</ListItem>
						))}
				</List>
			)}
			{isEmptyArray(availableSharings.data) ?
				<Typography>{t('views.sharing.drawer.noItems')}</Typography>
			:	null}
		</Box>
	);
};

export default ItmesList;
