import { NumericFormat } from 'react-number-format';

interface CustomNumberFormatProps {
	inputRef(...args: unknown[]): unknown;
	name: string;
	onChange(...args: unknown[]): unknown;
	currencySymbol?: string;
}

function CustomNumberFormat(props: CustomNumberFormatProps) {
	const { inputRef, onChange, currencySymbol, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			isNumericString
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			prefix={currencySymbol}
			thousandSeparator
		/>
	);
}

export default CustomNumberFormat;
