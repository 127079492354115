import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../components';
import * as actions from '../../../../../store/actions';

interface DeleteNfcDialogProps {
	open?: boolean;
	nfcId?: string;
	onClose?(...args: unknown[]): unknown;
	onDeleteNfc?(...args: unknown[]): unknown;
	getNfcData?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	deleteNfc?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const DeleteNfcDialog = (props: DeleteNfcDialogProps) => {
	const {
		open,
		onClose,
		nfcId,

		onDeleteNfc,
		deleteNfc,
		getNfcData,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const { success: deleteNfcSuccess, loading: deleteNfcLoading, error: deleteNfcError } = deleteNfc;

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (deleteNfcSuccess) {
			enqueueSnackbar(t('views.usersAndOrganisations.deleteNfc.successMessage'), {
				variant: 'success',
			});
			onClose();
			getNfcData();
			onResetStateCondition('deleteNfc', false);
		} else if (deleteNfcError) {
			enqueueSnackbar(deleteNfcError?.message ? deleteNfcError.message : 'Error', {
				variant: 'error',
			});
			onResetStateCondition('deleteNfc', false);
		}
	}, [deleteNfc]);

	const handleDeleteNfcDialog = () => {
		onDeleteNfc(nfcId);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleDeleteNfcDialog,
				text: deleteNfcLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={onClose}
			loading={deleteNfcLoading}
			open={open}
			title={t('views.usersAndOrganisations.deleteNfc.title')}
		>
			{`${nfcId} ${t('views.usersAndOrganisations.deleteNfc.description')}`}
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteNfc: state.condition.deleteNfc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onDeleteNfc: (nfcId) => dispatch(actions.deleteNfc(nfcId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteNfcDialog);
