import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { useDebounce } from '~hooks';

import { useStyles } from './style';
import { SearchBar, Table } from '../../../../../components';
import { localizeDateTime } from '../../../../../shared/datetime';
import {
	isEmptyString,
	isObject,
	isEmptyArray,
	isFullString,
	instanceName,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

const localizeTime = (value) =>
	localizeDateTime(value, undefined, {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});

interface OrganisationalBookingsProps {
	financeType?: string;
	onFetchRevenueBookings?(...args: unknown[]): unknown;
	date?: string;
	id?: string;
	partnerId?: string;
	revenueBookings?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchExpenseBookings?(...args: unknown[]): unknown;
}

const OrganisationalBookings = (props: OrganisationalBookingsProps) => {
	const { date, id, partnerId, onFetchRevenueBookings, revenueBookings } = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [shouldSort, setShouldSort] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const page = { number: pageNumber, size: pageSize };

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [shouldFilter, setShouldFilter] = useState(false);

	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		...(isSuperAdmin() && isFullString(partnerId) && { organisationId: partnerId }),
		orderDescending,
	};

	const shouldSearchOnDebounce = false;

	const debouncedSearchValue = useDebounce(searchValue, 500);

	const { data: revenueBookingsData, loading: revenueBookingsLoading } = revenueBookings;

	useEffect(() => {
		if (shouldSearch || shouldSort || (shouldDoInitialFetch && !revenueBookingsLoading)) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchRevenueBookings(date, id, page, filters);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [shouldSearch, shouldSort, shouldFilter, shouldDoInitialFetch, revenueBookingsLoading]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setShouldDoInitialFetch(true);
	};

	const handlePageChange = (newPage) => {
		setPageNumber(newPage);
		setShouldDoInitialFetch(true);
	};

	const headers = getFinanceTableHeaders(true, true, true);

	const tableHeader = [
		{ name: 'id', content: 'ID', hasSorting: true },
		{ name: 'start', content: t('views.ItemDetail.activities.start') },
		{ name: 'end', content: t('views.ItemDetail.activities.end') },
		{ name: 'user', content: t('views.ItemDetail.activities.user') },
		{ name: 'itemName', content: t('ui.label.itemName') },
		...headers,
	];

	const emptyBody =
		(
			isObject(revenueBookingsData) &&
			!revenueBookingsLoading &&
			isEmptyArray(revenueBookingsData.results)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(revenueBookingsData) || revenueBookingsLoading ?
			Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(revenueBookingsData) && !revenueBookingsLoading ?
			revenueBookingsData.results.map((item) => {
				const currency = item.price.currencySymbol;
				const price = item.price.total;

				return [
					{
						content: (
							<Link
								color='primary'
								component={RouterLink}
								to={`/booking/booking-detail/${item.id}/summary`}
								variant='h6'
							>
								{item.id}
							</Link>
						),
					},
					{ content: localizeTime(item.periodStart) },
					{ content: localizeTime(item.periodEnd) },
					{ content: item.userReference.name },
					{ content: `${item.itemReference.name} - ${instanceName(item.itemInstance)}` },
					{ content: `${currency}${price.subtotal.toFixed(2)}` },
					{ content: `${currency}${price.discount.toFixed(2)}` },
					{ content: `${currency}${(price.subtotal - price.discount).toFixed(2)}` },
					{ content: `${currency}${price.totalVat.toFixed(2)}` },
					{ content: `${currency}${price.refund.toFixed(2)}` },
					{ content: `${currency}${price.total.toFixed(2)}` },
				];
			})
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<div>
			<Box pt={3}>
				<SearchBar
					hasSearchButton
					placeholder={t('views.finance.bookingDetails.placeholder')}
					searchEvents={events}
					searchValue={searchValue}
				/>
			</Box>
			<Table
				body={loadingBody || emptyBody || dataBody}
				cellStyle={classes.cellStyle}
				handlePageChange={handlePageChange}
				handleSorting={handleRequestSort}
				header={tableHeader}
				loading={revenueBookingsLoading}
				order={order}
				orderBy={sortingCategory}
				page={pageNumber}
				rowsPerPage={pageSize}
				setRowsPerPage={handlePageSizeChange}
				title={t('nav.category.bookings')}
				total={isObject(revenueBookingsData) ? revenueBookingsData.total : 0}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		revenueBookings: state.paged.revenueBookings,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchRevenueBookings: (dateTime, partnerId, page, filters) =>
			dispatch(actions.fetchRevenueBookings(dateTime, partnerId, page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationalBookings);
