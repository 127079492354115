import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Typography, Divider, TextField, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { CustomNumberField, StyledButton } from '../../../../../../components';
import { weekdays, weekend, weekOptions, modifyDate } from '../../../../../../shared/datetime';
import {
	isInteger,
	isFullArray,
	isEmptyArray,
	ignoreKeyClick,
} from '../../../../../../shared/utility';
import { useStyles } from '../../style';

interface UpdatePerDayProps {
	className?: string;
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	periodLabel?: string;
	currency?: string;
	save?(...args: unknown[]): unknown;
	periodType?: string;
	selectedCategory?: object;
	pricingPerDay?: object;
	pricingPerDayModel?: unknown[];
	amountPerDay?: number;
	setPricingPerDay?(...args: unknown[]): unknown;
	createPricingModel?(...args: unknown[]): unknown;
}

const UpdatePerDay = (props: UpdatePerDayProps) => {
	const {
		open,
		onClose,
		periodLabel,
		save,
		periodType,
		pricingPerDay,
		pricingPerDayModel,
		setPricingPerDay,
		createPricingModel,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const handlePricingClose = () => {
		onClose();
	};

	const handlePricePerDayChange = (event) => {
		const value = parseFloat(event.target.value);
		setPricingPerDay({ ...pricingPerDay, price: value });
	};

	const [isEdit] = useState(isInteger(pricingPerDay.dayOfWeek));
	const start = !isEdit ? pricingPerDay.start.getDay() : pricingPerDay.dayOfWeek;
	const end = !isEdit ? pricingPerDay.end.getDay() : pricingPerDay.dayOfWeek + 1;
	const [selectedDays, setSelectedDays] = useState(
		weekOptions.filter((item) => item.id >= start && item.id <= (end === 0 ? 6 : end - 1)),
	);

	const updatedModels =
		!isEdit ? pricingPerDayModel : (
			pricingPerDayModel.reduce((acc, cur) => {
				const updatedModel =
					cur.id !== pricingPerDay.id ?
						cur
					:	{ ...cur, days: cur.days.filter((day) => day.id !== pricingPerDay.dayOfWeek) };

				return [...acc, ...(isFullArray(updatedModel.days) ? [updatedModel] : [])];
			}, [])
		);

	const disableSave =
		isEmptyArray(selectedDays) ||
		isFullArray(
			updatedModels.filter((model) => {
				const daysToAdd = model.start.getDate() !== model.end.getDate() ? 1 : 0;

				const selectedStart = modifyDate(model.start, { date: pricingPerDay.start.getDate() });
				const selectedEnd = modifyDate(model.end, {
					date: pricingPerDay.start.getDate() + daysToAdd,
				});

				return (
					isFullArray(
						model.days.filter((day) =>
							isFullArray(selectedDays.filter((selectedDay) => day.id === selectedDay.id)),
						),
					) &&
					((pricingPerDay.start.getTime() <= selectedStart.getTime() &&
						pricingPerDay.end.getTime() >= selectedEnd.getTime()) ||
						(pricingPerDay.start.getTime() >= selectedStart.getTime() &&
							pricingPerDay.end.getTime() <= selectedEnd.getTime()))
				);
			}),
		);

	const handleAutocomplete = (event, values) => {
		const updatedOptions = values.reduce((accumulator, currentValue) => {
			if (currentValue.id === 7) {
				return [...weekdays, ...weekend];
			} else if (currentValue.id === 8) {
				return [...weekdays];
			} else if (currentValue.id === 9) {
				return [...weekend];
			} else {
				return [...accumulator, currentValue];
			}
		}, []);
		setSelectedDays(updatedOptions);
	};

	const getOptionLabel = (selected) => {
		return selected.name;
	};

	const handlePricingAdd = () => {
		const priceValue = createPricingModel(
			Date.now(),
			pricingPerDay.start,
			pricingPerDay.end,
			pricingPerDay.allDay,
			pricingPerDay.price,
			periodType,
			selectedDays,
		);

		if (isEdit) {
			priceValue.isEdit = true;
			priceValue.initialModelId = pricingPerDay.id;
			priceValue.dayOfWeek = pricingPerDay.dayOfWeek;
		}

		save({ pricePerDay: priceValue, pricePerDayRemove: null });
		onClose();
	};

	const handlePricingRemove = () => {
		save({ pricePerDayRemove: pricingPerDay, pricePerDay: null });
		onClose();
	};

	const handleKeyUp = (event) => {
		ignoreKeyClick(event, 'Enter');
	};

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={handlePricingClose}
			open={open}
			variant='temporary'
		>
			<div>
				<StyledButton
					onClick={handlePricingClose}
					startIcon={<CloseIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.close')}
				</StyledButton>
				<Typography variant='h3'>{`${t('ui.price')} ${periodLabel.toLowerCase()}`}</Typography>
				<Divider className={classes.divider} />
				<form>
					<TextField
						InputLabelProps={{ shrink: true }}
						InputProps={{
							inputComponent: CustomNumberField,
							startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
						}}
						fullWidth
						label={t('ui.amount')}
						name='amountPer'
						onChange={handlePricePerDayChange}
						onKeyPress={handleKeyUp}
						size='medium'
						value={pricingPerDay.price}
						variant='outlined'
					/>
				</form>
				<Divider className={classes.divider} />
				<Autocomplete
					ListboxProps={{ style: { maxHeight: '210px' } }}
					filterSelectedOptions={true}
					getOptionLabel={getOptionLabel}
					multiple
					onChange={handleAutocomplete}
					options={weekOptions}
					renderInput={(params) => (
						<TextField
							{...params}
							InputLabelProps={{ shrink: true }}
							fullWidth
							label='Days'
							size='medium'
							variant='outlined'
						/>
					)}
					value={selectedDays}
				/>
			</div>
			<div>
				<StyledButton
					disabled={disableSave}
					fullWidth
					onClick={handlePricingAdd}
					variant='contained-secondary'
				>
					{t('ui.button.contained.save')}
				</StyledButton>
				{isEdit ?
					<StyledButton
						className={classes.removeButton}
						fullWidth
						onClick={handlePricingRemove}
						variant='contained-delete'
					>
						{t('ui.button.contained.remove')}
					</StyledButton>
				:	null}
			</div>
		</Drawer>
	);
};

export default UpdatePerDay;
