import { useEffect } from 'react';

import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { createSummaryPeriod, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import AddPricing from '../../ActionFlows/AddPricing';

interface EditPricingModelProps {
	pricingDetails?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPrice?(...args: unknown[]): unknown;
}

const EditPricingModel = (props: EditPricingModelProps) => {
	const { pricingDetails, onFetchPrice } = props;
	const location = useLocation();
	const { id } = useParams();

	const {
		data: pricingDetailsData,
		loading: pricingDetailsLoading,
		error: pricingDetailsError,
	} = pricingDetails;
	const pricingDetailsReady =
		isObject(pricingDetailsData) && !pricingDetailsLoading && !pricingDetailsError;
	const flexPricingReady = pricingDetailsReady && pricingDetailsData.calculationType === 'flex';

	useEffect(() => {
		onFetchPrice(id);
	}, [id, onFetchPrice]);

	const pricingPerMinuteModel =
		flexPricingReady ?
			createSummaryPeriod(
				pricingDetailsData.pricingPeriods.filter((period) => period.periodType === 'minute'),
			)
		:	[];
	const pricingPerHourModel =
		flexPricingReady ?
			createSummaryPeriod(
				pricingDetailsData.pricingPeriods.filter((period) => period.periodType === 'hour'),
			)
		:	[];
	const pricingPerDayModel =
		flexPricingReady ?
			createSummaryPeriod(
				pricingDetailsData.pricingPeriods.filter((period) => period.periodType === 'day'),
			)
		:	[];
	const pricingPerWeekModel =
		flexPricingReady ?
			createSummaryPeriod(
				pricingDetailsData.pricingPeriods.filter((period) => period.periodType === 'week'),
			)
		:	[];

	const fixedPricingModel =
		pricingDetailsReady && pricingDetailsData.calculationType === 'fixed' ?
			// eslint-disable-next-line no-unused-vars
			pricingDetailsData.pricingPeriods.map(
				({ start, end, days, id, name, ...pricingPeriods }) => pricingPeriods,
			)
		:	[];
	const isFree =
		isObject(pricingDetailsData) &&
		pricingDetailsData.pricingPeriods.reduce((acc, model) => acc && model.price === 0, true);

	// const activeFirstStep = 2 + (!isEmptyArray(pricingPerMinuteModel) ? 1 : 0) + (!isEmptyArray(pricingPerHourModel) ? 1 : 0) + (!isEmptyArray(pricingPerDayModel) ? 1 : 0) + (!isEmptyArray(pricingPerWeekModel) ? 1 : 0);
	return pricingDetailsReady ?
			<AddPricing
				category={pricingDetailsData.categoryReference}
				editing
				name={pricingDetailsData.name}
				organisation={pricingDetailsData.organisationReference}
				pricingModelId={pricingDetailsData.id}
				{...{ pricingPerMinuteModel, pricingPerHourModel, pricingPerDayModel, pricingPerWeekModel }}
				editRedirect={location.state.detail}
				fixedPricingModel={fixedPricingModel}
				pricingType={
					isFree && !pricingDetailsData?.pricingUsage ? 'free' : pricingDetailsData.calculationType
				}
				pricingUsage={
					isObject(pricingDetailsData) &&
					isObject(pricingDetailsData.pricingUsage) &&
					pricingDetailsData.pricingUsage
				}
				vatPercentage={pricingDetailsData.vatPercentage}
			/>
		:	null;
};

const mapStateToProps = (state) => {
	return {
		pricingDetails: state.details.fetchPrice,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPrice: (id) => dispatch(actions.fetchPrice(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPricingModel);
