import React, { useRef, useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Drawer,
	DrawerProps,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormContainerContext from '~components/dialogs/formContainerProvider';
import { FormWrapperRefProps } from '~interfaces/refProps';
import i18n from '~lib/i18n';

interface FormDrawerProps extends Omit<DrawerProps, 'children'> {
	saveLabel?: string;
	loading?: boolean;
	children: JSX.Element;
}

const FormDrawer = ({
	anchor = 'right',
	saveLabel = i18n.t('ui.save'),
	children,
	loading = false,
	title,
	...drawerProps
}: FormDrawerProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();
	const largerThanXs = useMediaQuery(theme.breakpoints.up('xs'));

	const [disabled, setDisabled] = useState(true);

	// Intercept the children, attach a ref, and use it to
	// fire the onSubmit
	const childRef = useRef<FormWrapperRefProps>(null);

	const handleSubmit = () => {
		// Trigger the child forms onSumbit if it is provided
		if (childRef.current?.onSubmit) {
			childRef.current.onSubmit();
		} else {
			console.warn('No onSubmit handler, did you forget it?');
		}
	};

	const handleClose = () => {
		if (drawerProps.onClose) {
			drawerProps.onClose({}, 'escapeKeyDown');
		}
	};

	return (
		<Drawer {...drawerProps} anchor={anchor}>
			<Stack spacing={1} sx={{ height: 1, display: 'flex' }}>
				<Box sx={{ pt: 2, px: 2 }}>
					<Button startIcon={<CloseIcon />} variant='text' onClick={handleClose}>
						{t('ui.close')}
					</Button>
				</Box>
				{title != null && (
					<Typography variant='h3' sx={{ px: 2, pt: 2 }}>
						{title}
					</Typography>
				)}
				<Box
					sx={{
						px: 2,
						flexGrow: 1,
						flexDirection: 'column',
						minWidth: 300,
						maxWidth: 600,
						overflow: 'auto',
					}}
				>
					<FormContainerContext.Provider
						value={{
							disabled: disabled,
							setDisabled: setDisabled,
						}}
					>
						{React.cloneElement(children, { ref: childRef })}
					</FormContainerContext.Provider>
				</Box>
				<Box sx={{ pb: 2, px: 2, pt: 1 }}>
					<LoadingButton
						variant='contained'
						onClick={handleSubmit}
						loading={loading}
						disabled={disabled}
						fullWidth
					>
						{saveLabel}
					</LoadingButton>
				</Box>
			</Stack>
		</Drawer>
	);
};

export default FormDrawer;
