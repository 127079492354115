import { Typography, Box, Tooltip as MUITooltip } from '@mui/material';

import { useStyles } from './style';

interface TooltipProps {
	infoTitle?: unknown;
	content?: object;
}

const Tooltip = (props: TooltipProps) => {
	const { infoTitle, content } = props;
	const classes = useStyles();

	return (
		<Box alignItems='center' display='flex'>
			<MUITooltip
				arrow
				classes={classes}
				placement='top'
				title={<Typography>{infoTitle}</Typography>}
			>
				{content}
			</MUITooltip>
		</Box>
	);
};

export default Tooltip;
