import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import InternalNote from './InternalNote';
import LocationInfo from './LocationInfo';
import ServiceInfo from './ServiceInfo';
import { isObject } from '../../../../../shared/utility';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

const PREFIX = 'Summary';

const classes = {
	root: `${PREFIX}-root`,
};

const StyledSummaryLoading = styled(SummaryLoading)(() => ({
	[`& .${classes.root}`]: {
		flexGrow: 1,
	},
}));

interface SummaryProps {
	className?: string;
	bookingData?: object;
	loading?: boolean;
	userRoleData?: string;
	userImageData?: unknown[];
	fetchUnavailabilityData?: object;
	fetchUnavailabilityLoading?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, fetchUnavailabilityData, fetchUnavailabilityLoading } = props;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return isObject(fetchUnavailabilityData) && !fetchUnavailabilityLoading ?
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<ServiceInfo {...props} />
				</Grid>
				<Grid {...gridProps}>
					<LocationInfo {...props} />
				</Grid>
				<Grid {...gridProps}>
					<InternalNote {...props} />
				</Grid>
			</Grid>
		:	<StyledSummaryLoading />;
};

export default Summary;
