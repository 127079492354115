import { useState, useEffect } from 'react';

import {
	Autocomplete,
	Card,
	CardHeader,
	CardContent,
	Chip,
	CircularProgress,
	TextField,
	Typography,
	Box,
	MenuItem,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { StyledButton, SelectWithLazyLoading } from '../../../../../components';
import { languages } from '../../../../../constantsOld';
import {
	isFullArray,
	isObject,
	isEmptyArray,
	isFullString,
	isInteger,
	isEmptyString,
} from '../../../../../shared/utility';
import { validateInput } from '../../../../../shared/validation';
import * as actions from '../../../../../store/actions';

interface GenerateLinksProps {
	save?(...args: unknown[]): unknown;
	emails: unknown[];
	alreadyExistingEmails?: unknown[];
	onEmailValidations?(...args: unknown[]): unknown;
	onFetchOrganisations?(...args: unknown[]): unknown;
	savedOrganisation?: object;
	onFetchUserGroups?(...args: unknown[]): unknown;
	dispatchNewEmailGroup?(...args: unknown[]): unknown;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	emailValidations?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	userGroupsObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const GenerateLinks = (props: GenerateLinksProps) => {
	const {
		save,
		emails: savedEmails,
		onEmailValidations,
		emailValidations,
		dispatchNewEmailGroup,
		userGroupsObject,
		onFetchUserGroups,

		onFetchOrganisations,
		organisationsList,
		savedOrganisation,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const { isSuperAdmin } = useAuthorize();

	const [emails, setEmails] = useState([]);
	const [addedEmails, setAddedEmails] = useState(savedEmails);
	const [shouldAddEmails, setShouldAddEmails] = useState(false);
	const [error, setError] = useState();
	const [duplicateError, setDuplicateError] = useState(false);
	const [newEmailsAdded, setNewEmailsAdded] = useState(false);

	const [selectedLanguage, setSelectedLanguage] = useState(null);

	const [selectedUserGroup, setSelectedUserGroup] = useState({
		userGroupId: '',
		userGroupName: '',
	});
	const [searchUserGroupHandle, setSearchUserGroupHandle] = useState('');

	const [organisation, setOrganisation] = useState({ name: '', id: '' });

	const [organisationIdFilter, setOrganisationIdFilter] = useState({
		name: 'organisationId',
		value: '',
	});
	const [searchHandle, setSearchHandle] = useState('');

	const {
		data: emailValidationsData,
		loading: emailValidationsLoading,
		error: emailValidationsError,
	} = emailValidations;
	const emailValidationsDone =
		isObject(emailValidationsData) && !emailValidationsLoading && !emailValidationsError;

	useEffect(() => {
		if (isInteger(organisation?.id)) {
			setOrganisationIdFilter({ ...organisationIdFilter, value: organisation.id });
		}
	}, [organisation]);

	useEffect(() => {
		if (savedOrganisation.id !== organisation.id) {
			dispatchNewEmailGroup({ type: 'reset', payload: [] });
			setEmails([]);
			setAddedEmails([]);
			setSelectedUserGroup({ userGroupName: '', userGroupId: '' });
			setSearchUserGroupHandle('');
		}
	}, [savedOrganisation, organisation]);

	useEffect(() => {
		if (emailValidationsDone) {
			setEmails(
				emails.map((email) =>
					emailValidationsData.rejected.indexOf(email.text) === -1 ?
						email
					:	{ ...email, value: false },
				),
			);
			if (!isEmptyArray(emailValidationsData.rejected) && isFullArray(emails)) {
				setError(
					`${t('views.inviteUsers.inputError.alreadyPartOfOrganisation')} ${emailValidationsData.rejected.join(', ')}`,
				);
			}
		}
	}, [emailValidationsDone, shouldAddEmails]);

	useEffect(() => {
		setAddedEmails(savedEmails);
	}, [savedEmails]);

	const handleCultureCode = (language) => {
		if (language === 'dutch') {
			return 'nl-NL';
		}
		if (language === 'english') {
			return 'en-US';
		}
		if (language === 'german') {
			return 'de-DE';
		}
	};

	useEffect(() => {
		const groupEmails = emails.map((item) => item.text);
		if (shouldAddEmails && isObject(emailValidationsData)) {
			setShouldAddEmails(false);
			setError(null);
			dispatchNewEmailGroup({
				type: 'add',
				emails: {
					emails: groupEmails,
					userGroupId: selectedUserGroup.userGroupId,
					userGroupName: selectedUserGroup.userGroupName,
					culture: handleCultureCode(selectedLanguage),
					language: selectedLanguage,
				},
			});
			setAddedEmails(addedEmails.concat(emailValidationsData.accepted));
			setEmails([]);
		}
	}, [shouldAddEmails, emailValidationsData]);

	useEffect(() => {
		save({ addedEmails });
	}, [addedEmails]);

	useEffect(() => {
		if (isFullArray(emails) && newEmailsAdded) {
			setNewEmailsAdded(false);
			const emailsToCheck = emails.map((email) => email.text);
			onEmailValidations({
				emails: emailsToCheck,
				...(isInteger(organisation?.id) && { organisationId: organisation?.id }),
			});
		}
	}, [emails, newEmailsAdded]);

	const handleAddChip = (chip) => {
		if (isValid(chip)) {
			setNewEmailsAdded(true);
			setEmails(emails.concat([{ text: chip, value: true }]));
		}
	};

	const handleClickAdd = () => {
		setShouldAddEmails(true);
	};

	const handlePaste = (e) => {
		e.preventDefault();
		if (error) {
			setError(null);
		}
		const pastedText = e.clipboardData.getData('text');
		const pastedEmails = pastedText.match(/([\w\d\.\+-])+@[\w\d\.-]+\.[\w\d\.-]+/g); //eslint-disable-line
		if (pastedEmails) {
			const validPastedEmails = pastedEmails.filter(
				(email) => !isDuplicateEmail(email) && isEmail(email),
			);
			const toBeAdded = validPastedEmails
				.filter((email, index) => validPastedEmails.indexOf(email) === index)
				.map((email) => ({ text: email, value: true }));
			if (!isEmptyArray(toBeAdded)) {
				setNewEmailsAdded(true);
			}
			setEmails(emails.concat(toBeAdded));
		}
	};

	const handleKeyDown = (e) => {
		if (error) {
			setError(null);
			setDuplicateError(false);
		}

		if ((e.key === ',' || e.key === ' ' || e.key === 'Enter') && e.target.value) {
			e.preventDefault();
			handleAddChip(e.target.value);
		}

		if (e.key === 'Backspace' && isFullArray(emails) && !e.target.value) {
			setEmails((prevEmails) => prevEmails.slice(0, -1));
		}
	};

	const isDuplicateEmail = (email) => {
		return (
			addedEmails.indexOf(email) !== -1 || emails.map((email) => email.text).indexOf(email) !== -1
		);
	};

	const isEmail = (email) => {
		return validateInput(email, { isEmail: true, required: true });
	};

	const isValid = (email) => {
		let errorMessage = null;

		if (isDuplicateEmail(email)) {
			errorMessage = `${email} ${t('views.inviteUsers.inputError.duplicateEmail')}`;
			setDuplicateError(true);
		}

		if (!isEmail(email)) {
			errorMessage = `${email} ${t('views.inviteUsers.inputError.notEmail')}`;
		}

		if (errorMessage) {
			setError(errorMessage);
			return false;
		}

		return true;
	};

	const valid =
		isFullArray(emails) &&
		isObject(emailValidationsData) &&
		isFullArray(emailValidationsData.accepted) &&
		isFullString(selectedLanguage);

	const handleLanguageChange = (e) => {
		e.preventDefault();
		setSelectedLanguage(e.target.value);
	};

	const selectUserGroup = (group) => {
		setSelectedUserGroup({ userGroupId: group.id, userGroupName: group.name });
		setSearchUserGroupHandle(group.name);
	};

	const selectOrganisation = (org) => {
		setOrganisation({ name: org.name, id: org.id });
		setSearchHandle(org.name);
		save({ organisation: { name: org.name, id: org.id } });
	};

	const handleChipBlur = (e) => {
		e.preventDefault();
		handleAddChip(e.target.value);
		if (isEmptyString(e.target.value)) {
			setError(null);
		}
	};

	return (
		<Card className={classes.root}>
			<CardHeader
				subheader={t('views.inviteUsers.subheader.inviteUsers')}
				title={
					<Typography variant='h4'>{t('views.inviteUsers.blockHeader.inviteUsers')}</Typography>
				}
			/>
			<CardContent>
				<Box display='flex' flexDirection='column' rowGap={3}>
					{isSuperAdmin() ?
						<SelectWithLazyLoading
							dataList={organisationsList}
							label={t('ui.organisation')}
							onFetchData={onFetchOrganisations}
							required={true}
							searchHandle={setSearchHandle}
							setSelected={selectOrganisation}
							value={searchHandle}
						/>
					:	null}
					<Autocomplete
						className={classes.inputRoot}
						clearOnBlur={true}
						disableClearable
						disablePortal={true}
						freeSolo
						fullWidth
						id='tags-standard'
						multiple
						options={[]}
						renderInput={(params) => (
							<TextField
								{...params}
								FormHelperTextProps={{
									style: { position: 'absolute', top: '100%', marginLeft: 0 },
								}}
								InputLabelProps={{ shrink: true }}
								error={!!error}
								helperText={error}
								label={t('ui.label.emails')}
								onBlur={handleChipBlur}
								onKeyDown={(event) => handleKeyDown(event)}
								onPaste={handlePaste}
								placeholder={t('views.inviteUsers.email.placeholder')}
							/>
						)}
						renderTags={(value, getTagProps) =>
							value.map((option, index) => (
								<Chip
									{...getTagProps({ index })}
									className={clsx({ [classes.chip]: true, [classes.existingChip]: duplicateError })} //eslint-disable-line
									key={option.text}
									label={option.text}
									onDelete={() => {
										if (error) {
											setError(null);
										}
										setEmails(emails.filter((_, emailIndex) => emailIndex !== index));
									}}
								/>
							))
						}
						value={emails}
					/>
					<SelectWithLazyLoading
						dataList={userGroupsObject}
						disabled={!isFullString(organisation.name) && isSuperAdmin()}
						filter={organisationIdFilter}
						label={t('ui.label.userGroups')}
						onFetchData={onFetchUserGroups}
						required={true}
						searchHandle={setSearchUserGroupHandle}
						setSelected={selectUserGroup}
						value={searchUserGroupHandle}
					/>
					<TextField
						InputLabelProps={{ shrink: true }}
						fullWidth={true}
						label={t('views.userDetails.summary.language')}
						onChange={handleLanguageChange}
						required
						select
						size='medium'
						value={selectedLanguage || ''}
						variant='outlined'
					>
						{!isEmptyArray(languages) &&
							languages.map((ln) => (
								<MenuItem key={ln.code} value={ln.language}>
									{' '}
									{t(`ui.${ln.language}`)}
								</MenuItem>
							))}
					</TextField>

					<StyledButton
						className={classes.addButton}
						disabled={!valid}
						onClick={handleClickAdd}
						variant='contained-secondary'
					>
						{emailValidationsLoading ?
							<CircularProgress />
						:	t('ui.button.contained.add')}
					</StyledButton>
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		emailValidations: state.details.emailValidations,
		userGroupsObject: state.paged.userGroups,
		organisationsList: state.paged.organisations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onEmailValidations: (bodyData) => dispatch(actions.emailValidations(bodyData)),
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.fetchUserGroups(page, filters, concat)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateLinks);
