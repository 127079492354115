import { Card, CardHeader, CardContent } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { FormField, FormFieldLabel } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';

interface InvoiceInfoProps {
	save?(...args: unknown[]): unknown;
	invoiceName?: string;
	invoiceEmail?: string;
	className?: string;
}

const InvoiceInfo = (props: InvoiceInfoProps) => {
	const { save, invoiceName, invoiceEmail, className } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const name = useWizardFormField(invoiceName || '', {
		required: true,
		minLength: 2,
		maxLength: 64,
	});
	useDebouncedWizardSave('invoiceName', name.value, name.isValid, save, 300);

	const email = useWizardFormField(invoiceEmail || '', {
		required: true,
		isEmail: true,
		maxLength: 64,
	});
	useDebouncedWizardSave('invoiceEmail', email.value, email.isValid, save, 300);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				title={t('views.register.organisation.invoiceInfo.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<FormFieldLabel label={t('ui.label.departmentOrPerson')} required>
							<FormField maxLength={64} name='name' variable={name} />
						</FormFieldLabel>
					</div>
					<div className={classes.formGroup}>
						<FormFieldLabel label={t('ui.label.email')} required>
							<FormField maxLength={64} name='contactEmail' variable={email} />
						</FormFieldLabel>
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

export default InvoiceInfo;
