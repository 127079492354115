import { Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { LoadingBar } from '../../../../../../components';

interface NfcCardProps {
	nfcId?: string;
	cardNumber?: string;
}

const NfcCard = (props: NfcCardProps) => {
	const { cardNumber, nfcId } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<>
			<Typography className={classes.nfcCardHeader} variant='h6'>
				{t('ui.label.nfcTags')}
			</Typography>
			<Card className={classes.nfcCard}>
				<Typography variant='h6'>
					{cardNumber ?
						`${t('views.usersAndOrganisations.nfcCards.cardNr')} ${cardNumber}`
					:	<LoadingBar />}
				</Typography>
				<Typography variant='body2'>
					{nfcId ? `${t('views.usersAndOrganisations.nfcCards.nfcId')}: ${nfcId}` : <LoadingBar />}
				</Typography>
			</Card>
		</>
	);
};

export default NfcCard;
