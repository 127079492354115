import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Settings from './Settings';
import { Wizard } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import { isUndefined, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface EditUserGroupSettingsProps {
	userGroupObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updatedUserGroup?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchUserGroup?(...args: unknown[]): unknown;
	onUpdateUserGroup?(...args: unknown[]): unknown;
}

const EditUserGroupSettings = (props: EditUserGroupSettingsProps) => {
	const { updatedUserGroup, onFetchUserGroup, userGroupObject, onUpdateUserGroup } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { id } = useParams();

	const { data: userGroupData, loading: userGroupLoading, error: userGroupError } = userGroupObject;
	const userGroupReady = isObject(userGroupData) && !userGroupLoading && !userGroupError;

	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const [shouldSubmitSettings, setShouldSubmitSettings] = useState(false);
	const [submittingSettings, setSubmittingSettings] = useState(false);
	const [submittedSettings, setSubmittedSettings] = useState(false);

	const {
		data: updatedSettingsData,
		loading: updatedSettingsLoading,
		error: updatedSettingsError,
	} = updatedUserGroup;
	const updateSettingsDone =
		isObject(updatedSettingsData) && !updatedSettingsLoading && !updatedSettingsError;

	const [organisationalBillingAllowed, setOrganisationalBillingAllowed] = useState(false);
	const [personalBillingAllowed, setPersonalBillingAllowed] = useState(false);

	const errorHandling = useError({
		value: updatedUserGroup,
		message: `${t('views.editUserGroupSettings.message.success')} ${userGroupData ? userGroupData.name : '-'}`,
		variant: 'success',
	});

	const dataHasChanged =
		userGroupReady &&
		(userGroupData.allowOrganisationalBilling !== organisationalBillingAllowed ||
			userGroupData.allowPersonalBilling !== personalBillingAllowed);

	useEffect(() => {
		onFetchUserGroup(id);
	}, [id, onFetchUserGroup]);

	useEffect(() => {
		if (shouldSubmitSettings && !submittingSettings && !submittedSettings) {
			setShouldSubmitSettings(false);
			setSubmittingSettings(true);
			errorHandling.setStartAction(true);
			onUpdateUserGroup(
				userGroupData.id,
				userGroupData.name,
				userGroupData.description,
				organisationalBillingAllowed,
				personalBillingAllowed,
			);
		}
	}, [shouldSubmitSettings, submittingSettings, submittedSettings]);

	useEffect(() => {
		if (submittingSettings && updateSettingsDone) {
			setSubmittingSettings(false);
			setSubmittedSettings(true);
		}
	}, [submittingSettings, updateSettingsDone]);

	useEffect(() => {
		if (submittedSettings) {
			navigate(`/user-management/user-groups/${id}/summary`);
		}
	}, [submittedSettings]);

	// reset on error
	useEffect(() => {
		if (updatedSettingsError) {
			setShouldSubmitSettings(false);
			setSubmittingSettings(false);
			setSubmittedSettings(false);
		}
	}, [updatedSettingsError]);

	const callback = (data) => {
		const { allowOrganisationalBilling, allowPersonalBilling, finishedWizard } = data;

		if (!isUndefined(allowOrganisationalBilling)) {
			setOrganisationalBillingAllowed(allowOrganisationalBilling);
		}

		if (!isUndefined(allowPersonalBilling)) {
			setPersonalBillingAllowed(allowPersonalBilling);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);
			setShouldSubmitSettings(true);
		}
	};

	// wizard steps
	const editSettingsSteps = [
		{
			name: t('views.addPolicy.steps.details'),
			content: Settings,
			valid: dataHasChanged,
			props: { organisationalBillingAllowed, personalBillingAllowed, userGroupReady },
		},
	];

	return (
		<Wizard
			callback={callback}
			cancelButtonLabel={t('ui.button.contained.back')}
			finishButtonLabel={t('ui.button.contained.save')}
			handleCancel={() => (window.history.length > 2 ? navigate(-1) : navigate('/'))}
			hasCancelButton
			hasNavHeader
			loading={startedFinishWizard || !userGroupReady}
			steps={editSettingsSteps}
			title={t('views.editUserGroupSettings.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		userGroupObject: state.details.userGroup,
		updatedUserGroup: state.details.updatedUserGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateUserGroup: (
			userGroupId,
			name,
			description,
			allowOrganisationalBilling,
			allowPersonalBilling,
		) =>
			dispatch(
				actions.updateUserGroup(
					userGroupId,
					name,
					description,
					allowOrganisationalBilling,
					allowPersonalBilling,
				),
			),
		onFetchUserGroup: (id) => dispatch(actions.fetchUserGroup(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserGroupSettings);
