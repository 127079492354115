import path from 'path';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Partner from '../interfaces/partner';

class PartnerShipsService extends TopologyService {
	public readonly path = 'partnerships';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of organisations
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getPartners({
		page = 1,
		pageSize = 10,
		...args
	}: {
		status?: 'accepted' | 'pending';
	} & Omit<SearchPagedParameters, 'organisation'>): Promise<PagedResults<Partner>> {
		const { data } = await this._client.get<PagedResponse<PartnerResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				searchTerm: args.searchQuery,
				status: args.status,
				sortBy: 'name',
			},
		});

		return this.mapPagedResponse(data, PartnerShipsService.fromResponse);
	}

	/**
	 * Get the details of a single organisation
	 * @param id
	 * @returns
	 */
	async getOrganisationById(id: string): Promise<Partner> {
		const { data } = await this._client.get<PartnerResponse>(path.join(this.path, id));

		return PartnerShipsService.fromResponse(data);
	}

	static fromResponse(data: PartnerResponse): Partner {
		const { partnerOrganisation, type, status } = data;

		return {
			...PartnerShipsService.fromBaseReferenceResponse(partnerOrganisation),
			status: status,
		};
	}
}

interface PartnerResponse {
	partnerOrganisation: BaseReferenceResponse;
	type: string;
	status: string;
}

export default PartnerShipsService;
