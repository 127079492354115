import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import Placeholder from '../../../../assets/images/ic-placeholder.svg';
import { LoadingBar } from '../../../../components';
import {
	isObject,
	handleHubReference,
	isFullArray,
	isUndefined,
	isNull,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { Search } from '../../../Actions';
import { useStyles } from '../style';

interface ItemCardProps {
	open?: boolean;
	presetData?: object;
	onFetchInstance?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onFetchItemImage?(...args: unknown[]): unknown;
	selectedItem?: object;
	setSelectedItem?(...args: unknown[]): unknown;
	setSelectedUser?(...args: unknown[]): unknown;
	selectedUser?: object;
	onPlanboardBooking?(...args: unknown[]): unknown;
	setHasChangedItem?(...args: unknown[]): unknown;
	isUnavailability?: boolean;
	onFetchUnavailability?(...args: unknown[]): unknown;
	resetAvailability?(...args: unknown[]): unknown;
	onResetListState?(...args: unknown[]): unknown;
	setFetchBookingPrice?(...args: unknown[]): unknown;
	onSearchInstance?(...args: unknown[]): unknown;
	selectType?: string;
	onUpdatePagedState?(...args: unknown[]): unknown;
	setPresetBookingData?(...args: unknown[]): unknown;
	fetchUnavailability?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	fetchItemImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	fetchInstance?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	planboardBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	searchInstance?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const ItemCard = (props: ItemCardProps) => {
	const {
		presetData,
		onFetchInstance,
		fetchInstance,
		onResetState,
		planboardBooking,
		fetchItemImage,
		onFetchItemImage,
		selectedItem,
		setSelectedItem,
		onUpdatePagedState,

		setPresetBookingData,
		setSelectedUser,
		onPlanboardBooking,
		selectedUser,
		setHasChangedItem,
		onFetchUnavailability,
		fetchUnavailability,
		resetAvailability,
		onSearchInstance,
		searchInstance,
		selectType,
		onResetListState,
		setFetchBookingPrice,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { data: fetchUnavailabilityData, loading: fetchUnavailabilityLoading } =
		fetchUnavailability;

	const { data: fetchInstanceData, loading: fetchInstanceLoading } = fetchInstance;

	const { data: bookingData, loading: bookingLoading } = planboardBooking;

	const { data: fetchItemImageData, loading: fetchItemImageLoading } = fetchItemImage;

	const filters = {
		requestedResources: 10,
		excludeImages: true,
		hidePublic: !isSuperAdmin(),
		hideUnassigned: true,
		hideUnpublished: true,
		hideNonBookable: true,
		...(selectType === 'booking' && { hideService: true }),
		...(selectedUser?.id && selectType === 'booking' && { userId: selectedUser.id }),
	};

	useEffect(() => {
		if (!bookingLoading && isObject(presetData) && presetData?.bookingId && isNull(selectedItem)) {
			onPlanboardBooking(presetData.bookingId);
		}
	}, [presetData]);

	useEffect(() => {
		if (
			!fetchUnavailabilityLoading &&
			presetData?.unavailabilityId &&
			!isObject(fetchUnavailabilityData)
		) {
			onFetchUnavailability(presetData.unavailabilityId);
		}
	}, [presetData]);

	useEffect(() => {
		if (
			!fetchInstanceLoading &&
			presetData?.instanceId &&
			presetData.itemSelected &&
			isNull(selectedItem)
		) {
			onFetchInstance(presetData.itemId, presetData.instanceId);
		}
	}, [presetData]);

	useEffect(() => {
		if (!fetchItemImageLoading && presetData?.itemId) {
			if (!fetchItemImageLoading) {
				onFetchItemImage(presetData.itemId);
			}
		}
	}, [presetData]);

	useEffect(() => {
		if (isObject(selectedItem) && !presetData?.itemId) {
			setPresetBookingData({ ...presetData, itemSelected: true, itemId: selectedItem.itemId });
		}
	}, [selectedItem, presetData]);

	useEffect(() => {
		if (isObject(fetchInstanceData)) {
			setSelectedItem({
				title: `${fetchInstanceData.itemReference.name} - ${fetchInstanceData.name}`,
				hubReference: fetchInstanceData.hubReference,
				itemId: fetchInstanceData.itemReference.id,
				instanceId: fetchInstanceData.id,
				clear: true,
				categoryId: fetchInstanceData.categoryReference.id,
				organisationReference: fetchInstanceData.itemReference.hubReference.organisationReference,
			});
		} else if (isObject(bookingData) && presetData.itemSelected) {
			setSelectedItem({
				title: `${bookingData.itemInstance.itemReference.name} - ${bookingData.itemInstance.name}`,
				hubReference: bookingData.itemInstance.hubReference,
				itemId: bookingData.itemInstance.itemReference.id,
				instanceId: bookingData.itemInstance.id,
				clear: bookingData.status === 'upcoming',
				categoryId: bookingData.itemInstance.categoryReference.id,
				organisationReference: bookingData.itemInstance.organisationReference,
			});
			setPresetBookingData({ ...presetData, itemSelected: true });
			setSelectedUser({
				id: bookingData?.userReference?.id,
				firstName: bookingData?.userReference?.firstName,
				lastName: bookingData?.userReference?.lastName,
				email: bookingData?.userReference?.emailAddress,
				clear: bookingData.status === 'upcoming',
			});
		} else if (isObject(fetchUnavailabilityData)) {
			setSelectedItem({
				title: `${fetchUnavailabilityData.itemInstance.itemReference.name} - ${fetchUnavailabilityData.itemInstance.name}`,
				hubReference: fetchUnavailabilityData.hubReference,
				itemId: fetchUnavailabilityData.itemInstance.itemReference.id,
				instanceId: fetchUnavailabilityData.itemInstance.id,
				clear: false,
				categoryId: fetchUnavailabilityData.itemInstance.itemReference.categoryReference.id,
				organisationReference: fetchUnavailabilityData.organisationReference,
			});
		}
	}, [fetchInstanceData, bookingData, fetchUnavailabilityData]);

	useEffect(() => {
		if (isUndefined(presetData) && isObject(selectedItem)) {
			setPresetBookingData({
				instanceId: selectedItem.itemInstanceId,
				itemId: selectedItem.mainItemId,
			});
		}
	}, [selectedItem, presetData]);

	useEffect(() => {
		if (isObject(selectedItem)) {
			setHasChangedItem(true);
		}
	}, [selectedItem]);

	const handleClearItem = () => {
		resetAvailability();
		setSelectedItem(null);
		onUpdatePagedState('planboardItems', null, false);
		onResetState('fetchInstance');
		onResetState('fetchItemImage');
		setPresetBookingData({ ...presetData, itemSelected: false, itemId: undefined });
		onResetState('maintenanceAvailability');
	};

	const loading = !isObject(selectedItem);

	const searchSelect = (value) => {
		setSelectedItem({
			title: value.optionsName,
			hubReference: value.hub,
			itemId: value.item.id,
			instanceId: value.id,
			clear: true,
			categoryId: value.item.categoryId,
			organisationReference: value.hub.organisation,
		});
		if (isObject(bookingData)) {
			setFetchBookingPrice(true);
		}
	};

	const handleRenderOption = (props, option) => (
		<li {...props} key={option.key}>
			<Box disabled={true} display='flex' flexDirection='column'>
				<Typography variant='h6'>{option.optionsName}</Typography>
				<Typography variant='body2'>{option.address}</Typography>
			</Box>
		</li>
	);

	const restState = () =>
		onResetListState('searchInstance', { data: null, loading: false, error: null });

	const searchProps = {
		renderOption: handleRenderOption,
		fetchData: onSearchInstance,
		filters,

		searchSelect: searchSelect,
		data: searchInstance,
		restState,
		noOptions: t('views.planboard.noOptions.item'),
	};

	return (
		<div>
			<Typography className={classes.cardHeaders} variant='h5'>
				{t('views.planboard.addBooking.itemDetails')}
			</Typography>
			{presetData?.itemSelected ?
				<Card className={classes.itemCard}>
					{loading ?
						<LoadingBar
							style={{ height: 80, width: 80, marginLeft: 8, marginTop: 8, marginBottom: 8 }}
						/>
					:	<img
							className={clsx({
								[classes.media]: true,
								[classes.placeholder]: !isFullArray(fetchItemImageData),
							})}
							src={isFullArray(fetchItemImageData) ? fetchItemImageData[0] : Placeholder}
						/>
					}
					<CardContent>
						<Typography variant='h5'>
							{' '}
							{loading ?
								<LoadingBar />
							:	selectedItem.title}{' '}
						</Typography>
						<Typography variant='body2'>
							{' '}
							{loading ?
								<LoadingBar />
							:	handleHubReference(selectedItem.hubReference)}
						</Typography>
					</CardContent>
					{selectedItem?.clear ?
						<IconButton className={classes.closeButton} onClick={handleClearItem} size='small'>
							{' '}
							<CloseIcon />{' '}
						</IconButton>
					:	null}
				</Card>
			:	<Search {...searchProps} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchInstance: state.details.fetchInstance,
		fetchItemImage: state.details.fetchItemImage,
		planboardBooking: state.details.planboardBooking,
		fetchUnavailability: state.details.fetchUnavailability,
		searchInstance: state.list.searchInstance,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPlanboardBooking: (bookingId) => dispatch(actions.planboardBooking(bookingId)),
		onFetchInstance: (itemId, instanceId) => dispatch(actions.fetchInstance(itemId, instanceId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onFetchItemImage: (itemId) => dispatch(actions.fetchItemImage(itemId)),
		onFetchUnavailability: (unavailabilityId) =>
			dispatch(actions.fetchUnavailability(unavailabilityId)),
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
		onSearchInstance: (filters) => dispatch(actions.searchInstance(filters)),
		onResetListState: (state, data) => dispatch(actions.updateListState(state, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
