import { useEffect, useState } from 'react';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility'; // import { useDetailPageBackButton } from '../../../shared/hooks';

interface HeaderProps {
	className?: string;
	type?: 'personal' | 'expenses' | 'revenue';
	revenueData?: object;
	loading?: boolean;
	title?: string;
	month?: string;
	route?: string;
	date?: string;
}

const Header = (props: HeaderProps) => {
	const { className, title, date } = props;
	const location = useLocation();
	const auth = useAuth();
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const role = auth.user?.profile.role.toLowerCase();
	const isSuperAdmin = role === 'superadmin';
	const classes = useStyles();
	const [pathName, setPathName] = useState('');
	const [buttonName, setButtonName] = useState('');
	const [titleName, setTitleName] = useState('');

	useEffect(() => {
		if (isSuperAdmin) {
			setPathName(`/financeManagement/expenses/${date}/${title}/organisations/expenses`);
			setButtonName(title);
			setTitleName(title);
		} else {
			setPathName('/financeManagement/expenses');
			setButtonName(t('ui.expenses'));
			setTitleName(title);
		}
	}, [isSuperAdmin]);

	const handleBackButtonChange = () => {
		navigate(pathName);
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Box>
				<Typography component='h2' gutterBottom variant='overline'>
					<Hidden only={['xs', 'sm']}>
						<StyledButton
							className={classes.backButton}
							onClick={handleBackButtonChange}
							startIcon={<KeyboardArrowLeftOutlinedIcon />}
							variant='inline-default'
						>
							{buttonName}
						</StyledButton>
					</Hidden>
				</Typography>
				<Typography component='h1' variant='h3'>
					{!isFullString(titleName) ?
						<LoadingBar />
					:	titleName}
				</Typography>
			</Box>
		</div>
	);
};

export default Header;
