import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { localizeDateTime } from '../../../../../shared/datetime';
import { isFullString } from '../../../../../shared/utility';

const localizeTime = (value) =>
	localizeDateTime(value, undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });

interface HeaderProps {
	className?: string;
	type?: 'personal' | 'expenses' | 'revenue';
	revenueData?: object;
	loading?: boolean;
	title?: string;
	month?: string;
	route?: string;
	date?: string;
	name?: string;
	total?: string;
	id?: string;
}

const Header = (props: HeaderProps) => {
	const { className, date, id, name, title } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const data = history.state.data;
	const classes = useStyles();

	const handleBackButtonChange = () =>
		navigate(`/financeManagement/revenue/${date}/${title}/partner/${id}/${name}/parsonal`);

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{`${localizeTime(data?.start)} ${t('ui.till')} ${localizeTime(data?.end)}`}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{!isFullString(data?.userReference?.name) ?
					<LoadingBar />
				:	data?.userReference?.name}
			</Typography>
		</div>
	);
};

export default Header;
