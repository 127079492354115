import { useState } from 'react';

import {
	Box,
	Stack,
	TextFieldProps as MuiTextFieldProps,
	TextField as MuiTextField,
} from '@mui/material';

type TextFieldProps = {
	disableCharacterCount?: boolean;
} & MuiTextFieldProps;

const TextField = ({
	disableCharacterCount = false,
	// The empty space makes sure the space is reserved, so we don't get the jumping behaviour
	...muiTextFieldProps
}: TextFieldProps) => {
	const [focussed, setFocussed] = useState(false);

	const maxLength =
		muiTextFieldProps.slotProps?.htmlInput?.maxLength ?? muiTextFieldProps.inputProps?.maxLength;
	const shouldShowCharacterCount =
		!disableCharacterCount && maxLength && focussed && muiTextFieldProps.value?.length > 0;

	return (
		<MuiTextField
			{...muiTextFieldProps}
			helperText={
				!shouldShowCharacterCount && !muiTextFieldProps.helperText ?
					' '
				:	<Stack
						direction='row'
						sx={{
							width: 1,
							justifyContent: 'space-between',
							pr: muiTextFieldProps.multiline ? 2 : 4,
						}}
					>
						<Box>{muiTextFieldProps.helperText}</Box>
						{shouldShowCharacterCount && `${muiTextFieldProps.value?.length}/${maxLength}`}
					</Stack>
			}
			minRows={muiTextFieldProps.multiline ? 3 : muiTextFieldProps.minRows}
			onFocus={() => setFocussed(true)}
			onBlur={() => setFocussed(false)}
			slotProps={{
				...muiTextFieldProps.slotProps,
				formHelperText: {
					sx: {
						width: 1,
						display: 'flex',
						...(!muiTextFieldProps.helperText && {
							height: 0,
							overflow: 'visible',
						}),
					},
				},
			}}
		/>
	);
};

export default TextField;
