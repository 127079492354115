import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, useTheme } from '@mui/material';

import { DayOfWeekEnum } from '~enums';

interface WeekToggleButtonGroupProps extends ToggleButtonGroupProps {
	value: DayOfWeekEnum[];
	onChange?: (event: React.MouseEvent<HTMLElement>, value: DayOfWeekEnum[]) => void;
}

const WeekToggleButtonGroup2 = ({ ...props }: WeekToggleButtonGroupProps) => {
	const theme = useTheme();

	const radius =
		props.size === 'small' ? '30px'
		: props.size === 'medium' ? '36px'
		: '42px';

	return (
		<ToggleButtonGroup
			{...props}
			sx={{
				gap: 0.25,
				'& .Mui-selected': {
					bgcolor: theme.palette.primary.main,
					color: theme.palette.common.white,
				},
				'&  .Mui-selected:hover': {
					bgcolor: theme.palette.primary.light,
					color: theme.palette.common.white,
				},
				'&  .MuiToggleButtonGroup-grouped': {
					mx: 0,
					border: 0,
					height: radius,
					width: radius,
					borderRadius: radius,
				},
			}}
		>
			{Object.values(DayOfWeekEnum).map((el: DayOfWeekEnum) => (
				<ToggleButton key={el} value={el}>
					{el[0]}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

const WeekToggleButtonGroup = ({ ...props }: WeekToggleButtonGroupProps) => {
	const theme = useTheme();

	return (
		<ToggleButtonGroup
			{...props}
			sx={{
				'& .Mui-selected': {
					bgcolor: theme.palette.secondary.dark,
					// color: theme.palette.common.white,
				},
				'.MuiToggleButtonGroup-grouped': {
					bgcolor: 'white',
					'&.Mui-selected': {
						backgroundColor: theme.palette.secondary.main,
					},
				},
			}}
		>
			{Object.values(DayOfWeekEnum).map((el: DayOfWeekEnum) => (
				<ToggleButton key={el} value={el}>
					{el[0]}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

export default WeekToggleButtonGroup;
