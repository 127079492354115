import { useEffect } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../../components';
import * as actions from '../../../../../../store/actions';

interface UnassignDevicesProps {
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	itmeId?: string;
	deviceIds?: unknown[];
	fetchData?(...args: unknown[]): unknown;
	onRemoveAccessDevices?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	removeAccessDevices?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const UnassignDevices = ({
	open = false,
	itmeId,
	deviceIds,
	onClose,
	fetchData,
	removeAccessDevices,
	onRemoveAccessDevices,
	onResetStateCondition,
}: UnassignDevicesProps) => {
	const { enqueueSnackbar } = useSnackbar();

	const { t } = useTranslation('general');

	const {
		success: removeAccessDevicesSuccess,
		loading: removeAccessDevicesLoading,
		error: removeAccessDevicesError,
	} = removeAccessDevices;

	const handleConfirm = () => {
		onRemoveAccessDevices(itmeId, deviceIds);
	};

	const handleCloseUnassignDevices = () => onClose();

	useEffect(() => {
		if (removeAccessDevicesSuccess) {
			fetchData();
			handleCloseUnassignDevices();
			onResetStateCondition('removeAccessDevices', false);
			enqueueSnackbar(t('views.unlinkDialog.success.message'), { variant: 'success' });
		} else if (removeAccessDevicesError) {
			onResetStateCondition('removeAccessDevices', false);
			enqueueSnackbar(
				removeAccessDevicesError?.message ? removeAccessDevicesError.message : 'error',
				{ variant: 'error' },
			);
		}
	}, [removeAccessDevicesSuccess, removeAccessDevicesError]);

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					removeAccessDevicesLoading ?
						<CircularProgress disableShrink size={24} />
					:	t('ui.confirm'),
			}}
			handleClose={handleCloseUnassignDevices}
			loading={removeAccessDevicesLoading || removeAccessDevicesSuccess}
			open={open}
			title={t('ui.unassignDevices.dialog.title')}
		>
			<Typography>{t('ui.unassignDevices.dialog.description')}</Typography>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		removeAccessDevices: state.condition.removeAccessDevices,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRemoveAccessDevices: (itemId, devices) =>
			dispatch(actions.removeAccessDevices(itemId, devices)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnassignDevices);
