// TODO: Could this be done as an enum?
const queryParameters = {
	Date: 'date',
	Filter: 'f',
	Item: 'item',
	Language: 'lang',
	OrderBy: 'order',
	Organisation: 'org',
	PageNumber: 'page',
	PageSize: 'size',
	Periodicity: 'step',
	SearchQuery: 'q',
	SortBy: 'sort',
	User: 'u',
} as const;

export default queryParameters;
