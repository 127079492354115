import { useEffect } from 'react';

import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { connect } from 'react-redux';

import Actions from './Actions';
import Bookings from './Bookings';
import Items from './Items';
import Reservations from './Reservations';
import Revenue from './Revenue';
import Tickets from './Tickets';
import { isFullString } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import { useMonthSwitcher } from '../hooks';
import { ServiceTicketsDataGrid, TicketsSummaryCard } from '~features/maintenance';

interface SummaryProps {
	onDashboardActions?(...args: unknown[]): unknown;
	onDashboardItemsOverview?(...args: unknown[]): unknown;
	onDashboardBookingsOverview?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	dashboardOrganisationFilter?: object;
	onDashboardTicketsStatus?(...args: unknown[]): unknown;
}

const Summary = (props: SummaryProps) => {
	const {
		onDashboardActions,
		onDashboardItemsOverview,
		onDashboardBookingsOverview,
		onResetState,
		dashboardOrganisationFilter,
		onDashboardTicketsStatus,
	} = props;

	const smallCard = { item: true, xs: 12, md: 4, lg: 4, xl: 4 };
	const bigCard = { item: true, xs: 12, md: 8, lg: 8, xl: 8 };
	const filters = {
		...(isFullString(dashboardOrganisationFilter?.valueName) && {
			organisationId: dashboardOrganisationFilter?.valueId,
		}),
	};

	const monthSwitcher = useMonthSwitcher();
	useEffect(() => {
		onDashboardActions(filters);
		onDashboardItemsOverview(filters);
		onDashboardTicketsStatus();
		return () => {
			onResetState('dashboardActions');
			onResetState('dashboardItemsOverview');
			onResetState('dashboardTicketsStatus');
		};
	}, [dashboardOrganisationFilter.valueId]);

	useEffect(() => {
		if (monthSwitcher?.currentMonth) {
			onDashboardBookingsOverview(monthSwitcher.currentMonth, dashboardOrganisationFilter.valueId);
		}
		return () => {
			onResetState('dashboardBookingsOverview');
		};
	}, [monthSwitcher.currentMonth, dashboardOrganisationFilter.valueId]);

	console.log(dashboardOrganisationFilter);

	return (
		<Grid container spacing={3} sx={{ flexGrow: 1, paddingTop: 3 }}>
			<Grid {...smallCard}>
				<Reservations monthSwitcher={monthSwitcher} />
			</Grid>
			<Grid {...smallCard}>
				<Items />
			</Grid>
			<Grid {...smallCard}>
				<Revenue monthSwitcher={monthSwitcher} />
			</Grid>
			<Grid {...bigCard}>
				<Bookings dashboardOrganisationFilter={dashboardOrganisationFilter} />
			</Grid>
			<Grid {...smallCard}>
				<Actions />
			</Grid>
			<Grid {...bigCard}>
				<TicketsSummaryCard
					organisationId={
						dashboardOrganisationFilter?.valueId !== 'all' ?
							dashboardOrganisationFilter?.valueId.toString()
						:	undefined
					}
				/>
			</Grid>
		</Grid>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardActions: (filters) => dispatch(actions.dashboardActions(filters)),
		onDashboardItemsOverview: (filters) => dispatch(actions.dashboardItemsOverview(filters)),
		onDashboardBookingsOverview: (dateTime, organisationId) =>
			dispatch(actions.dashboardBookingsOverview(dateTime, organisationId)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onDashboardTicketsStatus: () => dispatch(actions.dashboardTicketsStatus()),
	};
};

export default connect(null, mapDispatchToProps)(Summary);
