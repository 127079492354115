import { useEffect } from 'react';

import { ButtonProps } from '@mui/material';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import pageButtonsAtom from '../atoms/pageButtonsAtom';
import pageTitleAtom from '../atoms/pageTitleAtom';

interface PageButtonsProps {
	pageTitle?: string;
	setPageTitle: (value: string | undefined) => void;
	pageButtons: ButtonProps[];
	setPageButtons: (value: ButtonProps[]) => void;
}

/**
 * A hook to set the buttons defined in the header of
 * the page layout.
 * Why use this hook instead of using the atom directly? We want
 * the buttons to be reset if a user leaves the pages the button
 * are set. By using this hook we have control and we can make sure
 * that the buttons are reset whenever the component unmounts.
 * Tried to do this using context of React. But the state wasn't
 * properly updated
 */
const usePageHeader = (): PageButtonsProps => {
	const [pageTitle, setPageTitle] = useAtom(pageTitleAtom);
	const resetPageTitle = useResetAtom(pageTitleAtom);

	const [pageButtons, setPageButtons] = useAtom(pageButtonsAtom);
	const resetPageButtons = useResetAtom(pageButtonsAtom);

	useEffect(() => {
		return () => {
			resetPageTitle();
			resetPageButtons();
		};
	}, []);

	return {
		pageTitle: pageTitle,
		setPageTitle: setPageTitle,
		pageButtons: pageButtons,
		setPageButtons: setPageButtons,
	};
};

export default usePageHeader;
