import React, { useRef, useState } from 'react';

import { Search as SearchIcon } from '@mui/icons-material';
import {
	Autocomplete,
	AutocompleteInputChangeReason,
	AutocompleteProps,
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';

import { useDebounceCallback } from '~hooks';
import { BaseReference } from '~interfaces';

interface AsyncAutocompleteProps<T extends BaseReference>
	extends Omit<AutocompleteProps<T, false, boolean | undefined, undefined>, 'renderInput'> {
	label: string;
	/**
	 * Enable to trigger and onInputChange on a manual search
	 */
	enableManualSearch?: boolean;
	/**
	 * The delay in milliseconds to use when not performing manual searches
	 */
	delay?: number;
	/**
	 * Enable inifinite scroll functionality. Use this with the
	 * onOverflow handler
	 */
	enableInfiniteScroll?: boolean;
	/**
	 * An event raised when the list overflows. E.g. on a scroll
	 */
	onOverflow?: (e: React.WheelEvent<HTMLLIElement>) => void;
	required?: boolean;
}

const AsyncAutocomplete = <T extends BaseReference>({
	label,
	enableManualSearch = false,
	enableInfiniteScroll = true,
	onOverflow,
	onInputChange,
	required = false,
	delay,
	...autocompleteProps
}: AsyncAutocompleteProps<T>) => {
	const eventRef = useRef<React.ChangeEvent<HTMLInputElement>>(null);
	const debounceCallback = useDebounceCallback();

	const [inputValue, setInputValue] = useState('');

	/**
	 * Handle scrolling of the wheel
	 * @returns
	 */
	const handleWheel = (event: React.WheelEvent<HTMLUListElement>) => {
		if (!enableInfiniteScroll) {
			return;
		}

		const ulElement = (event.target as HTMLLIElement).parentElement as HTMLUListElement;
		const isBottomList = ulElement.scrollHeight - ulElement.scrollTop - 1 <= ulElement.clientHeight;
		if (isBottomList && !autocompleteProps.loading && onOverflow) {
			onOverflow(event);
		}
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setInputValue(value);

		if (!enableManualSearch && onInputChange != null) {
			debounceCallback(() => onInputChange(event, value, reason), delay);
		} else {
			eventRef.current = event;
		}
	};

	const handleSearchClick = () => {
		onInputChange?.(eventRef.current, inputValue, 'input');
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' && onInputChange != null && enableManualSearch) {
			onInputChange(eventRef.current, inputValue, 'input');
		}
	};

	return (
		<Autocomplete
			{...autocompleteProps}
			value={autocompleteProps.value ?? null}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			onKeyDown={handleKeyDown}
			filterOptions={(options) => options}
			isOptionEqualToValue={(option, value) => option.id == value.id}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={label}
					slotProps={{
						input: {
							...params.InputProps,
							...(autocompleteProps.size === 'small' && {
								sx: { height: 44 },
							}),
							endAdornment: (
								<InputAdornment position='end'>
									{autocompleteProps.loading && <CircularProgress color='inherit' size={20} />}
									{params.InputProps.endAdornment}
									{enableManualSearch && (
										<IconButton onClick={handleSearchClick}>
											<SearchIcon />
										</IconButton>
									)}
								</InputAdornment>
							),
						},
						inputLabel: {
							...params.InputLabelProps,
							sx: {
								...(autocompleteProps.size === 'small' && {
									// Arbitrary value, determined by eye to line it out in the middel
									top: 5,
									'&.MuiInputLabel-shrink': { top: 0 },
								}),
							},
						},
					}}
				/>
			)}
			ListboxProps={{
				onWheelCapture: handleWheel,
			}}
		/>
	);
};

export type { AsyncAutocompleteProps };

export default AsyncAutocomplete;
