import { Card, CardContent, Typography, Box, TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { SelectWithLazyLoading, InfoLabel } from '../../../../../components';
import { isFullString, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import ItemHeader from '../../../ActionFlows/AddItem/ItemHeader';

interface EditCardProps {
	types?: object;
	pricing?: object;
	editType?: string;
	selectedItems?: unknown[];
	businessPricing?: object | string;
	setBusinessPricing?(...args: unknown[]): unknown;
	selectedPolicy?: object | string;
	onFetchTerms?(...args: unknown[]): unknown;
	onFetchPricingModels?(...args: unknown[]): unknown;
	onFetchPolicies?(...args: unknown[]): unknown;
	pricingType?: string;
	setPricingType?(...args: unknown[]): unknown;
	organisationIdFilter?: any;
	setSelectedPolicy?(...args: unknown[]): unknown;
	terms?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	pricingModels?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	policiesList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const EditCard = (props: EditCardProps) => {
	const {
		editType,

		selectedItems,
		terms,
		pricingModels,
		policiesList,
		onFetchTerms,
		onFetchPricingModels,
		onFetchPolicies,
		selectedPolicy,
		organisationIdFilter,
		setSelectedPolicy,
		pricingType,
		setPricingType,
		businessPricing,
		setBusinessPricing,
		types,
		pricing,
	} = props;
	const { t } = useTranslation('general');

	const handleDataList = () => {
		if (editType === types.TERMS) {
			return terms;
		}
		if (editType === types.PRICING) {
			return pricingModels;
		}
		if (editType === types.POLICY) {
			return policiesList;
		}
	};

	const handleFetch = () => {
		if (editType === types.TERMS) {
			return onFetchTerms;
		}
		if (editType === types.PRICING) {
			return onFetchPricingModels;
		}
		if (editType === types.POLICY) {
			return onFetchPolicies;
		}
	};

	const handleTypeChange = (event) => {
		const type = event.target.value;
		setPricingType(() => type);
		if (type === pricing.BUSINESS) {
			change({});
			setSelectedPolicy('');
		} else if (type === pricing.PERSONAL) {
			selectedBusinessPricing({});
			setBusinessPricing('');
		}
	};

	const change = (value) => setSelectedPolicy(value);

	const selectedBusinessPricing = (value) => setBusinessPricing(value);

	const itemHeaderProps = {
		padding: 2,
		variant: 'h3',
		variantText: 'body2',
		header: t(`views.edit.management.header.${editType}`),
		subheader: t(`views.edit.management.header.subTitle.${editType}`),
	};

	const selectProps = {
		onChange: handleTypeChange,
		value: pricingType,
		InputLabelProps: { shrink: false },
		label: isFullString(pricingType) ? '' : 'Select opitn',
		select: true,
		size: 'medium',
		fullWidth: true,
		variant: 'outlined',
		FormHelperTextProps: { style: { position: 'absolute', top: '100%', marginLeft: '20px' } },
	};

	const selectLabelProps = {
		isRequired: true,
		variant: 'h4',
		paddingTop: 3,
		paddingBottom: 3,
		name: t('views.edit.management.label.select'),
		info: t('views.edit.management.label.info.select'),
	};

	const personalLabelProps = {
		isRequired: true,
		paddingBottom: 3,
		paddingTop: 3,
		variant: 'h4',
		name: t('views.edit.management.label.header.personal'),
		info: t('views.edit.management.label.personal.info'),
	};

	const selectWithLazyProps = {
		dataList: handleDataList(),
		filter: organisationIdFilter,
		onFetchData: handleFetch(),
		listType: editType === types.TERMS ? 'terms' : null,
		setSelected: change,
		searchHandle: setSelectedPolicy,
		events: { filter: { name: 'sortBy', value: editType === types.TERMS ? 'title' : 'name' } },
		value:
			isObject(selectedPolicy) ?
				selectedPolicy?.name ?
					selectedPolicy?.name
				:	selectedPolicy.title
			:	selectedPolicy,
		placeholder: t(`views.edit.management.search.${editType}`),
	};

	const businessInfoLabelProps = {
		isRequired: true,
		paddingBottom: 3,
		paddingTop: 3,
		variant: 'h4',
		name: t('views.edit.management.label.business.header'),
		info: t('views.edit.management.label.business.info'),
	};

	const businessSelectProps = {
		setSelected: selectedBusinessPricing,
		dataList: handleDataList(),
		listType: null,
		filter: organisationIdFilter,
		onFetchData: handleFetch(),
		searchHandle: setBusinessPricing,
		value: isObject(businessPricing) ? businessPricing?.name : businessPricing,
		events: { filter: { name: 'sortBy', value: editType === types.TERMS ? 'title' : 'name' } },
		placeholder: t('views.edit.management.label.business.placeholder'),
	};

	return (
		<Card>
			<CardContent>
				<ItemHeader {...itemHeaderProps} />
				{editType === types.PRICING ?
					<Box>
						<InfoLabel {...selectLabelProps} />
						<TextField {...selectProps}>
							{
								// eslint-disable-next-line no-unused-vars
								Object.entries(pricing).map(([key, value]) => (
									<MenuItem key={value} value={value}>
										{t(`views.edit.management.pricing.select.${value}`)}
									</MenuItem>
								))
							}
						</TextField>
					</Box>
				:	null}
				{pricingType === pricing.BUSINESS ? null : (
					<Box sx={editType !== types.PRICING ? { paddingTop: 2 } : {}}>
						{editType === types.PRICING ?
							<InfoLabel {...personalLabelProps} />
						:	null}
						<SelectWithLazyLoading {...selectWithLazyProps} />
					</Box>
				)}
				{(
					editType === types.PRICING &&
					(pricingType === pricing.BOTH || pricingType === pricing.BUSINESS)
				) ?
					<Box>
						<InfoLabel {...businessInfoLabelProps} />
						<SelectWithLazyLoading {...businessSelectProps} />
					</Box>
				:	null}
				<Typography sx={{ marginTop: 3 }} variant={editType === types.PRICING ? 'h4' : 'h3'}>
					{t('views.edit.management.item.header')}
				</Typography>
				<Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
					{selectedItems.map((item, index) => (
						<Box key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
							<Typography sx={{ marginRight: 1 }} variant='body2'>
								{item.name}
							</Typography>
							{'-'}
							<Typography sx={{ marginLeft: 1 }} variant='body2'>
								{' '}
								{item.type === 'internal' ?
									item.userGroup.name
								: item.type === 'public' ?
									t('ui.label.public')
								:	item?.consumerName}
							</Typography>
						</Box>
					))}
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		terms: state.paged.terms,
		pricingModels: state.paged.pricingModels,
		policiesList: state.paged.policies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTerms: (page, filters, concat) => dispatch(actions.fetchTerms(page, filters, concat)),
		onFetchPricingModels: (page, filters, concat) =>
			dispatch(actions.fetchPricingModels(page, filters, concat)),
		onFetchPolicies: (page, filters, concat) =>
			dispatch(actions.fetchPolicies(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCard);
