import { useMemo } from 'react';
import {
	TrendingDown as TrendingDownIcon,
	TrendingFlat as TrendingFlatIcon,
	TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { Skeleton, Stack, Typography } from '@mui/material';
import { areaElementClasses, SparkLineChart, useDrawingArea, useYScale } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { colorsSlim } from '~themes/chartColors';

import UserLicenseStatisticsService from '../../services/userLicenseStatisticsService';
import dayjs from 'dayjs';

const service = new UserLicenseStatisticsService();

interface UserLicenseCountChart {
	type: 'requests' | 'rejected' | 'approved' | 'uniqueUsers';
	organisationId?: string;
	period: DateRange;
	periodicity: PeriodicityEnum;
}

const VerticalGradient = ({
	color1,
	color2,
	id,
}: {
	color1: string;
	color2: string;
	id: string;
}) => {
	const { top, height, bottom } = useDrawingArea();
	const svgHeight = top + bottom + height;

	return (
		<defs>
			<linearGradient
				id={id}
				x1='0'
				x2='0'
				y1='0'
				y2={`${svgHeight}px`}
				gradientUnits='userSpaceOnUse'
			>
				<stop offset='5%' stopColor={color1} />
				<stop offset='95%' stopColor={color2} />
			</linearGradient>
		</defs>
	);
};

const UserLicenseCountChart = ({ type, ...props }: UserLicenseCountChart) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			...props,
		}),
		[props],
	);

	const { data, isLoading } = useSWR([service.basePath, fetchParameters], ([_, args]) => {
		if (isLoading) {
			service.abortCurrentRequest();
		}

		return service.getCounts(args);
	});

	if (isLoading) {
		return (
			<Stack direction='row' alignItems='center' spacing={3}>
				<Skeleton variant='rectangular' width={80} height={30} />
				<Skeleton variant='rectangular' width='100%' height={60} />
			</Stack>
		);
	} else if (!data) {
		return 'no data';
	}

	const mappedData = data
		?.map((el) => ({
			timestamp: el.timestamp,
			type: type,
			value: el[type],
		}))
		.reverse();

	if (mappedData.length < 6 && mappedData.length > 0) {
		mappedData.push({
			timestamp: dayjs(mappedData[0].timestamp)
				.subtract(1, props.periodicity === PeriodicityEnum.Month ? 'month' : 'year')
				.toDate(),
			value: 0,
			type: type,
		});
	}

	const difference = mappedData.length > 1 ? mappedData[0].value - mappedData[1].value : 0;

	return (
		<Stack direction='row' spacing={9} sx={{ alignItems: 'center' }} px={3}>
			<Stack direction='row' spacing={1}>
				<Typography variant='h4'>{mappedData[0]?.value ?? 0}</Typography>
				{mappedData.length > 0 && (
					<>
						{difference === 0 ?
							<TrendingFlatIcon />
						: difference > 0 ?
							<TrendingUpIcon color='success' />
						:	<TrendingDownIcon color='error' />}
						<Typography
							color={
								difference > 0 ? 'success'
								: difference < 0 ?
									'error'
								:	undefined
							}
						>
							{`${difference > 0 ? '+' : ''}${difference}`}
						</Typography>
					</>
				)}
			</Stack>

			<SparkLineChart
				data={mappedData.map((el) => el.value)}
				xAxis={{
					reverse: true,
				}}
				height={60}
				area
				curve='natural'
				colors={colorsSlim}
				sx={{
					[`& .${areaElementClasses.root}`]: {
						fill: 'url(#henk)',
					},
				}}
			>
				<VerticalGradient id='henk' color1={colorsSlim[0]} color2='#ffffff' />
			</SparkLineChart>
		</Stack>
	);
};

export default UserLicenseCountChart;
