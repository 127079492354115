import { useEffect } from 'react';

import { CircularProgress, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../components';
import { isNull, isFullArray } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface DialogCardProps {
	dialog?: object;
	setDialog?(...args: unknown[]): unknown;
	onUpdatePublicShare?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onStopSharingExternalItems?(...args: unknown[]): unknown;
	onUnassignUserGroupItem?(...args: unknown[]): unknown;
	updatePublicShare?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	stopSharingExternalItems?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	unassignUserGroupItem?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const DialogCard = (props: DialogCardProps) => {
	const {
		dialog,
		setDialog,
		onUpdatePublicShare,
		updatePublicShare,
		onResetState,
		onStopSharingExternalItems,
		stopSharingExternalItems,
		onUnassignUserGroupItem,
		unassignUserGroupItem,
	} = props;
	const { t } = useTranslation('general');

	const { enqueueSnackbar } = useSnackbar();

	const { data: updatePublicShareData, loading: updatePublicShareLoading } = updatePublicShare;
	const { data: stopSharingExternalItemsData, loading: stopSharingExternalItemsLoading } =
		stopSharingExternalItems;
	const { data: unassignUserGroupItemData, loading: unassignUserGroupItemLoading } =
		unassignUserGroupItem;

	const instanceIds = dialog?.data && dialog.data.map((instance) => instance.id);

	const accepted = isFullArray(updatePublicShareData?.accepted);
	const rejected = isFullArray(updatePublicShareData?.rejected);

	const acceptedSharingExternal = isFullArray(stopSharingExternalItemsData?.accepted);
	const rejectedSharingExternal = isFullArray(stopSharingExternalItemsData?.rejected);

	const acceptedUnassignUserGroupItem = isFullArray(unassignUserGroupItemData?.accepted);
	const rejectedUnassignUserGroupItem = isFullArray(unassignUserGroupItemData?.rejected);

	const handleRestDialog = (state, message, variant) => {
		enqueueSnackbar(t(message), { variant: variant });
		onResetState(state);
		cancelDialog();
	};

	useEffect(() => {
		if (!updatePublicShareLoading && accepted && !rejected) {
			handleRestDialog('updatePublicShare', 'views.tableResults.errorMessage', 'success');
		} else if (!updatePublicShareLoading && rejected) {
			handleRestDialog('updatePublicShare', 'views.items.shareDialog.negative', 'error');
		} else if (
			!stopSharingExternalItemsLoading &&
			acceptedSharingExternal &&
			!rejectedSharingExternal
		) {
			handleRestDialog('stopSharingExternalItems', 'views.tableResults.errorMessage', 'success');
		} else if (!stopSharingExternalItemsLoading && rejectedSharingExternal) {
			handleRestDialog('stopSharingExternalItems', 'views.items.shareDialog.negative', 'error');
		} else if (
			!unassignUserGroupItemLoading &&
			acceptedUnassignUserGroupItem &&
			!rejectedUnassignUserGroupItem
		) {
			handleRestDialog('unassignOrCancelItem', 'views.tableResults.errorMessage', 'success');
		} else if (!unassignUserGroupItemLoading && rejectedUnassignUserGroupItem) {
			handleRestDialog('unassignOrCancelItem', 'views.items.shareDialog.negative', 'error');
		}
	}, [updatePublicShare, stopSharingExternalItems, unassignUserGroupItem]);

	const handleConfirm = () => {
		if (dialog.type === 'sharingInternalPublicly') {
			onUpdatePublicShare(
				true,
				dialog?.data && [{ itemId: dialog.itemId, instanceIds: instanceIds }],
			);
		} else if (dialog.type === 'stopSharingPublic') {
			onUpdatePublicShare(
				false,
				dialog?.data && [{ itemId: dialog.itemId, instanceIds: instanceIds }],
			);
		} else if (dialog.type === 'stopSharingExternal') {
			onStopSharingExternalItems(dialog.consumerId, {
				items: [{ itemId: dialog.itemId, instanceIds: instanceIds }],
			});
		} else if (dialog.type === 'stopSharingInternalUserGroupItems') {
			onUnassignUserGroupItem(dialog.userGroupId, [
				{ itemId: dialog.itemId, instanceIds: instanceIds },
			]);
		}
	};

	const cancelDialog = () => {
		setDialog((dialog) => ({ ...dialog, open: false, data: null }));
	};

	const handleItems = () => {
		const showItems = dialog.data.map((item) => <Typography key={item.id}>{item.name}</Typography>);
		return showItems;
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					(
						updatePublicShareLoading ||
						stopSharingExternalItemsLoading ||
						unassignUserGroupItemLoading
					) ?
						<CircularProgress disableShrink size={23} />
					:	t('ui.confirm'),
			}}
			handleClose={cancelDialog}
			loading={
				updatePublicShareLoading || stopSharingExternalItemsLoading || unassignUserGroupItemLoading
			}
			open={dialog.open}
			title={t(`views.sharing.dialog.title.${dialog.type}`)}
		>
			<Box>
				{t(`views.sharing.dialog.description.${dialog.type}`)}
				{!isNull(dialog.data) && handleItems()}
			</Box>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		updatePublicShare: state.details.updatePublicShare,
		stopSharingExternalItems: state.details.stopSharingExternalItems,
		unassignUserGroupItem: state.details.unassignOrCancelItem,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdatePublicShare: (isPublic, items) => dispatch(actions.updatePublicShare(isPublic, items)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onStopSharingExternalItems: (partnershipId, bodyData) =>
			dispatch(actions.stopSharingExternalItems(partnershipId, bodyData)),
		onUnassignUserGroupItem: (userGroupId, items) =>
			dispatch(actions.unassignUserGroupItem(userGroupId, items)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogCard);
