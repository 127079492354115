import { memo } from 'react';

import { Box } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { Input } from '../../../../components';
import { isObject, isEmptyObject } from '../../../../shared/utility';
import { SearchAutocomplete } from '../../../local';
import { SelectedOrganisationCard } from '../../../UI';
import SelectWithLazyLoading from '../../../UI/SelectWithLazyLoading';

const propsAreEqual = (prevProps, nextProps) => {
	return (
		prevProps.className === nextProps.className &&
		JSON.stringify(prevProps.events) === JSON.stringify(nextProps.events) &&
		prevProps.value === nextProps.value &&
		JSON.stringify(prevProps.selectOptions) === JSON.stringify(nextProps.selectOptions) &&
		JSON.stringify(prevProps.dataList) === JSON.stringify(nextProps.dataList)
	);
};

interface MainFilterProps {
	className?: string;
	events?: object;
	value?: any;
	selectOptions?: unknown[];
	isSearchAutocomplete?: boolean;
	isSelectWithLazyLoading?: boolean;
	dataList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	listType?:
		| 'users'
		| 'items'
		| 'organisations'
		| 'userGroups'
		| 'locations'
		| 'planboard-items'
		| 'terms'
		| 'hubs';
	defaultListItem?: object;
	onFetchData?(...args: unknown[]): unknown;
	placeholder?: string;
}

const MainFilter = memo(({
	events,
	value,
	selectOptions = [
		{
			value: 'all',
		},
		{
			value: 'Skopei',
		},
		{
			value: 'Randstad',
		},
		{
			value: 'Skopei',
		},
	],
	className,
	isSearchAutocomplete,
	isSelectWithLazyLoading,
	dataList,
	onFetchData,
	placeholder,
	listType,
	defaultListItem,
}: MainFilterProps) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Box alignItems='center' className={classes.filterContainer} display='flex'>
				{isSearchAutocomplete ?
					isObject(value) && !isEmptyObject(value) ?
						<SelectedOrganisationCard
							className={classes.searchAutocomplete}
							handleClose={() => events.onChange({})}
							name={value.name}
						/>
					:	<SearchAutocomplete
							className={classes.searchAutocomplete}
							dataList={dataList}
							listType={listType}
							onFetchData={onFetchData}
							placeholder={placeholder}
							setSelected={events.onChange}
						/>

				: isSelectWithLazyLoading ?
					<SelectWithLazyLoading
						className={classes.searchAutocomplete}
						dataList={dataList}
						defaultListItem={defaultListItem}
						events={events}
						listType={listType}
						onFetchData={onFetchData}
						placeholder={placeholder}
						searchHandle={events.searchHandle}
						setSelected={events.onChange}
						value={value}
					/>
				:	<Input
						className={classes.filterInput}
						events={{ onChange: events.onChange }}
						inputType='select'
						selectOptions={selectOptions}
						value={value}
					/>
				}
			</Box>
		</div>
	);
}, propsAreEqual);

export default MainFilter;
