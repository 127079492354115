import AdministrativeConfiguration from './AdministrativeConfiguration';
import BookingConfiguration from './BookingConfiguration';
import Header from './Header';
import PaymentConfiguration from './PaymentConfiguration';
import { useStyles } from './style';

interface PolicyConfigurationProps {
	save(...args: unknown[]): unknown;
	editing?: boolean;
	requireHostConfirmation?: boolean;
	minimumBookingDuration?: string;
	minimumBookingDurationRequired?: boolean;
	hasCancellationPeriod?: boolean;
	cancelDay?: number;
	cancelHour?: number;
	cancelMin?: number;
	hasBufferPeriod?: boolean;
	hasGracePeriod?: boolean;
	daysBeforeUse?: number;
	hoursBeforeUse?: number;
	minBeforeUse?: number;
	daysAfterUse?: number;
	hoursAfterUse?: number;
	minAfterUse?: number;
	allowExtending?: boolean;
	requiredLicenseStatus?: string;
	requiredParkingLicensePlateNumber?: string;
	personalBillingDeposit?: number;
	depositAmountRequired?: boolean;
	requireReservationComment?: boolean;
	name?: string;
	allowPersonalBilling?: boolean;
	setAllowPersonalBilling?(...args: unknown[]): unknown;
	daysGracePeriod?: number;
	hoursGracePeriod?: number;
	minGracePeriod?: number;
	maxDurationDay?: number;
	maxDurationHour?: number;
	maxDurationMin?: number;
	hasMaxDuration?: boolean;
	hasMinDuration?: boolean;
	requireOvertimeValidation?: boolean;
	minDurationDay?: number;
	minDurationHour?: number;
	minDurationMin?: number;
	openHoursRestrictionType?: string;
	requireDutchLicense?: boolean;
	hasMinDurationBeforeStart?: boolean;
	hasMaxDurationBeforeStart?: boolean;
	minDurationBeforeStartDay?: number;
	minDurationBeforeStartHour?: number;
	minDurationBeforeStartMin?: number;
	maxDurationBeforeStartDay?: number;
	maxDurationBeforeStartHour?: number;
	maxDurationBeforeStartMin?: number;
	bookingUpfrontMaxDurationSmallerThanMinDuration?: boolean;
	minimumAge?: number;
}

const PolicyConfiguration = (props: PolicyConfigurationProps) => {
	const {
		save,
		requireHostConfirmation,
		minimumBookingDuration,
		minimumBookingDurationRequired,
		requireOvertimeValidation,
		hasCancellationPeriod,
		cancelMin,
		cancelDay,
		cancelHour,
		daysBeforeUse,
		hasBufferPeriod,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		hoursBeforeUse,
		minBeforeUse,
		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		allowExtending,
		requiredLicenseStatus,
		hasGracePeriod,
		depositAmountRequired,
		personalBillingDeposit,
		requireReservationComment,
		editing,
		name,
		requireDutchLicense,
		allowPersonalBilling,
		setAllowPersonalBilling,
		requiredParkingLicensePlateNumber,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		openHoursRestrictionType,
		bookingUpfrontMaxDurationSmallerThanMinDuration,
		minimumAge,
	} = props;

	const classes = useStyles();

	const bookingDurationProps = {
		requireHostConfirmation,
		openHoursRestrictionType,
		minimumBookingDuration,
		minimumBookingDurationRequired,
		minDurationBeforeStartDay,
		minDurationBeforeStartHour,
		minDurationBeforeStartMin,
		maxDurationBeforeStartDay,
		maxDurationBeforeStartHour,
		maxDurationBeforeStartMin,
		hasCancellationPeriod,
		cancelMin,
		cancelDay,
		requireOvertimeValidation,
		requireDutchLicense,
		cancelHour,
		daysBeforeUse,
		hasBufferPeriod,
		hoursBeforeUse,
		hasGracePeriod,
		hasMinDurationBeforeStart,
		hasMaxDurationBeforeStart,
		minBeforeUse,
		daysAfterUse,
		hoursAfterUse,
		minAfterUse,
		requiredParkingLicensePlateNumber,
		allowExtending,
		requiredLicenseStatus,
		daysGracePeriod,
		hoursGracePeriod,
		minGracePeriod,
		hasMinDuration,
		minDurationDay,
		minDurationHour,
		minDurationMin,
		maxDurationDay,
		maxDurationHour,
		maxDurationMin,
		hasMaxDuration,
		bookingUpfrontMaxDurationSmallerThanMinDuration,
		minimumAge,
	};
	const paymentConfigurationProps = {
		depositAmountRequired,
		personalBillingDeposit,
		allowPersonalBilling,
		setAllowPersonalBilling,
		editing,
	};
	const administrativeConfigurationProps = { requireReservationComment, editing };
	// const cancellationPoliciesProps = { cancelDay, setCancelDay, cancelHour, setCancelHour, cancelMin, setCancelMin };
	// const cooldownPolicyProps = { daysBeforeUse, setDaysBeforeUse, hoursBeforeUse, setHoursBeforeUse, minBeforeUse, setMinBeforeUse, daysAfterUse, setDaysAfterUse, hoursAfterUse, setHoursAfterUse, minAfterUse, setMinAfterUse };

	return (
		<>
			<Header editing={editing} name={name} />
			<BookingConfiguration
				className={classes.bookingDuration}
				save={save}
				{...bookingDurationProps}
			/>
			<PaymentConfiguration save={save} {...paymentConfigurationProps} />
			<AdministrativeConfiguration save={save} {...administrativeConfigurationProps} />
		</>
	);
};

export default PolicyConfiguration;
