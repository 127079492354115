import { ItemCategoryEnum } from '~enums';
import { ListResults } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';
import { BaseReferenceResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

interface BillingPeriodStat {
	closed: number;
	open: number;
	revoked: number;
}

class BillingPeriodStatisticsService extends TopologyService {
	public readonly path = 'graphs/payments';

	constructor() {
		super('v1');
	}

	async getBillingPeriodCounts({
		...args
	}: {
		organisationId?: string;
		period: DateRange;
	}): Promise<BillingPeriodStat> {
		const { data } = await this._client.get<BillingPeriodStatResponse>(this.path, {
			params: {
				organisationId: args.organisationId,
				dateAfter: args.period.start,
				dateBefore: args.period.end,
			},
		});

		return {
			open: data.open,
			closed: data.closed,
			revoked: data.revoked,
		};
	}
}

interface BillingPeriodStatResponse {
	closed: number;
	open: number;
	revoked: number;
}

export default BillingPeriodStatisticsService;
