import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Table } from '../../../../../components';
import {
	isFullString,
	isObject,
	isNumber,
	decimalAmount,
	isFullArray,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface BookingsFinanceProps {
	businessRevenue?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	id?: string;
	date?: string;
	onFetchBusinessRevenue?(...args: unknown[]): unknown;
}

const BookingsFinance = (props: BookingsFinanceProps) => {
	const classes = useStyles();
	const { businessRevenue, id, date, onFetchBusinessRevenue } = props;
	const { t } = useTranslation('general');
	const [finance, setFinance] = useState(null);
	const { data: businessRevenueData, loading: businessRevenueLoading } = businessRevenue;

	const filters = {
		...(isFullString(id) && { id: id }),
	};
	useEffect(() => {
		if (!isObject(businessRevenueData) && !businessRevenueLoading) {
			onFetchBusinessRevenue(date, filters);
		}
	}, [businessRevenueLoading]);

	useEffect(() => {
		const parseId = parseInt(id, 10);
		if (isNumber(parseId) && isObject(businessRevenueData)) {
			const financeData = businessRevenueData.billings.filter((billing) =>
				billing.organisationReference.id === parseId ? { amount: billing.amount } : null,
			);
			setFinance(financeData);
		}
	}, [id, businessRevenueLoading]);

	const tableHeader = getFinanceTableHeaders(false, true, true);

	const tableBody =
		isFullArray(finance) ?
			finance.map((item) => [
				{
					content: (
						<Box vw='510px'>
							<Typography className={classes.link}>{businessRevenueData.title}</Typography>
						</Box>
					),
				},
				{ content: decimalAmount(item.amount.subtotal, item.amount) },
				{ content: decimalAmount(item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.subtotal - item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.priceVat, item.amount) },
				{ content: decimalAmount(item.amount.refund, item.amount) },
				{ content: decimalAmount(item.amount.totalPrice, item.amount) },
			])
		:	Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation = {
		header: t('ui.label.organisationalBilling'),
	};

	return (
		<Table
			body={tableBody}
			cellStyle={classes.cellStyle}
			header={tableHeader}
			isNotPaginate={true}
			loading={businessRevenueLoading}
			titleWithInformation={titleWithInformation}
			withoutPages={true}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		businessRevenue: state.details.businessRevenue,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessRevenue: (date, filters) =>
			dispatch(actions.fetchBusinessRevenue(date, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsFinance);
