import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack, Typography } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';

import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import SkcAccessRule from '../../interfaces/accessRule';
import SelectOrganisationOverlay from './selectOrganisationOverlay';
import SkcAccessRulesService from '../../services/skcAccessRulesService';

const toolbarIdString = 'topology-access-rules-datagrid';

const service = new SkcAccessRulesService();

interface AccessRulesDataGridProps {
	enableCreate?: boolean;
	enableEdit?: boolean;
	enableDelete?: boolean;
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const AccessRulesDataGrid = forwardRef<ListMutationRefProps, AccessRulesDataGridProps>(
	(
		{ enableCreate = true, enableEdit = true, enableDelete = true, onEdit, onDelete, onCreate },
		ref,
	) => {
		const { t } = useTranslation('general');
		const { isSuperAdmin } = useAuthorize();
		const location = useLocation();
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [fetchParameters, setFetchParameters] = useState<object | null>();

		const columns = useMemo<GridColDef<SkcAccessRule>[]>(
			() => [
				{
					field: 'id',
					headerName: 'ID',
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={params.value.toString()}
							state={{ from: location.pathname }}
						>
							{params.value}
						</MuiLink>
					),
					width: 80,
					hideable: false,
				},
				{
					field: 'label',
					headerName: t('ui.label.name'),
					flex: 1,
				},
				{
					field: 'description',
					headerName: t('ui.label.description'),
					valueFormatter: (value: string) => value ?? '-',
					flex: 1,
				},
				{
					field: 'startDate',
					headerName: t('ui.label.startDate'),
					valueGetter: (_, row) => row.period.start,
					valueFormatter: (value: Date | null) => (value ? value.toLocaleDateString() : '-'),
					flex: 1,
					type: 'date',
				},
				{
					field: 'endDate',
					headerName: t('ui.label.endDate'),
					valueGetter: (_, row) => row.period.end,
					valueFormatter: (value: Date | undefined) => (value ? value.toLocaleDateString() : '-'),
					flex: 1,
					type: 'date',
				},
				{
					field: 'organisation',
					headerName: t('organisation'),
					valueGetter: () => selectedOrganisation,
					valueFormatter: (value: BaseReference) => value?.label ?? '-',
					flex: 1,
				},
				{
					field: 'userGroup',
					headerName: t('userGroup'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'deviceGroup',
					headerName: t('deviceGroup'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'schedule',
					headerName: t('schedule'),
					valueGetter: (value: BaseReference) => value.label,
					flex: 1,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-edit`}
							label={t('ui.edit')}
							icon={<EditIcon />}
							color='inherit'
							onClick={() => onEdit?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
						<GridActionsCellItem
							key={`${params.id}-delete`}
							label={t('ui.delete')}
							icon={<DeleteOutlinedIcon color='error' />}
							color='error'
							onClick={() => onDelete?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
					],
				},
			],
			[selectedOrganisation],
		);

		const { data, error, isLoading, isValidating, mutate } = useSWR(
			fetchParameters?.organisationId != null ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getAccessRules(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationModelChange = (
			value: GridPaginationModel,
			details: GridCallbackDetails,
		) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setSelectedOrganisation(organisation);
			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				<Box
					id={toolbarIdString}
					sx={{
						minHeight: 48,
					}}
				/>
				<DataGrid
					disableColumnSorting
					snapshotKey='accr'
					// autoHeight
					columns={columns}
					error={error}
					loading={isLoading || isValidating}
					rows={data?.results ?? []}
					rowCount={totalCount}
					onPaginationModelChange={handlePaginationModelChange}
					onFilterModelChange={handleFilterModelChange}
					initialState={{
						filter: {
							filterModel: {
								items:
									selectedOrganisation ?
										[
											{
												field: 'organisation',
												operator: 'contains',
												value: selectedOrganisation,
											},
										]
									:	[],
							},
						},
					}}
					slots={{
						toolbar: Toolbar,
						...(selectedOrganisation == null && {
							noRowsOverlay: SelectOrganisationOverlay,
						}),
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate,
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('rules') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('rules') }),
						},
					}}
					sx={{ flexGrow: 1, flexBasis: 200 }}
				/>
			</Stack>
		);
	},
);
AccessRulesDataGrid.displayName = 'AccessRulesDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableSearch disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', { resource: t('rule') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default AccessRulesDataGrid;
