import { useEffect, useState } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	FormControl,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { SelectWithLazyLoading, ActionDialog } from '../../../../../../components';
import { isObject, capitalizeFirstCharacter } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { usePoliciesChange, useItemSharings } from '../itemHooks';
import PoliciesToggle from '../PoliciesToggle';
import { useStyles } from '../style';

interface AssignPricingProps {
	className?: string;
	isOwnItem?: boolean;
	itemData?: object;
	onResetState?(...args: unknown[]): unknown;
	onFetchPricingModels?(...args: unknown[]): unknown;
	onFetchPrice?(...args: unknown[]): unknown;
	onItemPatch?(...args: unknown[]): unknown;
	priceData?: object;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	pricingModels?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AssignPricing = (props: AssignPricingProps) => {
	const {
		className,
		isOwnItem,
		itemPatch,
		itemData,
		pricingModels,
		onFetchPricingModels,
		onItemPatch,
		onUpdateDetailsState,
		priceData,

		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const { data: patchData, loading: patchLoading, error: patchError } = itemPatch;
	const patchReady = isObject(patchData) && !patchLoading && !patchError;

	const pricingReference = itemData?.pricingModelReference;

	const pricing = usePoliciesChange({ id: pricingReference.id, name: pricingReference.name });

	const [updatedPricing, setUpdatedPricing] = useState(false);
	const [updateConfig, setUpdateConfig] = useState(false);
	const [override, setOverride] = useState(false);
	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: itemData.hubReference.organisationReference.id,
	});

	const sharings = useItemSharings({ fetch: pricing.openDialog, type: 'pricing', id: itemData.id });

	useEffect(() => {
		if (isObject(patchData) && updatedPricing) {
			onUpdateDetailsState('itemDetails', patchData);
			enqueueSnackbar(
				`${t('ui.success.message.updated')} ${patchData?.name ? patchData.name : '-'}`,
				{ variant: 'success' },
			);
			onResetState('itemPatch');
			pricing.setOpenDialog(false);
			setUpdatedPricing(false);
		}
	}, [patchData]);

	const handleConfirmDialog = () => {
		const patchItemBody = {
			pricingModelId: pricing.initialValue.id,
			...(updateConfig && { updateConfigType: override ? 'all' : 'equal' }),
		};
		onItemPatch(itemData.id, patchItemBody);
		setUpdatedPricing(true);
	};

	const handlePricing = () => {
		if (priceData?.fromPrice && priceData.fromPrice.price === 0) {
			return t('ui.free');
		} else if (priceData?.fromPrice) {
			return `${priceData?.fromPrice.currencySymbol} ${priceData?.fromPrice.price.toFixed(2)}`;
		} else {
			return '-';
		}
	};

	const handleToggle = (evnet) => {
		const val = evnet.target.checked;
		setUpdateConfig(() => val);
		if (!val) {
			setOverride(() => false);
		}
	};

	const handleOverrideAll = (event) => {
		setOverride(() => event.target.checked);
	};

	const selectProps = {
		dataList: pricingModels,
		filter: organisationIdFilter,
		onFetchData: onFetchPricingModels,
		placeholder: t('ui.placeholders.search.pricing'),
		setSelected: pricing.change,
		value: pricing.initialValue.name,
	};

	const loading = patchLoading && !patchReady;

	const actionDialogProps = {
		noDivider: true,
		contentStyles: classes.actionDialog,
		loading: loading,
		handleClose: pricing.cancelDialog,
		open: pricing.openDialog,
		title: t('ui.pricing'),
		actionButtonProps: {
			action: handleConfirmDialog,
			text: loading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
		},
	};
	// Override sharing with the current policy
	const policiesToggleProps = {
		onClick: handleToggle,
		overrideAll: updateConfig,
		onChange: handleOverrideAll,
		override,
		sharings,
		type: 'pricing',
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={t('ui.pricing')} />
			<Divider />
			<CardContent>
				<Typography pb={2} variant='h6'>
					{t('views.itemDetail.summary.subtitle.assignedPricing')}
				</Typography>
				<FormControl fullWidth variant='standard'>
					{isOwnItem || isSuperAdmin() ?
						<SelectWithLazyLoading {...selectProps} />
					:	null}
				</FormControl>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.priceRange')}</TableCell>
							<TableCell>{handlePricing()}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.details')} </TableCell>
							<TableCell>
								{priceData?.fromPrice.periodType ?
									capitalizeFirstCharacter(priceData?.fromPrice.periodType)
								:	'-'}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{pricing.openDialog ?
				<ActionDialog {...actionDialogProps}>
					<PoliciesToggle {...policiesToggleProps} />
				</ActionDialog>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		pricingModels: state.paged.pricingModels,
		fetchPrice: state.details.fetchPrice,
		itemPatch: state.details.itemPatch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPricingModels: (page, filters, concat) =>
			dispatch(actions.fetchPricingModels(page, filters, concat)),
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignPricing);
