import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import AdministrationInfo from './AdministrationInfo';
import ContactInfo from './ContactInfo';
// import SummaryLoading from './SummaryLoading';
import IncomingRequestActions from './IncomingRequestActions';
import OrganisationInfo from './OrganisationInfo';
import OutgoingRequestActions from './OutgoingRequestActions';
import { isObject } from '../../../../../shared/utility';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

const PREFIX = 'Summary';

const classes = {
	root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(() => ({
	[`&.${classes.root}`]: {
		flexGrow: 1,
	},
}));

interface SummaryProps {
	className?: string;
	partner?: object;
	loading?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, partner, loading } = props;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return !loading && isObject(partner) ?
			<StyledGrid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<OrganisationInfo partner={partner} />
				</Grid>
				<Grid {...gridProps}>
					<AdministrationInfo partner={partner} />
				</Grid>
				<Grid {...gridProps}>
					<ContactInfo partner={partner} />
				</Grid>
				{partner.status !== 'accepted' ?
					partner.type === 'outgoing' ?
						<Grid {...gridProps}>
							<OutgoingRequestActions partner={partner} />
						</Grid>
					:	null
				:	null}
				{partner.status !== 'accepted' ?
					partner.type === 'incoming' ?
						<Grid {...gridProps}>
							<IncomingRequestActions partner={partner} />
						</Grid>
					:	null
				:	null}
			</StyledGrid>
		:	<SummaryLoading />;
};

export default Summary;
