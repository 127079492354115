import { HTMLAttributes, useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { Error as ErrorIcon } from '@mui/icons-material';
import { Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';
import {
	DataGrid as MuiDataGrid,
	DataGridProps,
	GridCallbackDetails,
	GridPaginationModel,
	GridSortModel,
	NoResultsOverlayPropsOverrides,
	GridInitialState,
	useGridApiRef,
	GridFilterModel,
	gridClasses,
	GridColumnVisibilityModel,
	GridColumnGroupingModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { TooltipNew } from '~components';
import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import { dataGridPageSizeOptions } from '~constants';
import { useAuthorize } from '~features/authentication';
import i18n from '~lib/i18n';

import useUrlSearchParamsPagination from './useUrlSearchParamsPagination';
import useUrlSearchParamsSorting from './useUrlSearchParamsSorting';

const snapshotDataGridsKey = 'tdg';

interface CustomDataGridProps extends DataGridProps {
	/**
	 * Title of the datagrid.
	 */
	title?: string;
	/**
	 * The key to use to persist part of the datagrid data. If not defined, the settings
	 * won't be persisted
	 */
	snapshotKey?: string;
	/**
	 * Set this boolean to allow error overlay inside the datagrid
	 */
	error?: boolean;
	/**
	 * A boolean to turn off url synchronisation. Useful when multiple
	 * datagrid are on one page or when we don't want the user to be able
	 * to control it through the url
	 */
	disableUrlSync?: boolean;
}

/**
 * Basically the MUI Datagrid component, but with added functionality like:
 * - Url parameter synchronisation
 * - Default slots components, like a toolbar and overlays
 * - Custom overridden slot components, like the pagination
 * - User datagrid configuration persistence
 * TODO: https://mui.com/x/react-data-grid/custom-columns/#date-pickers
 * @param props
 * @returns
 */
const DataGrid = ({
	title,
	snapshotKey,
	error = false,
	paginationMode = 'server',
	sortingMode = 'server',
	sortingOrder = ['desc', 'asc'],
	filterMode = 'server',
	disableColumnMenu = true,
	disableColumnFilter = true,
	pageSizeOptions = dataGridPageSizeOptions,
	disableRowSelectionOnClick = true,
	disableUrlSync = false,
	...dataGridProps
}: CustomDataGridProps) => {
	const theme = useTheme();
	const apiRef = useGridApiRef();
	const { isSuperAdmin } = useAuthorize();

	const [paginationModel, setPaginationModel] = useUrlSearchParamsPagination(
		dataGridProps.initialState?.pagination?.paginationModel?.pageSize,
		disableUrlSync,
	);
	const [sortModel, setSortModel] = useUrlSearchParamsSorting(
		dataGridProps.sortModel,
		disableUrlSync,
	);
	// const [filterModel, setFilterModel] = useUrlSearchParamsFiltering();
	const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
		dataGridProps.initialState?.filter?.filterModel,
	);

	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
		organisation: isSuperAdmin(),
	});

	/**
	 * Save a snapshot of the datagrid state to the localstorage
	 */
	const saveSnapshot = useCallback(() => {
		if (!snapshotKey || !apiRef.current?.exportState) {
			return;
		}

		const stateFromLocalStorageString = localStorage?.getItem(snapshotDataGridsKey);

		let state: object = {};
		try {
			// Get the existing state from the localstorage. If for some reason
			state = stateFromLocalStorageString ? JSON.parse(stateFromLocalStorageString) : {};
		} catch (error) {
			console.warn('Invalid datagrid state JSON in localstorage. Reset state', error);
			state = {};
		}

		state[snapshotKey] = apiRef.current.exportState();
		localStorage.setItem(snapshotDataGridsKey, JSON.stringify(state));
	}, [apiRef]);

	useLayoutEffect(() => {
		restoreState();

		return () => {
			saveSnapshot();
		};
	}, [saveSnapshot]);

	/**
	 * Initialize
	 */
	const restoreState = () => {
		const localStorageStateString = localStorage?.getItem(snapshotDataGridsKey);
		let state: GridInitialState = {};
		try {
			if (snapshotKey) {
				// If we have a snapshot key it means we want to persist the state of the datagrid.
				// Try to parse if, but just skips if it fails. In that case we will probably overwrite
				// the corrupt settings on the next saveSnapshot call
				const localStorageState: object =
					localStorageStateString ? JSON.parse(localStorageStateString) : {};
				state = {
					...state,
					...localStorageState[snapshotKey],
				};
			}
		} catch (error) {
			console.warn('Invalid datagrid state', error);
		} finally {
			// Overwrite certain states. Like the state that is in the url
			state = {
				...state,
				columns: {
					// Why we ignore the columnvisibilitymodel
					// https://github.com/mui/mui-x/issues/11494
					dimensions: state.columns?.dimensions,
					// The orderedfields can give a weird order on a update
					// orderedFields: state.columns?.orderedFields
					columnVisibilityModel: dataGridProps.initialState?.columns?.columnVisibilityModel,
				},
				filter: {
					filterModel: dataGridProps.initialState?.filter?.filterModel,
				},
				pagination: {
					paginationModel: paginationModel,
				},
				sorting: {
					sortModel: sortModel,
				},
			};
		}

		apiRef.current?.restoreState?.(state);

		dataGridProps.onPaginationModelChange?.(paginationModel, {
			reason: 'restoreState',
			api: apiRef.current,
		});
		if (dataGridProps.onSortModelChange && sortModel) {
			dataGridProps.onSortModelChange(sortModel, { reason: 'restoreState', api: apiRef.current });
		}
		if (dataGridProps.onFilterModelChange && filterModel) {
			dataGridProps.onFilterModelChange(filterModel, {
				reason: 'restoreState',
				api: apiRef.current,
			});
		}
	};

	/**
	 * Handle a change in pagination and synchronize the changes with the url
	 * @param value
	 * @param details
	 */
	const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
		if (dataGridProps.onPaginationModelChange) {
			// TODO: 20240329 the reason should always be set. Seems like a bug in mui. For that reason, set it
			// ourselfs for now if it is not defined
			dataGridProps.onPaginationModelChange(value, { reason: 'setPaginationModel', ...details });
		}

		setPaginationModel(value);
	};

	/**
	 * Handle a change in sorting and sync this with the url
	 * @param model
	 */
	const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
		if (dataGridProps.onSortModelChange) {
			dataGridProps.onSortModelChange(model, details);
		}

		setSortModel(model);
	};

	const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
		if (dataGridProps.onFilterModelChange) {
			dataGridProps.onFilterModelChange(model, details);
		}

		setFilterModel(model);
		// setFilterModel(model, details);
	};

	const handleColumnVisibilityModelChange = (
		model: GridColumnVisibilityModel,
		details: GridCallbackDetails,
	) => {
		setColumnVisibilityModel(model);

		if (dataGridProps.onColumnVisibilityModelChange) {
			dataGridProps.onColumnVisibilityModelChange(model, details);
		}
	};

	// Not sure if it is appropiate to use it this way, but we just want to
	// give a title to the datagrid without messing up the overarching structure
	const columnGroupingModel = useMemo<GridColumnGroupingModel | undefined>(
		() =>
			title != null ?
				[
					{
						groupId: 'all',
						description: 'bla',
						children: dataGridProps.columns.map((el) => ({ field: el.field })),
						renderHeaderGroup: () => <Typography variant='h5'>{title}</Typography>,
					},
				]
			:	undefined,
		[dataGridProps.columns, title],
	);

	// Take notice about the order of the injected props!
	// Everything before e.g. {...rest} can be overwritten by a child component.
	// Everything after {...rest} will not be overwritten. Additions from the child should
	// be handled in a different way.
	return (
		<MuiDataGrid
			apiRef={apiRef}
			{...dataGridProps}
			// autoPageSize={true}
			columnGroupingModel={columnGroupingModel}
			disableRowSelectionOnClick={disableRowSelectionOnClick}
			pageSizeOptions={pageSizeOptions}
			disableColumnMenu={disableColumnMenu}
			disableColumnFilter={disableColumnFilter}
			pagination
			paginationMode={paginationMode}
			paginationModel={paginationModel}
			onPaginationModelChange={handlePaginationChange}
			sortingMode={sortingMode}
			sortingOrder={sortingOrder}
			sortModel={sortModel}
			onSortModelChange={handleSortModelChange}
			filterMode={filterMode}
			filterModel={filterModel}
			onFilterModelChange={handleFilterModelChange}
			// Enabling the columnvisibilitymodel will do weird things with the persist and
			// restore state. Not restoring properly
			// Maybe do it similar to the columns, put it in a usememo?
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
			slots={{
				baseTooltip: TooltipNew,
				loadingOverlay: LoadingOverlay,
				noResultsOverlay: error ? ErrorOverlay : NoResultsOverlay,
				noRowsOverlay: error ? ErrorOverlay : NoResultsOverlay,
				// moreActionsIcon: GridMoreActionsIcon,
				// toolbar: DataGridToolbar,
				...dataGridProps.slots,
			}}
			sx={{
				border: 1,
				borderColor: theme.palette.secondary.main,
				// Border radius just determined visually. Seems it is not shared
				// among components...
				// TODO: define in the theme, e.g. paper. That is shared among the
				// datagrid and cards
				borderRadius: 0.75 * theme.shape.borderRadius,
				color: theme.palette.text.primary,
				fontWeight: 400,
				'.MuiDataGrid-columnHeaderTitle': {
					fontSize: theme.typography.h6.fontSize,
				},
				'.MuiDataGrid-actionsCell': {
					height: 1,
					display: 'flex',
					alignItems: 'center',
				},
				[`& .${gridClasses.cell}`]: {
					borderTopColor: theme.palette.secondary.main,
					borderLeft: 0,
					borderRight: 0,
					borderBottom: 0,
				},
				[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
					outline: 'transparent',
				},
				[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
					outline: 'none',
				},
				[`& .${gridClasses.columnHeaderTitleContainer}`]: {
					borderColor: '#E4EEFB',
				},
				...dataGridProps.sx,
			}}
		/>
	);
};

const NoResultsOverlay = ({
	title = i18n.t('noResults'),
	...props
}: HTMLAttributes<NoResultsOverlayPropsOverrides>) => (
	<Stack
		width={1}
		height={1}
		display='flex'
		alignItems='center'
		justifyContent='center'
		direction='column'
		spacing={1}
		minHeight={300}
	>
		<EmptyListIcon />
		<Typography variant='h6'>{title}</Typography>
	</Stack>
);

const LoadingOverlay = () => (
	<Box width={1} height={1} display='flex' flexDirection='column'>
		<LinearProgress />
	</Box>
);

const ErrorOverlay = () => {
	const { t } = useTranslation('general');

	return (
		<Box
			width={1}
			height={1}
			flexDirection='column'
			display='flex'
			alignItems='center'
			justifyContent='center'
		>
			<ErrorIcon fontSize='large' />
			<Typography variant='body1'>{t('somethingWentWrong')}</Typography>
		</Box>
	);
};

export default DataGrid;
