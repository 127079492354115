import { styled } from '@mui/material/styles';

import LocationCard from './LocationCard';
import OpeningHoursCard from '../../../../../components/elements/OpeningHoursCard';

const PREFIX = 'LocationDetails';

const classes = {
	root: `${PREFIX}-root`,
	openingCard: `${PREFIX}-openingCard`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
	[`& .${classes.root}`]: {},
	[`& .${classes.openingCard}`]: {
		marginTop: theme.spacing(5),
	},
}));

interface LocationDetailsProps {
	save(...args: unknown[]): unknown;
	className?: string;
	name?: string;
	description?: string;
	images?: unknown[];
	openingTime?: string;
	hubData?: object;
	selectedOrganisation?: object;
	openingHours?: unknown[];
	searchValue?: string;
}

const LocationDetails = (props: LocationDetailsProps) => {
	const {
		save,
		name,
		description,
		images,
		openingTime,
		selectedOrganisation,
		openingHours,
		searchValue,
	} = props;

	const locationCardProps = {
		name,
		description,
		images,
		searchValue,
		selectedOrganisation,
	};
	const openingHoursCardProps = {
		openingTime,
		openingHours,
	};

	return (
		<Root>
			<LocationCard className={classes} save={save} {...locationCardProps} />
			<OpeningHoursCard className={classes.openingCard} save={save} {...openingHoursCardProps} />
		</Root>
	);
};

export default LocationDetails;
