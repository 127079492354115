import clsx from 'clsx';
import ReactPaginate from 'react-paginate';

import { useStyles } from './style';

interface PaginateProps {
	className?: string;
	onPageChange(...args: unknown[]): unknown;
	pageCount: number;
	pageRangeDisplayed: number;
}

const Paginate = ({
	pageCount,
	pageRangeDisplayed = 5,
	onPageChange = () => {},
	className,
	...rest
}: PaginateProps) => {
	const classes = useStyles();

	return (
		<ReactPaginate
			activeClassName={classes.active}
			activeLinkClassName={classes.activeLink}
			breakClassName={classes.break}
			breakLabel='...'
			breakLinkClassName={classes.breakLink}
			containerClassName={clsx(classes.root, className)}
			disabledClassName={classes.disabled}
			marginPagesDisplayed={2}
			nextClassName={classes.next}
			nextLabel='next'
			nextLinkClassName={classes.nextLink}
			onPageChange={onPageChange}
			pageClassName={classes.page}
			pageCount={pageCount}
			pageLinkClassName={classes.pageLink}
			pageRangeDisplayed={pageRangeDisplayed}
			previousClassName={classes.previous}
			previousLabel='previous'
			previousLinkClassName={classes.previousLink}
			subContainerClassName='pages pagination'
			renderOnZeroPageCount={null}
			{...rest}
		/>
	);
};

export default Paginate;
