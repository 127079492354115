import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Label } from '../../../components';

const PREFIX = 'HeaderWithStatus';

const classes = {
	header: `${PREFIX}-header`,
};

const StyledBox = styled(Box)(() => ({
	[`& .${classes.header}`]: {
		paddingRight: '8px',
	},
}));

export {};

interface HeaderWithStatusProps {
	justifyContent?: string;
	header?: string;
	headerSize?: string;
	subheader?: string;
	subheaderSize?: string;
	type?: string;
	status?: string;
	text?: string;
	variantText?: string;
	padding?: number;
}

const HeaderWithStatus = (props: HeaderWithStatusProps) => {
	const {
		padding,
		justifyContent,
		header,
		headerSize,
		subheader,
		subheaderSize,
		type,
		status,
		text,
		variantText,
	} = props;

	return (
		<StyledBox display='flex' flexDirection='column' p={padding}>
			<Box display='flex' justifyContent={justifyContent}>
				<Typography className={classes.header} variant={headerSize}>
					{header}
				</Typography>
				{status ?
					<Label type={type}>{status}</Label>
				:	<Typography variant={variantText}>{text}</Typography>}
			</Box>
			{subheader ?
				<Typography variant={subheaderSize}>{subheader}</Typography>
			:	null}
		</StyledBox>
	);
};

export default HeaderWithStatus;
