import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Card, CardContent, IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'ItemCard';

const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`,
	imgCard: `${PREFIX}-imgCard`,
	imageContainer: `${PREFIX}-imageContainer`,
	imageSpacing: `${PREFIX}-imageSpacing`,
	iconButton: `${PREFIX}-iconButton`,
};

const StyledCard = styled(Card)(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.content}`]: {
		padding: 0,
		paddingBottom: '0px',
	},

	[`&.${classes.imgCard}`]: {
		padding: '8px',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: '0px',
		},
	},

	[`& .${classes.imageContainer}`]: {
		height: '69px',
		width: '94px',
	},

	[`& .${classes.imageSpacing}`]: {
		objectFit: 'scale-down',
		width: '100%',
		height: '100%',
		margin: '0',
		padding: '0',
	},

	[`& .${classes.iconButton}`]: {
		padding: '0px',
	},
}));

export {};

interface ItemCardProps {
	className?: string;
	image?: string;
	name?: string;
	instanceName?: string;
	locationIcon?: object;
	addressIcon?: object;
	address?: string;
	iconButton?: boolean;
	locationName?: string;
	handleItemView?(...args: unknown[]): unknown;
}

const ItemCard = (props: ItemCardProps) => {
	const {
		image,
		name,
		instanceName,
		addressIcon,
		address,
		locationIcon,
		iconButton,
		locationName,
		handleItemView,
	} = props;

	return (
		<StyledCard className={classes.imgCard}>
			<CardContent className={classes.content}>
				<Box alignItems='center' display='flex' justifyContent='space-between'>
					<Box alignItems='center' display='flex'>
						<div className={classes.imageContainer}>
							{image ?
								<img className={classes.imageSpacing} src={image} />
							:	null}
						</div>
						<Box display='flex' flexDirection='column' pl={0.5}>
							<Box pl={0.5}>
								<Typography>{name}</Typography>
							</Box>
							<Box pl={0.5}>
								<Typography sx={{ color: 'inherit' }}>{instanceName}</Typography>
							</Box>
							<Box alignItems='center' display='flex'>
								{addressIcon}
								<Typography variant='body2'>{address}</Typography>
							</Box>
							<Box alignItems='center' display='flex'>
								{locationIcon}
								<Typography variant='body2'>{locationName}</Typography>
							</Box>
						</Box>
					</Box>
					{iconButton ?
						<IconButton
							className={classes.iconButton}
							disableRipple={true}
							onClick={handleItemView}
							size='large'
						>
							<ArrowForwardIosIcon fontSize='small' />
						</IconButton>
					:	null}
				</Box>
			</CardContent>
		</StyledCard>
	);
};

export default ItemCard;
