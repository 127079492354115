import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Filters from './Filters';
import { useStyles } from './style';
import { Search } from '../../../../components';

interface FilterBarProps {
	searchEvents?: object;
	filterEvents?: object;
	searchValue?: string;
	organisationValue?: string | number;
	itemValue?: string | number;
}

const FilterBar = (props: FilterBarProps) => {
	const { searchEvents, filterEvents, searchValue, organisationValue, itemValue } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<Paper className={classes.root} square>
			<Search
				className={classes.search}
				events={searchEvents}
				hasInlineSearchButton={true}
				inputType='search-suggest'
				placeholder={t('views.planboard.filterbar.search.placeholder')}
				value={searchValue}
			/>
			<Filters
				className={classes.filters}
				events={filterEvents}
				itemTypeValue={itemValue}
				organisationValue={organisationValue}
			/>
		</Paper>
	);
};

export default FilterBar;
