import { useEffect } from 'react';

import { OidcClient } from 'oidc-client-ts';
import { useLocation, useNavigate } from 'react-router-dom';

import { oidcConfig } from '~features/authentication';

import { getUrlParams, isObject, isFullString } from '../../shared/utility';

/**
 * Use the passwordresetpage in pages once the confirm email
 * url is properly encoded
 * @returns
 * @deprecated
 */
const Reset = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryString = getUrlParams(location.search);
	const isValid =
		isObject(queryString) && isFullString(queryString.token) && isFullString(queryString.email);
	const hasCulture = isObject(queryString) && isFullString(queryString.culture);

	useEffect(() => {
		if (isValid) {
			const client = new OidcClient(oidcConfig);

			client.createSigninRequest(oidcConfig).then((res) => {
				// Replace the first occurrence of authorize with updatepassword in the created url.
				const url = res.url.replace(/authorize/i, 'updatepassword');
				// Replace all occurrences of + in the token with an encoded value to have double encoding and prevent it from being replaced with a space when decoded.
				// Encode the whole token value to allow its value to be in the url.
				const token = encodeURIComponent(queryString.token.replace(/\+/g, encodeURIComponent('+')));
				const email = queryString.email.replace(/\+/g, encodeURIComponent('+'));
				const culture = hasCulture ? `&culture=${queryString.culture}` : '';
				// Add additional email and token query parameters to the url and redirect the user to it.
				window.location = `${url}&email=${email}&token=${token}${culture}`;
			});
		} else {
			navigate('/auth/login');
		}
	}, []);

	return null;
};

export default Reset;
