import { useState } from 'react';

import { Badge } from '@mui/base/Badge';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Divider,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { userInfoAtom } from '~atoms';
import { FormDialog } from '~components';
import { useAuthorize } from '~features/authentication';
import { useSnackbar } from '~hooks';

import ServiceTicketStatusEnum from '../../enum/serviceTicketStatusEnum';
import ChangeTicketStatusForm from '../../forms/changeTicketStatusForm.tsx/changeTicketStatusForm';
import ServiceTicketsService from '../../services/serviceTicketsService';
import ServiceTicketsDataGrid from '../dataGrids/serviceTicketsDataGrid';
import { BaseReference } from '~interfaces';

interface UserAction {
	type: 'editStatus';
	label?: string;
	id: string;
	status: ServiceTicketStatusEnum;
}

const service = new ServiceTicketsService();

type ServiceTicketStatusEnumExceptClose = Exclude<
	ServiceTicketStatusEnum,
	ServiceTicketStatusEnum.Closed
>;

interface TicketsSummaryCardProps {
	organisationId?: string;
}

const TicketsSummaryCard = ({ organisationId }: TicketsSummaryCardProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const userInfo = useAtomValue(userInfoAtom);

	const [selectedTab, setSelectedTab] = useState<ServiceTicketStatusEnumExceptClose>(
		ServiceTicketStatusEnum.Open,
	);

	const { data, mutate } = useSWR(
		[
			service.basePath,
			{
				organsationId: !isSuperAdmin() ? userInfo.organisation.id : organisationId,
				access: !isSuperAdmin() ? ['shared', 'owned'] : ['owned'],
			},
		],
		([_, args]) => service.getTicketStatusSummary(args.organsationId, args.access),
	);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { trigger: triggerChangeStatus, isMutating: isChangeStatusMutating } = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id], { arg }) => service.updateTicketStatus(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(
					`${t('ui.successfully')} ${t('ui.updated')} ${t('ui.ticket.label')} ${userAction?.id}`,
				);
				setUserAction(null);
				mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	return (
		<>
			<Card sx={{ display: 'flex', flexDirection: 'column' }}>
				<CardHeader title={t('ui.ticketing')} />
				<Divider />
				<Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
					{Object.values(ServiceTicketStatusEnum).map((el: ServiceTicketStatusEnum) => {
						if (el === ServiceTicketStatusEnum.Closed) {
							return null;
						}

						return (
							<Tab
								key={el}
								label={
									<Stack spacing={1} direction='row' sx={{ alignItems: 'center' }}>
										{t(`ui.status.${el}`)}
										{data != null && data[el] > 0 && (
											<Box sx={{ pl: 1 }}>
												<Chip
													label={<Typography color='error'>{data[el]}</Typography>}
													color='error'
													sx={{ px: 0 }}
												/>
											</Box>
										)}
									</Stack>
								}
								value={el}
								sx={{ height: 72, alignItems: 'center' }}
							/>
						);
					})}
				</Tabs>
				<Divider />
				<CardContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
						flexGrow: 1,
						p: 0,
						'&:last-child': { pb: 0 },
					}}
				>
					<ServiceTicketsDataGrid
						access={isSuperAdmin() ? ['owned'] : ['owned', 'shared']}
						disableToolbar
						disableUrlSync
						mode='dashboard'
						ticketStatus={selectedTab}
						organisationId={organisationId}
						onStatusChange={(val) =>
							setUserAction({ id: val.id, type: 'editStatus', status: val.status })
						}
					/>
				</CardContent>
			</Card>
			<FormDialog
				title={t('ui.ticketStatus.title')}
				open={userAction?.type === 'editStatus'}
				onClose={() => setUserAction(null)}
				variant='legacy'
				maxWidth='sm'
				fullWidth
				loading={isChangeStatusMutating}
				saveLabel={t('ui.confirm')}
			>
				<ChangeTicketStatusForm
					id={userAction?.id}
					onSubmit={async (val) => await triggerChangeStatus(val)}
					status={userAction?.status}
				/>
			</FormDialog>
		</>
	);
};

export default TicketsSummaryCard;
