import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import {
	ActionDialog,
	AlertDialog,
	FormField,
	GenericMoreButton,
	SelectWithLazyLoading,
	StyledButton,
	Table,
} from '../../../../../components';
import { useError, useWizardFormField } from '../../../../../shared/hooks';
import { isEmptyArray, isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface EmailDomainsProps {
	organisationId?: string;
	emailDomains?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	editEmailDomain?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deleteEmailDomain?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	userGroups?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	organisationObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchEmailDomains?(...args: unknown[]): unknown;
	onEditEmailDomain?(...args: unknown[]): unknown;
	onDeleteEmailDomain?(...args: unknown[]): unknown;
	onFetchUserGroups?(...args: unknown[]): unknown;
}

const EmailDomains = (props: EmailDomainsProps) => {
	const {
		onFetchEmailDomains,
		emailDomains,

		deleteEmailDomain,
		onDeleteEmailDomain,
		organisationId,
		editEmailDomain,
		onEditEmailDomain,
		userGroups,
		onFetchUserGroups,
		organisationObject,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const navigate = useNavigate();

	const selectedEmailDomain = useWizardFormField('');
	const [selectedUserGroup, setSelectedUserGroup] = useState({ name: '' });

	const { data: organisationData } = organisationObject;

	const {
		data: emailDomainsData,
		loading: emailDomainsLoading,
		error: emailDomainsError,
	} = emailDomains;
	const emailDomainsReady =
		isObject(emailDomainsData) && !emailDomainsLoading && !emailDomainsError;

	const { loading: editedEmailDomainLoading } = editEmailDomain;

	const [openEditDialog, setOpenEditDialog] = useState(false);

	const {
		success: deleteEmailDomainSuccess,
		loading: deleteEmailDomainLoading,
		error: deleteEmailDomainError,
	} = deleteEmailDomain;
	const deletedEmailDomainDone =
		deleteEmailDomainSuccess && !deleteEmailDomainLoading && !deleteEmailDomainError;
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deletedEmailDomain, setDeletedEmailDomain] = useState();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const isEmpty = !emailDomainsLoading && isEmptyArray(emailDomainsData?.results);

	const editEmailDomainMessage = useError({
		value: editEmailDomain,
		message: t('views.emailDomains.editedEmailDomain.success'),
	});

	const deletedEmailDomainMessage = useError({
		value: deleteEmailDomain,
		message: t('views.emailDomains.deletedEmailDomain.success'),
	});

	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
		setPageNumber(1);
		setShouldDoInitialFetch(true);
	};

	const handlePageChange = (page) => {
		setPageNumber(page);
		setShouldDoInitialFetch(true);
	};

	useEffect(() => {
		if (deletedEmailDomainDone) {
			setOpenDeleteDialog(false);
		}
	}, [deletedEmailDomainDone]);

	/* * * * * * * * *
	 * DATA FETCHING *
	 * * * * * * * * */

	const [filter] = useState({
		name: 'organisationId',
		value: organisationId,
	});

	const [extraFilter] = useState({
		name: 'filters',
		value: 'none',
	});

	useEffect(() => {
		const page = { number: pageNumber, size: pageSize };

		if (shouldDoInitialFetch) {
			if (showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchEmailDomains(organisationId, page);
		}

		if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [pageNumber, pageSize]);

	/* * * * * *
	 * ACTIONS *
	 * * * * * */

	const handleAddEmailDomain = () => {
		navigate(`/organisations/${organisationId}/email-domains/add`, {
			state: { from: location.pathname, showBackButton: true },
		});
	};

	const handleEditEmailDomain = () => {
		onEditEmailDomain(selectedEmailDomain, selectedUserGroup.id, organisationId);
		editEmailDomainMessage.setStartAction(true);
		handleCloseEditDialog();
	};

	const handleDeleteEmailDomain = () => {
		onDeleteEmailDomain(organisationId, deletedEmailDomain);
		deletedEmailDomainMessage.setStartAction(true);
	};

	const handleCloseEditDialog = () => {
		setOpenEditDialog(false);
	};

	const handleClickDelete = (emailDomain) => {
		setDeletedEmailDomain(emailDomain.domainName);
		handleOpenDeleteDialog();
	};

	const handleOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	/* * * * * *
	 * FILTER  *
	 * * * * * */

	const createMenuItems = (selectedEmailDomain) => [
		{
			icon: <DeleteIcon />,
			text: t('ui.button.inline.delete'),
			action: () => handleClickDelete(selectedEmailDomain),
			isRed: true,
		},
	];

	const tableHeaders = [
		{ name: 'email-domain', content: t('ui.label.emailDomain') },
		{ name: 'user-group', content: t('ui.label.userGroup') },
		...(isSuperAdmin() ? [{ name: '', content: '', hasSorting: false }] : []),
	];

	const tableBody =
		emailDomainsReady ?
			emailDomainsData.results.map((emailDomain) => [
				{ content: '@'.concat(emailDomain.domainName) },
				{ content: emailDomain.userGroupReference?.name },
				...(isSuperAdmin() ?
					[
						{
							content: (
								<Box display='flex' flexDirection='row-reverse'>
									<Box pl={5.5}>
										<GenericMoreButton menuItems={createMenuItems(emailDomain)} />
									</Box>
								</Box>
							),
						},
					]
				:	[]),
			])
		:	Array(4)
				.fill(Array(3).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const emailDomainsEmpty = (
		<Paper className={classes.root}>
			<Box className={classes.emptyStateContainer}>
				<Box display='flex' justifyContent='center'>
					<MailOutlineIcon sx={{ fontSize: '100px' }} />
				</Box>
				<Box
					alignItems='center'
					className={classes.textContainer}
					display='flex'
					flexDirection='column'
				>
					<Typography className={classes.emptyStateTitle} variant='h6'>
						{t('views.emailDomains.noEmailDomains.title')}
					</Typography>
					<Typography className={classes.emptyStateSubtitle} variant='body2'>
						{isSuperAdmin() ?
							t('views.emailDomains.noEmailDomains.description')
						:	t('views.emailDomains.noEmailDomainsAdmin.description')}
					</Typography>
				</Box>
				<Box display='flex' justifyContent='center'>
					{isSuperAdmin() ?
						<StyledButton
							classes={classes.emptyStateButton}
							disabled={!organisationData?.features.includes('emailDomains')}
							onClick={handleAddEmailDomain}
							size='large'
							variant='contained-secondary'
						>
							{t('ui.button.outlined.addEmailDomain')}
						</StyledButton>
					:	<StyledButton
							classes={classes.emptyStateButton}
							disabled
							size='large'
							variant='contained-tertiary'
						>
							{t('views.emailDomains.contact')}
						</StyledButton>
					}
				</Box>
				{isSuperAdmin() && !organisationData?.features.includes('emailDomains') && (
					<Box display='flex' justifyContent='center' pt={1}>
						<Typography classes={classes.emptyStateButton} variant='body2'>
							{t('views.emailDomains.noEmailDomains.domainEnabled')}
						</Typography>
					</Box>
				)}
			</Box>
		</Paper>
	);

	return (
		<Box>
			{isEmpty ?
				emailDomainsEmpty
			:	<>
					{isSuperAdmin() && (
						<Box display='flex' flexDirection='row-reverse' pb={3}>
							<StyledButton onClick={handleAddEmailDomain} variant='contained-primary'>
								{t('ui.button.outlined.addEmailDomain')}
							</StyledButton>
						</Box>
					)}
					<Table
						body={tableBody}
						handlePageChange={handlePageChange}
						header={tableHeaders}
						loading={emailDomainsLoading}
						page={pageNumber}
						rowsPerPage={pageSize}
						setRowsPerPage={handlePageSizeChange}
						title={t('views.emailDomains.title')}
						total={emailDomainsData ? emailDomainsData.total : 0}
					/>
				</>
			}
			{openEditDialog && (
				<ActionDialog
					actionButtonProps={{
						action: handleEditEmailDomain,
						text:
							editedEmailDomainLoading ?
								<CircularProgress disableShrink size={24} />
							:	t('ui.edit'),
					}}
					handleClose={handleCloseEditDialog}
					loading={false}
					open={openEditDialog}
					title={t('views.emailDomains.editEmailDomain.title')}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
						<Typography variant='body1'>
							{t('views.emailDomains.editEmailDomain.description')}
						</Typography>
						<FormField
							label={t('ui.label.emailDomain')}
							name={'emailDomain'}
							placeholder={'Ex. @emaildomain.com'}
							required
							variable={selectedEmailDomain}
						/>
						<SelectWithLazyLoading
							dataList={userGroups}
							extraFilter={extraFilter}
							filter={filter}
							listType='organisations'
							onFetchData={onFetchUserGroups}
							setSelected={setSelectedUserGroup}
							value={selectedUserGroup.name}
						/>
					</Box>
				</ActionDialog>
			)}
			{openDeleteDialog && (
				<AlertDialog
					dialogDescription={`${t('views.emailDomains.deletedEmailDomain.confirm')}`}
					dialogTitle={t('ui.delete')}
					handleClose={handleCloseDeleteDialog}
					handleConfirm={handleDeleteEmailDomain}
					loading={deleteEmailDomainLoading && !deletedEmailDomainDone}
					open={openDeleteDialog}
				/>
			)}
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteEmailDomain: state.condition.deleteEmailDomain,
		editEmailDomain: state.condition.editEmailDomain,
		emailDomains: state.paged.emailDomains,

		userGroups: state.paged.userGroups,

		organisationObject: state.details.organisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchEmailDomains: (organisationId, page, filters) =>
			dispatch(actions.fetchEmailDomains(organisationId, page, filters)),
		onEditEmailDomain: (organisationId, emailDomain, userGroup) =>
			dispatch(actions.editEmailDomain(organisationId, emailDomain, userGroup)),
		onDeleteEmailDomain: (organisationId, emailDomain) =>
			dispatch(actions.deleteEmailDomain(organisationId, emailDomain)),
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.fetchUserGroups(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDomains);
