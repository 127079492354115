import * as React from 'react';

import { Popper, Paper, Typography, NoSsr } from '@mui/material';
import {
	ChartsAxisContentProps,
	useAxisTooltip,
	useMouseTracker,
} from '@mui/x-charts/ChartsTooltip';

type CustomAxisTooltipProps = {
	subSeries: any[];
} & ChartsAxisContentProps;

export const CustomAxisTooltip = (props: ChartsAxisContentProps) => {
	const tooltipData = useAxisTooltip();
	const mousePosition = useMouseTracker(); // Track the mouse position on chart.

	if (!tooltipData || !mousePosition) {
		// No data to display
		return null;
	}

	// The pointer type can be used to have different behavior based on pointer type.
	const isMousePointer = mousePosition?.pointerType === 'mouse';
	// Adapt the tooltip offset to the size of the pointer.
	const yOffset = isMousePointer ? 0 : 40 - mousePosition.height;

	return (
		<NoSsr>
			<Popper
				sx={{
					pointerEvents: 'none',
					zIndex: (theme) => theme.zIndex.modal,
				}}
				open
				placement='right-start'
				// placement={isMousePointer ? 'right-start' : 'bottom-end'}
				anchorEl={generateVirtualElement(mousePosition)}
				modifiers={[
					{
						name: 'offset',
						options: {
							offset: [-10, 10],
						},
					},
				]}
			>
				<Paper
					elevation={1}
					sx={{
						m: 1,
						borderRadius: 1,
						table: { borderSpacing: 0 },
						thead: {
							td: {
								px: 1.5,
								py: 0.75,
								borderBottom: 'solid',
								borderWidth: 1,
								borderColor: 'divider',
							},
						},
						tbody: {
							'tr:first-child': { td: { paddingTop: 1.5 } },
							'tr:last-child': { td: { paddingBottom: 1.5 } },
							tr: {
								'td:first-child': { paddingLeft: 1.5 },
								'td:last-child': { paddingRight: 1.5 },
								td: {
									paddingRight: 1,
									paddingBottom: 1,
								},
							},
						},
					}}
				>
					<table>
						<thead>
							<tr>
								<td colSpan={3}>
									<Typography>{tooltipData.axisFormattedValue}</Typography>
								</td>
							</tr>
						</thead>
						<tbody>
							{tooltipData.seriesItems.map((seriesItem) => (
								<>
									<TooltipRow
										color={seriesItem.color}
										label={seriesItem.formattedLabel}
										value={seriesItem.value}
									/>
									{props.subSeries
										.filter(
											(el) =>
												el.parentSeriesId === seriesItem.seriesId &&
												el.data[props.axisData.x!.index] != 0,
										)
										.map((el) => (
											<TooltipRow
												key={`${seriesItem.seriesId}-${el.label}`}
												label={el.label}
												value={el.data[props.axisData.x!.index]}
												type='sub'
											/>
										))}
								</>
							))}
						</tbody>
					</table>
				</Paper>
			</Popper>
		</NoSsr>
	);
};

const TooltipRow = ({
	color,
	label,
	value,
	type = 'main',
}: {
	color?: string;
	label: string;
	value: string;
	type: 'main' | 'sub';
}) => (
	<tr>
		<td>
			{color != null && (
				<div
					style={{
						width: 8,
						height: 8,
						borderRadius: 8,
						backgroundColor: color,
					}}
				/>
			)}
		</td>
		<td style={{ paddingRight: '60px' }}>
			<Typography
				variant={type === 'main' ? 'h6' : 'body1'}
				color={type === 'main' ? 'textPrimary' : 'disabled'}
			>
				{label}
			</Typography>
		</td>
		<td>
			<Typography variant={type === 'main' ? 'h6' : 'body1'}>{value}</Typography>
		</td>
	</tr>
);

/**
 * Helper faking an element bounding box for the Popper.
 */
const generateVirtualElement = (mousePosition: { x: number; y: number } | null) => {
	if (mousePosition === null) {
		return {
			getBoundingClientRect: () => ({
				width: 0,
				height: 0,
				x: 0,
				y: 0,
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
				toJSON: () => '',
			}),
		};
	}

	const { x, y } = mousePosition;
	const boundingBox = {
		width: 0,
		height: 0,
		x,
		y,
		top: y,
		right: x,
		bottom: y,
		left: x,
	};

	return {
		getBoundingClientRect: () => ({
			...boundingBox,
			toJSON: () => JSON.stringify(boundingBox),
		}),
	};
};

export default CustomAxisTooltip;
