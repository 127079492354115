import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { MenuItem, Select as MuiSelect, Stack } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { Select, TextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './createNfcTagSchema.json';
import TagTypeEnum from '../../enums/tagTypeEnum';
import { NfcTagNew } from '../../interfaces/nfcTag';

const hexRegex = new RegExp(/^[0-9a-f]*$/i);

interface CreateNfcTagFormProps {
	onSubmit?: (value: NfcTagNew) => void;
}

const CreateNfcTagForm = forwardRef<FormWrapperRefProps, CreateNfcTagFormProps>(
	({ onSubmit }, ref) => {
		const { t } = useTranslation('general');
		const userInfo = useAtomValue(userInfoAtom);
		const { isSuperAdmin } = useAuthorize();

		const { setDisabled } = useFormContainerState();
		const { getValues, control, formState, reset, watch } = useForm<NfcTagNew>({
			defaultValues:
				isSuperAdmin() ?
					{}
				:	{
						organisation: userInfo.organisation,
						tagType: TagTypeEnum.External,
					},
			mode: 'onChange',
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		const [errorMessage, setErrorMessage] = useState<string | null>(null);

		const handleNfcIdChange = (newNfcId: string, onChange: () => void) => {
			if (hexRegex.test(newNfcId)) {
				setErrorMessage(null);
			} else {
				setErrorMessage(
					`${t('ui.label.contract.status.invalid')} ${t('views.usersAndOrganisations.nfcCards.nfcId')}`,
				);
			}
			onChange(newNfcId);
		};

		return (
			<Stack
				direction='column'
				spacing={2}
				sx={{
					my: 1,
				}}
			>
				{isSuperAdmin() && (
					<>
						<Controller
							name='organisation'
							control={control}
							render={({ field }) => (
								<OrganisationAutocomplete
									required
									value={field.value}
									onChange={(_, newValue) => field.onChange(newValue)}
								/>
							)}
						/>
						<Controller
							name='tagType'
							control={control}
							render={({ field }) => (
								<Select
									required
									labelId='tag-type-select-label2'
									label={t('views.usersAndOrganisations.nfcCards.tagType')}
									defaultValue=''
									value={field.value}
									onChange={(e) => field.onChange(e.target.value)}
								>
									{Object.values(TagTypeEnum).map((el: TagTypeEnum) => (
										<MenuItem key={el} value={el}>
											{el === 'skopei' ? 'Skopei' : t('ui.label.external')}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</>
				)}
				<Controller
					name='tagNumber'
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							required
							label={t('views.userDetails.summary.nfcCardNumber')}
							placeholder='SK123123'
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.tagNumber.maxLength,
								},
							}}
						/>
					)}
				/>
				<Controller
					name='id'
					control={control}
					render={({ field }) => (
						<TextField
							value={field.value?.toUpperCase()}
							label={t('rfidUid')}
							placeholder='12AB56CD'
							required
							error={errorMessage != null}
							helperText={errorMessage}
							slotProps={{
								htmlInput: {
									minLength: schema.properties.id.minLength,
									maxLength: schema.properties.id.maxLength,
								},
							}}
							onChange={(e) => handleNfcIdChange(e.target.value, field.onChange)}
						/>
					)}
				/>
			</Stack>
		);
	},
);
CreateNfcTagForm.displayName = 'CreateNfcTagForm';

export default CreateNfcTagForm;
