import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';

import { useStyles } from './style';
import { LoadingBar, StyledButton } from '../../../../../components';
import { isFullString } from '../../../../../shared/utility';

interface HeaderProps {
	className?: string;
	loading?: boolean;
	title?: string;
	date?: string;
	name?: string;
	total?: string;
}

// import { useDetailPageBackButton } from '../../../shared/hooks';

const Header = (props: HeaderProps) => {
	const { className, title, date, name } = props;
	const location = useLocation();
	const navigate = useNavigate();

	const classes = useStyles();

	const handleBackButtonChange = () =>
		navigate(
			`/financeManagement/revenue/${date}/${title}/partner/${location.state.organisationId}/${name}`,
		);

	return (
		<div className={clsx(classes.root, className)}>
			<Typography component='h2' gutterBottom variant='overline'>
				<Hidden only={['xs', 'sm']}>
					<StyledButton
						className={classes.backButton}
						onClick={handleBackButtonChange}
						startIcon={<KeyboardArrowLeftOutlinedIcon />}
						variant='inline-default'
					>
						{title}
					</StyledButton>
				</Hidden>
			</Typography>
			<Typography component='h1' variant='h3'>
				{!isFullString(name) ?
					<LoadingBar />
				:	name}
			</Typography>
		</div>
	);
};

export default Header;
