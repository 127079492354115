import AboutPolicy from './AboutPolicy';
import Header from './Header';
import { useStyles } from './style';

interface PolicyDetailsProps {
	save(...args: unknown[]): unknown;
	name?: string;
	selectedOrganisation?: object;
	editing?: boolean;
	currentPolicyName?: string;
}

const PolicyDetails = (props: PolicyDetailsProps) => {
	const { save, name, selectedOrganisation, editing, currentPolicyName } = props;

	const classes = useStyles();

	const aboutPolicyProps = { name, selectedOrganisation, currentPolicyName };

	return (
		<>
			<Header editing={editing} />
			<AboutPolicy
				className={classes.aboutPolicy}
				editing={editing}
				save={save}
				{...aboutPolicyProps}
			/>
		</>
	);
};

export default PolicyDetails;
