import { useState, useReducer, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Card, CardContent, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import FileReaderInput from 'react-file-reader-input';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { HeaderWithStatus, StyledButton } from '../../../../../components';
import { languages } from '../../../../../constantsOld';
import { isEmptyArray, isFullArray, bytesToSize } from '../../../../../shared/utility';

const handleFileReducer = (state, action) => {
	switch (action.type) {
		case 'add': {
			return state.concat(action.file);
		}
		case 'delete': {
			return state.filter((_, index) => index !== action.index);
		}
		default: {
			return state;
		}
	}
};

interface UploadTermsAndConditionsProps {
	newFiles?: unknown[];
	dutch?: unknown[];
	german?: unknown[];
	english?: unknown[];
	save?(...args: unknown[]): unknown;
}

const UploadTermsAndConditions = (props: UploadTermsAndConditionsProps) => {
	const { save, newFiles, dutch, german, english } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [fileSize, setFileSize] = useState(null);
	const [sizeWarning, setSizeWarning] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(null);
	const [files, dispatchFiles] = useReducer(handleFileReducer, []);

	const maxFileSize = 10;

	const handleLanguageChange = (event) => {
		event.preventDefault();
		setSelectedLanguage(event.target.value);
	};

	useEffect(() => {
		if (isFullArray(newFiles)) {
			save({ files: [] });
		}
	}, [newFiles]);

	const handleAddFile = () => {
		save({
			selectedLanguage,
			fileName,
			fileSize,
			files,
		});
		setFileName(null);
		setFileSize(null);
		setSelectedLanguage(null);
		dispatchFiles({ type: 'delete', index: 0 });
	};

	return (
		<Box className={classes.root}>
			<Card className={classes.cardContainer}>
				<CardContent className={classes.cardContent}>
					<HeaderWithStatus
						header={t('views.addTerms.uploadFiles.title')}
						headerSize='h4'
						subheader={t('views.addTerms.uploadFiles.subtitle')}
						subheaderSize='body2'
					/>
					<TextField
						InputLabelProps={{
							shrink: true,
						}}
						SelectProps={{
							value: selectedLanguage || t('views.addTerms.uploadFiles.chooseLanguage'),
							displayEmpty: true,
							renderValue: (value) =>
								selectedLanguage ?
									t(`ui.${value}`)
								:	t('views.addTerms.uploadFiles.chooseLanguage'),
						}}
						className={classes.languageSelector}
						disabled={isFullArray(dutch) && isFullArray(german) && isFullArray(english)}
						fullWidth={true}
						onChange={handleLanguageChange}
						placeholder={t('views.addTerms.uploadFiles.chooseLanguage')}
						required
						select
						size='medium'
						variant='outlined'
					>
						{!isEmptyArray(languages) &&
							languages.map((ln) => (
								<MenuItem
									disabled={
										(isFullArray(dutch) && dutch[0].language === ln.language) ||
										(isFullArray(german) && german[0].language === ln.language) ||
										(isFullArray(english) && english[0].language === ln.language)
									}
									key={ln.code}
									value={ln.language}
								>
									{t(`ui.${ln.language}`)}
								</MenuItem>
							))}
					</TextField>
					<Typography className={classes.fileHeading} variant='h5'>
						{`${t('views.addTerms.uploadFiles.file')}`}
					</Typography>

					{isEmptyArray(files) ?
						<FileReaderInput
							accept={'application/pdf'}
							as={'binary'}
							multiple={false}
							onChange={(ev, results) => {
								setLoading(false);
								// eslint-disable-next-line no-unused-vars
								const [progressEvent, file] = results[0];
								setFileName(file.name);
								setFileSize(bytesToSize(file.size, 1));
								if (file.size / (1024 * 1024) > maxFileSize) {
									setSizeWarning(true);
									return;
								}

								dispatchFiles({ type: 'add', file: { file: [file], name: file.name } });
							}}
							onInput={() => {
								setLoading(true);
							}}
						>
							<label
								className={clsx({
									[classes.uploadButton]: true,
									[classes.uploadButtonWarning]: sizeWarning,
									['full-width']: true,
								})}
							>
								<FileUploadIcon className={classes.iconArrow} fontSize='large' />
								<span className={classes.uploadButtonLabel}>
									{loading ?
										<span>Loading...</span>
									:	<>
											<Typography gutterBottom variant='h5'>
												{'Upload pdf'}
											</Typography>
											<Typography
												className={clsx({
													[classes.errorStyle]: sizeWarning,
												})}
												variant='body2'
											>
												{`${t('ui.maxFileSize')}: `}
												{`${maxFileSize}MB`}
											</Typography>
										</>
									}
								</span>
							</label>
						</FileReaderInput>
					:	<Card>
							<CardContent className={classes.uploadedFile}>
								<Box className={classes.fileName}>
									<DescriptionOutlinedIcon className={classes.description} fontSize='large' />
									<Box>
										<Typography variant='h6'>{fileName}</Typography>
										<Typography variant='body2'>{`${fileSize}`}</Typography>
									</Box>
								</Box>
								<IconButton
									onClick={() => {
										dispatchFiles({ type: 'delete', index: 0 });
									}}
									size='large'
								>
									<CloseIcon />
								</IconButton>
							</CardContent>
						</Card>
					}
					<Box className={classes.buttons}>
						<StyledButton
							className={classes.addButton}
							disabled={!selectedLanguage || isEmptyArray(files)}
							onClick={handleAddFile}
							size='large'
							variant='contained-secondary'
						>
							{t('ui.button.contained.add')}
						</StyledButton>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
};

export default UploadTermsAndConditions;
