import { MenuItem, SelectProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Select } from '~components';

import PaymentStatusEnum from '../../enums/paymentStatusEnum';

const PaymentStatusSelect = (props: SelectProps) => {
	const { t } = useTranslation('general');

	return (
		<Select {...props} emptyLabel='All statusses'>
			{Object.values(PaymentStatusEnum).map((el: PaymentStatusEnum) => (
				<MenuItem key={el} value={el}>
					{t(`ui.label.${el}`)}
				</MenuItem>
			))}
		</Select>
	);
};

export default PaymentStatusSelect;
