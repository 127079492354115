import { ItemCategoryEnum } from '~enums';
import { ListResults } from '~interfaces';
import { TopologyService } from '~services';

interface DeviceStat {
	type?: ItemCategoryEnum | 'unlinked';
	total: number;
}

class DeviceStatisticsService extends TopologyService {
	public readonly path = 'graphs/devices';

	constructor() {
		super('v1');
	}

	async getCounts({
		organisationId,
	}: {
		organisationId?: string;
	}): Promise<ListResults<DeviceStat>> {
		const { data } = await this._client.get<DeviceStatResponse>(this.path, {
			params: {
				organisationId: organisationId,
			},
		});

		return {
			total: data.total,
			results: data.categories.map((el) => ({
				type: el.categoryType ? (el.categoryType as ItemCategoryEnum) : 'unlinked',
				total: el.total,
			})),
		};
	}
}

interface DeviceStatResponse {
	categories: {
		categoryType: string;
		total: 0;
	}[];
	total: number;
}

export default DeviceStatisticsService;
