import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog } from '~components';
import { useSnackbar } from '~hooks';

import UsersDataGrid from '../components/dataGrids/usersDataGrid';
import CreateAnonymousForm from '../forms/createAnonymousUser/createAnonymousUserForm';
import UserIdentitiesService from '../services/userIdentitiesService';
import UsersService from '../services/usersService';

type UserAction =
	| {
			type: 'create';
			id?: never;
			label?: never;
			variant: 'anonymous' | 'normal';
	  }
	| {
			type: 'delete' | 'disable' | 'edit';
			id: string;
			label?: string;
			variant?: never;
	  };

const usersService = new UsersService();
const userIdentitiesService = new UserIdentitiesService();

const UsersOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		userIdentitiesService.basePath,
		(_, { arg }) => userIdentitiesService.createAnonymousUser(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerDisable, isMutating: isDisableMutating } = useSWRMutation(
		userAction?.id ? [usersService.basePath, userAction.id] : null,
		([_, id]) => userIdentitiesService.disableUser(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deactivated'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		userAction?.id ? [usersService.basePath, userAction.id] : null,
		([_, id]) => usersService.deleteUser(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	return (
		<>
			<UsersDataGrid
				onEdit={(id) => setUserAction({ type: 'edit', id: id })}
				onDisable={(id) => setUserAction({ type: 'disable', id: id })}
				onCreate={(type) => setUserAction({ type: 'create', variant: 'anonymous' })}
			/>
			<FormDialog
				title={t('createUser')}
				open={userAction?.type === 'create'}
				loading={isCreateMutating}
				onClose={() => setUserAction(null)}
			>
				<CreateAnonymousForm onSubmit={async (data) => triggerCreate(data)} />
			</FormDialog>
			<ConfirmationDialog
				open={userAction?.type === 'delete'}
				loading={isDeleteMutating}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('user'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
			<ConfirmationDialog
				open={userAction?.type === 'disable'}
				loading={isDisableMutating}
				subTitle='TODO'
				onConfirm={async () => await triggerDisable()}
			/>
		</>
	);
};

export default UsersOverviewLayout;
