import { useState, useEffect } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Table, EmptyTable } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import {
	isFullArray,
	capitalizeFirstCharacter,
	isEmptyArray,
	isNull,
	handleHubReference,
} from '../../../../../shared/utility';
import { useStyles } from '../../../Management/style';
import Dialog from '../Dialog';

interface InstancesTableProps {
	instancesData?: unknown[];
	type?: string;
	item?: object;
	totalInstances?: number;
	consumerId?: number;
	userGroup?: object;
	instancesLoading?: boolean;
}

const InstancesTable = (props: InstancesTableProps) => {
	const {
		instancesData,
		instancesLoading,

		type,
		item,
		totalInstances,
		consumerId,
		userGroup,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [dialog, setDialog] = useState({
		open: false,
		type: '',
		itemId: item?.id,
		consumerId: consumerId,
		userGroupId: userGroup?.id,
		data: null,
	});

	useEffect(() => {
		setDialog((prevState) => ({
			...prevState,
			itemId: item?.id,
			consumerId: consumerId,
			userGroupId: userGroup?.id,
		}));
	}, [item, consumerId, userGroup]);

	const dialogProps = { dialog, setDialog, loading: false };

	const editActionButtons = [
		...(type === 'external' || type === 'public' ?
			[
				{
					icon: <ClearIcon sx={{ marginRight: '8px' }} />,
					text: t(`views.sharing.button.stopSharing.${type}`),
					isRed: true,
					action: (selectionInstances) => {
						setDialog((dialog) => ({
							...dialog,
							open: true,
							type: `stopSharing${capitalizeFirstCharacter(type)}`,
							data: selectionInstances,
						}));
					},
				},
			]
		:	[]),
		...(type === 'internal' ?
			[
				{
					icon: <RemoveCircleOutlineIcon sx={{ marginRight: '8px' }} />,
					text: t('views.sharing.button.stopSharingInternalUserGroupItems'),
					isRed: true,
					action: (selectionInstances) => {
						setDialog((dialog) => ({
							...dialog,
							open: true,
							type: `stopSharing${capitalizeFirstCharacter(type)}UserGroupItems`,
							data: selectionInstances,
						}));
					},
				},
			]
		:	[]),
	];

	const handleLinks = (
		path,
		label,
		returnButton = 'views.itemManagement.management.table.header',
	) => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, label: t(returnButton) }}
		>
			{label}
		</Link>
	);

	const showHeaders =
		isNull(instancesData) ? totalInstances
		: isFullArray(instancesData) ? true
		: false;

	const tableHeader = [
		...(showHeaders ?
			[
				{ name: 'itemGroep', content: t('ui.label.item') },
				{ name: 'location', content: t('ui.label.location') },
				{ name: 'created', content: t('ui.dateCreated') },
				{ name: 'lastUpdated', content: t('views.ticketsDetails.lastUpdated') },
			]
		:	[]),
	];

	const loading = Array(totalInstances)
		.fill(Array(tableHeader.length).fill())
		.map((arr) => arr.map(() => ({ loading: true })));

	const emptyBody = isEmptyArray(instancesData) &&
		!isNull(instancesData) && [[{ content: <EmptyTable label={'views.sharing.noItems'} /> }]];

	const tableBody =
		isFullArray(instancesData) ?
			instancesData?.map((instance) => [
				{
					content: handleLinks(
						`/item-management/${item?.id}/instance/${instance.id}/summary`,
						instance.name,
					),
				},
				{ content: handleHubReference(instance) },
				{ content: instance?.created && commaTimeStrings(instance.created) },
				{ content: instance?.lastUpdated && commaTimeStrings(instance.lastUpdated) },
			])
		:	null;

	const body =
		isNull(instancesData) || instancesLoading ? loading
		: isFullArray(instancesData) ? tableBody
		: emptyBody;

	return (
		<Box sx={{ marginTop: 3 }}>
			<Table
				body={body}
				data={isFullArray(instancesData) ? instancesData : []}
				editActionButtons={editActionButtons}
				hasSelectionEnabled={isFullArray(instancesData)}
				header={tableHeader}
				isNotPaginate={true}
				loading={instancesLoading}
				noTitle={true}
				withoutPages={true}
			/>
			{dialog.open ?
				<Dialog {...dialogProps} />
			:	null}
		</Box>
	);
};

export default InstancesTable;
