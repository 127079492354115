import { useState, useEffect, useReducer } from 'react';

import path from 'path';

import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
	AccordionDetails,
	Box,
	Card,
	CardContent,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { pagePathSegments } from '~constants';

import AddTermsAndConditionsTitle from './AddTermsAndConditionsTitle';
import { useStyles, Accordion, AccordionSummary } from './style';
import UploadTermsAndConditions from './UploadTermsAndConditions';
import { StyledButton, Wizard } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import {
	isEmptyObject,
	isObject,
	isNull,
	isInteger,
	isFullString,
	isFullArray,
	bytesToSize,
	isEmptyArray,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const handleNewFileReducer = (state, action) => {
	switch (action.type) {
		case 'add': {
			return state.concat(action.file);
		}
		case 'delete': {
			return state.filter((_, index) => index !== action.index);
		}
		default: {
			return state;
		}
	}
};

interface AddTermsAndConditionsProps {
	className?: string;
	editTermData?: object;
	editing?: boolean;
	detailEdit?: boolean;
	onAddTermsAndConditions?(...args: unknown[]): unknown;
	onUpdateTerm?(...args: unknown[]): unknown;
	addedTermsAndConditions?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateTerm?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddTermsAndConditions = (props: AddTermsAndConditionsProps) => {
	const {
		onAddTermsAndConditions,

		addedTermsAndConditions,
		editing,
		detailEdit,
		editTermData,
		onUpdateTerm,
		updateTerm,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const navigate = useNavigate();

	const [showSidepanel, setShowSidepanel] = useState(false);
	const [startedFinishWizard, setStartedFinishWizard] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [dataWizard, setDataWizard] = useState();
	const [termsAndConditionsTitle, setTermsAndConditionsTitle] = useState(
		editing ? editTermData.title : '',
	);
	const [newFiles, dispatchNewFiles] = useReducer(handleNewFileReducer, []);
	const [termsAndConditionsValid, setTermsAndConditionsValid] = useState(false);

	const { data: termsData, loading: termsLoading, error: termsError } = addedTermsAndConditions;

	const addedTermsAndConditionsMessage = useError({
		value: addedTermsAndConditions,
		message: `${t('ui.successfullyAdded')} ${isObject(termsData) && termsData.title}`,
	});

	const updateTermsAndConditionsMessage = useError({
		value: updateTerm,
		message: `${t('views.policyManagement.addTermsAndConditions.successMessage')} ${termsAndConditionsTitle}`,
	});

	const isCreateDone =
		!addedTermsAndConditions.loading &&
		isObject(addedTermsAndConditions.data) &&
		!addedTermsAndConditions.error;
	const isUpdateDone = !updateTerm.loading && isObject(updateTerm.data) && !updateTerm.error;

	useEffect(() => {
		if (isCreateDone) {
			navigate(
				`/${path.join(pagePathSegments.PolicyManagement, pagePathSegments.TermAndConditions)}`,
			);
		}
	}, [isCreateDone]);

	useEffect(() => {
		if (!isNull(termsError)) {
			setStartedFinishWizard(false);
		}
	}, [termsError]);

	const cultureCode = { nl: 'dutch', en: 'english', de: 'german' };

	// useEffect(() => {
	//   if (editing && isObject(editTermData)) {
	//     const newFile = editTermData.termsLocalizations.map(item => {
	//       fetch(item.url).then(resp => resp.arrayBuffer()).then(resp => {
	//         const myBlob = new Blob([resp], { type: 'application/pdf' });
	//         const file = new File([myBlob], `${cultureCode[item.cultureCode.slice(0, 2)]}.pdf`);
	//         if (isEmptyArray(newFiles)) {
	//           dispatchNewFiles({
	//             type: 'add', file: {
	//               file: file,
	//               language: cultureCode[item.cultureCode.slice(0, 2)]
	//             }
	//           });
	//         }

	//       });
	//     });
	//     return newFile;
	//   }
	// }, [editing, editTermData]);

	useEffect(() => {
		let isMounted = true;
		const fetchFiles = async () => {
			if (editing && isObject(editTermData)) {
				// eslint-disable-next-line no-unused-vars
				const newFilePromises = editTermData.termsLocalizations.map(async (item) => {
					const resp = await fetch(item.url);
					const respArrayBuffer = await resp.arrayBuffer();
					const myBlob = new Blob([respArrayBuffer], { type: 'application/pdf' });
					const file = new File([myBlob], `${cultureCode[item.cultureCode.slice(0, 2)]}.pdf`);
					if (isEmptyArray(newFiles)) {
						dispatchNewFiles({
							type: 'add',
							file: {
								file: file,
								language: cultureCode[item.cultureCode.slice(0, 2)],
							},
						});
					}
				});
				// const newFiles = await Promise.all(newFilePromises);
				if (isMounted) {
					// do something with newFiles if needed
				}
			}
		};
		fetchFiles();

		return () => {
			isMounted = false;
		};
	}, [editing, editTermData]);

	useEffect(() => {
		if (updateTermsAndConditionsMessage.startAction) {
			// Less than ideal, but if we don't use a timeout we miss the snackbar.
			// It seems that there is also no proper state change to listen to.
			setTimeout(
				() =>
					navigate(
						`/${path.join(pagePathSegments.PolicyManagement, pagePathSegments.TermAndConditions)}`,
					),
				500,
			);
		}
	}, [updateTermsAndConditionsMessage.startAction]);

	useEffect(() => {
		if (updateTerm.loading) {
			console.log(updateTerm);
		}
	}, [updateTerm]);

	const dutch = newFiles
		.filter((item) => (item.language === 'dutch' ? item.file : null))
		.map((val) => val);
	const german = newFiles
		.filter((item) => (item.language === 'german' ? item.file : null))
		.map((val) => val);
	const english = newFiles
		.filter((item) => (item.language === 'english' ? item.file : null))
		.map((val) => val);

	const handleConfirm = (edit) => {
		if (edit) {
			onUpdateTerm(editTermData.id, termsAndConditionsTitle, dutch, german, english, detailEdit);
			updateTermsAndConditionsMessage.setStartAction(true);
		} else {
			onAddTermsAndConditions(
				dataWizard.selectedOrganisation.value.id,
				dataWizard.title.value,
				dutch,
				german,
				english,
			);
			addedTermsAndConditionsMessage.setStartAction(true);
		}
	};

	const callback = (data) => {
		const { selectedOrganisation, title, selectedLanguage, activeStep, finishedWizard } = data;

		if (!isEmptyObject(data)) {
			if (data.files) {
				dispatchNewFiles({
					type: 'add',
					file: data.files.map((item) => ({
						file: item.file[0],
						language: selectedLanguage,
						title: title.value,
						organisationId: selectedOrganisation.value.id,
					})),
				});
			}
		}

		setTermsAndConditionsValid(selectedOrganisation.valid && title.valid);
		if (data) {
			setDataWizard(data);
		}
		if (isFullString(title)) {
			setTermsAndConditionsTitle(title);
		}

		if (isInteger(activeStep)) {
			setCurrentStep(activeStep);
		}

		if (activeStep === 0) {
			setShowSidepanel(false);
		} else {
			setShowSidepanel(true);
		}

		if (finishedWizard && !startedFinishWizard) {
			setStartedFinishWizard(true);

			addedTermsAndConditionsMessage.setStartAction(true);
		}
	};
	const addTermsAndConditionsSteps = [
		{
			name: 'Add terms and conditions title',
			content: AddTermsAndConditionsTitle,
			valid: termsAndConditionsValid,
			props: { editing, termsAndConditionsTitle },
		},
		{
			name: 'Upload terms and conditions pdf',
			content: UploadTermsAndConditions,
			valid: termsAndConditionsValid,
			props: { newFiles, dutch, german, english, editing, editTermData },
		},
	];

	const fileDetailsSidePanel = (
		<Card className={classes.topPanel}>
			<CardContent>
				<Typography pb={2} variant='h4'>
					{t('ui.summary')}
				</Typography>
				<Typography pb={2} pt={2} variant='h6'>
					{termsAndConditionsTitle}
				</Typography>
				<Divider />
				{isFullArray(newFiles) ?
					newFiles.map((item, index) => {
						return (
							<Accordion className={classes.panelBorder} key={index}>
								<AccordionSummary
									aria-controls='panel1a-content'
									className={classes.panelBorder}
									expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
									id='panel1a-header'
								>
									<Typography variant='h6'>{t(`ui.${item.language}`)}</Typography>
								</AccordionSummary>
								<AccordionDetails className={classes.expansionDetails}>
									<Box display='flex' justifyContent='space-between'>
										<Box className={classes.fileName}>
											<DescriptionOutlinedIcon className={classes.description} fontSize='large' />
											<Box>
												<Typography variant='h6'>
													{isObject(item.file) && item.file.name}
												</Typography>
												<Typography variant='body2'>
													{`${isObject(item.file) && bytesToSize(item.file.size, 1)}`}
												</Typography>
											</Box>
										</Box>
										<IconButton
											className={classes.closeButton}
											onClick={() => {
												dispatchNewFiles({ type: 'delete', index: index });
											}}
											size='large'
										>
											<CloseIcon />
										</IconButton>
									</Box>
								</AccordionDetails>
							</Accordion>
						);
					})
				:	<Box className={classes.noTermsCard}>
						<InfoOutlinedIcon className={classes.noTermsIcon} fontSize='medium' />
						<Typography className={classes.noTermsLabel} variant='h6'>
							{t('views.addTerms.noFiles')}
						</Typography>
					</Box>
				}
				<StyledButton
					className={classes.confirmButton}
					disabled={isEmptyArray(newFiles)}
					fullWidth
					onClick={() => handleConfirm(editing ? editing : '')}
					variant='contained-secondary'
				>
					{editing ? t('ui.update') : t('ui.confirm')}
				</StyledButton>
			</CardContent>
		</Card>
	);

	return (
		<Wizard
			callback={callback}
			loading={termsLoading}
			shouldNotShowStepActionFooter={currentStep === 1}
			sidebar={showSidepanel ? fileDetailsSidePanel : undefined}
			stepperType={'progress'}
			steps={addTermsAndConditionsSteps}
			title={t('views.addTermsAndConditions.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		addedTermsAndConditions: state.details.addedTermsAndConditions,
		updateTerm: state.details.updateTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddTermsAndConditions: (organisationId, title, dutch, german, english) =>
			dispatch(actions.addTermsAndConditions(organisationId, title, dutch, german, english)),
		onUpdateTerm: (termId, title, dutch, german, english, detailEdit) =>
			dispatch(actions.updateTerm(termId, title, dutch, german, english, detailEdit)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTermsAndConditions);
