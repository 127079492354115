import { useState, useEffect } from 'react';

import clsx from 'clsx';

import { AlertDialog, StyledButton } from '../../../components';
import { isNull } from '../../../shared/utility';

interface ButtonWithAlertDialogProps {
	className?: string;
	children: React.ReactNode[] | React.ReactNode;
	startIcon?: React.ReactNode;
	variant?: string;
	color?: string;
	callback(...args: unknown[]): unknown;
	dialogTitle?: string;
	dialogDescription?: string;
	dialogLoading?: boolean;
	actionDone?: boolean;
	onClick?(...args: unknown[]): unknown;
	redirectTo?: string;
	disabled?: boolean;
	error?: string | object;
	textDialog?: object;
}

const ButtonWithAlertDialog = (props: ButtonWithAlertDialogProps) => {
	const {
		className,
		children,
		startIcon,
		callback,
		dialogTitle,
		dialogDescription,
		dialogLoading,
		actionDone,
		onClick,
		color,
		variant,
		error,
		textDialog,
		disabled,
	} = props;

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirm = () => {
		callback();
	};
	const [submitting, setSubmitting] = useState(false);
	const [setSubmitted] = useState(false);

	useEffect(() => {
		if (open && submitting && actionDone) {
			setSubmitting(false);
			setSubmitted(true);
		}
	}, [open, submitting, actionDone]);

	useEffect(() => {
		if (dialogLoading || error) {
			const errorTimer = setTimeout(() => setOpen(false), 30000);
			if (!isNull(error)) {
				setOpen(false);
				return () => clearTimeout(errorTimer);
			}
		}
	}, [error, dialogLoading]);

	return (
		<>
			<StyledButton
				className={clsx(className)}
				color={color}
				disabled={disabled}
				onClick={onClick ? () => onClick(handleOpen) : handleOpen}
				startIcon={startIcon}
				variant={variant}
			>
				{children}
			</StyledButton>
			<AlertDialog
				dialogDescription={dialogDescription}
				dialogTitle={dialogTitle}
				handleClose={handleClose}
				handleConfirm={handleConfirm}
				loading={dialogLoading}
				open={open}
				textDialog={textDialog}
			/>
		</>
	);
};

export default ButtonWithAlertDialog;
