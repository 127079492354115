import { useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FormField } from '../../../../components';
import { licensePlateLength } from '../../../../constantsOld';

interface LicensePlateProps {
	planboardBookingData?: object;
	disabled: boolean;
	open: boolean;
	policies?: object;
	licensePlate?: object;
}

const LicensePlate = (props: LicensePlateProps) => {
	const { disabled, open, licensePlate } = props;
	const { t } = useTranslation('general');

	useEffect(() => {
		if (!open) {
			licensePlate.resetToInitialValue();
		}
	}, [open]);

	return (
		<Box mb={1.5} mt={1.5}>
			<Box mb={1.5}>
				<Typography variant='h5'>{t('ui.label.licensePlate')}</Typography>
			</Box>
			<FormField
				disabled={disabled}
				maxLength={licensePlateLength}
				name='licensePlate'
				variable={licensePlate}
			/>
		</Box>
	);
};

export default LicensePlate;
