import { IconButton } from '@mui/material';

interface CustomIconButtonProps {
	children?: any;
}

const CustomIconButton = (props: CustomIconButtonProps) => {
	const { children, ...rest } = props;
	return <IconButton {...rest}>{children}</IconButton>;
};

export default CustomIconButton;
