import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	className?: string;
	editing?: boolean;
	name?: string;
}

const Header = (props: HeaderProps) => {
	const { className, editing, name } = props;
	const { t } = useTranslation('general');

	return (
		<div className={className}>
			{editing ?
				<>
					<Typography component='h2' gutterBottom>
						{name}
					</Typography>
				</>
			:	<Typography component='h2' gutterBottom variant='overline'>
					{/* Company Name */}
				</Typography>
			}

			<Typography component='h1' variant='h3'>
				{editing ? t('ui.editing') : t('views.addPolicy.page.title')}
			</Typography>
		</div>
	);
};

export default Header;
