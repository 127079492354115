import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SwitchButton } from './style';
import { ReactDatePicker } from '../../../../components';
import {
	modifyDate,
	sameDates,
	unsetTime,
	isEarlierThan,
	isLaterThan,
	getDateRoundedToDuration,
} from '../../../../shared/datetime';

interface ExtendCardProps {
	end?: object | string;
	classes?: object;
	selectedEndDate?: object;
	setSelectedEndDate?(...args: unknown[]): unknown;
	description?: string;
	switchTimeSelect?: boolean;
	setSwitchTimeSelect?(...args: unknown[]): unknown;
	terminateCard?: boolean;
	extend?: boolean;
	start?: object;
}

const ExtendCard = (props: ExtendCardProps) => {
	const {
		classes,
		start,
		end,
		selectedEndDate,
		setSelectedEndDate,
		description,
		switchTimeSelect,
		setSwitchTimeSelect,
		terminateCard,
		extend,
	} = props;
	const { t } = useTranslation('general');

	const time = new Date();

	const updateEndDate = (date) => {
		setSelectedEndDate(date);
		if (terminateCard) {
			if (isEarlierThan(date, start)) {
				setSelectedEndDate(start);
			}
			if (isLaterThan(date, time)) {
				setSelectedEndDate(getDateRoundedToDuration(new Date(time), 'PT15M', false, true));
			}
		}
	};

	const handleSwitch = () => {
		setSwitchTimeSelect(!switchTimeSelect);
		if (!switchTimeSelect) {
			setSelectedEndDate(time);
		}
	};

	const handleMinTime = () => {
		if (sameDates(selectedEndDate, start, false, true)) {
			return start;
		}
		if (sameDates(selectedEndDate, time, false, true)) {
			return unsetTime(new Date());
		} else {
			return modifyDate(time, { hours: 23, minutes: 45 });
		}
	};

	const handleMaxTime = () => {
		if (sameDates(selectedEndDate, start, false, true) && !sameDates(start, end, false, true)) {
			return modifyDate(start, { hours: 23, minutes: 45 });
		} else {
			return time;
		}
	};

	return (
		<Box className={classes.sideCard}>
			<Box>
				<Typography>{t(description)}</Typography>
			</Box>
			{terminateCard ?
				<div className={classes.switch}>
					<Typography>{t('views.contract.details.switchButton.label')}</Typography>
					<SwitchButton checked={!switchTimeSelect} onChange={handleSwitch} />
				</div>
			:	null}
			<Box mt={4}>
				{(switchTimeSelect && terminateCard) || extend ?
					<ReactDatePicker
						containerClassName={classes.dateTimePicker}
						label={t('views.planboard.addBooking.placeholder.endDate')}
						maxDate={terminateCard ? time : null}
						maxTime={terminateCard ? handleMaxTime() : modifyDate(time, { hours: 23, minutes: 45 })}
						minDate={terminateCard ? start : new Date(end)}
						minTime={
							terminateCard ? handleMinTime()
							: sameDates(new Date(end), selectedEndDate, false, true) ?
								new Date(end)
							:	modifyDate(new Date(), { hours: 0, minutes: 0 })
						}
						onChange={(date) => updateEndDate(date)}
						selected={selectedEndDate}
						showTimeSelect
						wrapperClassName={classes.fullWidth}
					/>
				:	null}
			</Box>
		</Box>
	);
};

export default ExtendCard;
