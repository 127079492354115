import path from 'path';

import { AxiosResponse } from 'axios';

import { IdReference, PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import UserGroup from '../interfaces/userGroup';
import UserGroupWriteable from '../interfaces/userGroupWriteable';

class UserGroupsService extends TopologyService {
	public readonly path = 'usergroups';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of user groups
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUserGroups({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<UserGroup>> {
		const { data } = await this._client.get<PagedResponse<UserGroupResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, UserGroupsService.fromResponse);
	}

	/**
	 * Get the details of a user group
	 * @param id
	 * @returns
	 */
	async getUserGroupById(id: string): Promise<UserGroup> {
		const { data } = await this._client.get<UserGroupResponse>(path.join(this.path, id));

		return UserGroupsService.fromResponse(data);
	}

	/**
	 * Create a new user group
	 * @param data
	 * @returns
	 */
	async createUserGroup(data: UserGroupWriteable): Promise<IdReference> {
		const content = UserGroupsService.toRequest(data);

		const response = await this._client.post<
			IdReference,
			AxiosResponse<IdReference, UserGroupRequest>,
			UserGroupRequest
		>(this.path, content);

		return response.data;
	}

	/**
	 * Delete a user group
	 * @param id
	 * @returns
	 */
	async deleteUserGroup(id: string): Promise<IdReference> {
		const { data } = await this._client.delete<IdReference>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: UserGroupResponse): UserGroup {
		const { organisationReference, totalItems, totalItemInstances, ...rest } = data;

		return {
			...rest,
			...UserGroupsService.fromBaseReferenceResponse(rest),
			organisation: UserGroupsService.fromBaseReferenceResponse(organisationReference),
			totalItemGroups: totalItems,
			totalItems: totalItemInstances,
		};
	}

	static toRequest(data: UserGroupWriteable): UserGroupRequest {
		const { label, organisation, ...rest } = data;

		return {
			...rest,
			name: label,
			organisationId: organisation.id,
		};
	}
}

interface UserGroupResponse extends BaseReferenceResponse {
	organisationReference: BaseReferenceResponse;
	isDefault: boolean;
	totalUsers: number;
	totalItems: number;
	totalItemInstances: number;
}

interface UserGroupRequest {
	name: string;
	description?: string;
	organisationId: string | number;
}

export default UserGroupsService;
