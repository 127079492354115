import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { castMinutesToTimeString } from '../../../../../../shared/utility';

interface BookingPolicyInfoProps {
	className?: string;
	policy: object;
}

const BookingPolicyInfo = (props: BookingPolicyInfoProps) => {
	const { policy, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const handleLicenseStatus = (status) =>
		status === 'verification' || status === 'validation' ? t('ui.enabled') : t('ui.disabled');

	const handleUseNow = (status) =>
		status === 'notApplicable' || status === 'verification' ? t('ui.disabled') : t('ui.enabled');

	const licensePlate = {
		notApplicable: t('ui.disabled'),
		optional: t('ui.enabled'),
		required: t('ui.required'),
	};

	const bookingHours = {
		none: t('ui.disabled'),
		create: t('ui.enabled'),
		usage: t('ui.required'),
	};

	const bookingPolicies = [
		{
			key: '1',
			label: 'ui.label.requiresConfirmation',
			value: policy.requireHostConfirmation ? t('ui.enabled') : t('ui.disabled'),
		},
		{
			key: '2',
			label: 'ui.label.bookingHours',
			value: bookingHours[policy.openHoursRestrictionType],
		},
		{
			key: '3',
			label: 'ui.label.minDuration',
			value:
				policy?.minBookingDurationMinutes &&
				`${castMinutesToTimeString(policy.minBookingDurationMinutes)}`,
		},
		{
			key: '4',
			label: 'ui.label.maxDuration',
			value:
				policy?.maxBookingDurationMinutes &&
				`${castMinutesToTimeString(policy.maxBookingDurationMinutes)}`,
		},
		{
			key: '5',
			label: 'views.addPolicy.bookingConfiguration.section.header.inUse',
			value: policy?.requireOvertimeValidation ? t('ui.enabled') : t('ui.disabled'),
		},
		{
			key: '6',
			label: 'ui.label.futureLimit',
			value:
				policy?.maxDurationBeforeStartMinutes &&
				`${castMinutesToTimeString(policy.maxDurationBeforeStartMinutes)}`,
		},
		// { key: '7', label: 'ui.label.inAdvance', value: policy?.minDurationBeforeStartMinutes && `${castMinutesToTimeString(policy.minDurationBeforeStartMinutes )}` },
		{
			key: '8',
			label: 'ui.label.cooldownPeriod',
			value:
				policy?.cooldownBufferMinutes && `${castMinutesToTimeString(policy.cooldownBufferMinutes)}`,
		},
		{
			key: '9',
			label: 'ui.label.cancellationLimit',
			value:
				policy?.minDurationBeforeCancelMinutes &&
				`${castMinutesToTimeString(policy.minDurationBeforeCancelMinutes)}`,
		},
		{
			key: '10',
			label: 'views.policyManagement.details.policyDetail.graceiPariod',
			value:
				policy?.overtimeGracePeriodMinutes &&
				`${castMinutesToTimeString(policy.overtimeGracePeriodMinutes)}`,
		},
		{
			key: '11',
			label: 'ui.label.extensionPossible',
			value: policy.allowExtending ? t('ui.enabled') : t('ui.disabled'),
		},
		{
			key: '12',
			label: 'ui.label.verificationRequired',
			value: handleLicenseStatus(policy.requiredLicenseStatus),
		},
		{
			key: '13',
			label: 'views.policyManagement.details.policyDetail.useNow',
			value: handleUseNow(policy.requiredLicenseStatus),
		},
		{
			key: '14',
			label: 'minimumAge',
			value: policy.requireMinimumAge ?? '-',
		},
		{
			key: '15',
			label: 'ui.label.dutchLicense',
			value: policy.requireDutchLicense ? t('ui.enabled') : t('ui.disabled'),
		},
		{
			key: '16',
			label: 'ui.label.licensePlate',
			value: licensePlate[policy.requiredParkingLicensePlateNumber],
		},
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.bookingPolicies')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{bookingPolicies.map((item) => (
							<TableRow key={item.key}>
								<TableCell>{t(item.label)}</TableCell>
								<TableCell>{item?.value ? item.value : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default BookingPolicyInfo;
