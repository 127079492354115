import React, { useEffect, useRef, useState } from 'react';

import { Add as AddIcon, MoreVert, NavigateBefore } from '@mui/icons-material';
import { Box, Button, CircularProgress, DialogActions, DialogContent, Stack } from '@mui/material';
import dayjs from 'dayjs';

import { Item, ItemCategorySelect } from '~features/items';
import {
	AsyncTextField,
	Dialog,
	DragAndDropFilesUploader,
	DragAndDropImageCropper,
} from '~components';
import {
	AddSsoDomainsLayout,
	OrganisationsService,
	SsoDomainsDataGrid,
	SsoDomainsLayout,
} from '~features/organisations';
import useSWR from 'swr';
import SsoDomainForm from '~features/organisations/forms/ssoDomain/ssoDomainForm';

const service = new OrganisationsService();

/**
 * This page is meant as a sandbox for a develop to develop or
 * test a component outside of the normal flow
 */
const SandboxPage = () => {
	const [dateVal, setDateVal] = useState<Dayjs | null>(dayjs());
	const [val, setVal] = useState(null);
	const [textVal, setTextVal] = useState<string | null>(null);

	const [numVal, setNumVal] = useState(0);

	const [orignalImageUri, setOriginalImageUri] = useState<string | null>(null);
	const [imageUri, setImageUri] = useState<string | null>(null);

	const [bla, setBla] = useState(null);

	const { data, isLoading } = useSWR(
		textVal != null ? [service.basePath, textVal] : null,
		([_, args]) => service.validateSsoDomain('423', args),
	);

	return (
		<Stack spacing={1}>
			<CircularProgress size={20} color='inherit' />
			<ItemCategorySelect displayEmpty />
			<AsyncTextField />

			<AddSsoDomainsLayout organisationId='423' />

			<DragAndDropImageCropper slotProps={{ imageCropper: { aspect: 1.8 } }} />
		</Stack>
	);
};

export default SandboxPage;
