import { useEffect } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { useStyles } from './style';
import { Input, SelectWithLazyLoading, StyledButton } from '../../../../../components';
import { isFullArray, isFullString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface FiltersProps {
	className?: string;
	events?: object;
	organisationValue?: string | number;
	itemTypeValue: string | number;
	categoriesList?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	hubsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisations(...args: unknown[]): unknown;
	onFetchCategories(...args: unknown[]): unknown;
	onFetchHubs?(...args: unknown[]): unknown;
}

const Filters = (props: FiltersProps) => {
	const classes = useStyles();

	const {
		events,
		itemTypeValue,
		className,
		organisationsList,
		categoriesList,
		hubsList,

		onFetchCategories,
		onFetchOrganisations,
		onFetchHubs,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const itemEvents = { onChange: events.onChangeItem };

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isFullArray(categoriesData) && !categoriesLoading && !categoriesError;

	const categoryOptions = [
		{
			value: 'all',
			label: t('views.assets.items.mainFilters.category.all'),
		},
	].concat(
		categoriesReady ?
			categoriesData.map((category) => ({
				value: category.id,
				label: category.name,
			}))
		:	[],
	);

	useEffect(() => {
		if (!categoriesLoading && !isFullArray(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	return (
		<div className={clsx(classes.root, className)}>
			<div className={classes.filterInputContainer}>
				{isSuperAdmin() ?
					<SelectWithLazyLoading
						className={classes.filterInput}
						dataList={organisationsList}
						defaultListItem={{
							id: '',
							name: t('views.planboard.filterbar.filters.organisations.all'),
						}}
						events={{ filter: 'verified' }}
						listType='organisations'
						onFetchData={onFetchOrganisations}
						placeholder={t('views.planboard.filterbar.filters.organisations.all')}
						searchHandle={events.onOrganisationValue}
						setSelected={events.onChangeOrganisation}
						value={events.organisationFilterValue}
					/>
				:	null}
				<SelectWithLazyLoading
					className={classes.filterInput}
					dataList={hubsList}
					defaultListItem={{ id: '', name: t('ui.filter.locations.all') }}
					events={{ filter: 'none' }}
					listType='hubs'
					onFetchData={onFetchHubs}
					placeholder={t('ui.filter.locations.all')}
					searchHandle={events.setLocationFilterValue}
					setSelected={events.onChangeLocation}
					value={events.locationFilterValue}
				/>
				<Input
					className={classes.selectLazyLoading}
					events={itemEvents}
					inputType='select'
					selectOptions={categoryOptions}
					value={itemTypeValue}
				/>
			</div>
			{(
				isFullString(events.organisationFilterValue) ||
				itemTypeValue !== 'all' ||
				isFullString(events.locationFilterValue)
			) ?
				<StyledButton
					className={classes.button}
					onClick={events.onClear}
					size='medium'
					startIcon={<RefreshIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.clearfilters')}
				</StyledButton>
			:	null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		categoriesList: state.list.categories,
		organisationsList: state.paged.organisations,

		hubsList: state.paged.hubs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
