import { colors } from '@mui/material';

import palette from '../palette';
import typography from '../typography';

export default {
	defaultProps: {
		size: 'small',
		sx: {
			paddingX: 1,
		},
	},
	styleOverrides: {
		root: {
			backgroundColor: colors.blueGrey[50],
			color: colors.blueGrey[900],
		},
		label: {
			fontSize: typography.label.fontSize,
			fontWeight: typography.label.fontWeight,
		},
		sizeSmall: {
			height: '20px',
		},
		colorPrimary: {
			// Align color pattern. Not use the disabled color scheme for this
			backgroundColor: palette.disabled.light,
			color: palette.disabled.main,
		},
		colorSuccess: {
			backgroundColor: palette.success.light,
			color: palette.success.main,
		},
		colorInfo: {
			backgroundColor: palette.info.light,
			color: palette.info.main,
		},
		colorError: {
			backgroundColor: palette.error.light,
			color: palette.error.main,
		},
		colorWarning: {
			backgroundColor: palette.warning.light,
			color: palette.warning.main,
		},

		deletable: {
			'&:focus': {
				backgroundColor: colors.blueGrey[100],
			},
		},
	},
};
