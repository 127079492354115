import { useState, useEffect } from 'react';

import {
	Avatar,
	Box,
	Card,
	CardContent,
	List,
	ListItem,
	Typography,
	Checkbox,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import SelectedUserGroupCard from './SelectedUserGroupCard';
import { useStyles } from './style';
import {
	EmptyState,
	SearchAutocomplete,
	Paginate,
	SelectedOrganisationCard,
	Search,
	LoadingBar,
} from '../../../../../components';
import { useWizardFormField, useSearchComponent } from '../../../../../shared/hooks';
import {
	isEmptyObject,
	isObject,
	getInitials,
	isEmptyArray,
	isFullString,
	isEmptyString,
	isFullArray,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface SelectUserGroupProps {
	selectedUsers: unknown[];
	selectedUserGroup: object;
	selectedOrganisation: object;
	save?(...args: unknown[]): unknown;
	userGroupList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	organisationList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	organisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	usersList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchUserGroups(...args: unknown[]): unknown;
	onFetchOrganisations(...args: unknown[]): unknown;
	onFetchOrganisation(...args: unknown[]): unknown;
	onFetchUsers(...args: unknown[]): unknown;
}

const SelectUserGroup = (props: SelectUserGroupProps) => {
	const {
		selectedUsers: savedSelectedUsers,
		selectedUserGroup: savedSelectedUserGroup,
		selectedOrganisation: savedSelectedOrganisation,

		userGroupList,
		onFetchUserGroups,
		organisationList,
		onFetchOrganisations,
		organisation,
		onFetchOrganisation,
		save,
		currentUser,
		onFetchUsers,
		usersList,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const classes = useStyles();

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const {
		data: currentUserData,
		loading: currentUserLoading,
		error: currentUserError,
	} = currentUser;
	const currentUserReady = isObject(currentUserData) && !currentUserLoading && !currentUserError;

	const [selectedUsers, setSelectedUsers] = useState(savedSelectedUsers);

	useEffect(() => {
		setSelectedUsers(savedSelectedUsers);
	}, [savedSelectedUsers]);

	useEffect(() => {
		save({ selectedUsers });
	}, [selectedUsers]);

	const selectedOrganisation = useWizardFormField(savedSelectedOrganisation, { required: true });

	useEffect(() => {
		save({ selectedOrganisation: selectedOrganisation.value });
	}, [selectedOrganisation.value]);

	const selectedUserGroup = useWizardFormField(savedSelectedUserGroup, { required: true });

	useEffect(() => {
		save({
			userGroup: {
				value: selectedUserGroup.value,
				valid: selectedUserGroup.isValid,
			},
		});

		if (isEmptyObject(selectedOrganisation.value)) {
			save({ selectedOrganisation: selectedUserGroup.value.organisationReference });
		}
	}, [selectedUserGroup.value, selectedUserGroup.isValid]);

	const {
		data: organisationData,
		loading: organisationLoading,
		error: organisationError,
	} = organisation;
	const organisationReady =
		isObject(organisationData) && !organisationLoading && !organisationError;

	const [shouldSetOrganisation, setShouldSetOrganisation] = useState(false);

	const { data: usersData, loading: usersLoading, error: usersError } = usersList;
	const usersReady = isObject(usersData) && !usersLoading && !usersError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(6);

	// filters
	const [userGroupFilter, setUserGroupFilter] = useState({
		name: 'organisationId',
		value: null,
	});

	const search = useSearchComponent(setPageNumber, setShouldSearch, true);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		const page = { size: pageSize, number: pageNumber };

		const filters = {
			...(!isEmptyString(search.value) && { searchTerm: search.value }),
		};
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchUsers(page, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [
		onFetchUsers,
		shouldSearch,
		shouldDoInitialFetch,
		showingInitialResults,
		pageNumber,
		pageSize,
	]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	useEffect(() => {
		if (superAdmin) {
			if (!isEmptyObject(selectedOrganisation.value)) {
				setUserGroupFilter({
					...userGroupFilter,
					value: selectedOrganisation.value.id,
				});
			} else {
				setUserGroupFilter({
					...userGroupFilter,
					value: null,
				});
			}
		} else {
			if (currentUserReady) {
				setUserGroupFilter({
					...userGroupFilter,
					value: currentUserData.organisationReference.id,
				});
			}
		}
	}, [selectedOrganisation.value, selectedOrganisation.id, currentUserReady, superAdmin]);

	// set organisation if user group is set first
	useEffect(() => {
		if (!isEmptyObject(selectedUserGroup.value) && isEmptyObject(selectedOrganisation.value)) {
			onFetchOrganisation(selectedUserGroup.value.organisationReference.id);
			setShouldSetOrganisation(true);
		}
	}, [selectedUserGroup.value, selectedOrganisation.value]);

	useEffect(() => {
		if (organisationReady && shouldSetOrganisation) {
			selectedOrganisation.onChange(organisationData);
			setShouldSetOrganisation(false);
		}
	}, [organisationReady]);

	const resetSelectedOrganisation = () => {
		selectedUserGroup.onChange({});
		selectedOrganisation.onChange({});
	};

	const resetSelectedUserGroup = () => {
		selectedUserGroup.onChange({});
	};

	const handlePageChange = (page) => {
		setPageNumber(page.selected + 1);
		setShouldDoInitialFetch(true);
	};

	const usersListEmpty =
		isObject(usersData) && !usersLoading && isEmptyArray(usersData.results) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.users.empty.description.zeroInSystem')}
					title={t('views.usersAndOrganisations.users.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.users.empty.description.zeroMatching')}
					title={t('views.usersAndOrganisations.users.empty.title')}
				/>
		:	null;

	const handleSelectOne = (user) => {
		const selectedIndex = selectedUsers.map((user) => user.id).indexOf(user.id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedUsers, user);
		} else {
			newSelected = newSelected.concat(
				selectedUsers.slice(0, selectedIndex),
				selectedUsers.slice(selectedIndex + 1),
			);
		}

		setSelectedUsers(newSelected);
	};

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography className={classes.subHeader} variant='h4'>
					{t('views.assignUsersToUserGroup.page.title')}
				</Typography>
				{superAdmin ?
					<>
						<Typography variant='h5'>
							{t('views.assignUsersToUserGroup.subheader.chooseOrganisation')}
						</Typography>
						<div className={classes.formGroup}>
							{!isEmptyObject(selectedOrganisation.value) ?
								<SelectedOrganisationCard
									handleClose={resetSelectedOrganisation}
									name={selectedOrganisation.value.name}
								/>
							:	<SearchAutocomplete
									dataList={organisationList}
									listType={'organisations'}
									onFetchData={onFetchOrganisations}
									placeholder={t(
										'views.assignUsersToUserGroup.placeholder.organisationSearchAutocomplete',
									)}
									setSelected={selectedOrganisation.onChange}
								/>
							}
						</div>
					</>
				:	null}
				<Typography variant='h5'>
					{t('views.assignUsersToUserGroup.subheader.chooseUserGroup')}
				</Typography>
				<div className={classes.formGroup}>
					{!isEmptyObject(selectedUserGroup.value) ?
						<SelectedUserGroupCard
							handleClose={resetSelectedUserGroup}
							name={selectedUserGroup.value.name}
						/>
					:	<SearchAutocomplete
							dataList={userGroupList}
							filter={userGroupFilter}
							listType={'organisations'}
							onFetchData={onFetchUserGroups}
							placeholder={t('views.assignUsersToUserGroup.placeholder.searchAutocomplete')}
							setSelected={selectedUserGroup.onChange}
						/>
					}
				</div>
				<Typography className={clsx(classes.subHeader, classes.subsequentHeader)} variant='h4'>
					{t('views.assignUsersToUserGroup.subheader.users')}
				</Typography>
				<Search
					className={classes.search}
					events={search.events}
					placeholder={t('views.assignItemsToUserGroup.search.placeholder')}
					value={search.value}
				/>
				<Box className={classes.userListContainer}>
					{usersListEmpty ?
						usersListEmpty
					:	<>
							<Typography variant='caption'>
								{selectedUsers.length} {t('views.assignUsersToUserGroup.userlist.selected')}
							</Typography>
							<List>
								{usersReady ?
									usersData.results.map((user) => (
										<ListItem className={classes.listItem} key={`user-${user.id}`}>
											<Box>
												{isFullArray(user.imagesReference) ?
													<Avatar src={user.imagesReference[0]} />
												:	<Avatar>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>}
											</Box>
											<Box className={classes.userDetails} flex={1}>
												<Typography variant='h5'>{user.fullName}</Typography>
												<Typography>{user.emailAddress}</Typography>
											</Box>
											{superAdmin ?
												<Box flex={1}>
													<Typography>{user.organisationReference.name}</Typography>
												</Box>
											:	null}
											<Box>
												<Checkbox
													checked={selectedUsers.map((user) => user.id).indexOf(user.id) !== -1}
													onChange={() => handleSelectOne(user)}
												/>
											</Box>
										</ListItem>
									))
								:	Array(6)
										.fill(Array(superAdmin ? 4 : 3).fill())
										.map((row, index) => (
											<ListItem
												className={clsx(classes.loadingListItem, classes.listItem)}
												key={`loading-item-${index}`}
											>
												{row.map((_, index) => (
													<LoadingBar key={`loading-bar-${index}`} />
												))}
											</ListItem>
										))
								}
							</List>
							{usersReady ?
								usersData.total / pageSize > 1 ?
									<div className={classes.paginationContainer}>
										<Paginate
											forcePage={pageNumber - 1}
											onPageChange={handlePageChange}
											pageCount={usersData.total / pageSize}
										/>
									</div>
								:	null
							:	null}
						</>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
		userGroupList: state.paged.userGroups,
		organisationList: state.paged.organisations,
		organisation: state.details.organisation,
		usersList: state.paged.users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.fetchUserGroups(page, filters, concat)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onFetchUsers: (page, filters) => dispatch(actions.fetchUsers(page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectUserGroup);
