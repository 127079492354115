import Header from './Header';
import InvoiceInfo from './InvoiceInfo';
import { useStyles } from './style';
import TermsAndConditions from './TermsAndConditions';
import Subscription from '../InvoiceDetails/Subscription';

interface OrganisationDetailsProps {
	save?(...args: unknown[]): unknown;
	invoiceName?: string;
	invoiceEmail?: string;
	chosenSubscription?: string;
	isTermsChecked?: boolean;
}

const OrganisationDetails = (props: OrganisationDetailsProps) => {
	const classes = useStyles();

	const { save, invoiceName, invoiceEmail, isTermsChecked, chosenSubscription } = props;

	const invoiceProps = { invoiceName, invoiceEmail };
	const subscriptionProps = { chosenSubscription };
	const termsProps = { isTermsChecked };

	return (
		<>
			<Header />
			<InvoiceInfo className={classes.wizardSection} save={save} {...invoiceProps} />
			<Subscription className={classes.wizardSection} save={save} {...subscriptionProps} />
			<TermsAndConditions className={classes.wizardSection} save={save} {...termsProps} />
		</>
	);
};

export default OrganisationDetails;
