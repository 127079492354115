import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Box, Link, Avatar } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { Table, SearchBar, EmptyState, ButtonWithAlertDialog } from '../../../components';
import { usePrevious } from '../../../shared/hooks';
import {
	isEmptyString,
	isFullString,
	isObject,
	isEmptyArray,
	getInitials,
	isFullArray,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

const getFilters = (requestsType, searchTerm = '') => ({
	...(!isEmptyString(searchTerm) && { searchTerm: searchTerm }),
	...(requestsType !== 'accepted' && { type: requestsType, status: 'pending' }),
	...(requestsType === 'accepted' && { status: requestsType }),
});

interface RequestsProps {
	onFetchPartnerships?(...args: unknown[]): unknown;
	requestsType?: string;
	onUpdatePartnershipStatus?(...args: unknown[]): unknown;
	fetchPartnerships?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updatedPartnership?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Requests = (props: RequestsProps) => {
	const {
		fetchPartnerships,
		onFetchPartnerships,
		requestsType,
		onUpdatePartnershipStatus,
		updatedPartnership,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		data: partnershipsData,
		loading: partnershipsLoading,
		error: partnershipsError,
	} = fetchPartnerships;
	const partnershipsReady =
		isObject(partnershipsData) && !partnershipsLoading && !partnershipsError;

	const { data: currentUserData } = currentUser;
	const isTelematics =
		currentUserData?.organisationReference.subscriptionModule.includes('telematics');

	const [updatingPartnershipStatus, setUpdatingPartnershipStatus] = useState(false);
	const [action, setAction] = useState();

	const {
		data: updatedPartnershipData,
		loading: updatedPartnershipLoading,
		error: updatedPartnershipError,
	} = updatedPartnership;
	const updatePartnerShipStatusDone =
		isObject(updatedPartnershipData) && !updatedPartnershipLoading && !updatedPartnershipError;

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [searchValue, setSearchValue] = useState('');
	const [hasSearch, setHasSearch] = useState('');
	const shouldReset = usePrevious(isFullString(searchValue)) && isEmptyString(searchValue);

	const [showingInitialResults] = useState(true);

	const page = { number: pageNumber, size: pageSize };

	useEffect(() => {
		if (!partnershipsLoading) {
			onFetchPartnerships(page, getFilters(requestsType, searchValue));
		}
	}, [requestsType, updatedPartnershipData]);

	useEffect(() => {
		if (!partnershipsLoading && shouldReset && hasSearch) {
			onFetchPartnerships(page, getFilters(requestsType));
		}
	}, [shouldReset]);

	useEffect(() => {
		if (
			!partnershipsLoading &&
			isObject(partnershipsData) &&
			(partnershipsData.pageNumber !== page.number || partnershipsData.pageSize !== page.size)
		) {
			onFetchPartnerships(page, getFilters(requestsType, searchValue));
		}
	}, [pageNumber, pageSize]);

	// Search
	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setHasSearch(true);
			onFetchPartnerships(page, getFilters(requestsType, searchValue));
		}
		setPageNumber(1);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const handleResetSearch = () => {
		if (hasSearch) {
			onFetchPartnerships(page, getFilters(requestsType));
		}
		setHasSearch(false);
		setSearchValue('');
	};

	const searchEvents = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	// Table
	const handlePageSizeChange = (newSize) => {
		setPageSize(newSize);
	};

	const handlePageChange = (number) => {
		setPageNumber(number);
	};

	const handleClickRequestAction = (id, state) => {
		setUpdatingPartnershipStatus(true);
		setAction(state);
		onUpdatePartnershipStatus(id, state);
	};

	// in case status has been successfully updated
	useEffect(() => {
		if (updatingPartnershipStatus && updatePartnerShipStatusDone) {
			setUpdatingPartnershipStatus(false);
			if (action === 'cancel') {
				enqueueSnackbar(
					`${t('views.partnerships.messages.success.cancel')} ${updatedPartnershipData.partnerOrganisation.name}`,
				);
			} else if (action === 'accept') {
				enqueueSnackbar(
					`${t('views.partnerships.messages.success.accept')} ${updatedPartnershipData.partnerOrganisation.name}`,
					{ variant: 'success' },
				);
			} else if (action === 'reject') {
				enqueueSnackbar(
					`${t('views.partnerships.messages.success.reject')} ${updatedPartnershipData.partnerOrganisation.name}`,
				);
			}
		}
	}, [updatePartnerShipStatusDone, updatingPartnershipStatus, action]);

	// in case there's an error with updating the status
	useEffect(() => {
		if (updatingPartnershipStatus && !updatedPartnershipLoading && updatedPartnershipError) {
			setUpdatingPartnershipStatus(false);
			enqueueSnackbar(
				isObject(updatedPartnershipError) ?
					updatedPartnershipError.message
				:	updatedPartnershipError,
				{ variant: 'error' },
			);
		}
	}, [updatedPartnershipError, updatingPartnershipStatus, updatedPartnershipLoading]);

	const extraButtons = [
		{
			text: t('view.partnershipmanagement.button.contained.addpartner'),
			onClick: () => navigate('/partnerships/partners/add'),
			variant: 'contained-primary',
			hover: false,
			tooltipTitle: t('telematics.message.notAvailable'),
			disabled: isTelematics,
		},
	];

	const tableHeader = [
		{ name: 'name', content: t('ui.label.name'), hasSorting: false },
		...(requestsType === 'accepted' ? [] : [{ name: '', content: '', hasSorting: false }]),
	];

	const loadingState = Array(4)
		.fill(Array(tableHeader.length).fill())
		.map((arr) => arr.map(() => ({ loading: true })));

	const tableBody =
		partnershipsReady ?
			partnershipsData.results.map((item) => [
				{
					content:
						item.partnerOrganisation.logo ?
							<Box alignItems='center' display='flex'>
								<Box pr={2}>
									<Avatar className={classes.avatar} src={item.partnerOrganisation.logo} />
								</Box>
								<Link
									className={classes.itemGroup}
									color='inherit'
									component={RouterLink}
									to={`/partnerships/partners/${item.partnerOrganisation.id}/summary`}
									state={{ from: location.pathname }}
								>
									{item.partnerOrganisation.name}
								</Link>
							</Box>
						:	<Box alignItems='center' display='flex'>
								<Box pr={2}>
									<Avatar>{getInitials(item.partnerOrganisation.name)}</Avatar>
								</Box>
								<Link
									className={classes.itemGroup}
									color='inherit'
									component={RouterLink}
									to={`/partnerships/partners/${item.partnerOrganisation.id}/summary`}
									state={{ from: location.pathname }}
								>
									{item.partnerOrganisation.name}
								</Link>
							</Box>,
					className: classes.iconCell,
				},
				...(requestsType === 'incoming' && item.status === 'pending' ?
					[
						{
							content: (
								<Box display='flex' flexDirection='row-reverse'>
									<Box pl={2.5}>
										<ButtonWithAlertDialog
											actionDone={updatePartnerShipStatusDone}
											callback={() =>
												handleClickRequestAction(item.partnerOrganisation.id, 'accept')
											}
											color='primary'
											dialogDescription={t('ui.dialog.partnership.accept.description')}
											dialogLoading={updatedPartnershipLoading && !updatePartnerShipStatusDone}
											dialogTitle={t('ui.dialog.partnership.accept.title')}
											startIcon={<CheckIcon />}
											variant='contained-primary'
										>
											{t('ui.accept')}
										</ButtonWithAlertDialog>
									</Box>
									<ButtonWithAlertDialog
										actionDone={updatePartnerShipStatusDone}
										callback={() => handleClickRequestAction(item.partnerOrganisation.id, 'reject')}
										color='secondary'
										dialogDescription={t('ui.dialog.partnership.decline.description')}
										dialogLoading={updatedPartnershipLoading && !updatePartnerShipStatusDone}
										dialogTitle={t('ui.dialog.partnership.decline.title')}
										startIcon={<CloseIcon />}
										variant='contained-tertiary'
									>
										{t('ui.decline')}
									</ButtonWithAlertDialog>
								</Box>
							),
							className: classes.incomingActionsCell,
						},
					]
				:	[]),
				...(requestsType === 'outgoing' && item.status === 'pending' ?
					[
						{
							content: (
								<Box display='flex' flexDirection='row-reverse'>
									<ButtonWithAlertDialog
										actionDone={updatePartnerShipStatusDone}
										callback={() => handleClickRequestAction(item.partnerOrganisation.id, 'cancel')}
										dialogDescription={t('views.partnerDetail.summary.cancelDialog.description')}
										dialogLoading={updatedPartnershipLoading && !updatePartnerShipStatusDone}
										dialogTitle={t('views.partnerDetail.summary.cancelDialog.title')}
										startIcon={<DeleteIcon />}
										variant='inline-delete'
									>
										{t('ui.button.inline.cancel')}
									</ButtonWithAlertDialog>
								</Box>
							),
							className: classes.outgoingActionsCell,
						},
					]
				:	[]),
			])
		:	loadingState;

	// Empty state
	const itemsListEmpty =
		partnershipsReady && isEmptyArray(partnershipsData.results) && !partnershipsLoading ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/partnerships/partners/add'}
					callToActionText={
						requestsType === 'accepted' || requestsType === 'outgoing' ?
							t('views.partnerships.button.addPartner')
						:	null
					}
					disabled={isTelematics}
					image={'booking'}
					subTitle={
						requestsType === 'accepted' ? t('views.Partnerships.Requests.noPartners')
						: requestsType === 'incoming' ?
							t('views.Partnerships.Requests.noRequests')
						: requestsType === 'outgoing' ?
							t('views.Partnerships.Requests.noOutgoingRequests')
						:	null
					}
					title={t('views.Partnerships.Requests.notFound')}
					tooltipTitle={t('telematics.message.notAvailable')}
				/>
			:	<EmptyState
					callToAction={'/partnerships/partners/add'}
					callToActionText={
						requestsType === 'accepted' || requestsType === 'outgoing' ?
							t('views.partnerships.button.addPartner')
						:	null
					}
					disabled={isTelematics}
					image={'booking'}
					subTitle={t('views.Partnerships.Requests.zeroMatching')}
					title={t('views.Partnerships.Requests.notFound')}
					tooltipTitle={t('telematics.message.notAvailable')}
				/>
		:	null;

	return (
		<>
			<SearchBar
				extraButtons={extraButtons}
				hasExtraButtons={isFullArray(extraButtons)}
				hasMainFilter={false}
				hideMoreFilters={false}
				placeholder={t('ui.search')}
				searchEvents={searchEvents}
				searchValue={searchValue}
			/>
			{!itemsListEmpty ?
				<Table
					body={tableBody}
					error={partnershipsError}
					handlePageChange={handlePageChange}
					header={tableHeader}
					loading={partnershipsLoading}
					page={pageNumber}
					rowsPerPage={pageSize}
					setRowsPerPage={handlePageSizeChange}
					title={
						requestsType === 'accepted' ? t('views.Partnerships.Requests.allPartners')
						: requestsType === 'incoming' ?
							t('views.Partnerships.Requests.incomingRequests')
						: requestsType === 'outgoing' ?
							t('views.Partnerships.Requests.outgoingRequests')
						:	null
					}
					total={isObject(partnershipsData) ? partnershipsData.total : 0}
				/>
			:	itemsListEmpty}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchPartnerships: state.paged.fetchPartnerships,

		updatedPartnership: state.details.updatedPartnership,
		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPartnerships: (page, filters) => dispatch(actions.fetchPartnerships(page, filters)),
		onUpdatePartnershipStatus: (partnerId, state) =>
			dispatch(actions.updatePartnershipStatus(partnerId, state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
