import { useState, useEffect } from 'react';

import { Box, TextField, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { AlertDialog, HeaderWithStatus } from '../../../../components';
import { useError } from '../../../../shared/hooks';
import { isObject, isUndefined } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

const PREFIX = 'Dialog';

const classes = {
	description: `${PREFIX}-description`,
	iconButton: `${PREFIX}-iconButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
	[`& .${classes.description}`]: {
		'& .MuiOutlinedInput-root': {
			borderRadius: '12px',
		},
	},

	[`& .${classes.iconButton}`]: {
		padding: '5px',
		marginRight: '10px',
	},
}));

export {};
const maxLength = 200;

interface DialogProps {
	data?: object;
	dialogOpen?: boolean;
	setDialogOpen?(...args: unknown[]): unknown;
	status?: string;
	setStatus?(...args: unknown[]): unknown;
	typeOfTickets?: string;
	onDashboardTickets?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onDashboardTicketsStatus?(...args: unknown[]): unknown;
	isDashboard?: boolean;
	organisationId?: number | string;
	updateTicketActivities?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onUpdateTicketActivities?(...args: unknown[]): unknown;
	onFetchTickets?(...args: unknown[]): unknown;
	filterItems?: object;
	page?: object;
}

const Dialog = (props: DialogProps) => {
	const {
		data,
		dialogOpen,
		setDialogOpen,
		status,
		setStatus,
		updateTicketActivities,
		onUpdateTicketActivities,

		onFetchTickets,
		filterItems,
		page,
		isDashboard,
		onDashboardTickets,
		onResetState,
		onDashboardTicketsStatus,
		organisationId,
	} = props;
	const { t } = useTranslation('general');

	const [comment, setComment] = useState('');
	const [commentLength, setCommentLength] = useState(0);
	const {
		data: updateTicketData,
		loading: updateTicketLoading,
		error: updateTicketError,
	} = updateTicketActivities;
	const updateTicketDone = isObject(updateTicketData) && !updateTicketLoading && !updateTicketError;

	const updateTicketMessage = useError({
		value: updateTicketActivities,
		message: `${t('ui.successfully')} ${t('ui.updated')} ${t('ui.ticket.label')} ${data && data.id}`,
	});

	const handleStatusChange = (event) => {
		const newValue = event.target.value;
		setStatus(newValue);
	};

	const handleDescription = (event) => {
		const newValue = event.target.value;
		setComment(newValue);
		setCommentLength(newValue.length);
	};

	const resetState = () => {
		setComment('');
		setCommentLength(0);
		setDialogOpen(false);
	};

	const handleCloseDealog = () => {
		setStatus(data.status);
		resetState();
	};

	const statusOfTicket = [
		{ label: 'open', name: t('ui.open') },
		{ label: 'pending', name: t('ui.status.pending') },
		{ label: 'planned', name: t('ui.status.planned') },
		{ label: 'ready', name: t('ui.status.ready') },
		{ label: 'closed', name: t('ui.closed') },
	];

	const handleConfirmDialog = () => {
		onUpdateTicketActivities(data.id, status, comment);
		updateTicketMessage.setStartAction(true);
	};

	useEffect(() => {
		if (isObject(updateTicketData) && isUndefined(isDashboard)) {
			onFetchTickets(page, filterItems);
			onResetState('updateTicketActivities');
			resetState();
		}
	}, [updateTicketData]);

	useEffect(() => {
		if (isObject(updateTicketData) && isDashboard) {
			onDashboardTickets(page, filterItems);
			onResetState('updateTicketActivities');
			resetState();
			onDashboardTicketsStatus(organisationId);
		}
	}, [updateTicketData]);

	return (
		<Root>
			{dialogOpen ?
				<AlertDialog
					dialogContent={
						<Box>
							<Box pb={2}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									SelectProps={{ value: status || '' }}
									fullWidth={true}
									label={t('ui.label.status')}
									onChange={handleStatusChange}
									select
									size='medium'
									variant='outlined'
								>
									{statusOfTicket.map((tStatus) => (
										<MenuItem key={tStatus.label} value={tStatus.label}>
											{tStatus.name}
										</MenuItem>
									))}
								</TextField>
							</Box>
							<Box display='flex' flexDirection='column'>
								<Box pb={1.8}>
									<HeaderWithStatus
										header={`${t('views.planboard.addBooking.comment')}`}
										headerSize='h6'
										justifyContent='space-between'
										text={`${commentLength}/${maxLength}`}
										variantText='body2'
									/>
								</Box>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									// label={t('views.planboard.addBooking.comment')}
									className={classes.description}
									fullWidth
									inputProps={{ maxLength: maxLength }}
									maxRows={3}
									multiline
									onChange={handleDescription}
									rows={3}
									value={comment}
									variant='outlined'
								/>
							</Box>
						</Box>
					}
					dialogTitle={t('ui.ticketStatus.title')}
					handleClose={handleCloseDealog}
					handleConfirm={handleConfirmDialog}
					loading={updateTicketLoading && !updateTicketDone}
					open={dialogOpen}
				/>
			:	null}
		</Root>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchTickets: state.paged.fetchTickets,
		updateTicketActivities: state.details.updateTicketActivities,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateTicketActivities: (ticketId, status, comment) =>
			dispatch(actions.updateTicketActivities(ticketId, status, comment)),
		onFetchTickets: (page, filters) => dispatch(actions.fetchTickets(page, filters)),
		onDashboardTickets: (page, filters, concat) =>
			dispatch(actions.dashboardTickets(page, filters, concat)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onDashboardTicketsStatus: (organisationId) =>
			dispatch(actions.dashboardTicketsStatus(organisationId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
