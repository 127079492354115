import { TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import MaxLengthCountDown from './MaxLengthCountDown';
import { isFullString, isUndefined, isEmptyObject, isInteger } from '../../../shared/utility';

interface FormFieldProps {
	className?: string;
	name?: string;
	label?: string;
	variable?: object;
	maxLength?: number;
	hideCharacterCounter?: boolean;
	isExtraValid?: boolean;
	extraValidHelperText?: string;
	required?: boolean;
	startIcon?: boolean;
}

const FormField = (props: FormFieldProps) => {
	const {
		className,
		label,
		name,
		variable,
		startIcon,
		maxLength,
		hideCharacterCounter,
		isExtraValid,
		extraValidHelperText,
		required,

		...rest
	} = props;
	const { t } = useTranslation('general');
	const lengthValue = isNaN(variable.value) ? 0 : Math.ceil(Math.log10(variable.value + 1));

	const lengthProperties =
		maxLength ?
			{
				inputProps: {
					maxLength,
				},
				InputProps: {
					...(!hideCharacterCounter && {
						endAdornment: (
							<InputAdornment position='end'>
								<MaxLengthCountDown
									length={
										variable?.value?.length && isInteger(variable.value.length) ?
											variable.value.length
										:	lengthValue
									}
									maxLength={maxLength}
								/>
							</InputAdornment>
						),
					}),
					...(startIcon && {
						startAdornment: <InputAdornment position='start'>{'€'}</InputAdornment>,
					}),
				},
			}
		:	{};

	return (
		<TextField
			autoFocus={false}
			className={className}
			error={
				variable.isTouched &&
				!variable.hasFocus &&
				((!variable.isValid && !isEmptyObject(variable.validationError)) ||
					(!isUndefined(isExtraValid) && !isExtraValid))
			}
			fullWidth
			helperText={
				(
					variable.isTouched &&
					!variable.hasFocus &&
					((!variable.isValid && !isEmptyObject(variable.validationError)) ||
						(!isUndefined(isExtraValid) && !isExtraValid))
				) ?
					(
						isFullString(variable.value) &&
						!isUndefined(isExtraValid) &&
						!isExtraValid &&
						isEmptyObject(variable.validationError)
					) ?
						extraValidHelperText
					:	t(`ui.error.message.${variable.validationError.key}`)
				:	null
			}
			label={label}
			name={name}
			required={required}
			variant='outlined'
			{...variable.bindToFormField}
			InputLabelProps={{
				shrink: true,
			}}
			{...lengthProperties}
			FormHelperTextProps={{
				style: {
					position: 'absolute',
					top: '100%',
					marginLeft: 0,
				},
			}}
			{...rest}
		/>
	);
};

export default FormField;
