import { Card, CardContent, Divider, CardHeader, Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { isFullString } from '../../../../../../shared/utility';

interface InternalNoteProps {
	className?: string;
	fetchUnavailabilityData?: object;
}

const InternalNote = (props: InternalNoteProps) => {
	const { className, fetchUnavailabilityData } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={`${t('views.bookingDetail.userNote.header')}`} />
			<Divider />
			<CardContent className={classes.content}>
				<Box>
					<Typography>
						{isFullString(fetchUnavailabilityData.adminNote) ?
							fetchUnavailabilityData.adminNote
						:	t('views.bookingDetail.summary.userComment.empty')}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default InternalNote;
