import { useState, useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Avatar, Box, Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import {
	ButtonWithAlertDialog,
	InfoDialog,
	AlertDialog,
	SubscriptionSelectField,
	FormField,
	StyledButton,
} from '../../../../../../components';
import { useError, useWizardFormField } from '../../../../../../shared/hooks';
import { isObject, getInitials, isEmptyString } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface OtherActionsProps {
	className?: string;
	onDeleteOrganisation?(...args: unknown[]): unknown;
	organisation?: object;
	deleteOrganisation?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object | boolean;
		loading?: boolean;
		error?: object | string;
	};
	verifyOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	rejectOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	patchedOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onVerifyOrganisation?(...args: unknown[]): unknown;
	onRejectOrganisation?(...args: unknown[]): unknown;
	onPatchUpdateOrganisation?(...args: unknown[]): unknown;
}

const OtherActions = (props: OtherActionsProps) => {
	const {
		className,

		onDeleteOrganisation,
		deleteOrganisation,
		organisation,
		currentUser,
		verifyOrganisation,
		rejectOrganisation,
		onVerifyOrganisation,
		onRejectOrganisation,
		onPatchUpdateOrganisation,
		patchedOrganisation,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();
	const navigate = useNavigate();

	const role = auth.user?.profile.role.toLowerCase();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [openDialog, setOpenDialog] = useState(false);

	const [button, setButton] = useState(null);

	const subscription = useWizardFormField('', { required: true });
	const rejectionReason = useWizardFormField('', { required: true });

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deleteOrganisation;
	const deleteOrganisationDone = deleteSuccess && !deleteLoading && !deleteError;

	const {
		data: verifyOrganisationData,
		loading: verifyOrganisationLoading,
		error: verifyOrganisationError,
	} = verifyOrganisation;
	const verifyOrganisationDone =
		isObject(verifyOrganisationData) && !verifyOrganisationLoading && !verifyOrganisationError;
	const [verifyingOrganisation, setVerifyingOrganisation] = useState(false);

	const {
		data: rejectOrganisationData,
		loading: rejectOrganisationLoading,
		error: rejectOrganisationError,
	} = rejectOrganisation;
	const rejectOrganisationDone =
		isObject(rejectOrganisationData) && !rejectOrganisationLoading && !rejectOrganisationError;
	const [rejectingOrganisation, setRejectingOrganisation] = useState(false);

	const [patchingOrganisation, setPatchingOrganisation] = useState(false);
	const {
		data: patchedOrgData,
		loading: patchedOrgLoading,
		error: patchedOrgError,
	} = patchedOrganisation;
	const patchOrganisationDone = isObject(patchedOrgData) && !patchedOrgLoading && !patchedOrgError;

	const {
		data: currentUserData,
		loading: currentUserLoading,
		error: currentUserError,
	} = currentUser;
	const currentUserReady = isObject(currentUserData) && !currentUserLoading && !currentUserError;

	const [openInfoDialog, setOpenInfoDialog] = useState(false);

	const deleteOrganisationMessage = useError(
		isObject(deleteOrganisation) ?
			{
				value: deleteOrganisation,
				message: `${t('ui.successfullyDeleted')} ${organisation.name}`,
			}
		:	null,
	);

	useEffect(() => {
		if (patchingOrganisation && !patchedOrgLoading && !!patchedOrgError) {
			setPatchingOrganisation(false);
			enqueueSnackbar(isObject(patchedOrgError) ? patchedOrgError.message : patchedOrgError, {
				variant: 'error',
			});
		}
	}, [patchedOrgError, patchingOrganisation, patchedOrgLoading]);

	const handleCloseInfoDialog = () => {
		setOpenInfoDialog(false);
	};

	const handleClickDelete = (handleOpen) => {
		if (organisation.totalUsers) {
			setOpenInfoDialog(true);
		} else {
			handleOpen();
		}
	};

	const handleDeleteAccount = () => {
		onDeleteOrganisation(organisation.id);
		deleteOrganisationMessage.setStartAction(true);
	};
	const handleAccept = (accept) => {
		setButton(accept);
		if (organisation.subscription) {
			subscription.onChange(`${organisation.subscription.id}`);
		}
		setOpenDialog(true);
	};

	const handleDecline = (decline) => {
		setButton(decline);
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		if (button === 'accept') {
			if (
				!(organisation.subscription && `${organisation.subscription.id}` === subscription.value)
			) {
				onPatchUpdateOrganisation(organisation.id, { subscriptionId: subscription.value });
				setPatchingOrganisation(true);
			} else {
				onVerifyOrganisation(organisation.id);
				setVerifyingOrganisation(true);
			}
		}
		if (button === 'decline') {
			setRejectingOrganisation(true);
			onRejectOrganisation(organisation.id, rejectionReason.value);
		}
	};

	// verify organisation if patching organisation and if this is done.
	useEffect(() => {
		if (patchingOrganisation && patchOrganisationDone) {
			setPatchingOrganisation(false);
			onVerifyOrganisation(organisation.id);
			setVerifyingOrganisation(true);
		}
	}, [patchingOrganisation, patchOrganisationDone]);

	/* * * * * * * * *
	 * NOTIFICATIONS *
	 * * * * * * * * */
	useEffect(() => {
		if (verifyingOrganisation && verifyOrganisationDone) {
			setVerifyingOrganisation(false);
			enqueueSnackbar(
				`${t('views.organisations.messages.success.accept')} ${verifyOrganisationData.name}`,
				{ variant: 'success' },
			);
			window.location.reload();
		}
	}, [verifyOrganisationDone, verifyingOrganisation]);

	useEffect(() => {
		if (verifyingOrganisation && !!verifyOrganisationError) {
			setVerifyingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(
				isObject(verifyOrganisationError) ?
					verifyOrganisationError.message
				:	verifyOrganisationError,
				{ variant: 'error' },
			);
		}
	}, [verifyOrganisationError, verifyingOrganisation]);

	useEffect(() => {
		if (rejectingOrganisation && rejectOrganisationDone) {
			setRejectingOrganisation(false);
			enqueueSnackbar(
				`${t('views.organisations.messages.success.decline')} ${rejectOrganisationData.name}`,
			);
			navigate('/organisation-management/incoming-requests');
		}
	}, [rejectOrganisationDone, rejectingOrganisation]);

	useEffect(() => {
		if (rejectingOrganisation && !!rejectOrganisationError) {
			setRejectingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(
				isObject(rejectOrganisationError) ?
					rejectOrganisationError.message
				:	rejectOrganisationError,
				{ variant: 'error' },
			);
		}
	}, [rejectOrganisationError, rejectingOrganisation]);

	const alertDialogTitle =
		button === 'accept' ? t('views.organisations.dialog.acceptOrganisation.title')
		: button === 'decline' ? t('views.organisations.dialog.declineOrganisation.title')
		: '';
	const alertDialogContent =
		button === 'accept' ?
			<Box>
				<Typography variant='body2'>
					{t('views.organisations.dialog.acceptOrganisation.description')}
				</Typography>
				<Box alignItems='center' className={classes.organisationContainer} display='flex'>
					{organisation.logo ?
						<Avatar className={classes.avatar} src={organisation.logo} />
					:	<Avatar>{getInitials(organisation.name)}</Avatar>}
					<Typography className={classes.alertOrganisationName}>{organisation.name}</Typography>
				</Box>
				<Typography className={classes.subtitle} variant='h4'>
					{t('ui.label.subscription')}
				</Typography>
				<SubscriptionSelectField className={classes.subscriptionSelect} variable={subscription} />
			</Box>
		:	<Box className={classes.rejectionAlertContent}>
				<Box display='flex' justifyContent='space-between' pb={1}>
					<Typography variant='h5'>{t('ui.label.comment')}*</Typography>
					<Typography className={classes.counterText}>
						{rejectionReason.value.length}/200 {t('ui.characters')}
					</Typography>
				</Box>
				<FormField
					multiline
					name='rejectionReason'
					placeholder={t('ui.placeholder.description')}
					rows={5}
					variable={rejectionReason}
				/>
			</Box>;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.otherActions')} />
			<Divider />
			{organisation.isVerified ?
				<>
					<CardContent>
						{currentUserReady && role === 'superadmin' ?
							<>
								<Typography className={classes.notice} variant='body2'>
									{t('views.organisationDetail.summary.deleteWarning')}
								</Typography>
								<ButtonWithAlertDialog
									actionDone={deleteOrganisationDone}
									callback={handleDeleteAccount}
									className={classes.deleteButton}
									dialogDescription={`${t('ui.deleted.dialogDescription')} ${organisation.name} ${t('ui.permanently')}`}
									dialogLoading={deleteLoading && !deleteOrganisationDone}
									dialogTitle={t('views.organisationDetail.summary.deleteDialog.title')}
									onClick={handleClickDelete}
									startIcon={<DeleteIcon />}
									variant='inline-delete'
								>
									{t('view.organisationmanagement.organisationDetails.button.inline.delete')}
								</ButtonWithAlertDialog>
								<InfoDialog
									dialogTitle={t(
										'views.usersAndOrganisations.organisations.deleteNotPossibleInfo.title',
									)}
									onClose={handleCloseInfoDialog}
									open={openInfoDialog}
								>
									<Typography>
										{t(
											'views.usersAndOrganisations.organisations.deleteNotPossibleInfo.description',
										)}
									</Typography>
								</InfoDialog>
							</>
						:	null}
					</CardContent>
				</>
			:	<>
					<div className={classes.contact}>
						<Typography gutterBottom={true} variant='h6'>
							{`Connect with: ${organisation.name}`}{' '}
						</Typography>
						<Box display='flex'>
							<Box pr={2.5}>
								<StyledButton
									onClick={() => handleDecline('decline')}
									size='small'
									startIcon={<CloseIcon />}
									variant='contained-tertiary'
								>
									{t('ui.button.contained.decline')}
								</StyledButton>
							</Box>
							<StyledButton
								color='primary'
								onClick={() => handleAccept('accept')}
								size='small'
								startIcon={<CheckIcon />}
								variant='contained-secondary'
							>
								{t('ui.button.contained.accept')}
							</StyledButton>
						</Box>
						<AlertDialog
							confirmDisabled={
								!isEmptyString(button) &&
								(button === 'accept' ?
									isEmptyString(subscription.value)
								:	isEmptyString(rejectionReason.value))
							}
							dialogContent={alertDialogContent}
							dialogTitle={alertDialogTitle}
							handleClose={handleCloseDialog}
							handleConfirm={handleConfirmDialog}
							loading={patchingOrganisation || verifyingOrganisation || rejectingOrganisation}
							open={openDialog}
							titleVariant='h3'
						/>
					</div>
				</>
			}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteOrganisation: state.condition.deleteOrganisation,
		currentUser: state.details.currentUser,
		verifyOrganisation: state.details.verifyOrganisation,
		rejectOrganisation: state.details.rejectOrganisation,
		patchedOrganisation: state.details.patchedOrganisation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteOrganisation: (id) => dispatch(actions.deleteOrganisation(id)),
		onVerifyOrganisation: (id) => dispatch(actions.verifyOrganisation(id)),
		onRejectOrganisation: (id, reason) => dispatch(actions.rejectOrganisation(id, reason)),
		onPatchUpdateOrganisation: (id, properties) =>
			dispatch(actions.patchUpdateOrganisation(id, properties)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherActions);
