import React from 'react';

import { styled, useTheme } from '@mui/material';
import { LegendRendererProps } from '@mui/x-charts';
import { useSeries } from '@mui/x-charts/hooks/useSeries';

import { getRenderedTextSize } from '~utils';

const StyledText = styled('text')(({ theme }) => ({
	fill: theme.palette.text.primary,
	textAnchor: 'start',
	dominantBaseline: 'middle',
	fontFamily: theme.typography.fontFamily,
	fontSize: theme.typography.body1.fontSize,
}));

type ChartsLegendWithValuesProps = {
	values: number[];
	type: 'line' | 'bar';
} & LegendRendererProps;

const ChartsLegendWithValues = ({
	seriesToDisplay,
	drawingArea,
	values,
	type = 'line',
	...props
}: ChartsLegendWithValuesProps) => {
	const theme = useTheme();
	const series = useSeries();

	const radius = 4;
	let xStart = radius;
	const yStart = 10;

	return (
		<g>
			{seriesToDisplay.map((el, i) => {
				const size = getRenderedTextSize(
					el.label,
					theme.typography.body1.fontSize,
					theme.typography.fontFamily,
				);
				const serie = el.seriesId ? series[type]?.series[el.seriesId] : null;
				const value = values != null ? values[i] : null;

				const svgElement = (
					<React.Fragment key={el.seriesId}>
						<circle
							r={radius}
							cx={xStart}
							// The - 1 is just added based on visuals. If we subtract the one, it seems more
							// bottom aligned
							cy={yStart - 1}
							fill={el.color}
							alignmentBaseline='middle'
						/>
						<StyledText x={xStart + radius * 2} y={yStart} alignmentBaseline='middle'>
							{el.label}
						</StyledText>
						<StyledText
							x={xStart - radius}
							y={10 + size.height + 15}
							sx={{
								fontSize: theme.typography.h3.fontSize,
							}}
						>
							{value == null ?
								null
							: serie?.valueFormatter != null ?
								serie.valueFormatter?.(values[i])
							:	values[i]}
						</StyledText>
					</React.Fragment>
				);

				xStart += size.width + 40 + radius * 2;

				return svgElement;
			})}
		</g>
	);
};

export default ChartsLegendWithValues;
