import { List } from '@mui/material';
import clsx from 'clsx';

import { isUndefined } from '../../../../shared/utility';
import { useStyles } from '../style';

interface InputListProps {
	total?: number;
	children?: React.ReactNode;
}

const InputList = ({ total, children = null }: InputListProps) => {
	const classes = useStyles();

	return (
		<List
			className={clsx({
				[classes.selectResults]: true,
				[classes.smallPopper]: total < 5 || isUndefined(total),
				[classes.mediumPopper]: 5 < total < 20,
				[classes.largePopper]: total > 20,
			})}
		>
			{children}
		</List>
	);
};

export default InputList;
