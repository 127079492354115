import { forwardRef, useState, useEffect } from 'react';

import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { TextField, Typography, InputAdornment } from '@mui/material';
import { enGB, nl } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

import 'react-datepicker/dist/react-datepicker.css';

interface ReactDatePickerProps {
	containerClassName?: string;
	error?: boolean;
	variant?: 'input' | 'text';
	label?: string;
	disabled?: boolean;
}

const ReactDatePicker = ({
	containerClassName,
	error,
	label,
	disabled,
	dateFormat = 'd MMMM, H:mm',
	timeFormat = 'HH:mm',
	timeIntervals = 15,
	variant = 'input',
	...rest
}: ReactDatePickerProps) => {
	const { i18n, t } = useTranslation('general');
	const classes = useStyles();
	const [showErrorState, setShowErrorState] = useState(false);

	registerLocale('nl', nl);
	registerLocale('en', enGB);

	useEffect(() => {
		if (disabled) {
			setShowErrorState(false);
		} else {
			setShowErrorState(error);
		}
	}, [error, disabled]);

	const CustomTextInput = forwardRef(
		(
			{ value, onClick },
			ref, //eslint-disable-line
		) => (
			<Typography className={classes.date} component='p' onClick={onClick} ref={ref} variant='h5'>
				{value}
				<ArrowDownIcon />
			</Typography>
		),
	);

	return (
		<div className={containerClassName}>
			<DatePicker
				{...rest}
				dateFormat={dateFormat}
				timeFormat={timeFormat}
				timeIntervals={timeIntervals}
				calendarStartDay={1}
				customInput={
					variant === 'input' ?
						<TextField
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarTodayIcon />
									</InputAdornment>
								),
							}}
							error={showErrorState}
							fullWidth
							label={label}
							variant='outlined'
						/>
					:	<CustomTextInput />
				}
				disabled={disabled}
				// https://github.com/Hacker0x01/react-datepicker/issues/1871
				locale={i18n.language === 'en-US' ? 'en' : 'nl'}
				popperClassName={classes.popper}
				popperModifiers={[
					{
						name: 'preventOverflow',
						options: {
							enabled: true,
							escapeWithReference: false,
							boundariesElement: 'viewport',
						},
					},
				]}
				timeCaption={t('ui.time')}
			/>
		</div>
	);
};

export default ReactDatePicker;
