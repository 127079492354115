import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
	TableRow,
	TableCell,
	Collapse,
	Checkbox,
	TableBody,
	Table,
	IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { isUndefined, isEmptyArray, isFullArray } from '../../../shared/utility';
import { LoadingBar } from '../../loading';

const useRowStyles = makeStyles({
	root: {},
	internalRowCheckbox: {
		paddingLeft: '0',
		borderBottom: 'none',
		width: 100,
	},
	noneBorder: {
		borderBottom: 'none',
	},
	quantityCell: {
		borderBottom: 'none',
		minWidth: 74,
		maxWidth: 74,
	},
	nameCell: {
		borderBottom: 'none',
		// width: 200
		// whiteSpace: 'nowrap'
	},
	expanded: {
		// width: 200
		// whiteSpace: 'nowrap'
	},
	collapseCell: {
		width: 100,
		padding: '0',
	},
	checkboxCell: {
		// width: '50px',
	},
	checkboxBorder: {
		borderBottom: 'none',
		width: 74,
	},
	collapsData: {
		width: 74,
	},
	fullWidth: {
		width: '100%',
	},
	fullWidthBorder: {
		width: '100%',
		borderBottom: 'none',
	},
	noneBorderCell: {
		borderBottom: 'none',
		minWidth: 110,
		paddingLeft: 0,
	},
});

interface RowProps {
	open?: boolean;
	handleOpenTable?(...args: unknown[]): unknown;
	row?: any;
	item?: object;
	selectRow?: boolean;
	internalRowSelect?: boolean;
	handleSelectInternalRow?(...args: unknown[]): unknown;
	collapsData?: any;
	handleChecked?(...args: unknown[]): unknown;
	checkedValue?: any;
	handleExpanded?(...args: unknown[]): unknown;
	expandedValue?: any;
	isSelectable?: boolean;
	isExpandable?: boolean;
	page: number;
	rowsPerPage: number;
}

const Row = (props: RowProps) => {
	const {
		row,
		item,
		collapsData,
		handleExpanded,
		expandedValue,
		handleChecked,
		isSelectable,
		isExpandable,
	} = props;

	const classes = useRowStyles();

	const handleClasses = (value) => {
		if (value === 0) {
			return classes.quantityCell;
		} else if (value === 1) {
			return classes.nameCell;
		} else {
			return classes.noneBorder;
		}
	};

	const handleClassesExpanded = (value) => {
		if (value === 1 || value === 2) {
			return classes.expanded;
		}
	};

	return (
		<>
			<TableRow className={classes.root}>
				{isSelectable ?
					<TableCell align='right' className={classes.internalRowCheckbox}>
						{!row ?
							<LoadingBar />
						:	<>
								{isExpandable ?
									<IconButton
										aria-label='expand row'
										onClick={handleExpanded}
										size='small'
										value={expandedValue}
									>
										{expandedValue ?
											<ArrowDropUpIcon />
										:	<ArrowDropDownIcon />}
									</IconButton>
								:	null}
								<Checkbox
									checked={
										(item.instances.length === item.selectedInstanceIds.length &&
											!isEmptyArray(item.selectedInstanceIds)) ||
										item.hasSelectAllSelected
									}
									color='primary'
									indeterminate={
										!isEmptyArray(item.selectedInstanceIds) &&
										(item.selectedInstanceIds.length < item.instances.length ||
											(item.selectedInstanceIds.length > 0 &&
												!(
													item.hasSelectAllSelected ||
													item.selectedInstanceIds.length === item.totalItemInstances
												)))
									}
									onChange={(e) => handleChecked(null, e.target.checked)}
								/>
							</>
						}
					</TableCell>
				:	null}
				{row.map((cell, index) => (
					<TableCell
						align={cell.align}
						className={expandedValue ? handleClassesExpanded(index) : handleClasses(index)}
						key={index}
					>
						{cell.loading ?
							<LoadingBar />
						: !isUndefined(cell.content) ?
							cell.content ?
								cell.content
							:	<p>-</p>
						:	null}
					</TableCell>
				))}
			</TableRow>
			<TableRow>
				<TableCell
					className={classes.collapseCell}
					colSpan={8}
					style={{ paddingBottom: 0, paddingTop: 0 }}
				>
					<Collapse in={expandedValue} mountOnEnter>
						<Table aria-label='instances' size='small'>
							<TableBody>
								{isFullArray(collapsData) ?
									collapsData.map((collapsRow, rIndex) => (
										<TableRow key={rIndex}>
											<TableCell className={classes.noneBorderCell}></TableCell>
											<TableCell
												align='left'
												className={
													rIndex === collapsData.length - 1 ?
														classes.checkboxBorder
													:	classes.collapsData
												}
											>
												{collapsRow.loading ?
													<LoadingBar />
												:	<Checkbox
														checked={
															isEmptyArray(item.selectedInstanceIds) ?
																item.hasSelectAllSelected
															:	item.selectedInstanceIds.indexOf(collapsRow.instance.id) !== -1
														}
														color='primary'
														onChange={(e) => handleChecked(collapsRow.instance, e.target.checked)}
													/>
												}
											</TableCell>
											<TableCell
												align={collapsRow.align}
												className={
													rIndex === collapsData.length - 1 ?
														classes.fullWidthBorder
													:	classes.fullWidth
												}
											>
												{collapsRow.loading ?
													<LoadingBar />
												:	collapsRow.content}
											</TableCell>
											<TableCell
												align={'right'}
												className={rIndex === collapsData.length - 1 ? classes.noneBorder : ''}
											>
												{collapsRow.loading ?
													<LoadingBar />
												:	collapsRow.button}
											</TableCell>
										</TableRow>
									))
								:	null}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default Row;
