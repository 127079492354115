import { forwardRef } from 'react';

import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import { styles } from './style';

interface AdjustedSkeletonProps {
	animation?: 'pulse' | 'wave' | false;
	classes: object;
	className?: string;
	component?: React.ElementType;
	height?: number | string;
	variant?: 'text' | 'rect' | 'circle';
	width?: number | string;
}

const AdjustedSkeleton = forwardRef<HTMLElement, AdjustedSkeletonProps>(
	function AdjustedSkeleton(props, ref) {
		const {
			animation = 'pulse',
			classes,
			className,
			component: Component = 'span',
			height,
			variant = 'text',
			width,
			...other
		} = props;

		return (
			<Component
				className={clsx(
					classes.root,
					classes[variant],
					{
						[classes[animation]]: animation !== false,
					},
					className,
				)}
				ref={ref}
				{...other}
				style={{
					width,
					height,
					...other.style,
				}}
			/>
		);
	},
);

export default withStyles(styles)(AdjustedSkeleton);
