import { Typography } from '@mui/material';

interface CustomTypographyProps {
	children?: any;
}

const CustomTypography = (props: CustomTypographyProps) => {
	const { children, ...rest } = props;

	return <Typography {...rest}>{children}</Typography>;
};

export default CustomTypography;
