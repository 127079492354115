import { Card, CardHeader, CardContent } from '@mui/material';

import { useStyles } from './style';

interface SidePanelProps {
	title?: string | object;
	children?: React.ReactNode;
	// GLOBAL PROPS

	className?: string;
}

const SidePanel = (props: SidePanelProps) => {
	const { title, children, className } = props;
	const classes = useStyles();

	return (
		<Card className={className}>
			<CardHeader className={classes.cardHeader} title={title} />
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default SidePanel;
