import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface LinkUnlinkButtonProps {
	link?: boolean;
	onClick?(...args: unknown[]): unknown;
	data?: object;
}

const LinkUnlinkButton = ({ link, onClick = () => null, data }: LinkUnlinkButtonProps) => {
	return (
		<IconButton onClick={() => onClick(data)} size='small'>
			{link ?
				<LinkOffIcon />
			:	<LinkIcon />}
		</IconButton>
	);
};

export default LinkUnlinkButton;
