import { useEffect, useState } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	FormControl,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { Label, ActionDialog, SelectWithLazyLoading } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { usePoliciesChange, useItemSharings } from '../itemHooks';
import PoliciesToggle from '../PoliciesToggle';
import { useStyles } from '../style';

interface AssignPoliciesProps {
	className?: string;
	isOwnItem?: boolean;
	itemData?: object;
	onResetState?(...args: unknown[]): unknown;
	onItemPatch?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	onFetchPolicies?(...args: unknown[]): unknown;
	policiesList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AssignPolicies = (props: AssignPoliciesProps) => {
	const {
		className,
		isOwnItem,
		itemPatch,
		itemData,
		policiesList,
		onFetchPolicies,
		onItemPatch,
		onUpdateDetailsState,

		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();

	const { data: patchData, loading: patchLoading, error: patchError } = itemPatch;
	const patchReady = isObject(patchData) && !patchLoading && !patchError;

	const policies = usePoliciesChange({ id: itemData.policies.id, name: itemData.policies.name });

	const sharings = useItemSharings({ fetch: policies.openDialog, type: 'policy', id: itemData.id });

	const [updatedPolicies, setUpdatedPolicies] = useState(false);
	const [updateConfig, setUpdateConfig] = useState(false);
	const [override, setOverride] = useState(false);

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: itemData.hubReference.organisationReference.id,
	});

	useEffect(() => {
		if (isObject(patchData) && updatedPolicies) {
			onUpdateDetailsState('itemDetails', patchData);
			enqueueSnackbar(
				`${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
				{ variant: 'success' },
			);
			onResetState('itemPatch');
			policies.setOpenDialog(false);
			setUpdatedPolicies(false);
			setUpdateConfig(false);
		}
	}, [patchData]);

	const handleConfirmDialog = () => {
		const patchItemBody = {
			policyId: policies.initialValue.id,
			...(updateConfig && { updateConfigType: override ? 'all' : 'equal' }),
		};
		onItemPatch(itemData.id, patchItemBody);
		setUpdatedPolicies(true);
	};
	// all
	const handleToggle = (evnet) => {
		const val = evnet.target.checked;
		setUpdateConfig(() => val);
		if (!val) {
			setOverride(() => false);
		}
	};

	const handleOverrideAll = (event) => {
		setOverride(() => event.target.checked);
	};

	const selectProps = {
		dataList: policiesList,
		filter: organisationIdFilter,
		onFetchData: onFetchPolicies,
		placeholder: t('ui.placeholders.search.policy'),
		setSelected: policies.change,
		value: policies.initialValue.name,
	};

	const loading = patchLoading && !patchReady;

	const actionDialogProps = {
		noDivider: true,
		contentStyles: classes.actionDialog,
		loading: loading,
		handleClose: policies.cancelDialog,
		open: policies.openDialog,
		title: t('views.itemDetail.summary.alertDialog.updatePolicy.title'),
		actionButtonProps: {
			action: handleConfirmDialog,
			text: loading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
		},
	};

	const policiesToggleProps = {
		onClick: handleToggle,
		override,
		onChange: handleOverrideAll,
		overrideAll: updateConfig,
		sharings,
		type: 'policy',
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={t('views.itemDetail.summary.section.policy.title')} />
			<Divider />
			<CardContent>
				<Typography pb={2} variant='h6'>
					{t('views.itemDetail.summary.subtitle.assignedPolicy')}
				</Typography>
				<FormControl fullWidth variant='standard'>
					{isOwnItem || isSuperAdmin() ?
						<SelectWithLazyLoading {...selectProps} />
					:	null}
				</FormControl>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.confirmation')} </TableCell>
							<TableCell>
								<Label type={itemData.policies.requireHostConfirmation ? 'success' : 'error'}>
									{itemData.policies.requireHostConfirmation ?
										t('ui.required')
									:	t('ui.notRequired')}
								</Label>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.extend')} </TableCell>
							<TableCell>
								<Label type={itemData.policies.allowExtending ? 'success' : 'error'}>
									{itemData.policies.allowExtending ? t('ui.allowed') : t('ui.notAllowed')}
								</Label>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{policies.openDialog ?
				<ActionDialog {...actionDialogProps}>
					<PoliciesToggle {...policiesToggleProps} />
				</ActionDialog>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		itemPatch: state.details.itemPatch,
		policiesList: state.paged.policies,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPolicies: (page, filters, concat) =>
			dispatch(actions.fetchPolicies(page, filters, concat)),
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignPolicies);
