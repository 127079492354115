import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { isObject } from '../../../../../../shared/utility';

interface OrganisationInfoProps {
	className?: string;
	partner: object;
}

const OrganisationInfo = (props: OrganisationInfoProps) => {
	const { partner, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const administration =
		partner.partnerOrganisation.administration ? partner.partnerOrganisation.administration : {};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.partnerDetail.summary.cardHeaders.administration')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.vatNumber')}</TableCell>
							<TableCell>{administration.vatNumber ? administration.vatNumber : '-'}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.cocNumber')}</TableCell>
							<TableCell>
								{administration.chamberOfCommerceNumber ?
									administration.chamberOfCommerceNumber
								:	'-'}
							</TableCell>
						</TableRow>
						{isObject(partner) && partner.status === 'accepted' ?
							<TableRow>
								<TableCell>{t('ui.label.ibanNumber')}</TableCell>
								<TableCell>{administration.ibanNumber ? administration.ibanNumber : '-'}</TableCell>
							</TableRow>
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default OrganisationInfo;
