import { useState, useEffect, useReducer } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider, Typography, AccordionDetails, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GenerateLinks from './GenerateLinks';
import { useStyles, AccordionSummary, Accordion } from './style';
import { Wizard, SidepanelContainer, SidePanel, StyledButton } from '../../../../components';
import {
	isEmptyArray,
	isUndefined,
	isObject,
	isFullArray,
	isFullString,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

const handleAddReducer = (state, action) => {
	switch (action.type) {
		case 'add': {
			return state.concat(action.emails);
		}
		case 'delete': {
			return state.filter((_, index) => index !== action.index);
		}
		case 'reset': {
			return action.payload;
		}
		default: {
			return state;
		}
	}
};

interface InviteUsersProps {
	onInviteUsers?(...args: unknown[]): unknown;
	invitedUsers?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const InviteUsers = (props: InviteUsersProps) => {
	const { onInviteUsers, invitedUsers } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [newEmailGroup, dispatchNewEmailGroup] = useReducer(handleAddReducer, []);

	const [emails, setEmails] = useState([]);
	const [alreadyExistingEmails, setAlreadyExistingEmails] = useState([]);
	const [shouldInviteUsers, setShouldInviteUsers] = useState(false);
	const [invitingUsers, setInvitingUsers] = useState(false);

	const [organisation, setOrganisation] = useState({ name: '', id: '' });

	const {
		data: invitedUsersData,
		error: invitedUsersError,
		loading: invitedUsersLoading,
	} = invitedUsers;
	const invitingUsersDone =
		isObject(invitedUsersData) && !invitedUsersError && !invitedUsersLoading;

	useEffect(() => {
		if (shouldInviteUsers && !invitingUsers) {
			setShouldInviteUsers(false);
			setInvitingUsers(true);
			onInviteUsers({
				...(organisation.id && { organisationId: organisation.id }),
				invites: newEmailGroup.map((item) => ({
					emails: item.emails,
					userGroupId: item.userGroupId,
					culture: item.culture,
				})),
			});
		}
	}, [shouldInviteUsers, invitingUsers]);

	useEffect(() => {
		if (invitingUsers && invitingUsersDone) {
			setInvitingUsers(false);
			//success notifications
			if (!isEmptyArray(invitedUsersData.accepted)) {
				enqueueSnackbar(
					`${t('views.inviteUsers.messages.success.accepted.partOne')} ${invitedUsersData.accepted.length} ${t('views.inviteUsers.messages.success.accepted.partTwo')}`,
					{ variant: 'success' },
				);
			}

			if (!isEmptyArray(invitedUsersData.rejected)) {
				enqueueSnackbar(
					`${t('views.inviteUsers.messages.success.rejected.partOne')} ${invitedUsersData.rejected.length} ${t('views.inviteUsers.messages.success.rejected.partTwo')}`,
				);
			}
			//redirect
			navigate('/user-management/user-invitations');
		}
	}, [invitingUsers, invitingUsersDone]);

	useEffect(() => {
		if (invitingUsers && invitedUsersError) {
			setInvitingUsers(false);
			enqueueSnackbar(isObject(invitedUsersError) ? invitedUsersError.message : invitedUsersError, {
				variant: 'error',
			});
		}
	}, [invitingUsers, invitedUsersError]);

	const callback = (saved) => {
		const { addedEmails, addedInvalidEmails } = saved;

		if (!isUndefined(addedEmails)) {
			setEmails(addedEmails);
		}
		if (!isUndefined(addedInvalidEmails)) {
			setAlreadyExistingEmails(addedInvalidEmails);
		}
		if (isObject(saved.organisation)) {
			setOrganisation({ ...saved.organisation });
		}
	};

	const handleClickInviteUsers = () => {
		setShouldInviteUsers(true);
	};

	const inviteUsersSteps = [
		{
			name: 'Invite Users - Step 1',
			content: GenerateLinks,
			props: {
				emails,
				alreadyExistingEmails,
				dispatchNewEmailGroup,
				savedOrganisation: organisation,
			},
		},
	];

	const handleRemove = ({ item, index }) => {
		setEmails(emails.filter((email) => !item.emails.includes(email)));
		dispatchNewEmailGroup({ type: 'delete', index: index });
	};

	const sidepanel = (
		<SidepanelContainer>
			<SidePanel title={<Typography variant='h4'>{t('ui.category.invitations')}</Typography>}>
				{isFullString(organisation?.name) ?
					<Box display='flex' flexDirection='column' pb={1}>
						<Typography variant='h6'>{t('ui.organisation')}</Typography>
						<Typography variant='body2'> {organisation.name}</Typography>
					</Box>
				:	null}
				{isFullArray(newEmailGroup) ?
					newEmailGroup.map((item, index) => {
						return (
							<Accordion className={classes.panelBorder} key={index}>
								<AccordionSummary
									className={classes.panelBorder}
									expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
									id='panel1a-header'
								>
									<Typography variant='h6'>
										{item.emails.length === 1 ?
											item.emails[0]
										:	`${item.emails.length} invitations`}{' '}
									</Typography>
								</AccordionSummary>
								<AccordionDetails className={classes.expansionDetails}>
									<Box display='flex' justifyContent='space-between'>
										<Box display='flex' flexDirection='column' rowGap={1.5}>
											<Box display='flex' flexDirection='column'>
												<Typography variant='h6'>{t('ui.label.emails')}</Typography>
												<Box display='flex' flexDirection='row' flexWrap='wrap'>
													<Typography key={`emails-${index}`} variant='body2'>
														{' '}
														{item.emails.map((item) => item).join(', ')}{' '}
													</Typography>
												</Box>
											</Box>
											<Box display='flex' flexDirection='column'>
												<Typography variant='h6'>{t('ui.label.userGroup')}</Typography>
												<Typography variant='body2'> {item.userGroupName}</Typography>
											</Box>
											<Box display='flex' flexDirection='column'>
												<Typography variant='h6'>
													{t('views.userDetails.summary.language')}{' '}
												</Typography>
												<Typography variant='body2'>{t(`ui.${item.language}`)}</Typography>
											</Box>
										</Box>
									</Box>
									<StyledButton
										className={classes.removeButton}
										fullWidth
										onClick={() => handleRemove({ index, item })}
										variant='contained-tertiary'
									>
										{t('ui.button.contained.remove')}
									</StyledButton>
								</AccordionDetails>
							</Accordion>
						);
					})
				:	!organisation?.name && (
						<Typography className={classes.sidebarEmptyState}>
							{t('views.inviteUsers.sidebar.empty')}
						</Typography>
					)
				}
				<Divider />
				<StyledButton
					className={classes.sidebarButton}
					disabled={isEmptyArray(emails) || invitedUsersLoading}
					fullWidth
					onClick={handleClickInviteUsers}
					variant='contained-secondary'
				>
					{invitedUsersLoading ?
						<CircularProgress size={24} />
					:	t('view.usermanagement.invitations.button.contained.sendinvite')}
				</StyledButton>
			</SidePanel>
		</SidepanelContainer>
	);

	return (
		<Wizard
			callback={callback}
			shouldNotShowStepActionFooter
			sidebar={sidepanel}
			steps={inviteUsersSteps}
			title={t('views.inviteUsers.page.title')}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		invitedUsers: state.details.invitedUsers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onInviteUsers: (bodyData) => dispatch(actions.inviteUsers(bodyData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteUsers);
