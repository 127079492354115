import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Table } from '../../../../../components';
import {
	isFullString,
	isObject,
	isNumber,
	decimalAmount,
	isFullArray,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface BookingsFinanceProps {
	businessExpenses?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	id?: string;
	date?: string;
	onFetchBusinessExpenses?(...args: unknown[]): unknown;
}

const BookingsFinance = (props: BookingsFinanceProps) => {
	const classes = useStyles();
	const { businessExpenses, id, date, onFetchBusinessExpenses } = props;
	const { t } = useTranslation('general');
	const [finance, setFinance] = useState(null);
	const { data: businessExpensesData, loading: businessExpensesLoading } = businessExpenses;

	const filters = {
		...(isFullString(id) && { id: id }),
	};
	useEffect(() => {
		if (!isObject(businessExpensesData) && !businessExpensesLoading) {
			onFetchBusinessExpenses(date, filters);
		}
	}, [businessExpensesLoading]);

	useEffect(() => {
		const parseId = parseInt(id, 10);
		if (isNumber(parseId) && isObject(businessExpensesData)) {
			const financeData = businessExpensesData.billings.filter((billing) =>
				billing.organisationReference.id === parseId ? { amount: billing.amount } : null,
			);
			setFinance(financeData);
		}
	}, [id, businessExpensesLoading]);

	const tableHeader = getFinanceTableHeaders(false, true, true);

	const tableBody =
		isFullArray(finance) ?
			finance.map((item) => [
				{
					content: (
						<Box className={classes.link} width='480px'>
							{businessExpensesData.title}
						</Box>
					),
				},
				{ content: decimalAmount(item.amount.subtotal, item.amount) },
				{ content: decimalAmount(item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.subtotal - item.amount.discount, item.amount) },
				{ content: decimalAmount(item.amount.priceVat, item.amount) },
				{ content: decimalAmount(item.amount.refund, item.amount) },
				{ content: decimalAmount(item.amount.totalPrice, item.amount) },
			])
		:	Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation = {
		header: t('ui.label.organisationalBilling'),
	};

	return (
		<Table
			body={tableBody}
			cellStyle={classes.cellStyle}
			header={tableHeader}
			isNotPaginate={true}
			loading={businessExpensesLoading}
			titleWithInformation={titleWithInformation}
			withoutPages={true}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		businessExpenses: state.details.businessExpenses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessExpenses: (date, filters) =>
			dispatch(actions.fetchBusinessExpenses(date, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsFinance);
