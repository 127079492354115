import { Modal, Card } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';

interface ModalPopupProps {
	onClose?(...args: unknown[]): unknown;
	className?: string;
	children?: object;
	open?: boolean;
}

const ModalPopup = (props: ModalPopupProps) => {
	const { className, onClose, open, children } = props;

	const classes = useStyles();

	if (!open) {
		return null;
	}

	return (
		<Modal onClose={onClose} open={open}>
			<Card className={clsx(classes.root, className)}>{children}</Card>
		</Modal>
	);
};

export default ModalPopup;
