import { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CardActions,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AddressEdit from './AddressEdit';
import { useStyles } from './style';
import { StaticMap, StyledButton } from '../../../../../../components';
import { handleHubReference, isObject } from '../../../../../../shared/utility';

interface AddressInfoProps {
	className?: string;
	hub?: object;
	updateLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AddressInfo = (props: AddressInfoProps) => {
	const { hub, className, updateLocation } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [openEdit, setOpenEdit] = useState(false);

	const { data: updateLocationData, loading: updateLocationLoading } = updateLocation;

	useEffect(() => {
		if (!updateLocationLoading && isObject(updateLocationData)) {
			enqueueSnackbar(t('views.locationDetail.locationInfo.edit.success.message'), {
				variant: 'success',
			});
		}
	}, [updateLocationLoading]);

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const hubTable = [
		{ label: 'ui.label.address', content: handleHubReference(hub) },
		{ label: 'ui.label.gpsCoordinates', content: `${hub.latitude}, ${hub.longitude}` },
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.locationDetail.adressInfo.address')} />
			<Divider />
			<CardContent>
				<div>
					<StaticMap isImage lat={hub.latitude} lng={hub.longitude} />
				</div>
				<Table>
					<TableBody>
						{hubTable.map((item, index) => (
							<TableRow key={index}>
								<TableCell>{t(item.label)}</TableCell>
								<TableCell>{item.content ? item.content : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton onClick={handleEditOpen} startIcon={<EditIcon />} variant='inline-default'>
					{t('ui.button.inline.edit')}
				</StyledButton>
			</CardActions>
			{openEdit ?
				<AddressEdit location={hub} onClose={handleEditClose} open={openEdit} />
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		updateLocation: state.details.updateLocation,
	};
};

export default connect(mapStateToProps)(AddressInfo);
