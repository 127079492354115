import {
	CircularProgress,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

type AsyncSelectProps<T extends BaseReference> = Omit<MuiSelectProps, 'children'> & {
	value?: T | null;
	loading?: boolean;
	onChange?: (e: SelectChangeEvent<string>, value: T | undefined) => void;
	options: T[];
	clearable?: boolean;
	emptyLabel?: string;
};

/**
 * An async select component. E.g. used for a finite amount of server defined options.
 */
const AsyncSelect = <T extends BaseReference>({
	loading = false,
	value,
	onChange,
	options,
	clearable = false,
	displayEmpty,
	emptyLabel = i18n.t('all'),
	...props
}: AsyncSelectProps<T>) => {
	const { t } = useTranslation('general');

	const label = props.required && props.label ? `${props.label} *` : props.label;

	const handleChange = (event: SelectChangeEvent<string>) => {
		if (event.target.value === '') {
			onChange?.(event, null);
		} else {
			const option = options.find((el) => el.id === event.target.value);
			onChange?.(event, option);
		}
	};

	return (
		<FormControl sx={{ width: props.sx?.width ?? 1 }}>
			<InputLabel id={props.labelId} shrink={true}>
				{label}
			</InputLabel>
			<MuiSelect
				{...props}
				label={label}
				displayEmpty={displayEmpty}
				value={value?.id ?? ''}
				onChange={handleChange}
				renderValue={(selected) => {
					if (selected === '') {
						return emptyLabel;
					}

					// Find the label, otherwise fallback to selected to at least show something
					return options.find((el) => el.id == selected)?.label ?? selected;
				}}
				endAdornment={
					<InputAdornment
						position='end'
						sx={{ position: 'absolute', right: 35, pointerEvents: 'none' }}
					>
						{loading && <CircularProgress size={20} color='inherit' />}
					</InputAdornment>
				}
				sx={{ width: 1, ...props.sx }}
			>
				{loading ?
					<MenuItem disabled>{`${t('loading')}...`}</MenuItem>
				:	[
						...(clearable && displayEmpty ?
							[
								<MenuItem key='empty' value={''} sx={{ fontWeight: 500 }}>
									{emptyLabel}
								</MenuItem>,
							]
						:	[]),
						...options.map((el) => (
							<MenuItem key={el.id} value={el.id}>
								{el.label}
							</MenuItem>
						)),
					]
				}
			</MuiSelect>
		</FormControl>
	);
};

export default AsyncSelect;
