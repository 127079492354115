import { useStyles } from './style.js';

interface MaxLengthCountDownProps {
	maxLength?: number;
	length?: number;
}

const MaxLengthCountDown = (props: MaxLengthCountDownProps) => {
	const { maxLength, length } = props;

	const classes = useStyles();

	return <p className={classes.counterText}>{length + '/' + maxLength}</p>;
};

export default MaxLengthCountDown;
