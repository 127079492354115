import { useState } from 'react';

import path from 'path';

import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { EmptyTable, FormContainer } from '~components';
import FormContainerBox from '~components/containers/formContainer/formContainerBox';
import { pagePathSegments } from '~constants';
import { useSnackbar } from '~hooks';

import SsoDomainForm from '../forms/ssoDomain/ssoDomainForm';
import { NewSsoDomain } from '../interfaces/ssoDomain';
import OrganisationsService from '../services/organisationsService';

const service = new OrganisationsService();

interface AddSsoDomainsLayoutProps {
	organisationId: string;
}

const AddSsoDomainsLayout = ({ organisationId }: AddSsoDomainsLayoutProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();

	const [domains, setDomains] = useState<NewSsoDomain[]>([]);

	const handleRemoveDomain = (index: number) => {
		const newDomains = domains.filter((_, i) => i !== index);
		setDomains(newDomains);
	};

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		[`${service.basePath}.ssoDomains`, organisationId],
		([_, id], { arg }) => service.addSsoDomains(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('ssoDomain').toLowerCase()}`);
				navigate(
					`/${path.join(pagePathSegments.Organisations, organisationId, pagePathSegments.SsoDomains)}`,
				);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	return (
		<Stack spacing={6} direction='row' sx={{ width: 'fit-content' }}>
			<Card>
				<CardHeader
					title={t('ssoDomains')}
					titleTypographyProps={{
						variant: 'h3',
					}}
				/>
				<CardContent sx={{ minWidth: 640 }}>
					<Typography variant='body2' sx={{ pb: 2 }}>
						{t('addSsoSignupDomainDescription')}
					</Typography>
					<FormContainerBox spacing={3}>
						<SsoDomainForm
							selectedValues={domains}
							organisationId={organisationId}
							onSubmit={(value) => setDomains((prev) => [...prev, value])}
						/>
					</FormContainerBox>
				</CardContent>
			</Card>

			<Card
				sx={{
					height: 'fit-content',
				}}
			>
				<CardHeader title={t('ssoDomains')} />
				<CardContent sx={{ minWidth: 320 }}>
					{domains.length > 0 ?
						<List>
							{domains.map((el, i, arr) => (
								<ListItem
									key={i}
									secondaryAction={
										<IconButton onClick={() => handleRemoveDomain(i)} edge='end'>
											<CloseIcon />
										</IconButton>
									}
									divider={i < arr.length - 1}
								>
									<ListItemText>{el.domainName}</ListItemText>
								</ListItem>
							))}
						</List>
					:	<EmptyTable label={t(`views.emailDomains.noEmailDomains.title`)} />}
				</CardContent>
				<CardActions sx={{ pb: 3 }}>
					<LoadingButton
						variant='contained'
						disabled={domains.length <= 0}
						fullWidth
						onClick={async () => triggerCreate(domains)}
						loading={isCreateMutating}
					>
						{t('ui.confirm')}
					</LoadingButton>
				</CardActions>
			</Card>
		</Stack>
	);
};

export default AddSsoDomainsLayout;
