import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';

interface HeaderProps {
	className?: string;
}

const Header = (props: HeaderProps) => {
	const { className } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<div className={className}>
			<Grid
				alignItems='flex-end'
				className={classes.headerSpacing}
				container
				justifyContent='space-between'
				spacing={3}
			>
				<Grid item>
					<Typography component='h1' variant='h3'>
						{t('nav.category.licenses')}
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

export default Header;
