import { useEffect } from 'react';

import path from 'path';

import { Tab, Tabs } from '@mui/material';
import { Link, useMatches, useSearchParams } from 'react-router-dom';

import { UserRoleEnum } from '~enums';
import { useAuthorize } from '~features/authentication';

interface Page {
	label: string;
	path: string;
	// Otherwise defaults to admin?
	roles?: UserRoleEnum[];
}

interface PageNavBarProps {
	pages: Page[];
}

const PageNavBar = ({ pages }: PageNavBarProps) => {
	const matches = useMatches();
	const [searchParams] = useSearchParams();
	const { authorize } = useAuthorize();

	/**
	 * Split the last path entry from the path and keep the parent path.
	 * We use the useMatches for this, because if keeps a predictable
	 * trailing slash we can split on
	 */
	const pathElements = matches[matches.length - 1].pathname.split('/').reverse();
	const lastPath = pathElements.find((el) => el !== '');

	return (
		<Tabs value={lastPath}>
			{pages
				.filter((el) => !el.roles || authorize(el.roles))
				.map((page) => (
					<Tab
						key={page.path}
						component={Link}
						value={page.path}
						to={{
							pathname: page.path,
							search: searchParams.toString(),
						}}
						label={page.label}
						relative='path'
					/>
				))}
		</Tabs>
	);
};

export default PageNavBar;
