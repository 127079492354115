import { useEffect, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { queryParameters } from '~constants';
import { PeriodicityEnum } from '~enums';

/**
 * A hook to synchronize the periodicity to the url as search parameters
 * @param defaultPeriodicity
 * @returns
 */
const useUrlSearchParamsPeriodicity = (
	defaultPeriodicity = PeriodicityEnum.Month,
): [PeriodicityEnum, (value: PeriodicityEnum) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parseValue = (periodicityString: string) => {
		if (Object.values(PeriodicityEnum).includes(periodicityString as PeriodicityEnum)) {
			return periodicityString as PeriodicityEnum;
		}

		return defaultPeriodicity;
	};

	const periodicity = useMemo(() => {
		const periodicitySearchParam = searchParams.get(queryParameters.Periodicity);

		if (periodicitySearchParam != null) {
			return parseValue(periodicitySearchParam);
		}

		return defaultPeriodicity;
	}, [searchParams]);

	const setPeriodicity = (value?: PeriodicityEnum) => {
		if (!value) {
			searchParams.delete(queryParameters.Periodicity);
		} else {
			searchParams.set(queryParameters.Periodicity, value.toString());
		}

		setSearchParams(searchParams);
	};

	return [periodicity, setPeriodicity];
};

export default useUrlSearchParamsPeriodicity;
