import { useState, useEffect } from 'react';

import {
	Card,
	CardContent,
	Typography,
	CardHeader,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Avatar,
	Box,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Search, Paginate, LoadingBar, EmptyState, StyledButton } from '../../../../../components';
import { useSearchComponent } from '../../../../../shared/hooks';
import {
	isEmptyString,
	isObject,
	isOdd,
	getInitials,
	isEmptyArray,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface InvitePartnerProps {
	availablePartnerships?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	addedPartnership?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchAvailablePartnerships?(...args: unknown[]): unknown;
	onConnectToPartnership?(...args: unknown[]): unknown;
}

const InvitePartner = (props: InvitePartnerProps) => {
	const {
		availablePartnerships,
		onFetchAvailablePartnerships,
		onConnectToPartnership,
		addedPartnership,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		data: availablePartnershipsData,
		loading: availablePartnershipsLoading,
		error: availablePartnershipsError,
	} = availablePartnerships;
	const availablePartnershipsReady =
		isObject(availablePartnershipsData) &&
		!availablePartnershipsLoading &&
		!availablePartnershipsError;

	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 10;

	const [showPagination, setShowPagination] = useState(false);
	const [totalItems, setTotalItems] = useState();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [connectingToPartner, setConnectingToPartner] = useState(false);
	const [connectingToPartnerId, setConnectingToPartnerId] = useState();

	const {
		data: addedPartnershipData,
		loading: addedPartnershipLoading,
		error: addedPartnershipError,
	} = addedPartnership;
	const addPartnershipDone =
		isObject(addedPartnershipData) && !addedPartnershipLoading && !addedPartnershipError;

	const search = useSearchComponent(setPageNumber, setShouldSearch);

	useEffect(() => {
		const page = { number: pageNumber, size: pageSize };
		const filters = {
			...(!isEmptyString(search.value) && { searchTerm: search.value }),
		};

		if (shouldDoInitialFetch || shouldSearch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchAvailablePartnerships(page, filters);
		}

		if (shouldSearch) {
			setShowPagination(false);
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [onFetchAvailablePartnerships, shouldDoInitialFetch, shouldSearch, pageNumber, pageSize]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	useEffect(() => {
		if (!showPagination && availablePartnershipsReady) {
			setShowPagination(true);
			setTotalItems(availablePartnershipsData.total);
		}
	}, [availablePartnershipsReady, showPagination]);

	const handlePageChange = (page) => {
		setPageNumber(page.selected + 1);
		setShouldSearch(true);
	};

	const handleClickConnect = (id) => {
		if (!connectingToPartner) {
			onConnectToPartnership(id);
			setConnectingToPartnerId(id);
			setConnectingToPartner(true);
		}
	};

	useEffect(() => {
		if (connectingToPartner && addPartnershipDone) {
			setConnectingToPartner(false);
			setShouldSearch(true);
			setPageNumber(1);
			enqueueSnackbar(
				`${t('views.addPartner.messages.success')} ${addedPartnershipData.partnerOrganisation.name}`,
				{ variant: 'success' },
			);
		}
	}, [addPartnershipDone, connectingToPartner]);

	useEffect(() => {
		if (connectingToPartner && !!addedPartnershipError) {
			setConnectingToPartner(false);
			setConnectingToPartnerId(null);
			enqueueSnackbar(
				isObject(addedPartnershipError) ? addedPartnershipError.message : addedPartnershipError,
				{ variant: 'error' },
			);
		}
	}, [addedPartnershipError, connectingToPartner]);

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const availablePartnershipsEmpty =
		(
			isObject(availablePartnershipsData) &&
			!availablePartnershipsLoading &&
			isEmptyArray(availablePartnershipsData.results)
		) ?
			showingInitialResults ?
				<EmptyState
					className={classes.emptyState}
					image={'booking'}
					subTitle={t('views.addPartner.availablePartnerships.empty.description.zeroInSystem')}
					title={t('views.addPartner.availablePartnerships.empty.title')}
				/>
			:	<EmptyState
					className={classes.emptyState}
					image={'booking'}
					subTitle={t('views.addPartner.availablePartnerships.empty.description.zeroMatching')}
					title={t('views.addPartner.availablePartnerships.empty.title')}
				/>
		:	null;

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<Card>
			<CardHeader
				subheader={
					<Typography variant='body2'>
						{t('views.addPartner.subheader.availablePartners')}
					</Typography>
				}
				title={
					<Typography variant='h4'>
						{t('views.addPartner.blockHeader.availablePartners')}
					</Typography>
				}
			/>
			<CardContent>
				<Search
					events={search.events}
					hasSearchButton
					placeholder={t('views.addPartner.search.placeholder')}
					value={search.value}
				/>
				<Box className={classes.tableContainer}>
					{availablePartnershipsEmpty ?
						availablePartnershipsEmpty
					:	<Table size='small'>
							<TableBody>
								{availablePartnershipsReady ?
									availablePartnershipsData.results.map((partner, index) => (
										<TableRow
											className={classes.tableRow}
											key={`possible-partner-${partner.partnerOrganisation.id}`}
											selected={isOdd(index)}
										>
											<TableCell className={classes.avatarCell}>
												{partner.partnerOrganisation.logo ?
													<Avatar
														className={classes.avatar}
														src={partner.partnerOrganisation.logo}
													/>
												:	<Avatar>{getInitials(partner.partnerOrganisation.name)}</Avatar>}
											</TableCell>
											<TableCell>
												<Typography variant='h6'>{partner.partnerOrganisation.name}</Typography>
											</TableCell>
											<TableCell align='right'>
												<StyledButton
													disabled={
														connectingToPartnerId === partner.partnerOrganisation.id &&
														connectingToPartner
													}
													onClick={() => handleClickConnect(partner.partnerOrganisation.id)}
													variant='contained-secondary'
												>
													{t('ui.button.contained.connect')}
												</StyledButton>
											</TableCell>
										</TableRow>
									))
								:	Array(pageSize)
										.fill({})
										.map((_, index) => (
											<TableRow
												className={classes.tableRow}
												hover={false}
												key={`loading-partner-${index}`}
												selected={isOdd(index)}
											>
												<TableCell className={classes.avatarCell}>
													<LoadingBar />
												</TableCell>
												<TableCell>
													<LoadingBar />
												</TableCell>
												<TableCell align='right'>
													<LoadingBar />
												</TableCell>
											</TableRow>
										))
								}
							</TableBody>
						</Table>
					}
				</Box>
				<Box className={classes.paginationContainer} display='flex' justifyContent='center'>
					{showPagination ?
						totalItems / pageSize > 1 ?
							<Paginate
								forcePage={pageNumber - 1}
								onPageChange={handlePageChange}
								pageCount={totalItems / pageSize}
							/>
						:	null
					:	<LoadingBar />}
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		availablePartnerships: state.paged.availablePartnerships,
		addedPartnership: state.details.addedPartnership,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchAvailablePartnerships: (page, filters) =>
			dispatch(actions.fetchAvailablePartnerships(page, filters)),
		onConnectToPartnership: (id) => dispatch(actions.connectToPartner(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitePartner);
