import { useState } from 'react';

import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, SwitchButton } from '../../../../../../../components';
import * as actions from '../../../../../../../store/actions';

interface DevicesDialogProps {
	open?: boolean;
	handleClose?(...args: unknown[]): unknown;
	onUpdateDeviceAccess?(...args: unknown[]): unknown;
	organisationId?: number;
	hasDevicesAccess?: boolean;
}

const DevicesDialog = (props: DevicesDialogProps) => {
	const { open, handleClose, onUpdateDeviceAccess, organisationId, hasDevicesAccess } = props;
	const { t } = useTranslation('general');

	const [devicesAccess, setDevicesAccess] = useState(hasDevicesAccess);

	const handleDevicesUpdate = () => {
		onUpdateDeviceAccess(organisationId, devicesAccess);
		handleClose();
	};

	const handleDevices = (event) => {
		const value = event.target.checked;
		setDevicesAccess(value);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleDevicesUpdate,
				text: t('ui.confirm'),
			}}
			handleClose={handleClose}
			loading={false}
			open={open}
			title={t('ui.label.devices')}
		>
			<Box alignItems='center' display='flex' justifyContent='space-between'>
				<Typography variant='h6'>
					{t('views.organisationManagement.devices.devicesDialog.showDevices')}
				</Typography>
				<SwitchButton checked={devicesAccess} onChange={handleDevices} />
			</Box>
		</ActionDialog>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateDeviceAccess: (organisationId, properties) =>
			dispatch(actions.updateDeviceAccess(organisationId, properties)),
	};
};

export default connect(null, mapDispatchToProps)(DevicesDialog);
