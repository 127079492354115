enum ItemCategoryEnum {
	Cars = 'cars',
	Trailers = 'trailers',
	Bikes = 'bikes',
	Offices = 'offices',
	// Assets = 'assets',
	Boats = 'boats',
	ParkingLots = 'parkingLots',
	Other = 'other',
}

export default ItemCategoryEnum;
