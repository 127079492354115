import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FormFieldSelectProps {
	className?: string;
	name?: string;
	label?: string;
	variable?: object;
	required?: boolean;
	displayEmpty?: boolean;
	helperText?: string;
	disabled?: boolean;
	children?: React.ReactNode;
	fullWidth?: boolean;
}

const FormFieldSelect = (props: FormFieldSelectProps) => {
	const {
		className,
		label,
		name,
		variable,
		required,
		displayEmpty,
		helperText,
		disabled,

		children,
		fullWidth,
	} = props;
	const { t } = useTranslation('general');

	const helperOrErrorText = () => {
		if (variable.isTouched && !variable.hasFocus && !variable.isValid) {
			//show error message
			return t('ui.input.error.validValue');
		} else if (helperText) {
			return helperText;
		} else {
			return null;
		}
	};

	return (
		<TextField
			className={className}
			disabled={disabled}
			error={variable.isTouched && !variable.hasFocus && !variable.isValid}
			fullWidth={fullWidth}
			helperText={helperOrErrorText()}
			label={label}
			name={name}
			required={required}
			select
			variant='outlined'
			{...variable.bindToFormField}
			FormHelperTextProps={{
				style: {
					position: 'absolute',
					marginTop: '4rem',
				},
			}}
			InputLabelProps={{
				shrink: true,
			}}
			SelectProps={{
				displayEmpty: displayEmpty,
				MenuProps: {
					PaperProps: {
						style: {
							maxHeight: 250,
						},
					},
				},
			}}
		>
			{children}
		</TextField>
	);
};

export default FormFieldSelect;
