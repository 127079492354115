import { useState, useEffect } from 'react';

import {
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { StyledButton } from '../../../../../../components';
import { SelectWithLazyLoading, StaticMap } from '../../../../../../components';
import { useError } from '../../../../../../shared/hooks';
import { isObject, handleHubReference, isNull } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions/index';
import { useStyles } from '../style';

interface LocationInfoProps {
	instanceData?: object;
	hubsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchHubs?(...args: unknown[]): unknown;
	updateInstanceLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onUpdateInstanceLocation?(...args: unknown[]): unknown;
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const LocationInfo = (props: LocationInfoProps) => {
	const {
		onFetchHubs,
		hubsList,
		instanceData,
		updateInstanceLocation,
		onUpdateInstanceLocation,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const hubReference = instanceData.hubReference;

	const { data: currentUserData } = currentUser;
	const personalItem =
		isObject(currentUserData) &&
		isObject(instanceData) &&
		currentUserData.organisationReference.id ===
			instanceData.itemReference.hubReference.organisationReference.id;

	const { loading: updateLocationLoading, error: updateInstanceError } = updateInstanceLocation;

	const [locationFilter, setLocationFilter] = useState({
		id: hubReference.id,
		value: hubReference.name,
	});

	const [filter] = useState({
		name: 'organisationId',
		value: instanceData.itemReference.hubReference.organisationReference.id,
	});

	const [extraFilter] = useState({
		name: 'filters',
		value: 'none',
	});

	const updatedDataError = useError({
		value: updateInstanceLocation,
		message: `${t('ui.success.message.updated')} ${t('views.addItem.settingsCard.locationCard.header')}`,
		variant: 'success',
	});

	useEffect(() => {
		if (!updateLocationLoading && !isNull(updateInstanceError)) {
			setLocationFilter({ ...locationFilter });
		}
	}, [updateLocationLoading]);

	const classes = useStyles();

	const handleChangeLocation = (location) => {
		if (location.id === locationFilter.id) {
			return;
		}
		setLocationFilter({ id: location.id, value: location.name });
		onUpdateInstanceLocation(instanceData.itemReference.id, instanceData.id, {
			hubId: location.id,
		});
		updatedDataError.setStartAction(true);
	};

	return (
		<Card className={classes.maiCard}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('views.locationDetail.locationInfo.locationInfo')}</Typography>
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Box display='flex' flexDirection='column' p={2}>
					{personalItem || isSuperAdmin() ?
						<>
							<Typography pb={2}>
								{t('views.itemManagement.details.instanceDetails.locationInfo.assigned')}
							</Typography>
							<Box pb={2}>
								<SelectWithLazyLoading
									dataList={hubsList}
									extraFilter={extraFilter}
									filter={filter}
									listType='organisations'
									onFetchData={onFetchHubs}
									placeholder={t('ui.filter.locations.all')}
									setSelected={handleChangeLocation}
									value={locationFilter.value}
								/>
							</Box>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell>{t('ui.pickUpReturn')}</TableCell>
										<TableCell>{handleHubReference(hubReference)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>{t('views.locationDetail.locationInfo.actualLocation')}</TableCell>
										<TableCell>
											<StyledButton
												component={RouterLink}
												size='small'
												to={'/map-view'}
												state={{ from: location.pathname }}
												variant='contained-secondary'
											>
												{t('ui.label.goToMapView')}
											</StyledButton>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</>
					:	<>
							<StaticMap isImage lat={hubReference.latitude} lng={hubReference.longitude} />
							<Box display='flex' flexDirection='column' pt={2}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>{t('ui.pickUpReturn')}</TableCell>
											<TableCell>{handleHubReference(hubReference)}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>{t('ui.label.gpsCoordinates')}</TableCell>
											<TableCell>
												{' '}
												{`${hubReference.latitude}, ${hubReference.longitude}`}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		hubsList: state.paged.hubs,

		updateInstanceLocation: state.details.updateInstanceLocation,

		currentUser: state.details.currentUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onUpdateInstanceLocation: (itemId, instanceId, properties) =>
			dispatch(actions.updateInstanceLocation(itemId, instanceId, properties)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo);
