import { styled, useTheme } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
	fill: theme.palette.text.primary,
	textAnchor: 'middle',
	dominantBaseline: 'central',
	fontFamily: theme.typography.fontFamily,
}));

interface PieChartCenterLabelProps {
	primary?: string;
	secondary?: string | number;
}

/**
 * An component to show labels in a the center of a pie
 * chart
 */
const PieChartCenterLabel = ({ primary, secondary }: PieChartCenterLabelProps) => {
	const { width, height, left, top } = useDrawingArea();
	const theme = useTheme();

	return (
		<g>
			<StyledText
				x={left + width / 2}
				y={top + height / 2 - 20}
				fontSize={theme.typography.body1.fontSize}
			>
				{primary}
			</StyledText>
			<StyledText x={left + width / 2} y={top + height / 2 + 8} fontSize={30}>
				{secondary}
			</StyledText>
		</g>
	);
};

export default PieChartCenterLabel;
