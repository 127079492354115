import { useTheme } from '@mui/material';
import { legendClasses, BarChart as MuiBarChart, BarChartProps, useYAxis } from '@mui/x-charts';

import { colorsSlim } from '~themes/chartColors';

/**
 * A wrapper component for the mui pie chart
 */
const BarChart = ({ colors = colorsSlim, ...chartProps }: BarChartProps) => {
	const theme = useTheme();

	return (
		<MuiBarChart
			{...chartProps}
			colors={colors}
			slotProps={{
				popper: {
					sx: {
						fontFamily: theme.typography.fontFamily,
						fontSize: theme.typography.body1.fontSize,
						color: theme.palette.text.secondary,
					},
					...chartProps.slotProps?.popper,
				},
				legend: {
					itemMarkWidth: 8,
					itemMarkHeight: 8,
					position: {
						vertical: 'bottom',
						horizontal: 'middle',
					},
					...chartProps.slotProps?.legend,
				},
				...chartProps.slotProps,
			}}
			grid={{ horizontal: true }}
			bottomAxis={{
				disableLine: true,
				disableTicks: true,
				...chartProps.bottomAxis,
			}}
			leftAxis={{
				disableLine: true,
				disableTicks: true,
				...chartProps.leftAxis,
			}}
			margin={{
				top: 80,
				right: 10,
				bottom: 20,
				left: 40,
				...chartProps.margin,
			}}
			sx={{
				...chartProps.sx,
				[`& .${legendClasses.mark}`]: {
					ry: 10,
				},
			}}
		/>
	);
};

export default BarChart;
