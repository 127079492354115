import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack, Typography } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';

import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import SkcDeviceGroup from '../../interfaces/skcDeviceGroup';
import SkcDeviceGroupsService from '../../services/skcDeviceGroupsService';
import SelectOrganisationOverlay from './selectOrganisationOverlay';

const toolbarIdString = 'topology-target-datagrid';

const service = new SkcDeviceGroupsService();

interface SkcDeviceGroupsDataGridProps {
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const SkcDeviceGroupsDataGrid = forwardRef<ListMutationRefProps, SkcDeviceGroupsDataGridProps>(
	({ onEdit, onDelete, onCreate }, ref) => {
		const { t } = useTranslation('general');
		const { isSuperAdmin } = useAuthorize();
		const location = useLocation();
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [fetchParameters, setFetchParameters] = useState<object | null>(null);

		const columns = useMemo<GridColDef<SkcDeviceGroup>[]>(
			() => [
				{
					field: 'id',
					headerName: 'ID',
					width: 80,
					hideable: false,
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={params.value.toString()}
							state={{ from: location.pathname }}
						>
							{params.value}
						</MuiLink>
					),
				},
				{
					field: 'label',
					headerName: t('ui.label.name'),
					flex: 1,
				},
				{
					field: 'description',
					headerName: t('ui.label.description'),
					valueFormatter: (value: string) => value ?? '-',
					flex: 1,
				},
				{
					field: 'organisation',
					headerName: t('organisation'),
					valueGetter: () => selectedOrganisation,
					valueFormatter: (value: BaseReference) => value?.label ?? '-',
					flex: 1,
				},
				{
					field: 'devicesCount',
					headerName: t('devices'),
					valueGetter: (_, row) => row.devices?.length ?? 0,
					type: 'number',
					width: 180,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-edit`}
							label={t('ui.edit')}
							icon={<EditIcon />}
							color='inherit'
							onClick={() => onEdit?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
						<GridActionsCellItem
							key={`${params.id}-delete`}
							label={t('ui.delete')}
							icon={<DeleteOutlinedIcon color='error' />}
							color='error'
							onClick={() => onDelete?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
					],
				},
			],
			[selectedOrganisation],
		);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters?.organisationId != null ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getDeviceGroups(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(ref, () => ({
			mutate: mutate,
		}));

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setSelectedOrganisation(organisation);
			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		const getTogglableColumns = (columns: GridColDef[]): string[] => {
			const hiddenFields = isSuperAdmin() ? [] : ['organisation'];
			const visibleColumns = columns.filter(
				(column) => column.hideable && !hiddenFields.includes(column.field),
			);
			return visibleColumns.map((column) => column.field);
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				<Box
					id={toolbarIdString}
					sx={{
						minHeight: 48,
					}}
				/>
				<DataGrid
					disableColumnSorting
					snapshotKey='accdg'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					// columnVisibilityModel={columnVisibilityModel}
					// onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					initialState={{
						filter: {
							filterModel: {
								items:
									selectedOrganisation ?
										[
											{
												field: 'organisation',
												operator: 'contains',
												value: selectedOrganisation,
											},
										]
									:	[],
							},
						},
					}}
					slots={{
						toolbar: Toolbar,
						...(selectedOrganisation == null && {
							noRowsOverlay: SelectOrganisationOverlay,
						}),
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate,
						},
						columnsManagement: {
							getTogglableColumns,
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('deviceGroups') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('deviceGroups') }),
						},
					}}
					sx={{ flexGrow: 1, flexBasis: 200 }}
				/>
			</Stack>
		);
	},
);
SkcDeviceGroupsDataGrid.displayName = 'SkcDeviceGroupsDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableSearch disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', { resource: t('deviceGroup') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default SkcDeviceGroupsDataGrid;
