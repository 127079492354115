import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	CardActions,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import OpeningHoursEdit from './OpeningHoursEdit';
import { useStyles } from './style';
import { StyledButton } from '../../../../../../components';
import { stringifyTime } from '../../../../../../shared/datetime';

interface OpeningHoursProps {
	className?: string;
	hub?: object;
}

const OpeningHours = (props: OpeningHoursProps) => {
	const { className, hub } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [openEdit, setOpenEdit] = useState(false);

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.locationDetail.summary.cardHeaders.openingHours')} />
			<Divider />
			<CardContent>
				<Table>
					<TableBody>
						{hub.openHours.map((item) => (
							<TableRow key={item.dayOfWeek}>
								<TableCell>
									<Typography>{t(`ui.days.${item.dayOfWeek}`)}</Typography>
								</TableCell>
								<TableCell>
									{item.openStatus === 'open' ?
										`${stringifyTime(item.openHour.slice(11, 16))} - ${stringifyTime(item.closeHour.slice(11, 16))}`
									:	t(`ui.openStatus.${item.openStatus}`)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions className={classes.actions}>
				<StyledButton
					className={classes.editButton}
					onClick={handleEditOpen}
					startIcon={<EditIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.edit')}
				</StyledButton>
			</CardActions>
			<OpeningHoursEdit
				hub={hub}
				onClose={handleEditClose}
				open={openEdit}
				openHours={hub.openHours}
			/>
		</Card>
	);
};

export default OpeningHours;
