import { Box, Divider, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import PageHeader, { Page } from './headers/pageHeader';

interface PageLayoutProps {
	title?: string;
	pages?: Page[];
	enableOrganisationSelect?: boolean;
}

const PageLayout = ({ title, pages, enableOrganisationSelect = false }: PageLayoutProps) => {
	return (
		<Stack direction='column' width={1} height={1} spacing={3} px={{ xs: 1, md: 3 }} py={2}>
			<Box>
				<PageHeader
					title={title}
					pages={pages}
					enableOrganisationSelect={enableOrganisationSelect}
				/>
				<Divider orientation='horizontal' sx={{ m: 0, width: 1 }} />
			</Box>

			<Box flexGrow={1}>
				<Outlet />
			</Box>
		</Stack>
	);
};

export default PageLayout;
