import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';

import { useStyles } from './style';
import { LoadingBar } from '../../../components';

interface SelectedDeviceCardProps {
	name?: string;
	address?: string;
	image?: string;
	hasCloseButton?: boolean;
	handleClose?(...args: unknown[]): unknown;
	loading?: boolean;
	deviceType?: string;
	version?: string;
}

const SelectedDeviceCard = ({
	hasCloseButton = true,
	name,
	handleClose,
	loading,
	deviceType,
	version,
}: SelectedDeviceCardProps) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography variant='h5'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	name}
				</Typography>
				<Typography variant='body2'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	deviceType}
				</Typography>
				<Typography variant='body2'>
					{loading ?
						<>
							<LoadingBar />
							<LoadingBar />
						</>
					:	version}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

export default SelectedDeviceCard;
