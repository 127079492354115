import { useEffect } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HeaderWithStatus } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import { useStyles } from '../style';

interface CommentCardProps {
	open?: boolean;
	bookingData?: object;
	policies?: object;
	setComment?(...args: unknown[]): unknown;
	comment?: string;
	commentRequired?: boolean;
	setCommentRequired?(...args: unknown[]): unknown;
	unavailabilityData?: object;
	commentLength?: number;
	setCommentLength?(...args: unknown[]): unknown;
}

const CommentCard = (props: CommentCardProps) => {
	const {
		open,

		bookingData,
		policies,
		comment,
		setComment,
		commentLength,
		setCommentLength,
		commentRequired,
		setCommentRequired,
		unavailabilityData,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const maxLength = 200;

	useEffect(() => {
		if (!open) {
			setCommentRequired(false);
		}
	}, [open]);

	useEffect(() => {
		if (isObject(bookingData)) {
			setComment(bookingData.comment);
			if (bookingData?.comment) {
				setCommentLength(bookingData.comment.length);
			}
		} else if (isObject(unavailabilityData)) {
			setComment(unavailabilityData.adminNote);
			if (unavailabilityData?.adminNote) {
				setCommentLength(unavailabilityData.adminNote.length);
			}
		}
	}, [bookingData, open, unavailabilityData]);

	useEffect(() => {
		if (isObject(policies) && policies.requireReservationComment) {
			setCommentRequired(true);
		} else {
			setCommentRequired(false);
		}
	}, [policies]);

	const handleComment = (event) => {
		setCommentLength(event.target.value.length);
		setComment(event.target.value);
	};

	return (
		<Box pb={1.5} pt={1.5}>
			<Box pb={1.5} pt={0.5}>
				<HeaderWithStatus
					header={`${t('views.planboard.addBooking.comment')}${commentRequired ? '*' : ''}`}
					headerSize='h5'
					justifyContent='space-between'
					text={`${commentLength}/${maxLength}`}
					variantText='body2'
				/>
			</Box>
			<TextField
				className={classes.description}
				disabled={
					isObject(bookingData) &&
					(bookingData.status === 'completed' || bookingData.status === 'active')
				}
				fullWidth={true}
				inputProps={{ maxLength: maxLength }}
				maxRows={3}
				minRows={3}
				multiline
				onChange={handleComment}
				value={comment}
				variant='outlined'
			/>
			<Box display='flex' pt={1}>
				<Box pr={0.5}>
					<ErrorOutlineIcon color='error' fontSize='small' />
				</Box>
				<Typography variant='body2'>{t('views.planboard.addBooking.commentVisible')}</Typography>
			</Box>
		</Box>
	);
};

export default CommentCard;
