import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import { useStyles } from './style';
import { isFullString } from '../../../../../../shared/utility';

interface GeneralInfoProps {
	className?: string;
	userGroup: object;
}

const GeneralInfo = (props: GeneralInfoProps) => {
	const { userGroup, className } = props;
	const auth = useAuth();
	const { t } = useTranslation('general');

	const classes = useStyles();

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const tableRows = [
		{ label: t('ui.label.name'), content: userGroup.name },
		...(userGroup.description ?
			[{ label: t('ui.label.description'), content: userGroup.description }]
		:	[]),
		...(superAdmin ?
			[{ label: t('ui.label.organisation'), content: userGroup.organisationReference.name }]
		:	[]),
		{ label: t('ui.category.users'), content: userGroup.totalUsers },
		{
			label: t('views.userManagement.userGroup.details.label.itemGroup'),
			content: userGroup.totalItems,
		},
		{
			label: t('views.userManagement.userGroup.details.label.item'),
			content: userGroup.totalItemInstances,
		},
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.policyDetail.summary.cardHeaders.general')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{tableRows.map((tableRow, index) => (
							<TableRow className={classes.tableRow} key={`table-row-${index}`}>
								<TableCell>{tableRow.label}</TableCell>
								<TableCell>{tableRow.content}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default GeneralInfo;
