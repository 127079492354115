import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
	Typography,
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isFullArray } from '../../../../shared/utility';
import { useStyles } from '../../style';

interface ActionsProps {
	dashboardActions?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	dashboardBookings?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const Actions = (props: ActionsProps) => {
	const { dashboardActions } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { data: dashboardActionsData } = dashboardActions;

	const classes = useStyles();

	const actionTyps = {
		ticket: '/ticketing/open',
		bookingApproval: '/bookings',
		bookingOvertime: '/bookings',
		itemsUnavailable: '/item-management/items',
		contractApproval: '/contracts/contracts',
		organisationApproval: '/organisation-management/incoming-requests',
		addLocation: '/location-management/location/add',
		driversLicenseApproval: '/licenses/pending',
		partnershipOpenRequest: '/partnerships/incoming-requests',
		addPolicy: '/policy-management/item-policies/add',
		addTerms: '/policy-management/terms-and-conditions/add',
		addPriceModel: '/policy-management/pricing/add',
		addItem: '/item-management/items/add',
		addItemInstance: '/item-management/items',
		bookingRequireAttention: '/bookings',
	};

	const handleActionType = (type) => () => {
		navigate(actionTyps[type]);
		if (type === 'bookingOvertime') {
			sessionStorage.setItem('bookingStatusFilter', 'overtime');
		} else if (type === 'contractApproval') {
			sessionStorage.setItem('contractStatusFilter', 'pendingAdmin');
		} else if (type === 'ticket') {
			sessionStorage.setItem('ticketStatusFilter', 'open');
		} else if (type === 'bookingApproval') {
			sessionStorage.setItem('bookingStatusFilter', 'pending');
		} else if (type === 'bookingRequireAttention') {
			sessionStorage.setItem('bookingStatusFilter', 'attention');
		}
	};

	return (
		<Card className={classes.card}>
			<CardHeader title={t('ui.label.actions')} />
			<Divider />
			<CardContent>
				<List sx={{ padding: 0 }}>
					{isFullArray(dashboardActionsData) &&
						dashboardActionsData.map((action, index) => (
							<Box key={action.order}>
								<ListItem
									sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}
								>
									<Box display='flex'>
										<Typography
											className={classes.actionsHeader}
											onClick={handleActionType(action.type)}
										>
											{action.title}
										</Typography>
										{action?.total ?
											<Box
												alignItems='center'
												alignSelf='flex-start'
												className={classes.buble}
												sx={{ marginLeft: 'auto' }}
											>
												<Typography color='error'> {action.total}</Typography>
											</Box>
										:	null}
									</Box>
									<IconButton onClick={handleActionType(action.type)}>
										<KeyboardArrowRightIcon />
									</IconButton>
								</ListItem>
								{dashboardActionsData.length - 1 === index ? null : <Divider />}
							</Box>
						))}
				</List>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		dashboardActions: state.list.dashboardActions,
	};
};

export default connect(mapStateToProps, null)(Actions);
