import { useEffect, useMemo } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import { queryParameters } from '~constants';

const searchParamDateFormat = 'YYYYMMDD';

// TODO: generic typing for dayjs and date
// <T extends Dayjs | Date>
const useUrlSearchParamsDate = (
	defaultDate: Dayjs | undefined = dayjs(),
	type: 'date' = 'date',
): [Dayjs, (value: Dayjs) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	/**
	 * Try to parse the date from a string
	 * @param dateString
	 * @returns
	 */
	const parseDate = (dateString: string) => {
		let date = dayjs(dateString, searchParamDateFormat);
		if (!date.isValid()) {
			date = dayjs(dateString);
		}

		if (!date.isValid() || date == null) {
			return defaultDate;
		}

		return date;
	};

	const date = useMemo(() => {
		const dateSearchParam = searchParams.get(queryParameters.Date);

		if (dateSearchParam != null) {
			return parseDate(dateSearchParam);
		}

		return defaultDate;
	}, [searchParams]);

	const setDate = (value?: Dayjs) => {
		if (!value) {
			searchParams.delete(queryParameters.Date);
		} else if (type === 'date') {
			const dateString = value.format(searchParamDateFormat);

			searchParams.set(queryParameters.Date, dateString);
		}

		setSearchParams(searchParams);
	};

	return [date, setDate];
};

export default useUrlSearchParamsDate;
