import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { useAuthorize } from '~features/authentication';

import OtherActions from './OtherActions';
import TermInformation from './TermInformation';
import { isUndefined, handleCultureCode } from '../../../../../shared/utility';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

const PREFIX = 'Summary';

const classes = {
	root: `${PREFIX}-root`,
};

const StyledSummaryLoading = styled(SummaryLoading)(() => ({
	[`& .${classes.root}`]: {
		flexGrow: 1,
	},
}));

interface SummaryProps {
	className?: string;
	itemData?: object;
	loading?: boolean;
	imagesData?: unknown[];
	imagesLoading?: boolean;
	hubInfo?: object;
	priceData?: object;
	termData?: object;
}

const Summary = (props: SummaryProps) => {
	const { className, loading, termData } = props;
	const { isSuperAdmin } = useAuthorize();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return termData && !loading ?
			<>
				<Grid className={clsx(classes.root, className)} container spacing={3}>
					{isSuperAdmin() ?
						<Grid {...gridProps}>
							<TermInformation termData={termData} />
						</Grid>
					:	null}
					{!isUndefined(termData.termsLocalizations) ?
						termData.termsLocalizations.map((pdf) => {
							const data = {
								url: pdf.url,
								language: handleCultureCode(pdf.cultureCode),
								name: termData.organisationReference.name,
							};
							return (
								<Grid key={pdf.cultureCode} {...gridProps}>
									<OtherActions termData={data} />
								</Grid>
							);
						})
					:	null}
				</Grid>
			</>
		:	<StyledSummaryLoading />;
};

export default Summary;
