import { Tooltip } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { StyledButton } from '../../../../components';

interface ExtraButtonsProps {
	buttons: unknown[];
}

const ExtraButtons = (props: ExtraButtonsProps) => {
	const { buttons } = props;

	const classes = useStyles();
	return (
		<div className={clsx(classes.root)}>
			{buttons.map((button, index) => (
				<Tooltip
					arrow
					disableHoverListener={button?.hover}
					key={index}
					title={button?.tooltipTitle ? button?.tooltipTitle : ''}
				>
					<div>
						<StyledButton
							className={classes.extraButton}
							size='medium'
							startIcon={button.icon}
							{...(button.variant && { variant: button.variant })}
							disabled={button.disabled}
							onClick={button.onClick}
						>
							{button.text}
						</StyledButton>
					</div>
				</Tooltip>
			))}
		</div>
	);
};

export default ExtraButtons;
