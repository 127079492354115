import clsx from 'clsx';

import { useStyles } from './style';

interface SidepanelContainerProps {
	className?: string;
	children: React.ReactNode;
	sticky?: boolean;
}

const SidepanelContainer = (props: SidepanelContainerProps) => {
	const { sticky, children, className } = props;

	const classes = useStyles();

	return (
		<div
			className={clsx(
				{
					[classes.root]: true,
					[classes.sticky]: sticky,
				},
				className,
			)}
		>
			{children}
		</div>
	);
};

export default SidepanelContainer;
