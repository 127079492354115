import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, IconButton, CircularProgress } from '@mui/material';

import VerificationIcon from '../../../../../assets/icons/label-filled-icon-success.svg';
import WarningIcon from '../../../../../assets/icons/label-filled-icon-warning@3x.png';
import { Label, IconInfoLabel } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { isNumber } from '../../../../../shared/utility';
import { useStyles } from '../../../style';

interface AttentionProps {
	mileage?: number;
	attention?: boolean;
	info?: string;
	time?: string;
	editUser?: string;
	showIcon?: boolean;
	onClick?(...args: unknown[]): unknown;
	loading?: boolean;
}

const Attention = (props: AttentionProps) => {
	const { mileage, attention, time, info, editUser, showIcon, onClick, loading } = props;

	const classes = useStyles();

	const infoProps = {
		icon: <img alt='warning-icon' height='16' src={WarningIcon} width='16' />,
		infoTitle: info,
		showIcon: attention,
	};

	const editUserInfoProp = {
		icon: <img alt='verification-icon' height='16' src={VerificationIcon} width='16' />,
		infoTitle: editUser,
		showIcon: showIcon,
	};

	const isLoading = attention && loading;

	return (
		<Box className={classes.attentionCard}>
			{isNumber(mileage) ? mileage : '-'}
			<IconInfoLabel {...infoProps} />
			{editUser ?
				<IconInfoLabel {...editUserInfoProp} />
			:	null}
			{attention && time ?
				<Label className={classes.attention}>{commaTimeStrings(time)}</Label>
			:	null}
			{!loading && attention ?
				<IconButton disabled={loading} onClick={onClick}>
					<RefreshIcon />
				</IconButton>
			:	null}
			{isLoading && <CircularProgress className={classes.circularLoading} size={24} color='info' />}
		</Box>
	);
};

export default Attention;
