import { useRef, useEffect, useCallback } from 'react';

import { Box, ClickAwayListener, Paper, List, ListItem, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Input, InputPopper, InputList, LoadingContent } from './components';
import { useStyles } from './style';
import {
	isEmptyArray,
	isEmptyString,
	isFullString,
	isNull,
	isObject,
} from '../../../shared/utility';

interface LazyInputProps {
	onResetState?(...args: unknown[]): unknown;
	data?: object;
	state?: object;
	setState?(...args: unknown[]): unknown;
	placeholder?: string;
	fetchList?(...args: unknown[]): unknown;
	stateType?: string;
}

const LazyInput = (props: LazyInputProps) => {
	const { onResetState, data, state, setState, placeholder, stateType, fetchList } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const inputRef = useRef(null);

	const refStartOfList = useRef();

	const refEndOfList = useRef();

	const handleClick = () =>
		setState((prevState) => ({
			...prevState,
			...(!prevState.openPopper && { fetch: true, loading: true }),
			openPopper: true,
		}));

	const handleChange = (event) => {
		const value = event.target.value;
		setState((prevState) => ({
			...prevState,
			...(isFullString(prevState.name) && isEmptyString(value) ?
				{ fetch: true, openPopper: true }
			:	{ openPopper: false }),
			name: value,
			search: true,
		}));
		onResetState();
	};

	const handleClickAway = () => {
		setState((prevState) => ({ ...prevState, loading: false, openPopper: false }));
		inputRef.current.blur();
		onResetState();
		if (isEmptyString(state.name)) {
			onResetState();
		}
	};

	const handleSelect = (item) => {
		if (item.name === state.name) {
			setState((prevState) => ({ ...prevState, openPopper: false }));
			return;
		}
		sessionStorage.setItem(stateType, JSON.stringify(item));
		setState((prevState) => ({ ...prevState, name: item.name, id: item.id }));
		setState((prevState) => ({ ...prevState, openPopper: false }));
		onResetState();
		fetchList();
	};

	useEffect(() => {
		if (data?.results && inputRef?.current) {
			setState((prevState) => ({ ...prevState, loading: false, search: false, openPopper: true }));
		}
		if (isEmptyArray(data?.results)) {
			setState((prevState) => ({ ...prevState, openPopper: true }));
		}
	}, [data, inputRef]);

	const handleLazyLoading = () =>
		setState((prevState) => ({
			...prevState,
			loading: true,
			fetch: true,
			pageNumber: prevState.pageNumber + 1,
		}));

	const lazyLoadMore = useCallback(() => {
		if (refEndOfList?.current && isObject(data) && data?.hasMore) {
			const bcrStart = refStartOfList.current.getBoundingClientRect();
			const bcrEnd = refEndOfList.current.getBoundingClientRect();

			if (bcrEnd.top + bcrEnd.height - 250 < bcrStart.top + bcrStart.height && !state.loading) {
				handleLazyLoading();
			} else if (!data?.hasMore && state.loading) {
				setState((prevState) => ({ ...prevState, loading: true }));
			}
		}
	}, [data, state.loading]);

	useEffect(() => {
		if (isObject(data) && data.hasMore && document.querySelector(`.${classes.selectResults}`)) {
			document.querySelector(`.${classes.selectResults}`).addEventListener('scroll', lazyLoadMore);
		}

		return () => {
			if (document.querySelector(`.${classes.selectResults}`)) {
				document
					.querySelector(`.${classes.selectResults}`)
					.removeEventListener('scroll', lazyLoadMore);
			}
		};
	}, [lazyLoadMore, classes.selectResults, data, state.loading, state.openPopper]);

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div className={classes.searchInput}>
				<Input
					value={state.name}
					loading={state.loading}
					inputRef={inputRef}
					openPopper={state.openPopper}
					onChange={handleChange}
					onClick={handleClick}
					placeholder={placeholder}
				/>
				<InputPopper openPopper={state.openPopper} inputRef={inputRef}>
					<Paper className={classes.popper}>
						<span ref={refStartOfList} />
						{isNull(data) && state.loading && <List>{<LoadingContent />}</List>}
						{data?.total > 0 && (
							<InputList total={data?.totel}>
								{data?.results?.map((result) => (
									<ListItem
										component={Button}
										// className={clsx({ [classes.active]: index === activeIndex, [classes.listItem]: true })}
										key={result.id}
										onClick={() => handleSelect(result)}
									>
										<Box>
											<Typography variant='subtitle2'>{result.name}</Typography>
										</Box>
									</ListItem>
								))}
								{data.hasMore && state.loading && <LoadingContent />}
								<span ref={refEndOfList} />
							</InputList>
						)}
						{isEmptyArray(data?.results) && (
							<div className={classes.empty}>
								{<Typography>{t('ui.noResultsFound')}</Typography>}
							</div>
						)}
					</Paper>
				</InputPopper>
			</div>
		</ClickAwayListener>
	);
};

export default LazyInput;
