import {
	ChangeEvent,
	DragEvent,
	forwardRef,
	MouseEvent,
	PropsWithChildren,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';

import { Close as CloseIcon, FileUpload as FileUploadIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

/**
 * TODO: different naming
 */
interface SelectedFile {
	file: File | null;
	uri: string | null;
}

export type DragAndDropFilesUploaderProps = {
	title?: string;
	readonly?: boolean;
	src?: string | null;
	maxImageSizeInBytes?: number;
	accept?: string;
	multiple?: boolean; // allow selecting multiple files
	/**
	 *
	 * @param file The image as file
	 * @param uri
	 * @returns
	 */
	onChange?: (images: SelectedFile[]) => void;
	onError?: (error: Error) => void;
	clearable?: boolean;
} & PropsWithChildren;

export interface DragAndDropFilesUploaderRef {
	openFilePicker: () => void;
}

const DragAndDropFilesUploader = forwardRef<
	DragAndDropFilesUploaderRef,
	DragAndDropFilesUploaderProps
>(
	(
		{
			children,
			maxImageSizeInBytes = 1024000,
			clearable = true,
			multiple = false,
			readonly = false,
			title,
			...props
		},
		ref,
	) => {
		const { t } = useTranslation('general');
		const [error, setError] = useState<Error | null>(null);

		const fileInputRef = useRef<HTMLInputElement | null>(null);

		useImperativeHandle(ref, () => ({
			openFilePicker: openFilePicker,
		}));

		const handleDrop = (event: DragEvent<HTMLDivElement>) => {
			event.preventDefault();

			const files: SelectedFile[] = [];
			for (const file of event.dataTransfer.files) {
				files.push({
					file: file,
					uri: URL.createObjectURL(file),
				});
			}

			props.onChange?.(files);
		};

		const openFilePicker = () => {
			if (fileInputRef.current?.value) {
				fileInputRef.current.value = '';
			}
			fileInputRef.current?.click();
		};

		const handleClick = (event: MouseEvent<HTMLDivElement>) => {
			if (props.src != null) {
				return;
			}

			event.preventDefault();
			openFilePicker();
		};

		const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
			setError(null);

			if (event.target.files == null || event.target.files.length <= 0) {
				props.onChange?.([]);
				return;
			}

			const files: SelectedFile[] = [];
			for (const file of event.target.files) {
				if (maxImageSizeInBytes != null && file.size > maxImageSizeInBytes) {
					props.onError?.(
						new Error(
							t('fileSizeTooLargeWithValue', {
								value: `${(maxImageSizeInBytes / 1048576).toFixed(1)}MB`,
							}),
						),
					);
					setError(new Error(t('fileSizeTooLarge')));
					return;
				}

				files.push({
					file: file,
					uri: URL.createObjectURL(file),
				});
			}

			props.onChange?.(files);
		};

		// TODO: parent component should be able to control the height
		const image = (
			<img src={props.src} style={{ maxHeight: 290, width: 'auto' }} draggable={false} />
		);

		if (readonly) {
			return (
				<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: 1 }}>
					{image}
				</Box>
			);
		}

		return (
			<>
				<div
					onClick={handleClick}
					onDrop={handleDrop}
					onDragOver={(event) => event.preventDefault()}
				>
					{(children ?? props.src != null) ?
						<Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: 1 }}>
							{image}
							{clearable && (
								<IconButton
									onClick={() => props.onChange?.([])}
									sx={{
										position: 'absolute',
										top: 0,
										right: 0,
									}}
								>
									<CloseIcon />
								</IconButton>
							)}
						</Box>
					:	<DragAndDropUploaderBox
							title={title}
							maxImageSizeInBytes={maxImageSizeInBytes}
							error={error}
						/>
					}
				</div>
				<input
					ref={fileInputRef}
					type='file'
					onChange={handleSelectFile}
					accept={props.accept}
					multiple={multiple}
					style={{ display: 'none' }}
				/>
			</>
		);
	},
);

interface DragAndDropFilesUploaderBox {
	title?: string;
	maxImageSizeInBytes: number;
	error?: Error | null;
}

const DragAndDropUploaderBox = ({
	maxImageSizeInBytes = 1024000,
	title = i18n.t('ui.uploadImage'),
	...props
}: DragAndDropFilesUploaderBox) => {
	const { t } = useTranslation('general');
	const theme = useTheme();

	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{
				p: 3,
				alignItems: 'center',
				border: `1px dashed ${theme.palette.grey[200]}`,
				'&:hover': {
					borderColor: theme.palette.grey[400],
					cursor: 'pointer',
				},
			}}
		>
			<FileUploadIcon fontSize='large' sx={{ color: theme.palette.grey[400] }} />
			<Typography variant='h5'>{title}</Typography>
			<Typography variant='body2' color={props.error != null ? 'error' : undefined}>
				{`${t('ui.maxFileSize')}: ${(maxImageSizeInBytes / 1048576).toFixed(1)}MB`}
			</Typography>
			{props.error && (
				<Typography variant='body2' color={props.error != null ? 'error' : undefined}>
					{props.error.message}
				</Typography>
			)}
		</Stack>
	);
};

export default DragAndDropFilesUploader;
