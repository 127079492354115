import { useEffect } from 'react';

import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { usePrevious } from '../../../../shared/hooks';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import AddTermsAndConditions from '../../ActionFlows/AddTermsAndConditions';

interface EditTermsProps {
	fetchedTerm?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchTerm?(...args: unknown[]): unknown;
}

const EditTerms = (props: EditTermsProps) => {
	const { fetchedTerm, onFetchTerm } = props;
	const location = useLocation();

	const { id } = useParams();

	const { data: fetchedTermData, loading: fetchedTermLoading } = fetchedTerm;

	const termLoading = usePrevious(fetchedTermLoading);

	const data = termLoading && fetchedTermData;

	useEffect(() => {
		if (isObject(fetchedTermData) && id !== fetchedTermData.id) {
			onFetchTerm(id);
		}
	}, [id]);

	useEffect(() => {
		if (!isObject(fetchedTermData) && id) {
			onFetchTerm(id);
		}
	}, [fetchedTermData, id]);

	return isObject(data) ?
			<AddTermsAndConditions
				detailEdit={location.state.detail}
				editTermData={data}
				editing={true}
			/>
		:	null;
};

const mapStateToProps = (state) => {
	return {
		fetchedTerm: state.details.fetchTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTerm: (id) => dispatch(actions.fetchTerm(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTerms);
