import { useState, useEffect } from 'react';

import {
	Avatar,
	Box,
	Card,
	CardHeader,
	CardContent,
	Checkbox,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { EmptyState, Paginate, LoadingBar, Search } from '../../../../../components';
import { useSearchComponent } from '../../../../../shared/hooks';
import { isObject, isEmptyString, isEmptyArray, getInitials } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface SelectPartnerOrganisationProps {
	selectedPartners?: unknown[];
	partnerOrganisations?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPartnerships?(...args: unknown[]): unknown;
	save?(...args: unknown[]): unknown;
}

const SelectPartnerOrganisation = (props: SelectPartnerOrganisationProps) => {
	const {
		save,
		partnerOrganisations,
		onFetchPartnerships,
		selectedPartners: savedSelectedPartners,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [selectedPartners, setSelectedPartners] = useState(savedSelectedPartners);

	useEffect(() => {
		setSelectedPartners(savedSelectedPartners);
	}, [savedSelectedPartners]);

	useEffect(() => {
		save({ selectedPartners });
	}, [selectedPartners]);

	const {
		data: partnersData,
		loading: partnersLoading,
		error: partnersError,
	} = partnerOrganisations;
	const partnersReady = isObject(partnersData) && !partnersLoading && !partnersError;

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [shouldSearch, setShouldSearch] = useState(false);

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize] = useState(5);

	const search = useSearchComponent(setPageNumber, setShouldSearch, true);

	/* * * * * * *
	 * GET DATA  *
	 * * * * * * */
	useEffect(() => {
		const page = { size: pageSize, number: pageNumber };

		const filters = {
			...(!isEmptyString(search.value) && { searchTerm: search.value }),
			status: 'accepted',
		};
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchPartnerships(page, filters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [
		onFetchPartnerships,
		shouldSearch,
		shouldDoInitialFetch,
		showingInitialResults,
		pageNumber,
		pageSize,
	]);

	const handleSelectOne = (partner) => {
		const selectedIndex = selectedPartners
			.map((selectedPartner) => selectedPartner.partnerOrganisation.id)
			.indexOf(partner.partnerOrganisation.id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedPartners, partner);
		} else {
			newSelected = newSelected.concat(
				selectedPartners.slice(0, selectedIndex),
				selectedPartners.slice(selectedIndex + 1),
			);
		}

		setSelectedPartners(newSelected);
	};

	const handlePageChange = (page) => {
		setPageNumber(page.selected + 1);
		setShouldDoInitialFetch(true);
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const partnersListEmpty =
		isObject(partnersData) && !partnersLoading && isEmptyArray(partnersData.results) ?
			showingInitialResults ?
				<EmptyState
					callToAction={'/partnerships/partners/add'}
					callToActionText={t('views.partnerships.button.addPartner')}
					image={'booking'}
					subTitle={t('views.Partnerships.Requests.noPartners')}
					title={t('views.Partnerships.Requests.notFound')}
				/>
			:	<EmptyState
					callToAction={'/partnerships/partners/add'}
					callToActionText={t('views.partnerships.button.addPartner')}
					image={'booking'}
					subTitle={t('views.Partnerships.Requests.zeroMatching')}
					title={t('views.Partnerships.Requests.notFound')}
				/>
		:	null;

	return (
		<Card>
			<CardHeader
				title={
					<Typography variant='h4'>
						{t('views.assignItemsToPartner.blockHeader.selectPartners')}
					</Typography>
				}
			/>
			<CardContent>
				<Search
					className={classes.search}
					events={search.events}
					placeholder={t('views.assignItemsToPartner.search.placeholder')}
					value={search.value}
				/>
				<Box className={classes.listContainer}>
					{partnersListEmpty ?
						partnersListEmpty
					:	<>
							<List>
								{partnersReady ?
									partnersData.results.map((partner) => (
										<ListItem
											className={classes.listItem}
											key={`user-group-${partner.partnerOrganisation.id}`}
										>
											<Box className={classes.partnerDetails} flex={1}>
												{partner.partnerOrganisation.logo ?
													<Avatar
														classes={{ root: classes.avatar }}
														src={partner.partnerOrganisation.logo}
													/>
												:	<Avatar classes={{ root: classes.avatar }}>
														{getInitials(partner.partnerOrganisation.name)}
													</Avatar>
												}
												<Typography variant='h5'>{partner.partnerOrganisation.name}</Typography>
											</Box>
											<Box>
												<Checkbox
													checked={
														selectedPartners
															.map((sPartner) => sPartner.partnerOrganisation.id)
															.indexOf(partner.partnerOrganisation.id) !== -1
													}
													color='primary'
													onChange={() => handleSelectOne(partner)}
												/>
											</Box>
										</ListItem>
									))
								:	Array(pageSize)
										.fill(Array(2).fill())
										.map((row, index) => (
											<ListItem
												className={clsx(classes.loadingListItem, classes.listItem)}
												key={`loading-item-${index}`}
											>
												{row.map((_, index) => (
													<LoadingBar key={`loading-bar-${index}`} />
												))}
											</ListItem>
										))
								}
							</List>
							{partnersReady ?
								partnersData.total / pageSize > 1 ?
									<div className={classes.paginationContainer}>
										<Paginate
											forcePage={pageNumber - 1}
											onPageChange={handlePageChange}
											pageCount={partnersData.total / pageSize}
										/>
									</div>
								:	null
							:	null}
						</>
					}
				</Box>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		partnerOrganisations: state.paged.fetchPartnerships,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchPartnerships: (page, filters) => dispatch(actions.fetchPartnerships(page, filters)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPartnerOrganisation);
