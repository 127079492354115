import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';

interface SelectedOrganisationCardProps {
	className?: string;
	name?: string;
	handleClose?(...args: unknown[]): unknown;
	hasCloseButton?: boolean;
}

const SelectedOrganisationCard = (props: SelectedOrganisationCardProps) => {
	const { className, name, handleClose, hasCloseButton } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>{name}</Typography>
			</CardContent>
			{hasCloseButton ?
				<span></span>
			:	<IconButton onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			}
		</Card>
	);
};

export default SelectedOrganisationCard;
