import { useState } from 'react';

import { Box } from '@mui/material';

import { InfoPopup } from '../../../../components';

interface CalendarInfoPopupProps {
	heading?: string;
	title?: string;
}

const CalendarInfoPopup = (props: CalendarInfoPopupProps) => {
	const { heading, title } = props;

	const [isHover, setIsHover] = useState(false);

	const handleClosePopup = () => {
		setIsHover(false);
	};

	const handleOpenPopup = () => {
		setIsHover(true);
		setTimeout(handleClosePopup, 3000);
	};

	const infoPopupProps = {
		heading,
		title,
		open: isHover,
	};

	return (
		<InfoPopup {...infoPopupProps}>
			<Box
				className={'infoPopupHoverSpan'}
				onClick={handleClosePopup}
				onMouseLeave={handleClosePopup}
				onMouseOver={handleOpenPopup}
			/>
		</InfoPopup>
	);
};

export default CalendarInfoPopup;
