import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style.js';
import Bar from '../../loading/Bar/Bar.js';

interface BasicSelectedCardProps {
	className?: string;
	name?: string;
	loading?: boolean;
	handleClose?(...args: unknown[]): unknown;
	hasCloseButton?: boolean;
}

const BasicSelectedCard = (props: BasicSelectedCardProps) => {
	const { className, name, loading, handleClose, hasCloseButton } = props;
	const classes = useStyles();

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>
					{loading ?
						<Bar />
					:	name}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

export default BasicSelectedCard;
