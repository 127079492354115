import ActiveFinance from './ActiveFinance';
import History from './History';

interface RevenueProps {
	isSuperAdmin?: boolean;
}

const Revenue = (props: RevenueProps) => {
	const { isSuperAdmin } = props;

	return (
		<>
			<ActiveFinance isSuperAdmin={isSuperAdmin} />
			<History isSuperAdmin={isSuperAdmin} />
		</>
	);
};

export default Revenue;
