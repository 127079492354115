import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import ItemPolicies from './ItemPolicies';
import Pricing from './Pricing';
import { useStyles } from './style';
import TermsAndConditions from './TermsAndConditions';
import { Page } from '../../components';

interface PolicyManagementProps {}

const PolicyManagement = (props: PolicyManagementProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { tab } = useParams();

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'item-policies', label: t('ui.category.itemPolicies') },
		{ value: 'terms-and-conditions', label: t('ui.category.termsAndConditions') },
		{ value: 'pricing', label: t('ui.category.pricingModels') },
	];

	const headerButtons = [
		{ key: 'item-policies' },
		{ key: 'terms-and-conditions' },
		{ key: 'pricing' },
	];

	return (
		<Page className={classes.root} title={t('views.policies.page.title')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'item-policies' && <ItemPolicies />}
				{tab === 'terms-and-conditions' && <TermsAndConditions />}
				{tab === 'pricing' && <Pricing />}
			</div>
		</Page>
	);
};

export default PolicyManagement;
