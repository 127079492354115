import { useState, useEffect } from 'react';

import MoneyIcon from '@mui/icons-material/Money';
import {
	Box,
	Card,
	CardActions,
	CardHeader,
	CardContent,
	CircularProgress,
	Divider,
	Switch,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useSWRMutation from 'swr/mutation';

import { useAuthorize } from '~features/authentication';
import { OrganisationsService } from '~features/organisations';
import { useSnackbar } from '~hooks';

import { useStyles } from './style';
import {
	SubscriptionSelectField,
	AlertDialog,
	StyledButton,
	Label,
} from '../../../../../../components';
import { useError, useWizardFormField } from '../../../../../../shared/hooks';
import { isObject, isArray, isEmptyObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

const service = new OrganisationsService();

interface SubscriptionProps {
	className?: string;
	organisation: object;
	patchedOrganisation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	subscriptions?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	publicItems?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateEmailDomainLinking?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	onPatchUpdateOrganisation?(...args: unknown[]): unknown;
	onFetchOrganisation?(...args: unknown[]): unknown;
	onFetchPublicItems?(...args: unknown[]): unknown;
	onUpdateEmailDomainLinking?(...args: unknown[]): unknown;
}

const Subscription = (props: SubscriptionProps) => {
	const {
		organisation,
		className,

		onPatchUpdateOrganisation,
		patchedOrganisation,
		subscriptions,
		onFetchOrganisation,

		onFetchPublicItems,
		publicItems,
		updateEmailDomainLinking,
		onUpdateEmailDomainLinking,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const { enqueueSnackbar, enqueueAxiosErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();
	const orgSubscriptions =
		isObject(organisation) && isObject(organisation.subscription) && organisation.subscription;
	const isSupplierSubscription =
		orgSubscriptions && orgSubscriptions.subscriptionType === 'supplier';

	const { data: subsData, loading: subsLoading, error: subsError } = subscriptions;
	const subscriptionsReady = isArray(subsData) && !subsLoading && !subsError;

	const {
		data: patchOrgData,
		loading: patchOrgLoading,
		error: patchOrgError,
	} = patchedOrganisation;
	const patchOrganisationDone = isObject(patchOrgData) && !patchOrgLoading && !patchOrgError;

	const {
		data: publicItemsData,
		loading: publicItemsLoading,
		error: publicItemsError,
	} = publicItems;
	const publicItemsReady = isObject(publicItemsData) && !publicItemsLoading && !publicItemsError;

	const subscription = useWizardFormField(
		isObject(orgSubscriptions) ? `${orgSubscriptions.id}` : '',
		{ required: true },
	);
	const [chosenSubscription, setChosenSubscription] = useState({});
	const [openDialog, setOpenDialog] = useState(false);
	const [updatingOrganisation, setUpdatingOrganisation] = useState(false);

	const { loading: updateEmailDomainLinkingLoading } = updateEmailDomainLinking;

	const { trigger: triggerSsoOnly, isMutating: isSsoOnlyMutating } = useSWRMutation(
		[`${service.basePath}.ssoOnly`, organisation.id.toString()],
		([_, id], { arg }) => service.setSsoOnlyAccess(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('ssoSignupChangeSuccess'));
				setUpdatingOrganisation(false);
				onFetchOrganisation(organisation.id);
			},
			onError: (error: AxiosError) => {
				console.log(error.response?.data?.message);
				enqueueAxiosErrorSnackbar(error, error.response?.data?.message);
			},
		},
	);

	const [emailDomainLinkingEnabled, setEmailDomainLinkingEnabled] = useState(
		organisation?.features.includes('emailDomains'),
	);

	useEffect(() => {
		setEmailDomainLinkingEnabled(organisation?.features.includes('emailDomains'));
	}, [organisation?.features.includes('emailDomains')]);

	const updateEmailDomainLinkingMessage = useError({
		value: updateEmailDomainLinking,
		message: t('views.organisationDetail.summary.updatedEmailDomainLinking.success'),
	});

	useEffect(() => {
		onFetchPublicItems(organisation.id);
	}, []);

	useEffect(() => {
		if (updatingOrganisation && patchOrganisationDone) {
			setUpdatingOrganisation(false);
			setOpenDialog(false);
			onFetchOrganisation(organisation.id);
			enqueueSnackbar(
				`${t('views.organisationDetail.summary.subscription.message.success')} ${organisation.name}`,
				{ variant: 'success' },
			);
		}
	}, [updatingOrganisation, patchOrganisationDone]);

	useEffect(() => {
		if (updatingOrganisation && !patchOrgLoading && !!patchOrgError) {
			setUpdatingOrganisation(false);
			setOpenDialog(false);
			enqueueSnackbar(isObject(patchOrgError) ? patchOrgError.message : patchOrgError, {
				variant: 'error',
			});
		}
	});

	const handleChangeSubscription = (e) => {
		if (subscriptionsReady) {
			const subIndexArray = subscriptions.data.map((sub) => `${sub.id}`);
			const subIndex = subIndexArray.indexOf(e.target.value);
			setChosenSubscription(subsData[subIndex]);
			setOpenDialog(true);
		}
	};

	const handleClickUpdateSubscription = () => {
		window.open('https://www.topology.nl/contact');
		// if (orgSubscriptions && orgSubscriptions.subscriptionType === 'supplier') {
		//   window.open('https://topology.nl/aanbieders#page-section-608fc736a35f170e6972b43f');
		// } else {
		//   window.open('https://topology.nl/bedrijven#page-section-608bc6f8ebfd05223ad02870');
		// }
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = () => {
		onPatchUpdateOrganisation(organisation.id, { subscriptionId: chosenSubscription.id });
		setUpdatingOrganisation(true);
	};

	const handleChangeEmailDomainPairing = () => {
		onUpdateEmailDomainLinking(organisation.id, !emailDomainLinkingEnabled);
		updateEmailDomainLinkingMessage.setStartAction(true);
	};

	const alertDialogContent =
		subscriptionsReady && !isEmptyObject(chosenSubscription) ?
			(
				isSupplierSubscription &&
				chosenSubscription.subscriptionType === 'organisation' &&
				publicItemsReady &&
				publicItemsData.results.length > 0
			) ?
				<Box>
					<Typography variant='body2'>
						{t('views.organisationDetail.summary.alertDialog.subscription.description.warning')}
					</Typography>
				</Box>
			:	<Box>
					<Typography variant='body2'>{`${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partOne')} ${orgSubscriptions ? `${orgSubscriptions.name} (${t(`ui.subscriptionType.${orgSubscriptions.subscriptionType}`)})` : t('ui.none')} ${t('views.organisationDetail.summary.alertDialog.subscription.description.detail.partTwo')} ${chosenSubscription.name} (${t(`ui.subscriptionType.${chosenSubscription.subscriptionType}`)}).`}</Typography>
					<Typography variant='body2'>
						{t('views.organisationDetail.summary.alertDialog.subscription.description.general')}
					</Typography>
				</Box>
		:	null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.organisationDetail.summary.cardHeaders.subscription')} />
			<Divider />
			<CardContent className={classes.content}>
				{isSuperAdmin() ?
					<SubscriptionSelectField
						className={classes.subscriptionSelect}
						disabled={!publicItemsReady}
						hasFirstSelectionDisabled
						helperText={t('views.organisationDetail.summary.helperText.subscription')}
						variable={{
							...subscription,
							bindToFormField: {
								...subscription.bindToFormField,
								onChange: handleChangeSubscription,
							},
						}}
					/>
				:	null}
				<Table>
					<TableBody>
						{!isSuperAdmin() ?
							<>
								<TableRow>
									<TableCell>{t('ui.label.subscription')}</TableCell>
									<TableCell>
										{orgSubscriptions ?
											orgSubscriptions.name
										:	t('views.organisationDetail.summary.noSubscriptionSelected')}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{t('ui.label.price')}</TableCell>
									<TableCell>
										{orgSubscriptions ?
											`€${orgSubscriptions.price.price}/${t('ui.month').toLowerCase()}`
										:	'-'}
									</TableCell>
								</TableRow>
							</>
						:	null}
						<TableRow>
							<TableCell>{t('ui.label.itemLimit')}</TableCell>
							<TableCell>
								{orgSubscriptions && orgSubscriptions.maxItemInstances !== 999999999 ?
									(
										orgSubscriptions.subscriptionType === 'supplier' &&
										orgSubscriptions.maxItemInstances === 1000
									) ?
										'*'
									:	orgSubscriptions.maxItemInstances
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.userLimit')}</TableCell>
							<TableCell>{'-'}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>
								{t('views.organisationDetail.summary.subscription.emailDomainPairing')}
							</TableCell>
							<TableCell>
								{!isSuperAdmin() ?
									emailDomainLinkingEnabled ?
										<Label type='success'>{t('ui.enabled')}</Label>
									:	<Label type='error'>{t('ui.status.disabled')}</Label>
								: updateEmailDomainLinkingLoading ?
									<CircularProgress color='inherit' disableShrink size={24} />
								:	<Switch
										checked={emailDomainLinkingEnabled}
										color='primary'
										onChange={handleChangeEmailDomainPairing}
										size='small'
									/>
								}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('signupOnlyViaSso')}</TableCell>
							<TableCell>
								{isSsoOnlyMutating ?
									<CircularProgress color='inherit' disableShrink size={24} />
								: isSuperAdmin() ?
									<Switch
										checked={organisation?.features.includes('ssoDomains')}
										color='primary'
										onChange={async () =>
											await triggerSsoOnly(!organisation?.features.includes('ssoDomains'))
										}
										size='small'
									/>
								:	<Label type={organisation?.features.includes('ssoDomains') ? 'success' : 'error'}>
										{t(
											organisation?.features.includes('ssoDomains') ?
												'ui.enabled'
											:	'ui.status.disabled',
										)}
									</Label>
								}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{!isSuperAdmin() ?
				<CardActions>
					<StyledButton
						onClick={handleClickUpdateSubscription}
						startIcon={<MoneyIcon />}
						variant='inline-default'
					>
						{t('view.organisationmanagement.organisationDetails.button.inline.updatesubscription')}
					</StyledButton>
				</CardActions>
			:	null}
			<AlertDialog
				confirmDisabled={
					isSupplierSubscription &&
					chosenSubscription.subscriptionType === 'organisation' &&
					publicItemsReady &&
					publicItemsData.results.length > 0
				}
				dialogContent={alertDialogContent}
				dialogTitle={t('views.organisationDetail.summary.alertDialog.subscription.title')}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				open={openDialog}
			/>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		subscriptions: state.list.subscriptions,
		patchedOrganisation: state.details.patchedOrganisation,

		publicItems: state.paged.items,
		updateEmailDomainLinking: state.condition.updateEmailDomainLinking,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onPatchUpdateOrganisation: (id, properties) =>
			dispatch(actions.patchUpdateOrganisation(id, properties)),
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onFetchPublicItems: (organisationId) =>
			dispatch(
				actions.fetchItems({ number: 1, size: 20 }, { itemAccess: 'public', organisationId }),
			),
		onUpdateEmailDomainLinking: (organisationId, emailDomainLinkingEnabled) =>
			dispatch(actions.updateEmailDomainLinking(organisationId, emailDomainLinkingEnabled)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
