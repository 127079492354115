import { forwardRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DragAndDropFilesUploader, {
	DragAndDropFilesUploaderProps,
	DragAndDropFilesUploaderRef,
} from './dragAndDropFilesUploader';
import ImageCropper, { ImageCropperProps } from './imageCropper';
import Dialog, { DialogProps } from '../dialogs/dialog';

interface DragAndDropImageCropperProps {
	src?: string;
	onChange?: (value: { file: File; uri: string } | null) => void;
	slotProps?: {
		dialog?: Partial<DialogProps>;
		dragAndDropField?: Partial<Omit<DragAndDropFilesUploaderProps, 'src'>>;
		imageCropper?: Partial<Omit<ImageCropperProps, 'src'>>;
	};
}

/**
 * A component to combine the image selecting and cropping.
 *
 */
const DragAndDropImageCropper = forwardRef<
	DragAndDropFilesUploaderRef,
	DragAndDropImageCropperProps
>(({ src, slotProps, ...props }, ref) => {
	const { t } = useTranslation('general');
	const [originalImage, setOriginalImage] = useState<{ file: File; uri: string } | null>(null);
	const [crop, setCrop] = useState<{ x: number; y: number; width: number; height: number } | null>(
		null,
	);

	useEffect(() => {
		return () => {
			// Make sure to clean up the resource in memory
			setOriginalImage(null);
		};
	}, []);

	const reset = () => {
		setOriginalImage(null);
	};

	const handleSave = async () => {
		if (!originalImage || !crop) {
			return;
		}

		const offscreenCanvas = new OffscreenCanvas(crop.width, crop.height);
		const ctx = offscreenCanvas.getContext('2d');

		const image = new Image();
		image.src = originalImage.uri;

		ctx?.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

		const blob = await offscreenCanvas.convertToBlob({
			type: originalImage.file.type,
			// This value is randomly chose, but if we upload a jpg. It is transformed by the
			// canvas to png which raises the size.
			quality: 0.7,
		});
		const file = new File([blob], originalImage.file.name);

		if (
			slotProps?.dragAndDropField?.maxImageSizeInBytes != null &&
			file.size > slotProps?.dragAndDropField?.maxImageSizeInBytes
		) {
			console.warn(`Too large file: ${file.size / 1048576}MB`);
		}

		props.onChange?.({
			file: file,
			uri: URL.createObjectURL(file),
		});
		reset();
	};

	return (
		<>
			<DragAndDropFilesUploader
				accept='image/png, image/jpeg'
				{...slotProps?.dragAndDropField}
				ref={ref}
				src={src}
				onChange={(files) => {
					if (files.length <= 0) {
						setOriginalImage(null);
						props.onChange?.(null);
					} else {
						setOriginalImage(files[0]);
					}
				}}
			/>
			<Dialog
				title={t('cropImage')}
				variant='legacy'
				{...slotProps?.dialog}
				open={originalImage?.uri != null}
				onSave={handleSave}
				onClose={reset}
				disableDefaultActions={false}
				saveLabel={t('ui.confirm')}
				// maxWidth='lg'
			>
				<ImageCropper
					{...slotProps?.imageCropper}
					src={originalImage?.uri}
					onCropChange={(crop) => setCrop(crop)}
				/>
			</Dialog>
		</>
	);
});

export default DragAndDropImageCropper;
