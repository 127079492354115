import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Typography, IconButton } from '@mui/material';

import { useStyles } from './style.js';
import Bar from '../../../../../../components/loading/Bar';

interface SelectedHubCardProps {
	className?: string;
	name?: string;
	loading?: boolean;
	handleClose?(...args: unknown[]): unknown;
	hasCloseButton?: boolean;
	hubData?: object;
}

const SelectedHubCard = (props: SelectedHubCardProps) => {
	const { hubData, hasCloseButton, loading, handleClose } = props;

	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Typography variant='h5'>
					{loading ?
						<Bar />
					:	hubData.name}
				</Typography>
			</CardContent>
			{hasCloseButton ?
				<IconButton className={classes.closeButton} onClick={handleClose} size='small'>
					<CloseIcon />
				</IconButton>
			:	null}
		</Card>
	);
};

export default SelectedHubCard;
