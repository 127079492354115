import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid, Typography, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const PREFIX = 'Asynchronous';

const classes = {
	icon: `${PREFIX}-icon`,
};

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.icon}`]: {
		color: theme.palette.primary,
		marginRight: theme.spacing(1),
	},
}));

interface AutocompleteInputProps {
	options?: unknown[];
	open?: boolean;
	setOpen?(...args: unknown[]): unknown;
	loading?: boolean;
	onChange?(...args: unknown[]): unknown;
	onSelected?(...args: unknown[]): unknown;
	placeholder?: string;
	noOptionsText?: string;
	loadingText?: string;
}

const AutocompleteInput = (props: AutocompleteInputProps) => {
	const {
		options,
		open,
		setOpen,
		onChange,
		onSelected,
		loading,
		placeholder,
		noOptionsText,
		loadingText,
		...rest
	} = props;

	return (
		<Root className={classes.root}>
			<Autocomplete
				forcePopupIcon={false}
				fullWidth
				getOptionLabel={(option) => option.name}
				id='asynchronous'
				isOptionEqualToValue={(option, value) => option.name === value.name}
				loading={loading}
				loadingText={loadingText}
				noOptionsText={noOptionsText}
				onChange={onSelected}
				onClose={() => {
					setOpen(false);
				}}
				onOpen={() => {
					setOpen(true);
				}}
				open={open}
				options={options}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{loading ?
										<CircularProgress color='inherit' size={20} />
									:	null}
									{params.InputProps.endAdornment}
								</>
							),
						}}
						fullWidth
						onChange={onChange}
						placeholder={placeholder}
						variant='outlined'
					/>
				)}
				renderOption={(renderProps, option) => {
					return (
						<li {...renderProps}>
							<Grid alignItems='center' container>
								<Grid item>
									<Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }}></Box>
								</Grid>
								<Grid item xs>
									<Typography variant='body2'>{option.name}</Typography>
								</Grid>
							</Grid>
						</li>
					);
				}}
				{...rest}
			/>
		</Root>
	);
};

export default AutocompleteInput;
