import { memo, useState, useEffect } from 'react';

import { Typography, List, ListItem, Radio, Box, FormControlLabel, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FormField } from '../../../../../../components';
import { useWizardFormField, useDebouncedNumberSave } from '../../../../../../shared/hooks';
import { isBoolean, isObject } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface TrailersCharacteristicsProps {
	className?: string;
	itemStep?: number;
	save?(...args: unknown[]): unknown;
	axleCount?: string;
	brakeType?: string;
	isEdit?: boolean;
	isFinishingEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	hasTrailerCover?: boolean;
	cargoSpaceL?: number | object | string;
	cargoSpaceW?: number | object | string;
	cargoSpaceH?: number | object | string;
	maxWeightInKg?: number | object | string;
	grossWeightInKg?: number | object | string;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const TrailersCharacteristics = memo((props: TrailersCharacteristicsProps) => {
	const {
		itemStep,
		isEdit,

		axleCount,
		brakeType,
		save,
		cargoSpaceL,
		onClose,
		isFinishingEdit,
		cargoSpaceW,
		cargoSpaceH,
		maxWeightInKg,
		grossWeightInKg,
		hasTrailerCover,
		characteristics,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const [trailerCharacteristics, setTrailerCharacteristics] = useState({
		hasLuggageCarrier: false,
	});

	const cargoL = useWizardFormField(cargoSpaceL, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedCargoSpaceL', cargoL.value, cargoL.isValid, save);

	const cargoW = useWizardFormField(cargoSpaceW, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedCargoSpaceW', cargoW.value, cargoW.isValid, save);

	const cargoH = useWizardFormField(cargoSpaceH, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedCargoSpaceH', cargoH.value, cargoH.isValid, save);

	const maxWeight = useWizardFormField(maxWeightInKg, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedMaxWeightInKg', maxWeight.value, maxWeight.isValid, save);

	const grossWeight = useWizardFormField(grossWeightInKg, {
		required: true,
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave('updatedGrossWeightInKg', grossWeight.value, grossWeight.isValid, save);

	const handleCharacteristics = (event) => {
		const target = event.target;
		setTrailerCharacteristics({ ...trailerCharacteristics, [target.name]: target.checked });
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	useEffect(() => {
		if (brakeType && axleCount) {
			save({ updatedAxleCount: axleCount });
			save({ updatedBrakeType: brakeType });
		}
	}, []);

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;

	const axle =
		readyData ?
			characteristicsData.trailers.filter((trailer) =>
				trailer.name === 'axleCount' ? trailer : null,
			)
		:	null;
	const brake =
		readyData ?
			characteristicsData.trailers.filter((trailer) =>
				trailer.name === 'brakeType' ? trailer : null,
			)
		:	null;

	const primaryCharacteristics = [
		{
			label: t('ui.characteristics.hasTrailerCover'),
			checked:
				isBoolean(hasTrailerCover) ? hasTrailerCover : trailerCharacteristics.hasTrailerCover,
			action: handleCharacteristics,
			name: 'hasTrailerCover',
		},
	];

	const handleAxlesCount = (event, item) => {
		save({ updatedAxleCount: item.name });
	};

	const handleBrakes = (event, item) => {
		save({ updatedBrakeType: item.name });
	};

	const cargoSpace = [
		{ label: t('ui.label.lengthInCm'), name: 'cargoSpaceL', variable: cargoL },
		{ label: t('ui.label.widthInCm'), name: 'cargoSpaceW', variable: cargoW },
		{ label: t('ui.label.heightInCm'), name: 'cargoSpaceH', variable: cargoH },
	];

	return (
		<>
			<CharacteristicsCard isEdit={isEdit} itemStep={itemStep}>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.howManyAxles')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						axle[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option)}
								key={item.id}
								onClick={(event) => handleAxlesCount(event, item)}
							>
								<Radio
									checked={axleCount === item.name}
									className={classes.optionRadio}
									onChange={(event) => handleAxlesCount(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.doesItHaveBrakes')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						brake[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option)}
								key={item.id}
								onClick={(event) => handleBrakes(event, item)}
							>
								<Radio
									checked={brakeType === item.name}
									className={classes.optionRadio}
									onChange={(event) => handleBrakes(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.innerDimensions')}
				</Typography>
				<Box className={classes.listComponent}>
					{cargoSpace.map((item) => (
						<Box key={item.name} pb={4} pr={2} width='180px'>
							<FormField
								fullWidth={true}
								hideCharacterCounter={!item.variable.hasFocus}
								label={item.label}
								maxLength={5}
								name='cargoSpaceL'
								required
								variable={item.variable}
							/>
						</Box>
					))}
				</Box>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.maxPayload')}
				</Typography>
				<Box className={classes.listComponent}>
					<Box width='180px'>
						<FormField
							fullWidth={true}
							hideCharacterCounter={!maxWeight.hasFocus}
							label={t('ui.label.weightInKg')}
							maxLength={5}
							name='maxWeightInKg'
							required
							variable={maxWeight}
						/>
					</Box>
				</Box>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.grossWeightInKg')}
				</Typography>
				<Box className={classes.listComponent}>
					<Box mb={2} width='180px'>
						<FormField
							fullWidth={true}
							hideCharacterCounter={!grossWeight.hasFocus}
							label={t('ui.label.weightInKg')}
							maxLength={5}
							name='grossWeightInKg'
							required
							variable={grossWeight}
						/>
					</Box>
				</Box>
			</CharacteristicsCard>
			<AdditionalCharactaristics isEdit={isEdit}>
				{primaryCharacteristics.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
});

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(TrailersCharacteristics);
