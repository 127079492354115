import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../../../../../components';

interface ButtonCardProps {
	onClose?(...args: unknown[]): unknown;
}

const ButtonCard = (props: ButtonCardProps) => {
	const { onClose } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	return (
		<div className={classes.buttonCard}>
			<StyledButton
				onClick={onClose}
				size='small'
				startIcon={<CloseIcon />}
				variant='inline-default'
			>
				{t('ui.button.inline.close')}
			</StyledButton>
		</div>
	);
};

export default ButtonCard;
