import {
	ArrowDropUp as ArrowDropUpIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import { TextField, CircularProgress } from '@mui/material';

import { useStyles } from '../style';

interface InputProps {
	openPopper?: boolean;
	inputRef?: object;
	onChange?(...args: unknown[]): unknown;
	onClick?(...args: unknown[]): unknown;
	loading?: boolean;
	value?: string;
	placeholder?: string;
	onKeyUp?(...args: unknown[]): unknown;
}

const Input = ({
	openPopper = false,
	inputRef,
	onChange,
	onClick,
	loading,
	value,
	placeholder,
	onKeyUp,
}: InputProps) => {
	const classes = useStyles();

	const handleAdornment = () => {
		if (loading) {
			return <CircularProgress className={classes.endAdornment} color='inherit' size={20} />;
		}
		if (openPopper) {
			return <ArrowDropUpIcon className={classes.endAdornment} />;
		}
		return <ArrowDropDownIcon className={classes.endAdornment} />;
	};

	return (
		<form autoComplete='off'>
			<TextField
				InputLabelProps={{ shrink: true }}
				fullWidth
				inputRef={inputRef}
				onChange={onChange}
				placeholder={placeholder}
				onClick={onClick}
				onKeyUp={onKeyUp}
				style={{ zIndex: 1 }}
				value={value || ''}
				variant='outlined'
				InputProps={{
					endAdornment: handleAdornment(),
					classes: { root: classes.inputContainer, input: classes.input },
				}}
			/>
		</form>
	);
};

export default Input;
