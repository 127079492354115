import { forwardRef } from 'react';

import { NumericFormat } from 'react-number-format';

interface CustomNumberProps {
	inputRef?: (...args: unknown[]) => unknown | object;
	decimalSeparators?: unknown[];
	name: string;
	onChange(...args: unknown[]): unknown;
	thousandSeparator?: boolean;
}

// eslint-disable-next-line no-unused-vars
const CustomNumber = forwardRef<HTMLElement, CustomNumberProps>((props, ref) => {
	const { inputRef, onChange, thousandSeparator, decimalSeparators = [], ...other } = props;

	const handleChange = (values) => onChange({ target: { name: props.name, value: values.value } });

	return (
		<NumericFormat
			{...other}
			allowNegative={false}
			allowedDecimalSeparators={decimalSeparators}
			decimalScale={2}
			getInputRef={inputRef}
			isNumericString
			onValueChange={handleChange}
			ref={inputRef}
			thousandSeparator={thousandSeparator}
		/>
	);
});

export default CustomNumber;
