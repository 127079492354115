import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../components';
import * as actions from '../../../../../store/actions';

interface UnlinkDialogProps {
	open?: boolean;
	nfcId?: string;
	onClose?(...args: unknown[]): unknown;
	user?: string;
	onUnlinkNfc?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	getNfcData?(...args: unknown[]): unknown;
	unlinkNfc?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const UnlinkDialog = (props: UnlinkDialogProps) => {
	const { open, onClose, nfcId, user, onUnlinkNfc, unlinkNfc, onResetStateCondition, getNfcData } =
		props;
	const { t } = useTranslation('general');

	const { success: unlinkNfcSuccess, loading: unlinkNfcLoading, error: unlinkNfcError } = unlinkNfc;

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (unlinkNfcSuccess) {
			enqueueSnackbar(t('views.usersAndOrganisations.nfcUnlink.successMessage'), {
				variant: 'success',
			});
			onClose();
			getNfcData();
			onResetStateCondition('unlinkNfc', false);
		} else if (unlinkNfcError) {
			enqueueSnackbar(unlinkNfcError?.message ? unlinkNfcError.message : 'Error', {
				variant: 'error',
			});
			onResetStateCondition('unlinkNfc', false);
		}
	}, [unlinkNfc]);

	const handleUnlinkNFCTag = () => {
		onUnlinkNfc(nfcId);
	};

	const handleCloseUnlinkNFCTag = () => {
		onClose();
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleUnlinkNFCTag,
				text: unlinkNfcLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseUnlinkNFCTag}
			loading={unlinkNfcLoading}
			open={open}
			title={t('views.usersAndOrganisations.nfcUnlink.title')}
		>
			{`${user || ''} ${t('views.usersAndOrganisations.nfcUnlink.description')}`}
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		unlinkNfc: state.condition.unlinkNfc,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
		onUnlinkNfc: (nfcId) => dispatch(actions.unlinkNfc(nfcId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkDialog);
