import { useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid2, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { PeriodicityEnum } from '~enums';
import { UserLicenseCountChart } from '~features/users';
import { useUrlSearchParamsOrganisation } from '~hooks';
import { DateRange } from '~interfaces/dateRanges';
import { DetailsGridLayout } from '~layouts';

import StatisticsToolbar from '../components/toolbars/statisticsToolbar';
import useUrlSearchParamsDate from '../hooks/useUrlSearchParamsDate';
import useUrlSearchParamsPeriodicity from '../hooks/useUrlSearchParamsPeriodicity';

const UserLicenseStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const [organisation] = useUrlSearchParamsOrganisation();
	const [date, setDate] = useUrlSearchParamsDate();
	const [periodicity, setPeriodicity] = useUrlSearchParamsPeriodicity();

	const getPeriod = (date: Dayjs, periodicity: PeriodicityEnum, range = 1): DateRange => {
		const end = periodicity === PeriodicityEnum.Month ? date.endOf('month') : date.endOf('year');

		return {
			start: end
				.subtract(range, periodicity === PeriodicityEnum.Month ? 'months' : 'year')
				.startOf('month')
				.toDate(),
			end: end.toDate(),
		};
	};

	return (
		<Stack spacing={2} sx={{ height: 1 }}>
			<StatisticsToolbar
				disableFilters
				date={date}
				onDateChange={(value) => setDate(value)}
				periodicity={periodicity}
				onPeriodicityChange={(value) => setPeriodicity(value)}
			/>
			<DetailsGridLayout>
				{['requests', 'rejected', 'approved', 'uniqueUsers'].map((el) => (
					<Card key={el}>
						<CardHeader title={t(el)} />
						<CardContent>
							<UserLicenseCountChart
								key={el}
								type={el}
								organisationId={organisation?.id}
								periodicity={periodicity}
								period={getPeriod(date, periodicity, 6)}
							/>
						</CardContent>
					</Card>
				))}
			</DetailsGridLayout>
		</Stack>
	);
};

export default UserLicenseStatisticsLayout;
