import { Drawer, Grid, Typography, Hidden } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../UI';

interface TableEditBarProps {
	className?: string;
	actionButtons?: unknown[];
	selected: unknown[];
}

const TableEditBar = (props: TableEditBarProps) => {
	const { className, actionButtons, selected } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const open = selected?.length > 0;

	return (
		<Drawer PaperProps={{ elevation: 1 }} anchor='bottom' open={open} variant='persistent'>
			<div className={clsx(classes.root, className)}>
				<Grid alignItems='center' container spacing={2}>
					<Hidden mdDown>
						<Grid item md={3}>
							<Typography color='textSecondary' variant='subtitle1'>
								{' '}
								{selected.length} {t('ui.selected')}
							</Typography>
						</Grid>
					</Hidden>
					<Grid item md={6} xs={12}>
						<div className={classes.actions}>
							{actionButtons ?
								actionButtons.map((button, index) => (
									<StyledButton
										color={button.isRed && 'error'}
										key={`actionButton-${index}`}
										onClick={() => button.action(selected)}
										{...(button.variant && { variant: button.variant })}
									>
										{button.icon ? button.icon : null}
										{button.text}
									</StyledButton>
								))
							:	null}
						</div>
					</Grid>
				</Grid>
			</div>
		</Drawer>
	);
};

export default TableEditBar;
