import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const localStorageLanguage = localStorage.getItem('languageCode');

i18n
	.use(initReactI18next)
	.use(HttpBackend)
	.init<HttpBackendOptions>({
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			// Adding these query params should prevent the app to get
			// translations from the cache on a update. Or otherwise said, force
			// update the translations, requires discipline of the package version...
			// TODO: try to use a hash for unique values on whatever update?
			queryStringParams: {
				v: import.meta.env.VITE_PACKAGE_VERSION
			}
		},
		lng:
			localStorageLanguage === 'de-DE' ? 'de'
			: localStorageLanguage === 'en-US' ? 'en'
			: 'nl',
		fallbackLng: 'nl',
		supportedLngs: ['en', 'nl', 'de'],
		debug: import.meta.env.NODE_ENV === 'development',
		ns: ['general'],
		defaultNS: 'general',
		interpolation: {
			escapeValue: false,
		},
		keySeparator: '.',
	});

// Add the formatters
i18n.services.formatter?.add('lowercase', (value: string) => value.toLowerCase());

// Forces the languages to be loaded. This is done because some components
// require translations outside of the current selected languages.
// Example is for superadmins when rejecting user licenses and selecting
// a predefined response message.
i18n.loadLanguages(['de', 'nl', 'en'], (err) => console.log(err));

export default i18n;
