import { atomWithReset } from 'jotai/utils';

interface FormContainerProps<T = any> {
	loading?: boolean;
	disabled?: boolean;
	onSubmit?: (value: T) => void;
}

const formContainerAtom = atomWithReset<FormContainerProps>({
	loading: false,
	disabled: true,
});

export default formContainerAtom;
