import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
	className?: string;
	editing?: boolean;
}

const Header = (props: HeaderProps) => {
	const { className, editing } = props;
	const { t } = useTranslation('general');

	return (
		<div className={className}>
			<Grid alignItems='flex-end' container justifyContent='space-between'>
				<Grid item>
					<Box pb={5}>
						<Typography gutterBottom variant='overline'>
							{editing ?
								t('views.addPricing.header.editPricing')
							:	t('views.addPricing.header.addPricing')}
						</Typography>
						<Typography variant='h2'>{t('ui.pricing')}</Typography>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};

export default Header;
