import { useState, useEffect } from 'react';

import ContactlessOutlinedIcon from '@mui/icons-material/ContactlessOutlined';
import {
	Box,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	CircularProgress,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog, AlertDialog, FormField, StyledButton } from '../../../../../../components';
import { commaTimeStrings } from '../../../../../../shared/datetime';
import { useWizardFormField } from '../../../../../../shared/hooks';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { useStyles } from '../style';

interface TankCardProps {
	className?: string;
	instanceData?: object;
	isInternalUser?: boolean;
	onAddTankCard?(...args: unknown[]): unknown;
	onDeleteTankCards?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	addTankCard?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deleteTankCards?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const TankCard = (props: TankCardProps) => {
	const {
		instanceData,
		className,

		deleteTankCards,
		onAddTankCard,
		addTankCard,
		onDeleteTankCards,
		onResetState,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const tankCard = isObject(instanceData?.tankCard) && instanceData.tankCard;

	const { data: addTankCardData, loading: addTankCardLoading } = addTankCard;

	const { success: deleteTankCardsSuccess, loading: deleteTankCardsLoading } = deleteTankCards;

	const [openAddTankCardDialog, setOpenAddTankCardDialog] = useState(false);
	const [openDeleteTankCardDialog, setOpenDeleteTankCardDialog] = useState(false);

	const pincode = useWizardFormField('', { required: true, minLength: 1, maxLength: 16 });
	const cardId = useWizardFormField('', { minLength: 1, maxLength: 32 });
	const cardCode = useWizardFormField('', { maxLength: 32 });

	const handleReset = () => {
		pincode.resetToInitialValue();
		cardId.resetToInitialValue();
		cardCode.resetToInitialValue();
	};

	const handleAddTankCards = () => {
		const bodyData = { pincode: pincode.value, cardId: cardId.value, cardCode: cardCode.value };
		onAddTankCard(instanceData.itemReference.id, instanceData.id, bodyData, instanceData);
	};

	const handleCloseAddTankCards = () => {
		setOpenAddTankCardDialog(false);
		handleReset();
	};

	const handleOpenAddTankCardDialog = () => setOpenAddTankCardDialog(true);

	useEffect(() => {
		if (isObject(addTankCardData)) {
			handleCloseAddTankCards();
			enqueueSnackbar(`${t('ui.successfullyAdded')} ${t('ui.label.tank')}`, { variant: 'success' });
			onResetState('addTankCard');
		}
	}, [addTankCard]);

	const handleOpenDeleteTankCardDialog = () => {
		setOpenDeleteTankCardDialog(true);
	};

	const handleCloseDeleteTankCardDialog = () => {
		setOpenDeleteTankCardDialog(false);
	};

	const handleDeleteTankCard = () => {
		onDeleteTankCards(instanceData.itemReference.id, instanceData.id);
	};

	useEffect(() => {
		if (deleteTankCardsSuccess) {
			handleCloseDeleteTankCardDialog();
			enqueueSnackbar(`${t('ui.successfullyDeleted')} ${t('ui.label.tank')}`, {
				variant: 'success',
			});
			onResetStateCondition('deleteTankCards', false);
		}
	}, [deleteTankCards]);

	// isInternalUser
	const tankCardData = [
		...(tankCard ?
			[
				{ label: 'tank.card.label.cardID', content: tankCard.cardCode },
				{ label: 'tank.card.label.chipID', content: tankCard.cardId },
				{ label: 'tank.card.label.pinCode', content: tankCard.pincode },
				{ label: 'ui.label.linkedDate', content: commaTimeStrings(tankCard.linkedDate) },
			]
		:	[]),
	];

	const addData = [
		{
			name: 'cardId',
			label: 'tank.card.label.chipID',
			focus: !cardId.hasFocus,
			maxLength: 32,
			variable: cardId,
			required: false,
		},
		{
			name: 'pincode',
			label: 'tank.card.label.pinCode',
			focus: !pincode.hasFocus,
			maxLength: 16,
			variable: pincode,
			required: true,
		},
		{
			name: 'cardCode',
			label: 'tank.card.label.cardID',
			focus: !cardCode.hasFocus,
			maxLength: 32,
			variable: cardCode,
			required: false,
		},
	];

	return (
		<>
			<Card className={clsx(classes.root, className)}>
				<CardHeader title={t('ui.label.tank')} />
				<Divider />
				<CardContent>
					<Table>
						<TableBody>
							{tankCard &&
								tankCardData.map((card, index) => (
									<TableRow key={card.label}>
										<TableCell className={index === 3 ? classes.bottomCell : ''}>
											{t(card.label)}
										</TableCell>
										<TableCell className={index === 3 ? classes.bottomCell : ''}>
											{card?.content ? card.content : '-'}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</CardContent>
				<CardActions className={classes.actions}>
					{tankCard ?
						<StyledButton
							onClick={handleOpenDeleteTankCardDialog}
							startIcon={<ContactlessOutlinedIcon />}
							variant='inline-delete'
						>
							{t('ui.button.tankCard.delete')}
						</StyledButton>
					:	<StyledButton
							onClick={handleOpenAddTankCardDialog}
							startIcon={<ContactlessOutlinedIcon />}
							variant='inline-default'
						>
							{t('ui.button.tankCard.add')}
						</StyledButton>
					}
				</CardActions>
			</Card>
			{openAddTankCardDialog && (
				<ActionDialog
					actionButtonProps={{
						action: handleAddTankCards,
						text: addTankCardLoading ? <CircularProgress disableShrink size={24} /> : t('ui.add'),
					}}
					handleClose={handleCloseAddTankCards}
					loading={addTankCardLoading || !pincode.isValid}
					open={openAddTankCardDialog}
					title={t('ui.button.tankCard.add')}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
						{addData.map((item) => (
							<FormField
								hideCharacterCounter={item.focus}
								key={item.name}
								label={t(item.label)}
								maxLength={item.maxLength}
								name={item.name}
								placeholder={'Ex. 123123123123123123123123'}
								required={item.required}
								variable={item.variable}
							/>
						))}
					</Box>
				</ActionDialog>
			)}
			{openDeleteTankCardDialog && (
				<AlertDialog
					dialogDescription={t('ui.label.tankCard.deleteDialog.description')}
					dialogTitle={t('ui.label.tankCard.delete')}
					handleClose={handleCloseDeleteTankCardDialog}
					handleConfirm={handleDeleteTankCard}
					loading={deleteTankCardsLoading}
					open={openDeleteTankCardDialog}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		addTankCard: state.details.addTankCard,
		deleteTankCards: state.condition.deleteTankCards,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAddTankCard: (itemId, instanceId, bodyData, data) =>
			dispatch(actions.addTankCard(itemId, instanceId, bodyData, data)),
		onDeleteTankCards: (itemId, instanceId) =>
			dispatch(actions.deleteTankCards(itemId, instanceId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TankCard);
