import path from 'path';

import { ItemCategoryEnum } from '~enums';
import { PagedResults } from '~interfaces';
import { GeneralPagedParameters, SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import DefectSeverityEnum from '../enum/defectSeverityEnum';
import ServiceTicketStatusEnum from '../enum/serviceTicketStatusEnum';
import ServiceTicket, { ServiceTicketNew } from '../interfaces/serviceTicket';

class ServiceTicketsService extends TopologyService {
	public readonly path = 'tickets';

	constructor() {
		super('v1');
	}

	async createTicket(data: ServiceTicket): Promise<null> {
		const content = ServiceTicketsService.toRequest(data);
		const response = await this._client.postForm(this.path, content);

		return null;
	}

	/**
	 * Get a list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getServiceTickets({
		page = 1,
		pageSize = 10,
		access = ['owned', 'shared'],
		descending = true,
		...args
	}: {
		access: ('owned' | 'shared' | 'public')[];
		status?: ('none' | 'open' | 'pending' | 'planned' | 'ready' | 'closed' | 'unclosed')[];
	} & GeneralPagedParameters<'id' | 'itemGroup' | 'severity' | 'defect' | 'status'>): Promise<
		PagedResults<ServiceTicket>
	> {
		const sortBy = (() => {
			switch (args.sortBy) {
				case 'itemGroup':
					return 'itemName';
				case 'defect':
					return 'defectTitle';
				case 'severity':
					return 'defectType';
				default:
					return args.sortBy;
			}
		})();

		const { data } = await this._client.get<PagedResponse<ServiceTicketResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				searchTerm: args.searchQuery || undefined,
				access: access.join(','),
				status: args.status != null && args.status?.length > 0 ? args.status.join(',') : undefined,
				sortBy: sortBy,
				orderDescending: descending,
			},
		});

		return this.mapPagedResponse(data, ServiceTicketsService.fromResponse);
	}

	async getTicketStatusSummary(
		organisationId?: string,
		access?: ('shared' | 'owned')[],
	): Promise<TicketsSummaryResponse> {
		const { data } = await this._client.get<TicketsSummaryResponse>(
			path.join('dashboard', 'tickets', 'status'),
			{
				params: {
					organisationId: organisationId,
					access: access != null && access?.length > 0 ? access.join(',') : undefined,
				},
			},
		);

		return data;
	}

	async updateTicketStatus(id: string, data): Promise<null> {
		const response = await this._client.post(path.join(this.path, id, 'activities'), data);
		return response;
	}

	/**
	 * Get the details of a single service ticket
	 * @param id The id of the service ticket
	 * @returns
	 */
	async getServiceTicketById(id: string): Promise<ServiceTicket> {
		const { data } = await this._client.get<ServiceTicketResponse>(path.join(this.path, id));

		return ServiceTicketsService.fromResponse(data);
	}

	static fromResponse(data: ServiceTicketResponse): ServiceTicket {
		const {
			id,
			name,
			itemInstanceReference,
			dateCreated,
			dateModified,
			status,
			defectReference,
			serviceReference,
			...rest
		} = data;

		return {
			...rest,
			...ServiceTicketsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			dateCreated: new Date(dateCreated),
			dateModified: new Date(dateModified),
			item: {
				...ServiceTicketsService.fromBaseReferenceResponse(itemInstanceReference),
				itemGroup: ServiceTicketsService.fromBaseReferenceResponse(
					itemInstanceReference.itemReference,
				),
				category: itemInstanceReference.itemReference.categoryReference.type as ItemCategoryEnum,
				hub: ServiceTicketsService.fromBaseReferenceResponse(itemInstanceReference.hubReference),
			},
			status: status as ServiceTicketStatusEnum,
			defect: {
				id: defectReference.id.toString(),
				label: defectReference.title,
				severity: defectReference.type as DefectSeverityEnum,
			},
			// Why from the hub?
			provider: ServiceTicketsService.fromBaseReferenceResponse(
				itemInstanceReference.hubReference.organisationReference,
			),
			serviceProvider: ServiceTicketsService.fromBaseReferenceResponse(serviceReference),
		};
	}

	static toRequest(data: ServiceTicketNew): ServiceTicketRequest {
		const { description, item, defect } = data;

		return {
			itemInstanceId: item.id,
			ticketDefectId: defect,
			description: description,
		};
	}
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface ServiceTicketResponse extends BaseReferenceResponse {
	description: string;
	dateCreated: string;
	dateModified: string;
	status: string;
	defectReference: {
		title: string;
		type: string;
		categoryReference: CategoryResponse;
	} & IdReferenceResponse;
	itemInstanceReference: {
		itemReference: {
			categoryReference: CategoryResponse;
		} & BaseReferenceResponse;
		hubReference: {
			latitude: number;
			longitude: number;
			organisationReference: BaseReferenceResponse;
		} & BaseReferenceResponse;
	} & BaseReferenceResponse;
	userReference: IdReferenceResponse;
	serviceReference?: BaseReferenceResponse;
}

interface ServiceTicketRequest {
	description?: string;
	itemInstanceId: string;
	ticketDefectId: string;
}

interface CategoryResponse extends BaseReferenceResponse {
	type: string;
}

interface TicketsSummaryResponse {
	open: number;
	pending: number;
	planned: number;
	ready: number;
}

export default ServiceTicketsService;
