import { useState, useEffect } from 'react';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
	Typography,
	Box,
	Card,
	CardHeader,
	CardContent,
	CircularProgress,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { Label, StyledButton } from '../../../../../components';
import { gpsLocationIcons } from '../../../../../constantsOld';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { usePrevious } from '../../../../../shared/hooks';
import {
	isObject,
	isEmptyObject,
	isFullArray,
	isUndefined,
	isFullString,
	isBoolean,
	convertBatteryLevelToEnum,
} from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { MyTooltip } from '../../../DevicesList/style';

interface HeartbeatDeviceProps {
	className?: string;
	deviceData?: object;
	deviceType?: string;
	id?: string;
	isInstance?: boolean;
	onResetState?(...args: unknown[]): unknown;
	onDeviceUnlockCode?(...args: unknown[]): unknown;
	gpsIcons?: boolean;
	fetchDeviceHeartbeats?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchDeviceLocation?(...args: unknown[]): unknown;
	fetchDeviceLocation?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	deviceUnlockCode?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	instanceHeartbeats?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	instanceAccess?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const HeartbeatDevice = (props: HeartbeatDeviceProps) => {
	const {
		className,

		deviceData,
		fetchDeviceHeartbeats,
		onFetchDeviceLocation,
		fetchDeviceLocation,
		onDeviceUnlockCode,
		deviceUnlockCode,
		id,
		gpsIcons,
		isInstance,
		instanceHeartbeats,
		instanceAccess,
		deviceType,
		onResetState,
	} = props;
	const { t } = useTranslation('general');

	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();

	const isBoat = deviceType === 'cpacBoat';

	const [location, setLocation] = useState(null);
	const [loader, setLoader] = useState(false);

	const { data: instanceAccessData } = instanceAccess;

	const { data: fetchDeviceHeartbeatsDataTmp, loading: fetchDeviceHeartbeatsLoading } =
		isInstance ? instanceHeartbeats : fetchDeviceHeartbeats;

	// Dennis 20240301: For some reason the device data was input into this element, but also fetched.
	// So used the input data again instead of fetching. Do we have the latest heartbeat in that case?
	// Dennis 20240503: The above fix created other issuess. Because we don't have a clue what it should
	// be doing, just take the fetchDeviceHeartbeatsData if it is present, fallback to deviceData
	// TODO: But this eventually needs to be refactored! Because we are not happy with the solution
	// For some reason, the fetchDeviceHeartbeatsData sometimes returns just true
	const fetchDeviceHeartbeatsData =
		fetchDeviceHeartbeatsDataTmp !== true ? fetchDeviceHeartbeatsDataTmp : deviceData;

	const {
		data: locationData,
		loading: locationLoading,
		error: locationError,
	} = fetchDeviceLocation;

	const { data: deviceUnlockCodeData, loading: deviceUnlockCodeLoading } = deviceUnlockCode;

	const isLocation =
		isObject(fetchDeviceHeartbeatsData) && isObject(fetchDeviceHeartbeatsData.location);

	const locationLoadingPrevious = usePrevious(locationLoading);

	useEffect(() => {
		return () => {
			onResetState('deviceUnlockCode');
		};
	}, []);

	useEffect(() => {
		if (isObject(fetchDeviceHeartbeatsData) && isObject(fetchDeviceHeartbeatsData.location)) {
			setLocation(fetchDeviceHeartbeatsData.location);
			setLoader(false);
		} else if (isObject(locationData) && isObject(locationData.location)) {
			setLocation(locationData.location);
			setLoader(false);
		} else if (!isLocation && isEmptyObject(locationData)) {
			setLocation(null);
			setLoader(false);
		}
	}, [locationLoading, locationData, fetchDeviceHeartbeatsData]);

	useEffect(() => {
		if (isEmptyObject(locationData) && locationLoadingPrevious && !isObject(locationError)) {
			enqueueSnackbar(t('ui.label.noLocationFound'), { variant: 'error' });
		} else if (isObject(locationError) && locationLoadingPrevious) {
			enqueueSnackbar(t('ui.label.somethingIsWrong'), { variant: 'error' });
		} else if (!isEmptyObject(locationData) && locationLoadingPrevious) {
			enqueueSnackbar(t('ui.label.retrievedLocation'), { variant: 'success' });
		}
	}, [locationLoading]);

	const handleGps = () => {
		setLoader(true);
		onFetchDeviceLocation(deviceData.deviceId);
	};

	const handleLocation = () => {
		if (isObject(location)) {
			return (
				<Box alignItems='center' display='flex'>
					<a
						className={classes.mapLink}
						href={`https://maps.google.com/?daddr=${location.latitude},${location.longitude}`}
						rel='noreferrer'
						target='_blank'
					>{`${location.latitude}, ${location.longitude}`}</a>
					{(
						gpsIcons &&
						isFullString(fetchDeviceHeartbeatsData?.locationType) &&
						fetchDeviceHeartbeatsData.locationType !== 'none'
					) ?
						<Box display='flex' ml={1}>
							<MyTooltip
								arrow
								title={
									<Typography>
										{t(
											`views.devices.heartbeatsList.icon.${fetchDeviceHeartbeatsData?.locationType}`,
										)}
									</Typography>
								}
							>
								{gpsLocationIcons[fetchDeviceHeartbeatsData?.locationType]}
							</MyTooltip>
						</Box>
					:	null}
				</Box>
			);
		} else {
			if (loader) {
				return <CircularProgress color='info' disableShrink size={31} />;
			} else {
				return (
					<StyledButton
						className={classes.button}
						onClick={handleGps}
						startIcon={<LocationOnOutlinedIcon fontSize='small' />}
					>
						{t('ui.label.getGps')}
					</StyledButton>
				);
			}
		}
	};

	const handleDeviceUnlockCode = () => onDeviceUnlockCode(deviceData.deviceId);

	const handleUnlockCode = () => {
		if (isObject(deviceUnlockCodeData) && deviceData.deviceId === id) {
			return deviceUnlockCodeData.code;
		} else {
			return (
				<StyledButton
					onClick={handleDeviceUnlockCode}
					startIcon={<LockOutlinedIcon fontSize='small' />}
				>
					{deviceUnlockCodeLoading ?
						<CircularProgress color='info' disableShrink size={31} />
					:	t('ui.button.contained.getUnlockCode')}
				</StyledButton>
			);
		}
	};

	const showLocation =
		isInstance ?
			instanceAccessData?.includes('internal') &&
			(deviceData?.communicationType?.includes('lte') ||
				deviceData?.communicationType?.includes('thread'))
		:	deviceData?.communicationType?.includes('lte') ||
			deviceData?.communicationType?.includes('thread');

	const deviceTable =
		isObject(fetchDeviceHeartbeatsData) ?
			[
				{
					label: 'ui.label.lastOnline',
					content: <Label>{commaTimeStrings(fetchDeviceHeartbeatsData.lastUpdated)}</Label>,
				},
				{
					label: 'views.devices.heartbeatsList.deviceState',
					content: t(
						`ui.label.device.state.${fetchDeviceHeartbeatsData.state ?? fetchDeviceHeartbeatsData.deviceState}`,
					),
				},
				{
					label: 'ui.label.lockStatus',
					content:
						!isUndefined(fetchDeviceHeartbeatsData.lockState) &&
						t(`ui.label.${fetchDeviceHeartbeatsData.lockState}`),
				},
				...(isBoat ?
					[
						{
							label: 'ui.label.ignitionStatus',
							content:
								fetchDeviceHeartbeatsData?.ignition &&
								t(`ui.${fetchDeviceHeartbeatsData?.ignition?.toLowerCase()}`),
						},
						{
							label: 'ui.label.immobiliserStatus',
							content:
								isBoolean(fetchDeviceHeartbeatsData?.immobiliserStateEnabled) &&
								(fetchDeviceHeartbeatsData.immobiliserStateEnabled ?
									t('ui.enabled')
								:	t('ui.disabled')),
						},
					]
				:	[]),
				...(isBoat ?
					[]
				:	[
						{
							label: 'ui.label.battery',
							content:
								!isUndefined(fetchDeviceHeartbeatsData.batteryLevel) &&
								(deviceType === 'doorLock' ?
									convertBatteryLevelToEnum(fetchDeviceHeartbeatsData.batteryLevel)
								:	`${fetchDeviceHeartbeatsData.batteryLevel}%`),
						},
					]),
				...(deviceType === 'trailerLock' ?
					[
						{
							label: 'ui.label.secondaryBattery',
							content: fetchDeviceHeartbeatsData?.externalBatteryLevel,
						},
					]
				:	[]),
				{ label: 'ui.label.temperature', content: fetchDeviceHeartbeatsData?.temperature },
				...(isBoat ?
					[
						{
							label: 'ui.label.chargingStatus',
							content:
								isFullString(fetchDeviceHeartbeatsData.charging) &&
								t(`ui.label.${fetchDeviceHeartbeatsData.charging}`),
						},
						{
							label: 'ui.label.vehicleBattery',
							content: fetchDeviceHeartbeatsData.vehicleBatteryLevel,
						},
						{
							label: 'ui.device.label.reachElectric',
							content: fetchDeviceHeartbeatsData.electricalRange,
						},
						{
							label: 'ui.label.fuelLevel',
							content:
								(
									fetchDeviceHeartbeatsData?.vehicleFuelVolume &&
									fetchDeviceHeartbeatsData?.vehicleFuelVolume > 0
								) ?
									`${fetchDeviceHeartbeatsData?.vehicleFuelVolume} L`
								:	fetchDeviceHeartbeatsData?.vehicleFuelLevel &&
									fetchDeviceHeartbeatsData?.vehicleFuelLevel > 0 &&
									`${fetchDeviceHeartbeatsData?.vehicleFuelLevel}%`,
						},
						{
							label: 'ui.device.label.reachCombustion',
							content: fetchDeviceHeartbeatsData.combustionRange,
						},
						{ label: 'ui.device.label.reachTotal', content: fetchDeviceHeartbeatsData.range },
					]
				:	[
						{
							label: 'ui.label.firmwareVersion',
							content: fetchDeviceHeartbeatsData?.firmwareVersion,
						},
					]),
				...(showLocation ? [{ label: 'ui.label.location', content: handleLocation() }] : []),
				...(showLocation && deviceData?.identificationType?.includes('totp') ?
					[{ label: 'ui.label.unlockCode', content: handleUnlockCode() }]
				:	[]),
				...(isObject(deviceUnlockCodeData) ?
					[
						{
							label: 'views.devices.details.deviceStatus.unlockCodeValid',
							content: commaTimeStrings(deviceUnlockCodeData.adjustedEndDate),
						},
					]
				:	[]),
			]
		:	null;

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('ui.label.deviceStatus')} />
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{isFullArray(deviceTable) ?
							deviceTable.map((item, index) => (
								<TableRow key={index}>
									<TableCell>{t(item.label)}</TableCell>
									<TableCell>{item.content ? item.content : '-'}</TableCell>
								</TableRow>
							))
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchDeviceHeartbeats: state.details.fetchDeviceHeartbeats,
		fetchDeviceLocation: state.details.fetchDeviceLocation,
		deviceUnlockCode: state.details.deviceUnlockCode,
		instanceHeartbeats: state.details.instanceHeartbeats,
		instanceAccess: state.list.instanceAccess,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchDeviceLocation: (id) => dispatch(actions.fetchDeviceLocation(id)),
		onDeviceUnlockCode: (deviceId) => dispatch(actions.deviceUnlockCode(deviceId)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeartbeatDevice);
