import { useEffect } from 'react';

import { Typography, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ActionDialog } from '../../../../../../components';
import * as actions from '../../../../../../store/actions';

interface DeleteUserDialogProps {
	openDeleteUserDialog?: boolean;
	setOpenDeleteUserDialog?(...args: unknown[]): unknown;
	userId?: number;
	onHardDeleteUser?(...args: unknown[]): unknown;
	onResetStateCondition?(...args: unknown[]): unknown;
	hardDeleteUser?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const DeleteUserDialog = (props: DeleteUserDialogProps) => {
	const {
		openDeleteUserDialog,
		setOpenDeleteUserDialog,
		userId,

		onHardDeleteUser,
		hardDeleteUser,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');

	const {
		success: hardDeleteUserSuccess,
		loading: hardDeleteUserLoading,
		error: hardDeleteUserError,
	} = hardDeleteUser;

	const { enqueueSnackbar } = useSnackbar();

	const handleCloseAttentionDialog = () => setOpenDeleteUserDialog(false);

	useEffect(() => {
		if (hardDeleteUserSuccess) {
			enqueueSnackbar(t('views.userDetails.summary.deleteDialog.userDeletion'), {
				variant: 'success',
			});
			handleCloseAttentionDialog();
		} else if (hardDeleteUserError) {
			enqueueSnackbar(hardDeleteUserError?.message, { variant: 'error' });
			onResetStateCondition('hardDeleteUser', false);
		}
	}, [hardDeleteUser]);

	const handleConfirm = () => {
		onHardDeleteUser(userId, true);
	};

	return (
		<ActionDialog
			actionButtonProps={{
				action: handleConfirm,
				text:
					hardDeleteUserLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
			}}
			handleClose={handleCloseAttentionDialog}
			loading={hardDeleteUserLoading}
			open={openDeleteUserDialog}
			title={t('views.userDetails.summary.deleteDialog.title')}
		>
			<Typography variant='body2'>
				{t('views.userDetails.summary.deleteDialog.description')}
			</Typography>
		</ActionDialog>
	);
};

const mapStateToProps = (state) => {
	return {
		hardDeleteUser: state.condition.hardDeleteUser,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onHardDeleteUser: (id, hardDelete) => dispatch(actions.hardDeleteUser(id, hardDelete)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserDialog);
