import { useEffect, useState } from 'react';

import { Card, Box, CardHeader, Divider, Tabs, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import BookingList from './BookingList';
import MaintenanceList from './MaintenanceList';
import { isNumber } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../../style';

interface BookingsProps {
	onDashboardBookings?(...args: unknown[]): unknown;
	dashboardOrganisationFilter?: object;
	onDashboardStatus?(...args: unknown[]): unknown;
	dashboardStatus?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const Bookings = (props: BookingsProps) => {
	const { onDashboardStatus, dashboardStatus, dashboardOrganisationFilter } = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const { data: dashboardStatusData } = dashboardStatus;

	const [tab, setTab] = useState(location?.state?.status ? location?.state?.status : 'active');

	const classes = useStyles();

	const handleTabsChange = (event, value) => setTab(value);

	useEffect(() => {
		onDashboardStatus();
	}, [dashboardOrganisationFilter.valueId]);

	const tabs = [
		{ value: 'active', label: 'ui.label.active', status: 0 },
		{ value: 'upcoming', label: 'ui.label.upcoming', status: 0 },
		{ value: 'overtime', label: 'ui.label.overTime', status: dashboardStatusData?.overtime },
		...(isSuperAdmin() ?
			[
				{
					value: 'attention',
					label: 'ui.label.needAttention',
					status: dashboardStatusData?.attention,
				},
			]
		:	[]),
		{ value: 'approval', label: 'ui.label.approval', status: dashboardStatusData?.approval },
		{ value: 'maintenance', label: 'ui.label.maintenance', status: 0 },
	];

	return (
		<Card className={classes.card}>
			<CardHeader title={t('nav.category.bookings')} />
			<Divider />
			<Tabs
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab
						icon={
							isNumber(tab?.status) && tab?.status > 0 ?
								<Box className={classes.buble}>
									<Typography color='error'>{tab.status}</Typography>
								</Box>
							:	<Box minHeight='20px'></Box>
						}
						iconPosition='end'
						key={tab.value}
						label={t(tab.label)}
						value={tab.value}
					/>
				))}
			</Tabs>
			<Divider />
			{tab === 'active' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='active' />
			)}
			{tab === 'upcoming' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='upcoming' />
			)}
			{tab === 'overtime' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='overtime' />
			)}
			{tab === 'attention' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='attention' />
			)}
			{tab === 'approval' && (
				<BookingList dashboardOrganisationFilter={dashboardOrganisationFilter} status='approval' />
			)}
			{tab === 'maintenance' && (
				<MaintenanceList
					dashboardOrganisationFilter={dashboardOrganisationFilter}
					status='maintenance'
				/>
			)}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		dashboardStatus: state.details.dashboardStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDashboardStatus: () => dispatch(actions.dashboardStatus()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
