import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../../../components';
import { useStyles } from '../style';

interface EditButtonsProps {
	onClose?(...args: unknown[]): unknown;
	isFinishingEdit?: boolean;
}

const EditButtons = (props: EditButtonsProps) => {
	const { onClose, isFinishingEdit } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();
	const handleEditClose = (event) => {
		event.preventDefault();
		onClose(true);
	};

	const handleEditCancel = (event) => {
		event.preventDefault();
		onClose(false);
	};

	return (
		<Box display='flex' justifyContent='space-between' m={[10, 4, 10, 10]}>
			<StyledButton
				className={classes.button}
				disabled={isFinishingEdit}
				onClick={handleEditCancel}
				variant='contained-tertiary'
			>
				{t('ui.button.contained.cancel')}
			</StyledButton>
			<StyledButton
				className={classes.button}
				disabled={isFinishingEdit}
				onClick={handleEditClose}
				variant='contained-primary'
			>
				{isFinishingEdit ?
					<CircularProgress size={25} />
				:	t('ui.button.contained.update')}
			</StyledButton>
		</Box>
	);
};

export default EditButtons;
