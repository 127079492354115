import { Card, CardContent, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { InfoLabel } from '../../../../../../components';
import { isArray } from '../../../../../../shared/utility';
import { useStylesEdit } from '../editStyle';
import { useStyles } from '../style';

interface AdditionalCharactaristicsProps {
	isEdit?: boolean;
	className?: string;
	children?: unknown[];
	additionalChildren?: unknown[];
}

const AdditionalCharactaristics = (props: AdditionalCharactaristicsProps) => {
	const { children, isEdit, additionalChildren } = props;
	const { t } = useTranslation('general');
	const editClasses = useStylesEdit();
	const classes = useStyles();

	return (
		<Card className={isEdit ? editClasses.editCard : classes.additionalCharactaristics}>
			<CardContent className={classes.card}>
				<InfoLabel
					info={t('ui.label.characteristics.additional.descriptions')}
					name={t('ui.label.characteristics.additional.name')}
					paddingBottom={2}
					paddingTop={2}
					variant='h4'
				/>
				<div className={classes.additional}>
					<FormGroup className={classes.formGroup}>{children}</FormGroup>
					{isArray(additionalChildren) ?
						<FormGroup>{additionalChildren}</FormGroup>
					:	null}
				</div>
			</CardContent>
		</Card>
	);
};

export default AdditionalCharactaristics;
