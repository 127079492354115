import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import {
	Card,
	CardContent,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	Divider,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { ItemCard, Label } from '../../../../../../components';
import { commaTimeStrings } from '../../../../../../shared/datetime';
import { handleHubReference } from '../../../../../../shared/utility';

interface BookingInfoProps {
	className?: string;
	fetchUnavailabilityData?: object;
}

const BookingInfo = (props: BookingInfoProps) => {
	const { fetchUnavailabilityData, className } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const unavailabilityStatus = {
		upcoming: 'default',
		cancelled: 'error',
		active: 'success',
		completed: 'disabled',
	};

	const unavailabilityData = [
		{
			label: 'views.ItemDetail.activities.start',
			content: commaTimeStrings(fetchUnavailabilityData.start),
		},
		{
			label: 'views.ItemDetail.activities.end',
			content: commaTimeStrings(fetchUnavailabilityData.end),
		},
		{ label: 'ui.dateCreated', content: commaTimeStrings(fetchUnavailabilityData.created) },
		{ label: 'Service type', content: t('nav.category.maintenance') },
		{ label: 'ui.provider', content: fetchUnavailabilityData.organisationReference.name },
		{
			label: 'ui.serviceCompany.label',
			content: fetchUnavailabilityData.itemInstance.itemReference?.serviceProviderReference?.name,
		},
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>
					{t('views.maintenance.unavailabilitys.details.serviceInfo')}
				</Typography>
				{fetchUnavailabilityData.status ?
					<Label shape='rounded' type={unavailabilityStatus[fetchUnavailabilityData.status]}>
						{t(`ui.status.${fetchUnavailabilityData.status}`)}
					</Label>
				:	null}
			</Box>
			<Divider />
			<CardContent className={classes.content}>
				<Box p={1.5}>
					<ItemCard
						address={handleHubReference(
							fetchUnavailabilityData.itemInstance.itemReference.hubReference,
						)}
						addressIcon={<RoomOutlinedIcon fontSize='small' />}
						// handleItemView={false}
						iconButton={false}
						image={
							fetchUnavailabilityData.itemInstance.itemReference?.imagesReference ?
								fetchUnavailabilityData.itemInstance.itemReference?.imagesReference[0]
							:	null
						}
						instanceName={fetchUnavailabilityData.itemInstance.name}
						locationIcon={<ApartmentIcon fontSize='small' />}
						locationName={fetchUnavailabilityData.hubReference.name}
						name={fetchUnavailabilityData.itemInstance.itemReference.name}
					/>
				</Box>
				<Table>
					<TableBody>
						{unavailabilityData ?
							<>
								{unavailabilityData.map((unavailability, index) => (
									<TableRow key={unavailability.label}>
										<TableCell className={index === 8 ? classes.bottomCell : ''}>
											{t(unavailability.label)}
										</TableCell>
										<TableCell className={index === 8 ? classes.bottomCell : ''}>
											{unavailability.content ? unavailability.content : '-'}
										</TableCell>
									</TableRow>
								))}
							</>
						:	null}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default BookingInfo;
