import { useTranslation } from 'react-i18next';

import { FinanceStatisticsLayout } from '~features/statistics';

import Page from '../page';

const RevenueStatisticsPage = () => {
	const { t } = useTranslation('general');

	return (
		<Page title={`${t('data')} - ${t('nav.financialReports.revenue')}`}>
			<FinanceStatisticsLayout />
		</Page>
	);
};

export default RevenueStatisticsPage;
