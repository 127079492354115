import { Grid } from '@mui/material';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import Address from './Address';
import BillingAndAdminInfo from './BillingAndAdminInfo';
import ContactInfo from './ContactInfo';
import Devices from './Devices';
import GeneralInfo from './GeneralInfo';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import Subscription from './Subscription';
import SummaryLoading from './SummaryLoading';
import { isObject } from '../../../../../shared/utility';

interface SummaryProps {
	className?: string;
	organisation?: object;
	loading?: boolean;
	verified?: boolean;
	currentUser?: {
		data?: any;
		loading?: boolean;
		error?: object | string;
	};
}

const Summary = (props: SummaryProps) => {
	const { className, organisation, loading, currentUser } = props;
	const { isSuperAdmin } = useAuthorize();

	const { data: currentUserData } = currentUser;
	const classes = useStyles();
	const isMyOrganisation =
		isObject(currentUserData) &&
		isObject(organisation) &&
		organisation.id === currentUserData.organisationReference.id;

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return loading ?
			<SummaryLoading />
		:	<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<GeneralInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<ContactInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<BillingAndAdminInfo organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<Address organisation={organisation} />
				</Grid>
				<Grid {...gridProps}>
					<Subscription organisation={organisation} />
				</Grid>
				{isSuperAdmin() && isMyOrganisation ? null : (
					<Grid {...gridProps}>
						<Devices organisation={organisation} />
					</Grid>
				)}
				<Grid {...gridProps}>
					{isSuperAdmin() ?
						<OtherActions organisation={organisation} />
					:	null}
				</Grid>
			</Grid>;
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps, null)(Summary);
