import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StyledButton } from '../../../components';

interface ActionDialogProps {
	loading?: boolean;
	open?: boolean;
	handleClose?(...args: unknown[]): unknown;
	actionButtonProps?: object;
	title?: string;
	noDivider?: boolean;
	contentStyles?: any;
	children?: React.ReactNode[] | React.ReactNode;
	variant?: string;
}

const ActionDialog = ({
	open,
	handleClose,
	actionButtonProps,
	title,
	children,
	loading,
	variant = 'contained-primary',
	noDivider,
	contentStyles,
}: ActionDialogProps) => {
	const { t } = useTranslation('general');
	const classes = useStyles();

	return (
		<Dialog classes={{ paper: classes.root }} onClose={handleClose} open={open}>
			<DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
			<DialogContent className={contentStyles ? contentStyles : classes.dialogContent}>
				{children}
			</DialogContent>
			{noDivider ? null : <Divider />}
			<DialogActions classes={{ root: classes.dialogActions }}>
				<StyledButton className={classes.button} onClick={handleClose} size='large'>
					{t('ui.button.contained.cancel')}
				</StyledButton>
				<StyledButton
					autoFocus
					className={classes.button}
					disabled={loading}
					onClick={actionButtonProps.action}
					size='large'
					variant={variant}
				>
					{actionButtonProps.text}
				</StyledButton>
			</DialogActions>
		</Dialog>
	);
};

export default ActionDialog;
