import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, Typography, IconButton, DialogContent } from '@mui/material';

import { useStyles } from './style';

interface InfoDialogProps {
	open?: boolean;
	dialogTitle?: string;
	onClose?(...args: unknown[]): unknown;
	children: React.ReactNode[] | React.ReactNode;
}

const InfoDialog = (props: InfoDialogProps) => {
	const { open, dialogTitle, onClose, children } = props;

	const classes = useStyles();

	return (
		<Dialog open={open}>
			<DialogTitle className={classes.title}>
				<Typography variant='h6'>{dialogTitle}</Typography>
				{onClose ?
					<IconButton aria-label='close' onClick={onClose} size='small'>
						<CloseIcon />
					</IconButton>
				:	null}
			</DialogTitle>
			<DialogContent dividers>{children}</DialogContent>
		</Dialog>
	);
};

export default InfoDialog;
