import path from 'path';

import { ItemCategoryEnum } from '~enums';
import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import DefectSeverityEnum from '../enum/defectSeverityEnum';
import Defect from '../interfaces/defect';

class DefectsService extends TopologyService {
	public readonly path = 'ticketdefects';

	constructor() {
		super('v1');
	}

	async getDefects({
		page = 1,
		pageSize = 10,
		...args
	}: {
		categoryId?: string;
	} & SearchPagedParameters): Promise<PagedResults<Defect>> {
		const { data } = await this._client.get<PagedResponse<DefectResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
			},
		});

		return this.mapPagedResponse(data, DefectsService.fromResponse);
	}

	/**
	 * Get a list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getItemGroupDefects(itemGroupId: string): Promise<Defect[]> {
		const { data } = await this._client.get<DefectResponse[]>(
			path.join('items', itemGroupId, 'defects'),
		);

		return data.map((el) => DefectsService.fromResponse(el));
	}

	static fromResponse(data: DefectResponse): Defect {
		const { id, title, type, categoryReference, organisationReference } = data;

		return {
			...DefectsService.fromBaseReferenceResponse({
				id: id,
				name: title,
			}),
			severity: type as DefectSeverityEnum,
			organisation: DefectsService.fromBaseReferenceResponse(organisationReference),
			category: categoryReference.type as ItemCategoryEnum,
		};
	}
}

interface DefectResponse extends IdReferenceResponse {
	organisationReference: BaseReferenceResponse;
	title: string;
	type: 'missing' | 'critical' | 'nonCritical';
	categoryReference: {
		type: 'string';
	} & BaseReferenceResponse;
}

export default DefectsService;
