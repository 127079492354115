import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			variants: [
				{
					props: { size: 'small' },
					style: { height: '44px' },
				},
			],
		},
		select: {
			'&:focus': {
				backgroundColor: palette.background.default,
			},
		},
	},
};
