import { useMemo, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { queryParameters } from '~constants';
import { Organisation, OrganisationsService } from '~features/organisations';

/**
 * A hook to sync the organisation to the url. Only the id is synchronized, so
 * this also provides a mechanism to the organisation from the backend based on
 * the id.
 * There should be one source of truth, and that is the id in the url! So other
 * variables used are only there to support that system.
 * @param defaultOrganisation
 * @returns
 */
const useUrlSearchParamsOrganisation = (
	defaultOrganisation?: Organisation | null,
): [Organisation | null, (value: Organisation) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const service = useRef(new OrganisationsService());

	const organisationId = searchParams.get(queryParameters.Organisation);

	const { data } = useSWR(
		organisationId != null ? [service.current.basePath, organisationId] : null,
		([_, args]) => service.current.getOrganisationById(args),
		{
			// We will use this hook over multiple components. This prevent this from overfetching
			// but try to keep it up to date every once in a while. The organisation may change very
			// rarely
			dedupingInterval: 10000, // ms
		},
	);

	const organisation = useMemo(() => {
		if (!organisationId) {
			return null;
		}

		return data ?? null;
	}, [organisationId, data]);

	const setOrganisation = (value: Organisation | null) => {
		if (value == null) {
			searchParams.delete(queryParameters.Organisation);
		} else {
			searchParams.set(queryParameters.Organisation, value.id);
		}

		setSearchParams(searchParams);
	};

	return [organisation, setOrganisation];
};

export default useUrlSearchParamsOrganisation;
