import { Card, CardContent, Box, Typography } from '@mui/material';

import Placeholder from '../../../../assets/images/ic-placeholder.svg';
import { LoadingBar } from '../../../../components';
import { isNull } from '../../../../shared/utility';
import { useStyles } from '../../style';

interface ItemCardProps {
	cardData?: object;
	handleLink?(...args: unknown[]): unknown;
	handleCardNameLink?(...args: unknown[]): unknown;
}

const ItemCard = (props: ItemCardProps) => {
	const { cardData, handleLink, handleCardNameLink } = props;
	const classes = useStyles();

	return (
		<Card sx={{ padding: 0, paddingBottom: 0, minHeight: 95, marginBottom: '16px' }}>
			<CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
				<Box alignItems='center' display='flex'>
					<div className={classes.imageContainer}>
						{isNull(cardData) ?
							<img className={classes.imageSpacing} src={Placeholder} />
						:	<img className={classes.imageSpacing} src={cardData.image} />}
					</div>
					<Box display='flex' flexDirection='column' pl={0.5}>
						<Typography variant='h5'>
							{isNull(cardData) ?
								<LoadingBar />
							:	handleCardNameLink()}
						</Typography>
						{handleLink()}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ItemCard;
