import { Grid } from '@mui/material';
import clsx from 'clsx';

import AddressInfo from './AddressInfo';
import LocationInfo from './LocationInfo';
import OpeningHours from './OpeningHours';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

interface SummaryProps {
	className?: string;
	hub?: object;
	loading?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, hub, loading } = props;

	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return hub && !loading ?
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<LocationInfo hub={hub} />
				</Grid>
				<Grid {...gridProps}>
					<AddressInfo hub={hub} />
				</Grid>
				<Grid {...gridProps}>
					<OpeningHours hub={hub} />
				</Grid>
				<Grid {...gridProps}>
					<OtherActions hub={hub} hubId={hub.id} />
				</Grid>
			</Grid>
		:	<SummaryLoading />;
};

export default Summary;
