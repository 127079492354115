import { Grid } from '@mui/material';
import clsx from 'clsx';

import DeviceAllocation from './DeviceAllocation';
import DeviceInfo from './DeviceInfo';
import HeartbeatDevice from './HeartbeatDevice/HeartbeatDevice';
import { useStyles } from './style';
import VehicleStatus from './VehicleStatus/VehicleStatus';
import { isObject } from '../../../../shared/utility';
import SummaryLoading from '../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

interface SummaryProps {
	className?: string;
	deviceData?: object;
	loading?: boolean;
	isVehicle?: boolean;
	id?: string;
	gpsIcons?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, deviceData, loading, id, isVehicle, gpsIcons } = props;

	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return isObject(deviceData) && !loading ?
			<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<DeviceInfo deviceData={deviceData} />
				</Grid>
				<Grid {...gridProps}>
					{isVehicle ?
						<VehicleStatus
							deviceType={deviceData.deviceType}
							id={id}
							vehicleData={deviceData.deviceStatus}
						/>
					:	<HeartbeatDevice
							deviceData={deviceData}
							deviceType={deviceData.deviceType}
							gpsIcons={gpsIcons}
							id={id}
						/>
					}
				</Grid>
				{deviceData?.organisationReference && (
					<Grid {...gridProps}>
						<DeviceAllocation deviceData={deviceData} />
					</Grid>
				)}
			</Grid>
		:	<SummaryLoading />;
};

export default Summary;
