import path from 'path';

import { ItemCategoryEnum } from '~enums';
import { HubsService } from '~features/hub';
import { PagedResults } from '~interfaces';
import { BasePagedParameters, SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Item from '../interfaces/item';

class ItemsService extends TopologyService {
	/**
	 * Notice!! To prevent confusement. In this application (the admin panel)
	 * we the following terminilogy
	 * - Item = Instance aka ItemInstance
	 * - ItemGroup = Group of items (in other aspects of Topology may be referred as item)
	 * So the backend uses itemInstance for items
	 */
	public readonly path: string;

	/**
	 *
	 */
	constructor(apiVersion: 'v1' | 'v2' = 'v2') {
		super(apiVersion);

		this.path = path.join('iteminstances');
	}

	/**
	 * Get a list of items
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getItems({
		page = 1,
		pageSize = 10,
		access = ['owned', 'shared'],
		...args
	}: {
		access: ('owned' | 'shared' | 'public')[];
	} & SearchPagedParameters): Promise<PagedResults<Item>> {
		const { data } = await this._client.get<PagedResponse<ItemResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				access: access.join(','),
				organisationId: args.organisationId,
				searchTerm: args.searchQuery,
			},
		});

		return this.mapPagedResponse(data, ItemsService.fromResponse);
	}

	/**
	 * Get details from a single item
	 * @param id The item(instance) identifyer
	 * @returns
	 */
	async getItemById(id: string): Promise<Item> {
		const { data } = await this._client.get<ItemResponse>(path.join(this.path, id));

		return ItemsService.fromResponse(data);
	}

	/**
	 * Delete an item(instance) by id
	 * @param id The identifyer of the item(instance)
	 * @returns
	 */
	async deleteItem(id: string): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: ItemResponse): Item {
		const { id, name, category, itemReference, hubReference, organisationReference, ...rest } =
			data;

		return {
			...rest,
			...ItemsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			organisation: ItemsService.fromBaseReferenceResponse(organisationReference),
			category: category as unknown as ItemCategoryEnum,
			itemGroup: ItemsService.fromBaseReferenceResponse(itemReference),
			hub: HubsService.fromResponse(hubReference),
		};
	}
}

interface ItemResponse extends BaseReferenceResponse {
	organisationReference: BaseReferenceResponse;
	category: string;
	itemReference: BaseReferenceResponse;
	hubReference: {
		latitude: number;
		longitude: number;
		address: object;
	} & BaseReferenceResponse;
}

export default ItemsService;
