import { CardContent } from '@mui/material';

import { useStyles } from './style';

interface CustomCardContentProps {
	children?: any;
}

const CustomCardContent = (props: CustomCardContentProps) => {
	const classes = useStyles();

	const { children, ...rest } = props;

	return (
		<CardContent className={classes.content} {...rest}>
			{children}
		</CardContent>
	);
};

export default CustomCardContent;
