import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Header from './Header';
import Requests from './Requests';
import { useStyles } from './style';
import { Page } from '../../components';

interface PartnershipsProps {}

const Partnerships = (props: PartnershipsProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();

	const { tab } = useParams();

	const classes = useStyles();

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const tabs = [
		{ value: 'partners', label: t('ui.category.partners') },
		{ value: 'incoming-requests', label: t('ui.category.incomingRequests') },
		{ value: 'outgoing-requests', label: t('ui.category.outgoingRequests') },
	];

	return (
		<Page className={classes.root} title={t('views.partnerships.page.title')}>
			<Header />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'partners' && <Requests requestsType='accepted' />}
				{tab === 'incoming-requests' && <Requests requestsType='incoming' />}
				{tab === 'outgoing-requests' && <Requests requestsType='outgoing' />}
			</div>
		</Page>
	);
};

export default Partnerships;
