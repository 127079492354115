import { useState, useEffect } from 'react';

import { Typography, List, ListItem, Radio, FormControlLabel, Checkbox, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { FormField } from '../../../../../../components';
import { useWizardFormField, useDebouncedNumberSave } from '../../../../../../shared/hooks';
import { isBoolean, isObject } from '../../../../../../shared/utility';
import AdditionalCharactaristics from '../AdditionalCharactaristics';
import CharacteristicsCard from '../CharacteristicsCard';
import EditButtons from '../EditButtons';
import { useStyles } from '../style';

interface BiksCharacteristicsProps {
	className?: string;
	save?(...args: unknown[]): unknown;
	itemStep?: number;
	bikePropulsionType?: string;
	updatedBikeRange?: number;
	bikeKmRange?: number | object | string;
	bikeSex?: string;
	bikeModel?: string;
	hasLuggageCarrier?: boolean;
	numberOfGears?: number | object | string;
	isEdit?: boolean;
	isFinishingEdit?: boolean;
	onClose?(...args: unknown[]): unknown;
	characteristics?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const BiksCharacteristics = (props: BiksCharacteristicsProps) => {
	const {
		itemStep,
		save,
		isEdit,
		isFinishingEdit,
		onClose,
		bikePropulsionType,
		bikeKmRange,
		bikeSex,
		bikeModel,
		hasLuggageCarrier,
		numberOfGears,
		characteristics,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const [bikeCharacteristics, setBiksCharacteristics] = useState({
		hasLuggageCarrier: false,
	});

	const bikeKmRangeValue = useWizardFormField(bikeKmRange, {
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave(
		'updatedBikeKmRange',
		bikeKmRangeValue.value,
		bikeKmRangeValue.isValid,
		save,
	);

	const numberOfGearsValue = useWizardFormField(numberOfGears, {
		minLength: 1,
		maxLength: 5,
		isNumeric: true,
	});
	useDebouncedNumberSave(
		'updatedNumberOfGears',
		numberOfGearsValue.value,
		numberOfGearsValue.isValid,
		save,
	);

	const handleCharacteristics = (event) => {
		const target = event.target;
		setBiksCharacteristics({ ...bikeCharacteristics, [target.name]: target.checked });
		save({
			[`updated${target.name.charAt(0).toUpperCase() + target.name.substring(1)}`]: target.checked,
		});
	};

	const {
		data: characteristicsData,
		loading: characteristicsLoading,
		error: characteristicsError,
	} = characteristics;
	const readyData =
		isObject(characteristicsData) && !characteristicsLoading && !characteristicsError;

	const types =
		readyData ?
			characteristicsData.bikes.filter((bike) => (bike.name === 'bikeModel' ? bike : null))
		:	null;
	const models =
		readyData ?
			characteristicsData.bikes.filter((bike) => (bike.name === 'bikeSex' ? bike : null))
		:	null;
	const support =
		readyData ?
			characteristicsData.bikes.filter((bike) => (bike.name === 'bikePropulsionType' ? bike : null))
		:	null;

	const primaryCharacteristics = [
		{
			label: t('ui.characteristics.hasLuggageCarrier'),
			checked:
				isBoolean(hasLuggageCarrier) ? hasLuggageCarrier : bikeCharacteristics.hasLuggageCarrier,
			action: handleCharacteristics,
			name: 'hasLuggageCarrier',
		},
	];

	const handleTypeOptions = (event, item) => {
		save({ updatedBikeModel: item.name });
	};

	const handleSexType = (event, item) => {
		save({ updatedBikeSex: item.name });
	};

	const handlePropulsionType = (event, item) => {
		save({ updatedBikePropulsionType: item.name });
	};

	useEffect(() => {
		if (bikePropulsionType === 'noSupport') {
			save({ updatedBikeKmRange: null });
		}
	}, [bikePropulsionType]);

	return (
		<>
			<CharacteristicsCard isEdit={isEdit} itemStep={itemStep}>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.bikeModel')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						types[0].enumValues.map((type) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: bikeModel === type.name,
								})}
								key={type.id}
								onClick={(event) => handleTypeOptions(event, type)}
							>
								<Radio
									checked={bikeModel === type.name}
									className={classes.optionRadio}
									onChange={(event) => handleTypeOptions(event, type)}
									value={type.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${type.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.bikeSex')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						models[0].enumValues.map((model) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: bikeSex === model.name,
								})}
								key={model.id}
								onClick={(event) => handleSexType(event, model)}
							>
								<Radio
									checked={bikeSex === model.name}
									className={classes.optionRadio}
									onChange={(event) => handleSexType(event, model)}
									value={model.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${model.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('ui.characteristics.bikePropulsionType')}
				</Typography>
				<List className={classes.listComponent}>
					{readyData &&
						support[0].enumValues.map((item) => (
							<ListItem
								button={true}
								className={clsx(classes.option, {
									[classes.selectedOption]: bikePropulsionType === item.name,
								})}
								key={item.name}
								onClick={(event) => handlePropulsionType(event, item)}
							>
								<Radio
									checked={bikePropulsionType === item.name}
									className={classes.optionRadio}
									onChange={(event) => handlePropulsionType(event, item)}
									value={item.name}
								/>
								<div className={classes.optionDetails}>
									<Typography variant='h5'>{t(`ui.characteristics.${item.name}`)}</Typography>
								</div>
							</ListItem>
						))}
				</List>
				<Typography className={classes.labelSpacing} variant='h5'>
					{t('views.addItem.characteristicsBikes.whatIsTheMaxNumberOfGears')}
				</Typography>
				<Box className={classes.listComponent}>
					<Box mb={1} width='180px'>
						<FormField
							fullWidth={true}
							hideCharacterCounter={!numberOfGearsValue.hasFocus}
							label={t('views.addItem.characteristicsBikes.gears')}
							maxLength={5}
							name='numberOfGears'
							required
							variable={numberOfGearsValue}
						/>
					</Box>
				</Box>
				{bikePropulsionType === 'eBike' || bikePropulsionType === 'pedelec' ?
					<>
						<Typography className={classes.labelSpacing} variant='h5'>
							{t('views.addItem.characteristicsBikes.whatIsTheMaxRangeOfElectric')}
						</Typography>
						<Box className={classes.listComponent}>
							<Box mb={2} width='180px'>
								<FormField
									fullWidth={true}
									hideCharacterCounter={!bikeKmRangeValue.hasFocus}
									label={t('ui.characteristics.kmRange')}
									maxLength={5}
									name='rangeKm'
									required
									variable={bikeKmRangeValue}
								/>
							</Box>
						</Box>
					</>
				:	null}
			</CharacteristicsCard>
			<AdditionalCharactaristics isEdit={isEdit}>
				{primaryCharacteristics.map((item) => (
					<FormControlLabel
						control={<Checkbox checked={item.checked} name={item.name} onChange={item.action} />}
						key={item.name}
						label={item.label}
					></FormControlLabel>
				))}
			</AdditionalCharactaristics>
			{isEdit ?
				<EditButtons isFinishingEdit={isFinishingEdit} onClose={onClose} />
			:	null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		characteristics: state.details.characteristics,
	};
};

export default connect(mapStateToProps)(BiksCharacteristics);
