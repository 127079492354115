import { MenuItem, SelectProps } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { AsyncSelect } from '~components';

import ItemCategory from '../../interfaces/itemCategory';
import ItemCategoryService from '../../services/itemCategoryService';

type ItemCategorySelectProps = {
	value?: ItemCategory | null;
} & SelectProps;

const service = new ItemCategoryService();

const ItemCategorySelect = ({
	// label = i18n.t('ui.label.category'),
	value = null,
	...props
}: ItemCategorySelectProps) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);

	const { data, isLoading } = useSWRImmutable(service.basePath, () => service.getCategories());

	return (
		<AsyncSelect
			{...props}
			clearable
			emptyLabel={t('allResources', { resource: t('categories') })}
			// label={label}
			loading={isLoading}
			value={value}
			options={data ?? []}
		/>
	);
};

export default ItemCategorySelect;
