import { useEffect } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Paper, Link, List, ListItem, Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import BookingIcons from '../../../../assets/icons/icons-booking.svg';
import { LoadingBar, Label } from '../../../../components';
import { commaTimeStrings } from '../../../../shared/datetime';
import { handleBookingState } from '../../../../shared/functionality';
import { isInteger, isFullArray, isFullString, isUndefined } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../../style';

interface LatestActivityCardProps {
	itemInstanceId?: number;
	requestedResources?: number;
	onLatestActivity?(...args: unknown[]): unknown;
	selectedFleet?: object;
	onUpdateListState?(...args: unknown[]): unknown;
	latestActivity?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
}

const LatestActivityCard = (props: LatestActivityCardProps) => {
	const {
		itemInstanceId,
		requestedResources,
		onLatestActivity,
		latestActivity,

		selectedFleet,
		onUpdateListState,
	} = props;
	const { t } = useTranslation('general');

	const { data: latestActivityData, loading: latestActivityLoading } = latestActivity;

	const classes = useStyles();

	const statusColors = {
		upcoming: '#e4eefb',
		active: '#cff5e7',
		overtime: '#fff7ef',
		cancelled: '#fff7ef',
		completed: '#eeeeee',
		pending: '#fff7ef',
	};

	const fontColor = {
		upcoming: '#15263e',
		active: '#285a47',
		overtime: '#ce2a3d',
		cancelled: '#ce2a3d',
		completed: '#586b85',
		pending: '#ce2a3d',
	};

	const approvalStatusColors = { declined: '#fff7ef', pending: '#fff7ef' };

	const approvalFontColor = { declined: '#ce2a3d', pending: '#ce2a3d' };

	useEffect(() => {
		if (isInteger(itemInstanceId)) {
			onLatestActivity(itemInstanceId, requestedResources);
		} else if (isUndefined(itemInstanceId) && isUndefined(selectedFleet?.instanceId)) {
			onUpdateListState('latestActivity');
		}
	}, [itemInstanceId, selectedFleet]);

	const handleResultsAndLoading = () => {
		if (latestActivityLoading) {
			return (
				<Box>
					<ListItem>
						<LoadingBar />
					</ListItem>
					<ListItem>
						<LoadingBar />
					</ListItem>
					<ListItem>
						<LoadingBar />
					</ListItem>
				</Box>
			);
		} else if (!isFullArray(latestActivityData)) {
			return (
				<Paper className={classes.tipCard}>
					<InfoOutlinedIcon className={classes.icon} fontSize='small' />
					<Typography className={classes.tip}>
						{t('views.mapWiew.drawer.emptyActivities')}
					</Typography>
				</Paper>
			);
		}
	};

	const handleStatus = (activity) => {
		const approval = activity.approvalState;
		const status = activity.status;
		return (
			<Label
				color={
					(approval === 'pending' && status !== 'cancelled') || approval === 'declined' ?
						approvalStatusColors[approval]
					:	statusColors[status.toLowerCase()]
				}
				fontColor={
					(approval === 'pending' && status !== 'cancelled') || approval === 'declined' ?
						approvalFontColor[approval]
					:	fontColor[status.toLowerCase()]
				}
				shape='rounded'
			>
				{handleBookingState(activity)}
			</Label>
		);
	};

	const handleType = (type) =>
		type === 'booking' ? t('ui.label.booking') : t('views.planboard.info.unavailability.title');

	const handleLinkBooking = (id, returnButton = 'nav.category.fleetManagement') => {
		return (
			<Link
				className={classes.deviceCardItems}
				color='primary'
				component={RouterLink}
				to={`/booking/booking-detail/${id}/summary`}
				state={{ from: location.pathname, label: t(returnButton) }}
			>
				{id}
			</Link>
		);
	};

	const handleLinkUser = (activity, returnButton = 'nav.category.fleetManagement') => {
		return (
			<Link
				className={classes.deviceCardItems}
				color='primary'
				component={RouterLink}
				to={`/user-management/users/${activity.user.id}/summary`}
				state={{ from: location.pathname, label: t(returnButton) }}
			>
				{activity.user.name}
			</Link>
		);
	};

	return (
		<List sx={{ padding: '0px 0px' }}>
			<Typography variant='h6'>{t('ui.label.activities')}</Typography>

			{!latestActivityLoading && isFullArray(latestActivityData) ?
				latestActivityData.map((activity) => (
					<div key={activity.id}>
						<ListItem className={classes.activityListItem} key={activity.id}>
							<Box className={classes.activityListItemStatus}>
								<Box display='flex'>
									<Box pr={1}>
										<img alt='BookingIcons' height='16' src={BookingIcons} width='16' />
									</Box>
									<Typography className={classes.deviceCardItems}>
										{`${handleType(activity.activityType)}:`} {handleLinkBooking(activity.id)}
									</Typography>
								</Box>
								{handleStatus(activity)}
							</Box>
							<Typography variant='body2'>
								{`${commaTimeStrings(activity.start)} • ${isFullString(activity.end) ? commaTimeStrings(activity.end) : ''}`}
							</Typography>
							<Typography className={classes.deviceCardItems} gutterBottom>
								{handleLinkUser(activity)}
							</Typography>
						</ListItem>
						<Divider />
					</div>
				))
			:	handleResultsAndLoading()}
		</List>
	);
};

const mapStateToProps = (state) => {
	return {
		latestActivity: state.list.latestActivity,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLatestActivity: (instanceId, quantity) =>
			dispatch(actions.latestActivity(instanceId, quantity)),
		onUpdateListState: (data) => dispatch(actions.updateListState(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LatestActivityCard);
