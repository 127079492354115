import {
	Box,
	Card,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { Label } from '../../../../../../components';
import { usePaymentDetails } from '../../../../../../shared/hooks';

interface BillingInfoProps {
	className?: string;
	paymentMethod: object;
}

const BillingInfo = (props: BillingInfoProps) => {
	const { paymentMethod } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const statusLabelType = {
		unavailable: 'disabled',
		started: 'default',
		pending: 'default',
		authorized: 'success',
		rejected: 'error',
		disabled: 'warning',
	};

	const paymentDetails = usePaymentDetails(paymentMethod);

	return (
		<Card className={classes.root}>
			<Box display='flex' justifyContent='space-between' pb={2} pl={3} pr={3} pt={2}>
				<Typography variant='h5'>{t('views.userDetails.summary.cardHeaders.billing')}</Typography>
				<Label type={statusLabelType[paymentMethod.status]}>
					{t(`ui.status.${paymentMethod.status}`)}
				</Label>
			</Box>
			<Divider />
			<CardContent>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.paymentMethod')}</TableCell>
							<TableCell>{paymentDetails.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>
								{paymentMethod.card ? t('ui.label.cardDetails') : t('ui.label.bankDetails')}
							</TableCell>
							<TableCell>
								{paymentMethod.status === 'authorized' ? paymentDetails.bank : '-'}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};

export default BillingInfo;
