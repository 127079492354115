import { Box } from '@mui/material';

interface CustomBoxProps {
	children?: any;
}

const CustomBox = (props: CustomBoxProps) => {
	const { children, ...rest } = props;
	return <Box {...rest}>{children}</Box>;
};

export default CustomBox;
