import { Paper } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { LoadingBar } from '../../../../components';

interface LoadingStateProps {
	className?: string;
}

const LoadingState = (props: LoadingStateProps) => {
	const classes = useStyles();
	const { className } = props;

	return (
		<Paper className={clsx(classes.root, className)}>
			<table className={clsx(classes.table, classes.loadingTable)}>
				<thead className={classes.tableHeader}>
					<tr>
						<td className={clsx(classes.resourceArea, classes.headerArea)}></td>
						<td className={clsx(classes.divider, classes.headerDivider, classes.headerArea)}></td>
						<td className={classes.headerArea}>
							<div className={classes.scrollerClip}>
								<div className={classes.scroller}>
									<div className={classes.scrollerCanvas}>
										<div className={classes.scrollerContent}>
											<table className={classes.table}>
												<tbody>
													<tr>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>00:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>00:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>01:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>01:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>02:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>02:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>03:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>03:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>04:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>04:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>05:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>05:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>06:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>06:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>07:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>07:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>08:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>08:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>09:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>09:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>10:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>10:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>11:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>11:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>12:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>12:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>13:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>13:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>14:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>14:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>15:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>15:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>16:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>16:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>17:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>17:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>18:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>18:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>19:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>19:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>20:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>20:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>21:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>21:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>22:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>22:30</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>23:00</span>
															</div>
														</th>
														<th className={classes.time}>
															<div className={classes.timeTextContainer}>
																<span className={classes.timeText}>23:30</span>
															</div>
														</th>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className={classes.resourceArea}>
							<table className={classes.table}>
								<tbody>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
									<tr className={classes.resourceRow}>
										<td className={classes.resourceData}>
											<LoadingBar />
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td className={clsx(classes.divider, classes.bodyDivider)}></td>
						<td className={classes.eventArea}>
							<table className={classes.table}>
								<tbody>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
									<tr className={classes.eventRow}>
										<td></td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</Paper>
	);
};

export default LoadingState;
