import { useMemo, useState } from 'react';

import { CloudDownload as CloudDownloadIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Grid2, IconButton, ListItemIcon, Menu, MenuItem, ToggleButton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DatePickerNew, ToggleButtonGroup } from '~components';
import { PeriodicityEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { HubAutocomplete } from '~features/hub';
import { ItemAutocomplete, ItemCategorySelect } from '~features/items';
import { PartnerAutocomplete } from '~features/organisations';

import StatisticsFilters from '../../interfaces/statisticsFilters';

interface StatisticsToolbarProps {
	organisationId?: string;
	date: Dayjs;
	onDateChange?: (value: Dayjs | null) => void;
	periodicity: PeriodicityEnum | null;
	onPeriodicityChange?: (value: PeriodicityEnum) => void;
	filters: StatisticsFilters;
	onFiltersChange: (value: StatisticsFilters) => void;
	disableFilters?: boolean;
}

const StatisticsToolbar = ({
	filters = {},
	onFiltersChange,
	date,
	onDateChange,
	periodicity,
	onPeriodicityChange,
	disableFilters = false,
	...props
}: StatisticsToolbarProps) => {
	const { isSuperAdmin } = useAuthorize();
	const { t } = useTranslation('general');
	const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);

	const filterElements = useMemo(
		() => [
			<ItemAutocomplete
				value={filters.item ?? null}
				onChange={(_, value) => onFiltersChange?.({ ...filters, item: value })}
				organisationId={props.organisationId}
			/>,
			<HubAutocomplete
				value={filters.hub ?? null}
				onChange={(_, value) => onFiltersChange?.({ ...filters, hub: value })}
				organisationId={props.organisationId}
			/>,
			<ItemCategorySelect
				displayEmpty
				value={filters.category}
				onChange={(_, value) => onFiltersChange({ ...filters, category: value })}
			/>,
			...(!isSuperAdmin() ?
				[
					<PartnerAutocomplete
						value={filters.partner}
						onChange={(_, value) => onFiltersChange({ ...filters, partner: value })}
						includeUserOrganisation
					/>,
				]
			:	[]),
		],
		[filters, props.organisationId],
	);

	return (
		<>
			<Grid2 container gap={1} direction='row-reverse'>
				<Grid2
					container
					size={{ xs: 12, sm: 'auto' }}
					direction='row'
					gap='inherit'
					sx={{ alignItems: 'center' }}
				>
					<Grid2>
						<ToggleButtonGroup
							size='small'
							exclusive
							value={periodicity}
							onChange={(_, value) => value != null && onPeriodicityChange?.(value)}
						>
							{[PeriodicityEnum.Month, PeriodicityEnum.Year].map((el: PeriodicityEnum) => (
								<ToggleButton key={el} value={el}>
									{el}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</Grid2>
					<Grid2>
						<DatePickerNew
							periodicity={periodicity ?? undefined}
							value={date}
							onChange={(value) => value != null && onDateChange?.(value)}
						/>
					</Grid2>
					<Grid2>
						<IconButton
							onClick={(e) => setAnchorElMore(e.currentTarget)}
							sx={{ height: 'fit-content' }}
						>
							<MoreVertIcon />
						</IconButton>
					</Grid2>
				</Grid2>

				{!disableFilters && (
					<Grid2 container size={{ xs: 12, sm: 12, md: 'grow' }} direction='row' gap='inherit'>
						{filterElements.map((el, i) => (
							<Grid2 key={i} size='grow' sx={{ minWidth: 150, maxWidth: 250 }}>
								{el}
							</Grid2>
						))}
					</Grid2>
				)}
			</Grid2>

			<Menu
				anchorEl={anchorElMore}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElMore)}
				onClose={() => setAnchorElMore(null)}
				sx={{ mt: '45px' }}
			>
				<MenuItem disabled>
					<ListItemIcon>
						<CloudDownloadIcon />
					</ListItemIcon>
					<ListItemIcon>{t('export')}</ListItemIcon>
				</MenuItem>
			</Menu>
		</>
	);
};

export default StatisticsToolbar;
