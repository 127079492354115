import { Drawer } from '@mui/material';

import { useStyles } from './style';

interface CustomDrawerProps {
	open: boolean;
	onClose(...args: unknown[]): unknown;
	children?: any;
}

const CustomDrawer = (props: CustomDrawerProps) => {
	const { open, onClose, children } = props;

	const classes = useStyles();

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => onClose()}
			open={open}
			variant='temporary'
		>
			{children}
		</Drawer>
	);
};

export default CustomDrawer;
