import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Divider, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserRoleEnum } from '~enums';

import pageButtonsAtom from '../atoms/pageButtonsAtom';
import pageTitleAtom from '../atoms/pageTitleAtom';
import PageNavBar from '../navBar/pageNavBar';
import { OrganisationAutocomplete } from '~features/organisations';
import { useUrlSearchParamsOrganisation } from '~hooks';
import { useAuthorize } from '~features/authentication';

interface Page {
	label: string;
	path: string;
	// Otherwise defaults to admin?
	roles?: UserRoleEnum[];
}

interface PageHeaderProps {
	title: string;
	pages?: Page[];
	enableOrganisationSelect?: boolean;
}

const PageHeader = ({ pages, title, enableOrganisationSelect = false }: PageHeaderProps) => {
	const { t } = useTranslation('general');

	const location = useLocation();
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();

	const pageTitle = useAtomValue(pageTitleAtom);
	const pageButtons = useAtomValue(pageButtonsAtom);
	const [organisation, setOrganisation] = useUrlSearchParamsOrganisation();

	const pathDepth = location.pathname.split('/').length - 1;

	const handleGoBackClick = () => {
		if (location.state?.from != null) {
			navigate(location.state?.from);
		} else {
			navigate('..', { relative: 'path' });
		}
	};

	return (
		<Stack width={1} spacing={1} alignItems='flex-start'>
			{(pathDepth > 2 || location.state?.from != null) && (
				<Button startIcon={<KeyboardArrowLeftIcon />} onClick={handleGoBackClick}>
					{location.state?.label ?? t('ui.button.contained.back')}
				</Button>
			)}
			<Stack
				width={1}
				spacing={1}
				direction='row'
				justifyContent='space-between'
				alignItems='center'
			>
				<Stack direction='row' spacing={3}>
					{(pageTitle != null || title != null) && (
						<Typography variant='h3' py={1}>
							{pageTitle || title}
						</Typography>
					)}

					{enableOrganisationSelect && isSuperAdmin() && (
						<OrganisationAutocomplete
							value={organisation}
							onChange={(_, newValue) => setOrganisation(newValue)}
							sx={{ width: 250 }}
						/>
					)}
				</Stack>

				<Stack direction='row' gap={1}>
					{pageButtons.length > 0 && (
						<ButtonGroup
							variant='text'
							sx={{
								'.MuiButtonGroup-grouped:not(:last-of-type)': {
									borderColor: 'transparent',
								},
							}}
						>
							{pageButtons.map((el, i) => (
								<Button key={i} {...el} />
							))}
						</ButtonGroup>
					)}
				</Stack>
			</Stack>
			<Box width={1}>{pages != null && pages.length > 0 && <PageNavBar pages={pages} />}</Box>
		</Stack>
	);
};

export type { Page };

export default PageHeader;
