import { useEffect, useRef } from 'react';

import { Popper } from '@mui/material';

interface InputPopperProps {
	inputRef?: object;
	openPopper?: boolean;
	children?: React.ReactNode;
}

const InputPopper = ({ inputRef = {}, openPopper = false, children = null }: InputPopperProps) => {
	const popperRef = useRef(null);

	useEffect(() => {
		if (popperRef.current) {
			popperRef.current.update();
		}
	}, []);

	return (
		<Popper
			anchorEl={inputRef.current ? inputRef.current : null}
			disablePortal
			modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
			open={openPopper}
			popperRef={popperRef}
			style={{ width: inputRef.current ? inputRef.current.clientWidth : null, zIndex: 2 }}
		>
			{children}
		</Popper>
	);
};

export default InputPopper;
