import { StyledTooltip } from './style';
import { isUndefined } from '../../../shared/utility';

interface InfoPopupProps {
	children?: React.ReactNode;
	title?: string;
	heading?: string;
	open?: boolean;
}

const InfoPopup = (props: InfoPopupProps) => {
	const { heading, title, children, open } = props;

	return (
		<StyledTooltip
			title={
				<>
					{heading ?
						<h4>{heading}</h4>
					:	null}
					{title}
				</>
			}
			{...(!isUndefined(open) && { open })}
		>
			{children}
		</StyledTooltip>
	);
};

export default InfoPopup;
