import { Card } from '@mui/material';

interface CustomCardProps {
	children?: any;
}

const CustomCard = (props: CustomCardProps) => {
	const { children, ...rest } = props;
	return <Card {...rest}>{children}</Card>;
};

export default CustomCard;
