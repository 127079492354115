import path from 'path';

import { ItemCategoryEnum } from '~enums';
import { HubsService } from '~features/hub';
import { ItemGroupsService } from '~features/itemGroups';
import { PagedResults } from '~interfaces';
import { BasePagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Item from '../interfaces/item';

class ItemsInItemGroupService extends TopologyService {
	/**
	 * Notice!! To prevent confusement. In this application (the admin panel)
	 * we the following terminilogy
	 * - Item = Instance aka ItemInstance
	 * - ItemGroup = Group of items (in other aspects of Topology may be referred as item)
	 * So the backend uses itemInstance for items
	 */
	public readonly path: string;

	/**
	 * Set the item group for this service.
	 * TODO: is this smart to already put the itemgroupid in the constructor?
	 * Means we need to reconstruct the service for every new itemGroupId
	 * @param itemGroupId
	 */
	constructor(itemGroupId: string) {
		super('v1');

		this.path = path.join('items', itemGroupId, 'iteminstances');
	}

	/**
	 * Get a list of items
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getItems({
		page = 1,
		pageSize = 10,
		...args
	}: BasePagedParameters): Promise<PagedResults<Item>> {
		const { data } = await this._client.get<PagedResponse<ItemResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
			},
		});

		return this.mapPagedResponse(data, ItemsInItemGroupService.fromResponse);
	}

	async getAutocompletedItems({ count = 10, ...args }: { count: number; searchQuery?: string }) {
		const { data } = await this._client.get<PagedResponse<ItemResponse>>(
			'autocompletes/instances',
			{
				params: {
					requestedResources: count,
					searchTerm: args.searchQuery,
				},
			},
		);
	}

	/**
	 * Get details from a single item
	 * @param id The item(instance) identifyer
	 * @returns
	 */
	async getItemById(id: string): Promise<Item> {
		const { data } = await this._client.get<ItemResponse>(path.join(this.path, id));

		return ItemsInItemGroupService.fromResponse(data);
	}

	/**
	 * Delete an item(instance) by id
	 * @param id The identifyer of the item(instance)
	 * @returns
	 */
	async deleteItem(id: string): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: ItemResponse): Item {
		const { id, name, category, itemReference, hubReference, organisationReference, ...rest } =
			data;

		return {
			...rest,
			...ItemsInItemGroupService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			organisation: ItemsInItemGroupService.fromBaseReferenceResponse(organisationReference),
			category: category as unknown as ItemCategoryEnum,
			// itemGroup: ItemsInItemGroupService.fromBaseReferenceResponse(itemReference),
			itemGroup: ItemGroupsService.fromResponse(itemReference),
			hub: HubsService.fromResponse(hubReference),
		};
	}
}

interface ItemResponse extends BaseReferenceResponse {
	organisationReference: BaseReferenceResponse;
	category: string;
	itemReference: BaseReferenceResponse;
	hubReference: {
		latitude: number;
		longitude: number;
		address: object;
	} & BaseReferenceResponse;
}

export default ItemsInItemGroupService;
