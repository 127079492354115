import { useEffect, useState } from 'react';

import { Box, Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { userInfoAtom } from '~atoms';

import { useStyles } from './style';
import { Table, Label } from '../../../../components';
import { isObject, decimalAmount, getFinanceTableHeaders } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface ActiveFinanceProps {
	financeType?: string;
	date?: string;
	onFetchCurrentExpenses?(...args: unknown[]): unknown;
	isSuperAdmin?: boolean;
	currentExpenses?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const ActiveFinance = (props: ActiveFinanceProps) => {
	const { onFetchCurrentExpenses, currentExpenses, isSuperAdmin } = props;
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);

	const classes = useStyles();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);

	const { data: currentExpensesData, loading: currentExpensesLoading } = currentExpenses;

	useEffect(() => {
		if (shouldDoInitialFetch && !currentExpensesLoading) {
			onFetchCurrentExpenses();
			setShouldDoInitialFetch(false);
		}
	}, [currentExpensesLoading]);

	const tableHeader = getFinanceTableHeaders();

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const handlePathName = () => {
		const path = `/financeManagement/expenses/${currentExpensesData.start}/${currentExpensesData.title}/`;
		if (isSuperAdmin) {
			return `${path}organisations/expenses`;
		} else {
			return `${path}partner/expenses/${userInfo.organisation.id}/${userInfo.organisation.label}`;
		}
	};

	const tableBody =
		isObject(currentExpensesData) ?
			[
				[
					{
						content: (
							<Box className={classes.cell}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={handlePathName()}
									variant='h6'
								>
									{currentExpensesData.title}
								</Link>
							</Box>
						),
					},
					{
						content: decimalAmount(currentExpensesData.amount.subtotal, currentExpensesData.amount),
					},
					{
						content: decimalAmount(currentExpensesData.amount.discount, currentExpensesData.amount),
					},
					{
						content: decimalAmount(
							currentExpensesData.amount.subtotal - currentExpensesData.amount.discount,
							currentExpensesData.amount,
						),
					},
					{
						content: decimalAmount(currentExpensesData.amount.priceVat, currentExpensesData.amount),
					},
					{
						content: decimalAmount(currentExpensesData.amount.refund, currentExpensesData.amount),
					},
					{
						content: decimalAmount(
							currentExpensesData.amount.totalPrice,
							currentExpensesData.amount,
						),
					},
					{ content: decimalAmount(currentExpensesData.open, currentExpensesData.amount) },
					{
						content: (
							<Box className={classes.status}>
								<Label type={statusColor[currentExpensesData.status]}>
									{statusText[currentExpensesData.status]}
								</Label>
							</Box>
						),
					},
				],
			]
		:	Array(2)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<div className={classes.root}>
			<Table
				body={tableBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				isNotPaginate={true}
				loading={currentExpensesLoading}
				title={t('ui.label.active')}
				withoutPages={true}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentExpenses: state.details.currentExpenses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchCurrentExpenses: () => dispatch(actions.fetchCurrentExpenses()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFinance);
