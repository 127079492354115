import { forwardRef, useEffect, useImperativeHandle } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { MenuItem } from '@mui/material';
import { JSONSchemaType } from 'ajv';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormContainer, Select, TextField } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './changeTicketStatusSchema.json';
import ServiceTicketStatusEnum from '../../enum/serviceTicketStatusEnum';
import ServiceTicketsService from '../../services/serviceTicketsService';

const service = new ServiceTicketsService();

interface ChangeTicketStatusFormData {
	status: ServiceTicketStatusEnum;
	comment?: string;
}

interface TicketFormProps {
	id: string;
	onSubmit?: (value: ChangeTicketStatusFormData) => void;
	status?: ServiceTicketStatusEnum;
}

const ChangeTicketStatusForm = forwardRef<FormWrapperRefProps, TicketFormProps>(
	({ id, onSubmit, status }, ref) => {
		const { t } = useTranslation('general');

		const { setDisabled } = useFormContainerState();
		const { getValues, control, watch, formState } = useForm<ChangeTicketStatusFormData>({
			defaultValues: {
				status: status,
				comment: '',
			},
			resolver: ajvResolver(schema as JSONSchemaType<any>),
		});

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!(formState.isValid && formState.isDirty));
		}, [formState.isValid, setDisabled, formState.isDirty]);

		return (
			<FormContainer>
				<Controller
					name='status'
					control={control}
					render={({ field }) => (
						<Select
							label={t('ui.label.status')}
							value={field.value ?? ''}
							onChange={(e) => field.onChange(e.target.value)}
							required
						>
							{Object.values(ServiceTicketStatusEnum)?.map((el: ServiceTicketStatusEnum) => (
								<MenuItem key={el} value={el}>
									{t(
										el === ServiceTicketStatusEnum.Open || el === ServiceTicketStatusEnum.Closed ?
											`ui.${el}`
										:	`ui.status.${el}`,
									)}
								</MenuItem>
							))}
						</Select>
					)}
				/>
				<Controller
					name='comment'
					control={control}
					render={({ field }) => (
						<TextField
							label={t('views.planboard.addBooking.comment')}
							value={field.value}
							onChange={field.onChange}
							multiline
							minRows={3}
							slotProps={{
								htmlInput: {
									maxLength: schema.properties.comment.maxLength,
								},
							}}
						/>
					)}
				/>
			</FormContainer>
		);
	},
);

export default ChangeTicketStatusForm;
