import { Grid } from '@mui/material';
import clsx from 'clsx';

import GeneralInfo from './GeneralInfo';
import PriceSettings from './PriceSettings';
import { useStyles } from './style';
import SummaryLoading from './SummaryLoading';

interface SummaryProps {
	className?: string;
	pricingModel?: object;
	loading?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, pricingModel, loading } = props;
	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return loading ?
			<SummaryLoading />
		:	<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<GeneralInfo pricingModel={pricingModel} />
				</Grid>
				<Grid {...gridProps}>
					<PriceSettings pricingModel={pricingModel} />
				</Grid>
			</Grid>;
};

export default Summary;
