import { useState, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Link,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import Attention from './Attention';
import EditMileageDialog from './EditMileageDialog';
import { StyledButton } from '../../../../../components';
import { commaTimeStrings } from '../../../../../shared/datetime';
import { useArray } from '../../../../../shared/hooks';
import { isObject, isFullString, isNumber } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import { useStyles } from '../../../style';

interface UsageInfoProps {
	bookingData: object;
	onFetchVehicleStatus?(...args: unknown[]): unknown;
	onFetchDeviceHeartbeats?(...args: unknown[]): unknown;
	onResetBookingMileage?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	vehicleStatus?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchDeviceHeartbeats?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	resetBookingMileage?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const UsageInfo = (props: UsageInfoProps) => {
	const {
		bookingData,

		onFetchVehicleStatus,
		vehicleStatus,
		onFetchDeviceHeartbeats,
		fetchDeviceHeartbeats,

		onResetBookingMileage,
		resetBookingMileage,
		onUpdateDetailsState,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const { array, set, clear } = useArray([]);

	const usage = isObject(bookingData.usage) && bookingData.usage;

	const unlockCode = isObject(bookingData.unlockData) && bookingData.unlockData;

	const [openEditMileageDialog, setEditMileageDialog] = useState(false);

	const { data: vehicleStatusData, loading: vehicleStatusLoading } = vehicleStatus;
	const { data: fetchDeviceHeartbeatsData, loading: fetchDeviceHeartbeatsLoading } =
		fetchDeviceHeartbeats;

	const {
		data: resetBookingMileageData,
		loading: resetBookingMileageLoading,
		error: resetBookingMileageError,
	} = resetBookingMileage;

	const isBMW = bookingData.itemInstance?.deviceType === 'bmwCar';

	const isVehicle =
		bookingData?.itemInstance?.categoryReference?.type === 'cars' ||
		bookingData?.itemInstance?.categoryReference?.type === 'trailers';

	useEffect(() => {
		if (isObject(bookingData)) {
			if (isBMW && !vehicleStatusLoading) {
				onFetchVehicleStatus(bookingData.itemInstance.deviceId);
			} else if (
				bookingData.itemInstance.deviceType === 'vehicleTrackerCan' &&
				!fetchDeviceHeartbeatsLoading
			) {
				onFetchDeviceHeartbeats(bookingData.itemInstance.deviceId);
			}
		}
	}, [bookingData]);

	useEffect(() => {
		if (isObject(resetBookingMileageData)) {
			onUpdateDetailsState('fetchBooking', {
				...bookingData,
				usage: resetBookingMileageData?.usage,
			});
			onResetState('resetBookingMileage');
			enqueueSnackbar(t('views.bookingDetail.usageInfo.mileageReset.success.message'), {
				variant: 'success',
			});
		} else if (isObject(resetBookingMileageError)) {
			enqueueSnackbar(resetBookingMileageError?.message, { variant: 'error' });
		}
	}, [resetBookingMileageLoading]);

	const centralLocking = {
		locked: t('ui.label.locked'),
		unlocked: t('ui.label.unlocked'),
		secured: t('ui.label.lockedSecurely'),
	};

	const handleGPSLocation = () => {
		if (vehicleStatusData?.latitude || fetchDeviceHeartbeatsData?.location?.latitude) {
			const latitude =
				isBMW ? vehicleStatusData?.latitude : fetchDeviceHeartbeatsData.location.latitude;
			const longitude =
				isBMW ? vehicleStatusData?.longitude : fetchDeviceHeartbeatsData.location.longitude;
			return (
				<a
					className={classes.mapLink}
					href={`https://maps.google.com/?daddr=${latitude},${longitude}`}
					target='blank'
				>
					{' '}
					{`${latitude}, ${longitude}`}
				</a>
			);
		}
	};
	usage;
	const handleLink = () => (
		<Link
			className={classes.itemGroup}
			component={RouterLink}
			to={`/user-management/users/${usage.adminData.id}/summary`}
			state={{
				from: location.pathname,
				booking: true,
			}}
		>
			{usage.adminData.name}
		</Link>
	);

	const handleAttention = () => {
		setEditMileageDialog(true);
		set(
			bookingData.attentionStates.length === 2 ?
				['both', ...bookingData.attentionStates]
			:	[...bookingData.attentionStates],
		);
	};

	const resetMileage = () => {
		onResetBookingMileage(bookingData?.id);
	};

	const editMileageDialogProps = {
		openEditMileageDialog,
		setEditMileageDialog,
		options: array,
		clearOptions: clear,
		startUsage: usage?.startMileageKm,
		stopUsage: usage?.stopMileageKm,
		data: bookingData,
	};

	const startMileageProps = {
		mileage: usage?.startMileageKm,
		attention: bookingData.attentionStates.includes('startMileage'),
		time: usage?.startMileageTimestamp,
		info:
			isSuperAdmin() ?
				t('views.bookingDetail.usageInfo.startMileage')
			:	t('views.bookingDetail.usageInfo.underReview'),
		editUser: usage?.adminData?.startMileageUser?.name,
		showIcon: isSuperAdmin(),
		onClick: resetMileage,
		loading: resetBookingMileageLoading,
	};

	const endMileageProps = {
		mileage: usage?.stopMileageKm,
		attention: bookingData.attentionStates.includes('stopMileage'),
		time: usage?.stopMileageTimestamp,
		info:
			isSuperAdmin() ?
				t('views.bookingDetail.usageInfo.stopMileage')
			:	t('views.bookingDetail.usageInfo.underReview'),
		editUser: usage?.adminData?.stopMileageUser?.name,
		showIcon: isSuperAdmin(),
		onClick: resetMileage,
		loading: resetBookingMileageLoading,
	};

	const usageDetails = [
		...(isObject(usage) ?
			[
				{
					label: 'views.bookingDetail.usageInfo.checkIn',
					value: isFullString(usage.start) ? commaTimeStrings(usage.start) : '-',
					key: 1,
				},
				{
					label: 'views.bookingDetail.usageInfo.checkOut',
					value: isFullString(usage.stop) ? commaTimeStrings(usage.stop) : '-',
					key: 2,
				},
			]
		:	[]),
		...(isVehicle ?
			[
				{
					label: 'views.bookingDetail.usageInfo.mileageStart',
					value: <Attention {...startMileageProps} />,
					key: 3,
				},
				{
					label: 'views.bookingDetail.usageInfo.mileageEnd',
					value: <Attention {...endMileageProps} />,
					key: 4,
				},
				{
					label: 'views.bookingDetail.usageInfo.kmDriven',
					value: isNumber(usage.kmUsage) ? usage.kmUsage : '-',
					key: 5,
				},
			]
		:	[]),
		...(isFullString(unlockCode?.code) ?
			[{ label: 'ui.label.unlockCode', value: unlockCode.code, key: 6 }]
		:	[]),
		...((
			(isObject(vehicleStatusData) || isObject(fetchDeviceHeartbeatsData)) &&
			['bmwCar', 'vehicleTrackerCan'].includes(bookingData.itemInstance.deviceType)
		) ?
			[
				{
					label: 'ui.label.lockStatus',
					value:
						isBMW ?
							centralLocking[vehicleStatusData?.centralLocking?.toLowerCase()]
						:	centralLocking[fetchDeviceHeartbeatsData?.lockState],
					key: 7,
				},
				{
					label: 'views.addLocation.locationDetails.locationSection.input.gps.title',
					value: handleGPSLocation(),
					key: 8,
				},
			]
		:	[]),
		...(isObject(usage.adminData) ?
			[
				{
					label: 'views.bookingDetail.usageInfo.bookingStopped',
					value: commaTimeStrings(usage.adminData.stop),
					key: 9,
				},
				{ label: 'views.bookingDetail.usageInfo.stoppedBy', value: handleLink(), key: 10 },
			]
		:	[]),
	];

	return (
		<Card className={classes.card}>
			<CardHeader title={t('ui.label.usageInfo')} />
			<Divider />
			<CardContent className={classes.cardConten}>
				<Table>
					<TableBody>
						{usageDetails.map((item) => (
							<TableRow key={item.key}>
								<TableCell>{t(item.label)}</TableCell>
								<TableCell>{item.value}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
			{(
				!bookingData.attentionStates.includes('none') &&
				bookingData.status === 'completed' &&
				isSuperAdmin()
			) ?
				<CardActions className={classes.actions}>
					<StyledButton onClick={handleAttention} startIcon={<EditIcon />} variant='inline-default'>
						{t('views.bookingDetail.usageInfo.button.editMileage')}
					</StyledButton>
				</CardActions>
			:	null}
			{openEditMileageDialog ?
				<EditMileageDialog {...editMileageDialogProps} />
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		fetchDeviceHeartbeats: state.details.fetchDeviceHeartbeats,
		vehicleStatus: state.details.vehicleStatus,
		resetBookingMileage: state.details.resetBookingMileage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchVehicleStatus: (id) => dispatch(actions.fetchVehicleStatus(id)),
		onFetchDeviceHeartbeats: (id) => dispatch(actions.fetchDeviceHeartbeats(id)),
		onResetBookingMileage: (bookingId) => dispatch(actions.resetBookingMileage(bookingId)),
		onUpdateDetailsState: (state, data) => dispatch(actions.updateDetailsState(state, data)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsageInfo);
