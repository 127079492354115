import { Typography, Box } from '@mui/material';

import { MyTooltip } from './style';
import { isObject } from '../../../shared/utility';

interface IconInfoLabelProps {
	content?: object;
	label?: string;
	infoTitle?: string;
	icon?: object;
	showIcon?: boolean;
}

const IconInfoLabel = (props: IconInfoLabelProps) => {
	const { content, label, infoTitle, icon, showIcon } = props;

	return (
		<Box alignItems='center' display='flex'>
			<Box pr={0.8}>{isObject(content) ? content : <Typography>{label}</Typography>}</Box>
			{showIcon ?
				<MyTooltip arrow placement='top' title={<Typography>{infoTitle}</Typography>}>
					{icon}
				</MyTooltip>
			:	null}
		</Box>
	);
};

export default IconInfoLabel;
