import { Card, CardContent, Divider } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import AdjustedSkeleton from '../AdjustedSkeleton';

interface LoadingDetailActionsBlockProps {
	className?: string;
}

const LoadingDetailActionsBlock = (props: LoadingDetailActionsBlockProps) => {
	const classes = useStyles();

	const { className } = props;

	return (
		<Card className={clsx(classes.root, className)}>
			<div className={classes.header}>
				<AdjustedSkeleton animation='wave' width='50%' />
			</div>
			<Divider />
			<CardContent className={classes.content}>
				<div className={classes.header}>
					<AdjustedSkeleton animation='wave' />
					<AdjustedSkeleton animation='wave' />
					<AdjustedSkeleton animation='wave' width='60%' />
				</div>
			</CardContent>
		</Card>
	);
};

export default LoadingDetailActionsBlock;
