import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AddSsoDomainsLayout } from '~features/organisations';
import PageNotFoundPage from '~pages/feedbacks/pageNotFoundPage';

import Page from '../page';

const AddSsoDomainsPage = () => {
	const { t } = useTranslation('general');
	const { id } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const handleGoBackClick = () => {
		if (location.state?.from != null) {
			navigate(location.state?.from);
		} else {
			navigate('..', { relative: 'path' });
		}
	};

	if (!id) {
		return <PageNotFoundPage />;
	}

	return (
		<Page title={t('addResource', { resource: t('ssoDomain') })}>
			{/* TODO: eventually this top bar needs to be part of a layout. So
			we don't copy/paste this everywhere */}
			<Box sx={{ p: 3 }}>
				<Button
					startIcon={<KeyboardArrowLeftOutlinedIcon fontSize='large' />}
					onClick={handleGoBackClick}
				>
					{t('ui.button.inline.previous')}
				</Button>
			</Box>
			<Divider />
			<Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
				<AddSsoDomainsLayout organisationId={id} />
			</Box>
		</Page>
	);
};

export default AddSsoDomainsPage;
