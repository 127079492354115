import { useState } from 'react';

import path from 'path';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog } from '~components';
import { pagePathSegments } from '~constants';
import { useSnackbar } from '~hooks';

import UserInvitationsDataGrid from '../components/dataGrids/userInvitationsDataGrid';
import UserInvitationsService from '../services/userInvitationsService';

type UserAction =
	| {
			type: 'resend' | 'delete';
			id: string;
	  }
	| {
			type: 'create';
			id?: never;
	  };

const service = new UserInvitationsService();

const UserInvitationsOverviewLayout = () => {
	const { t } = useTranslation('general');
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const {
		trigger: triggerDelete,
		isMutating: isDeleteMutating,
		error: deleteError,
	} = useSWRMutation(
		userAction?.id ? [service.basePath, userAction.id] : null,
		([_, id]) => service.deleteUserInvitation(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				setUserAction(null);
				// dataGridRef.current.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	return (
		<>
			<UserInvitationsDataGrid
				onNewInvite={() =>
					navigate(
						`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, pagePathSegments.Invite)}`,
					)
				}
				onDelete={(id) => setUserAction({ type: 'delete', id: id })}
			/>
			<ConfirmationDialog
				loading={isDeleteMutating}
				open={userAction?.type === 'delete'}
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('invitation'),
					name: userAction?.label ?? userAction?.id,
				})}
				onConfirm={async () => await triggerDelete()}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default UserInvitationsOverviewLayout;
