import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { Typography, Hidden, Box } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStyles } from './style';
import { AdjustedSkeleton, StyledButton, ButtonWithAlertDialog } from '../../../../../components';
import { useDetailPageBackButton, useError } from '../../../../../shared/hooks';
import { isObject } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';

interface HeaderProps {
	className?: string;
	termData?: object;
	name?: string;
	loading?: boolean;
	data?: object;
	onDeleteTerm?(...args: unknown[]): unknown;
	deleteTerm?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const Header = (props: HeaderProps) => {
	const { className, name, termData, loading, onDeleteTerm, deleteTerm } = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();

	const classes = useStyles();

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deleteTerm;
	const deleteTermDone = deleteSuccess && !deleteLoading && !deleteError;

	const deleteTermMessage = useError({
		value: deleteTerm,
		message: `${t('ui.successfullyDeleted')} ${isObject(termData) ? termData.title : ''}`,
	});

	const returnButton = useDetailPageBackButton(location);

	const handleBackButtonChange = () => navigate('/policy-management/terms-and-conditions');

	const handleBackLabel = () => {
		if (returnButton.isBackButton) {
			return t('ui.button.inline.back');
		} else if (location?.state?.label) {
			return location?.state?.label;
		} else {
			return t('view.policymanagement.termdetails.button.inline.returntotermslist');
		}
	};

	const handleDelete = () => {
		onDeleteTerm(termData.id);
		deleteTermMessage.setStartAction(true);
	};

	const handleEditTerm = () => {
		navigate(`/terms/${termData.id}/edit`, {
			state: { detail: true },
		});
	};

	return (
		<div className={clsx(classes.root, className)}>
			<Hidden only={['xs', 'sm']}>
				<StyledButton
					className={classes.backButton}
					onClick={handleBackButtonChange}
					startIcon={<KeyboardArrowLeftOutlinedIcon />}
					variant='inline-default'
				>
					{handleBackLabel()}
				</StyledButton>
			</Hidden>
			<Typography component='h1' variant='h3'>
				<Box display='flex' justifyContent='space-between'>
					{loading ?
						<AdjustedSkeleton animation='wave' width={200} />
					:	name}
					<Box display='flex'>
						<ButtonWithAlertDialog
							actionDone={deleteTermDone}
							callback={handleDelete}
							dialogDescription={`${t('ui.deleted.dialogDescription')} ${isObject(termData) ? termData.title : ''} ${t('ui.permanently')}`}
							dialogLoading={deleteLoading && !deleteTermDone}
							dialogTitle={t('ui.delete')}
							startIcon={<DeleteIcon />}
							variant='inline-delete'
						>
							{t('ui.button.inline.delete')}
						</ButtonWithAlertDialog>
						<StyledButton
							className={classes.editButton}
							onClick={handleEditTerm}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.edit')}
						</StyledButton>
					</Box>
				</Box>
			</Typography>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		deleteTerm: state.condition.deleteTerm,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onDeleteTerm: (id) => dispatch(actions.deleteTerm(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
