import path from 'path';

import { AxiosResponse } from 'axios';

import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import NfcTag from '../interfaces/nfcTag';
import { NfcTagNew } from '../interfaces/nfcTag';

class NfcTagsService extends TopologyService {
	public readonly path = 'nfccards';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of Nfc Cards
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getNfcTags({
		page = 1,
		pageSize = 10,
		sortBy = 'id',
		descending = true,
		...args
	}: GeneralPagedParameters<'id' | 'tagNumber' | 'dateLinked'> & {
		isLinked?: boolean;
	}): Promise<PagedResults<NfcTag>> {
		const sortByMapped =
			sortBy === 'id' ? 'nfcId'
			: sortBy === 'tagNumber' ? 'cardNumber'
			: 'linkedDate';
		const { data } = await this._client.get<PagedResponse<NfcTagResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				sortBy: sortByMapped,
				orderDescending: descending,
				searchTerm: args.searchQuery || undefined,
				...(args.isLinked != null && { isLinked: args.isLinked }),
			},
		});

		return this.mapPagedResponse(data, NfcTagsService.fromResponse);
	}

	/**
	 * Get details of a NFC tag by id.
	 * Notice!! At the moment of writing (2024-08-12) the backend here is buggy.
	 * If the id is a number, it will be interpret as a user. Else it will
	 * be a nfc id
	 * @param id The id of the NFC tag
	 * @returns
	 */
	async getNfcTagById(id: string): Promise<NfcTag> {
		const { data } = await this._client.get(path.join(this.path, id));

		return NfcTagsService.fromResponse(data);
	}

	async updateNfcTag(id: string, data: NfcTag): Promise<NfcTag> {
		const content: NfcTagUpdateRequest = NfcTagsService.toUpdateRequest(data);

		const response = await this._client.put<
			NfcTagUpdateRequest,
			AxiosResponse<NfcTagResponse, NfcTagUpdateRequest>,
			NfcTagUpdateRequest
		>(path.join(this.path, id), content);

		return NfcTagsService.fromResponse(response.data);
	}

	async createNfcTag(data: NfcTagNew): Promise<null> {
		const content = NfcTagsService.toPostRequest(data);
		const response = await this._client.post<
			NfcTagPostRequest,
			AxiosResponse<NfcTagResponse, NfcTagPostRequest>,
			NfcTagPostRequest
		>(this.path, content);

		return null;
	}

	/**
	 * Unlink a NFC tag
	 * @param id The id of the NFC tag
	 * @returns
	 */
	async unlinkNfcTag(id: string): Promise<null> {
		const { data } = await this._client.put(path.join(this.path, id, 'unlink'));

		return null;
	}

	/**
	 * Delete a nfc tag
	 * @param id The nfcId
	 * @returns
	 */
	async deleteNfcTag(id: string): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: NfcTagResponse): NfcTag {
		const {
			nfcId,
			cardNumber,
			linkedDate,
			tagType,
			organisationReference,
			userGroupReference,
			userReference,
			...rest
		} = data;

		return {
			...rest,
			id: nfcId,
			tagNumber: cardNumber,
			dateLinked: linkedDate ? new Date(linkedDate) : undefined,
			tagType: tagType === 'none' ? 'external' : 'skopei',
			organisation: NfcTagsService.fromBaseReferenceResponse(organisationReference),
			userGroup: NfcTagsService.fromBaseReferenceResponse(userGroupReference),
			user: NfcTagsService.fromBaseReferenceResponse(userReference),
		};
	}

	static toUpdateRequest(data: NfcTag): NfcTagUpdateRequest {
		const { user, organisation, tagNumber, status } = data;

		return {
			cardStatus: status,
			cardNumber: tagNumber,
			...(user != null && { userId: user.id }),
			...(organisation != null && { organisationId: organisation.id }),
		};
	}

	static toPostRequest(data: NfcTagNew): NfcTagPostRequest {
		const { id, organisation, tagNumber, tagType } = data;

		return {
			nfcId: id,
			cardNumber: tagNumber,
			tagType: tagType === 'external' ? 'none' : 'skopei',
			...(organisation != null && { organisationId: organisation.id }),
		};
	}
}

interface NfcTagUpdateRequest {
	userId?: string;
	organisationId?: string;
	cardNumber: string;
	cardStatus: string;
}

interface NfcTagPostRequest extends NfcTagDto {
	userId?: number | string;
	cardStatus?: string;
	organisationId?: string;
	cardType?: string; // personal vs business
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface NfcTagResponse extends NfcTagDto {
	cardType: 'business' | 'personal';
	status: string;
	userReference?: BaseReferenceResponse;
	userGroupReference?: BaseReferenceResponse;
	organisationReference?: BaseReferenceResponse;
	linkedDate?: string;
}

interface NfcTagDto {
	nfcId: string;
	cardNumber: string;
	tagType?: 'none' | 'skopei'; // skopei vs external
}

export default NfcTagsService;
