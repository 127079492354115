/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useTranslation } from 'react-i18next';

import {
	mapIcons,
	createMap,
	createMarker,
	createLabel,
	createIcon,
	renderer,
	style,
} from './Configuration';
import { useMainContext } from './FleetManagement';
import { mapStyles, mapRestriction } from '../../constantsOld';
import { usePrevious } from '../../shared/hooks';
import { isObject, isNull, isArray, isFullArray } from '../../shared/utility';

interface MapComponentProps {
	data?: unknown[];
	loading?: boolean;
	prevLoading?: boolean;
	children?: object;
}

const MapComponent = (props: MapComponentProps) => {
	const { children, data, loading, prevLoading } = props;
	const { t } = useTranslation('general');

	const {
		center,
		zoom,
		selectedFleet,
		setListHight,
		resetOptions,
		resetActiveStates,
		setActiveStates,
	} = useMainContext();
	const ref = useRef(null);
	const [map, setMap] = useState(null);
	const [activeMarker, setActiveMarker] = useState(null);

	const [markers, setMarkers] = useState(null);
	const [markerCluster, setMarkerCluster] = useState(null);

	const prevActiveMarker = usePrevious(activeMarker);
	const prevSelectedFleet = usePrevious(selectedFleet);

	// create map
	useEffect(() => {
		if (isNull(map)) {
			setMap(createMap(ref.current, zoom, center, mapStyles, mapRestriction));
		}
	}, []);

	useEffect(() => {
		if (ref?.current) {
			setListHight(ref.current.offsetHeight);
		}
	}, [ref?.current?.offsetHeight]);
	// Set markers
	useEffect(() => {
		if (prevLoading && !loading) {
			removeMarkers(markers);

			//Create LatLngBounds object.
			const latlngBounds = new window.google.maps.LatLngBounds();
			const updatedMarkers = data.map((fleet) => {
				const marker = addMarker(fleet);

				//Extend each marker's position in LatLngBounds object.
				latlngBounds?.extend(marker.position);

				return marker;
			});

			if (!isNull(latlngBounds)) {
				// map.setCenter(latlngBounds.getCenter());
				map?.fitBounds(latlngBounds);
				if (!isFullArray(data)) {
					map.setCenter({ lat: 52.00717, lng: 4.3673422 });
					map.setZoom(5);
				}
			}

			setMarkers(updatedMarkers);
		}
	}, [data]);

	// Update cluster with markers
	useEffect(() => {
		if (!loading && isArray(markers)) {
			updateCluster();
		}
	}, [markers]);

	// Set map center
	useEffect(() => {
		if (!isNull(map)) {
			map.setCenter(center);
			map.setZoom(17);
		}
	}, [center]);

	// Set map zoom
	useEffect(() => {
		if (!isNull(map)) {
			updateCluster();
			map.setZoom(zoom);
		}
	}, [zoom]);

	// Add map events
	useEffect(() => {
		if (!isNull(map)) {
			['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));
			map.setZoom(zoom);
			map.addListener('click', () => {
				// setClicks([...clicks, e.latLng]);
				setActiveMarker(null);
			});
			// map.addListener("idle", () => onIdle(map));
		}
	}, [map]);

	// Updater marker icon and label when active marker state has changed
	useEffect(() => {
		// if active marker is an object, then set map marker in active icon and label state
		if (prevActiveMarker !== activeMarker && isObject(activeMarker)) {
			const { marker, fleet } = activeMarker;
			// in addition to the url, update the size of the icon due to the difference in sizes between active and non active icons when total is one
			const iconOptions = {
				url: fleet.total > 1 ? mapIcons.defaultActive : mapIcons[fleet.type + 'Active'],
				...(fleet.total === 1 && {
					size: new window.google.maps.Size(86, 86),
					// origin: new window.google.maps.Point(0, -2),
					// anchor: new window.google.maps.Point(43, 43),
					labelOrigin: new window.google.maps.Point(43, 40),
					scaledSize: new window.google.maps.Size(86, 86),
				}),
			};
			const labelOptions = { total: fleet.total, color: '#016dff' };
			marker.setIcon(createIcon(iconOptions));
			marker.setLabel(createLabel(labelOptions));
			const location = { lat: fleet.latitude, lng: fleet.longitude };
			resetOptions(location, 16);

			// if active marker was updated from map, then update selected fleet to match list with the map
			const activeCoordinates = JSON.stringify(location);
			const activeFleetCoordinates =
				isObject(selectedFleet) ?
					JSON.stringify({ lat: selectedFleet.latitude, lng: selectedFleet.longitude })
				:	null;
			if (activeCoordinates !== activeFleetCoordinates) {
				// Filter fleets by gps coordinates
				setActiveStates({ fleet: fleet });
			}

			// infoWindow.open(map, marker);
			// infoWindow.open({
			//   anchor: marker,
			//   map,
			//   shouldFocus: false,
			// });
		}

		// if previous active marker is an object, then reset its marker style to default icon and label state
		if (isObject(prevActiveMarker)) {
			const { marker, fleet } = prevActiveMarker;
			const iconOptions = { url: fleet.total > 1 ? mapIcons.default : mapIcons[fleet.type] };
			const labelOptions = { total: fleet.total, color: '#000' };
			marker.setIcon(createIcon(iconOptions));
			marker.setLabel(createLabel(labelOptions));

			// if marker was deselected from map and selected fleet is still an object, then reset selected fleet
			if (isNull(activeMarker) && isObject(selectedFleet)) {
				resetActiveStates();
			}
		}
	}, [activeMarker]);

	// update marker when selected fleet is updated from the list
	useEffect(() => {
		const activeMarkerCoordinates =
			isObject(activeMarker) ? JSON.stringify(activeMarker.marker.getPosition().toJSON()) : null;
		const activeCoordinates =
			isObject(selectedFleet) ?
				JSON.stringify({ lat: selectedFleet.latitude, lng: selectedFleet.longitude })
			:	null;

		// if fleet is updated from list, update the active marker to match the selected fleet
		if (
			isObject(selectedFleet) &&
			(isNull(activeMarker) || activeMarkerCoordinates !== activeCoordinates) &&
			isFullArray(markers)
		) {
			const marker = markers.reduce((prev, current) =>
				JSON.stringify(current.getPosition().toJSON()) === activeCoordinates ? current : prev,
			);
			const fleet = data.reduce((prev, current) =>
				JSON.stringify({ lat: current.latitude, lng: current.longitude }) === activeCoordinates ?
					current
				:	prev,
			);

			if (isObject(marker)) {
				setActiveMarker({ marker: marker, fleet: fleet });
			}
		}
		// if fleet is deselected from list, reset active marker to match
		if (isObject(prevSelectedFleet) && isNull(selectedFleet) && isObject(activeMarker)) {
			setActiveMarker(null);
		}
	}, [selectedFleet, markers]);

	// Update cluster (clear markers if presetn, add markers again with latest states)
	const updateCluster = () => {
		if (!isNull(markerCluster)) {
			markerCluster.clearMarkers(markers);
			markerCluster.addMarkers(markers);
		} else {
			const newCluster = new MarkerClusterer({ markers, map, renderer });
			setMarkerCluster(newCluster);
		}
	};

	// Remove specified marker from map
	const removeMarker = (marker) => {
		if (isObject(marker)) {
			marker.setMap(null);
		}
	};

	// Clear markers from map
	const removeMarkers = (clusterMarkers) => {
		if (isFullArray(clusterMarkers)) {
			clusterMarkers.forEach((marker) => {
				removeMarker(marker);
			});
		}
	};

	// Create default configurations marker
	const addMarker = (fleet) => {
		if (isObject(fleet)) {
			const position = {
				lat: Number(fleet.latitude.toFixed(6)),
				lng: Number(fleet.longitude.toFixed(6)),
			};
			const iconOptions = { url: fleet.total > 1 ? mapIcons.default : mapIcons[fleet.type] };
			const labelOptions = { total: fleet.total, color: '#000' };
			const icon = createIcon(iconOptions);
			const label = createLabel(labelOptions);
			const title = `${t('views.mapWiew.label.addMarker.title')} ${fleet.total}`;

			const marker = createMarker(position, map, label, icon, title);

			// var infoWindow = new window.google.maps.InfoWindow({
			//     content: `Total items at location ${fleet.total}`
			// });

			// open info window when marker is clicked
			marker.addListener('click', () => {
				setActiveMarker({ marker: marker, fleet: fleet });
			});
			return marker;
		}
	};

	return (
		<>
			<div ref={ref} style={style} />
			{React.Children.map(children, (child) => {
				if (React.isValidElement(child)) {
					// set the map prop on the child component
					return React.cloneElement(child, { map });
				}
			})}
		</>
	);
};

export default MapComponent;
