import { useStyles } from './style';

interface OpenPhoneNumberProps {
	number?: string | number;
}

const OpenPhoneNumber = (props: OpenPhoneNumberProps) => {
	const { number } = props;
	const classes = useStyles();

	return (
		<a className={classes.styledLink} href={`tel:${number}`}>
			{number}
		</a>
	);
};

export default OpenPhoneNumber;
