import { Grid } from '@mui/material';
import clsx from 'clsx';

import AdministrativePolicyInfo from './AdministrativePolicyInfo';
import BookingPolicyInfo from './BookingPolicyInfo';
import GeneralInfo from './GeneralInfo';
import PaymentPolicyInfo from './PaymentPolicyInfo';
import { useStyles } from './style';
import SummaryLoading from './SummaryLoading';

interface SummaryProps {
	className?: string;
	policy?: object;
	loading?: boolean;
}

const Summary = (props: SummaryProps) => {
	const { className, policy, loading } = props;

	const classes = useStyles();

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4 };

	return loading ?
			<SummaryLoading />
		:	<Grid className={clsx(classes.root, className)} container spacing={3}>
				<Grid {...gridProps}>
					<GeneralInfo policy={policy} />
				</Grid>
				<Grid {...gridProps}>
					<BookingPolicyInfo policy={policy} />
				</Grid>
				<Grid {...gridProps}>
					<PaymentPolicyInfo policy={policy} />
				</Grid>
				<Grid {...gridProps}>
					<AdministrativePolicyInfo policy={policy} />
				</Grid>
			</Grid>;
};

export default Summary;
