import path from 'path';

import { ItemCategoryEnum, PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { BasePagedParameters, StatisticsFilterParameters } from '~interfaces/requests';
import { TopologyService } from '~services';

interface BookingListResults<T extends object> {
	results: T[];
	uniqueUsers: number;
	total: number;
}

interface BookingStat {
	timestamp: Date;
	data: {
		bookingCount: number;
		category: ItemCategoryEnum;
		uniqueUsers: number;
	}[];
}

type BookingStatBup = {
	timestamp: Date;
} & Record<'cars' | 'bikes', { bookings: number; uniqueUsers: number }>;

class BookingStatisticsService extends TopologyService {
	public readonly path = 'graphs/bookings';

	constructor() {
		super('v1');
	}

	async getCounts({
		period,
		...args
	}: {
		organisationId?: string;
		period: DateRange;
		periodicity?: PeriodicityEnum;
	} & StatisticsFilterParameters): Promise<BookingListResults<BookingStat>> {
		const filters = BookingStatisticsService.mapRequestFilterParameters(args);

		const { data } = await this._client.get<BookingStatResponse>(this.path, {
			params: {
				organisationId: args.organisationId,
				dateAfter: period.start.toISOString(),
				dateBefore: period.end.toISOString(),
				step: args.periodicity,
				...filters,
			},
		});

		const temp = data.categories.reduce((accumulator, el) => {
			const { dateTime, bookings, ...rest } = el;

			accumulator[dateTime] = accumulator[dateTime] ?? [];
			accumulator[dateTime].push({
				...rest,
				bookingCount: bookings,
			});
			return accumulator;
		}, []);

		const resultsWithGaps = Object.keys(temp).map((el) => ({
			timestamp: new Date(el),
			data: temp[el],
		}));

		return {
			total: data.bookings,
			uniqueUsers: data.uniqueUsers,
			results: resultsWithGaps,
		};
	}

	async getCategoryBookingCounts({
		...args
	}: {
		period: DateRange;
	} & BasePagedParameters &
		StatisticsFilterParameters): Promise<BookingStat[]> {
		const { data } = await this._client.get<BookingSubStatResponse[]>(
			path.join(this.path, 'list'),
			{
				params: {
					organisationId: args.organisationId,
					dateAfter: args.period.start,
					dateBefore: args.period.end,
					itemInstanceId: args.itemId,
					hubId: args.hubId,
					partnerId: args.partnerId,
					categoryId: args.categoryId,
				},
			},
		);

		return data.map((el) => {
			const { categoryType, bookings, durations, ...rest } = el;

			return {
				...rest,
				bookingCount: bookings,
				duration: durations,
				category: categoryType as ItemCategoryEnum,
			};
		});
	}

	static fromResponse(data: BookingSubStatResponse): BookingStat {
		const { ...rest } = data;

		return {
			...rest,
		};
	}
}

interface BookingSubStatResponse {
	categoryType: string;
	dateTime: string;
	uniqueUsers: number;
	extended: number;
	noShow: number;
	cancelled: number;
	durations: number;
	bookings: number;
	average: number;
}

interface BookingStatResponse {
	categories: BookingSubStatResponse[];
	uniqueUsers: number;
	bookings: number;
}

export default BookingStatisticsService;
