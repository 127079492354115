import { TextField, Paper, Stack } from '@mui/material';

import { useStyles } from '../style';

interface TimePickerProps {
	onChangeStart?(...args: unknown[]): unknown;
	onChangeEnd?(...args: unknown[]): unknown;
}

const TimePicker = (props: TimePickerProps) => {
	const { onChangeStart, onChangeEnd } = props;

	const classes = useStyles();
	return (
		<Paper className={classes.timePop}>
			<Stack component='form' noValidate spacing={3} sx={{ marginRight: 2 }}>
				<TextField
					InputLabelProps={{ shrink: true }}
					InputProps={{ disableUnderline: true }}
					id='datetime-local'
					type='datetime-local'
					variant='standard'
					onChange={onChangeStart}
					// defaultValue="2017-05-24T10:30"
					sx={{ width: 150 }}
				/>
			</Stack>
			<Stack component='form' noValidate spacing={3}>
				<TextField
					InputLabelProps={{ shrink: true }}
					InputProps={{ disableUnderline: true }}
					id='datetime-local'
					type='datetime-local'
					variant='standard'
					onChange={onChangeEnd}
					// defaultValue="2017-05-24T10:30"
					sx={{ width: 150 }}
				/>
			</Stack>
		</Paper>
	);
};

export default TimePicker;
