import path from 'path';

import axios from 'axios';

import { BaseReference, PagedResults } from '~interfaces';
import { BasePagedParameters, SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import Organisation from '../interfaces/organisation';
import SsoDomain, { NewSsoDomain } from '../interfaces/ssoDomain';

class OrganisationsService extends TopologyService {
	public readonly path = 'organisations';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of organisations
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getOrganisations({
		page = 1,
		pageSize = 10,
		...args
	}: Omit<SearchPagedParameters, 'organisation'>): Promise<PagedResults<Organisation>> {
		const { data } = await this._client.get<PagedResponse<OrganisationResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				searchTerm: args.searchQuery,
				sortBy: 'name',
			},
		});

		return this.mapPagedResponse(data, OrganisationsService.fromResponse);
	}

	/**
	 * Get the details of a single organisation
	 * @param id
	 * @returns
	 */
	async getOrganisationById(id: string): Promise<Organisation> {
		const { data } = await this._client.get<OrganisationResponse>(path.join(this.path, id));

		return OrganisationsService.fromResponse(data);
	}

	async updateImage(id: string, file: File) {
		const formData = new FormData();
		formData.append('files', file);

		const response = await this._client.put(path.join(this.path, id, 'images'), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		return null;
	}

	async getSsoDomains({
		page = 1,
		pageSize = 10,
		...args
	}: Required<Pick<BasePagedParameters, 'organisationId'>> & BasePagedParameters): Promise<
		PagedResults<SsoDomain>
	> {
		const { data } = await this._client.get(
			path.join(this.path, args.organisationId, 'ssodomains'),
			{
				params: {
					pageNumber: page,
					pageSize: pageSize,
				},
			},
		);

		return this.mapPagedResponse(data, OrganisationsService.fromSsoDomainResponse);
	}

	async validateSsoDomain(
		organisationId: string,
		value: string,
	): Promise<{ valid: boolean; message?: string }> {
		try {
			await this._client.get(path.join(this.path, organisationId, 'ssodomains/validate'), {
				params: {
					// Why is this necessary, the other option is none. If you choose none,
					// what would the validate endpoint do? Or trying to make it future proof
					type: 'uniqueName',
					value: value,
				},
			});

			// If response.status == 200, it means validation is ok
			return {
				valid: true,
			};
		} catch (error) {
			if (
				axios.isAxiosError(error) &&
				error.status === 400 &&
				error.response?.data.key === 'EmailDomainUnavailable'
			) {
				// If response.status == 400 and specific key, already taken
				return {
					valid: false,
					message: error.response.data.message,
				};
			}

			throw error;
		}
	}

	async setSsoOnlyAccess(organisationId: string, value: boolean): Promise<null> {
		const { data } = await this._client.put(path.join(this.path, organisationId, 'sso'), {
			value: value,
		});

		return null;
	}

	async addSsoDomains(organisationId: string, domains: NewSsoDomain[]): Promise<null> {
		const content = domains.map((el) => ({ domain: el.domainName }));

		const response = await this._client.put(
			path.join(this.path, organisationId, 'ssodomains'),
			content,
		);

		return null;
	}

	async deleteSsoDomain(organisationId: string, domainName: string): Promise<null> {
		await this._client.delete(path.join(this.path, organisationId, 'ssodomains', domainName));

		return null;
	}

	static fromResponse(data: OrganisationResponse): Organisation {
		const { id, name, logo, ...rest } = data;

		return {
			...rest,
			...OrganisationsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			logoUri: logo,
			ssoDomainSignupOnly: rest.features.includes('ssoDomains'),
		};
	}

	static fromEmailDomainResponse(data: EmailDomainResponse): EmailDomain {
		return {
			domainName: data.domainName,
			userGroup: OrganisationsService.fromBaseReferenceResponse(data.userGroupReference),
		};
	}

	static fromSsoDomainResponse(data: SsoDomainResponse): SsoDomain {
		return {
			domainName: data.domainName,
			organisation: OrganisationsService.fromBaseReferenceResponse(data.organisation),
		};
	}
}

interface OrganisationResponse extends BaseReferenceResponse {
	logo?: string;
	description?: string;
	features: string[];
}

interface EmailDomainResponse {
	domainName: string;
	userGroupReference: BaseReferenceResponse;
}

interface SsoDomainResponse {
	domainName: string;
	organisation: BaseReferenceResponse;
}

export default OrganisationsService;
