import { useEffect, useState } from 'react';

import { Download as DownloadIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Button,
	Box,
	Typography,
	Skeleton,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { BillinToggle } from './BillinToggle';
import EditBillingAndAdminInfo from './EditBillingAndAdminInfo';
import { useStyles } from './style';
import { OpenEmail, StyledButton } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';
import {
	createDynamicLink,
	fetchOrganisation,
	getDynamicLinkQr,
	patchUpdateOrganisation,
} from '../../../../../../store/actions';

interface BillingAndAdminInfoProps {
	className?: string;
	organisation: object;
	onFetchOrganisation?(...args: unknown[]): unknown;
	onFetchDynamicLinkQr?(...args: unknown[]): unknown;
	onCreateDynamicLink?(...args: unknown[]): unknown;
	dynamicLinkQr?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	createDynamicLink?: string;
	onPatchUpdateOrganisation?(...args: unknown[]): unknown;
	patchedOrganisation?: {
		data?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const BillingAndAdminInfo = (props: BillingAndAdminInfoProps) => {
	const {
		organisation,
		className,

		patchedOrganisation,
		onPatchUpdateOrganisation,
		onFetchOrganisation,
		currentUser,

		onFetchDynamicLinkQr,
		dynamicLinkQr,
		onCreateDynamicLink,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		data: patchedOrganisationData,
		loading: patchedOrganisationLoading,
		error: patchedOrganisationError,
	} = patchedOrganisation;
	const patchOrganisationDone =
		isObject(patchedOrganisationData) && !patchedOrganisationLoading && !patchedOrganisationError;
	const [updatingOrganisation, setUpdatingOrganisation] = useState(false);

	const [loadingPartnershipToggle, setLoadingPartnershipToggle] = useState(false);

	const [loadingDashboardToggle, setLoadingDashboardToggle] = useState(false);

	const { data: currentUserData } = currentUser;

	const { data: dynamicLinkQrData, loading: dynamicLinkQrLoading } = dynamicLinkQr;

	const [openEdit, setOpenEdit] = useState(false);

	const [qrUrl, setQrUrl] = useState(null);

	useEffect(() => {
		onFetchDynamicLinkQr(organisation.id);
	}, []);

	useEffect(() => {
		let url;
		if (dynamicLinkQrData?.size > 0 && !dynamicLinkQrLoading) {
			url = URL.createObjectURL(dynamicLinkQrData);
		} else {
			url = null;
		}
		setQrUrl(url);
	}, [dynamicLinkQr]);

	useEffect(() => {
		if (updatingOrganisation && patchOrganisationDone) {
			setUpdatingOrganisation(false);
			onFetchOrganisation(organisation.id);
			enqueueSnackbar(
				`${t('views.organisationDetail.summary.partnershipAvailability.success')} ${organisation.name}`,
				{ variant: 'success' },
			);
			setLoadingDashboardToggle(false);
			setLoadingPartnershipToggle(false);
		}
	}, [updatingOrganisation, patchOrganisationDone]);

	useEffect(() => {
		if (updatingOrganisation && !patchedOrganisationLoading && !!patchedOrganisationError) {
			setUpdatingOrganisation(false);
			enqueueSnackbar(
				isObject(patchedOrganisationError) ?
					patchedOrganisationError.message
				:	patchedOrganisationError,
				{ variant: 'error' },
			);
		}
	});

	// showOnDashboard
	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const isTelematics =
		currentUserData?.organisationReference.subscriptionModule.includes('telematics');

	const handleShowOnDashboard = () => {
		onPatchUpdateOrganisation(organisation.id, { showOnDashboard: !organisation?.showOnDashboard });
		setUpdatingOrganisation(true);
		setLoadingDashboardToggle(true);
	};

	const showOnDashboardToggleProps = {
		onChange: handleShowOnDashboard,
		checked: organisation?.showOnDashboard,
		loading: loadingDashboardToggle && patchedOrganisationLoading,
		size: 'small',
		color: 'primary',
	};

	const handlePartnershipAvailabilityToggle = () => {
		onPatchUpdateOrganisation(organisation.id, { isPublic: !organisation?.isPublic });
		setUpdatingOrganisation(true);
		setLoadingPartnershipToggle(true);
	};

	const partnershipToggleProps = {
		onChange: handlePartnershipAvailabilityToggle,
		checked: organisation?.isPublic,
		loading: loadingPartnershipToggle && patchedOrganisationLoading,
		size: 'small',
		color: 'primary',
	};

	// TODO: this can be generalised
	const handleDownloadQrClick = () => {
		const a = document.createElement('a');
		a.href = qrUrl;
		a.download = `${organisation.name}-qr.png`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const handleGenerateQrClick = async () => {
		await onCreateDynamicLink(organisation.id);
		onFetchDynamicLinkQr(organisation.id);
	};

	const billingData = [
		{ label: 'ui.label.vatNumber', content: organisation?.administration?.vatNumber },
		...(organisation?.address?.countryCode === 'BE' ?
			[
				{
					label: 'ui.label.enterpriseNumber',
					content: organisation?.administration?.enterpriseNumber,
				},
			]
		:	[]),
		{ label: 'ui.label.cocNumber', content: organisation?.administration?.chamberOfCommerceNumber },
		...(organisation.isVerified ?
			[{ label: 'ui.label.ibanNumber', content: organisation?.administration?.ibanNumber }]
		:	[]),
		{
			label: 'ui.label.email',
			content: organisation?.invoiceContact?.emailAddress && (
				<OpenEmail email={organisation.invoiceContact.emailAddress} />
			),
		},
		{ label: 'ui.label.name', content: organisation?.invoiceContact?.name },
		...(isSuperAdmin() ?
			[
				{
					label: 'ui.label.showOnDashboard.publicItem',
					content: <BillinToggle {...showOnDashboardToggleProps} />,
				},
			]
		:	[]),
		...(isSuperAdmin() || !isTelematics ?
			[
				{
					label: 'ui.label.availableForPartnership',
					content: <BillinToggle {...partnershipToggleProps} />,
				},
			]
		:	[]),
	];

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				title={t('views.organisationDetail.summary.cardHeaders.billingAndAdministration')}
			/>
			<Divider />
			<CardContent className={classes.content}>
				<Table>
					<TableBody>
						{billingData.map((item) => (
							<TableRow key={item.label}>
								<TableCell>{t(item.label)}</TableCell>
								<TableCell>{item?.content ? item?.content : '-'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{isSuperAdmin() && (
					<Box display='flex' flexDirection='column' p={2}>
						<Typography>QR-code</Typography>

						{dynamicLinkQrLoading ?
							<Skeleton animation='wave' width={160} height={160} sx={{ alignSelf: 'center' }} />
						: qrUrl != null ?
							<>
								<Box alignSelf='center' pt={4}>
									<img alt='qrCode' src={qrUrl} width='160px' />
								</Box>
								<Box alignSelf='center'>
									<Button startIcon={<DownloadIcon />} onClick={handleDownloadQrClick}>
										{t('views.instanceDetails.qrCode')}
									</Button>
								</Box>
							</>
						:	<Button onClick={() => handleGenerateQrClick(organisation.id)}>
								{t('ui.generateQr')}
							</Button>
						}
					</Box>
				)}
			</CardContent>
			{organisation.isVerified ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.edit')}
						</StyledButton>
					</CardActions>
					{openEdit ?
						<EditBillingAndAdminInfo
							onClose={handleEditClose}
							open={openEdit}
							organisation={organisation}
						/>
					:	null}
				</>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		patchedOrganisation: state.details.patchedOrganisation,
		currentUser: state.details.currentUser,
		createDynamicLink: state.details.createDynamicLink,
		dynamicLinkQr: state.blob.dynamicLinkQr,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisation: (id) => dispatch(fetchOrganisation(id)),
		onPatchUpdateOrganisation: (organisationId, partnershipAvailability) =>
			dispatch(patchUpdateOrganisation(organisationId, partnershipAvailability)),
		onFetchDynamicLinkQr: (id) => dispatch(getDynamicLinkQr(`organisations/${id}`)),
		onCreateDynamicLink: (id) => dispatch(createDynamicLink(`organisations/${id}`)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAndAdminInfo);
