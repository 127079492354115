import { useState, useEffect, useRef } from 'react';

import { Edit as EditIcon, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	IconButton,
	Stack,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { DragAndDropImageCropper } from '~components';
import { useSnackbar } from '~hooks';

import EditGeneralInfo from './EditGeneralInfo';
import { useStyles } from './style';
import { OpenEmail, OpenPhoneNumber, StyledButton } from '../../../../../../components';
import { isObject, isFullString, isFullArray, isUndefined } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';

interface GeneralInfoProps {
	className?: string;
	organisation: object;
	onUpdateOrganisationImage?(...args: unknown[]): unknown;
	updateOrganisationImage?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onUpdateDetailsState?(...args: unknown[]): unknown;
}

const GeneralInfo = (props: GeneralInfoProps) => {
	const {
		organisation,
		className,

		onUpdateOrganisationImage,
		updateOrganisationImage,
		onUpdateDetailsState,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const imageRef = useRef(null);

	const defaultImage =
		isObject(organisation) && isFullString(organisation.logo) ? organisation.logo : '';

	const {
		data: updateImageData,
		loading: updateImageLoading,
		error: updateImageError,
	} = updateOrganisationImage;
	const [previewImage, setPreviewImage] = useState(defaultImage);

	useEffect(() => {
		// Clean up on exit
		return () => setPreviewImage(null);
	}, []);

	useEffect(() => {
		// Update preview image.
		// TODO: organisation.logo should be set as the only and only source.
		// Delete previewImage
		setPreviewImage(organisation.logo);
	}, [organisation.logo]);

	const [openEdit, setOpenEdit] = useState(false);

	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();

	useEffect(() => {
		if (!updateImageLoading) {
			if (isFullArray(updateImageData)) {
				onUpdateDetailsState('organisation', { ...organisation, logo: updateImageData });
			} else if (isObject(updateImageError)) {
				const initialState =
					isObject(organisation) && isFullString(organisation.logo) ? organisation.logo : '';
				setPreviewImage(initialState);
			}
		}
	}, [updateOrganisationImage]);

	const handleEditOpen = () => {
		setOpenEdit(true);
	};

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const handleChange = (file, uri) => {
		setPreviewImage(uri);
		onUpdateOrganisationImage(organisation.id, [file]);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader title={t('views.organisationDetail.summary.cardHeaders.general')} />
			<Divider />
			<CardContent className={classes.content}>
				<DragAndDropImageCropper
					ref={imageRef}
					src={previewImage}
					onChange={(val) => val != null && handleChange(val.file, val.uri)}
					slotProps={{
						dragAndDropField: {
							maxImageSizeInBytes: 1024000,
							clearable: false,
							onError: (error) => enqueueErrorSnackbar(error.message)
						},
						imageCropper: {
							aspect: 1,
						},
					}}
				/>

				<Stack direction='row' sx={{ justifyContent: 'flex-start', width: 1 }}>
					<IconButton onClick={() => imageRef.current?.openFilePicker()}>
						<PhotoCameraIcon />
					</IconButton>
				</Stack>

				<Table>
					<TableBody>
						<TableRow>
							<TableCell>{t('ui.label.name')}</TableCell>
							<TableCell>{organisation.name}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.legalName')}</TableCell>
							<TableCell>
								{!isUndefined(organisation.legalName) ? organisation.legalName : '-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.email')}</TableCell>
							<TableCell>
								{isFullString(organisation.emailAddress) ?
									<OpenEmail email={organisation.emailAddress} />
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.label.phoneNumber')}</TableCell>
							<TableCell>
								{isFullString(organisation.phoneNumber) ?
									<OpenPhoneNumber number={organisation.phoneNumber} />
								:	'-'}
							</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell>{t('ui.label.users')}</TableCell>
							<TableCell>{organisation.totalUsers}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>{t('ui.category.userGroups')}</TableCell>
							<TableCell>{organisation.totalUserGroups}</TableCell>
						</TableRow>
						<TableRow className={classes.tableRow}>
							<TableCell style={{ verticalAlign: 'top' }}>{t('ui.label.description')}</TableCell>
							<TableCell>{organisation.description}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			{organisation.isVerified ?
				<>
					<CardActions className={classes.actions}>
						<StyledButton
							onClick={handleEditOpen}
							startIcon={<EditIcon />}
							variant='inline-default'
						>
							{t('ui.button.inline.edit')}
						</StyledButton>
					</CardActions>
					<EditGeneralInfo onClose={handleEditClose} open={openEdit} organisation={organisation} />
				</>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		category: state.details.category,
		updateOrganisationImage: state.details.updateOrganisationImage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateOrganisationImage: (id, imageArray) =>
			dispatch(actions.updateOrganisationImage(id, imageArray)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
