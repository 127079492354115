import { useEffect } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';

import EmailDomains from './EmailDomains';
import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import BookingList from '../../../Bookings/BookingList';
import SsoDomainsPage from '~pages/organisations/ssoDomainsPage';

interface OrganisationDetailProps {
	organisationObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchOrganisation?(...args: unknown[]): unknown;
	onCategory?(...args: unknown[]): unknown;
	category?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const OrganisationDetail = (props: OrganisationDetailProps) => {
	const { organisationObject, onFetchOrganisation } = props;
	const location = useLocation();
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const classes = useStyles();

	const { id, tab } = useParams();

	const {
		data: organisationData,
		loading: organisationLoading,
		error: organisationError,
	} = organisationObject;
	const organisationReady =
		isObject(organisationData) && !organisationLoading && !organisationError;
	// const {data: categoryData, loading: categoryLoading, error: categoryError } = category;

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (!isObject(organisationData) || id !== organisationData.id.toString()) {
			onFetchOrganisation(id);
		}
	}, [id]);

	const verified = isObject(organisationData) && organisationData.isVerified;

	const tabs =
		!verified ?
			[{ value: 'summary', label: t('ui.summary') }]
		:	[
				{ value: 'summary', label: t('ui.summary') },
				{ value: 'bookings', label: t('nav.category.bookings') },
				{ value: 'email-domains', label: t('views.emailDomains.title') },
				{ value: 'sso-domains', label: t('ssoDomains') },
			];

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to={`/organisations/${id}/summary`} />;
	}

	return (
		<Page className={classes.root} title={t('views.organisationDetail.page.title')}>
			<Header
				data={organisationData}
				loading={!organisationReady}
				name={isObject(organisationData) ? organisationData.name : '-'}
			/>
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((tab) => (
					<Tab key={tab.value} label={tab.label} value={tab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			{verified ?
				<div className={classes.content}>
					{tab === 'summary' && (
						<Summary
							loading={organisationData == null}
							organisation={organisationData}
							verified={verified}
						/>
					)}
					{tab === 'bookings' && verified && <BookingList organisationId={parseInt(id, 10)} />}
					{tab === 'email-domains' && <EmailDomains organisationId={id} />}
					{tab === 'sso-domains' && <SsoDomainsPage organisationId={id} />}
				</div>
			:	<div className={classes.content}>
					{tab === 'summary' && (
						<Summary loading={organisationData == null} organisation={organisationData} />
					)}
				</div>
			}
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationObject: state.details.organisation,

		category: state.details.category,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onCategory: (id) => dispatch(actions.category(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationDetail);
