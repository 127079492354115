import path from 'path';

import { TopologyService } from '~services';

import PaymentStatusEnum from '../enums/paymentStatusEnum';
import Billing from '../interfaces/billing';

class BillingsService extends TopologyService {
	public readonly path = 'finances/billings';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of access rules
	 * @param date As date object or date iso string
	 * TODO: date should eventually be of type Date, but for backwards compatibility
	 * allow a string.
	 * @returns
	 */
	async getBilling(args: {
		// TODO: the providerId is used in the admin panel, but does it do anything.
		// Seems not defined in the swagger
		providerId?: string;
		// TODO: check if we really need to provide the organisationId. Feels random...
		organisationId?: string;
		date: Date | string;
		paymentStatus?: PaymentStatusEnum;
		searchQuery?: string;
	}): Promise<Billing> {
		const pathParam = typeof args.date === 'string' ? args.date : args.date.toISOString();
		const { data } = await this._client.get<BillingResponse>(path.join(this.path, pathParam), {
			params: {
				providerId: args.providerId,
				organisationId: args.organisationId,
				filters: args.paymentStatus,
				searchTerm: args.searchQuery,
			},
		});

		return BillingsService.fromResponse(data);
	}

	/**
	 * TODO: don't mapping for now, because we use this service mainly for "legacy" code.
	 * Eventually do proper mapping to seperate business and api data
	 * @param data
	 * @returns
	 */
	static fromResponse(data: BillingResponse): Billing {
		const { ...rest } = data;

		return {
			...rest,
		};
	}
}

interface BillingResponse {
	start: string;
	end: string;
	title: string;
	open: number;
	status: string;
	amount: object;
	billings: object[];
}

export default BillingsService;
