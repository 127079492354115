import { Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '../style';

interface BookingTypeProps {
	onChange?(...args: unknown[]): unknown;
	selectType?: string;
}

const BookingType = (props: BookingTypeProps) => {
	const { selectType, onChange } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();

	const sx = { textTransform: 'capitalize', backgroundColor: 'rgba(21, 38, 62, 0.08)' };
	return (
		<Box mb={2.5}>
			<ToggleButtonGroup
				exclusive={true}
				fullWidth={true}
				onChange={onChange}
				size='small'
				value={selectType}
			>
				<ToggleButton className={classes.test} sx={sx} value='booking'>
					{t('ui.category.bookings')}
				</ToggleButton>
				<ToggleButton className={classes.test} sx={sx} value='maintenance'>
					{t('nav.category.maintenance')}
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
};

export default BookingType;
