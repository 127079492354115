import { useState } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { FormDrawer } from '~components';
import { CreateTicketForm, ServiceTicketsService } from '~features/maintenance';

import AddTicket from './AddTickets';
import Header from './Header';
import ServiceTickets from './ServiceTickets';
import { useStyles } from './style';
import Unavailabilitys from './Unavailabilitys';
import { Page } from '../../components';
import useSWRMutation from 'swr/mutation';
import { useSnackbar } from '~hooks';

const service = new ServiceTicketsService();

const Maintenance = (props) => {
	const { t } = useTranslation('general');
	const { tab } = useParams();
	const navigate = useNavigate();

	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();

	const classes = useStyles();
	const [openAddTicket, setOpenAddTicket] = useState(false);
	const [shouldRefreshOpenTickets, setShouldRefreshOpenTickets] = useState(false);

	const { trigger: triggerCreate, isMutating: isCreateMutating } = useSWRMutation(
		service.basePath,
		(_, { arg }) => service.createTicket(arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyAdded')} ${t('rule').toLowerCase()}`);
				setOpenAddTicket(false);
				setShouldRefreshOpenTickets(true);
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path' });
	};

	const handleCloseAddTicket = (shouldRefresh = false) => {
		setOpenAddTicket(false);
		if (shouldRefresh) {
			setShouldRefreshOpenTickets(true);
		}
	};

	const tabs = [
		{ value: 'open', label: 'ui.label.tab.openTickets' },
		{ value: 'closed', label: 'ui.label.tab.closedTickets' },
		{ value: 'unavailability', label: 'ui.label.tab.unavailability' },
	];

	if (!tab) {
		return <Navigate to={'/ticketing/open'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const headerButtons = [
		{ key: 'open', buttons: [] },
		{ key: 'closed', buttons: [] },
		{ key: 'unavailability', buttons: [] },
	];

	return (
		<Page className={classes.root} title={t('ui.label.maintenance')}>
			{headerButtons.map((item) => item.key === tab && <Header key={item.key} {...item} />)}
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((maintenanceTab) => (
					<Tab
						key={maintenanceTab.value}
						label={t(maintenanceTab.label)}
						value={maintenanceTab.value}
					/>
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'open' && (
					<ServiceTickets
						setOpenAddTicket={setOpenAddTicket}
						setShouldRefresh={setShouldRefreshOpenTickets}
						shouldRefresh={shouldRefreshOpenTickets}
						typeOfTickets='open,pending,planned,ready'
					/>
				)}
				{tab === 'closed' && <ServiceTickets typeOfTickets='closed' />}
				{tab === 'unavailability' && <Unavailabilitys />}
			</div>
			<AddTicket onClose={handleCloseAddTicket} open={openAddTicket} />
			{/* <FormDrawer
				title={t('views.tickets.addTicket.newTitle')}
				open={openAddTicket}
				onClose={() => setOpenAddTicket(false)}
				saveLabel={t('view.ticketmanagement.button.contained.createticket')}
				loading={isCreateMutating}
			>
				<CreateTicketForm
					onSubmit={async (val) => await triggerCreate(val)}
				/>
			</FormDrawer> */}
		</Page>
	);
};

export default Maintenance;
