import { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useStyles } from './style';
import { SearchAutocomplete, SelectedOrganisationCard, StyledButton } from '../../../components';
import { useWizardFormField, useError } from '../../../shared/hooks';
import { isEmptyObject, isFullArray } from '../../../shared/utility';
import * as actions from '../../../store/actions';

interface AssignDevicesProps {
	open?: boolean;
	onClose?(...args: unknown[]): unknown;
	onFetchOrganisations?(...args: unknown[]): unknown;
	selectedDevices?: object | unknown[];
	onAssignDevices?(...args: unknown[]): unknown;
	unassigned?: boolean;
	setSelectedDevices?(...args: unknown[]): unknown;
	organisationsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	assignDevices?: {
		success?: object | boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const AssignDevices = (props: AssignDevicesProps) => {
	const {
		onClose,
		open,

		organisationsList,
		onFetchOrganisations,
		selectedDevices,
		onAssignDevices,
		assignDevices,
		setSelectedDevices,
	} = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const selectedOrganisation = useWizardFormField({}, { required: true });

	const assignDevicesMessage = useError({
		value: assignDevices,
		message: t('views.devices.successfullyAssigned'),
	});

	const resetSelectedOrganisation = () => {
		selectedOrganisation.onChange({});
	};

	useEffect(() => {
		if (!open) {
			setSelectedDevices(null);
			resetSelectedOrganisation();
		}
	}, [open]);

	const handleConfirm = () => {
		if (isFullArray(selectedDevices)) {
			const devices = selectedDevices.map((item) => item.deviceId);
			onAssignDevices(selectedOrganisation.value.id, devices);
			assignDevicesMessage.setStartAction(true);
		}
		onClose();
	};

	return (
		<Drawer
			anchor='right'
			classes={{ paper: classes.drawer }}
			onClose={() => onClose()}
			open={open}
			variant='temporary'
		>
			<Box display='flex'>
				<StyledButton
					onClick={() => onClose()}
					size='small'
					startIcon={<CloseIcon />}
					variant='inline-default'
				>
					{t('ui.button.inline.close')}
				</StyledButton>
			</Box>
			<>
				<Typography className={classes.header} variant='h2'>
					{t('views.devices.assignedToorganisation')}
				</Typography>
				<div className={classes.formGroup}>
					<Typography gutterBottom={true} variant='h6'>
						{t('ui.label.organisation')}
						<sup>*</sup>
					</Typography>
					{!isEmptyObject(selectedOrganisation.value) ?
						<SelectedOrganisationCard
							handleClose={resetSelectedOrganisation}
							hasCloseButton={false}
							name={selectedOrganisation.value.name}
						/>
					:	<SearchAutocomplete
							dataList={organisationsList}
							listType={'organisations'}
							onFetchData={onFetchOrganisations}
							placeholder={t('views.addPolicy.aboutPolicy.placeholder.searchOrganisation')}
							setSelected={selectedOrganisation.onChange}
						/>
					}
				</div>
				<Box mb={3} mt={3}>
					<Typography variant='h6'>{t('ui.label.devices')}</Typography>
					<Box border='solid 1px #dedede' borderRadius='8px' mt={2} p={1}>
						{isFullArray(selectedDevices) &&
							selectedDevices.map((device) => (
								<Typography key={device.id}>{`Hw UID: ${device.deviceId}`}</Typography>
							))}
					</Box>
				</Box>
				<StyledButton
					disabled={isEmptyObject(selectedOrganisation.value)}
					onClick={handleConfirm}
					size='large'
					variant='contained-primary'
				>
					{t('ui.confirm')}
				</StyledButton>
			</>
		</Drawer>
	);
};

const mapStateToProps = (state) => {
	return {
		organisationsList: state.paged.organisations,

		assignDevices: state.details.assignDevices,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onAssignDevices: (organisationId, devices) =>
			dispatch(actions.assignDevices(organisationId, devices)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignDevices);
