import { LoadingButton } from '@mui/lab';
import {
	Dialog as MuiDialog,
	DialogProps as MuiDialogProps,
	Stack,
	Typography,
	useTheme,
	useMediaQuery,
	Box,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	DialogActionsProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import i18n from '~lib/i18n';

export type DialogProps = {
	disableDefaultActions?: boolean;

	loading?: boolean;
	variant?: 'new' | 'legacy';
	saveLabel?: string;
	disableSave?: boolean;
	onSave?: () => void;
	subTitle?: string;
} & MuiDialogProps;

/**
 * A dialog wrapper component to keep things like title and
 * fullscreen consistent among the application
 */
const Dialog = ({
	children,
	loading = false,
	disableDefaultActions = true,
	variant = 'new',
	saveLabel = i18n.t('ui.save'),
	disableSave = false,
	onSave,
	subTitle,
	...dialogProps
}: DialogProps) => {
	const { t } = useTranslation('general');
	const theme = useTheme();
	const largerThanMd = useMediaQuery(theme.breakpoints.up('md'));

	const handleClose = () => {
		dialogProps.onClose?.({}, 'escapeKeyDown');
	};

	return (
		<MuiDialog fullScreen={!largerThanMd} {...dialogProps}>
			<DialogTitle
				variant='h4'
				{...(subTitle != null && {
					sx: {
						pb: 1,
					},
				})}
			>
				{dialogProps.title}
			</DialogTitle>
			{subTitle && (
				<DialogTitle variant='body2' sx={{ pt: 0 }}>
					{subTitle}
				</DialogTitle>
			)}

			<DialogContent>{children}</DialogContent>

			{!disableDefaultActions && (
				<DialogActions
					sx={
						variant === 'legacy' ?
							{
								justifyContent: 'space-evenly',
								px: 4,
								py: 2,
							}
						:	{}
					}
				>
					<Button
						onClick={handleClose}
						{...(variant === 'legacy' && {
							fullWidth: true,
							variant: 'outlined',
							size: 'large',
						})}
					>
						{t('ui.close')}
					</Button>
					<LoadingButton
						variant='contained'
						onClick={onSave}
						disabled={disableSave}
						loading={loading}
						{...(variant === 'legacy' && {
							fullWidth: true,
							size: 'large',
						})}
					>
						{saveLabel}
					</LoadingButton>
				</DialogActions>
			)}
		</MuiDialog>
	);
};

export default Dialog;
