import { useEffect, useState } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	Box,
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import {
	AlertDialog,
	StaticMap,
	SelectWithLazyLoading,
	StyledButton,
} from '../../../../../../components';
import { useError } from '../../../../../../shared/hooks';
import { isObject, isNumber } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { useStyles } from '../style';

interface LocationInformationProps {
	className?: string;
	itemData?: object;
	isOwnItem?: boolean;
	onResetState?(...args: unknown[]): unknown;
	onItemPatch?(...args: unknown[]): unknown;
	hubsList?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchHubs?(...args: unknown[]): unknown;
	onFetchPrice?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
}

const LocationInformation = (props: LocationInformationProps) => {
	const {
		className,
		itemData,

		itemPatch,
		onUpdateDetailsState,
		onItemPatch,
		isOwnItem,
		onFetchHubs,
		hubsList,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const hubData = itemData.hubReference;

	const [dialog, setDialog] = useState(false);

	const [locationFilter, setLocationFilter] = useState({ id: hubData.id, value: hubData.name });

	const { data: patchData, loading: patchLoading, error: patchError } = itemPatch;
	const patchReady = isObject(patchData) && !patchLoading && !patchError;

	const patchItemErrorHandling = useError({
		value: itemPatch,
		variant: 'success',
		message: `${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
	});

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: itemData.hubReference.organisationReference.id,
	});

	const [filter] = useState({ name: 'filters', value: 'none' });

	useEffect(() => {
		if (isObject(patchData)) {
			onUpdateDetailsState('itemDetails', patchData);
			onResetState('itemPatch');
		}
	}, [patchData]);

	const handleCancelDialog = () => {
		setDialog(false);
		setLocationFilter({ id: hubData.id, value: hubData.name });
	};

	const handleConfirmDialog = () => {
		const patchItemBody = {
			hubId: locationFilter.id,
		};
		onItemPatch(itemData.id, patchItemBody);
		patchItemErrorHandling.setStartAction(true);
		setDialog(false);
	};

	const handleChangeLocation = (value) => {
		if (value.id !== locationFilter.id) {
			setLocationFilter({ id: value.id, value: value.name });
			setDialog(true);
		}
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={'Location information'} />
			<Divider />
			<CardContent>
				<Box display='flex' flexDirection='column' pb={2}>
					<Typography pb={2}>
						{t('views.itemManagement.details.instanceDetails.locationInfo.assigned')}
					</Typography>
					{isOwnItem || isSuperAdmin() ?
						<>
							<SelectWithLazyLoading
								dataList={hubsList}
								extraFilter={filter}
								filter={organisationIdFilter}
								listType='organisations'
								onFetchData={onFetchHubs}
								placeholder={t('views.addItem.settingsCard.locationCard.searchLocation')}
								setSelected={handleChangeLocation}
								value={locationFilter.value}
							/>
							<AlertDialog
								dialogDescription={t('views.itemDetail.summary.locationChanging')}
								dialogTitle={t('ui.label.location')}
								handleClose={handleCancelDialog}
								handleConfirm={handleConfirmDialog}
								loading={patchLoading && !patchReady}
								open={dialog}
							/>
						</>
					:	null}
				</Box>
				{isObject(hubData) ?
					<>
						<StaticMap isImage lat={hubData.latitude} lng={hubData.longitude} />
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>{t('ui.pickUpReturn')}</TableCell>
									<TableCell>
										{`${isObject(hubData.address) ? hubData.address.street : ''} ${isObject(hubData.address) && isNumber(hubData.address.number) ? hubData.address.number : ''}${isObject(hubData.address) ? ',' : ''} ${isObject(hubData.address) ? hubData.address.city : ''}`}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>{t('views.locationDetail.locationInfo.actualLocation')}</TableCell>
									<TableCell>
										<StyledButton
											component={RouterLink}
											size='small'
											to={'/map-view'}
											variant='contained-secondary'
											state={{ from: location.pathname }}
										>
											{t('ui.label.goToMapView')}
										</StyledButton>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</>
				:	null}
			</CardContent>
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		hubsList: state.paged.hubs,
		itemPatch: state.details.itemPatch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationInformation);
