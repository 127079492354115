import { Typography } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';

interface NotificationProps {
	className?: string;
	startIcon?: React.ReactNode;
	message?: string;
	variant?: 'error';
}

const Notification = (props: NotificationProps) => {
	const { className, startIcon, message, variant } = props;
	const classes = useStyles();

	return (
		<div
			className={clsx({
				[className]: isFullString(className),
				[classes.root]: true,
				[classes.error]: variant === 'error',
			})}
		>
			{startIcon}
			<Typography
				className={clsx({
					[classes.text]: true,
					[classes.error]: variant === 'error',
				})}
			>
				{message}
			</Typography>
		</div>
	);
};

export default Notification;
