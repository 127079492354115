import { Switch, CircularProgress } from '@mui/material';

interface BillinToggleProps {
	loading?: boolean;
	checked?: boolean;
	onChange?(...args: unknown[]): unknown;
	color?: string;
	size?: string;
}

const BillinToggle = (props: BillinToggleProps) => {
	const { loading, checked, onChange, color, size } = props;

	return (
		<>
			{loading && <CircularProgress color='inherit' disableShrink size={19} />}
			{!loading && <Switch checked={checked} onChange={onChange} color={color} size={size} />}
		</>
	);
};

export default BillinToggle;
