import { Typography } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import { isFullString } from '../../../shared/utility';
import palette from '../../../theme/palette';

interface LabelProps {
	children?: React.ReactNode;
	className?: string;
	color?: string;
	shape?: 'square' | 'rounded';
	style?: object;
	variant?: 'contained' | 'outlined';
	fontColor?: string;
	type?: 'success' | 'warning' | 'error' | 'default' | 'disabled';
}

const Label = ({
	className,
	variant = 'contained',
	color,
	shape = 'rounded',
	children,
	style = {},
	fontColor,
	type = 'default',
	...rest
}: LabelProps) => {
	const classes = useStyles();

	const rootClassName = clsx(
		{
			[classes.root]: true,
			[classes.rounded]: shape === 'rounded',
		},
		className,
	);

	const colorVariants = {
		success: {
			backgroundColor: palette.success.light,
			color: palette.success.main,
		},
		warning: {
			backgroundColor: palette.warning.light,
			color: palette.warning.main,
		},
		error: {
			backgroundColor: palette.error.light,
			color: palette.error.main,
		},
		default: {
			backgroundColor: palette.info.light,
			color: palette.info.main,
		},
		disabled: {
			backgroundColor: palette.disabled.light,
			color: palette.disabled.main,
		},
	};

	const finalStyle = { ...style };

	if (variant === 'contained') {
		finalStyle.backgroundColor =
			isFullString(color) ? color
			: isFullString(type) ? colorVariants[type].backgroundColor
			: colorVariants.default.backgroundColor;
		finalStyle.color =
			isFullString(fontColor) ? fontColor
			: isFullString(type) ? colorVariants[type].color
			: colorVariants.default.color;
	} else {
		finalStyle.border = `1px solid ${
			isFullString(color) ? color
			: isFullString(type) ? colorVariants[type].color
			: colorVariants.default.color
		}`;
		finalStyle.color =
			isFullString(color) ? color
			: isFullString(type) ? colorVariants[type].color
			: colorVariants.default.color;
	}

	return (
		<Typography {...rest} className={rootClassName} style={finalStyle}>
			{children}
		</Typography>
	);
};

export default Label;
