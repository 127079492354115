import { useState, useEffect } from 'react';

import {
	LinkOff as LinkOffIcon,
	Link as LinkIcon,
	InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { Paper, Typography, Box, CircularProgress, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import { useStyles, MyTooltip } from './style';
import { Tooltip } from './Tooltip';
import { UnlinkDialog } from './UnlinkDialog';
import { Table, ActionDialog, GenericMoreButton } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import { useError } from '../../../shared/hooks';
import {
	isEmptyArray,
	isUndefined,
	isObject,
	isEmptyString,
	handleLockType,
	mapBatteryLevelEnum,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import LinkDevices from '../ActionFlows/LinkDevices';

interface LinkedDevicesTableProps {
	assigned?: boolean;
	unassigned?: boolean;
	onFetchLinkedDevices?(...args: unknown[]): unknown;
	onUnlinkDevices?(...args: unknown[]): unknown;
	search?: object;
	shouldSearch?: boolean;
	setShouldSearch?(...args: unknown[]): unknown;
	eventsFilters?: object;
	paginationLinked?: object;
	devices?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	linkDevices?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	unlinkDevices?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	unassignDevices?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	linkDeviceToItemGroup?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	unlinkItemGroup?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	unlinkAllItemGroup?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
}

const LinkedDevicesTable = (props: LinkedDevicesTableProps) => {
	const {
		assigned,
		unassigned,
		devices,
		onFetchLinkedDevices,
		currentUser,
		onUnlinkDevices,
		unlinkDevices,
		linkDevices,
		search,
		shouldSearch,
		setShouldSearch,
		eventsFilters,
		paginationLinked,
		linkDeviceToItemGroup,
		unlinkItemGroup,
		unlinkAllItemGroup,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();
	const { isSuperAdmin } = useAuthorize();

	const [openLinkDevices, setOpenLinkDevices] = useState(false);

	const [order, setOrder] = useState('asc');
	const [loading, setLoading] = useState(true);
	const [openUnlinkDevices, setOpenUnlinkDevices] = useState(false);
	const [orderDescending, setOrderDescending] = useState(true);
	const [sortingById, setSortingById] = useState('id');
	const [unlinkDialogData, setUnlinkDialogData] = useState(null);
	const [shouldSort, setShouldSort] = useState(false);

	const [selectedDevices, setSelectedDevices] = useState(null);

	const [openUnlinkDialog, setOpenUnlinkDialog] = useState(false);

	const { data: devicesData, loading: devicesLoading } = devices;

	const { data: currentUserData } = currentUser;

	const {
		success: unlinkDevicesSuccess,
		loading: unlinkDevicesLoading,
		error: unlinkDevicesError,
	} = unlinkDevices;

	const unlinkDevicesDone = unlinkDevicesSuccess && !unlinkDevicesLoading && !unlinkDevicesError;

	const {
		data: linkDevicesData,
		loading: linkDevicesLoading,
		error: linkDevicesError,
	} = linkDevices;

	const linkDevicesDone = isObject(linkDevicesData) && !linkDevicesLoading && !linkDevicesError;

	const {
		data: linkDeviceToItemGroupData,
		loading: linkDeviceToItemGroupLoading,
		error: linkDeviceToItemGroupError,
	} = linkDeviceToItemGroup;
	const linkDeviceToItemGroupDone =
		isObject(linkDeviceToItemGroupData) &&
		!linkDeviceToItemGroupLoading &&
		!linkDeviceToItemGroupError;

	const { success: unlinkItemGroupSuccess } = unlinkItemGroup;

	const { success: unlinkAllItemGroupSuccess } = unlinkAllItemGroup;

	const unlinkDevicesMessage = useError({
		value: unlinkDevices,
		message: t('views.devices.successfullyUnlinked'),
	});

	const classes = useStyles();
	const isEmptyResults = isObject(devicesData) && isEmptyArray(devicesData.results);
	const isFullResults = isObject(devicesData) && !isEmptyArray(devicesData.results);

	const filters = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingById) && { sortBy: sortingById }),
		...(!isSuperAdmin() &&
			isObject(currentUserData) && { organisationId: currentUserData.organisationReference.id }),
		...(!isEmptyString(sortingById) && { sortBy: sortingById }),
		...(eventsFilters.deviceType !== 'all' && { deviceType: eventsFilters.deviceType }),
		...(eventsFilters.firmwareVersionFilter !== 'fw' && {
			firmwareVersion: eventsFilters.firmwareVersionFilter,
		}),
		...(eventsFilters.unlockTypeFilter !== 'unlockType' && {
			identificationType: eventsFilters.unlockTypeFilter,
		}),
		...(eventsFilters.organisationFilter !== 'all' && {
			organisationId: eventsFilters.organisationFilter,
		}),
		isAssigned: true,
		isLinked: true,
		orderDescending,
	};

	useEffect(() => {
		if (!unlinkDevicesLoading) {
			setOpenUnlinkDevices(false);
		}
	}, [unlinkDevicesLoading]);

	useEffect(() => {
		if (
			!devicesLoading &&
			(paginationLinked?.fetch ||
				!isObject(devicesData) ||
				assigned ||
				unassigned ||
				shouldSearch ||
				shouldSort ||
				sortingById ||
				unlinkDevicesDone ||
				unlinkAllItemGroupSuccess ||
				unlinkItemGroupSuccess ||
				linkDeviceToItemGroupDone ||
				linkDevicesDone ||
				eventsFilters.shouldFilter)
		) {
			onFetchLinkedDevices(paginationLinked?.page, filters);
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (eventsFilters.shouldFilter) {
			eventsFilters.setShouldFilter(false);
		} else if (paginationLinked?.fetch) {
			paginationLinked?.setFatch(false);
		}
	}, [
		assigned,
		linkDeviceToItemGroupDone,
		unlinkAllItemGroupSuccess,
		unlinkItemGroupSuccess,
		unassigned,
		shouldSearch,
		paginationLinked?.fetch,
		sortingById,
		order,
		unlinkDevicesDone,
		linkDevicesDone,
		eventsFilters.shouldFilter,
	]);

	useEffect(() => {
		if (isObject(devicesData) && !devicesLoading) {
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [devicesLoading]);

	const handleRequestSort = (property) => {
		const isDesc = sortingById === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!isDesc);
		setSortingById(property);
		setShouldSort(true);
	};

	const handleLinkDevicesClose = () => {
		setOpenLinkDevices(false);
		setSelectedDevices(null);
	};

	const handleLinkItemGroup = (item) => {
		setSelectedDevices(item);
		setOpenLinkDevices(true);
	};

	const handUnlinkItemGroup = (item) => {
		setUnlinkDialogData(item);
		setOpenUnlinkDialog(true);
	};

	const handleCloseUnlinkDialog = () => {
		setOpenUnlinkDialog(false);
		setUnlinkDialogData(null);
	};

	const handleUnlinkDevices = (data) => {
		setOpenUnlinkDevices(true);
		setSelectedDevices(data);
	};

	const handleCloseDialog = () => {
		setOpenUnlinkDevices(false);
	};

	const handleConfirmUnlink = () => {
		if (isObject(selectedDevices)) {
			onUnlinkDevices(
				selectedDevices.deviceId,
				selectedDevices.organisationReference.id,
				selectedDevices.itemInstanceReference.id,
			);
		}
		unlinkDevicesMessage.setStartAction(true);
	};

	const createMenuItems = (item) => [
		...(item?.items ?
			[
				{
					icon: <LinkIcon />,
					text: t('views.devices.linkDevices.button.linkToGroup'),
					action: () => handleLinkItemGroup({ ...item, link: 'itemGroup' }),
				},
			]
		:	[]),
		{
			icon: <LinkOffIcon />,
			text: t('views.devices.linkDevices.button.unlink'),
			action: () => (item?.items ? handUnlinkItemGroup(item) : handleUnlinkDevices(item)),
			isRed: true,
		},
	];

	const tableHeaders =
		!loading && isObject(devicesData) && isEmptyArray(devicesData.results) ?
			[]
		:	[
				{ name: 'id', content: t('ui.label.HwUID'), hasSorting: true },
				{ name: 'name', content: t('nav.category.devices'), hasSorting: true },
				{
					name: 'unlockType',
					content: t('views.organisationManagement.devices.unlockType'),
					hasSorting: false,
				},
				{
					name: 'firmware',
					content: t('views.organisationManagement.devices.firmware'),
					hasSorting: false,
				},
				...(isSuperAdmin() ?
					[{ name: 'organisation', content: t('ui.organisation'), hasSorting: false }]
				:	[]),
				{ name: 'itemGroup', content: t('ui.label.itemGroup'), hasSorting: false },
				{ name: 'item', content: t('ui.label.item'), hasSorting: false },
				{ name: 'battery', content: t('ui.label.battery'), hasSorting: true },
				{ name: 'deviceState', content: t('ui.label.deviceStatus'), hasSorting: false },
				{ name: 'lastOnline', content: t('ui.label.lastOnline'), hasSorting: true },
			];

	const loadingBody =
		loading ?
			Array(3)
				.fill(Array(tableHeaders.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const emptyBody =
		isObject(devicesData) && isEmptyArray(devicesData.results) ?
			[
				[
					{
						content: (
							<Paper className={classes.tipCard}>
								<InfoOutlinedIcon className={classes.icon} fontSize='small' />
								<Typography className={classes.tip}>
									{assigned ? '' : t('views.devices.devices.emptyBody.noLinkedDevices')}
								</Typography>
							</Paper>
						),
					},
				],
			]
		:	null;

	const handleLinks = (path, label) => (
		<Link
			className={classes.itemGroup}
			component={RouterLink}
			to={path}
			state={{
				from: location.pathname,
			}}
		>
			{label}
		</Link>
	);

	const handleItemGroup = (device) => {
		if (device?.items?.length === 1) {
			const item = device.items.at(0);
			return handleLinks(`/item-management/items/${item.id}/summary`, item.name);
		}
		if (device?.items?.length > 1) {
			return (
				<MyTooltip
					arrow
					title={device.items.map((item) => (
						<Typography key={item.id}>
							{handleLinks(`/item-management/items/${item.id}/summary`, item.name)}
						</Typography>
					))}
				>
					<Typography className={classes.detailTooltip}>
						{`${device.items.length} ${t('ui.label.itemGroups')}`}
					</Typography>
				</MyTooltip>
			);
		}
		if (device?.itemReference) {
			return handleLinks(
				`/item-management/items/${device.itemReference.id}/summary`,
				device.itemReference.name,
			);
		}
	};

	const tableBody =
		!loading && isObject(devicesData) && !isEmptyArray(devicesData.results) ?
			devicesData.results.map((device) => [
				{
					content: handleLinks(`/devices/${device.deviceId}/summary`, device.deviceId),
					classCell: classes.cellStyle,
				},
				{ content: handleLockType(device.deviceType), classCell: classes.cellStyle },
				{ content: <Tooltip titles={device.identificationType} />, classCell: classes.cellStyle },
				{
					content: isSuperAdmin() ? device.firmwareVersion : device.firmwareVersion,
				},
				...(isSuperAdmin() ?
					[
						{
							content:
								device?.organisationReference &&
								handleLinks(
									`/organisations/${device.organisationReference.id}/summary`,
									device.organisationReference.name,
								),
							classCell: classes.cellStyle,
						},
					]
				:	[]),
				{ content: handleItemGroup(device) },
				{
					content:
						device?.itemInstanceReference ?
							handleLinks(
								`/item-management/${device.itemReference.id}/instance/${device.itemInstanceReference.id}/summary`,
								device.itemInstanceReference.name,
							)
						:	'',
				},
				{
					content:
						!isUndefined(device.batteryLevel) && device.deviceType === 'doorLock' ?
							mapBatteryLevelEnum(device.batteryLevelEnum)
						:	device.batteryLevel,
					classCell: classes.batteryLevelCell,
				},
				{
					content: t(`ui.label.device.state.${device.deviceState}`),
					classCell: classes.cellStyle,
				},
				{
					content: (
						<Box
							alignItems='center'
							display='flex'
							flexDirection='row-reverse'
							justifyContent='space-between'
							maxHeight='20px'
						>
							<Box pl={1}>
								<GenericMoreButton menuItems={createMenuItems(device)} />
							</Box>
							{!isUndefined(device.lastUpdated) ? commaTimeStrings(device.lastUpdated) : '-'}
						</Box>
					),
					classCell: classes.lastUpdatedCell,
				},
			])
		:	null;

	return (
		<>
			<Table
				body={loadingBody || emptyBody || tableBody}
				cellStyle={classes.cellStyle}
				checkboxAligning={isSuperAdmin() && !isEmptyResults ? true : false}
				data={isObject(devicesData) ? devicesData.results : []}
				handlePageChange={paginationLinked?.pageNumberChange}
				handleSorting={handleRequestSort}
				header={tableHeaders}
				isNotPaginate={isUndefined(paginationLinked) || loading || isEmptyResults || !isFullResults}
				loading={devicesLoading}
				order={order}
				orderBy={sortingById}
				page={paginationLinked?.pageNumber}
				rowsPerPage={paginationLinked?.pageSize}
				setRowsPerPage={paginationLinked?.pageSizeChange}
				title={`${devicesData ? devicesData.total : 0} ${t('views.devices.linkedDevicesTable.linkedDevices')}`}
				total={devicesData ? devicesData.total : 0}
			/>
			<LinkDevices
				id={isObject(selectedDevices) ? selectedDevices.organisationReference.id : null}
				onClose={handleLinkDevicesClose}
				open={openLinkDevices}
				selectedDevices={selectedDevices}
				setSelectedDevices={setSelectedDevices}
				unassigned={true}
			/>
			{openUnlinkDevices ?
				<ActionDialog
					actionButtonProps={{
						action: handleConfirmUnlink,
						text:
							unlinkDevicesLoading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
					}}
					handleClose={handleCloseDialog}
					loading={false}
					open={openUnlinkDevices}
					title={t('views.devices.linkedDevicesTable.unlinkDevice')}
				>
					<Typography variant='body2'>{t('views.devices.linkDevices.confirmUnlinking')}</Typography>
				</ActionDialog>
			:	null}
			<UnlinkDialog
				open={openUnlinkDialog}
				onClose={handleCloseUnlinkDialog}
				data={unlinkDialogData}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		devices: state.paged.fetchLinkedDevices,

		currentUser: state.details.currentUser,
		linkDevices: state.details.linkDevices,
		unlinkDevices: state.condition.unlinkDevices,
		linkDeviceToItemGroup: state.details.linkDeviceToItemGroup,
		unlinkItemGroup: state.condition.unlinkItemGroup,
		unlinkAllItemGroup: state.condition.unlinkAllItemGroup,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchLinkedDevices: (page, filters) => dispatch(actions.fetchLinkedDevices(page, filters)),
		onUnlinkDevices: (deviceId, organisationId, instanceId) =>
			dispatch(actions.unlinkDevices(deviceId, organisationId, instanceId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedDevicesTable);
