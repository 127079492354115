import { useEffect, useState } from 'react';

import { Tabs, Tab, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';

import Header from './Header';
import { useStyles } from './style';
import Summary from './Summary';
import UserFinance from './UserFinance';
import { Page } from '../../../../components';
import { usePrevious } from '../../../../shared/hooks';
import { isObject, isArray } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import BookingList from '../../../Bookings/BookingList';

interface UserDetailsProps {
	onResetState?(...args: unknown[]): unknown;
	userObject?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchUser?(...args: unknown[]): unknown;
	onFetchUsersUserRole?(...args: unknown[]): unknown;
	userRole?: {
		data?: string;
		loading?: boolean;
		error?: object | string;
	};
	userPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	updateUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	currentUser?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	personalPaymentMethod?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	userLicense?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	NFCTags?: {
		data?: unknown[];
		loading?: boolean;
		error?: object | string;
	};
	onUpdateDetailsState?(...args: unknown[]): unknown;
	onFetchPersonalPaymentMethodOfUser?(...args: unknown[]): unknown;
	onFetchUserLicense?(...args: unknown[]): unknown;
	onFetchNFCTags?(...args: unknown[]): unknown;
}

const UserDetails = (props: UserDetailsProps) => {
	const {
		userObject,
		onFetchUser,
		onFetchUsersUserRole,
		onFetchPersonalPaymentMethodOfUser,
		onFetchUserLicense,
		personalPaymentMethod,
		userLicense,

		userRole,
		onUpdateDetailsState,
		userPatch,
		updateUser,
		currentUser,
		onFetchNFCTags,
		NFCTags,
		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();
	const { isSuperAdmin } = useAuthorize();

	const { data: userRoleData } = userRole;
	const classes = useStyles();

	const { id, tab } = useParams();

	const { data: userData, loading: userLoading, error: userError } = userObject;
	const { data: userPatchData, loading: userPatchLoading } = userPatch;
	const { data: updateUserData, loading: updateUserLoading } = updateUser;
	const { data: currentUserData } = currentUser;
	const userPatchSuccess = usePrevious(userPatchLoading) && isObject(userPatchData);
	const updateUserSuccess = usePrevious(updateUserLoading) && isObject(updateUserData);

	const {
		data: paymentMethodData,
		loading: paymentMethodLoading,
		error: paymentMethodError,
	} = personalPaymentMethod;
	const paymentMethodReady =
		isObject(paymentMethodData) && !paymentMethodLoading && !paymentMethodError;

	const { data: licenseData, loading: licenseLoading } = userLicense;

	const { loading: NFCTagLoading, data: NFCTagData, error: NFCTagError } = NFCTags;
	const nfcReady = isArray(NFCTagData) && !NFCTagError && !NFCTagLoading;

	const userReady = isObject(userData) && !userLoading && !userError;

	const [isInternalUser, setIsInternalUser] = useState(false);
	const [userIsCurrentUser, setUserIsCurrentUser] = useState(false);

	useEffect(() => {
		if (isObject(userData) && `${userData.id}` !== id) {
			onResetState('userLicense');
		}
	}, [userData, id]);

	useEffect(() => {
		if (userReady && !!currentUserData && tab === 'summary') {
			setIsInternalUser(
				currentUserData.organisationReference.id === userData.organisationReference.id,
			);
			setUserIsCurrentUser(currentUserData.id === userData.id);
		}
	}, [currentUserData, userReady, userData]);

	const handleTabsChange = (event, value) => {
		navigate(`../${value}`, { relative: 'path', state: { ...location.state } });
	};

	useEffect(() => {
		if (id) {
			onFetchUser(id);
		}
	}, [id]);

	useEffect(() => {
		return () => {
			onResetState('user');
		};
	}, []);

	useEffect(() => {
		if (!!id && isSuperAdmin() && tab === 'summary') {
			onFetchPersonalPaymentMethodOfUser(id);
		}
	}, [id, auth.user?.profile.role]);

	useEffect(() => {
		if (!!id && !userData?.isAnonymous && tab === 'summary' && (isInternalUser || isSuperAdmin())) {
			onFetchUsersUserRole(id);
		}
	}, [id, isInternalUser, auth.user?.profile.role]);

	useEffect(() => {
		if (
			!licenseLoading &&
			tab === 'summary' &&
			userReady &&
			`${userData.id}` === id &&
			isSuperAdmin() &&
			userData.licenseStatus !== 'notApplicable'
		) {
			onFetchUserLicense(id);
		}
	}, [userReady, userData, auth.user?.profile.role, id]);

	useEffect(() => {
		return () => {
			onResetState('userLicense');
		};
	}, []);

	useEffect(() => {
		if (userPatchSuccess) {
			onUpdateDetailsState('user', userPatchData);
		} else if (updateUserSuccess) {
			onUpdateDetailsState('user', updateUserData);
		}
	}, [userPatchSuccess, updateUserSuccess]);

	useEffect(() => {
		if (userReady && tab === 'summary' && !NFCTagLoading && (isInternalUser || isSuperAdmin())) {
			onFetchNFCTags(id);
		}
	}, [userReady, isInternalUser, auth.user?.profile.role]);

	const tabs = [
		{ value: 'summary', label: 'ui.summary' },
		{ value: 'bookings', label: 'views.bookings.page.title' },
		...(isSuperAdmin() ? [{ value: 'finance', label: 'views.userDetails.tab.finance' }] : []),
	];

	if (!tab) {
		return <Navigate to={'/user-management/users'} />;
	}

	if (!tabs.find((t) => t.value === tab)) {
		return <Navigate to='/errors/error-404' />;
	}

	const summaryProps = {
		NFCTags: NFCTagData,
		customer: userData,
		license: licenseData,
		isInternalUser,
		currentUserData,
		userIsCurrentUser,
		paymentMethod: paymentMethodData,
		userRole: userRoleData,
		loading: isSuperAdmin() ? !userReady || !paymentMethodReady || !nfcReady : !userReady,
	};

	return (
		<Page className={classes.root} title={t('views.userDetails.page.title')}>
			<Header customer={userData} loading={!userReady} />
			<Tabs
				className={classes.tabs}
				indicatorColor='primary'
				onChange={handleTabsChange}
				scrollButtons='auto'
				value={tab}
				variant='scrollable'
			>
				{tabs.map((userTab) => (
					<Tab key={userTab.value} label={t(userTab.label)} value={userTab.value} />
				))}
			</Tabs>
			<Divider className={classes.divider} />
			<div className={classes.content}>
				{tab === 'summary' && <Summary {...summaryProps} />}
				{tab === 'bookings' && <BookingList userId={parseInt(id, 10)} />}
				{tab === 'finance' && <UserFinance userId={parseInt(id, 10)} />}
			</div>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		userObject: state.details.user,

		userRole: state.details.userRole,
		userPatch: state.details.userPatch,
		updateUser: state.details.updateUser,
		currentUser: state.details.currentUser,
		personalPaymentMethod: state.details.personalPaymentMethodUser,
		userLicense: state.details.userLicense,
		NFCTags: state.details.NFCTags,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUser: (id) => dispatch(actions.fetchUser(id)),
		onFetchPersonalPaymentMethodOfUser: (id) =>
			dispatch(actions.fetchPersonalPaymentMethodOfUser(id)),
		onFetchUserLicense: (id) => dispatch(actions.fetchUserLicense(id)),
		onFetchUsersUserRole: (id) => dispatch(actions.fetchUsersUserRole(id)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onFetchNFCTags: (id) => dispatch(actions.fetchNFCTags(id)),
		onResetState: (state) => dispatch(actions.resetState(state)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
