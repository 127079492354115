import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Bookings from './Bookings';
import Header from './Header';
import { useStyles } from './style';
import { Page } from '../../../../components';
import { isObject } from '../../../../shared/utility';

interface UserBookingsProps {}

const UserBookings = (props: UserBookingsProps) => {
	const location = useLocation();
	const { t } = useTranslation('general');

	const { date, title, id, name } = useParams();

	const userId = isObject(location?.state?.data) ? `${location.state.data.userReference.id}` : '';
	const classes = useStyles();

	return (
		<Page className={classes.root} title={t('ui.finance')}>
			<Header date={date} id={id} name={name} title={title} />
			<Bookings date={date} name={name} providerId={id} title={title} userId={userId} />
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		revenues: state.paged.revenues,
	};
};

export default connect(mapStateToProps, null)(UserBookings);
