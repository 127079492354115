import ActiveFinance from './ActiveFinance';
import History from './History';

interface ExpensesProps {
	isSuperAdmin?: boolean;
}

const Expenses = (props: ExpensesProps) => {
	const { isSuperAdmin } = props;

	return (
		<>
			<ActiveFinance isSuperAdmin={isSuperAdmin} />
			<History isSuperAdmin={isSuperAdmin} />
		</>
	);
};

export default Expenses;
