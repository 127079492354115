import { Card, CardHeader, CardContent } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { SubscriptionSelectField } from '../../../../../components';
import { useWizardFormField, useDebouncedWizardSave } from '../../../../../shared/hooks';

interface SubscriptionProps {
	className?: string;
	save?(...args: unknown[]): unknown;
}

const Subscription = (props: SubscriptionProps) => {
	const { className, save } = props;
	const { t } = useTranslation('general');
	const classes = useStyles();

	const subscription = useWizardFormField('', { required: true });
	useDebouncedWizardSave('subscription', subscription.value, subscription.isValid, save, 300);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader
				subheader={t('views.register.organisation.setSubscription.subtitle')}
				title={t('views.register.organisation.setSubscription.title')}
				titleTypographyProps={{
					variant: 'h3',
				}}
			/>
			<CardContent>
				<form>
					<div className={classes.formGroup}>
						<SubscriptionSelectField variable={subscription} />
					</div>
				</form>
			</CardContent>
		</Card>
	);
};

export default Subscription;
