import { Box, Typography, Switch } from '@mui/material';

interface OnHoldSwitchProps {
	checked?: boolean;
	actions?(...args: unknown[]): unknown;
	label?: string;
}

const OnHoldSwitch = (props: OnHoldSwitchProps) => {
	const { checked, actions, label } = props;
	return (
		<div>
			<Box alignItems='center' display='flex'>
				<Typography variant='h6'>{label}</Typography>
				<Switch checked={checked} disabled={false} onChange={actions} size='small' />
			</Box>
		</div>
	);
};

export default OnHoldSwitch;
