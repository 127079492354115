import { useState, useEffect } from 'react';

import { Avatar, Box, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import { AsyncTextField } from '~components';
import { BillingsService, PaymentStatusEnum, PaymentStatusSelect } from '~features/finances';

import BillingPeriod from './BillingPeriod';
import { useStyles } from './style';
import { Table, Label } from '../../../../../components';
import {
	isFullArray,
	isObject,
	getInitials,
	decimalAmount,
	isEmptyArray,
	getFinanceTableHeaders,
} from '../../../../../shared/utility';

const service = new BillingsService();

interface BillingsUsersProps {
	financeType?: string;
	date?: string;
	id?: string;
	setFullName?(...args: unknown[]): unknown;
}

const BillingsUsers = (props: BillingsUsersProps) => {
	const { date, setFullName } = props;
	const { t } = useTranslation('general');

	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');
	const [paymentStatusFilter, setPaymentStatusFilter] = useState<PaymentStatusEnum | null>(null);

	const { data, isLoading } = useSWR(
		[
			service.basePath,
			{
				date: date,
				searchQuery: searchValue || undefined,
				paymentStatus: paymentStatusFilter ?? undefined,
			},
		],
		([_, args]) => service.getBilling(args),
	);

	useEffect(() => {
		if (data != null) {
			setFullName(data.title);
		}
	}, [data]);

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [
		{ name: 'users', content: t('ui.category.users') },
		{ name: 'userId', content: `${t('ui.label.user')} id` },
		...headers,
	];

	const handlePathName = (start, name, user) =>
		`/financeManagement/personal/${start}/${data.title}/billing/${name}/${user}`;

	const emptyBody =
		isObject(data) && !isLoading && isEmptyArray(data.billings) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(data) || isLoading ?
			Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(data) && !isLoading ?
			data.billings.map((user) => [
				{
					content:
						isFullArray(user.userReference.imagesReference) ?
							<Box alignItems='center' display='flex'>
								<Avatar src={user.userReference.imagesReference[0]} />
								<Box pl={1.5}>
									<Link
										className={classes.link}
										color='inherit'
										component={RouterLink}
										to={handlePathName(user.start, user.userReference.name, user.userReference.id)}
										state={{ data: user }}
										variant='h6'
									>
										{user.userReference.name}
									</Link>
								</Box>
							</Box>
						:	<Box alignItems='center' display='flex'>
								<Avatar>{getInitials(user.userReference.name)}</Avatar>
								<Box pl={1}>
									<Link
										className={classes.link}
										color='inherit'
										component={RouterLink}
										to={handlePathName(user.start, user.userReference.name, user.userReference.id)}
										state={{ data: user }}
										variant='h6'
									>
										{user.userReference.name}
									</Link>
								</Box>
							</Box>,
				},
				{ content: user.userReference.id },
				{ content: decimalAmount(user.amount.subtotal, user.amount) },
				{ content: decimalAmount(user.amount.discount, user.amount) },
				{ content: decimalAmount(user.amount.subtotal - user.amount.discount, user.amount) },
				{ content: decimalAmount(user.amount.priceVat, user.amount) },
				{ content: decimalAmount(user.amount.refund, user.amount) },
				{ content: decimalAmount(user.amount.totalPrice, user.amount) },
				{ content: decimalAmount(user.open, user.amount) },
				{
					content: <Label type={statusColor[user.status]}>{statusText[user.status]}</Label>,
				},
			])
		:	Array(10)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	return (
		<>
			<Box pt={3}>
				<BillingPeriod data={data} loading={isLoading} />
				<Stack spacing={1} direction='row' sx={{ pb: 3 }}>
					<AsyncTextField
						manual
						placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
						defaultValue={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						size='small'
						sx={{ width: 250 }}
					/>
					<PaymentStatusSelect
						value={paymentStatusFilter}
						onChange={(e) => setPaymentStatusFilter(e.target.value)}
						displayEmpty
						size='small'
						sx={{ width: 200 }}
					/>
				</Stack>
			</Box>
			<Table
				body={loadingBody || emptyBody || dataBody}
				cellStyle={classes.cellStyle}
				data={data ?? []}
				header={tableHeader}
				isNotPaginate={true}
				loading={isLoading}
				noTitle={true}
				withoutPages={true}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		personalRevenue: state.details.personalRevenue,
		currentUser: state.details.currentUser,
	};
};

export default connect(mapStateToProps)(BillingsUsers);
