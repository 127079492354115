import { Box, Typography, Switch } from '@mui/material';

interface SwitchBtnProps {
	extraSwitch: unknown[];
}

const SwitchBtn = (props: SwitchBtnProps) => {
	const { extraSwitch } = props;

	return (
		<div>
			{extraSwitch.map((item, index) => (
				<Box alignItems='center' display='flex' key={index}>
					<Typography variant='h6'>{item.switchLabel}</Typography>
					<Switch checked={item.checked} disabled={false} onChange={item.actions} />
				</Box>
			))}
		</div>
	);
};

export default SwitchBtn;
