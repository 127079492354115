import { useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import UpdatePerMinute from './UpdatePerMinute';
import { InfoLabel, Tip } from '../../../../../components';
import { getDateObject, setWeekDay, modifyDate } from '../../../../../shared/datetime';
import { isObject } from '../../../../../shared/utility';
import CalendarDetails from '../CalendarDetails';
import Header from '../Header';

const PREFIX = 'PerMinute';

const classes = {
	root: `${PREFIX}-root`,
	calendarComponents: `${PREFIX}-calendarComponents`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
	[`& .${classes.root}`]: {},

	[`& .${classes.calendarComponents}`]: {
		'& .fc-widget-content': {
			display: 'none',
		},
	},
}));

interface PerMinuteProps {
	className?: string;
	perMinuteValue?: object;
	save?(...args: unknown[]): unknown;
	editing?: boolean;
	amount?: number;
	pricingPerMinuteModel?: unknown[];
	eventColors?: object;
	createPricingModel?(...args: unknown[]): unknown;
}

const PerMinute = (props: PerMinuteProps) => {
	const {
		className,

		perMinuteValue,
		save,
		editing,
		pricingPerMinuteModel,
		eventColors,
		createPricingModel,
	} = props;
	const { t } = useTranslation('general');

	const [pricingPerMinute, setPricingPerMinute] = useState(null);

	const eventsArray = pricingPerMinuteModel.reduce((acc, cur) => {
		const endOfDay = (cur.end.getHours() === 0 && cur.end.getMinutes() === 0) || !!cur.allDay;
		return [
			...acc,
			...cur.days.map((day) => ({
				id: cur.id,
				allDay: !!cur.allDay || cur.end.getTime() - cur.start.getTime() === 86400000,
				name: `${cur.price} / ${cur.periodType}`,
				start: setWeekDay(cur.start, day.id),
				end: setWeekDay(cur.end, day.id, endOfDay ? 1 : 0),
				color: cur.price === 0 ? eventColors.free : eventColors.fixed,
				title: cur.price === 0 ? t('ui.free') : `€${cur.price}/min`,
			})),
		];
	}, []);

	const handleCloseDrawer = () => {
		setPricingPerMinute(null);
	};

	const handleSelect = (selectionInfo) => {
		const end = getDateObject(selectionInfo.end);
		const start = getDateObject(selectionInfo.start);
		const startOfDay = start.getHours() === 0 && start.getMinutes() === 0;
		const endOfDay = end.getHours() === 0 && end.getMinutes() === 0;
		const allDay = startOfDay && endOfDay;

		const initialPrice = createPricingModel(
			Date.now(),
			start,
			modifyDate(endOfDay ? start : end, { ...(endOfDay && { hours: 23, minutes: 59 }) }),
			selectionInfo.allDay || allDay,
		);

		setPricingPerMinute(initialPrice);
	};

	const handleClickEvent = (info) => {
		const clickedEvent = pricingPerMinuteModel.find(
			(item) => item.id === parseInt(info.event.id, 10),
		);
		const endOfDay = clickedEvent.end.getHours() === 0 && clickedEvent.end.getMinutes() === 0;
		setPricingPerMinute({
			id: clickedEvent.id,
			start: clickedEvent.start,
			end: modifyDate(endOfDay ? clickedEvent.start : clickedEvent.end, {
				...(endOfDay && { hours: 23, minutes: 59 }),
			}),
			price: clickedEvent.price,
			allDay: info.event.allDay,
			dayOfWeek: info.event.start.getDay(),
		});
	};

	const fullCalendarEvents = {
		select: handleSelect,
		clickEvent: handleClickEvent,
	};

	return (
		<Root>
			<Header editing={editing} />
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<InfoLabel
						info={t('views.addPricing.perMinute.nameInfo')}
						name={t('views.addPricing.perMinute.name')}
						paddingBottom={4.5}
						paddingTop={1.5}
						variant='h4'
					/>
					<Tip message={t('views.addPricing.tip')} />
					<CalendarDetails
						allDayText={t('ui.allDay')}
						constraint={{ startTime: '00:00', endTime: '24:00', daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }}
						eventArray={eventsArray}
						events={fullCalendarEvents}
						periodLabel={perMinuteValue.label}
					/>
					{isObject(pricingPerMinute) ?
						<UpdatePerMinute
							createPricingModel={createPricingModel}
							onClose={handleCloseDrawer}
							open={isObject(pricingPerMinute)}
							periodLabel={perMinuteValue.label}
							periodType={perMinuteValue.name}
							pricingPerMinute={pricingPerMinute}
							pricingPerMinuteModel={pricingPerMinuteModel}
							save={save}
							setPricingPerMinute={setPricingPerMinute}
						/>
					:	null}
				</CardContent>
			</Card>
		</Root>
	);
};

export default PerMinute;
