import { useEffect, useState } from 'react';

import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Typography,
	FormControl,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { SelectWithLazyLoading, ActionDialog } from '../../../../../../components';
import { isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import { usePoliciesChange, useItemSharings } from '../itemHooks';
import PoliciesToggle from '../PoliciesToggle';
import { useStyles } from '../style';

interface AssignTermsProps {
	className?: string;
	isOwnItem?: boolean;
	itemData?: object;
	onResetState?(...args: unknown[]): unknown;
	onFetchTerms?(...args: unknown[]): unknown;
	onItemPatch?(...args: unknown[]): unknown;
	onUpdateDetailsState?(...args: unknown[]): unknown;
	terms?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	itemPatch?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const AssignTerms = (props: AssignTermsProps) => {
	const {
		className,
		isOwnItem,
		itemData,
		terms,
		onFetchTerms,
		onItemPatch,
		itemPatch,
		onUpdateDetailsState,

		onResetState,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();

	const termsReference = itemData?.termsReference;

	const itemTerms = usePoliciesChange({ id: termsReference.id, name: termsReference.name });

	const sharings = useItemSharings({ fetch: itemTerms.openDialog, type: 'terms', id: itemData.id });

	const [updatedTerms, setUpdatedTerms] = useState(false);
	const [updateConfig, setUpdateConfig] = useState(false);
	const [override, setOverride] = useState(false);

	const { data: patchData, loading: patchLoading, error: patchError } = itemPatch;
	const patchReady = isObject(patchData) && !patchLoading && !patchError;

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: itemData.hubReference.organisationReference.id,
	});

	useEffect(() => {
		if (isObject(patchData) && updatedTerms) {
			onUpdateDetailsState('itemDetails', patchData);
			enqueueSnackbar(
				`${t('ui.success.message.updated')} ${isObject(itemPatch.data) ? itemPatch.data.name : '-'}`,
				{ variant: 'success' },
			);
			onResetState('itemPatch');
			itemTerms.setOpenDialog(false);
			itemTerms.change();
			setUpdatedTerms(true);
			setUpdateConfig(false);
		}
	}, [itemPatch]);

	const handleConfirmDialog = () => {
		const patchItemBody = {
			termsId: itemTerms.initialValue.id,
			...(updateConfig && { updateConfigType: override ? 'all' : 'equal' }),
		};
		onItemPatch(itemData.id, patchItemBody);
		setUpdatedTerms(true);
	};

	const handleToggle = (evnet) => {
		const val = evnet.target.checked;
		setUpdateConfig(() => val);
		if (!val) {
			setOverride(() => false);
		}
	};

	const handleOverrideAll = (event) => {
		setOverride(() => event.target.checked);
	};

	const selectProps = {
		dataList: terms,
		listType: 'terms',
		filter: organisationIdFilter,
		onFetchData: onFetchTerms,
		placeholder: t('ui.placeholders.search.terms'),
		setSelected: itemTerms.change,
		value: itemTerms.initialValue.name,
	};

	const loading = patchLoading && !patchReady;

	const actionDialogProps = {
		noDivider: true,
		contentStyles: classes.actionDialog,
		loading: loading,
		handleClose: itemTerms.cancelDialog,
		open: itemTerms.openDialog,
		title: t('ui.category.termsAndConditions'),
		actionButtonProps: {
			action: handleConfirmDialog,
			text: loading ? <CircularProgress disableShrink size={24} /> : t('ui.confirm'),
		},
	};

	const policiesToggleProps = {
		onClick: handleToggle,
		onChange: handleOverrideAll,
		override,
		overrideAll: updateConfig,
		sharings,
		type: 'terms',
	};

	return (
		<Card className={clsx(classes.card, className)}>
			<CardHeader title={t('ui.category.termsAndConditions')} />
			<Divider />
			<CardContent>
				<Typography pb={2} variant='h6'>
					{t('views.itemDetail.summary.subtitle.assignedTerms')}
				</Typography>
				<FormControl fullWidth variant='standard'>
					{isOwnItem || isSuperAdmin() ?
						<SelectWithLazyLoading {...selectProps} />
					:	null}
				</FormControl>
			</CardContent>
			{itemTerms.openDialog ?
				<ActionDialog {...actionDialogProps}>
					<PoliciesToggle {...policiesToggleProps} />
				</ActionDialog>
			:	null}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		terms: state.paged.terms,
		itemPatch: state.details.itemPatch,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTerms: (page, filters, concat) => dispatch(actions.fetchTerms(page, filters, concat)),
		onItemPatch: (itemId, properties) => dispatch(actions.itemPatch(itemId, properties)),
		onUpdateDetailsState: (identifier, data) =>
			dispatch(actions.updateDetailsState(identifier, data)),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignTerms);
