import moment from 'moment';

import { FETCH_DETAILS_START, FETCH_DETAILS_SUCCESS, FETCH_DETAILS_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { updateRedirect } from './global';
import { localToUTCTime } from '../../shared/datetime';
import {
	isNull,
	isEmptyObject,
	isObject,
	isFullString,
	isInteger,
	isEmptyArray,
	stringifyQueryParams,
	isNumber,
	isFullArray,
	dataURItoFile,
	isString,
	isUndefined,
	isBoolean,
} from '../../shared/utility';
import { detailsStates } from '../states';

import { updateListState } from './index';

/* * * * * * * * * * * * * * *
 * ACTIVE ACTION TYPE METHODS *
 * * * * * * * * * * * * * *  */
const DetailsStatesCounter = new Counter(detailsStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchDetailsStart = (identifier) => {
	return {
		type: FETCH_DETAILS_START,
		identifier: identifier,
	};
};

export const fetchDetailsSuccess = (identifier, data = null) => {
	DetailsStatesCounter.reset(identifier);
	return {
		type: FETCH_DETAILS_SUCCESS,
		identifier: identifier,
		data: data,
	};
};

export const fetchDetailsFail = (
	identifier,
	error = 'Error message missing. Please contact site administrator.',
) => {
	DetailsStatesCounter.reset(identifier);
	return {
		type: FETCH_DETAILS_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const resetState = (identifier) => {
	return (dispatch) => {
		dispatch(fetchDetailsSuccess(identifier));
	};
};

export const updateDetailsState = (identifier, data = null) => {
	return (dispatch) => {
		dispatch(fetchDetailsSuccess(identifier, data));
	};
};

const flattenFilters = (filters) => {
	const filtersStr =
		isFullArray(Object.keys(filters)) ?
			Object.entries(filters)
				.reduce(
					(arr, map) =>
						// flatten filterproperties is passed in array
						isEmptyArray(map[1]) ? arr
						: isFullArray(map[1]) ? [...arr, ...map[1].map((value) => [map[0], value])]
						: [...arr, ...[map]],
					[],
				)
				.filter((map) => isFullArray(map))
				.map((map) => `${map[0]}=${map[1]}`)
				.join('&')
		:	'';

	const filterString = '?' + [filtersStr].filter((str) => str.length).join('&');

	return filterString;
};

/* * * * * * * * *
 * USER METHODS  *
 * * * * * * * * */
//#region User
export const addUser = (
	firstName = null,
	lastName = null,
	phoneNumber = null,
	email = null,
	password = null,
	userGroupId = null,
	address = {},
) => {
	const bodyData = {
		...(firstName &&
			lastName &&
			phoneNumber &&
			email &&
			password &&
			userGroupId && {
				firstName,
				lastName,
				phoneNumber,
				email,
				password,
				redirectUri: 'http://localhost:3001/confirm-reset', //hardcoded for now, will probably need to get it from the backend in the future.
				userGroupId,
				...(!isEmptyObject(address) && { address }),
			}),
	};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedUser'));
		try {
			const data = await events.post('identityusers', bodyData);
			dispatch(fetchDetailsSuccess('addedUser', data));
			//dispatch(updateRedirect('/user-management/users'));
		} catch (error) {
			dispatch(fetchDetailsFail('addedUser', error));
		}
	};
};

export const fetchUser = (id, preventRedirect = false) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('user'));
		try {
			const data = await events.get(`users/${id}`, preventRedirect);
			dispatch(fetchDetailsSuccess('user', data));
		} catch (error) {
			dispatch(fetchDetailsFail('user', error));
		}
	};
};

export const exportUsers = (organisationId) => {
	if (!organisationId) {
		return;
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportUsers'));
		try {
			const data = await events.get(
				`users/exports?organisationId=${organisationId}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportUsers', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportUsers', error));
		}
	};
};

export const fetchCurrentUser = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('currentUser'));
		if (localStorage.getItem('currentUser')) {
			const userData = localStorage.getItem('currentUser');
			dispatch(fetchDetailsSuccess('currentUser', JSON.parse(userData)));
		} else {
			try {
				const userData = await events.get('users/me');
				dispatch(fetchDetailsSuccess('currentUser', userData));
				localStorage.setItem('currentUser', JSON.stringify(userData));
			} catch (error) {
				dispatch(fetchDetailsFail('currentUser', error));
			}
		}
	};
};

export const fetchCurrentUserOrganisationStatus = () => {
	return async (dispatch) => {
		// dispatch(fetchDetailsStart('currentUserOrganisationStatus'));
		// if (localStorage.getItem('organisationStatus')) {
		//   const organisationStatus = localStorage.getItem('organisationStatus');
		//   dispatch(fetchDetailsSuccess('currentUserOrganisationStatus', JSON.parse(organisationStatus)));
		// } else {
		//   try {
		//     const data = await events.get('organisations/me/status');
		//     dispatch(fetchDetailsSuccess('currentUserOrganisationStatus', data));
		//     localStorage.setItem('organisationStatus', JSON.stringify(data));
		//   } catch (error) {
		//     dispatch(fetchDetailsFail('currentUserOrganisationStatus', error));
		//   }
		// }
		dispatch(fetchDetailsStart('currentUserOrganisationStatus'));
		try {
			const data = await events.get('organisations/me/status');
			dispatch(fetchDetailsSuccess('currentUserOrganisationStatus', data));
		} catch (error) {
			dispatch(fetchDetailsFail('currentUserOrganisationStatus', error));
		}
	};
};

export const fetchUsersUserRole = (id) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('userRole'));
		try {
			const data = await events.get(`identityusers/${id}/role`);
			dispatch(fetchDetailsSuccess('userRole', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userRole', error));
		}
	};
};

export const updateUser = (
	userId = null,
	firstName = null,
	lastName = null,
	phoneNumber = null,
	address = null,
	preferences = null,
) => {
	const bodyData = {
		firstName: firstName,
		lastName: lastName,
		phoneNumber: phoneNumber,
		...(isObject(address) && {
			address: {
				street: address.street,
				number: address.number,
				postalCode: address.postalCode,
				city: address.city,
				countryCode: address.countryCode,
			},
		}),
		preferences: preferences,
	};
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateUser'));
		try {
			const data = await events.put(`users/${userId}`, bodyData);
			dispatch(fetchDetailsSuccess('updateUser', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateUser', error));
		}
	};
};

export const userPatch = (userId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).reduce(
				(acc, prop) =>
					isObject(prop[1]) ?
						[
							...acc,
							...Object.entries(prop[1]).map((innerProp) => ({
								value: innerProp[1],
								path: `${prop[0]}/${innerProp[0]}`,
								op: 'replace',
							})),
						]
					:	[
							...acc,
							...[
								{
									value: prop[1],
									path: prop[0],
									op: 'replace',
								},
							],
						],
				[],
			)
		:	[{}];

	return async (dispatch) => {
		dispatch(fetchDetailsStart('userPatch'));
		try {
			let data = await events.patch(`users/${userId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('userPatch', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userPatch', error));
		}
	};
};

export const inviteUsers = (bodyData) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('invitedUsers'));
		try {
			const data = await events.post('invitations', bodyData);
			dispatch(fetchDetailsSuccess('invitedUsers', data));
		} catch (error) {
			dispatch(fetchDetailsFail('invitedUsers', error));
		}
	};
};

export const emailValidations = (bodyData) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('emailValidations'));
		try {
			const data = await events.post('invitations/validate', bodyData);
			dispatch(fetchDetailsSuccess('emailValidations', data));
		} catch (error) {
			dispatch(fetchDetailsFail('emailValidations', error));
		}
	};
};

export const fetchUserLicense = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('userLicense'));
		try {
			const data = await events.get(`userlicenses/${userId}`, true);
			dispatch(fetchDetailsSuccess('userLicense', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userLicense', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
//#region Organisation
export const addOrganisation = (
	name = null,
	description = null,
	phoneNumber = null,
	email = null,
	address = null,
	billing = null,
	contact = null,
	invoice = null,
	images = null,
	legalName = null,
) => {
	const bodyData =
		(
			isFullArray(images) &&
			isFullString(name) &&
			isFullString(email) &&
			isFullString(phoneNumber) &&
			isObject(address) &&
			isObject(contact) &&
			isObject(invoice)
		) ?
			new FormData()
		:	null;

	if (!isNull(bodyData)) {
		bodyData.append('isPublic', true);
		bodyData.append('logo', images[0].file);
		bodyData.append('name', name);
		if (isFullString(legalName)) {
			bodyData.append('legalName', legalName);
		} else if (!isFullString(legalName)) {
			bodyData.append('legalName', name);
		}

		if (isFullString(description)) {
			bodyData.append('description', description);
		}
		bodyData.append('phoneNumber', phoneNumber);
		bodyData.append('email', email);
		if (isObject(address)) {
			bodyData.append('address.street', address.street);
			bodyData.append('address.number', address.number);
			bodyData.append('address.numberAddition', address.numberAddition);
			bodyData.append('address.postalCode', address.postalCode);
			bodyData.append('address.city', address.city);
			bodyData.append('address.countryCode', address.country);
		}
		if (isObject(billing)) {
			if (isFullString(billing.vatNumber)) {
				bodyData.append('administration.vatNumber', billing.vatNumber);
			}
			if (isFullString(billing.enterpriseNumber)) {
				bodyData.append('administration.enterpriseNumber', billing.enterpriseNumber);
			}
			if (isFullString(billing.chamberOfCommerceNumber)) {
				bodyData.append('administration.chamberOfCommerceNumber', billing.chamberOfCommerceNumber);
			}
			if (isFullString(billing.ibanNumber)) {
				bodyData.append('administration.ibanNumber', billing.ibanNumber);
			}
		}
		if (isObject(contact)) {
			bodyData.append('contactPerson.firstName', contact.firstName);
			bodyData.append('contactPerson.lastName', contact.lastName);
			bodyData.append('contactPerson.phoneNumber', contact.phoneNumber);
			bodyData.append('contactPerson.email', contact.email);
		}
		if (isObject(invoice)) {
			bodyData.append('invoiceContact.name', invoice.name);
			bodyData.append('invoiceContact.email', invoice.email);
			bodyData.append('subscriptionId', invoice.subscriptionId);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedOrganisation'));
		try {
			const data = await events.post('organisations', bodyData, false, true);
			dispatch(fetchDetailsSuccess('addedOrganisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedOrganisation', error));
		}
	};
};

export const fetchOrganisation = (id) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('organisation'));
		try {
			const data = await events.get(`Organisations/${id}`);
			dispatch(fetchDetailsSuccess('organisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('organisation', error));
		}
	};
};

export const verifyOrganisation = (organisationId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('verifyOrganisation'));
		try {
			const data = await events.put(`organisations/${organisationId}/verify`);
			dispatch(fetchDetailsSuccess('verifyOrganisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('verifyOrganisation', error));
		}
	};
};

export const updateOrganisation = (organisationId = null, bodyData = {}) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateOrganisation'));
		try {
			bodyData.isPublic = true;
			const data = await events.put(`organisations/${organisationId}`, bodyData);
			dispatch(fetchDetailsSuccess('updateOrganisation', data));
			dispatch(fetchDetailsSuccess('organisation', data));
			// dispatch(fetchDetailsSuccess('currentUser', true));
		} catch (error) {
			dispatch(fetchDetailsFail('updateOrganisation', error));
		}
	};
};

export const patchUpdateOrganisation = (organisationId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' }))
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('patchedOrganisation'));
		try {
			let data = await events.patch(`organisations/${organisationId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('patchedOrganisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('patchedOrganisation', error));
		}
	};
};

export const rejectOrganisation = (organisationId = null, reason = null) => {
	const bodyData =
		isFullString(reason) ?
			{
				value: reason,
			}
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('rejectOrganisation'));
		try {
			const data = await events.put(`organisations/${organisationId}/reject`, bodyData);
			dispatch(fetchDetailsSuccess('organisation', data));
			dispatch(fetchDetailsSuccess('rejectOrganisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('rejectOrganisation', error));
		}
	};
};

export const addOrganisationImage = (organisationId, logo = null) => {
	const bodyData = !isNull(logo) ? new FormData() : null;

	if (!isNull(bodyData)) {
		bodyData.append('files', logo);
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addOrganisationImage'));
		try {
			const data = await events.post(
				`organisations/${organisationId}/Images`,
				bodyData,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('addOrganisationImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addOrganisationImage', error));
		}
	};
};

export const updateOrganisationImage = (id, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateOrganisationImage'));
		try {
			const data = await events.put(`organisations/${id}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('updateOrganisationImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateOrganisationImage', error));
		}
	};
};

//#endregion

/* * * * * * * * * *
 * TICKET METHODS  *
 * * * * * * * * * */
//#region Ticket
export const addTicketOld = (body = null) => {
	const bodyData =
		!isNull(body) ?
			{
				itemCode: body.itemCode,
				defectType: body.defectType,
				comment: body.comment,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedTicketOld'));
		try {
			const data = await events.post('tickets', bodyData);
			dispatch(fetchDetailsSuccess('addedTicketOld', data));
			dispatch(updateRedirect('/tickets/open'));
		} catch (error) {
			dispatch(fetchDetailsFail('addedTicketOld', error));
		}
	};
};

export const addTicket = (
	itemInstanceId = null,
	description = null,
	ticketDefectId = null,
	serviceOrganisationId = null,
) => {
	const bodyData = isInteger(itemInstanceId) && isInteger(ticketDefectId) ? new FormData() : null;

	if (!isNull(bodyData)) {
		bodyData.append('itemInstanceId', itemInstanceId);
		bodyData.append('ticketDefectId', ticketDefectId);
		if (isInteger(serviceOrganisationId)) {
			bodyData.append('serviceOrganisationId', serviceOrganisationId);
		}
		if (isFullString(description)) {
			bodyData.append('description', description);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedTicket'));
		try {
			const data = await events.post('tickets', bodyData, true, true);
			dispatch(fetchDetailsSuccess('addedTicket', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedTicket', error));
		}
	};
};

export const getTicket = (id) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('getTicket'));
		try {
			const data = await events.get(`tickets/${id}`);
			dispatch(fetchDetailsSuccess('getTicket', data));
		} catch (error) {
			dispatch(fetchDetailsFail('getTicket', error));
		}
	};
};

export const updateTicket = (id = null, status = null, service = null, description = null) => {
	const bodyData =
		isFullString(status) ?
			{
				status: status,
				serviceOrganisationId: service,
				description: description,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateTicket'));
		try {
			const data = await events.put(`tickets/${id}`, bodyData);
			dispatch(fetchDetailsSuccess('updateTicket', data));
			dispatch(fetchDetailsSuccess('getTicket', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateTicket', error));
		}
	};
};

export const updateTicketActivities = (id = null, status = null, comment = null) => {
	const bodyData =
		isFullString(status) ?
			{
				status: status,
				comment: comment,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateTicketActivities'));
		try {
			const data = await events.post(`tickets/${id}/activities`, bodyData);
			dispatch(fetchDetailsSuccess('updateTicketActivities', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateTicketActivities', error));
		}
	};
};
//#endregion

/* * * * * * * *
 * HUBS METHODS *
 * * * * * * * */
//#region Hub
export const fetchHub = (hubId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('hubDetails'));
		try {
			const data = await events.get(`hubs/${hubId}`);
			dispatch(fetchDetailsSuccess('hubDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('hubDetails', error));
		}
	};
};

export const addHub = (
	organisationId = null,
	latitude = null,
	longitude = null,
	name = null,
	address = null,
	newOpenHours = null,
	description = null,
	hubImages = [],
) => {
	const bodyData =
		(
			isNumber(organisationId) &&
			isNumber(latitude) &&
			isNumber(longitude) &&
			isObject(address) &&
			isFullString(name) &&
			isFullArray(newOpenHours)
		) ?
			{
				organisationId: organisationId,
				latitude: latitude,
				longitude: longitude,
				address: {
					street: address.street,
					number: address.number,
					postalCode: address.postalCode,
					city: address.city,
					countryCode: address.country,
					...(isFullString(address.number) && { number: address.number }),
					...(isFullString(address.numberAddition) && { numberAddition: address.numberAddition }),
				},
				name: name,
				newOpenHours: newOpenHours,
				description: description,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addHubDetails'));
		try {
			const data = await events.post('hubs', bodyData);
			if (hubImages) {
				const images = hubImages.map((image) => image.file);
				dispatch(addHubImage(data.id, images));
			}

			dispatch(fetchDetailsSuccess('addHubDetails', data));
			// dispatch(updateRedirect(''));
		} catch (error) {
			dispatch(fetchDetailsFail('addHubDetails', error));
		}
	};
};

export const addHubImage = (hubId, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addHubImage'));
		try {
			const data = await events.post(`hubs/${hubId}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('addHubImage', data));
			dispatch(updateRedirect('/location-management/locations'));
		} catch (error) {
			dispatch(fetchDetailsFail('addHubImage', error));
		}
	};
};

export const updateLocationImage = (locationId, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateLocationImage'));
		try {
			const data = await events.put(`hubs/${locationId}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('updateLocationImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateLocationImage', error));
		}
	};
};

export const updateLocation = (locationId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' }))
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateLocation'));
		try {
			let data = await events.patch(`hubs/${locationId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('hubDetails', data));
			dispatch(fetchDetailsSuccess('updateLocation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateLocation', error));
		}
	};
};
//#endregion

/* * * * * * * * * * *
 * LOCATIONS METHODS *
 * * * * * * * * * * */
//#region Autocomplete google
export const fetchPlacesAutocomplete = (
	searchTerm = '',
	placeType = 'addresses',
	sessionToken = null,
	globalSearch = false,
) => {
	const queryParams = stringifyQueryParams({
		searchTerm,
		placeType,
		...(isFullString(sessionToken) && { sessionToken }),
		countryCodes: 'nl|be|de',
		globalSearch,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('placesAutocomplete'));
		try {
			const data = await events.get(`places${queryParams}`);
			dispatch(fetchDetailsSuccess('placesAutocomplete', data));
		} catch (error) {
			dispatch(fetchDetailsFail('placesAutocomplete', error));
		}
	};
};

export const fetchPlace = (placeId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('place'));
		try {
			const data = await events.get(`places/${placeId}`);
			dispatch(fetchDetailsSuccess('place', data));
		} catch (error) {
			dispatch(fetchDetailsFail('place', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * *
 * USER GROUP METHODS  *
 * * * * * * * * * * * */
//#region  USER GROUP
export const addUserGroup = (name = null, organisationId = null, description = null) => {
	const bodyData =
		isFullString(name) && isInteger(organisationId) ?
			{
				name: name,
				organisationId: organisationId,
				allowOrganisationalBilling: true, // TODO: Implement option for user to select if organisational billing should be allowed or not
				...(!isNull(description) && { description }),
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addUserGroup'));
		try {
			const data = await events.post('usergroups', bodyData);
			dispatch(fetchDetailsSuccess('addUserGroup', data));
			//dispatch(updateRedirect('/users-management/user-groups'));
		} catch (error) {
			dispatch(fetchDetailsFail('addUserGroup', error));
		}
	};
};

export const updateUserGroup = (
	userGroupId = null,
	name = null,
	description = null,
	allowOrganisationalBilling = null,
	allowPersonalBilling = null,
) => {
	const bodyData =
		isFullString(name) && !isNull(allowOrganisationalBilling) ?
			{
				name,
				...(isString(description) && { description }),
				allowOrganisationalBilling,
				allowPersonalBilling,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedUserGroup'));
		try {
			const data = await events.put(`usergroups/${userGroupId}`, bodyData);
			dispatch(fetchDetailsSuccess('updatedUserGroup', data));
			dispatch(fetchDetailsSuccess('userGroup', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedUserGroup', error));
		}
	};
};

export const fetchUserGroup = (id) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('userGroup'));
		try {
			const data = await events.get(`usergroups/${id}`);
			dispatch(fetchDetailsSuccess('userGroup', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userGroup', error));
		}
	};
};

export const assignUsersToUserGroup = (userGroupId = null, bodyData = [], forceMove = false) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('assignedUsersToUserGroup'));
		try {
			const data = await events.put(
				`usergroups/${userGroupId}/includeusers?forceMove=${forceMove}`,
				bodyData,
			);
			dispatch(fetchDetailsSuccess('assignedUsersToUserGroup', data));
		} catch (error) {
			dispatch(fetchDetailsFail('assignedUsersToUserGroup', error));
		}
	};
};

export const validateUserGroupName = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueName',
		name: value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validatedUserGroupName'));
		try {
			const data = await events.get(`usergroups/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedUserGroupName', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedUserGroupName', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * * * *
 * USER GROUP ITEM METHODS *
 * * * * * * * * * * * * * */
//#region User Group Item
export const assignItemsToUserGroup = (userGroupId, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('assignedItemsToUserGroup'));
		try {
			const data = await events.post(`usergroups/${userGroupId}/items`, bodyData);
			dispatch(fetchDetailsSuccess('assignedItemsToUserGroup', data));
		} catch (error) {
			dispatch(fetchDetailsFail('assignedItemsToUserGroup', error));
		}
	};
};

export const unassignUserGroupItem = (userGroupId = null, items = null) => {
	const bodyData =
		userGroupId ?
			{
				items: items,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('unassignOrCancelItem'));
		try {
			const data = await events.delete(`usergroups/${userGroupId}/items`, bodyData);
			dispatch(fetchDetailsSuccess('unassignOrCancelItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('unassignOrCancelItem', error));
		}
	};
};

//#endregion

/* * * * * * * * * * * *
 * IMAGE/FILE METHODS  *
 * * * * * * * * * * * */
//#region IMAGE/FILE
export const addUserImage = (userId, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedUserImage'));
		try {
			const data = await events.post(`users/${userId}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('addedUserImage', data));
			dispatch(updateRedirect('/user-management/users'));
		} catch (error) {
			dispatch(fetchDetailsFail('addedUserImage', error));
		}
	};
};

const removeIconUpdateState = (list, resourceId) => {
	return list.map((item) => {
		if (item.id === resourceId) {
			const { icon, ...itemData } = item; //eslint-disable-line
			return itemData;
		} else {
			return item;
		}
	});
};

const addIconUpdateState = (list, resourceId, icon) =>
	list.map((item) => (item.id === resourceId ? { ...item, icon: icon } : item));

export const addCategoryIcon = (categoryId, imageArray, categoriesData) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedCategoryIcon'));
		try {
			const data = await events.post(`categories/${categoryId}/Images`, bodyData, false, true);

			const updatedStateData = addIconUpdateState(categoriesData, categoryId, data[0]);
			dispatch(updateListState('categories', updatedStateData));
			dispatch(fetchDetailsSuccess('addedCategoryIcon', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedCategoryIcon', error));
		}
	};
};

export const removeCategoryIcon = (categoryId, imageId, categoriesData) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('removedCategoryIcon'));
		try {
			const data = await events.delete(`categories/${categoryId}/images/${imageId}`);

			const updatedStateData = removeIconUpdateState(categoriesData, categoryId);
			dispatch(updateListState('categories', updatedStateData));
			dispatch(fetchDetailsSuccess('removedCategoryIcon', data));
		} catch (error) {
			dispatch(fetchDetailsFail('removedCategoryIcon', error));
		}
	};
};

export const addCharacteristicsIcon = (characteristicId, imageArray, characteristicsData) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addCharacteristicsIcon'));
		try {
			const data = await events.post(
				`characteristics/${characteristicId}/Images`,
				bodyData,
				false,
				true,
			);

			const updatedStateData = {
				cars: addIconUpdateState(characteristicsData.cars, characteristicId, data[0]),
				bikes: addIconUpdateState(characteristicsData.bikes, characteristicId, data[0]),
				offices: addIconUpdateState(characteristicsData.offices, characteristicId, data[0]),
			};
			dispatch(fetchDetailsSuccess('characteristics', updatedStateData));
			dispatch(fetchDetailsSuccess('addCharacteristicsIcon', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addCharacteristicsIcon', error));
		}
	};
};

export const removeCharacteristicsIcon = (characteristicId, imageId, characteristicsData) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('removeCharacteristicsIcon'));
		try {
			const data = await events.delete(`characteristics/${characteristicId}/Images/${imageId}`);
			const updatedStateData = {
				cars: removeIconUpdateState(characteristicsData.cars, characteristicId),
				bikes: removeIconUpdateState(characteristicsData.bikes, characteristicId),
				offices: removeIconUpdateState(characteristicsData.offices, characteristicId),
			};
			dispatch(fetchDetailsSuccess('characteristics', updatedStateData));
			dispatch(fetchDetailsSuccess('removeCharacteristicsIcon', data));
		} catch (error) {
			dispatch(fetchDetailsFail('removeCharacteristicsIcon', error));
		}
	};
};

const removeIconUpdateStateNested = (list, resourceId, enumValueId) => {
	return list.map((item) =>
		item.id === resourceId ?
			{
				...item,
				enumValues: item.enumValues.map((nItem) => {
					if (nItem.id === enumValueId) {
						const { icon, ...itemData } = nItem; //eslint-disable-line
						return itemData;
					} else {
						return nItem;
					}
				}),
			}
		:	item,
	);
};

const addIconUpdateStateNested = (list, resourceId, enumValueId, icon) =>
	list.map((item) =>
		item.id === resourceId ?
			{
				...item,
				enumValues: item.enumValues.map((nItem) =>
					nItem.id === enumValueId ? { ...nItem, icon: icon } : nItem,
				),
			}
		:	item,
	);

export const addCharacteristicsIconNested = (
	characteristicId,
	enumValueId,
	imageArray,
	characteristicsData,
) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addCharacteristicsIconNested'));
		try {
			const data = await events.post(
				`characteristics/${characteristicId}/enumvalue/${enumValueId}/Images`,
				bodyData,
				false,
				true,
			);

			const updatedStateData = {
				cars: addIconUpdateStateNested(
					characteristicsData.cars,
					characteristicId,
					enumValueId,
					data[0],
				),
				bikes: addIconUpdateStateNested(
					characteristicsData.bikes,
					characteristicId,
					enumValueId,
					data[0],
				),
				offices: addIconUpdateStateNested(
					characteristicsData.offices,
					characteristicId,
					enumValueId,
					data[0],
				),
			};
			dispatch(fetchDetailsSuccess('characteristics', updatedStateData));
			dispatch(fetchDetailsSuccess('addCharacteristicsIconNested', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addCharacteristicsIconNested', error));
		}
	};
};

export const removeCharacteristicsIconNested = (
	characteristicId,
	enumValueId,
	imageId,
	characteristicsData,
) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('removeCharacteristicsIconNested'));
		try {
			const data = await events.delete(
				`characteristics/${characteristicId}/enumvalue/${enumValueId}/Images/${imageId}`,
			);

			const updatedStateData = {
				cars: removeIconUpdateStateNested(characteristicsData.cars, characteristicId, enumValueId),
				bikes: removeIconUpdateStateNested(
					characteristicsData.bikes,
					characteristicId,
					enumValueId,
				),
				offices: removeIconUpdateStateNested(
					characteristicsData.offices,
					characteristicId,
					enumValueId,
				),
			};
			dispatch(fetchDetailsSuccess('characteristics', updatedStateData));
			dispatch(fetchDetailsSuccess('removeCharacteristicsIconNested', data));
		} catch (error) {
			dispatch(fetchDetailsFail('removeCharacteristicsIconNested', error));
		}
	};
};

export const updateItemImage = (itemId, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateItemImage'));
		try {
			const data = await events.put(`items/${itemId}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('updateItemImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateItemImage', error));
		}
	};
};
//#endregion

/* * * * * * * *
 * ITEM METHODS *
 * * * * * * * */
//#region ITEM
export const validateItemName = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueName',
		name: value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validateItemName'));
		try {
			const data = await events.get(`items/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validateItemName', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validateItemName', error));
		}
	};
};

export const addItem = (bodyData = null, itemImages = []) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addItem'));
		try {
			const data = await events.post('items', bodyData);
			if (isFullArray(itemImages)) {
				const images = itemImages.map((image) => image.file);
				dispatch(addItemImage(data.id, images));
			}
			dispatch(fetchDetailsSuccess('addItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addItem', error));
		}
	};
};

export const addItemImage = (itemId, imageArray) => {
	const bodyData = !isEmptyArray(imageArray) ? new FormData() : null;

	if (!isNull(bodyData)) {
		for (let i = 0; i < imageArray.length; i++) {
			const file = imageArray[i];
			bodyData.append('files', file);
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addItemImages'));
		try {
			const data = await events.post(`items/${itemId}/Images`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('addItemImages', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addItemImages', error));
		}
	};
};

export const addItemInstance = (
	itemId = null,
	hubId = null,
	bike = null,
	office = null,
	car = null,
	trailer = null,
	parkingLot = null,
	asset = null,
) => {
	const bodyData =
		(
			isObject(car) ||
			isObject(bike) ||
			isObject(office) ||
			isObject(trailer) ||
			isObject(parkingLot) ||
			isObject(asset)
		) ?
			{
				isPublic: false,
				hubId: hubId,

				...(isObject(bike) && {
					bike: {
						frameNumber: bike.frameNumber,
						// bikeLockId: bike.bikeLockId,
						bikeNumber: bike.bikeNumber,
					},
				}),
				...(isObject(office) && {
					office: {
						roomNumber: office.roomNumber,
					},
				}),
				...(isObject(car) && {
					car: {
						vinNumber: car.vinNumber,
						brand: car.brand,
						model: car.model,
						// carLockId: car.carLockId,
						licensePlate: car.licensePlate,
						currentHectometers: car.currentHectometers,
					},
				}),
				...(isObject(trailer) && {
					trailer: {
						trailerNumber: trailer.trailerNumber,
						vinNumber: trailer.trailerVinNumber,
					},
				}),
				...(isObject(parkingLot) && {
					parkingLot: {
						parkingNumber: parkingLot.parkingNumber,
					},
				}),
				...(isObject(asset) && {
					asset: {
						assetNumber: asset.assetNumber,
					},
				}),
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addInstance'));
		try {
			const data = await events.post(`items/${itemId}/iteminstances`, bodyData);
			dispatch(fetchDetailsSuccess('addInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addInstance', error));
		}
	};
};

export const addInstances = (itemId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addInstances'));
		try {
			const data = await events.post(`items/${itemId}/iteminstances/batch`, bodyData);
			dispatch(fetchDetailsSuccess('addInstances', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addInstances', error));
		}
	};
};

export const fetchItem = (itemId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemDetails'));
		try {
			const data = await events.get(`items/${itemId}`);
			dispatch(fetchDetailsSuccess('itemDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemDetails', error));
		}
	};
};

export const fetchItemImage = (itemId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchItemImage'));
		try {
			const data = await events.get(`items/${itemId}/images`);
			dispatch(fetchDetailsSuccess('fetchItemImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchItemImage', error));
		}
	};
};

export const itemPatch = (itemId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' }))
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemPatch'));
		try {
			let data = await events.patch(`items/${itemId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('itemPatch', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemPatch', error));
		}
	};
};

export const fetchItemPrice = (
	itemId = null,
	periodStart = null,
	periodEnd = null,
	itemInstanceId = null,
) => {
	const queryParams = stringifyQueryParams({
		...(!isNull(itemInstanceId) && { itemInstanceId }),
		...(moment.isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString(),
	});
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemPrice'));
		try {
			const data = await events.get(`items/${itemId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('itemPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemPrice', error));
		}
	};
};

export const fetchCalculatedPrice = (itemId = null, params = null) => {
	const queryParams = stringifyQueryParams(params);

	return async (dispatch) => {
		dispatch(fetchDetailsStart('calculatedPrice'));
		try {
			const data = await events.get(`items/${itemId}/calculatedprice${queryParams}`);
			dispatch(fetchDetailsSuccess('calculatedPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('calculatedPrice', error));
		}
	};
};

export const refundPrice = (bookingId = null, refund = null) => {
	const queryParams = stringifyQueryParams({ refund: refund, isExtending: false });

	return async (dispatch) => {
		dispatch(fetchDetailsStart('refundPrice'));
		try {
			const data = await events.get(`bookings/${bookingId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('refundPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('refundPrice', error));
		}
	};
};

export const fetchItemInstanceAvailability = (
	itemId = null,
	itemInstanceId = null,
	periodStart = null,
	periodEnd = null,
	userId = null,
) => {
	const queryParams = stringifyQueryParams({
		userId: userId,
		...(!isNull(itemInstanceId) && { itemInstanceId }),
		...(moment.isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString(),
	});
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemInstanceAvailability'));
		try {
			const data = await events.get(`items/${itemId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('itemInstanceAvailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemInstanceAvailability', error));
		}
	};
};

export const maintenanceAvailability = (
	itemId = null,
	itemInstanceId = null,
	periodStart = null,
	periodEnd = null,
) => {
	const queryParams = stringifyQueryParams({
		...(moment.isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		periodEnd: periodEnd.toISOString(),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('maintenanceAvailability'));
		try {
			const data = await events.get(
				`items/${itemId}/iteminstances/${itemInstanceId}/availability${queryParams}`,
			);
			dispatch(fetchDetailsSuccess('maintenanceAvailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('maintenanceAvailability', error));
		}
	};
};

export const fetchItemPolicies = (itemId = null, userId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchItemPolicies'));
		try {
			const data = await events.get(`items/${itemId}/policies?userId=${userId}`);
			dispatch(fetchDetailsSuccess('fetchItemPolicies', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchItemPolicies', error));
		}
	};
};

export const planboardBooking = (bookingId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('planboardBooking'));
		try {
			const data = await events.get(`planboard/bookings/${bookingId}`);
			dispatch(fetchDetailsSuccess('planboardBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('planboardBooking', error));
		}
	};
};

export const fetchPlanboardItemDetails = (itemId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('planboardItemDetails'));
		try {
			const data = await events.get(`planboard/items/${itemId}/iteminstances/${instanceId}`);
			dispatch(fetchDetailsSuccess('planboardItemDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('planboardItemDetails', error));
		}
	};
};

export const updatePublicShare = (isPublic = null, items = null) => {
	const bodyData =
		isFullArray(items) ?
			{
				items: items,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatePublicShare'));
		try {
			const data =
				isPublic ?
					await events.post('items/publics', bodyData)
				:	await events.delete('items/publics', bodyData);
			dispatch(fetchDetailsSuccess('updatePublicShare', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatePublicShare', error));
		}
	};
};

export const updateInstancesLocation = (itemId = null, innstances = null) => {
	const bodyData =
		isFullArray(innstances) ?
			{
				items: innstances,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateInstancesLocation'));
		try {
			const data = await events.put(`hubs/${itemId}/iteminstances`, bodyData);
			dispatch(fetchDetailsSuccess('updateInstancesLocation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateInstancesLocation', error));
		}
	};
};

export const patchInstance = (itemId = null, instanceId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' }))
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('patchInstance'));
		try {
			let data = await events.patch(`items/${itemId}/iteminstances/${instanceId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('patchInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('patchInstance', error));
		}
	};
};

//#endregion

/* * * * * * * * * * *
 * INSTANCE METHODS  *
 * * * * * * * * * * */
//#region INSTANCE METHOD
export const fetchInstance = (itemId = null, itemInstanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchInstance'));
		try {
			const data = await events.get(`items/${itemId}/iteminstances/${itemInstanceId}`);
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchInstance', error));
		}
	};
};

export const updateInstanceLocation = (itemId = null, instanceId = null, properties = null) => {
	const bodyData =
		isObject(properties) ?
			Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' }))
		:	{};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateInstanceLocation'));
		try {
			let data = await events.patch(`items/${itemId}/iteminstances/${instanceId}`, bodyData);
			if (data instanceof Blob) {
				data = JSON.parse(await data.text());
			}
			dispatch(fetchDetailsSuccess('updateInstanceLocation', data));
			dispatch(fetchDetailsSuccess('fetchInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateInstanceLocation', error));
		}
	};
};

export const updateInstanceAvailability = (
	itemId = null,
	instanceId = null,
	isAvailable = null,
) => {
	const bodyData =
		!isNull(isAvailable) ?
			{
				available: isAvailable,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedInstanceAvailability'));
		try {
			const data = await events.put(
				`items/${itemId}/iteminstances/${instanceId}/availability`,
				bodyData,
			);
			dispatch(fetchDetailsSuccess('updatedInstanceAvailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedInstanceAvailability', error));
		}
	};
};

export const updateInstance = (itemId = null, instanceId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateInstance'));
		try {
			const data = await events.put(`items/${itemId}/iteminstances/${instanceId}`, bodyData);
			dispatch(fetchDetailsSuccess('updateInstance', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateInstance', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * * * * * * *
 * TERMS AND CONDITIONS METHODS  *
 * * * * * * * * * * * * * * * * */
//#region TERMS AND CONDITIONS
export const addTermsAndConditions = (
	organisationId = null,
	title = null,
	dutch = null,
	german = null,
	english = null,
) => {
	const bodyData =
		(
			(!isEmptyArray(dutch) || !isEmptyArray(german) || !isEmptyArray(english)) &&
			isFullString(title)
		) ?
			new FormData()
		:	null;

	if (!isNull(bodyData)) {
		bodyData.append('title', title);
		bodyData.append('organisationId', organisationId);
		if (dutch) {
			for (let i = 0; i < dutch.length; i++) {
				const file = dutch[i];
				bodyData.append('dutch', file.file);
			}
		}
		if (german) {
			for (let i = 0; i < german.length; i++) {
				const file = german[i];
				bodyData.append('german', file.file);
			}
		}
		if (english) {
			for (let i = 0; i < english.length; i++) {
				const file = english[i];
				bodyData.append('english', file.file);
			}
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedTermsAndConditions'));
		try {
			const data = await events.post('terms', bodyData, false, true);
			dispatch(fetchDetailsSuccess('addedTermsAndConditions', data));
			dispatch(updateRedirect('/policy-management/terms-and-conditions'));
		} catch (error) {
			dispatch(fetchDetailsFail('addedTermsAndConditions', error));
		}
	};
};

export const updateTerm = (
	termId = null,
	title = null,
	dutch = null,
	german = null,
	english = null,
	detailEdit = null,
) => {
	const bodyData =
		(
			(!isEmptyArray(dutch) || !isEmptyArray(german) || !isEmptyArray(english)) &&
			isFullString(title)
		) ?
			new FormData()
		:	null;

	if (!isNull(bodyData)) {
		bodyData.append('title', title);
		bodyData.append('keepExisting', false);
		if (dutch) {
			for (let i = 0; i < dutch.length; i++) {
				const file = dutch[i];
				bodyData.append('dutch', file.file);
			}
		}
		if (german) {
			for (let i = 0; i < german.length; i++) {
				const file = german[i];
				bodyData.append('german', file.file);
			}
		}
		if (english) {
			for (let i = 0; i < english.length; i++) {
				const file = english[i];
				bodyData.append('english', file.file);
			}
		}
	}

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateTerm'));
		try {
			const data = await events.put(`terms/${termId}`, bodyData, false, true);
			dispatch(fetchDetailsSuccess('updateTerm', data));
			if (detailEdit) {
				const dataTerm = await events.get(`terms/${termId}`);
				dispatch(updateRedirect(`/term/details/${termId}/summary`));
				dispatch(fetchDetailsSuccess('fetchTerm', dataTerm));
			} else {
				dispatch(updateRedirect('/policy-management/terms-and-conditions'));
			}
		} catch (error) {
			dispatch(fetchDetailsFail('updateTerm', error));
		}
	};
};

export const fetchTerm = (termId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchTerm'));
		try {
			const data = await events.get(`terms/${termId}`);
			dispatch(fetchDetailsSuccess('fetchTerm', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchTerm', error));
		}
	};
};

//#endregion

/* * * * * * * * * *
 * METHODS BOOKING *
 * * * * * * * * * */
//#region BOOKING
export const addPlanboardBooking = (bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedPlanboardBooking'));
		try {
			const data = await events.post('planboard', bodyData, true);
			dispatch(fetchDetailsSuccess('addedPlanboardBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedPlanboardBooking', error));
		}
	};
};

export const fetchBooking = (id = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchBooking'));
		try {
			const data = await events.get(`bookings/${id}`);
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchBooking', error));
		}
	};
};

export const fetchBookingPrice = (
	bookingId = null,
	startDate = null,
	endDate = null,
	discount = null,
	promoCode = null,
	isStartNow = null,
	isExtending = null,
) => {
	const queryParams = stringifyQueryParams({
		...(moment.isDate(startDate) && { startDate: startDate.toISOString() }),
		...(moment.isDate(endDate) && { endDate: endDate.toISOString() }),
		...(!isNull(discount) && { discount }),
		...(isFullString(promoCode) && { promoCode }),
		...(!isNull(isStartNow) && { isStartNow: true }),
		...(isBoolean(isExtending) && { isExtending: isExtending }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('bookingPrice'));
		try {
			const data = await events.get(`bookings/${bookingId}/price${queryParams}`);
			dispatch(fetchDetailsSuccess('bookingPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('bookingPrice', error));
		}
	};
};

export const startBooking = (id = null, kilometres = null) => {
	const bodyData =
		isFullString(kilometres) ?
			{
				currentKilometres: parseInt(kilometres, 10),
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('startBooking'));
		try {
			const data = await events.put(`bookings/${id}/start`, bodyData);
			dispatch(fetchDetailsSuccess('startBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('startBooking', error));
		}
	};
};

export const stopBooking = (id = null, kilometres = null, stopTime = null) => {
	const bodyData = {
		currentKilometres: isFullString(kilometres) ? parseInt(kilometres, 10) : 0,
		stopTime: stopTime,
	};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('stopBooking'));
		try {
			const data = await events.put(`bookings/${id}/stop`, bodyData);
			dispatch(fetchDetailsSuccess('stopBooking', data));
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('stopBooking', error));
		}
	};
};

export const validateEditedBookingCategory = (
	bookingId = null,
	periodStart = null,
	periodEnd = null,
	userId = null,
) => {
	const queryParams = stringifyQueryParams({
		type: 'categoryUnique',
		...(!isNull(periodStart) && { startDate: periodStart.toISOString() }),
		endDate: periodEnd.toISOString(),
		...(!isNull(userId) && { userId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validatedEditedBookingCategory'));
		try {
			const data = await events.get(`bookings/${bookingId}/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedEditedBookingCategory', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedEditedBookingCategory', error));
		}
	};
};

export const validateBookingCategory = (
	userId = null,
	categoryId = null,
	periodStart = null,
	periodEnd = null,
) => {
	const queryParams = stringifyQueryParams({
		type: 'bookingAllowed',
		userId,
		periodStart,
		periodEnd,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validatedBookingCategory'));
		try {
			const data = await events.get(`categories/${categoryId}/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedBookingCategory', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedBookingCategory', error));
		}
	};
};

export const updatedBookingNote = (bookingId = null, adminNote = null, bookingData = null) => {
	const bodyData =
		adminNote ?
			{
				adminNote: adminNote,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedBookingNote'));
		try {
			const data = await events.put(`bookings/${bookingId}/note`, bodyData);
			dispatch(fetchDetailsSuccess('updatedBookingNote', data));
			dispatch(fetchDetailsSuccess('fetchBooking', { ...bookingData, note: adminNote }));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedBookingNote', error));
		}
	};
};

export const updatedBookingLocation = (bookingId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedBookingLocation'));
		try {
			const data = await events.put(`bookings/${bookingId}/hub`, bodyData);
			dispatch(fetchDetailsSuccess('updatedBookingLocation', data));
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedBookingLocation', error));
		}
	};
};

export const resetBookingMileage = (bookingId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('resetBookingMileage'));
		try {
			const data = await events.put(`bookings/${bookingId}/mileage/reset`);
			dispatch(fetchDetailsSuccess('resetBookingMileage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('resetBookingMileage', error));
		}
	};
};

export const exportBooking = (filters = {}) => {
	const filterString = flattenFilters(filters);

	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportBooking'));
		try {
			const data = await events.get(`bookings/exports${filterString}`, false, false, true);
			dispatch(fetchDetailsSuccess('exportBooking', data));
			dispatch(fetchDetailsSuccess('fetchBooking', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportBooking', error));
		}
	};
};

export const exportBookingTrip = (bookingId = null, filters = {}) => {
	const filterString = flattenFilters(filters);

	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportBookingTrip'));
		try {
			const data = await events.get(
				`bookings/${bookingId}/trips/exports${filterString}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportBookingTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportBookingTrip', error));
		}
	};
};

export const exportBookingTripDetails = (bookingId = null, tripId = null, exportType = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportBookingTripDetails'));
		try {
			const data = await events.get(
				`bookings/${bookingId}/trips/${tripId}/exports?exportType=${exportType}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportBookingTripDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportBookingTripDetails', error));
		}
	};
};

export const exportInstanceTrip = (instanceId = null, filters = {}) => {
	const filterString = flattenFilters(filters);

	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportInstanceTrip'));
		try {
			const data = await events.get(
				`iteminstances/${instanceId}/trips/exports${filterString}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportInstanceTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportInstanceTrip', error));
		}
	};
};

export const exportInstanceTripDetails = (instanceId = null, tripId = null, exportType = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportInstanceTripDetails'));
		try {
			const data = await events.get(
				`iteminstances/${instanceId}/trips/${tripId}/exports?exportType=${exportType}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportInstanceTripDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportInstanceTripDetails', error));
		}
	};
};

export const exportDeviceTrip = (deviceId = null, filters = {}) => {
	const filterString = flattenFilters(filters);

	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportDeviceTrip'));
		try {
			const data = await events.get(
				`devices/${deviceId}/trips/exports${filterString}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportDeviceTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportDeviceTrip', error));
		}
	};
};

export const exportDeviceTripDetails = (deviceId = null, tripId = null, exportType = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('exportDeviceTripDetails'));
		try {
			const data = await events.get(
				`devices/${deviceId}/trips/${tripId}/exports?exportType=${exportType}`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('exportDeviceTripDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('exportDeviceTripDetails', error));
		}
	};
};

//#endregion

/* * * * * * * * * * * * *
 * PRICING MODELS METHODS *
 * * * * * * * * * * * * */
//#region PRICING
export const addPricingModels = (
	name = null,
	organisationId = null,
	categoryId = null,
	calculationType = null,
	vatPercentage = null,
	pricingPeriods = null,
	usageCost = null,
) => {
	const bodyData =
		(
			isFullString(name) &&
			isFullArray(pricingPeriods) &&
			isInteger(organisationId) &&
			isInteger(categoryId)
		) ?
			{
				name: name,
				organisationId,
				categoryId,
				calculationType,
				vatPercentage,
				pricingPeriods: pricingPeriods.map((period) => ({
					...period,
					days: period.days,
					...(moment.isDate(period.start) &&
						moment.isDate(period.end) && {
							start: localToUTCTime(period.start).toISOString(),
							end: localToUTCTime(period.end).toISOString(),
						}),
				})),
				usageCost,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addPricingModels'));
		try {
			const data = await events.post('pricingmodels', bodyData);
			dispatch(fetchDetailsSuccess('addPricingModels', data));
			dispatch(updateRedirect('/policy-management/pricing'));
		} catch (error) {
			dispatch(fetchDetailsFail('addPricingModels', error));
		}
	};
};

export const updatePricingModel = (
	pricingModelId = null,
	name = null,
	categoryId = null,
	vatPercentage = null,
	pricingPeriods = null,
	usageCost = null,
) => {
	const bodyData =
		isFullString(name) && isFullArray(pricingPeriods) && isInteger(categoryId) ?
			{
				name: name,
				categoryId,
				vatPercentage,
				pricingPeriods: pricingPeriods.map((period) => ({
					...period,
					days: period.days,
					...(moment.isDate(period.start) &&
						moment.isDate(period.end) && {
							start: localToUTCTime(period.start).toISOString(),
							end: localToUTCTime(period.end).toISOString(),
						}),
				})),
				usageCost,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedPricingModel'));
		try {
			const data = await events.put(`pricingmodels/${pricingModelId}`, bodyData);
			dispatch(fetchDetailsSuccess('updatedPricingModel', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedPricingModel', error));
		}
	};
};

export const fetchPrice = (priceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchPrice'));
		try {
			const data = await events.get(`pricingmodels/${priceId}`);
			dispatch(fetchDetailsSuccess('fetchPrice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchPrice', error));
		}
	};
};

export const businessPricing = (priceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('businessPricing'));
		try {
			const data = await events.get(`pricingmodels/${priceId}`);
			dispatch(fetchDetailsSuccess('businessPricing', data));
		} catch (error) {
			dispatch(fetchDetailsFail('businessPricing', error));
		}
	};
};
//#endregion

/* * * * * * * * *
 * POLICY MODELS *
 * * * * * * * * */
//#region POLICY
export const addPolicy = (organisationId = null, name = null, data = {}) => {
	const bodyData =
		isNumber(organisationId) && isFullString(name) ?
			{
				organisationId,
				name,
				...data,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedPolicy'));
		try {
			const data = await events.post('policies', bodyData);
			dispatch(fetchDetailsSuccess('addedPolicy', data));
			dispatch(updateRedirect('/policy-management/item-policies'));
		} catch (error) {
			dispatch(fetchDetailsFail('addedPolicy', error));
		}
	};
};

export const fetchPolicy = (policyId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchPolicy'));
		try {
			const data = await events.get(`policies/${policyId}`);
			dispatch(fetchDetailsSuccess('fetchPolicy', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchPolicy', error));
		}
	};
};

export const updatePolicy = (
	policyId = null,
	name = null,
	policyData = {},
	editRedirect = false,
) => {
	const bodyData =
		isFullString(name) ?
			{
				name: name,
				...policyData,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatePolicy'));
		try {
			const data = await events.put(`policies/${policyId}`, bodyData);
			dispatch(fetchDetailsSuccess('updatePolicy', data));
			if (editRedirect) {
				dispatch(updateRedirect(`/policy-management/item-policies/${policyId}/summary`));
			} else {
				dispatch(updateRedirect('/policy-management/item-policies'));
			}
		} catch (error) {
			dispatch(fetchDetailsFail('updatePolicy', error));
		}
	};
};

export const validatePolicyName = (value = null, organisationId = null) => {
	const queryParams = stringifyQueryParams({
		type: 'uniqueName',
		name: value,
		...(!isNull(organisationId) && { organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validatedPolicyName'));
		try {
			const data = await events.get(`policies/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedPolicyName', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedPolicyName', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * * * *
 * CHARACTERISTICS METHODS *
 * * * * * * * * * * * * * */
//#region CHARACTERISTICS
export const fetchCharacteristics = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('characteristics'));
		try {
			const data = await events.get('characteristics');
			dispatch(fetchDetailsSuccess('characteristics', data));
		} catch (error) {
			dispatch(fetchDetailsFail('characteristics', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * *
 * PARTNERSHIP METHODS *
 * * * * * * * * * * * */
//#region PARTNERSHIP
export const connectToPartner = (id) => {
	const bodyData =
		isNumber(id) ?
			{
				partnerOrganisationId: id,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('addedPartnership'));
		try {
			const data = await events.post('partnerships', bodyData);
			dispatch(fetchDetailsSuccess('addedPartnership', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addedPartnership', error));
		}
	};
};

export const partner = (partnerId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('partner'));
		try {
			const data = await events.get(`partnerships/${partnerId}`);
			dispatch(fetchDetailsSuccess('partner', data));
		} catch (error) {
			dispatch(fetchDetailsFail('partner', error));
		}
	};
};

export const updatePartnershipStatus = (partnerId = null, state = null) => {
	const bodyData =
		isFullString(state) ?
			{
				state,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updatedPartnership'));
		try {
			const data = await events.put(`partnerships/${partnerId}`, bodyData);
			dispatch(fetchDetailsSuccess('updatedPartnership', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updatedPartnership', error));
		}
	};
};

export const unassignPartnershipsItems = (partnershipId = null, items = null) => {
	const bodyData =
		partnershipId ?
			{
				items: items,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('unassignOrCancelItem'));
		try {
			const data = await events.delete(`partnerships/${partnershipId}/items`, bodyData);
			dispatch(fetchDetailsSuccess('unassignOrCancelItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('unassignOrCancelItem', error));
		}
	};
};

export const assignItemsToPartner = (partnerId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('assignedItemsToPartner'));
		try {
			const data = await events.post(`partnerships/${partnerId}/items`, bodyData);
			dispatch(fetchDetailsSuccess('assignedItemsToPartner', data));
		} catch (error) {
			dispatch(fetchDetailsFail('assignedItemsToPartner', error));
		}
	};
};
//#endregion

/* * * * * * * * * * *
 * CATEGORIES METHODS *
 * * * * * * * * * *  */
//#region CATEGORIES
export const category = (id) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('category'));
		try {
			const data = await events.get(`categories/${id}`);
			dispatch(fetchDetailsSuccess('category', data));
		} catch (error) {
			dispatch(fetchDetailsFail('category', error));
		}
	};
};
//#endregion

/* * * * * * * * * * * *
 * INVITATION METHODS  *
 * * * * * * * * * * * */
export const validateEmailToInvite = (email = null) => {
	const queryParams = stringifyQueryParams({
		email,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validatedEmailToInvite'));
		try {
			const data = await events.get(`invitations/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validatedEmailToInvite', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validatedEmailToInvite', error));
		}
	};
};

/* * * * * * * * * *
 * PAYMENT METHODS *
 * * * * * * * * * */
export const fetchBusinessPaymentMethodOfUser = (userId = null) => {
	const queryParams = stringifyQueryParams({
		userId,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('businessPaymentMethodUser'));
		try {
			const data = await events.get(`paymentmethods/business${queryParams}`);
			dispatch(fetchDetailsSuccess('businessPaymentMethodUser', data));
		} catch (error) {
			dispatch(fetchDetailsFail('businessPaymentMethodUser', error));
		}
	};
};

export const fetchPersonalPaymentMethodOfUser = (userId = null) => {
	const queryParams = stringifyQueryParams({
		userId,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('personalPaymentMethodUser'));
		try {
			const data = await events.get(`paymentmethods/personal${queryParams}`);
			dispatch(fetchDetailsSuccess('personalPaymentMethodUser', data));
		} catch (error) {
			dispatch(fetchDetailsFail('personalPaymentMethodUser', error));
		}
	};
};

export const fetchPaymentMethodsOfUser = (userId = null) => {
	const queryParams = stringifyQueryParams({
		userId,
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('paymentMethodsUser'));
		try {
			const data = await events.get(`paymentmethods${queryParams}`);
			dispatch(fetchDetailsSuccess('paymentMethodsUser', data));
		} catch (error) {
			dispatch(fetchDetailsFail('paymentMethodsUser', error));
		}
	};
};

/* * * * * *
 * QR CODE *
 * * * * * */
export const fetchItemInstanceQrCode = (itemId = null, itemInstanceId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemInstanceQrCode'));
		try {
			const data = await events.get(
				`items/${itemId}/iteminstances/${itemInstanceId}/qr`,
				false,
				false,
				true,
			);
			dispatch(fetchDetailsSuccess('itemInstanceQrCode', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemInstanceQrCode', error));
		}
	};
};

export const fetchAllQRCodes = (itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('allQRCodes'));
		try {
			const data = await events.get(`items/${itemId}/iteminstances/qr`, false, false, true);
			dispatch(fetchDetailsSuccess('allQRCodes', data));
		} catch (error) {
			dispatch(fetchDetailsFail('allQRCodes', error));
		}
	};
};

/* * * * * * * * * *
 * REVENUES METHODS *
 * * * * * * * * * */
//#region REVENUES
export const fetchCurrentRevenue = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('currentRevenue'));
		try {
			const data = await events.get('finances/revenues/current');
			// const dataModified = {...data, revenueBillings: data.revenueBillings.map(revenue => ({...revenue, billingType: 'business', status: 'paid'}))};
			dispatch(fetchDetailsSuccess('currentRevenue', data));
		} catch (error) {
			dispatch(fetchDetailsFail('currentRevenue', error));
		}
	};
};

export const fetchPersonalRevenue = (date = null, id = null) => {
	const queryParams = isFullString(id) ? `?organisationId=${id}` : '';
	return async (dispatch) => {
		dispatch(fetchDetailsStart('personalRevenue'));
		try {
			const data = await events.get(`finances/revenues/${date}/personal${queryParams}`);
			// const dataModified = {...data, revenueBillings: data.revenueBillings.map(revenue => ({...revenue, billingType: 'business', status: 'paid'}))};
			dispatch(fetchDetailsSuccess('personalRevenue', data));
		} catch (error) {
			dispatch(fetchDetailsFail('personalRevenue', error));
		}
	};
};

export const fetchBusinessRevenue = (date = null, filters = {}) => {
	const id = isFullString(filters.id) ? `?organisationId=${filters.id}` : '';
	const searchTerm = isFullString(filters.searchTerm) ? `?searchTerm=${filters.searchTerm}` : '';
	return async (dispatch) => {
		dispatch(fetchDetailsStart('businessRevenue'));
		try {
			const data = await events.get(`finances/revenues/${date}/business${searchTerm}${id}`);
			dispatch(fetchDetailsSuccess('businessRevenue', data));
		} catch (error) {
			dispatch(fetchDetailsFail('businessRevenue', error));
		}
	};
};

export const fetchCurrentPersonalBillingPeriod = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('currentPersonalBillingPeriod'));
		try {
			const data = await events.get('finances/billings/current');
			dispatch(fetchDetailsSuccess('currentPersonalBillingPeriod', data));
		} catch (error) {
			dispatch(fetchDetailsFail('currentPersonalBillingPeriod', error));
		}
	};
};

export const fetchPersonalBillingPeriodByDate = (date = null, filters = {}) => {
	const organisationId =
		isFullString(filters.organisationId) && `?organisationId=${filters.organisationId}`;
	const search = isFullString(filters.searchTerm) && `?searchTerm=${filters.searchTerm}`;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('personalBillingPeriodByDate'));
		try {
			const data = await events.get(
				`finances/billings/${date}${isFullString(search) ? search : ''}${isFullString(organisationId) ? organisationId : ''}`,
			);
			dispatch(fetchDetailsSuccess('personalBillingPeriodByDate', data));
		} catch (error) {
			dispatch(fetchDetailsFail('personalBillingPeriodByDate', error));
		}
	};
};

export const fetchUsersPersonalBillingPeriodByDate = (date = null, userId, filters = {}) => {
	const queryParams = stringifyQueryParams({
		userId,
		...(isFullString(filters.searchTerm) && { searchTerm: filters.searchTerm }),
	});
	return async (dispatch) => {
		dispatch(fetchDetailsStart('usersPersonalBillingPeriodByDate'));
		try {
			const data = await events.get(`finances/billings/${date}${queryParams}`);
			dispatch(fetchDetailsSuccess('usersPersonalBillingPeriodByDate', data));
		} catch (error) {
			dispatch(fetchDetailsFail('usersPersonalBillingPeriodByDate', error));
		}
	};
};

export const fetchUserImage = (userId) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchUserImage'));
		try {
			const data = await events.get(`users/${userId}/images`);
			dispatch(fetchDetailsSuccess('fetchUserImage', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchUserImage', error));
		}
	};
};
//#endregion
/* * * * * * * * * *
 * EXPENSES METHODS *
 * * * * * * * * * */
//#region REVENUES
export const fetchCurrentExpenses = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('currentExpenses'));
		try {
			const data = await events.get('finances/expenses/current');
			// const dataModified = {...data, revenueBillings: data.revenueBillings.map(revenue => ({...revenue, billingType: 'business', status: 'paid'}))};
			dispatch(fetchDetailsSuccess('currentExpenses', data));
		} catch (error) {
			dispatch(fetchDetailsFail('currentExpenses', error));
		}
	};
};

export const fetchBusinessExpenses = (date = null, filters = {}) => {
	const id = isFullString(filters.id) ? `?organisationId=${filters.id}` : '';
	const searchTerm = isFullString(filters.searchTerm) ? `?searchTerm=${filters.searchTerm}` : '';
	return async (dispatch) => {
		dispatch(fetchDetailsStart('businessExpenses'));
		try {
			const data = await events.get(`finances/expenses/${date}/business${searchTerm}${id}`);
			dispatch(fetchDetailsSuccess('businessExpenses', data));
		} catch (error) {
			dispatch(fetchDetailsFail('businessExpenses', error));
		}
	};
};
//#endregion
export const updateEmailDomain = (organisationsId = null, emailDomain = null) => {
	const bodyData =
		isNumber(organisationsId) && isFullString(emailDomain) ?
			{
				value: emailDomain,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('emailDomain'));
		try {
			const data = await events.put(`organisations/${organisationsId}/domain`, bodyData);
			dispatch(fetchDetailsSuccess('emailDomain', data));
			dispatch(fetchDetailsSuccess('organisation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('emailDomain', error));
		}
	};
};

export const linkDevices = (deviceId = null, organisationId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('linkDevices'));
		try {
			const data = await events.put(
				`devices/${deviceId}/organisations/${organisationId}/iteminstance/${instanceId}/assign`,
			);
			dispatch(fetchDetailsSuccess('linkDevices', data));
		} catch (error) {
			dispatch(fetchDetailsFail('linkDevices', error));
		}
	};
};

/**
 * Updates the name of a device.
 *
 * @param {string|null} deviceId - The ID of the device to update. Pass null if not available.
 * @param {string|null} deviceName - The new name for the device. Pass null if not available.
 * @returns {Function} - A function that dispatches actions for updating device name.
 */
export const updateDeviceName = (deviceId = null, deviceName = null) => {
	const bodyData = {
		name: deviceName,
	};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateDeviceName'));
		try {
			const data = await events.put(`devices/${deviceId}`, bodyData);
			dispatch(fetchDetailsSuccess('updateDeviceName', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateDeviceName', error));
		}
	};
};

export const linkDeviceToItemGroup = (deviceId = null, organisationId = null, itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('linkDeviceToItemGroup'));
		try {
			const data = await events.put(
				`devices/${deviceId}/organisations/${organisationId}/items/${itemId}/assign`,
			);
			dispatch(fetchDetailsSuccess('linkDeviceToItemGroup', data));
		} catch (error) {
			dispatch(fetchDetailsFail('linkDeviceToItemGroup', error));
		}
	};
};

export const assignDevices = (organisationsId = null, devices = null) => {
	const bodyData =
		isNumber(organisationsId) && isFullArray(devices) ?
			{
				deviceIds: devices,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('assignDevices'));
		try {
			const data = await events.put(`devices/organisations/${organisationsId}/assign`, bodyData);
			dispatch(fetchDetailsSuccess('assignDevices', data));
		} catch (error) {
			dispatch(fetchDetailsFail('assignDevices', error));
		}
	};
};

export const unassignDevices = (devices = null) => {
	const bodyData =
		isFullArray(devices) ?
			{
				deviceIds: devices,
			}
		:	null;
	return async (dispatch) => {
		dispatch(fetchDetailsStart('unassignDevices'));
		try {
			const data = await events.put('devices/unassign', bodyData);
			dispatch(fetchDetailsSuccess('unassignDevices', data));
		} catch (error) {
			dispatch(fetchDetailsFail('unassignDevices', error));
		}
	};
};

export const fetchDevice = (deviceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchDevice'));
		try {
			const data = await events.get(`devices/${deviceId}`, true);
			dispatch(fetchDetailsSuccess('fetchDevice', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchDevice', error));
		}
	};
};

export const fetchDeviceHeartbeats = (deviceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchDeviceHeartbeats'));
		try {
			const data = await events.get(`devices/${deviceId}/heartbeats/latest`, true);
			dispatch(fetchDetailsSuccess('fetchDeviceHeartbeats', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchDeviceHeartbeats', error));
		}
	};
};

export const instanceHeartbeats = (itemId = null, instanceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('instanceHeartbeats'));
		try {
			const data = await events.get(
				`items/${itemId}/iteminstances/${instanceId}/heartbeats/latest`,
				true,
			);
			dispatch(fetchDetailsSuccess('instanceHeartbeats', data));
		} catch (error) {
			dispatch(fetchDetailsFail('instanceHeartbeats', error));
		}
	};
};

export const fetchVehicleStatus = (deviceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('vehicleStatus'));
		try {
			const data = await events.get(`devices/${deviceId}/vehiclestatus`, true);
			dispatch(fetchDetailsSuccess('vehicleStatus', data));
		} catch (error) {
			dispatch(fetchDetailsFail('vehicleStatus', error));
		}
	};
};

export const fetchDeviceLocation = (deviceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchDeviceLocation'));
		try {
			const data = await events.get(`devices/${deviceId}/location`);
			dispatch(fetchDetailsSuccess('fetchDeviceLocation', data));
			if (!isEmptyObject(data)) {
				const dataLocation = await events.get(`devices/${deviceId}/heartbeats/latest`);
				dispatch(fetchDetailsSuccess('fetchDeviceHeartbeats', dataLocation));
			}
		} catch (error) {
			dispatch(fetchDetailsFail('fetchDeviceLocation', error));
		}
	};
};

export const deviceUnlockCode = (deviceId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('deviceUnlockCode'));
		try {
			const data = await events.get(`devices/${deviceId}/unlockcode`);
			dispatch(fetchDetailsSuccess('deviceUnlockCode', data));
		} catch (error) {
			dispatch(fetchDetailsFail('deviceUnlockCode', error));
		}
	};
};

export const fetchPendingLicenseDetails = (requestId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchPendingLicenseDetails'));
		try {
			const data = await events.get(`userlicenses/${requestId}`);
			dispatch(fetchDetailsSuccess('fetchPendingLicenseDetails', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchPendingLicenseDetails', error));
		}
	};
};

/* * * * * * * * * *
 * NFC Tags METHODS *
 * * * * * * * * * */
//#region NFCTags
export const fetchNFCTags = (userId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('NFCTags'));
		try {
			const data = await events.get(`nfccards/${userId}`);
			dispatch(fetchDetailsSuccess('NFCTags', data));
		} catch (error) {
			dispatch(fetchDetailsFail('NFCTags', error));
		}
	};
};
/**
 * bodyData for NFC-related data.
 * @typedef NfcBody
 * @type {Object}
 * @property {string} cardType - The type of NFC card.
 * @property {string} card - The NFC card information.
 * @property {string} nfcId - The NFC ID.
 * @property {string} tagType - The tag type of card.
 * @property {number} organisationId - The organization ID for NFC.
 */

/**
 * @param {NfcBody} bodyData
 * @returns
 */
export const addNfc = (bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addNfc'));
		try {
			const data = await events.post('nfccards', bodyData);
			dispatch(fetchDetailsSuccess('addNfc', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addNfc', error));
		}
	};
};

export const updateNFCTags = (
	nfcId = null,
	cardNumber = null,
	userId = null,
	cardStatus = null,
) => {
	const bodyData =
		isFullString(cardNumber) ?
			{
				cardStatus: cardStatus,
				userId: userId,
				cardNumber: cardNumber,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateNFCTags'));
		try {
			const data = await events.put(`nfccards/${nfcId}`, bodyData);
			dispatch(fetchDetailsSuccess('NFCTags', [data]));
			dispatch(fetchDetailsSuccess('updateNFCTags', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateNFCTags', error));
		}
	};
};
/**
 * Link NFC card to a specific ID.
 * @param {string|null} nfcId - The ID of the NFC card to link. Set to null for default behavior.
 * @param {Object|null} bodyData
 */
export const linkNfc = (nfcId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('linkNfc'));
		try {
			const data = await events.put(`nfccards/${nfcId}`, bodyData);
			dispatch(fetchDetailsSuccess('linkNfc', data));
		} catch (error) {
			dispatch(fetchDetailsFail('linkNfc', error));
		}
	};
};
//#endregion

/* * * * * * * * * *
 * UNAVAILABILITIES *
 * * * * * * * * * */
//#region Unavailability
export const addUnavailability = (bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addUnavailability'));
		try {
			const data = await events.post('unavailabilities', bodyData);
			dispatch(fetchDetailsSuccess('addUnavailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addUnavailability', error));
		}
	};
};

export const fetchUnavailability = (unavailabilityId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('fetchUnavailability'));
		try {
			const data = await events.get(`unavailabilities/${unavailabilityId}`);
			dispatch(fetchDetailsSuccess('fetchUnavailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('fetchUnavailability', error));
		}
	};
};

export const cancelUnavailabilities = (unavailabilityId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('cancelUnavailabilities'));
		try {
			const data = await events.put(`unavailabilities/${unavailabilityId}/cancel`);
			dispatch(fetchDetailsSuccess('cancelUnavailabilities', data));
		} catch (error) {
			dispatch(fetchDetailsFail('cancelUnavailabilities', error));
		}
	};
};
export const updateUnavailability = (unavailabilityId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateUnavailability'));
		try {
			const data = await events.put(`unavailabilities/${unavailabilityId}`, bodyData);
			dispatch(fetchDetailsSuccess('updateUnavailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateUnavailability', error));
		}
	};
};

export const stopUnavailability = (unavailabilityId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('stopUnavailability'));
		try {
			const data = await events.put(`unavailabilities/${unavailabilityId}/stop`);
			dispatch(fetchDetailsSuccess('stopUnavailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('stopUnavailability', error));
		}
	};
};

export const validateUnavailability = (
	itemInstanceId = null,
	itemId = null,
	periodStart = null,
	periodEnd = null,
	unavailId = null,
) => {
	const queryParams = stringifyQueryParams({
		...(!isNull(itemInstanceId) && !isUndefined(itemInstanceId) && { itemInstanceId }),
		...(!isNull(itemId) && !isUndefined(itemId) && { itemId }),
		...(moment.isDate(periodStart) && { periodStart: periodStart.toISOString() }),
		...(moment.isDate(periodEnd) && { periodEnd: periodEnd.toISOString() }),
		...(!isNull(unavailId) && !isUndefined(unavailId) && { unavailId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('validateUnavailability'));
		try {
			const data = await events.get(`unavailabilities/validate${queryParams}`);
			dispatch(fetchDetailsSuccess('validateUnavailability', data));
		} catch (error) {
			dispatch(fetchDetailsFail('validateUnavailability', error));
		}
	};
};
//#endregion

//#region DOCKING LOCATIONS
export const linkDockingLocation = (slug = null, locationId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('linkDockingLocation'));
		try {
			const data = await events.put(`dockinglocations/${slug}/locations/${locationId}/link`);
			dispatch(fetchDetailsSuccess('linkDockingLocation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('linkDockingLocation', error));
		}
	};
};

export const unlinkDockingLocation = (slug = null, locationId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('unlinkDockingLocation'));
		try {
			const data = await events.put(`dockinglocations/${slug}/locations/${locationId}/unlink`);
			dispatch(fetchDetailsSuccess('unlinkDockingLocation', data));
		} catch (error) {
			dispatch(fetchDetailsFail('unlinkDockingLocation', error));
		}
	};
};
//#endregion

/**
 *
 * @param {Date} dateStart
 * @param {Date} dateEnd
 * @returns
 */
export const tripsInsights = (filters) => {
	// const dateStartString = dateStart?.toISOString();
	// const dateEndString = dateEnd?.toISOString();

	return async (dispatch) => {
		dispatch(fetchDetailsStart('tripsInsights'));
		try {
			const data = await events.get(`trips/insights${flattenFilters(filters)}`);
			dispatch(fetchDetailsSuccess('tripsInsights', data));
		} catch (error) {
			dispatch(fetchDetailsFail('tripsInsights', error));
		}
	};
};

export const trip = (deviceId = null, tripId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('trip'));
		try {
			const data = await events.get(`devices/${deviceId}/trips/${tripId}`);
			dispatch(fetchDetailsSuccess('trip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('trip', error));
		}
	};
};

export const itemInstancesTrip = (itemInstanceId = null, tripId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemInstancesTrip'));
		try {
			const data = await events.get(`iteminstances/${itemInstanceId}/trips/${tripId}`);
			dispatch(fetchDetailsSuccess('itemInstancesTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemInstancesTrip', error));
		}
	};
};

export const bookingTrip = (bookingId = null, tripId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('bookingTrip'));
		try {
			const data = await events.get(`bookings/${bookingId}/trips/${tripId}`);
			dispatch(fetchDetailsSuccess('bookingTrip', data));
		} catch (error) {
			dispatch(fetchDetailsFail('bookingTrip', error));
		}
	};
};

/* * * * * *
 * TANKCARDS *
 * * * * * * */
//#region Unavailability
export const addTankCard = (
	itemId = null,
	instanceId = null,
	bodyData = null,
	instanceData = null,
) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addTankCard'));
		try {
			const data = await events.post(
				`items/${itemId}/iteminstances/${instanceId}/tankcards`,
				bodyData,
			);
			dispatch(fetchDetailsSuccess('addTankCard', data));
			if (data) {
				dispatch(fetchDetailsSuccess('fetchInstance', { ...instanceData, tankCard: data }));
			}
		} catch (error) {
			dispatch(fetchDetailsFail('addTankCard', error));
		}
	};
};
//#endregion

/* * * * *
 * KEYTAG *
 * * * * */
//#region KEYTAG
export const addKeyTag = (
	itemId = null,
	instanceId = null,
	bodyData = null,
	instanceData = null,
) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addKeyTag'));
		try {
			const data = await events.post(
				`items/${itemId}/iteminstances/${instanceId}/keytags`,
				bodyData,
			);
			dispatch(fetchDetailsSuccess('addKeyTag', data));
			if (data) {
				dispatch(fetchDetailsSuccess('fetchInstance', { ...instanceData, keyTag: data }));
			}
		} catch (error) {
			dispatch(fetchDetailsFail('addKeyTag', error));
		}
	};
};
//#endregion

/* * * * * *
 * Contracts *
 * * * * * * */
//#region Contracts
export const addContract = (bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('addContract'));
		try {
			const data = await events.post('contracts', bodyData);
			dispatch(fetchDetailsSuccess('addContract', data));
		} catch (error) {
			dispatch(fetchDetailsFail('addContract', error));
		}
	};
};

export const contract = (contractId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('contract'));
		try {
			const data = await events.get(`contracts/${contractId}`);
			dispatch(fetchDetailsSuccess('contract', data));
		} catch (error) {
			dispatch(fetchDetailsFail('contract', error));
		}
	};
};

export const terminateContract = (contractId = null, body = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('terminateContract'));
		try {
			const data = await events.put(`contracts/${contractId}/terminate`, body);
			dispatch(fetchDetailsSuccess('terminateContract', data));
		} catch (error) {
			dispatch(fetchDetailsFail('terminateContract', error));
		}
	};
};

export const rejectContract = (contractId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('rejectContract'));
		try {
			const data = await events.put(`contracts/${contractId}/reject`);
			dispatch(fetchDetailsSuccess('rejectContract', data));
		} catch (error) {
			dispatch(fetchDetailsFail('rejectContract', error));
		}
	};
};
//#endregion

/* * * * * * *
 * DASHBOARD  *
 * * * * * * */
//#region Dashboard
export const dashboardActions = () => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('dashboardActions'));
		try {
			const data = await events.get('dashboard/actions');
			dispatch(fetchDetailsSuccess('dashboardActions', data));
		} catch (error) {
			dispatch(fetchDetailsFail('dashboardActions', error));
		}
	};
};

export const dashboardItemsOverview = (filters = {}) => {
	const filterString = !isEmptyObject(filters) ? flattenFilters(filters) : '';

	return async (dispatch) => {
		dispatch(fetchDetailsStart('dashboardItemsOverview'));
		try {
			const data = await events.get(`dashboard/items/overview${filterString}`);
			dispatch(fetchDetailsSuccess('dashboardItemsOverview', data));
		} catch (error) {
			dispatch(fetchDetailsFail('dashboardItemsOverview', error));
		}
	};
};

export const dashboardBookingsOverview = (dateTime = null, organisationId = null) => {
	const year = dateTime.getFullYear();
	const month = new Date(dateTime).getMonth() + 1;

	const queryParams = stringifyQueryParams({
		...(!isNull(dateTime) && { dateTime: `${year}-${month}-01T00:00:00Z` }),
		...(isNumber(organisationId) && { organisationId: organisationId }),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('dashboardBookingsOverview'));
		try {
			const data = await events.get(`dashboard/bookings/overview${queryParams}`);
			dispatch(fetchDetailsSuccess('dashboardBookingsOverview', data));
		} catch (error) {
			dispatch(fetchDetailsFail('dashboardBookingsOverview', error));
		}
	};
};

export const dashboardStatus = () => {
	const queryParams = stringifyQueryParams({
		...(isFullString(sessionStorage.getItem('dashboardOrganisationIdFilter')) && {
			organisationId: sessionStorage.getItem('dashboardOrganisationIdFilter'),
		}),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('dashboardStatus'));
		try {
			const data = await events.get(`dashboard/bookings/status${queryParams}`);
			dispatch(fetchDetailsSuccess('dashboardStatus', data));
		} catch (error) {
			dispatch(fetchDetailsFail('dashboardStatus', error));
		}
	};
};

export const dashboardTicketsStatus = () => {
	const queryParams = stringifyQueryParams({
		...(isFullString(sessionStorage.getItem('dashboardOrganisationIdFilter')) && {
			organisationId: sessionStorage.getItem('dashboardOrganisationIdFilter'),
		}),
	});

	return async (dispatch) => {
		dispatch(fetchDetailsStart('dashboardTicketsStatus'));
		try {
			const data = await events.get(`dashboard/tickets/status${queryParams}`);
			dispatch(fetchDetailsSuccess('dashboardTicketsStatus', data));
		} catch (error) {
			dispatch(fetchDetailsFail('dashboardTicketsStatus', error));
		}
	};
};

export const userLicensesHold = (userId = null, body = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('userLicensesHold'));
		try {
			const data = await events.put(`userlicenses/${userId}/hold`, body);
			dispatch(fetchDetailsSuccess('userLicensesHold', data));
		} catch (error) {
			dispatch(fetchDetailsFail('userLicensesHold', error));
		}
	};
};
//#endregion

export const sharingsUpdate = (providerId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('sharingsUpdate'));
		try {
			const data = await events.put(`sharings/${providerId}/consumers/items`, bodyData);
			dispatch(fetchDetailsSuccess('sharingsUpdate', data));
		} catch (error) {
			dispatch(fetchDetailsFail('sharingsUpdate', error));
		}
	};
};

export const stopSharingExternalItems = (partnershipId = null, bodyData = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('stopSharingExternalItems'));
		try {
			const data = await events.delete(`partnerships/${partnershipId}/items`, bodyData);
			dispatch(fetchDetailsSuccess('stopSharingExternalItems', data));
		} catch (error) {
			dispatch(fetchDetailsFail('stopSharingExternalItems', error));
		}
	};
};

export const externalItem = (providerId = null, consumerId = null, itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('externalItem'));
		try {
			const data = await events.get(
				`sharings/${providerId}/externals/consumers/${consumerId}/items/${itemId}`,
			);
			dispatch(fetchDetailsSuccess('externalItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('externalItem', error));
		}
	};
};

export const internalItem = (providerId = null, itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('internalItem'));
		try {
			const data = await events.get(`sharings/${providerId}/internals/consumers/items/${itemId}`);
			dispatch(fetchDetailsSuccess('internalItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('internalItem', error));
		}
	};
};

export const publicItem = (providerId = null, itemId = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('publicItem'));
		try {
			const data = await events.get(`sharings/${providerId}/publics/consumers/items/${itemId}`);
			dispatch(fetchDetailsSuccess('publicItem', data));
		} catch (error) {
			dispatch(fetchDetailsFail('publicItem', error));
		}
	};
};

export const itemSharings = (itemId = null, sharingType = null) => {
	return async (dispatch) => {
		dispatch(fetchDetailsStart('itemSharings'));
		try {
			const data = await events.get(`items/${itemId}/sharings/${sharingType}`);
			dispatch(fetchDetailsSuccess('itemSharings', data));
		} catch (error) {
			dispatch(fetchDetailsFail('itemSharings', error));
		}
	};
};

export const updateDiscount = (bookingId = null, discount = null) => {
	const bodyData =
		isInteger(discount) ?
			{
				discount: discount,
			}
		:	null;

	return async (dispatch) => {
		dispatch(fetchDetailsStart('updateDiscount'));
		try {
			const data = await events.put(`bookings/${bookingId}/discount`, bodyData);
			dispatch(fetchDetailsSuccess('updateDiscount', data));
		} catch (error) {
			dispatch(fetchDetailsFail('updateDiscount', error));
		}
	};
};

/**
 *
 * @param {string} path
 * @param {('adminPanel' | 'portal')} platform
 */
export const createDynamicLink = (path, target = 'portal') => {
	const body = {
		path: path,
		target: target,
	};

	return async (dispatch) => {
		dispatch(fetchDetailsStart('createDynamicLink'));
		try {
			const data = await events.post('dynamiclinks', body, false, false, false, 'v2');
			dispatch(fetchDetailsSuccess('createDynamicLink', data));
		} catch (error) {
			dispatch(fetchDetailsFail('createDynamicLink', error));
		}
	};
};
