import { NumericFormat } from 'react-number-format';

interface CustomNumberFormatProps {
	inputRef: any;
	name: string;
	onChange(...args: unknown[]): unknown;
}

const CustomNumberFormat = (props: CustomNumberFormatProps) => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			allowNegative={false}
			decimalScale={2}
			getInputRef={inputRef}
			ref={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			allowedDecimalSeparators={['.', ',']}
		/>
	);
};

export default CustomNumberFormat;
