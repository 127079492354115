import { useEffect, useState } from 'react';

import { Box, Typography, Switch } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { useAuthorize } from '~features/authentication';

import { ReactDatePicker } from '../../../../components';
import {
	isEarlierThan,
	sameDates,
	getDateRoundedToDuration,
	modifyDate,
	isDate,
	isDateToday,
	unsetTime,
	duration,
	durationToMinutes,
	areDatesEqual,
} from '../../../../shared/datetime';
import { isObject, isNull, isFullString } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import { useStyles } from '../style';

interface DateTimeCardProps {
	date?: object;
	selectedStartDate?: object;
	selectedEndDate?: object;
	selectedItem?: object;
	presetData?: object;
	selectedUser?: object;
	selectType?: string;
	isTimeAvailabilityError?: boolean;
	availableForMaintenance?: boolean;
	setAvailableForMaintenance?(...args: unknown[]): unknown;
	onFetchItemPolicies?(...args: unknown[]): unknown;
	setSelectedStartDate?(...args: unknown[]): unknown;
	setSelectedEndDate?(...args: unknown[]): unknown;
	setSelectedPaymentMethod?(...args: unknown[]): unknown;
	setComment?(...args: unknown[]): unknown;
	setCommentLength?(...args: unknown[]): unknown;
	onResetState?(...args: unknown[]): unknown;
	onFetchItemInstanceAvailability?(...args: unknown[]): unknown;
	setPolicies?(...args: unknown[]): unknown;
	onValidateBookingCategory?(...args: unknown[]): unknown;
	onValidateEditedBookingCategory?(...args: unknown[]): unknown;
	onValidateUnavailability?(...args: unknown[]): unknown;
	fetchUnavailabilityData?: object;
	isProvider?: boolean;
	open?: boolean;
	instanceAvailabilityLoading?: boolean;
	availability?: boolean;
	nowSwitch?: boolean;
	setNowSwitch?(...args: unknown[]): unknown;
	isExternalAdminError?: boolean;
	setIsExternalAdminError?(...args: unknown[]): unknown;
	onMaintenanceAvailability?(...args: unknown[]): unknown;
	durationErrors?(...args: unknown[]): unknown;
	setFetchBookingPrice?(...args: unknown[]): unknown;
	maintenanceAvailabilityLoading?: boolean;
	planboardBooking?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchItemPolicies?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validatedBookingCategory?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validatedEditedBookingCategory?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchInstance?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	fetchUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validateUnavailability?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
}

const DateTimeCard = (props: DateTimeCardProps) => {
	const {
		presetData,
		planboardBooking,
		selectedStartDate,
		selectedEndDate,

		setSelectedPaymentMethod,
		onFetchItemPolicies,
		setSelectedStartDate,
		setSelectedEndDate,
		setComment,
		setCommentLength,
		fetchItemPolicies,
		open,
		onResetState,
		onFetchItemInstanceAvailability,
		selectedItem,
		setPolicies,
		selectedUser,
		onValidateBookingCategory,
		onValidateEditedBookingCategory,
		validatedBookingCategory,
		validatedEditedBookingCategory,
		fetchInstance,
		instanceAvailabilityLoading,
		setFetchBookingPrice,
		fetchUnavailability,
		onValidateUnavailability,
		validateUnavailability,
		availableForMaintenance,
		setAvailableForMaintenance,
		selectType,
		nowSwitch,
		setNowSwitch,
		isProvider,
		isExternalAdminError,
		setIsExternalAdminError,

		date: calendarDate,
		onMaintenanceAvailability,
		maintenanceAvailabilityLoading,
		durationErrors,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const { data: bookingData } = planboardBooking;

	const { data: fetchItemPoliciesData, loading: fetchItemPoliciesLoading } = fetchItemPolicies;

	const { loading: categoryCheckLoading } = validatedBookingCategory;

	const { data: bookingCategoryCheckData, loading: bookingCategoryCheckLoading } =
		validatedEditedBookingCategory;

	const { data: fetchUnavailabilityData } = fetchUnavailability;

	const {
		data: validateUnavailabilityData,
		loading: validateUnavailabilityLoading,
		error: validateUnavailabilityError,
	} = validateUnavailability;
	const validateUnavailabilityCheckDone =
		isObject(validateUnavailabilityData) &&
		!validateUnavailabilityLoading &&
		!validateUnavailabilityError;

	const { data: fetchInstanceData } = fetchInstance;

	const classes = useStyles();

	const [minDuration, setMinDuration] = useState(15);
	const [startDateChanged, setStartDateChanged] = useState(false);
	const [endDateChanged, setEndDateChanged] = useState(false);

	const [chosenDuration, setChosenDuration] = useState(null);

	useEffect(() => {
		if (
			selectType === 'maintenance' &&
			isObject(selectedItem) &&
			!!selectedStartDate &&
			!!selectedEndDate &&
			isObject(selectedUser) &&
			validateUnavailabilityCheckDone
		) {
			setAvailableForMaintenance(validateUnavailabilityData?.allowed);
		} else {
			setAvailableForMaintenance(true);
		}
	}, [
		validateUnavailabilityCheckDone,
		bookingCategoryCheckData,
		selectedItem,
		selectedStartDate,
		selectedEndDate,
		selectedUser,
		fetchUnavailabilityData,
	]);

	useEffect(() => {
		if (
			!fetchItemPoliciesLoading &&
			isObject(presetData) &&
			isObject(selectedUser) &&
			presetData?.itemId &&
			!isObject(fetchItemPoliciesData)
		) {
			onFetchItemPolicies(presetData.itemId, selectedUser.id);
		}
	}, [presetData, selectedUser]);

	useEffect(() => {
		if (!open) {
			onResetState('maintenanceAvailability');
			onResetState('validateUnavailability');
			setStartDateChanged(false);
			setEndDateChanged(false);
			setChosenDuration(null);
			setMinDuration(15);
		}
	}, [open]);

	useEffect(() => {
		if (
			!startDateChanged &&
			!isSuperAdmin() &&
			!isProvider &&
			fetchItemPoliciesData?.requireHostConfirmation &&
			isEarlierThan(selectedStartDate, modifyDate(new Date(), { minutes: '+15' }))
		) {
			setIsExternalAdminError(true);
		} else {
			setIsExternalAdminError(false);
		}
	}, [isProvider, fetchItemPoliciesData, selectedStartDate, presetData?.start, startDateChanged]);

	useEffect(() => {
		if (isObject(bookingData)) {
			setSelectedStartDate(new Date(bookingData.periodStart));
			if (bookingData?.usageState === 'stopped') {
				setSelectedEndDate(new Date(bookingData.usage.stop));
			} else {
				setSelectedEndDate(new Date(bookingData.periodEnd));
			}

			setChosenDuration(
				durationToMinutes(
					duration(new Date(bookingData.periodStart), new Date(bookingData.periodEnd)),
				),
			);
			if (bookingData.billingType) {
				setSelectedPaymentMethod(bookingData.billingType);
			}
			if (bookingData?.comment) {
				setComment(bookingData.comment);
				setCommentLength(bookingData.comment.length);
			}
		} else if (presetData?.start) {
			setSelectedStartDate(new Date(presetData.start));
			setSelectedEndDate(new Date(presetData.end));
		} else if (isObject(fetchUnavailabilityData)) {
			setSelectedStartDate(new Date(fetchUnavailabilityData.start));
			setSelectedEndDate(new Date(fetchUnavailabilityData.end));
		}
	}, [bookingData, presetData, fetchUnavailabilityData]);

	useEffect(() => {
		if (isObject(fetchItemPoliciesData)) {
			setPolicies(fetchItemPoliciesData);
			if (fetchItemPoliciesData?.minBookingDurationMinutes) {
				setMinDuration(fetchItemPoliciesData?.minBookingDurationMinutes);
			}
		}
	}, [fetchItemPoliciesData]);

	useEffect(() => {
		if (
			isDate(selectedStartDate) &&
			isDate(selectedEndDate) &&
			(startDateChanged || endDateChanged)
		) {
			if (endDateChanged) {
				setChosenDuration(durationToMinutes(duration(selectedStartDate, selectedEndDate)));
			}
			if (startDateChanged && isNull(chosenDuration)) {
				setChosenDuration(durationToMinutes(duration(selectedStartDate, selectedEndDate)));
			}
			if (selectType === 'maintenance') {
				onValidateUnavailability(
					selectedItem?.instanceId,
					selectedItem?.itemId,
					selectedStartDate,
					selectedEndDate,
					fetchUnavailabilityData?.id,
				);
			}
		}

		if (startDateChanged) {
			setChosenDuration(durationToMinutes(duration(selectedStartDate, selectedEndDate)));
			if (
				isEarlierThan(selectedEndDate, selectedStartDate) ||
				areDatesEqual(selectedStartDate, selectedEndDate)
			) {
				setSelectedEndDate(null);
			}
		}

		if (startDateChanged) {
			setStartDateChanged(false);
		} else if (endDateChanged) {
			setEndDateChanged(false);
		}
	}, [selectedStartDate, selectedEndDate, startDateChanged, endDateChanged, chosenDuration]);

	useEffect(() => {
		if (
			isObject(selectedItem) &&
			!!selectedStartDate &&
			!!selectedEndDate &&
			!presetData?.unavailabilityId &&
			isObject(selectedUser)
		) {
			if (!instanceAvailabilityLoading && !presetData?.bookingId && selectType !== 'maintenance') {
				const isStartBigger = selectedStartDate.getTime() > selectedEndDate.getTime();
				const endDate =
					selectedStartDate.toISOString() === selectedEndDate.toISOString() || isStartBigger ?
						new Date(selectedStartDate.getTime() + 900000)
					:	selectedEndDate;
				onFetchItemInstanceAvailability(
					selectedItem.itemId,
					selectedItem.instanceId,
					selectedStartDate,
					endDate,
					selectedUser.id,
				);
			} else if (!maintenanceAvailabilityLoading && selectType === 'maintenance') {
				const isStartBigger = selectedStartDate.getTime() > selectedEndDate.getTime();

				const endDate =
					selectedStartDate.toISOString() === selectedEndDate.toISOString() || isStartBigger ?
						new Date(selectedStartDate.getTime() + 900000)
					:	selectedEndDate;
				onMaintenanceAvailability(
					selectedItem.itemId,
					selectedItem.instanceId,
					selectedStartDate,
					endDate,
				);
			}
		}
	}, [
		selectedItem,
		selectedStartDate,
		selectedEndDate,
		presetData,
		bookingData,
		fetchInstanceData,
		selectedUser,
	]);

	useEffect(() => {
		if (
			(!categoryCheckLoading || !bookingCategoryCheckLoading) &&
			isObject(selectedItem) &&
			!!selectedStartDate &&
			!!selectedEndDate &&
			isObject(selectedUser)
		) {
			const isStartBigger = selectedStartDate.getTime() > selectedEndDate.getTime();
			if (
				selectedUser?.clear &&
				!bookingCategoryCheckLoading &&
				!isStartBigger &&
				isObject(bookingData) &&
				bookingData.status !== 'completed'
			) {
				onValidateEditedBookingCategory(
					bookingData.id,
					isEarlierThan(selectedStartDate) ? null : selectedStartDate,
					selectedEndDate,
					bookingData.userReference.id === selectedUser.id ? null : selectedUser.id,
				);
			} else if (
				selectType === 'booking' &&
				!categoryCheckLoading &&
				!presetData?.bookingId &&
				!isStartBigger
			) {
				onValidateBookingCategory(
					selectedUser.id,
					selectedItem.categoryId,
					new Date(selectedStartDate).toISOString(),
					new Date(selectedEndDate).toISOString(),
				);
			}
		}
	}, [
		selectedItem,
		selectedStartDate,
		selectedEndDate,
		selectedUser,
		bookingData,
		presetData,
		fetchInstanceData,
	]);

	const handleNowSwitch = () => {
		setNowSwitch(!nowSwitch);
		if (!nowSwitch) {
			setSelectedStartDate(new Date());
		} else {
			setSelectedStartDate(bookingData?.periodStart ? new Date(bookingData?.periodStart) : null);
		}
		if (isObject(bookingData)) {
			setFetchBookingPrice(true);
		}
	};

	const updateStartDate = (date) => {
		setStartDateChanged(true);
		setSelectedStartDate(date);
		if (isObject(bookingData)) {
			setFetchBookingPrice(true);
		}
	};

	const getMinEndTime = () => {
		if (isEarlierThan(new Date(selectedStartDate))) {
			if (
				isDateToday(selectedEndDate) &&
				!isEarlierThan(modifyDate(new Date(selectedStartDate), { minutes: `+${minDuration}` }))
			) {
				return modifyDate(new Date(selectedStartDate), { minutes: `+${minDuration}` });
			} else if (
				!isDateToday(calendarDate) ||
				(!isDateToday(selectedEndDate) && isDateToday(calendarDate))
			) {
				return modifyDate(new Date(), {
					hours:
						isNull(selectedEndDate) || isDateToday(selectedEndDate) ?
							getDateRoundedToDuration(new Date(), 'PT15M')
						:	0,
					minutes: isNull(selectedEndDate) || isDateToday(selectedEndDate) ? `+${minDuration}` : 0,
				});
			} else {
				return getDateRoundedToDuration(new Date(), 'PT15M');
			}
		} else if (isNull(selectedStartDate)) {
			return modifyDate(getDateRoundedToDuration(new Date(), 'PT15M'), {
				minutes: `+${minDuration}`,
			});
		} else if (
			isNull(selectedEndDate) ||
			sameDates(unsetTime(selectedStartDate), unsetTime(selectedEndDate))
		) {
			return modifyDate(selectedStartDate, { minutes: `+${minDuration}` });
		} else {
			return modifyDate(new Date(), { hours: 0, minutes: 0 });
		}
	};

	const handleOnChangeEndTime = (date) => {
		let updatedDate = null;
		if (isDateToday(date) && isNull(selectedStartDate) && isDateToday(calendarDate)) {
			updatedDate = modifyDate(getDateRoundedToDuration(new Date(), 'PT15M'), {
				minutes: `+${minDuration}`,
			});
		} else if (
			isDateToday(calendarDate) &&
			sameDates(unsetTime(selectedStartDate), unsetTime(date)) &&
			isEarlierThan(date, modifyDate(selectedStartDate, { minutes: `+${minDuration}` }))
		) {
			updatedDate = modifyDate(selectedStartDate, { minutes: `+${minDuration}` });
		} else if (
			!isDateToday(calendarDate) &&
			isDateToday(date) &&
			isEarlierThan(date, selectedEndDate)
		) {
			updatedDate = getDateRoundedToDuration(new Date(), 'PT15M');
		} else if (isEarlierThan(date, selectedStartDate)) {
			updatedDate = modifyDate(selectedStartDate, {
				hours: date.getHours(),
				minutes: date.getMinutes(),
			});
		} else {
			updatedDate = date;
		}
		setEndDateChanged(true);
		setSelectedEndDate(updatedDate);
		if (isObject(bookingData)) {
			setFetchBookingPrice(true);
		}
	};

	const disabledStart =
		(isObject(bookingData) && bookingData.status !== 'upcoming') ||
		(isObject(fetchUnavailabilityData) && fetchUnavailabilityData.status !== 'upcoming');
	const disabledEnd =
		(isObject(bookingData) && bookingData.status === 'completed') ||
		(isObject(fetchUnavailabilityData) && fetchUnavailabilityData.status === 'completed');
	const isSwitch = presetData?.bookingId || presetData?.unavailabilityId;

	const isError =
		isFullString(durationErrors()) || !availableForMaintenance || isExternalAdminError;

	return (
		<Box pt={2}>
			<Box alignItems='baseline' display='flex' justifyContent='space-between'>
				<Typography className={classes.cardHeaders} variant='h5'>
					{t('ui.label.date&time')}
				</Typography>
				{(!isSwitch || ['upcoming'].includes(bookingData?.status)) && (
					<Box alignItems='center' display='flex'>
						<Typography variant='h5'>{t('ui.label.now')}</Typography>
						<Switch checked={nowSwitch} onChange={handleNowSwitch} />
					</Box>
				)}
			</Box>
			<Box>
				<Box className={classes.dateTimePickerContainer}>
					<ReactDatePicker
						containerClassName={classes.dateTimePicker}
						disabled={disabledStart || nowSwitch}
						error={isError}
						label={t('views.planboard.addBooking.placeholder.startDate')}
						maxTime={modifyDate(new Date(), { hours: 23, minutes: 45 })}
						minDate={new Date()}
						minTime={
							isNull(selectedStartDate) || isDateToday(selectedStartDate) ?
								getDateRoundedToDuration(new Date(), 'PT15M')
							:	modifyDate(new Date(), { hours: 0, minutes: 0 })
						}
						onChange={(date) =>
							updateStartDate(
								isDateToday(date) && isEarlierThan(date) ?
									getDateRoundedToDuration(new Date(), 'PT15M')
								:	date,
							)
						}
						selected={selectedStartDate}
						showTimeSelect
						wrapperClassName={classes.fullWidth}
					/>
					<ReactDatePicker
						disabled={disabledEnd}
						error={isError}
						highlightDates={undefined}
						label={t('views.planboard.addBooking.placeholder.endDate')}
						maxTime={modifyDate(new Date(), { hours: 23, minutes: 45 })}
						minDate={selectedStartDate ? selectedStartDate : new Date()}
						minTime={getMinEndTime()}
						onChange={handleOnChangeEndTime}
						selected={selectedEndDate}
						showTimeSelect
						wrapperClassName={classes.fullWidth}
					/>
				</Box>
				<Typography className={clsx({ [classes.helperText]: true, [classes.errorText]: isError })}>
					{isError ? durationErrors() : ''}
				</Typography>
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => {
	return {
		planboardBooking: state.details.planboardBooking,
		fetchItemPolicies: state.details.fetchItemPolicies,
		validatedBookingCategory: state.details.validatedBookingCategory,
		validatedEditedBookingCategory: state.details.validatedEditedBookingCategory,
		fetchInstance: state.details.fetchInstance,
		fetchUnavailability: state.details.fetchUnavailability,
		validateUnavailability: state.details.validateUnavailability,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItemPolicies: (itemId, userId) => dispatch(actions.fetchItemPolicies(itemId, userId)),
		onResetState: (state) => dispatch(actions.resetState(state)),
		onFetchItemInstanceAvailability: (itemId, instanceId, startDate, endDate, userId) =>
			dispatch(
				actions.fetchItemInstanceAvailability(itemId, instanceId, startDate, endDate, userId),
			),
		onMaintenanceAvailability: (itemId, instanceId, startDate, endDate) =>
			dispatch(actions.maintenanceAvailability(itemId, instanceId, startDate, endDate)),
		onValidateBookingCategory: (userId, categoryId, periodStart, periodEnd) =>
			dispatch(actions.validateBookingCategory(userId, categoryId, periodStart, periodEnd)),
		onValidateEditedBookingCategory: (bookingId, periodStart, periodEnd, userId) =>
			dispatch(actions.validateEditedBookingCategory(bookingId, periodStart, periodEnd, userId)),
		onValidateUnavailability: (itemInstanceId, itemId, periodStart, periodEnd, unavailabilityId) =>
			dispatch(
				actions.validateUnavailability(
					itemInstanceId,
					itemId,
					periodStart,
					periodEnd,
					unavailabilityId,
				),
			),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeCard);
