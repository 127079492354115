import { useRef, useState } from 'react';

import path from 'path';

import { MailOutline as MailOutlineIcon } from '@mui/icons-material';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog } from '~components';
import { generalInformation, pagePathSegments } from '~constants';
import { useAuthorize } from '~features/authentication';

import SsoDomainsDataGrid from '../components/dataGrids/ssoDomainsDataGrid';
import OrganisationsService from '../services/organisationsService';
import { useSnackbar } from '~hooks';
import { ListMutationRefProps } from '~interfaces/refProps';

const service = new OrganisationsService();

type UserAction =
	| {
			type: 'delete';
			domainName: string;
	  }
	| {
			type: 'create';
			domainName?: never;
	  };

interface SsoDomainsLayoutProps {
	organisationId: string;
}

const SsoDomainsLayout = ({ organisationId }: SsoDomainsLayoutProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { isSuperAdmin } = useAuthorize();
	const { enqueueSuccessSnackbar, enqueueAxiosErrorSnackbar } = useSnackbar();
	const dataGridRef = useRef<ListMutationRefProps>(null);

	const { data: ssoDomainsData } = useSWR(
		[`${service.path}.ssodomains`, organisationId],
		([_, id]) => service.getSsoDomains({ organisationId: id }),
	);

	const { data: organisation } = useSWR([service.basePath, organisationId], ([_, id]) =>
		service.getOrganisationById(id),
	);

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		[`${service.basePath}.ssoDomains`, organisationId],
		([_, id], { arg }) => service.deleteSsoDomain(id, arg),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(`${t('ui.successfullyDeleted')} ${t('ssoDomain').toLowerCase()}`);
				setUserAction(null);
				dataGridRef.current?.mutate();
			},
			onError: (error) => enqueueAxiosErrorSnackbar(error),
		},
	);

	const handleAdd = () => {
		navigate(pagePathSegments.Add);
	};

	if (!organisation?.ssoDomainSignupOnly || ssoDomainsData?.results.length <= 0) {
		return (
			<Card>
				<CardContent
					sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 1, p: 3 }}
				>
					<MailOutlineIcon sx={{ fontSize: '100px' }} />
					<Stack spacing={1} sx={{ alignItems: 'inherit' }}>
						<Typography variant='h6'>{t('noSsoDomainsAdded')}</Typography>
						<Typography variant='body2'>
							{t(
								isSuperAdmin() ?
									'addSsoSignupDomainFirstTimeDescription'
								:	'addSsoSignupDomainFirstTimeAdminDescription',
							)}
						</Typography>

						{isSuperAdmin() ?
							<Button
								disabled={!organisation?.ssoDomainSignupOnly}
								variant='contained'
								onClick={handleAdd}
								color='secondary'
							>
								{t('addResource', { resource: t('ssoDomains') })}
							</Button>
						:	<Typography>{generalInformation.infoTopologyEmail}</Typography>}

						{isSuperAdmin() && !organisation?.ssoDomainSignupOnly && (
							<Typography variant='body2'>
								{t('views.emailDomains.noEmailDomains.domainEnabled')}
							</Typography>
						)}
					</Stack>
				</CardContent>
			</Card>
		);
	}

	return (
		<>
			<SsoDomainsDataGrid
				ref={dataGridRef}
				organisationId={organisationId}
				onCreate={handleAdd}
				onDelete={(value) => setUserAction({ type: 'delete', domainName: value })}
			/>
			<ConfirmationDialog
				subTitle={t('thisWillDeleteResource', { resource: t('ssoDomain') })}
				loading={isDeleteMutating}
				open={userAction?.type === 'delete'}
				onConfirm={async () =>
					userAction?.domainName != null && (await triggerDelete(userAction.domainName))
				}
				onClose={() => setUserAction(null)}
			/>
		</>
	);
};

export default SsoDomainsLayout;
