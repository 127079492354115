import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Card, CardContent, IconButton, Typography } from '@mui/material';

import { useStyles } from './style';
import { isFullString, getInitials } from '../../../shared/utility';

interface UserCardProps {
	user?: object;
	userImage?: unknown[];
	hasButton?: boolean;
	onClick?(...args: unknown[]): unknown;
}

const UserCard = (props: UserCardProps) => {
	const { user, userImage, hasButton, onClick } = props;
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Box alignItems='center' display='flex'>
					{isFullString(userImage[0]) ?
						<Avatar src={userImage[0]} variant='circular' />
					:	<Avatar variant='circular'>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>}
					<Typography
						className={classes.label}
						variant='h6'
					>{`${user.firstName} ${user.lastName}`}</Typography>
				</Box>
				{hasButton ?
					<IconButton
						className={classes.iconButton}
						disableRipple={true}
						onClick={onClick}
						size='large'
					>
						<ArrowForwardIosIcon fontSize='small' />
					</IconButton>
				:	null}
			</CardContent>
		</Card>
	);
};

export default UserCard;
