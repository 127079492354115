import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack, Typography, useTheme } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem, WeekToggleButtonGroup } from '~components';
import { DayOfWeekEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { BaseReference, WeeklySchedule } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';
import { time24WithoutSecondsFormatter } from '~utils/dateUtils';

import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import SkcSchedule from '../../interfaces/skcSchedule';
import SkcSchedulesService from '../../services/skcSchedulesService';
import SelectOrganisationOverlay from './selectOrganisationOverlay';

const toolbarIdString = 'topology-participant-toolbar';

const service = new SkcSchedulesService();

interface SchedulesDataGridProps {
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const SchedulesDataGrid = forwardRef<ListMutationRefProps, SchedulesDataGridProps>(
	({ onEdit, onDelete, onCreate }, ref) => {
		const { t } = useTranslation('general');
		const theme = useTheme();
		const location = useLocation();

		const { isSuperAdmin } = useAuthorize();
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [fetchParameters, setFetchParameters] = useState<object | null>({
			organisationId: selectedOrganisation?.id,
		});

		const columns = useMemo<GridColDef<SkcSchedule>[]>(
			() => [
				{
					field: 'id',
					headerName: 'ID',
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={params.value.toString()}
							state={{ from: location.pathname }}
						>
							{params.value}
						</MuiLink>
					),
					width: 80,
					hideable: false,
				},
				{
					field: 'label',
					headerName: t('ui.label.name'),
					flex: 1,
				},
				{
					field: 'description',
					headerName: t('ui.label.description'),
					valueFormatter: (value: string) => value ?? '-',
					flex: 1,
				},
				{
					field: 'open',
					headerName: t('ui.open'),
					valueGetter: (_, row) => row.schedule,
					valueFormatter: (value: WeeklySchedule) =>
						value.isOpenAllDay ?
							t('ui.allDay')
						:	`${time24WithoutSecondsFormatter.format(value.period.start)} - ${time24WithoutSecondsFormatter.format(value.period.end)}`,
					flex: 1,
				},
				{
					field: 'organisation',
					headerName: t('organisation'),
					valueGetter: () => selectedOrganisation,
					valueFormatter: (value: BaseReference) => value?.label ?? '-',
					flex: 1,
				},
				{
					field: 'daysOfWeek',
					headerName: t('daysOfWeek'),
					valueOptions: Object.values(DayOfWeekEnum),
					valueGetter: (_, row) => row.schedule.daysOfWeek,
					renderCell: (params) => (
						<WeekToggleButtonGroup disabled size='small' value={params.value} />
					),
					type: 'singleSelect',
					flex: 1,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-edit`}
							icon={<EditIcon />}
							label={t('ui.edit')}
							onClick={() => onEdit?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
						<GridActionsCellItem
							key={`${params.id}-delete`}
							icon={<DeleteOutlinedIcon color='error' />}
							label={t('ui.delete')}
							onClick={() => onDelete?.({ id: params.id.toString(), label: params.row.label })}
							color='error'
							showInMenu
						/>,
					],
				},
			],
			[selectedOrganisation],
		);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters?.organisationId != null ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getSchedules(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setSelectedOrganisation(organisation);
			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				<Box
					id={toolbarIdString}
					sx={{
						minHeight: 48,
					}}
				/>
				<DataGrid
					disableColumnSorting
					snapshotKey='accs'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					// columnVisibilityModel={columnVisibilityModel}
					// onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					initialState={{
						filter: {
							filterModel: {
								items:
									selectedOrganisation ?
										[
											{
												field: 'organisation',
												operator: 'contains',
												value: selectedOrganisation,
											},
										]
									:	[],
							},
						},
					}}
					slots={{
						toolbar: Toolbar,
						...(selectedOrganisation == null && {
							noRowsOverlay: SelectOrganisationOverlay,
						}),
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate,
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('schedules') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('schedules') }),
						},
					}}
					sx={{ flexGrow: 1, flexBasis: 200 }}
				/>
			</Stack>
		);
	},
);
SchedulesDataGrid.displayName = 'AccessSchedulesDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar disableSearch disableClearableOrganisation>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', { resource: t('schedule') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default SchedulesDataGrid;
