import { useState, useEffect, useRef } from 'react';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Link, Avatar, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useAuthorize } from '~features/authentication';
import { useDebounce } from '~hooks';

import Header from './Header';
import MonthlyFinance from './MonthlyFinance';
import { useStyles } from './style';
import {
	Table,
	Label,
	SearchBar,
	GenericMoreButton,
	TextDialog,
	Page,
} from '../../../../components';
import { exportType } from '../../../../constantsOld';
import { localizeDateTime } from '../../../../shared/datetime';
import { useError } from '../../../../shared/hooks';
import {
	isObject,
	getInitials,
	isEmptyString,
	decimalBase,
	isFullString,
	isNull,
	isNumber,
	isEmptyArray,
	decimalAmount,
	getFinanceTableHeaders,
} from '../../../../shared/utility';
import * as actions from '../../../../store/actions';

interface OrganisationalBillingProps {
	onFetchBusinessRevenue?(...args: unknown[]): unknown;
	businessRevenue?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onFetchPersonalBillingPeriodByDate?(...args: unknown[]): unknown;
	onBillingReport?(...args: unknown[]): unknown;
	billingReport?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onPayBillingPeriod?(...args: unknown[]): unknown;
	payBillingPeriod?: object;
}

const OrganisationalBilling = (props: OrganisationalBillingProps) => {
	const {
		onFetchBusinessRevenue,
		businessRevenue,

		onPayBillingPeriod,
		payBillingPeriod,
		onBillingReport,
		billingReport,
		match,
	} = props;
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const { date, title, id, name } = useParams();

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [download, setDownload] = useState(false);
	const [exportTypeLocal, setExportTypeLocal] = useState('csv');
	const [itemToDownload, setItemToDownload] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [shouldSearch, setShouldSearch] = useState(false);
	const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [totalAmount, setTotalAmount] = useState({});
	const linkRef = useRef(null);
	const [billingOrganisation, setBillingOrganisation] = useState(null);
	const [billingId, setBillingId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [monthlyTotal, setMonthlyTotal] = useState('');
	const [total, setTotal] = useState('');
	const payBillingMessage = useError({
		value: payBillingPeriod,
		message: `${t('views.finance.organisationalBilling.payBillingMessage')} ${decimalBase(totalAmount.price, totalAmount.currency)} ${t('ui.to')} ${billingOrganisation}`,
	});

	const { data: businessRevenueData, loading: businessRevenueLoading } = businessRevenue;

	const { data: CVSReportBlob, loading: CVSReportLoading } = billingReport;

	const shouldSearchOnDebounce = false;

	const filters = {
		...(!isEmptyString(searchValue) && { searchTerm: searchValue }),
		...(isFullString(id) && { id: id }),
	};

	useEffect(() => {
		if (shouldSearch || shouldDoInitialFetch) {
			if (shouldSearch && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (date && !businessRevenueLoading) {
				onFetchBusinessRevenue(date, filters);
			}
		}
		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}
	}, [date, shouldSearch, shouldDoInitialFetch]);

	useEffect(() => {
		if (payBillingPeriod.loading) {
			setLoading(true);
		} else if (!payBillingPeriod.loading && payBillingPeriod.success) {
			setLoading(false);
			setOpenDialog(false);
		}
	}, [payBillingPeriod.loading]);

	const handleSearchClick = () => {
		if (!isEmptyString(searchValue)) {
			setShouldSearch(true);
			setIsShowingSearchResults(true);
		}
		if (isEmptyString(searchValue) && isShowingSearchResults) {
			setShouldSearch(true);
			setIsShowingSearchResults(false);
		}
	};

	const handleResetSearch = () => {
		setSearchValue('');
		if (isShowingSearchResults === true) {
			setShouldSearch(true);
		}
		setIsShowingSearchResults(false);
	};

	const handleKeyUp = (e) => {
		switch (e.key) {
			case 'Enter':
				handleSearchClick();
				break;
			default:
				return;
		}
	};

	const events = {
		onClick: handleSearchClick,
		onChange: (e) => setSearchValue(e.target.value),
		onClear: handleResetSearch,
		onKeyUp: (e) => handleKeyUp(e),
	};

	const debouncedSearchValue = useDebounce(searchValue, 500);

	useEffect(() => {
		if (shouldSearchOnDebounce) {
			handleSearchClick();
		}
	}, [shouldSearchOnDebounce, debouncedSearchValue]);

	const organisationId = isSuperAdmin() ? parseInt(id) : null;

	const handleClickDownloadReport = (billingItem, type) => {
		onBillingReport('revenue', date, billingItem.organisationReference.id, organisationId, type);
		setDownload(true);
		setExportTypeLocal(type);
		setItemToDownload(billingItem);
	};

	const handlePaymentStatusChange = (billingItem) => {
		setTotalAmount({
			price: billingItem.amount.totalPrice,
			currency: billingItem.amount.currencySymbol,
		});
		setOpenDialog(true);
		setBillingOrganisation(billingItem.organisationReference.name);
		setBillingId(billingItem.id);
	};

	useEffect(() => {
		if (!CVSReportLoading && !isNull(CVSReportBlob) && download) {
			const itemToDownloadLocal = `-${localizeDateTime(itemToDownload.end)}`;
			const instanceName = `${itemToDownload.organisationReference ? itemToDownload.organisationReference.name : itemToDownload.userReference.name}_${localizeDateTime(itemToDownload.start)}${itemToDownload.end === '0001-01-01T00:00:00' ? '' : itemToDownloadLocal}`;
			linkRef.current.href = URL.createObjectURL(CVSReportBlob);
			linkRef.current.download = `${instanceName}.${exportTypeLocal}`;
			linkRef.current.click();
			setDownload(false);
		}
	}, [CVSReportBlob, download]);

	useEffect(() => {
		if (isObject(businessRevenueData) && isNumber(monthlyTotal)) {
			setTotal(
				`${decimalBase(monthlyTotal + (businessRevenueData.amount.subtotal - businessRevenueData.amount.discount), businessRevenueData.amount.currencySymbol)}`,
			);
		}
	}, [monthlyTotal, businessRevenueLoading]);

	const createMoreMenuItems = (billingItem) => [
		{
			icon: <InsertDriveFileOutlinedIcon />,
			text: t('ui.downloadReport.csv'),
			action: () => handleClickDownloadReport(billingItem, exportType.CSV),
		},
		{
			icon: <InsertDriveFileOutlinedIcon />,
			text: t('ui.downloadReport.xlsx'),
			action: () => handleClickDownloadReport(billingItem, exportType.XLSX),
		},
		...(billingItem.status === 'open' ?
			[
				{
					icon: <AssignmentTurnedInIcon />,
					text: t('views.tableResults.revenue.expenses.statusToPaid'),
					action: () => handlePaymentStatusChange(billingItem),
				},
			]
		:	[]),
	];

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleConfirmPay = () => {
		if (isNumber(billingId)) {
			onPayBillingPeriod(billingId);
		}
		payBillingMessage.setStartAction(true);
	};

	const headers = getFinanceTableHeaders(true);

	const tableHeader = [{ name: 'partners', content: t('ui.category.partners') }, ...headers];

	const statusColor = {
		open: 'default',
		paid: 'success',
	};

	const statusText = {
		open: t('ui.open'),
		paid: t('ui.label.paid'),
	};
	// decimalBaseRow
	const emptyBody =
		(
			isObject(businessRevenueData) &&
			!businessRevenueLoading &&
			isEmptyArray(businessRevenueData.billings)
		) ?
			Array(1)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ content: '-' })))
		:	null;

	const loadingBody =
		!isObject(businessRevenueData) || businessRevenueLoading ?
			Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })))
		:	null;

	const dataBody =
		isObject(businessRevenueData) && !businessRevenueLoading ?
			businessRevenueData.billings.map((revenue) => [
				{
					content: (
						<Box className={classes.cell}>
							{revenue.organisationReference.logo ?
								<Avatar src={revenue.organisationReference.logo} variant='square' />
							:	<Avatar variant='square'>{getInitials(revenue.organisationReference.name)}</Avatar>}
							<Box pl={1}>
								<Link
									className={classes.link}
									color='inherit'
									component={RouterLink}
									to={`/financeManagement/revenue/${revenue.start}/${title}/partner/${revenue.organisationReference.id}/${revenue.organisationReference.name}/bookings`}
									state={{
										organisationId: id,
										title: title,
										name: revenue.organisationReference.name,
									}}
								>
									{`${revenue.organisationReference.name}`}
								</Link>
							</Box>
						</Box>
					),
				},
				{ content: decimalAmount(revenue.amount.subtotal, revenue.amount) },
				{ content: decimalAmount(revenue.amount.discount, revenue.amount) },
				{
					content: decimalAmount(revenue.amount.subtotal - revenue.amount.discount, revenue.amount),
				},
				{ content: decimalAmount(revenue.amount.priceVat, revenue.amount) },
				{ content: decimalAmount(revenue.amount.refund, revenue.amount) },
				{ content: decimalAmount(revenue.amount.totalPrice, revenue.amount) },
				{ content: decimalAmount(revenue.open, revenue.amount) },

				{
					content: (
						<Box alignItems='center' display='flex' justifyContent='space-between'>
							<Label type={statusColor[revenue.status]}>{statusText[revenue.status]}</Label>
							<GenericMoreButton menuItems={createMoreMenuItems(revenue)} />
						</Box>
					),
				},
			])
		:	Array(3)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const titleWithInformation =
		isObject(businessRevenueData) ?
			{
				header: t('ui.category.organisations'),
				sideHeader: decimalAmount(
					businessRevenueData.amount.subtotal - businessRevenueData.amount.discount,
					businessRevenueData.amount,
				),
			}
		:	null;

	return (
		<Page className={classes.root} title={t('ui.details')}>
			<Header date={date} id={id} name={name} title={title} total={total} />
			<Box pb={3} pt={3}>
				<MonthlyFinance
					date={date}
					id={id}
					name={name}
					setMonthlyTotal={setMonthlyTotal}
					title={title}
				/>
			</Box>
			<a ref={linkRef} style={{ display: 'none' }} />
			<SearchBar
				hasSearchButton
				placeholder={t('views.usersAndOrganisations.organisations.searchPlaceholder')}
				searchEvents={events}
				searchValue={searchValue}
			/>
			<Table
				body={loadingBody || emptyBody || dataBody}
				cellStyle={classes.cellStyle}
				header={tableHeader}
				headerLabel={t('ui.label.finance.totaExclVat')}
				isNotPaginate={true}
				loading={businessRevenueLoading}
				noTitle={false}
				titleWithInformation={titleWithInformation}
				withoutPages={true}
			/>
			<TextDialog
				content={
					<Box>
						<Box pb={2}>
							<Typography>{`${t('views.finance.organisationalBilling.textDialog.toPay')}: ${isNumber(totalAmount.price) && decimalBase(totalAmount.price, totalAmount.currency)}`}</Typography>
						</Box>
						<Paper className={classes.tipCard}>
							<Box alignContent='center' display='flex'>
								<Box pr={1.3}>
									<InfoIcon fontSize='small' />
								</Box>
								<Typography variant='body2'>
									{t('views.finance.organisationalBilling.textDialog.description')}
								</Typography>
							</Box>
						</Paper>
					</Box>
				}
				disabled={false}
				handleClose={handleClose}
				handleConfirm={handleConfirmPay}
				isLoading={true}
				isText={true}
				loading={loading}
				open={openDialog}
				subTitle={`${title} - ${isFullString(billingOrganisation) && billingOrganisation}`}
				title={`${t('views.finance.organisationalBilling.textDialog.header')}:`}
			/>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		businessRevenue: state.details.businessRevenue,

		payBillingPeriod: state.condition.payBillingPeriod,
		billingReport: state.blob.billingReport,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchBusinessRevenue: (date, filters) =>
			dispatch(actions.fetchBusinessRevenue(date, filters)),
		onPayBillingPeriod: (billingId) => dispatch(actions.payBillingPeriod(billingId)),
		onBillingReport: (type, dateTime, id, organisationId, exportType) =>
			dispatch(actions.billingReport(type, dateTime, id, organisationId, exportType)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationalBilling);
