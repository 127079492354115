import { Fragment, Suspense } from 'react';

import { LinearProgress } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useStyles } from './style';
import Topbar from './Topbar';

interface AuthProps {
	route?: object;
}

/**
 * See authentication layout
 * @param props
 * @returns
 * @deprecated
 */
const Auth = (props: AuthProps) => {
	const classes = useStyles();

	return (
		<Fragment>
			<Topbar />
			<main className={classes.content}>
				<Outlet />
			</main>
		</Fragment>
	);
};

export default Auth;
