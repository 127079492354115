import path from 'path';

import axios, { AxiosInstance } from 'axios';
import { User } from 'oidc-client-ts';

import { Organisation } from '~features/organisations';
import { IdReference, PagedResults } from '~interfaces';
import { IdReferenceResponse, SkcPagedResponse, SkcSingleResponse } from '~interfaces/responses';

import BaseService from './baseService';

/**
 * This service is used to get data from Skopei Connect, but use the
 * Topology backend as a reverse proxy to do that.
 * The Topology backend may do a small bit of logic, but it should reflect
 * communication with the Skopei Connect API as much as possible.
 */
abstract class TopologyProxiedSkopeiConnectService extends BaseService {
	protected _client: AxiosInstance;

	protected readonly apiVersionPath: string;
	protected readonly path: string = '';

	public organisation?: Organisation = undefined;

	public get basePath(): string {
		return path.join(this.apiVersionPath, this.path);
	}

	constructor(version: 'v1' = 'v1') {
		super();

		this.apiVersionPath = path.join('skc', version);
		this._client = axios.create({
			baseURL: new URL(this.apiVersionPath, import.meta.env.VITE_TOPOLOGY_API_URL).href,
			// Allows the the requests to be cancelled
			signal: this._controller.signal,
		});

		this.initInterceptor();
	}

	/**
	 * Abort the current get requests.
	 * @param reason A reason to abort, e.g. for logging
	 */
	public abortCurrentRequest(reason?: string) {
		this._controller.abort(reason);
		this._controller = new AbortController();

		// Reattach it to the client so we can abort the next request
		this._client.defaults.signal = this._controller.signal;
	}

	/**
	 * If interceptors are defined in the /src/lib folder
	 * that won't work for axios instances. We really have to set it
	 * on the instance
	 * We add the token though an interceptor because if we set it directly
	 * on the axios instance and the token expires? How to refresh? This
	 * seems the more easy and stable way to do it
	 */
	private initInterceptor() {
		const identityAuthority = import.meta.env.VITE_IDENTITY_AUTHORITY.replace(/\/$/, '');
		const oidcStorageString = `oidc.user:${identityAuthority}:${import.meta.env.VITE_IDENTITY_CLIENT_ID}`;

		this._client.interceptors.request.use((config) => {
			// Determine the data size
			// if (!config.headers['Prefer']) {
			// 	config.headers['Prefer'] = 'return=Minimal';
			// }

			const oidcStorage = localStorage.getItem(oidcStorageString);
			if (oidcStorage) {
				const user = User.fromStorageString(oidcStorage);
				config.headers['Authorization'] = `Bearer ${user.access_token}`;

				// 20240919 The proxy expects the following:
				// - If admin, any added organisationId parameter will return a forbidden. So remove it,
				//   the authorization and scope is determined by jwt
				// - If superadmin, an organisationId needs to be provided. Or otherwise it defaults
				//   to the organisation in the jwt. But for superadmins this is always Topology for some
				// 	 reason
				const role = user.profile.role.toLowerCase();
				if (role === 'admin' && config.params?.organisationId) {
					delete config.params.organisationId;
				} else if (role === 'superadmin' && this.organisation && !config.params?.organisationId) {
					config.params = { ...config.params, organisationId: this.organisation.id };
				}
			}

			return config;
		});
	}

	protected mapIdResponse(response: SkcSingleResponse<IdReferenceResponse>): IdReference {
		return {
			id: response.data.id.toString(),
		};
	}

	/**
	 * Work in progress
	 * To map a response in one go.
	 * @param response
	 * @param mapFunction
	 * @returns
	 */
	protected mapPagedResponse<T extends object, U extends object>(
		response: SkcPagedResponse<T>,
		mapFunction: (value: T) => U,
	): PagedResults<U> {
		const total = response.meta.totalRecordCount < 0 ? 0 : response.meta.totalRecordCount;
		const count = (response.meta.pageNumber - 1) * response.meta.pageSize + response.data.length;

		return {
			hasMore: count < total,
			total: total,
			pageNumber: response.meta.pageNumber,
			pageSize: response.meta.pageSize,
			results: response.data.map((el) => mapFunction(el)),
		};
	}
}

export default TopologyProxiedSkopeiConnectService;
