import { useEffect, useMemo, useState } from 'react';

import path from 'path';

import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DataGrid } from '~components';
import { ItemCategoryEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';

import BookingStatisticsService from '../../services/bookingStatisticsService';

interface BookingStat {
	category: ItemCategoryEnum;
	bookingCount: number;
	duration: number;
	uniqueUserCount: number;
	extendedCount: number;
	noShowCount: number;
	cancelledCount: number;
}

const service = new BookingStatisticsService();

interface BookingStatisticsDataGridProps {
	organisationId?: string;
	period?: DateRange;
	itemId?: string;
	hubId?: string;
	categoryId?: string;
	partnerId?: string;
}

const BookingStatisticsDataGrid = (props: BookingStatisticsDataGridProps) => {
	const { t } = useTranslation('general');

	const columns = useMemo<GridColDef<BookingStat>[]>(
		() => [
			{
				field: 'category',
				headerName: t('ui.label.category'),
				flex: 1,
				valueFormatter: (value: ItemCategoryEnum) => t(`ui.label.${value}`),
			},
			{
				field: 'bookingCount',
				headerName: t('ui.category.bookings'),
				flex: 1,
			},
			{
				field: 'duration',
				headerName: t('duration'),
				flex: 1,
				valueFormatter: (value: number) => {
					const hourCount = Math.floor(value / 60);
					return `${hourCount} ${t('hours', { count: hourCount }).toLowerCase()}`;
				},
			},
			{
				field: 'averageDuration',
				headerName: t('averageDuration'),
				flex: 1,
				valueGetter: (_, row) => row.duration / row.bookingCount,
				valueFormatter: (value: number) => {
					const hourCount = Math.floor(value / 60);
					const minuteCount = Math.ceil(value % 60);
					const hourCountString =
						hourCount >= 1 ? `${hourCount} ${t('hours', { count: hourCount }).toLowerCase()} ` : '';
					return `${hourCountString} ${minuteCount} ${t('minutes', { count: minuteCount }).toLowerCase()}`;
				},
			},
			{
				field: 'uniqueUsers',
				headerName: t('uniqueUsers'),
				flex: 1,
			},
			{
				field: 'extended',
				headerName: t('extended'),
				flex: 1,
			},
			{
				field: 'noShow',
				headerName: t('ui.status.noShow'),
				flex: 1,
			},
			{
				field: 'cancelled',
				headerName: t('ui.label.cancelled'),
				flex: 1,
			},
		],
		[],
	);

	const [fetchParameters, setFetchParameters] = useState(props);
	const { data, error, isLoading, isValidating } = useSWR(
		[path.join(service.basePath, 'list'), fetchParameters],
		([_, args]) => service.getCategoryBookingCounts(args),
	);

	useEffect(() => {
		setFetchParameters((prev) => ({
			...prev,
			...props,
		}));
	}, [props]);

	return (
		<DataGrid
			getRowId={(row) => row.category}
			paginationMode='client'
			hideFooterPagination
			error={error}
			loading={isLoading || isValidating}
			columns={columns}
			rows={data ?? []}
			disableColumnSorting
			sx={{ flexGrow: 1, flexBasis: 250 }}
		/>
	);
};

export default BookingStatisticsDataGrid;
