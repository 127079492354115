import { useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid2, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { PeriodicityEnum } from '~enums';
import {
	RevenuesLineChart,
	BillingPeriodCountBarChart,
	ItemRevenueStatisticsDataGrid,
} from '~features/finances';
import { useUrlSearchParamsOrganisation } from '~hooks';
import { DateRange } from '~interfaces/dateRanges';

import StatisticsToolbar from '../components/toolbars/statisticsToolbar';
import useUrlSearchParamsDate from '../hooks/useUrlSearchParamsDate';
import useUrlSearchParamsPeriodicity from '../hooks/useUrlSearchParamsPeriodicity';
import StatisticsFilters from '../interfaces/statisticsFilters';

const FinanceStatisticsLayout = () => {
	const { t } = useTranslation('general');

	const [organisation] = useUrlSearchParamsOrganisation();

	const [date, setDate] = useUrlSearchParamsDate();
	const [periodicity, setPeriodicity] = useUrlSearchParamsPeriodicity();
	const [filters, setFilters] = useState<StatisticsFilters>({});

	const getPeriodAsDate = (
		date: Dayjs,
		periodicity: PeriodicityEnum,
		monthRange = 1,
	): DateRange => {
		const period = getPeriod(date, periodicity, monthRange);
		return {
			start: period.start.toDate(),
			end: period.end.toDate(),
		};
	};

	const getPeriod = (
		date: Dayjs,
		periodicity: PeriodicityEnum,
		monthRange = 1,
	): DateRange<Dayjs> => {
		// Notice, the end of is to allow some sort of API caching, we need some stable query
		// parameter that doesn't change every selection
		const end =
			date > dayjs().startOf('month') ?
				dayjs().endOf('day')
			:	date.endOf(periodicity === PeriodicityEnum.Month ? 'month' : 'year');

		return {
			start: end
				.subtract(periodicity === PeriodicityEnum.Month ? monthRange - 1 : 11, 'months')
				.startOf('month'),
			end: end,
		};
	};

	return (
		<Stack spacing={2} sx={{ height: 1 }}>
			<StatisticsToolbar
				organisationId={organisation?.id}
				date={date}
				onDateChange={(value) => setDate(value)}
				periodicity={periodicity}
				onPeriodicityChange={(value) => setPeriodicity(value)}
				filters={filters}
				onFiltersChange={(value) => setFilters(value)}
			/>
			<Grid2 container spacing={2}>
				<Grid2 size='grow'>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('nav.financialReports.revenue')} />
						<CardContent sx={{ width: 1, height: 400 }}>
							<RevenuesLineChart
								periodicity={periodicity}
								organisationId={organisation?.id}
								period={getPeriod(date, periodicity, 3)}
								categoryId={filters.category?.id}
								itemId={filters.item?.id}
								hubId={filters.hub?.id}
							/>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 sx={{ width: { xs: 1, sm: 1, md: 400 } }}>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('payments')} />
						<CardContent sx={{ width: 1, height: 400 }}>
							<BillingPeriodCountBarChart
								organisationId={organisation?.id}
								period={getPeriodAsDate(date, periodicity, 1)}
							/>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 size={12} sx={{ width: 1, minHeight: 400 }}>
					<ItemRevenueStatisticsDataGrid
						organisationId={organisation?.id}
						period={getPeriodAsDate(date, periodicity, 1)}
						categoryId={filters.category?.id}
						itemId={filters.item?.id}
						hubId={filters.hub?.id}
					/>
				</Grid2>
			</Grid2>
		</Stack>
	);
};

export default FinanceStatisticsLayout;
