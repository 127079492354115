import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import {
	AccordionDetails,
	Box,
	Card,
	CardContent,
	CircularProgress,
	Divider,
	IconButton,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStyles, Accordion, AccordionSummary } from './style';
import {
	FormField,
	Page,
	SelectWithLazyLoading,
	SidePanel,
	StyledButton,
} from '../../../../../../components';
import {
	useDebouncedBackendFieldCheck,
	useError,
	useWizardFormField,
} from '../../../../../../shared/hooks';
import { isEmptyString, isFullArray, isObject } from '../../../../../../shared/utility';
import * as actions from '../../../../../../store/actions';
import path from 'path';
import { pagePathSegments } from '~constants';

interface AddEmailDomainsProps {
	organisationId?: string;
	addEmailDomains?: {
		success?: boolean;
		loading?: boolean;
		error?: object | string;
	};
	userGroups?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	validateEmailDomain?: {
		data?: object;
		loading?: boolean;
		error?: object | string;
	};
	onAddEmailDomains?(...args: unknown[]): unknown;
	onFetchUserGroups?(...args: unknown[]): unknown;
	onValidateEmailDomain?(...args: unknown[]): unknown;
}

const AddEmailDomains = (props: AddEmailDomainsProps) => {
	const {
		userGroups,
		onFetchUserGroups,
		addEmailDomains,
		onAddEmailDomains,
		validateEmailDomain,
		onValidateEmailDomain,
	} = props;
	const { t } = useTranslation('general');
	const location = useLocation();

	const classes = useStyles();
	const navigate = useNavigate();

	const { id: organisationId } = useParams();

	const handleBackButtonChange = () => {
		if (!organisationId) {
			console.warn('No organisation id available for redirecting');
			return;
		}

		navigate(
			`/${path.join(pagePathSegments.Organisations, organisationId, pagePathSegments.EmailDomains)}`,
		);
	};

	const {
		success: addEmailDomainsSuccess,
		loading: addEmailDomainsLoading,
		error: addEmailDomainsError,
	} = addEmailDomains;
	const addEmailDomainsDone =
		!addEmailDomainsLoading && !addEmailDomainsError && addEmailDomainsSuccess;

	const currentEmailDomain = useWizardFormField('@', { required: true, isEmailDomain: true });
	const [currentUserGroup, setCurrentUserGroup] = useState({ name: '' });
	const [addedEmailDomains, setAddedEmailDomains] = useState([]);
	const [submittedEmailDomains, setSubmittedEmailDomains] = useState(false);

	const [organisationIdFilter] = useState({
		name: 'organisationId',
		value: organisationId,
	});

	const addEmailDomainsMessage = useError({
		value: addEmailDomains,
		message: t('views.addEmailDomains.success'),
	});

	const isExtraValidLocally = addedEmailDomains.find(
		(item) => item.domain === currentEmailDomain.value,
	);
	const validatedEmailDomain = useDebouncedBackendFieldCheck(
		currentEmailDomain.isValid ? currentEmailDomain.value : '',
		onValidateEmailDomain,
		validateEmailDomain,
		{ organisationId },
	);

	useEffect(() => {
		if (addEmailDomainsDone && submittedEmailDomains) {
			handleBackButtonChange();
		}
	}, [addEmailDomainsDone, submittedEmailDomains]);

	const handleAddEmailDomain = () => {
		setAddedEmailDomains([
			...addedEmailDomains,
			{
				domain: currentEmailDomain.value,
				userGroupId: currentUserGroup.id,
				userGroupName: currentUserGroup.name,
			},
		]);
		currentEmailDomain.resetToInitialValue();
		setCurrentUserGroup({ name: '' });
	};

	const handleRemoveEmailDomain = (removedEmailDomainIndex) => {
		setAddedEmailDomains(
			addedEmailDomains.filter((instance, index) => index !== removedEmailDomainIndex),
		);
	};

	const handleSubmitEmailDomains = () => {
		if (isFullArray(addedEmailDomains)) {
			const emailDomainsAdded = [...addedEmailDomains];
			// eslint-disable-next-line no-unused-vars
			onAddEmailDomains(
				organisationId,
				emailDomainsAdded.map(({ userGroupName, ...rest }) => rest),
			);
			setAddedEmailDomains([]);
			addEmailDomainsMessage.setStartAction(true);
			setSubmittedEmailDomains(true);
		}
	};

	return (
		<Page className={classes.root}>
			<Box>
				<StyledButton
					className={classes.backButton}
					onClick={handleBackButtonChange}
					size='medium'
					startIcon={<KeyboardArrowLeftOutlinedIcon fontSize='large' />}
					variant='inline-default'
				>
					{t('ui.button.inline.previous')}
				</StyledButton>
				<Divider className={classes.divider} />
			</Box>
			<Box className={classes.mainContent}>
				<Card className={classes.emailDomainForm}>
					<CardContent className={classes.emailDomainFormContent}>
						<Typography variant='h3'>{t('views.addEmailDomains.title')}</Typography>
						<Typography variant='body2'>{t('views.addEmailDomains.subtitle')}</Typography>
						<FormField
							className={classes.formSpacing}
							extraValidHelperText={t('views.addEmailDomains.extraValid')}
							isExtraValid={!isObject(isExtraValidLocally) && validatedEmailDomain.isUnique}
							key={'email-domain'}
							label={t('ui.label.emailDomain')}
							name={'emailDomain'}
							placeholder={'@emailDomain.com'}
							required
							variable={currentEmailDomain}
						/>
						<SelectWithLazyLoading
							dataList={userGroups}
							filter={organisationIdFilter}
							listType='organisations'
							onFetchData={onFetchUserGroups}
							placeholder={`${t('views.addUser.userGroup.subheader.selectUserGroup')}*`}
							setSelected={setCurrentUserGroup}
							value={currentUserGroup.name}
						/>
						<Box display={'flex'} justifyContent={'end'}>
							<StyledButton
								className={classes.addButton}
								disabled={
									!currentEmailDomain.isValid ||
									isEmptyString(currentUserGroup.name) ||
									isObject(isExtraValidLocally) ||
									!validatedEmailDomain.isUnique
								}
								onClick={handleAddEmailDomain}
								size='large'
								variant='contained-secondary'
							>
								{t('ui.button.contained.add')}
							</StyledButton>
						</Box>
					</CardContent>
				</Card>
				<Box className={classes.sidePanel}>
					<SidePanel title={t('views.emailDomains.title')}>
						{isFullArray(addedEmailDomains) ?
							addedEmailDomains.map((emailDomain, index) => (
								<Accordion key={emailDomain.domain}>
									<AccordionSummary
										aria-controls='panel1a-content'
										className={classes.panelBorder}
										expandIcon={<ExpandMoreIcon htmlColor='#3f51b5' />}
										id='panel1a-header'
									>
										<Typography variant='h6'>{emailDomain.domain}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Box alignItems='center' display='flex' justifyContent='space-between'>
											<Typography variant='body2'>{`${t('ui.label.userGroup')}: ${emailDomain.userGroupName}`}</Typography>
											<IconButton onClick={() => handleRemoveEmailDomain(index)} size='small'>
												<CloseIcon />
											</IconButton>
										</Box>
									</AccordionDetails>
								</Accordion>
							))
						:	<Box className={classes.noEmailDomainsCard}>
								<InfoOutlinedIcon className={classes.noEmailDomainsIcon} fontSize='medium' />
								<Typography className={classes.noEmailDomainsLabel} variant='h6'>
									{t('views.emailDomains.noEmailDomains.title')}
								</Typography>
							</Box>
						}
						<StyledButton
							disabled={!isFullArray(addedEmailDomains)}
							fullWidth
							onClick={handleSubmitEmailDomains}
							variant='contained-primary'
						>
							{addEmailDomainsLoading ?
								<CircularProgress className={classes.progress} disableShrink size={25} />
							:	t('ui.button.contained.confirm')}
						</StyledButton>
					</SidePanel>
				</Box>
			</Box>
		</Page>
	);
};

const mapStateToProps = (state) => {
	return {
		userGroups: state.paged.userGroups,
		addEmailDomains: state.condition.addEmailDomains,
		validateEmailDomain: state.condition.validateEmailDomain,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserGroups: (page, filters, concat) =>
			dispatch(actions.fetchUserGroups(page, filters, concat)),
		onAddEmailDomains: (organisationId, emailDomains) =>
			dispatch(actions.addEmailDomains(organisationId, emailDomains)),
		onValidateEmailDomain: ({ organisationId, value }) =>
			dispatch(actions.validateEmailDomain(organisationId, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailDomains);
